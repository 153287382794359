import React, { useCallback, useEffect, useMemo } from 'react'
import actions from './actions'
import a from './actions'
import PageHeader from '../PageHeader'
import DeepLink from '../DeepLink'
import GeneralList from '../GeneralListFunctions/GeneralList'
import { Appbar } from 'react-native-paper'
import { connect, useDispatch, useSelector } from 'react-redux'
import { getFirstSelectedId } from '../FilterType/config'
import { push } from '../Navigation'
import * as routes from '../Routes.config'
import FieldListItem, { findActiveGrowingPeriod } from './FieldListItem'
import AdvisorFilter from '../AdvisorFilter'
import { hasRole } from '../helpers/role'
import idx from 'idx'
import { loadMyLocation } from '../Map/actions'
import { Translator } from 'react-translated'
import BottomFab from '../WidthAndHeight/BottomFab'
import AddCrops from './New/AddCrops'
import gpActions from './New/GrowingPeriod/actions'
import { Platform } from 'react-native'

function FieldListScreen(props) {
  const {
    selectedFarmerId,
    componentId,
    user,
    list,
    selectedCrop,
    selectedContractId,
    selectedGrowingPlan,
    certifications,
  } = props

  const [showCrops, setShowCrops] = React.useState(false)
  const organizationField = useSelector(
    state => state.organizationField.crud.object
  )
  const crud = useSelector(state => state.organizationField.crud)
  const gpCrud = useSelector(state => state.organizationFieldGrowingPeriod.crud)

  const dispatch = useDispatch()

  useEffect(() => {
    if (gpCrud?.created) {
      setShowCrops(false)
      dispatch(gpActions.reset())
    }
  }, [gpCrud, dispatch])

  const selectSection = useCallback(
    (item, originalItem) => {
      // Check if the selectedSections all contain the same active growing period ID and if the new section also contains that ID
      // If the new section does not contain that ID, remove all other sections
      // If the new section does contain that ID, just add it to the selected sections

      const activeGrowingPeriod = findActiveGrowingPeriod(item?.growingPeriods)
      let selectedSections = organizationField?.selectedSections || []

      if (activeGrowingPeriod) {
        const allSelectedSectionsHaveActiveGrowingPeriod =
          selectedSections.every(section => {
            const sectionActiveGrowingPeriod = findActiveGrowingPeriod(
              section?.growingPeriods
            )
            return (
              sectionActiveGrowingPeriod &&
              sectionActiveGrowingPeriod.id === activeGrowingPeriod.id
            )
          })

        if (!allSelectedSectionsHaveActiveGrowingPeriod) {
          if (selectedSections.some(selLoc => selLoc.id === item.id)) {
            selectedSections = selectedSections.filter(
              selLoc => selLoc.id !== item.id
            )
          } else {
            selectedSections = [item]
          }
        } else {
          if (selectedSections.some(selLoc => selLoc.id === item.id)) {
            selectedSections = selectedSections.filter(
              selLoc => selLoc.id !== item.id
            )
          } else {
            selectedSections.push(item)
          }
        }
      } else {
        if (selectedSections.some(selLoc => selLoc.id === item.id)) {
          selectedSections = selectedSections.filter(
            selLoc => selLoc.id !== item.id
          )
        } else {
          selectedSections.push(item)
        }
      }

      dispatch(
        a.setData({
          ...originalItem,
          selectedSections,
        })
      )
    },
    [organizationField, dispatch]
  )

  const extraParams = useMemo(
    () => ({
      managedOrganizationId: selectedFarmerId,
      preloadAll: true,
      sort: 'name',
      cropId: selectedCrop,
      contractId: selectedContractId,
      growingPlan: selectedGrowingPlan,
      certifications: Object.keys(certifications).filter(key =>
        certifications[key] ? key : false
      ),
    }),
    [
      selectedFarmerId,
      selectedCrop,
      selectedContractId,
      selectedGrowingPlan,
      certifications,
    ]
  )

  useEffect(() => {
    dispatch(loadMyLocation())
  }, [dispatch])

  const renderItem = useCallback(
    ({ item }) => {
      if (!list.loadingList) {
        return (
          <FieldListItem
            item={item}
            componentId={componentId}
            selectedSections={organizationField?.selectedSections || []}
            selectSection={section => selectSection(section, item)}
          />
        )
      }
    },
    [selectSection, organizationField, list.loadingList, componentId]
  )

  const onPressAdd = useCallback(
    () =>
      push(componentId, {
        component: {
          name: routes.NEW_FIELD_ROUTE,
        },
      }),
    [componentId]
  )

  const renderStickyBelowHeader = useCallback(
    () => <AdvisorFilter componentId={componentId} context="field" />,
    [componentId]
  )

  // const renderAboveHeader = useCallback(() => {
  //   if (list && list.data && list.data.length > 0) {
  //     return (
  //       <Resizer>
  //         {showOnMapButton && <ShowOnMapButton componentId={componentId} />}
  //       </Resizer>
  //     )
  //   }
  // }, [componentId, showOnMapButton, list])

  const isFarmer = hasRole(user, 'farmer')
  return (
    <>
      <Translator>
        {({ translate }) => (
          <>
            {showCrops ? (
              <AddCrops
                isLoading={gpCrud.creating}
                onFinish={(growingPeriods, onlyBack) => {
                  if (onlyBack) {
                    dispatch(
                      actions.setData({
                        ...crud.object,
                        selectedSections: [],
                      })
                    )
                    setShowCrops(false)
                    return
                  }
                  const notSelectedSections = (
                    crud.object.sections || []
                  ).filter(
                    section =>
                      !crud.object.selectedSections.some(
                        selSection => selSection.id === section.id
                      )
                  )
                  const growingPeriodsWithIDButShouldBePostedBecauseOtherSectionsWithThisGrowingPeriodIDAreNotSelected =
                    growingPeriods.filter(
                      newGp =>
                        notSelectedSections?.some(section =>
                          section?.growingPeriods?.some(
                            gp => newGp.id === gp.id
                          )
                        ) && newGp.id
                    )
                  const allSectionsWithThatGrowingPeriod =
                    notSelectedSections?.filter(section =>
                      section?.growingPeriods?.some(gp2 =>
                        growingPeriodsWithIDButShouldBePostedBecauseOtherSectionsWithThisGrowingPeriodIDAreNotSelected.some(
                          gp => gp.id === gp2.id
                        )
                      )
                    )

                  dispatch(
                    gpActions.create({
                      growingPeriods: growingPeriods.map(gp => {
                        return {
                          ...gp,
                          certifications: [
                            ...new Set([
                              ...(gp?.certifications || []),
                              gp?.demeter ? 'DEMETER' : undefined,
                              gp?.skal ? 'SKAL' : undefined,
                              gp?.mps ? 'MPS' : undefined,
                              gp?.planetProof ? 'PLANETPROOF' : undefined,
                            ]),
                          ].filter(n => n),

                          startDate: Math.round(gp?.startDate / 1000),
                          endDate: Math.round(gp?.endDate / 1000),
                          organizationField: {
                            // id: crud.object.id,
                            sections: crud.object.selectedSections.map(
                              section => {
                                return {
                                  id: section.id,
                                }
                              }
                            ),
                          },
                        }
                      }),
                      growingPeriodsWithIDButShouldBePostedBecauseOtherSectionsWithThisGrowingPeriodIDAreNotSelected:
                        growingPeriodsWithIDButShouldBePostedBecauseOtherSectionsWithThisGrowingPeriodIDAreNotSelected?.map(
                          gp => {
                            return {
                              ...gp,
                              certifications: [
                                ...new Set([
                                  ...(gp?.certifications || []),
                                  gp?.demeter ? 'DEMETER' : undefined,
                                  gp?.skal ? 'SKAL' : undefined,
                                  gp?.mps ? 'MPS' : undefined,
                                  gp?.planetProof ? 'PLANETPROOF' : undefined,
                                ]),
                              ].filter(n => n),

                              startDate: Math.round(gp?.startDate / 1000),
                              endDate: Math.round(gp?.endDate / 1000),
                              allSectionsWithThatGrowingPeriod:
                                allSectionsWithThatGrowingPeriod,
                              oldGrowingPeriod: notSelectedSections
                                ?.find(nss =>
                                  nss?.growingPeriods?.some(
                                    gp2 => gp2.id === gp.id
                                  )
                                )
                                ?.growingPeriods?.find(gp2 => gp2.id === gp.id),
                              organizationField: {
                                // id: crud.object.id,
                                sections: crud.object.selectedSections.map(
                                  section => {
                                    return {
                                      id: section.id,
                                    }
                                  }
                                ),
                              },
                            }
                          }
                        ),
                    })
                  )
                }}
              />
            ) : (
              <>
                <GeneralList
                  {...props}
                  searchPlaceholder={translate({ text: 'searchParcels' })}
                  renderItem={renderItem}
                  icon
                  extraParams={extraParams}
                  renderStickyBelowHeader={renderStickyBelowHeader}
                  // renderAboveHeader={renderAboveHeader}
                  fabLabel={Platform.OS === 'web' && translate({ text: 'new' })} // TEMP FIX for native not working new stepper
                  loadWhenLoadingList
                  onPressFab={Platform.OS === 'web' && onPressAdd} // TEMP FIX for native not working new stepper
                  isFarmer={isFarmer}
                  appbarContent={
                    <PageHeader
                      // back={() => back()}
                      componentId={componentId}
                      title={
                        <Appbar.Content
                          title={translate({ text: 'parcels' })}
                          titleStyle={{
                            textAlign: 'center',
                          }}
                          subtitleStyle={{
                            textAlign: 'center',
                          }}
                        />
                      }
                    />
                  }
                />
                {!(
                  !organizationField?.selectedSections ||
                  organizationField?.selectedSections?.length === 0
                ) && (
                  <BottomFab
                    testID="addCrops"
                    style={{ marginBottom: 64, zIndex: 200 }}
                    label={translate({ text: 'changeCrops' })}
                    icon="pencil"
                    onPress={() => setShowCrops(true)}
                    loading={crud.updating}
                  />
                )}
              </>
            )}
          </>
        )}
      </Translator>
      <DeepLink loggedIn={true} componentId={componentId} />
    </>
  )
}

const mapStateToProps = state => ({
  list: state.organizationField.list,
  showOnMapButton: (state.organizationField.list.data || []).length > 0,
  selectedFarmerId: getFirstSelectedId(state.filter.advisorFarmer.selected),
  selectedCrop: getFirstSelectedId(state.filter.crop.selected),
  selectedContractId: getFirstSelectedId(state.filter.contract.selected),
  selectedGrowingPlan: getFirstSelectedId(state.filter.growingPlan.selected),
  certifications: state.filter.certification.selectedKeyValue,
  user: idx(state, _ => _.auth.user.user),
})

export default connect(mapStateToProps, { ...actions, loadMyLocation })(
  FieldListScreen
)
