import * as a from './actions'

const initialFieldState = {
  data: {},
  fieldData: [],
  customFields: [],
  loaded: false,
  error: false,
  loading: false,
}
const fieldReducer = (
  state = initialFieldState,
  { type, payload, error, meta }
) => {
  switch (type) {
    case 'RESET': {
      return initialFieldState
    }

    case a.LOAD_POLYGONS: {
      return {
        ...state,
        loading: true,
        loaded: false,
      }
    }

    case a.LOADED_POLYGONS: {
      if (error) {
        return {
          ...state,
          data: {},
          error: true,
          loading: false,
          loaded: true,
        }
      }
      return {
        ...state,
        data: {
          field: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'MultiPolygon',
                  coordinates: [
                    ...((payload.data &&
                      payload.data.field &&
                      payload.data.field.features &&
                      payload.data.field.features[0].geometry.coordinates) ||
                      []),
                    ...(state.rawCoordinates || []),
                  ],
                },
              },
            ],
          },
        },
        fieldData: [...(state.fieldData || []), ...(payload.fieldData || [])],
        customFields: payload.customFields,
        error: false,
        loading: false,
        loaded: true,
      }
    }
    case a.ADD_CUSTOM_FIELD: {
      return {
        ...state,
        data: {
          field: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'MultiPolygon',
                  coordinates: [
                    ...((state.data.field &&
                      state.data.field.features &&
                      state.data.field.features[0].geometry.coordinates) ||
                      []),
                    [payload[0].rawCoordinates],
                  ],
                },
              },
            ],
          },
        },
        fieldData: [...payload, ...(state.fieldData || [])],
        rawCoordinates: payload[0].rawCoordinates,
      }
    }
    default:
      return state
  }
}

export default fieldReducer
