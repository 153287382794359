import React, { useCallback } from 'react'
import { StyleSheet, View } from 'react-native'
import { RadioButton, Text, TouchableRipple } from 'react-native-paper'

const styles = StyleSheet.create({
  inner: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
    paddingLeft: 0,
  },
  label: { paddingLeft: 0, paddingRight: 16 / 2 },
})
function RadioButtonWithLabel({
  value,
  onChangeValue,
  label,
  disabled,
  testID,
  checked,
}) {
  const toggle = useCallback(() => {
    onChangeValue(!checked)
  }, [onChangeValue, checked])

  return (
    <TouchableRipple
      onPress={toggle}
      style={styles.container}
      testID={testID}
      disabled={disabled}
      accessibilityTraits={'button'}
      accessibilityComponentType={'button'}
      accessibilityRole={'button'}
      accessibilityLabel={label}
    >
      <View style={[styles.inner]}>
        <RadioButton
          key="radio-button"
          value={value}
          onPress={toggle}
          disabled={disabled}
          status={checked ? 'checked' : 'unchecked'}
        />
        <Text style={styles.label}>{label}</Text>
      </View>
    </TouchableRipple>
  )
}

export default RadioButtonWithLabel
