import {useLocation} from "react-router-dom";
import React from "react";

const useQueryParameter = (url, queryParam) => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search).get(queryParam), [search, queryParam]);
}

export default useQueryParameter
