import React from 'react'
import './Checkbox.web.css'
import CheckmarkIcon from '../vectors/CheckmarkIcon.web'

function Checkbox({ status, withPadding, onPress, style }) {
  // console.log({ style })
  return (
    <div
      role="checkbox"
      aria-checked={status === 'checked'}
      tabIndex="0"
      className={`checkbox ${status} ${withPadding ? 'with-padding' : ''}`}
      style={style && typeof style === 'object' ? style : {}}
      onClick={onPress}
    >
      <div className={'inner'}>
        <CheckmarkIcon className={'checkmark'} color="white" />
      </div>
    </div>
  )
}

export default Checkbox
