import React, { Component } from 'react'
import { List } from 'react-native-paper'
import { Platform, View } from 'react-native'
import BeneficialOrganismList from './List/BeneficialOrganismList'
import ClosableModal from '../components/ClosableModal'
import fonts from '../fonts'
import { Translator } from 'react-translated'
import { getLanguage } from '../language'

class SingleSelect extends Component {
  state = {
    modalVisible: false,
  }
  _hideModal = () => {
    this.setState({ modalVisible: false })
  }
  _showModal = () => {
    this.setState({ modalVisible: true })
  }
  _isSelected = item => {
    const valueId = this.props.value && this.props.value.id
    return item.id === valueId
  }
  _select = item => {
    console.log(item)
    this._hideModal()
    this.props.onSelect(item)
  }

  render() {
    const { modalVisible } = this.state
    const { value, editable } = this.props
    const language = getLanguage()
    return (
      <>
        <Translator>
          {({ translate }) => (
            <List.Item
              onPress={editable && this._showModal}
              title={
                value && value.name && typeof value.name === 'object'
                  ? language === 'nl'
                    ? `${[
                        value.name.nl,
                        value.name?.la ? `(${value.name.la})` : undefined,
                      ]
                        .filter(n => n)
                        .join(' ')}`
                    : `${[
                        value.name?.en ? value.name.en : value.name?.nl,
                        value.name?.la ? `(${value.name.la})` : undefined,
                      ]
                        .filter(n => n)
                        .join(' ')}`
                  : editable
                  ? translate({ text: 'selectBeneficialOrganism' })
                  : translate({ text: 'noBeneficialOrganismSelected' })
              }
              titleStyle={{
                ...fonts.medium,
              }}
              description={
                value && value.name?.la
                  ? translate({ text: 'beneficialOrganism' })
                  : ''
              }
              right={p => editable && <List.Icon {...p} icon="chevron-down" />}
            />
          )}
        </Translator>

        <ClosableModal
          isVisible={modalVisible}
          onBackButtonPress={this._hideModal}
          onBackdropPress={this._hideModal}
          onRequestClose={this._hideModal}
          onModalHide={this._hideModal}
          hideHeader={true}
        >
          <View
            style={{
              marginBottom: 25,
              height: Platform.OS === 'web' ? 600 : '100%',
            }}
          >
            <BeneficialOrganismList
              {...this.props}
              singleSelect
              isSelected={this._isSelected}
              // searchIcon={'close'}
              editable={editable}
              parentId={
                value &&
                (value.parent && value.parent.id
                  ? value.parent.id
                  : value.parentId
                  ? value.parentId
                  : value.parent_id)
              }
              onBack={this._hideModal}
              onSelect={this._select}
              autoFocusSearch={true}
            />
          </View>
        </ClosableModal>
      </>
    )
  }
}

SingleSelect.defaultProps = {
  editable: true,
}

export default SingleSelect
