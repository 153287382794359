import React from 'react'
import { View } from 'react-native'
import { Text } from 'react-native-paper'
import Resizer from '../WidthAndHeight/Resizer'
import { Translate } from 'react-translated'

function ComingSoon() {
  return (
    <Resizer>
      <View style={{ padding: 16 }}>
        <Text>
          <Translate text={'comingSoon'} />
        </Text>
      </View>
    </Resizer>
  )
}

export default ComingSoon
