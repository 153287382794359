import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SimpleBackgroundHeader } from '../../GeneralListFunctions/GeneralListHeader'
import PageHeader from '../../PageHeader'
import { Appbar, Text } from 'react-native-paper'
import Navigation from '../../Navigation'
import actions from '../actions'
import * as newActions from './actions'

import AdviceLines from '../AdviceLines'
import BottomFab from '../../WidthAndHeight/BottomFab'
import Resizer from '../../WidthAndHeight/Resizer'
import KeyboardAvoidingScrollView from '../../WidthAndHeight/KeyboardAvoidingScrollView'

export default function AdviceLinesScreen({ componentId }) {
  const onBack = useCallback(() => {
    Navigation.pop(componentId)
  }, [componentId])

  const dispatch = useDispatch()
  const pest = useSelector(
    state =>
      (state.filter && state.filter.pest && state.filter.pest.selected) || {}
  )
  const lines = useSelector(state => {
    let newLines = (
      (state.advice.listAndCrud.crud.object || {}).lines || []
    ).map(line => {
      return { ...line, pest: pest && pest[0] }
    })
    return newLines
  })
  const maker = useSelector(state => state.advice.extra.maker)
  const onPressCreate = useCallback(() => {
    dispatch(newActions.create())
  }, [dispatch])

  useEffect(() => {
    if (!maker.creating && maker.created) {
      Navigation.popToRoot(componentId)
    }
  }, [componentId, maker.created, maker.creating])

  const onChangeLines = useCallback(
    lines => {
      dispatch(
        actions.setData({
          lines,
        })
      )
    },
    [dispatch]
  )

  return (
    <>
      <SimpleBackgroundHeader
        appbarContent={
          <PageHeader
            componentId={componentId}
            back={onBack}
            title={<Appbar.Content title={`Middelen om te adviseren`} />}
          />
        }
      />
      <KeyboardAvoidingScrollView>
        <Resizer>
          {maker.creatingError ? <Text>Fout bij aanmaken</Text> : null}
          <AdviceLines
            value={lines || []}
            onChange={onChangeLines}
            // label={'....'}
          />
        </Resizer>
      </KeyboardAvoidingScrollView>

      <BottomFab
        label={'Adviseer'}
        onPress={onPressCreate}
        loading={maker.creating}
      />
    </>
  )
}
