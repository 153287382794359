import React from 'react'
import Svg from './SvgAbstraction'
import Path from './PathAbstraction'

import { propTypes, defaultProps, color } from './standardVector'

export const width = 24
export const height = 24

const Vector = props => (
  <Svg
    width={props.width || width}
    height={props.height || height}
    className={props.className}
    viewBox="0 0 24 24"
  >
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M17.25,12.75a1.5,1.5,0,0,0-1.5-1.5h-9L5.69,10.189A1.5,1.5,0,0,0,4.629,9.75H2.25a1.5,1.5,0,0,0-1.5,1.5v10.5a1.5,1.5,0,0,0,1.5,1.5h13.5a1.5,1.5,0,0,0,1.5-1.5Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M20.25,17.25v-9a1.5,1.5,0,0,0-1.5-1.5h-9L8.69,5.689A1.5,1.5,0,0,0,7.629,5.25H5.25a1.5,1.5,0,0,0-1.5,1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M23.25,12.75v-9a1.5,1.5,0,0,0-1.5-1.5h-9L11.69,1.189A1.5,1.5,0,0,0,10.629.75H8.25a1.5,1.5,0,0,0-1.5,1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </Svg>
)

Vector.propTypes = propTypes
Vector.defaultProps = defaultProps

export default Vector
