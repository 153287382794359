import React, { Component } from 'react'
import PT from 'prop-types'
import { ScrollView, StyleSheet, View } from 'react-native'
import keyboardHOC from './keyboardHOC'
import safeAreaHOC from './safeAreaHOC'

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
})

class KeyboardAvoidingScrollView extends Component {
  render() {
    const {
      keyboardHeight,
      safe,
      safeSettings,
      contentContainerStyle,
      ...rest
    } = this.props

    const { left, right, bottom, top } = safeSettings
    const safePadding = {
      paddingLeft: left ? safe.left : 0,
      paddingRight: right ? safe.right : 0,
      paddingBottom: bottom ? safe.bottom : 0,
      paddingTop: top ? safe.top : 0,
    }
    console.log(safeSettings, safePadding)
    return (
      <View style={styles.flex}>
        <ScrollView
          {...rest}
          contentContainerStyle={[
            { flexGrow: 1 },
            safePadding,
            contentContainerStyle,
          ]}
        />
        <View style={{ height: keyboardHeight || 0 }} />
      </View>
    )
  }
}

KeyboardAvoidingScrollView.propTypes = {
  children: PT.oneOfType([PT.arrayOf(PT.node), PT.node]).isRequired,
  keyboardHeight: PT.number.isRequired,
}
KeyboardAvoidingScrollView.defaultProps = {
  scrollToEnd: false,
  safeSettings: {
    top: false,
    bottom: false,
    left: false,
    right: false,
  },
}
export default safeAreaHOC(keyboardHOC(KeyboardAvoidingScrollView))
