import { actionCreator as ac } from '../../helpers/actionCreator'

const ns = (type: string) => `GROUNDWATERPROTECTION_AREA_${type}`

export const CHECK = ns('CHECK')
export const check = ac(CHECK)

export const UNCHECK = ns('UNCHECK')
export const uncheck = ac(UNCHECK)

export const REMOVE = ns('REMOVE')
export const remove = ac(REMOVE)

export const RESET = ns('RESET')
export const reset = ac(RESET)
