import { getErrorMessage } from '../api'
import { Reducer } from 'redux'
import { pushCustomEvent } from '../Analytics/abstraction'

// import { filterTypesSorted } from '../FilterType/config'

export interface CrudType<T> {
  object: any | T
  generatingExport: boolean
  generatedExport: boolean
  loaded: boolean
  loading: boolean
  updating: boolean
  updated: boolean
  removing: boolean
  creating: boolean
  created: boolean
  loadingError: boolean
  loadingErrorMessage: string
  updatingError: boolean
  updatingErrorMessage: string
  removingError: boolean
  removingErrorMessage: string
  creatingError: boolean
  creatingErrorMessage: string
  creatingAdvice: boolean
  createdAdvice: boolean
  createAdviceError: boolean
  createAdviceErrorMessage: string
  sendingAdvice: boolean
  sentAdvice: boolean
  sentAdviceError: boolean
  sentAdviceErrorMessage: string
  sentAdviceResponse: any[]
  advice: undefined | T
}

export interface ListType<T> {
  tree: T[]
  data: T[]
  params: any
  reloadingList: boolean
  loadingList: boolean
  loadingMoreList: boolean
  loadedList: boolean
  offset: number
  total: number
  limit: number
  stopLoadingMore: boolean
  loadingListError: boolean
  loadingListErrorMessage: string
  loadingMoreListError: boolean
  loadingMoreListErrorMessage: string
}

export interface FactoryReducerType<T> {
  crud: CrudType<T>
  list: ListType<T>
  selected: T[]
  selectedKeyValue: T
}

// import { uniqBy } from 'lodash'
// CRUD
function getInitialCrudState<T>(): CrudType<T> {
  return {
    object: undefined,
    generatingExport: false,
    generatedExport: false,
    loaded: false,

    loading: false,
    updating: false,
    removing: false,
    creating: false,
    created: false,
    updated: false,

    loadingError: false,
    loadingErrorMessage: '',
    updatingError: false,
    updatingErrorMessage: '',
    removingError: false,
    removingErrorMessage: '',
    creatingError: false,
    creatingErrorMessage: '',
    creatingAdvice: false,
    createdAdvice: false,
    createAdviceError: false,
    createAdviceErrorMessage: '',
    advice: undefined,
    sendingAdvice: false,
    sentAdvice: false,
    sentAdviceError: false,
    sentAdviceResponse: [],
    sentAdviceErrorMessage: '',
  }
}

// PAGED LIST
export function getInitialListState<T>(): ListType<T> {
  return {
    tree: [],
    data: [],
    params: undefined,

    reloadingList: false,
    loadingList: false,
    loadingMoreList: false,
    loadedList: false,

    offset: 0,
    total: 0,
    limit: 0,

    stopLoadingMore: false,

    loadingListError: false,
    loadingListErrorMessage: '',

    loadingMoreListError: false,
    loadingMoreListErrorMessage: '',
  }
}

function getInitialSelectedState<T>(): T[] {
  return []
}

function getInitialSelectedKeyValueState<T>(): T {
  return {} as T
}

export function getDefaultState<T>(): FactoryReducerType<T> {
  const crud = getInitialCrudState<T>()
  const list = getInitialListState<T>()
  const selected = getInitialSelectedState<T>()
  const selectedKeyValue = getInitialSelectedKeyValueState<T>()
  return {
    crud,
    list,
    selected,
    selectedKeyValue,
  }
}

interface ResponseType<T> {
  tree: T[]
  data: T[]
  pagination?: {
    total: number
    number?: number // in Agro4all some things are number and some limit
    limit?: number // in Agro4all some things are number and some limit
    offset: number
  }
}

function getDataFromResponse<T>(
  response: ResponseType<T>
  // oldList: ListType<T>,
  // silentReloading: boolean
) {
  // {
  //   data: {
  //     success: true,
  //     data: {
  //       data: [],
  //       current_page: 1,
  //       last_page: 1,
  //       per_page: 15,
  //     },
  //     message: '....'
  //   },
  //   status: 200,
  //   statusText: 'OK',
  //   headers: {},
  // }

  // and sometimes without pagination ->
  // {
  //   {
  //     data: [],
  //     message: '....'
  //   },
  //   status: 200,
  //   statusText: 'OK',
  //   headers: {},
  // }
  const tree = response && response.tree ? response.tree : []

  const apiResponse = response || {}

  // Support mock api
  let data: T[] = []
  let listGetter: {} = {}
  if (Array.isArray(apiResponse)) {
    data = apiResponse
  } else if (Array.isArray(apiResponse.data)) {
    data = apiResponse.data
  } else if (Object.keys(apiResponse).length > 0) {
    listGetter = apiResponse
  }

  const pagination = apiResponse.pagination
  const total = pagination?.total || 0
  const offset = pagination?.offset || 0
  const limit = pagination?.limit || pagination?.number || 0

  return {
    tree,
    data,
    limit,
    offset,
    total,
    listGetter,
  }
}

export const getObjectFromResponse = (response: any) => {
  if (response && response.date) {
    return {
      ...response,
      date: response.date * 1000,
    }
  }
  if (
    response &&
    response.growingPeriods &&
    response.growingPeriods.length > 0
  ) {
    return {
      ...response,
      growingPeriods: response.growingPeriods.map((it: any) => {
        return {
          ...it,
          endDate: Math.round(it.endDate * 1000),
          startDate: Math.round(it.startDate * 1000),
        }
      }),
    }
  }
  // if (response && response.data) {
  //   return response.data
  // }
  return response
}

interface ActionType {
  type: any
  payload: any
  meta: any
  error: any
}

type ReducerFactoryReturnType<T> = (
  s: FactoryReducerType<T>,
  action: ActionType
) => FactoryReducerType<T>

export default function reducerFactory<T>(
  actionTypes: {
    RESET: string
    RELOAD_LIST: string
    LOAD_LIST: string
    LOADED_LIST: string
    LOAD_MORE_LIST: string
    LOADED_MORE_LIST: string
    LOAD: string
    LOADED: string
    SELECT: string
    SELECT_KEY_VALUE: string
    TOGGLE_SELECT_ALL: string
    REMOVE_SELECTED: string
    REMOVE_SELECTED_KEY_VALUE: string
    CREATE: string
    CREATED: string
    UPDATE: string
    UPDATED: string
    REMOVE: string
    REMOVED: string
    SET_DATA: string
    CREATE_ADVICE: string
    CREATED_ADVICE: string
    SEND_ADVICE: string
    SENT_ADVICE: string
  },
  is?: FactoryReducerType<T>
): Reducer<FactoryReducerType<T>, any> {
  const ds: FactoryReducerType<T> = is || getDefaultState<T>()

  return function reducerImplementation(
    state = ds,
    { type, payload, meta, error }: ActionType
  ): FactoryReducerType<T> {
    switch (type) {
      case 'RESET': {
        return ds
      }
      case actionTypes.RESET: {
        return ds
      }
      case actionTypes.SET_DATA: {
        return {
          ...state,
          crud: {
            ...state.crud,
            object: { ...state.crud.object, ...payload },
          },
        }
      }
      case actionTypes.CREATE_ADVICE: {
        return {
          ...state,
          crud: {
            ...state.crud,
            creatingAdvice: true,
            createdAdvice: false,
            createAdviceError: false,
            createAdviceErrorMessage: '',
          },
        }
      }
      case actionTypes.CREATED_ADVICE: {
        if (error) {
          return {
            ...state,
            crud: {
              ...state.crud,
              creatingAdvice: false,
              createdAdvice: false,
              createAdviceError: true,
              createAdviceErrorMessage: getErrorMessage(payload),
            },
          }
        }
        return {
          ...state,
          crud: {
            ...state.crud,
            creatingAdvice: false,
            createdAdvice: true,
            createAdviceError: false,
            createAdviceErrorMessage: '',
            advice: payload,
          },
        }
      }
      case actionTypes.SEND_ADVICE: {
        return {
          ...state,
          crud: {
            ...state.crud,
            sendingAdvice: true,
            sentAdvice: false,
            sentAdviceError: false,
            sentAdviceErrorMessage: '',
            sentAdviceResponse: [],
          },
        }
      }
      case actionTypes.SENT_ADVICE: {
        console.log({ payload, meta, error })
        if (error) {
          return {
            ...state,
            crud: {
              ...state.crud,
              sendingAdvice: false,
              sentAdvice: false,
              sentAdviceError: true,
              sentAdviceErrorMessage: getErrorMessage(payload),
              sentAdviceResponse: payload,
            },
          }
        }
        return {
          ...state,
          crud: {
            ...state.crud,
            sendingAdvice: false,
            sentAdvice: true,
            sentAdviceError: false,
            sentAdviceErrorMessage: '',
            sentAdviceResponse: payload,
          },
        }
      }
      case actionTypes.REMOVE_SELECTED: {
        return {
          ...state,
          selected: [],
        }
      }
      case actionTypes.REMOVE_SELECTED_KEY_VALUE: {
        return {
          ...state,
          selectedKeyValue: {} as T,
        }
      }
      case actionTypes.SELECT_KEY_VALUE: {
        return {
          ...state,
          selectedKeyValue: payload,
        }
      }
      case actionTypes.SELECT: {
        const selectFunc = (currentItem: any) => {
          return (currentItem && currentItem.id) || currentItem.id === 0
            ? currentItem.id === payload.id
            : `${currentItem.code}_${currentItem.type}` ===
                `${payload.code}_${payload.type}`
        }

        const alreadySelected = state.selected.some(selectFunc)
        const isSingleSelect = meta && meta.singleSelect
        const noTracking = meta && meta.noTracking
        const removeOther = meta && meta.removeOther

        // alert(alreadySelected ? 'yes' : 'no')
        let selected
        if (isSingleSelect) {
          selected = [payload]
        } else {
          selected = alreadySelected
            ? state.selected.filter(item => !selectFunc(item))
            : [...state.selected, payload]
        }
        if (removeOther) {
          selected = selected.filter(it => it.id !== removeOther.id)
        }
        if (!noTracking) {
          let forItem
          // TYPE for example is FILTER_RESISTANCE_SELECT
          if (`${type}`.toLowerCase().includes('filter_')) {
            // slug for example is resistance_select
            const slug = `${type}`.toLowerCase().split('filter_').pop() || ''
            // forItem for example is resistance
            forItem = slug.substring(0, slug?.length - 7)
          }
          if (alreadySelected) {
            if (forItem) {
              pushCustomEvent('Filter deselected for', `${forItem}`, {
                Item: payload,
              })
            } else {
              pushCustomEvent('Filter deselected', '', { Item: payload })
            }
          } else {
            console.log('not already selected')
            if (forItem) {
              pushCustomEvent('Filter selected for', `${forItem}`, {
                Item: payload,
              })
            } else {
              pushCustomEvent('Filter selected', '', { Item: payload })
            }
          }
        }

        return {
          ...state,
          selected,
        }
      }
      case actionTypes.TOGGLE_SELECT_ALL: {
        const selectedAll = state.selected.length === state.list.data.length
        pushCustomEvent('Filters all selected', '', {
          selectedItems: state.list.data,
        })
        return {
          ...state,
          selected: selectedAll ? [] : state.list.data || [],
        }
      }
      case actionTypes.LOAD: {
        let object: T | undefined
        let loading = true
        let generatingExport = false

        if (payload && payload.id) {
          object = payload
        } else if (typeof payload === 'string') {
          object = {
            id: payload,
          } as any
        } else {
          loading = false
          generatingExport = true
        }
        // Let's give our users the fastest response possible without networking
        //@ts-ignore
        if (meta && meta.cacheFromList && object && object.id) {
          //@ts-ignore
          const cachedObject = state.list.data.find(it => it.id === object.id)

          if (cachedObject) {
            object = cachedObject
            loading = false
            meta.cachedFromList = true
          }
        }

        return {
          ...state,
          crud: {
            ...getInitialCrudState<T>(),
            loaded: false,
            loading,
            object,
            generatingExport,
            generatedExport: false,
            sentAdvice: false,
          },
        }
      }
      case actionTypes.LOADED: {
        if (error) {
          return {
            ...state,
            crud: {
              ...getInitialCrudState<T>(),
              loadingError: true,
              generatingExport: false,
              generatedExport: false,
              loadingErrorMessage: getErrorMessage(payload),
            },
          }
        }
        console.log(payload)
        return {
          ...state,
          crud: {
            ...getInitialCrudState<T>(),
            loaded: payload ? true : false,
            generatingExport: false,
            generatedExport: payload && !payload?.id ? true : false,
            object: getObjectFromResponse(payload),
          },
        }
      }
      case actionTypes.CREATE: {
        console.log({ payload })
        return {
          ...state,
          crud: {
            ...state.crud,
            object: payload || state.crud.object,
            creating: true,
            created: false,
            creatingError: false,
          },
        }
      }
      case actionTypes.CREATED: {
        if (error) {
          return {
            ...state,
            crud: {
              ...state.crud,
              object: {
                ...state.crud.object,
                date: state.crud.object.date
                  ? Math.round(state.crud.object.date * 1000)
                  : undefined,
              } as any,
              creating: false,
              created: false,
              creatingError: true,
              creatingErrorMessage: getErrorMessage(payload),
            },
          }
        }
        const object = getObjectFromResponse(payload)
        return {
          ...state,
          list: {
            ...state.list,
            data: [...state.list.data, object],
          },
          crud: {
            ...state.crud,
            object,
            creating: false,
            created: true,
            creatingError: false,
          },
        }
      }
      case actionTypes.UPDATE: {
        return {
          ...state,
          crud: {
            ...state.crud,
            updating: true,
            updatingError: false,
          },
        }
      }
      case actionTypes.UPDATED: {
        if (error) {
          return {
            ...state,
            crud: {
              ...state.crud,
              updating: false,
              updated: false,
              updatingError: true,
              updatingErrorMessage: getErrorMessage(payload),
            },
          }
        }
        const object = getObjectFromResponse(payload)

        return {
          ...state,
          list: {
            ...state.list,
            data: state.list.data.map(it =>
              //@ts-ignore
              it.id === object.id ? { ...it, ...object } : it
            ),
          },
          crud: {
            ...state.crud,
            object,
            updating: false,
            updated: true,
            updatingError: false,
          },
        }
      }
      case actionTypes.REMOVE: {
        return {
          ...state,
          crud: {
            ...state.crud,
            removing: true,
            removingError: false,
          },
        }
      }
      case actionTypes.REMOVED: {
        if (error) {
          return {
            ...state,
            crud: {
              ...state.crud,
              removing: false,
              removingError: true,
              removingErrorMessage: getErrorMessage(payload),
            },
          }
        }
        // const object = getObjectFromResponse(payload)
        return {
          ...state,
          list: {
            ...state.list,
            //@ts-ignore
            data: state.list.data.filter(it => it.id !== payload.id),
          },
          crud: {
            ...state.crud,
            removing: false,
            removingError: false,
          },
        }
      }
      // case actionTypes.SET_LIST_PARAMS:
      case actionTypes.LOAD_LIST:
      case actionTypes.RELOAD_LIST: {
        const { silentReloading } = meta || {}
        const { params } = payload || {}
        const initialListState = getInitialListState<T>()
        return {
          ...state,
          list: {
            ...initialListState,
            tree: silentReloading ? state.list.tree : [],
            offset: silentReloading ? state.list.offset : 0,
            limit: silentReloading ? state.list.limit : 0,
            total: silentReloading ? state.list.total : 0,
            loadedList: silentReloading
              ? state.list.loadedList
              : initialListState.loadedList,
            loadingList: true,
            reloadingList: actionTypes.RELOAD_LIST === type,
            params: params || state.list.params,
            data: silentReloading ? state.list.data : [],
          },
          crud: {
            ...getInitialCrudState(),
          },
        }
      }
      // case actionTypes.RESET: {
      //   const initialCrudState = getInitialCrudState<T>()
      //   return {
      //     ...state,
      //     crud: initialCrudState,
      //   }
      // }
      case actionTypes.LOADED_LIST: {
        const initialListState = getInitialListState<T>()

        if (error) {
          return {
            ...state,
            list: {
              ...initialListState,
              params: state.list.params,
              loadingListError: true,
              loadingListErrorMessage: getErrorMessage(payload),
            },
          }
        }

        const data = getDataFromResponse<T>(payload)
        const stopLoadingMore = data.limit + data.offset >= data.total
        return {
          ...state,
          list: {
            ...initialListState,
            stopLoadingMore,
            loadedList: true,
            params: state.list.params,
            ...data,
          },
        }
      }
      case actionTypes.LOAD_MORE_LIST: {
        const { total, offset } = state.list
        if (offset >= total) {
          return state
        }
        return {
          ...state,
          list: {
            ...state.list,
            loadingMoreList: true,
          },
        }
      }
      case actionTypes.LOADED_MORE_LIST: {
        if (error) {
          return {
            ...state,
            list: {
              ...state.list,
              loadingMoreList: false,
              loadingMoreListError: true,
              loadingMoreListErrorMessage: getErrorMessage(payload),
            },
          }
        }
        const { data, tree, total, offset, limit } =
          getDataFromResponse<T>(payload)
        const initialListState = getInitialListState<T>()

        return {
          ...state,
          list: {
            ...initialListState,
            tree,
            params: state.list.params,
            data: state.list.data.concat(data),
            total,
            offset,
            limit,
          },
        }
      }

      default: {
        return state
      }
    }
  }
}
