import React from 'react'
import { View } from 'react-native'
import { Link } from 'react-router-dom'

function Linkie(props) {
  const { to, children, ...rest } = props

  if (!to) {
    return <View {...rest}>{children}</View>
  }
  return (
    <Link
      to={to}
      className="remove-link-style flex-like"
      // onClick={event => {
      //   //   event.stopPropagation()
      //   //   event.preventDefault()
      // }}
    >
      <View {...rest}>{children}</View>
    </Link>
  )
}
export default Linkie
