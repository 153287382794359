import React, { Component } from 'react'
import { View } from 'react-native'
import { connect } from 'react-redux'
import { withTheme } from 'react-native-paper'
import actions from './actions'
import GeneralList from '../../../GeneralListFunctions/GeneralList'
import ItemInList from '../../../FilterType/ItemInList'
import FilterHeader from '../../../FilterType/FilterHeader'
import { Translator } from 'react-translated'

class AttributesListScreen extends Component {
  _getParams = (ignore, inputSearch) => {
    const { list } = this.props
    let search = inputSearch
    if (search === undefined) {
      search = list.params && list.params.search
    }

    let params = {
      ...list.params,
      sort: 'number',
      search,
      [`search[name]`]: search,
      name: search,
      onlyExceptAttributes: true,
    }

    return params
  }
  _select = item => {
    const { onSelect, selected } = this.props
    let newSelected

    if (selected && selected.length > 0) {
      if (selected.some(it => it.id === item.id)) {
        // deselect, because its already selected
        newSelected = selected.filter(it => it.id !== item.id)
      } else {
        // select, because its not yet selected
        // Also, we need to deselect all items that are in this items exceptAttributes array.
        newSelected = [...selected, item].filter(it => {
          return !item.exceptAttributes.some(except => except.id === it.id)
        })
      }
    } else {
      // select, because nothing is selected
      newSelected = [item]
    }

    onSelect(newSelected)
  }
  _renderItem = ({ item }) => {
    let isSelected = this.props.isSelected(item)
    return (
      <ItemInList
        onSelect={this._select}
        onPress={this._select}
        item={item}
        filterType={this.props.filterType}
        isSelected={isSelected}
      />
    )
  }
  render() {
    const { componentId, onBack, list, filterType } = this.props
    return (
      <View style={{ marginBottom: 25, height: 600 }}>
        <Translator>
          {({ translate }) => (
            <GeneralList
              {...this.props}
              searchPlaceholder={filterType.searchPlaceholder}
              renderItem={this._renderItem}
              loadListOnMount={this.props.editable}
              list={list}
              icon
              appbarContent={
                <FilterHeader
                  onBack={onBack}
                  filterType={filterType}
                  componentId={componentId}
                />
              }
              getParams={this._getParams}
              fabLabel={translate({ text: 'done' })}
              onPressFab={() => onBack()}
              fabIcon={'check'}
            />
          )}
        </Translator>
      </View>
    )
  }
}

const mapStateToProps = state => ({
  list: state.growingPeriodAttributes.list,
})

export default connect(
  mapStateToProps,
  actions
)(withTheme(AttributesListScreen))
