import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { connect } from 'react-redux'
import { Appbar, List, RadioButton, withTheme } from 'react-native-paper'
import actions from '../../Farmer/Organization/User/List/actions'
import registrationActions from '../actions'

import GeneralList from '../../GeneralListFunctions/GeneralList'
import PageHeader from '../../PageHeader'
import Resizer from '../../WidthAndHeight/Resizer'
import Navigation from '../../Navigation'
import { getUserName } from '../../Farmer/Organization/User/UserItem'
import { Translator } from 'react-translated'

class PerformedByScreen extends Component {
  _press = (performedBy, withoutNext) => {
    this.props.setRegistrationData({
      performedBy: {
        user: performedBy,
        organization: {
          id: performedBy.organization_id,
        },
      },
    })
    if (!withoutNext) {
      this.props.next()
    }
  }
  componentDidMount() {
    const { authData, selectedPerformedBy } = this.props
    // We pre-select the authenticated user if nothing is selected becuase it mainly occurs that the user creating the registration, also performed the registration.
    if (!selectedPerformedBy) {
      this._press(authData.user, true)
    }
  }

  _renderLeft = (item, isSelected) => () => {
    return (
      <View style={styles.leftContainer}>
        <RadioButton.Android
          status={isSelected ? 'checked' : 'unchecked'}
          onPress={() => this._press(item)}
        />
      </View>
    )
  }
  _onBack = () => {
    Navigation.pop(this.props.componentId)
  }
  _renderItem = ({ item }) => {
    const { selectedPerformedBy } = this.props

    let selected = false
    if (selectedPerformedBy && selectedPerformedBy.user) {
      selected = selectedPerformedBy.user.id === item.id
    }
    return (
      <Resizer>
        <List.Item
          key={item.id}
          title={getUserName(item)}
          description={item && item.organization && item.organization.name}
          onPress={() => this._press(item)}
          left={this._renderLeft(item, selected)}
        />
      </Resizer>
    )
  }
  // _getParams = () => {
  //   const { authData } = this.props
  //   return {
  //     'organizationIds[][]': authData && authData.id,
  //   }
  // }
  render() {
    const { componentId, performedBy, prev, list, authData, loaded } =
      this.props
    return (
      <Translator>
        {({ translate }) => (
          <GeneralList
            {...this.props}
            searchPlaceholder={translate({ text: 'searchPerformer' })}
            renderItem={this._renderItem}
            // extraParams={this._getParams()}
            list={
              loaded
                ? {
                    ...list,
                    data: [
                      authData && {
                        ...authData.user,
                        organization: authData.organization,
                      },
                      ...(list && list.data).filter(
                        n =>
                          n.id !==
                          (authData && authData.user && authData.user.id)
                      ),
                    ],
                  }
                : list
            }
            renderAboveHeader={this._renderAboveHeader}
            icon
            rerenderKey={performedBy && performedBy.user && performedBy.user.id}
            appbarContent={
              <PageHeader
                componentId={componentId}
                back={prev}
                title={
                  <Appbar.Content
                    title={translate({ text: 'registrationPerformer' })}
                  />
                }
              />
            }
            context={'activity'}
          />
        )}
      </Translator>
    )
  }
}
// eslint-disable-next-line
const styles = StyleSheet.create({})

const mapStateToProps = state => {
  return {
    authData: state.auth.user,
    list: state.farmer.userList.list,
    loaded: state.farmer.userList.list.loadedList,
    selectedPerformedBy:
      state.registration.crud.object &&
      state.registration.crud.object.performedBy,
    registration: state.registration.crud.object,
  }
}

export default connect(mapStateToProps, {
  ...actions,
  setRegistrationData: registrationActions.setData,
})(withTheme(PerformedByScreen))
