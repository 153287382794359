import { all, put, takeLatest } from 'redux-saga/effects'
import * as a from './actions'
import locationActions from '../../FilterType/Location/actions'
import cropActions from '../../FilterType/Crop/actions'
import * as groundwaterProtectionAreaActions from '../../FilterType/GroundWaterProtectionArea/actions'
import * as parcelCloseToWaterActions from '../../FilterType/ParcelCloseToWater/actions'
import * as cropFreeBufferZoneActions from '../../FilterType/CropFreeBufferZone/actions'
import * as aquaticBufferZoneActions from '../../FilterType/ParcelCloseToWater/AquaticBufferZone/actions'
// import dateActions from '../../FilterType/Location/actions'
import sprayTechniqueActions from '../../FilterType/SprayTechnique/actions'
import sprayNozzleActions from '../../FilterType/SprayNozzle/actions'
import sprayDirectionActions from '../../FilterType/SprayDirection/actions'

function* setFilters({ payload }) {
  try {
    const { params } = payload
    console.log(params)

    yield all([
      put(
        locationActions.select(
          { id: params.locationId, name: params.locationName },
          { singleSelect: true }
        )
      ),
      put(
        cropActions.select(
          { id: params.cropId, name: params.cropName },
          { singleSelect: true }
        )
      ),
      params.groundwaterProtectionArea === true
        ? put(groundwaterProtectionAreaActions.check())
        : put(groundwaterProtectionAreaActions.uncheck()),
      params.surfaceWater === true
        ? put(parcelCloseToWaterActions.check())
        : put(parcelCloseToWaterActions.uncheck()),
      put(
        cropFreeBufferZoneActions.change({
          width: params['cropFreeBufferZone[width]'],
          present: params['cropFreeBufferZone[present]'],
        })
      ),
      put(
        aquaticBufferZoneActions.change({
          width: params['aquaticBufferZone[width]'],
          present: params['aquaticBufferZone[present]'],
        })
      ),
      put(
        sprayTechniqueActions.select(
          { id: params.sprayerIds, name: params.sprayerName },
          { singleSelect: true }
        )
      ),
      put(
        sprayNozzleActions.select(
          { id: params.nozzleIds, name: params.nozzleName },
          { singleSelect: true }
        )
      ),
      put(
        sprayDirectionActions.select(
          { id: params.sprayDirection, name: params.sprayDirectionName },
          { singleSelect: true }
        )
      ),
    ])

    yield put(a.settedFilters())
  } catch (e) {
    console.log('error setting filters: ', e)
    // yield put(a.loadedPolygons(e, null, true))
  }
}

export default function* saga() {
  yield takeLatest(a.SET_FILTERS, setFilters)
}
