import actions from './actions'
import saga from '../../GeneralListFunctions/sagaFactory'
import { RootState } from '../../reducers'

export default () =>
  saga(
    actions,
    () => 'admissionholder',
    (state: RootState) => state.filter.admissionHolder
  )
