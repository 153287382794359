import actions from './actions'
import saga from '../../GeneralListFunctions/sagaFactory'
import { RootState } from '../../reducers'

export default () =>
  saga(
    actions,
    () => 'restriction/sprayproduct',
    (state: RootState) => state.filter.sprayProduct
  )
