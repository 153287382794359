import React, { useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import actions from './actions'
import ProductRestrictionItem from './ProductRestrictionItem'
import { getProductData } from '../selectors'
import List from '../../GeneralListFunctions/List'
import { getFirstSelectedId, mapIds } from '../../FilterType/config'
import { View } from 'react-native'
import { Text } from 'react-native-paper'
import styles from '../../Comparison/styles'
import Resizer from '../../WidthAndHeight/Resizer'
import { push } from '../../Navigation'
import * as routes from '../../Routes.config'
import { Translate, Translator } from 'react-translated'

function ProductRestrictionList({
  list,
  params,
  selectedCrop,
  componentId,
  ...rest
}) {
  const extraParams = useMemo(() => {
    return {
      limit: 100000,
      number: 100000,
      ...params,
    }
  }, [params])

  const memoizedList = useMemo(() => list, [list])

  const renderItem = useCallback(({ item }) => {
    return <ProductRestrictionItem item={item} />
  }, [])

  if (!params.productId) {
    return null
  }

  const openCropFilter = () => {
    push(componentId, {
      component: {
        name: routes.FILTER_MODAL_ROUTE,
        passProps: {
          activeId: 'crop',
        },
      },
    })
  }

  return selectedCrop.length > 0 ? (
    <Translator>
      {({ translate }) => (
        <List
          {...rest}
          list={memoizedList}
          renderItem={renderItem}
          extraParams={extraParams}
          silentReloading={false}
          reloadIfAlreadyLoaded
          customEmptyTitle={translate({ text: 'noRestrictions' })}
          customEmptyDescription={translate({
            text: 'noRestrictionsDescription',
          })}
        />
      )}
    </Translator>
  ) : (
    <Resizer>
      <View
        style={{
          padding: 16,
        }}
      >
        <Text>
          <Translate text={'cropNeededForRestrictionsFirst'} />
        </Text>
        <Text>
          <Translate text={'cropNeededForRestrictionsSecond'} />
          <Text style={styles.filterLabel} onPress={openCropFilter}>
            <Translate text={'cropNeededForRestrictionsLink'} />
          </Text>
          .
        </Text>
      </View>
    </Resizer>
  )
}

const mapStateToProps = state => ({
  list: state.product.restriction.list,
  selectedCrop: state.filter.crop.selected,
  params: {
    productId: getProductData(state).id,
    cropId: getFirstSelectedId(state.filter.crop.selected),
    attributeIds: [...mapIds(state.filter.location.selected)],
    sprayerIds: [...mapIds(state.filter.sprayTechnique.selected)],
    nozzleIds: [...mapIds(state.filter.sprayNozzle.selected)],
    sprayDirection: getFirstSelectedId(state.filter.sprayDirection.selected),
    // sandySoil: getProductData(state).id, // TODO: Add when sandy soil filter is added to the product list filter
    groundwaterProtectionArea: state.filter.groundwaterProtectionArea,
    surfaceWater: state.filter.parcelCloseToWater,
    'cropFreeBufferZone[present]':
      state.filter.cropFreeBufferZone &&
      state.filter.cropFreeBufferZone.present,
    'cropFreeBufferZone[width]':
      state.filter.cropFreeBufferZone &&
      state.filter.cropFreeBufferZone.present &&
      state.filter.cropFreeBufferZone.width
        ? state.filter.cropFreeBufferZone.width
        : undefined,
    'aquaticBufferZone[present]':
      state.filter.aquaticBufferZone && state.filter.aquaticBufferZone.present,
    'aquaticBufferZone[width]':
      state.filter.aquaticBufferZone &&
      state.filter.aquaticBufferZone.present &&
      state.filter.aquaticBufferZone.width
        ? state.filter.aquaticBufferZone.width
        : undefined,
    timeOfApplication: state.filter.sprayDate.date,
  },
})

export default connect(mapStateToProps, actions)(ProductRestrictionList)
