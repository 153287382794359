import React, { PureComponent } from 'react'
import { View, StyleSheet } from 'react-native'
import ShimmerPlaceHolder from './ShimmerPlaceHolder'
import Resizer from '../WidthAndHeight/Resizer'

class ItemShimmer extends PureComponent {
  render() {
    const { icon } = this.props
    return (
      <Resizer>
        <View style={styles.item}>
          {icon ? (
            <View style={styles.iconContainer}>
              <ShimmerPlaceHolder style={styles.icon} />
            </View>
          ) : null}
          <View style={styles.itemInner}>
            <ShimmerPlaceHolder style={styles.title} />
          </View>
        </View>
      </Resizer>
    )
  }
}

const styles = StyleSheet.create({
  item: {
    height: 46,
    flexDirection: 'row',
    padding: 8,
    paddingLeft: 0,
    alignItems: 'center',
  },
  iconContainer: {
    width: 46,
    height: 46,

    marginRight: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    height: 24,
    width: 24,
    borderRadius: 10,
  },
  itemInner: {
    flex: 1,
    margin: 8,
  },
  title: {
    width: 100,
    height: 20,
    borderRadius: 10,
  },
})

export default ItemShimmer
