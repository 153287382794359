import * as a from './actions'
import { pushCustomEvent } from '../../Analytics/abstraction'

interface SprayDateStateType {
  open: boolean
  date: undefined | number
}

const initialState: SprayDateStateType = {
  open: false,
  date: undefined,
}

const reducer = (
  state = initialState,
  {
    type,
    payload,
    closeModal,
  }: { type: string; payload: any; closeModal?: boolean },
): SprayDateStateType => {
  switch (type) {
    case a.OPEN: {
      pushCustomEvent('Filter opened:', 'Spray date')
      return {
        ...state,
        open: true,
      }
    }
    case a.CLOSE: {
      pushCustomEvent('Filter closed:', 'Spray date')
      return {
        ...state,
        open: false,
      }
    }
    case a.SET: {
      pushCustomEvent('Filter set:', 'Spray date')
      return {
        ...state,
        date: payload,
        open: closeModal ? false : state.open,
      }
    }
    case a.REMOVE: {
      pushCustomEvent('Filter removed:', 'Spray date')
      return {
        ...state,
        date: undefined,
      }
    }
    default:
      return state
  }
}

export default reducer
