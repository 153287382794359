import React from 'react'
import { DatePickerModal } from 'react-native-paper-dates'
import { List } from 'react-native-paper'
import fonts from '../fonts'
import { getFriendlyDate } from '../dayjs'
import dayjs from 'dayjs'
// @ts-ignore
import { Translator } from 'react-translated'
import { getLanguage } from '../language'

const rightIcon = (p: any) => <List.Icon {...p} icon="chevron-down" />

export default function DatePicker({
  onChange,
  value,
  label,
  editable,
  onlyDatePicker,
  isVisible,
  onClose,
  style,
}: {
  onChange: (val: any) => any
  value: number
  label?: string
  editable?: boolean
  onlyDatePicker?: boolean
  isVisible?: boolean
  onClose?: () => any
  style?: any
}) {
  const [visible, setVisible] = React.useState(false)
  const language = getLanguage()
  console.log({ language })
  console.log({ label })
  return (
    <>
      {!onlyDatePicker && (
        <List.Item
          style={style}
          title={getFriendlyDate(dayjs(value))}
          description={label}
          right={editable !== false ? rightIcon : undefined}
          onPress={editable !== false ? () => setVisible(true) : () => null}
          titleStyle={{ ...fonts.medium }}
        />
      )}
      <Translator>
        {({
          translate,
        }: {
          translate: ({ text }: { text: string }) => string
        }) => (
          <DatePickerModal
            mode="single"
            visible={isVisible !== undefined ? isVisible : visible}
            locale={language === 'nl' ? 'nl-NL' : 'en-US'}
            label={translate({ text: 'selectDate' })}
            saveLabel={translate({ text: 'done' })}
            onDismiss={
              onClose !== undefined ? onClose : () => setVisible(false)
            }
            date={new Date(value)}
            onConfirm={val => {
              onChange(new Date(val.date as Date).getTime())
              setVisible(false)
              onClose && onClose()
            }}
          />
        )}
      </Translator>
    </>
  )
}
