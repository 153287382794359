import React, { useCallback, useState } from 'react'
import { Linking, Platform, StyleSheet, View } from 'react-native'
import actions from './actions'
import { useSelector } from 'react-redux'

import FormContainer from '../Form/FormContainer'
import detailHOC from '../GeneralListFunctions/detailHOC'

import safeAreaHOC from '../WidthAndHeight/safeAreaHOC'
import { ActivityIndicator, Appbar, Text } from 'react-native-paper'
import Navigation from '../Navigation'
import FieldsMap from '../FieldMap/FieldsMap'
import KeyboardAvoidingScrollView from '../WidthAndHeight/KeyboardAvoidingScrollView'
import AppbarHeader from '../WidthAndHeight/AppbarHeader'
import AppbarResizer from '../WidthAndHeight/AppbarResizer'
import AdviceLines from './AdviceLines'
import GrowingPeriods from '../GroupedCrop/GrowingPeriods'
import { fromTimestamp, getFriendlyDate } from '../dayjs'
import idx from 'idx'
import { hasRole } from '../helpers/role'
// import BottomFab from '../WidthAndHeight/BottomFab'
// import * as routes from '../Routes.config'
import { getLanguage } from '../language'

function AdviceDetailScreen({
  crud: {
    loading,
    updating,
    removing,
    creating,
    loaded,
    loadingError,
    loadingErrorMessage,
    updatingError,
    updatingErrorMessage,
    removingError,
    removingErrorMessage,
    object,
    creatingError,
    creatingErrorMessage,
  },
  onSet,
  onRemove,
  onSubmit,
  componentId,
  selectedField,
}) {
  const user = useSelector(state => idx(state, _ => _.auth.user.user) || {})
  const isAdvisor = hasRole(user, 'advisor')
  const [changed, setChanged] = useState(false)
  const language = getLanguage()
  const back = useCallback(() => {
    if (changed) {
      if (
        window.confirm(
          language === 'nl'
            ? 'U heeft niet opgeslagen werk. Weet u zeker dat u terug wilt gaan?'
            : 'You have unsaved work. Are you sure you want to leave the page?'
        )
      ) {
        Navigation.pop(componentId)
      } else {
        // Do nothing!
      }
    } else {
      Navigation.pop(componentId)
    }
  }, [componentId, changed, language])
  const changeLines = useCallback(
    lines => {
      onSet({
        lines,
      })
      setChanged(true)
    },
    [onSet]
  )
  // const registerAdvice = useCallback(() => {
  //   push(componentId, {
  //     component: {
  //       name: routes.NEW_REGISTRATION_ROUTE,
  //       passProps: {
  //         adviceId: object.id,
  //       },
  //     },
  //   })
  // }, [object, componentId])
  const crop = object && object.crop
  const title = (crop && crop.name) || '...'
  const createdAt =
    object && object.createdAt
      ? getFriendlyDate(fromTimestamp(object.createdAt))
      : ''
  const advisor =
    object && object.advisor && object.advisor.first_name !== '' && !isAdvisor
      ? [
          'door',
          object.advisor.first_name,
          object.advisor.preposition,
          object.advisor.last_name,
        ]
          .filter(n => n)
          .join(' ')
      : ''
  console.log({ object })
  return (
    <>
      <AppbarHeader
        key="header"
        dark
        style={{ elevation: 0, justifyContent: 'center' }}
      >
        <AppbarResizer>
          <Appbar.BackAction onPress={back} color="#fff" />
          <Appbar.Content
            title={`Advies voor ${title} - ${createdAt} ${advisor}`}
            color="#fff"
          />

          {object && object.url && !loading ? (
            <Appbar.Action
              icon={'download'}
              onPress={() => {
                if (Platform.OS === 'web') {
                  window.open(object.url, '_blank')
                } else {
                  Linking.openURL(object.url)
                }
              }}
              color="#fff"
            />
          ) : null}
          {object && object.id && !loading && isAdvisor ? (
            <Appbar.Action icon={'delete'} onPress={onRemove} color="#fff" />
          ) : null}
          {loading || updating || removing || creating ? (
            <ActivityIndicator style={{ padding: 12 }} color={'#FFF'} />
          ) : isAdvisor ? (
            <Appbar.Action icon={'check'} onPress={onSubmit} color="#fff" />
          ) : null}
        </AppbarResizer>
      </AppbarHeader>

      <View style={styles.formContainer}>
        <KeyboardAvoidingScrollView safeSettings={{ bottom: true }}>
          <View style={styles.formInner}>
            <FormContainer onSubmit={onSubmit}>
              {loadingError ||
              updatingError ||
              removingError ||
              creatingError ? (
                <Text>
                  {loadingErrorMessage ||
                    updatingErrorMessage ||
                    removingErrorMessage ||
                    creatingErrorMessage}
                </Text>
              ) : null}

              <View style={styles.fieldsMapContainer}>
                <FieldsMap
                  selectedField={selectedField}
                  componentId={componentId}
                  headerIcon={''}
                  loaded={loaded}
                  editable={isAdvisor}
                  fullScreen={false}
                  loadOnlyWhenCenter
                  hasReducerZoom
                  noCurrent
                />
              </View>

              {object && object.growingPeriod && (
                <GrowingPeriods
                  value={[object.growingPeriod]}
                  editable={false}
                />
              )}

              <View style={styles.whiteSpace} />
              <AdviceLines
                onChange={changeLines}
                value={(object && object.lines) || []}
                editable={isAdvisor}
              />
            </FormContainer>
          </View>
          <View style={{ height: 56 }} />
        </KeyboardAvoidingScrollView>
        {/*<BottomFab*/}
        {/*  style={[styles.fab]}*/}
        {/*  label={'Advies registreren'}*/}
        {/*  icon="file-check-outline"*/}
        {/*  onPress={registerAdvice}*/}
        {/*  type="submit"*/}
        {/*  loading={creating}*/}
        {/*/>*/}
      </View>
    </>
  )
}
//eslint-disable-next-line
const styles = StyleSheet.create({
  padding: { padding: 12 },
  whiteSpace: { height: 56 },
  formContainer: {
    backgroundColor: '#fff',
    zIndex: 100,
    flex: 1,
  },
  formInner: {
    maxWidth: 600,
    alignSelf: 'center',
    width: '100%',
  },
  fieldsMapContainer: {
    height: 350,
    borderRadius: 8,
    margin: 16,
    overflow: 'hidden',
  },
})

const mapStateToProps = state => ({
  crud: state.advice.listAndCrud.crud,
  auth: state.auth,
  selectedField:
    state.advice.listAndCrud.crud.object &&
    state.advice.listAndCrud.crud.object.growingPeriod &&
    state.advice.listAndCrud.crud.object.growingPeriod.organizationField &&
    state.advice.listAndCrud.crud.object.growingPeriod.organizationField.field,
})

export default safeAreaHOC(
  detailHOC(AdviceDetailScreen, mapStateToProps, actions, {
    optinEdit: true,
    enableDelete: true,
  })
)
