import React, { useEffect, useState } from 'react' // useCallback, useState
import Fields from '../../Form/Fields'
import FormContainer from '../../Form/FormContainer'
import { connect, useDispatch, useSelector } from 'react-redux'
import Resizer from '../../WidthAndHeight/Resizer'
import * as a from './actions'
import { Translate } from 'react-translated'
import { getLanguage, getTranslatedString } from '../../language'
import RadioButtonGroup from 'react-native-paper/src/components/RadioButton/RadioButtonGroup'
import RadioButtonWithLabel from '../../components/RadioButtonWithLabel'
import { Title } from 'react-native-paper'

const fieldProps = {
  style: { marginBottom: 16 },
}
const fields = lang => [
  {
    name: 'name',
    label: getTranslatedString('name', lang),
    type: 'text',
    fieldProps,
  },
]
const options = lang => [
  { name: getTranslatedString('integrated', lang), value: 'INTEGRATED' },
  { name: getTranslatedString('biological', lang), value: 'ORGANIC' },
  { name: getTranslatedString('chemical', lang), value: 'CONVENTIONAL' },
]

function OrganizationTab({
  onSubmit,
  crud: { object, loaded },
  organizationId,
}) {
  const crud = useSelector(state => state.farmer.organization.crud.object)
  const [value, setValue] = useState(
    crud && crud.settings && crud.settings.cultivationType
  )

  useEffect(() => {
    if (crud && crud.settings && crud.settings.cultivationType) {
      setValue(crud && crud.settings && crud.settings.cultivationType)
    }
  }, [crud])

  const dispatch = useDispatch()

  useEffect(() => {
    if (organizationId && !loaded) {
      dispatch(a.default.load({ id: organizationId }))
    }
  }, [organizationId, dispatch, loaded])

  const onChange = (key, val) => {
    dispatch(a.default.setData({ ...crud, [key]: val }))
  }

  const change = val => {
    setValue(val)
    onChange('settings', { ...((crud && crud.settings) || {}), cultivationType: val })
  }

  const language = getLanguage()

  return (
    <Resizer>
      <FormContainer onSubmit={onSubmit}>
        <Fields
          fields={fields(language)}
          values={object}
          onChange={onChange}
          onSubmit={onSubmit}
        />
        <Title>
          <Translate text={'growingStrategy'} />
        </Title>
        <RadioButtonGroup value={value}>
          {options(language).map(opt => {
            return (
              <RadioButtonWithLabel
                checked={value === opt.value}
                value={opt.value}
                label={opt.name}
                onChangeValue={() => change(opt.value)}
              />
            )
          })}
        </RadioButtonGroup>
      </FormContainer>
    </Resizer>
  )
}

const mapStateToProps = state => ({
  crud: state.farmer.organization.crud,
})

export default connect(mapStateToProps, a)(OrganizationTab)
