import actions from './actions'
import reducer from '../../GeneralListFunctions/reducerFactory'

export interface SprayModeType {
  id: string
  name: string
  exceptAttributes: SprayModeType[]
}

export default reducer<SprayModeType>(actions)
