import React, { Component } from 'react'
import { Keyboard, Platform, StyleSheet, View } from 'react-native'
import PT from 'prop-types'
import AcceptTerms from './AcceptTerms'
import { pushCustomEvent } from '../Analytics/abstraction'
import { CustomTabs } from 'react-native-custom-tabs'
// import Header from '../components/header/Header'
import { connect } from 'react-redux'
import { Button, Divider, Text } from 'react-native-paper'
import loginHOC from 'react-auth/lib/hocs/loginHOC'
import { getLoginPage } from 'react-auth/lib/promises'
import { getRequestCodeFromHTML } from 'react-auth/lib/utils'
import { clientRedirectUri } from 'react-auth/lib/config'
import { authPath } from 'react-api'
import SafariView from './SafariView'
import c from '../constants'
import { toggle } from './actions'
import Agro4allLogo from '../vectors/Agro4allLogo'
import AppbarHeader from '../WidthAndHeight/AppbarHeader'
import { push } from '../Navigation'
import { FORGOT_PASSWORD_ROUTE, REGISTER_SCREEN_ROUTE } from '../Routes.config'
import Fields from '../Form/Fields'
import DeepLink from '../DeepLink'
import FormContainer from '../Form/FormContainer'
import ErrorMessages from '../components/ErrorMessages'
import { Translate, Translator } from 'react-translated'
// import Config from 'react-native-config'
import a from './providerLogic/actions'

const styles = StyleSheet.create({
  loginContainer: {
    flex: 1,
    maxWidth: 700,
    width: '100%',
    alignSelf: 'center',
  },
  checkboxContainer: {
    marginTop: 8,
    marginBottom: 8,
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center',
    flex: 1,
  },
  checkboxLabel: {},
  loginForm: {
    maxWidth: 400,
    width: '100%',
    alignSelf: 'center',
    padding: 10,
    paddingTop: 60,
  },

  buttonBox: {
    marginTop: 16,
  },
  buttonBoxSmall: {},
})

const fieldProps = {
  style: { marginBottom: 16 },
}
const fields = translate => {
  return [
    {
      name: 'login',
      label: translate({ text: 'email' }),
      type: 'email',
      fieldProps: { ...fieldProps, autoFocus: true },
    },
  ]
}

const secondFields = translate => {
  return [
    {
      name: 'password',
      label: translate({ text: 'password' }),
      type: 'password',
      fieldProps: { ...fieldProps },
    },
  ]
}
class SignIn extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errors: [],
      showPassword: false,
      hideErrors: true,
      externalLogin: {
        microsoft: {
          loading: false,
        },
      },
    }
  }

  _loginWithConnector = connector => {
    this.setState({
      externalLogin: {
        [connector]: {
          loading: true,
        },
      },
    })
    console.log('micro')
    getLoginPage(clientRedirectUri)
      .then(response => {
        console.log('microresponse', response)
        const reqId = getRequestCodeFromHTML(response)

        const realPath = authPath

        const url = `${realPath}auth/${connector}?req=${reqId}`
        if (Platform.OS === 'web') {
          window.open(url, '_self')
        }

        if (Platform.OS === 'android') {
          CustomTabs.openURL(url, {
            toolbarColor: c.greenColor,
            showPageTitle: true,
            forceCloseOnRedirection: true,
          }).catch(err => {
            this.setState({
              errors: ['Chrome browser niet geïnstalleerd'],
            })
          })
        }

        if (Platform.OS === 'ios') {
          SafariView.isAvailable()
            .then(available => {
              SafariView.show({
                url,
                tintColor: '#fff',
                barTintColor: c.greenColor,
              })
            })
            .catch(error => {
              this.setState({
                errors: ['Safari niet geïnstalleerd'],
              })
            })
        }
      })
      .catch(error => {
        this.setState({
          errors: [error],
        })
      })
  }

  componentDidUpdate(prevProps, prevState) {
    console.log({ prevProps, props: this.props })
    if (
      this.props.provider &&
      !prevProps.provider &&
      this.props.provider?.providers &&
      this.props.provider?.providers?.length > 0
    ) {
      console.log({ prevProps, props: this.props })
      if (this.props.provider?.providers?.some(pro => pro === 'LOCAL')) {
        this.setState({
          showPassword: true,
        })
      } else {
        // TODO: MICROSOFT
        console.log('go with micro soft')

        // This is also called on native when registering TODO: fix this?,
        // so we check if the email address is set on the login screen.
        // Because this email field is cleared when pushing the request account screen
        if (this.props.values?.login) {
          this._loginWithConnector('microsoft')
        }
      }
    }
    if (this.props.providerError && !prevProps.providerError) {
      this.setState({
        errors: [this.props.providerErrorMessage],
        hideErrors: false,
      })
    }
  }

  _change = (name, value) => {
    if (name === 'login') {
      // reset password state, because email address was changed
      this.setState({
        showPassword: false,
      })
    }
    this.setState({
      hideErrors: true,
    })
    this.props.onChangeField(name, value)
  }

  _submit = e => {
    if (!this.state.showPassword) {
      this.props.dispatch(
        a.loadList({
          disableAuthentication: true,
          params: {
            email: this.props.values?.login?.includes('+')
              ? 'local@local.com'
              : this.props.values?.login,
          },
        })
      )
      return
    }
    this.setState({
      hideErrors: false,
    })
    // if (e && Platform.OS === 'web') {
    //   e.preventDefault()
    // }
    Keyboard.dismiss()

    // alert(JSON.stringify(Config))
    this.props.onSubmit()
  }
  render() {
    const { sending, acceptedTerms, error } = this.props
    const code = '' //this.props.navigation.getParam('code', '')
    // alert(JSON.stringify(this.state.errors))
    if (!acceptedTerms && !(code.length > 3)) {
      return [
        <AcceptTerms
          key="accept"
          componentId={this.props.componentId}
          onSubmit={this.props.toggle}
        />,
        <DeepLink
          key="deep-link"
          componentId={this.props.componentId}
          loggedIn={false}
        />,
      ]
    }
    return [
      <AppbarHeader
        key="header"
        style={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Agro4allLogo />
      </AppbarHeader>,
      <View style={styles.loginContainer} key="content">
        {/* <Header logo /> */}
        <View style={styles.loginForm}>
          <FormContainer key="form-container" onSubmit={this._submit}>
            <Translator>
              {({ translate }) => (
                <>
                  <Fields
                    fields={fields(translate) || []}
                    values={this.props.values || {}}
                    onChange={this._change}
                    onSubmit={this._submit}
                  />
                  {this.state.showPassword && (
                    <>
                      <Fields
                        fields={secondFields(translate) || []}
                        values={this.props.values || {}}
                        onChange={this._change}
                        onSubmit={this._submit}
                      />
                      <View
                        style={{
                          marginBottom: 16,
                          alignItems: 'flex-end',
                        }}
                      >
                        <Text
                          uppercase={false}
                          // mode={'contained'}
                          style={{
                            color: '#8e8e8e',
                            cursor: 'pointer',
                            textDecorationColor: '#8e8e8e',
                            textDecorationLine: 'underline',
                          }}
                          onPress={() => {
                            push(this.props.componentId, {
                              component: {
                                name: FORGOT_PASSWORD_ROUTE,
                              },
                            })
                          }}
                        >
                          <Translate text={'forgotPassword'} />?
                        </Text>
                      </View>
                    </>
                  )}

                  {!this.state.hideErrors && (
                    <ErrorMessages
                      messages={[...this.state.errors, error]
                        .filter(n => !!n)
                        .map(n =>
                          n === 'Geen gebruikersnaam en wachtwoord ingevuld'
                            ? translate({ text: 'noUserOrPass' })
                            : n
                        )}
                    />
                  )}
                </>
              )}
            </Translator>
            <View style={styles.buttonBox}>
              <Button
                loading={sending || this.props.providerLoading}
                onPress={this._submit}
                accessibilityTraits={'button'}
                accessibilityComponentType={'button'}
                accessibilityRole={'button'}
                testID={'submitLogin'}
                mode={'contained'}
                uppercase={false}
                dark={true}
              >
                <Text style={{ color: '#fff' }}>
                  {!this.state.showPassword ? (
                    <Translate text={'next'} />
                  ) : sending ? (
                    <Translate text={'loggingIn'} />
                  ) : (
                    <Translate text={'login'} />
                  )}
                </Text>
              </Button>
            </View>
            <View style={{ margin: 16 }}></View>
            <View
              style={{ flexDirection: 'row', display: 'flex', flexGrow: 1 }}
            >
              <Divider
                style={{
                  flex: 1,
                  marginTop: 10,
                  color: '#8e8e8e',
                  height: 1,
                }}
              />
              <Text
                style={{
                  marginLeft: 12,
                  marginRight: 12,
                  color: '#8e8e8e',
                }}
              >
                <Translate text={'noAccount'} />
              </Text>
              <Divider
                style={{
                  flex: 1,
                  marginTop: 10,
                  color: '#8e8e8e',
                  height: 1,
                  opacity: 1,
                }}
              />
            </View>
            <View style={{ margin: 16 }}></View>
            <View style={styles.buttonBoxSmall}>
              <Button
                // mode={'outlined'}
                uppercase={false}
                mode={'outlined'}
                onPress={() => {
                  // Nieuwe accountaanvraag via knop.
                  pushCustomEvent(`Pressed account request form button`, ``)
                  this._change('login', '')
                  push(this.props.componentId, {
                    component: {
                      name: REGISTER_SCREEN_ROUTE,
                    },
                  })
                }}
                theme={{
                  colors: {
                    text: '#000',
                  },
                }}
              >
                <Translate text={'requestAccount'} />
              </Button>
            </View>
          </FormContainer>
        </View>
      </View>,
      <DeepLink
        key="deep-link"
        componentId={this.props.componentId}
        loggedIn={false}
      />,
    ]
  }
}

SignIn.defaultProps = {
  errors: undefined,
  fields: [],
}
SignIn.propTypes = {
  onSubmit: PT.func.isRequired,
  onChangeField: PT.func.isRequired,
  sending: PT.bool.isRequired,
  errors: PT.arrayOf(
    PT.shape({
      message: PT.string.isRequired,
    })
  ),
  // fields: PT.object,
}

const mapStateToProps = state => ({
  provider: state.provider.list.listGetter,
  providerLoading: state.provider.list.loadingList,
  providerError: state.provider.list.loadingListError,
  providerErrorMessage: state.provider.list.loadingListErrorMessage,
  acceptedTerms: state.authSettings,
})
export default loginHOC(connect(mapStateToProps, { toggle })(SignIn))
