import actions from './actions'
import reducer from '../../GeneralListFunctions/reducerFactory'

export interface AdvisorFarmerType {
  id: string
  name: string
  email: string
}

export default reducer<AdvisorFarmerType>(actions)
