import React, { useCallback, useEffect, useState } from 'react'
import { Platform, StyleSheet, TouchableHighlight, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import actions from '../actions'
import FieldsMap from '../../FieldMap/FieldsMap'
import { Portal, Text, Title } from 'react-native-paper'
import round from '../../helpers/round'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import { Translate } from 'react-translated'
import { usePrevious } from '../../Auth/ChangePassword'

const styles = StyleSheet.create({
  customModal: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 5,
    elevation: 1,
    backgroundColor: '#fff',

    zIndex: 210,
    bottom: 0,
    right: 0,
    left: 0,
    height: 150,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    maxWidth: 500,
    width: '100%',
  },
  closeButton: {
    padding: 10,
    paddingRight: 15,
    paddingLeft: 15,
    fontSize: 20,
  },
  title: {
    margin: 10,
    marginTop: 5,
    flex: 1,
  },
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
})
function LocationScreen({
  setShowFieldInfo,
  showFieldInfo,
  setData,
  save,
  showDrawMenu,
  componentId,
  prev,
}) {
  const dispatch = useDispatch()
  const [selectedField, setSelectedField] = useState({})
  const close = useCallback(() => {
    setShowFieldInfo(false)
  }, [setShowFieldInfo])
  const esc = useCallback(
    e => {
      if (e.keyCode === 27) {
        close()
      }
    },
    [close]
  )
  const authenticatedOrganization = useSelector(
    state => state.auth.user.organization
  )
  const selectedOrganization = useSelector(
    state => state.filter.advisorFarmer.selected
  )
  const object = useSelector(
    state =>
      state.organizationField.crud.object && state.organizationField.crud.object
  )
  const freshlyDrawnField = useSelector(
    state =>
      state.organizationField.crud.object &&
      state.organizationField.crud.object.freshlyDrawnField
  )
  const selectField = useCallback(
    (propsField, isCustomField) => {
      console.log({ propsField })
      let field = propsField
      let customField
      if (isCustomField) {
        customField = propsField
        field = { id: null }
      }
      if (selectedField && selectedField.id === field.id && !!showFieldInfo) {
        close()
      }

      if (isCustomField) {
        let customFieldField = { ...customField?.fields[0] }
        const customFieldFields = customField?.fields || []
        customFieldFields.forEach(
          cf => (customFieldField.area = customFieldField?.area + cf?.area)
        )
        setSelectedField(customFieldField)
        setShowFieldInfo(true)
        dispatch(
          actions.setData({
            ...customFieldField,
            field: customFieldField,
            organization: selectedOrganization
              ? selectedOrganization[0]
              : authenticatedOrganization,
          })
        )
      } else {
        setSelectedField(field)
        setShowFieldInfo(true)
        dispatch(
          actions.setData({
            ...field,
            field: field,
            organization: selectedOrganization
              ? selectedOrganization[0]
              : authenticatedOrganization,
          })
        )
      }
    },
    [
      authenticatedOrganization,
      selectedOrganization,
      close,
      dispatch,
      selectedField,
      showFieldInfo,
      setShowFieldInfo,
    ]
  )
  useEffect(() => {
    if (Platform.OS === 'web') {
      document.addEventListener('keydown', esc, false)
    }
    return () => {
      if (Platform.OS === 'web') {
        document.removeEventListener('keydown', esc, false)
      }
    }
  }, [esc])
  const previousFreshlyDrawnField = usePrevious(freshlyDrawnField)
  useEffect(() => {
    if (
      freshlyDrawnField &&
      previousFreshlyDrawnField !== freshlyDrawnField &&
      save
    ) {
      selectField(freshlyDrawnField)
      setShowFieldInfo(true)
    }
  }, [
    freshlyDrawnField,
    save,
    previousFreshlyDrawnField,
    selectField,
    setShowFieldInfo,
  ])
  return (
    <>
      <FieldsMap
        onPressField={selectField}
        selectedField={object}
        alreadySelectedFields={[]}
        componentId={componentId}
        onHeaderIconPress={prev}
        headerIcon={'arrow-left'}
        loaded={object}
        showDrawMenu={showDrawMenu}
        save={save}
        portalActive={showFieldInfo}
      />

      {showFieldInfo && (
        <>
          <Portal>
            <View pointerEvents="box-none" style={styles.container}>
              <View style={styles.customModal}>
                <View
                  style={{
                    flexDirection: 'row',
                  }}
                >
                  <View style={styles.title}>
                    <Title>
                      <Translate text="parcelInfo" />
                    </Title>
                  </View>
                  <TouchableHighlight
                    onPress={close}
                    accessibilityTraits={'button'}
                    accessibilityComponentType={'button'}
                    accessibilityRole={'button'}
                    // style={{ flex: 1 }}
                    underlayColor={'rgba(0,0,0,0.3)'}
                  >
                    <Icon
                      name="close"
                      style={styles.closeButton}
                      color={'#000'}
                    />
                  </TouchableHighlight>
                </View>
                <View style={{ margin: 10, marginTop: 5 }}>
                  <View style={{ flexDirection: 'row' }}>
                    <Text>
                      <Translate text="area" />:{' '}
                    </Text>
                    <Text style={{ fontWeight: 'bold' }}>
                      {round((selectedField?.area || 0) / 10000, 3)} ha
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </Portal>
        </>
      )}
    </>
  )
}

export default LocationScreen
