import React, { Component } from 'react'
import Icon from '../../img/webp/pinpoint.webp'

import { GeoJSONLayer, Image } from 'react-mapbox-gl'

class Point extends Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.id !== nextProps.id) {
      return true
    }
    if (this.props.fillColor !== nextProps.fillColor) {
      return true
    }
    if (this.props.fillOpacity !== nextProps.fillOpacity) {
      return true
    }
    if (this.props.shape !== nextProps.shape) {
      return true
    }
    return false
  }

  render() {
    const { shape } = this.props
    return (
      <>
        <Image id={'point-image-1'} url={Icon} sdf={true} />

        <GeoJSONLayer
          data={{
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [shape[0].longitude, shape[0].latitude],
                },
                properties: {
                  icon: 'point-image-1',
                },
              },
            ],
          }}
          type={'geoJson'}
          symbolLayout={{
            'icon-image': 'point-image-1',
            'icon-allow-overlap': true,
            'icon-size': 0.5,
            'icon-anchor': 'bottom',
          }}
        />
      </>
    )
  }
}

Point.defaultProps = {
  // belowLayerID: 'tunnel-oneway-arrows-blue-minor',
}
export default Point
