import constants from "../constants";

const openWebPage = ({url}) => {
  window.open(
    url,
    '_blank'
  )
}

export const openEppoPage = ({code}) => {
  openWebPage({url: `${constants.eppoUrl}${code}`})
}
