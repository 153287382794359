import React from 'react'
import { useSelector } from 'react-redux'
import actions from './actions'
import GeneralList from '../../GeneralListFunctions/GeneralList'
import ItemInList from '../ItemInList'
import FilterHeader from '../FilterHeader'
import Navigation from '../../Navigation'
import { RootState } from '../../reducers'
import { LocationType } from './reducer'
import { useActionsAsFunctions } from '../../helpers/actions'

type MannerListPropsType = {
  componentId: string
  filterType: any
}

function LocationList({ componentId, filterType }: MannerListPropsType) {
  const list = useSelector((state: RootState) => state.filter.location.list)
  const selected = useSelector(
    (state: RootState) => state.filter.location.selected
  )
  const actionAsDispatchers = useActionsAsFunctions(actions)

  const getParams = (ignore: any, inputSearch?: string) => {
    let search = inputSearch
    if (search === undefined) {
      search = list.params && list.params.search
    }

    let params = {
      ...list.params,
      sort: 'name',
      search,
      name: search,
      type: 'cultivation_method',
      onlyExceptAttributes: true,
    }

    return params
  }
  const selectFunc = (item: LocationType) => {
    const exceptItem =
      item?.exceptAttributes && item?.exceptAttributes.length > 0
        ? item?.exceptAttributes[0]
        : {}
    actionAsDispatchers.select(item, { removeOther: exceptItem })
  }
  const renderItem = ({ item }: { item: any }) => {
    return (
      <ItemInList
        onPress={selectFunc}
        onSelect={selectFunc}
        item={item}
        filterType={filterType}
        isSelected={selected.some(it => it.id === item.id)}
        selectType={'checkbox'}
      />
    )
  }
  const pop = () => {
    Navigation.pop(componentId)
  }

  return (
    <>
      {/* @ts-ignore*/}
      <GeneralList
        list={list}
        {...actionAsDispatchers}
        searchPlaceholder={filterType.searchPlaceholder}
        renderItem={renderItem}
        icon
        appbarContent={
          <FilterHeader filterType={filterType} componentId={componentId} />
        }
        getParams={getParams}
        onPressFab={pop}
      />
    </>
  )
}

export default LocationList
