import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ScrollView, StyleSheet, View } from 'react-native'
import { Snackbar, Text } from 'react-native-paper'
import { RootState } from '../reducers'
import { dismiss } from './actions'
import SimpleSwipableModal from './SimpleSwipableModal'
import { RestrictionType } from '../Products/ItemNoteIcons'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import c from '../constants'

const styles = StyleSheet.create({
  text: {
    fontSize: 16,
    color: '#565656',
    padding: 24,
  },
  textWhite: {
    fontSize: 16,
    color: '#FFF',
  },
  restrictionItem: {
    padding: 12,
    marginTop: 24,
  },
  restrictionItemInner: {},
  restrictionItemLeft: {},
  restrictionValue: { fontSize: 16, fontWeight: 'bold' },
  conditionValue: { flexDirection: 'row', alignItems: 'center', marginTop: 6 },
  conditionValueText: { fontSize: 16 },

  iconContainer: {
    width: 30,
  },
  icon: {},
  iconUnknown: {},
  iconTrue: {
    color: c.greenColor,
  },
  iconFalse: {
    color: 'red',
  },
})

function NotificationModal() {
  const dispatch = useDispatch()
  const open = useSelector((state: RootState) => state.notification.open)
  const openNotifications = useSelector(
    (state: RootState) => state.notification.openNotifications
  )
  const messages = useSelector(
    (state: RootState) => state.notification.messages
  )
  const notifications = useSelector(
    (state: RootState) => state.notification.notifications
  )
  const restrictionSummary = useSelector(
    (state: RootState) => state.notification.restrictionSummary
  )

  const restrictions = restrictionSummary?.restrictions || []
  return (
    <>
      {notifications.length > 0 && (
        <Snackbar
          style={{ marginBottom: 32 }}
          visible={openNotifications}
          onDismiss={() => dispatch(dismiss())}
          action={{
            label: 'OK',
            labelStyle: styles.textWhite,
            onPress: () => {
              dispatch(dismiss())
            },
          }}
        >
          <Text
            onPressIn={undefined}
            onPressOut={undefined}
            android_hyphenationFrequency={undefined}
            style={styles.textWhite}
            onTextLayout={undefined}
            dataDetectorType={undefined}
          >
            {notifications.map((message, i) => {
              let fullText = ''
              let bulletPoints = []
              if (message[0].includes('•')) {
                fullText = message[0].split('•')[0].trim()
                bulletPoints = message[0].split('•')
                bulletPoints.shift()
                let itemString = ''
                bulletPoints.length > 0 &&
                  bulletPoints.forEach(bp => {
                    itemString += `• ${
                      bp.charAt(1).toUpperCase() + bp.slice(2)
                    }\n`
                  })
                // console.log(items)
                return `${fullText}\n${itemString}`
              }
              return `${i === 0 ? '' : '\n\n'}${message}`
            })}
          </Text>
        </Snackbar>
      )}
      <SimpleSwipableModal
        isVisible={open}
        onRequestClose={() => dispatch(dismiss())}
        withGutter={false}
      >
        <ScrollView>
          {messages.length > 0 && (
            <Text
              onPressIn={undefined}
              onPressOut={undefined}
              android_hyphenationFrequency={undefined}
              style={styles.text}
              onTextLayout={undefined}
              dataDetectorType={undefined}
            >
              {messages.map((message, i) => {
                let fullText = ''
                let bulletPoints = []
                if (message[0].includes('•')) {
                  fullText = message[0].split('•')[0].trim()
                  bulletPoints = message[0].split('•')
                  bulletPoints.shift()
                  let itemString = ''
                  bulletPoints.length > 0 &&
                    bulletPoints.forEach(bp => {
                      itemString += `• ${
                        bp.charAt(1).toUpperCase() + bp.slice(2)
                      }\n`
                    })
                  // console.log(items)
                  return `${fullText}\n${itemString}`
                }
                return `${i === 0 ? '' : '\n\n'}${message}`
              })}
            </Text>
          )}

          {restrictions.map((restriction, i) => (
            <Restriction key={i} restriction={restriction!} />
          ))}
        </ScrollView>
      </SimpleSwipableModal>
    </>
  )
}

const groupedStatusIcon: { [key: string]: string } = {
  true: 'checkbox-marked-circle',
  false: 'close-circle',
  unknown: 'help-circle',
}
const groupedStatusStyles: { [key: string]: any } = {
  true: styles.iconTrue,
  false: styles.iconFalse,
  unknown: styles.iconUnknown,
}

function Restriction({ restriction }: { restriction: RestrictionType }) {
  return (
    <View style={styles.restrictionItem}>
      {(restriction.generated?.restrictionValues || []).map(v => (
        <Text
          onPressIn={undefined}
          onPressOut={undefined}
          android_hyphenationFrequency={undefined}
          key={v}
          style={styles.restrictionValue}
          selectable={true}
          onTextLayout={undefined}
          dataDetectorType={undefined}
        >
          {v}
        </Text>
      ))}
      <View style={styles.restrictionItemInner}>
        {(restriction.generated?.conditionsValues || []).map((v, i) => (
          <View key={i} style={styles.conditionValue}>
            <View style={styles.iconContainer}>
              <Icon
                name={groupedStatusIcon[v.status]}
                style={[styles.icon, groupedStatusStyles[v.status]]}
                size={20}
              />
            </View>

            <Text
              onPressIn={undefined}
              onPressOut={undefined}
              android_hyphenationFrequency={undefined}
              style={styles.conditionValueText}
              selectable={true}
              onTextLayout={undefined}
              dataDetectorType={undefined}
            >
              {v.text}
            </Text>
          </View>
        ))}
      </View>
    </View>
  )
}

export default NotificationModal
