import React from 'react'
import Svg from './SvgAbstraction'
import Path from './PathAbstraction'

import { color, defaultProps, propTypes } from './standardVector'

export const width = 24
export const height = 24

const Vector = props => (
  <Svg
    width={props.width || width}
    height={props.height || height}
    className={props.className}
    viewBox="0 0 24 24"
  >
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M0.750 12.000 A11.250 11.250 0 1 0 23.250 12.000 A11.250 11.250 0 1 0 0.750 12.000 Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M4.045 19.955L19.955 4.045"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </Svg>
)

Vector.propTypes = propTypes
Vector.defaultProps = defaultProps

export default Vector
