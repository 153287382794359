import React from 'react'
import { Button, IconButton, List, Text } from 'react-native-paper'
import Resizer from '../WidthAndHeight/Resizer'
import { Platform, View } from 'react-native'
import Checkbox from '../components/Checkbox'
import { push } from '../Navigation'
import * as routes from '../Routes.config'
import { getTotalAreaOfField } from '../helpers/area'

// import Touchable from '../WidthAndHeight/Touchable'

export function findActiveGrowingPeriod(growingPeriods) {
  const now = new Date().getTime()
  const activeGrowingPeriod = growingPeriods?.find(growingPeriod => {
    return (
      new Date(growingPeriod?.startDate).getTime() <= now &&
      new Date(growingPeriod?.endDate).getTime() >= now
    )
  })
  return activeGrowingPeriod
}

export default function FieldListItem({
  item,
  selectSection,
  selectedSections,
  componentId,
}) {
  const { name } = item
  const [open, setOpen] = React.useState(false)
  const totalArea = getTotalAreaOfField(item || {})

  console.log({ name, totalArea })
  const len = item?.sections?.length
  const nam = item?.sections?.[0]?.name?.includes('Vak')
    ? 'Vakken'
    : item?.sections?.[0]?.name?.includes('Kap')
    ? 'Kappen'
    : item?.sections?.[0]?.name?.includes('Bed')
    ? 'Bedden'
    : item?.sections?.[0]?.name?.includes('Rij')
    ? 'Rijen'
    : item?.sections?.[0]?.name?.includes('Tafel')
    ? 'Tafels'
    : item?.sections?.[0]?.name?.includes('Vloer')
    ? 'Vloeren'
    : 'Vak'
  console.log(`${len} ${nam}`)
  // let description = (crop ? crop.name : '') + (variety ? ` ${variety}` : '')
  return (
    <Resizer key={item?.id}>
      <List.Accordion
        title={name}
        expanded={open}
        style={{ margin: 0, padding: 0 }}
        onPress={() => setOpen(!open)}
        left={() => (
          <>
            {open ? (
              <IconButton icon={'chevron-up'} onPress={() => setOpen(!open)} />
            ) : (
              <IconButton
                icon={'chevron-down'}
                onPress={() => setOpen(!open)}
              />
            )}
          </>
        )}
        right={
          totalArea
            ? () => (
                <View
                  pointerEvents={'box-none'}
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    zIndex: 199,
                  }}
                >
                  <Text>{totalArea} ha</Text>
                  {Platform.OS === 'web' && (
                    <IconButton
                      icon={'pencil'}
                      onPress={() => {
                        push(componentId, {
                          component: {
                            name: routes.FIELD_DETAIL_ROUTE,
                            passProps: {
                              id: item?.id,
                            },
                          },
                        })
                      }}
                      style={{ marginLeft: 6, zIndex: 199 }}
                    />
                  )}
                </View>
              )
            : () => (
                <View
                  pointerEvents={'box-none'}
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    zIndex: 199,
                  }}
                >
                  {Platform.OS === 'web' && (
                    <IconButton
                      icon={'pencil'}
                      onPress={() => {
                        push(componentId, {
                          component: {
                            name: routes.FIELD_DETAIL_ROUTE,
                            passProps: {
                              id: item?.id,
                            },
                          },
                        })
                      }}
                      style={{ marginLeft: 6, zIndex: 199 }}
                    />
                  )}
                </View>
              )
        }
        expandable={item?.sections?.length > 0}
        description={
          item?.sections?.length > 0 ? `${len} ${nam}` : 'Geen onderverdeling'
        }
      >
        {Platform.OS !== 'web' && (
          <Button
            mode={'outlined'}
            icon={'pencil'}
            onPress={() => {
              push(componentId, {
                component: {
                  name: routes.FIELD_DETAIL_ROUTE,
                  passProps: {
                    id: item?.id,
                  },
                },
              })
            }}
            style={{
              // zIndex: 199,
              // float: 'center',
              marginLeft: 20,
              marginRight: 20,
              marginTop: 6,
              marginBottom: 6,
              // alignSelf: 'center',
              width: '100%',
            }}
          >
            Bewerken of bekijken
          </Button>
        )}
        {item?.sections?.map(section => {
          const activeGrowingPeriod = findActiveGrowingPeriod(
            section?.growingPeriods?.map(gp => {
              return {
                ...gp,
                startDate: gp?.startDate * 1000 || new Date().getTime(),
                endDate: gp?.endDate * 1000 || new Date().getTime(),
              }
            })
          )
          return (
            <List.Item
              key={section?.id}
              title={section?.name}
              description={`${activeGrowingPeriod?.crop?.name}${
                activeGrowingPeriod?.variety
                  ? ` - ${activeGrowingPeriod?.variety}`
                  : ''
              }`}
              onPress={() => {
                selectSection({
                  ...section,
                  growingPeriods: section?.growingPeriods?.map(gp => {
                    return {
                      ...gp,
                      startDate: gp?.startDate * 1000 || new Date().getTime(),
                      endDate: gp?.endDate * 1000 || new Date().getTime(),
                    }
                  }),
                })
              }}
              left={() => (
                <Checkbox
                  style={{ marginTop: 4, marginRight: 4 }}
                  status={
                    selectedSections?.some(selSec => selSec?.id === section?.id)
                      ? 'checked'
                      : 'unchecked'
                  }
                  // onPress={() => {
                  //   console.log('SELECT CHECK, ', section)
                  // }}
                />
              )}
            />
          )
        })}
      </List.Accordion>
    </Resizer>
  )
}
