import * as React from 'react'
import DatePicker from '../../components/DatePicker'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../reducers'
import * as a from './actions'
import safeAreaHOC from '../../WidthAndHeight/safeAreaHOC'
import widthAndHeightHOC from '../../WidthAndHeight/widthAndHeightHOC'
import dayjs from 'dayjs'

function SprayDate({ componentId }: { componentId: string }) {
  const dispatch = useDispatch()
  const { date, open } = useSelector(
    (state: RootState) => state.filter.sprayDate
  )

  const onClose = () => {
    dispatch(a.close())
  }

  const onConfirm = (d: number) => {
    dispatch(a.set(d, false))
  }

  return (
    <>
      {/*<AppbarHeader*/}
      {/*  key="header"*/}
      {/*  dark*/}
      {/*  style={{*/}
      {/*    justifyContent: 'center',*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <AppbarResizer>*/}
      {/*    <Appbar.BackAction onPress={onBack} color="#fff" testID={'back'} />*/}
      {/*    <Appbar.Content title={filterType && filterType.label} color="#fff" />*/}
      {/*  </AppbarResizer>*/}
      {/*</AppbarHeader>*/}
      {/*<KeyboardAvoidingScrollView*/}
      {/*  style={{ flex: 1 }}*/}
      {/*  safeSettings={safeSettings}*/}
      {/*  keyboardDismissMode="on-drag"*/}
      {/*  keyboardShouldPersistTaps="handled"*/}
      {/*  contentInsetAdjustmentBehavior="always"*/}
      {/*>*/}
      {/*  <Resizer>*/}
      <DatePicker
        onChange={onConfirm}
        onlyDatePicker
        isVisible={open}
        onClose={onClose}
        value={date ? date : dayjs(new Date()).unix() * 1000}
      />
      {/*</Resizer>*/}
      {/*// </KeyboardAvoidingScrollView>*/}
      {/*// <BottomFab onPress={onBack} label={'Klaar'} />*/}
    </>
  )
}
export default safeAreaHOC(widthAndHeightHOC(SprayDate))
