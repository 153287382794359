import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Appbar } from 'react-native-paper'
import GeneralList from '../../GeneralListFunctions/GeneralList'
import PageHeader from '../../PageHeader'
import Navigation, { push } from '../../Navigation'
import Touchable from '../../WidthAndHeight/Touchable'
import SelectionComponent from '../../components/SelectionComponent'
import { selectFieldWithGrowingPeriod } from './actions'
import * as routes from '../../Routes.config'

function getTitle({ organizationField: { name, id } }) {
  return name || `Teeltlocatie #${id}`
}
function getDescription({ organizationField: { organization } }) {
  return (organization || {}).name
}
const isSelected = (selected, item) => selected.some(it => it.id === item.id)

function AdviceFieldListItem({ item, onSelect, isSelected }) {
  return (
    <Touchable
      left={
        <SelectionComponent
          status={isSelected ? 'checked' : 'unchecked'}
          onPress={() => onSelect(item)}
          withPadding
        />
      }
      title={getTitle(item)}
      description={getDescription(item)}
      onPress={() => onSelect(item)}
    />
  )
}

export default function AdviceFieldsScreen({ componentId }) {
  const [search, setSearch] = useState(undefined)
  const dispatch = useDispatch()
  const selectedFieldsWithGrowingPeriod = useSelector(
    state => state.advice.extra.selectedFieldsWithGrowingPeriod
  )
  const selectedGroupedCrop = useSelector(
    state => state.advice.extra.selectedGroupedCrop || {}
  )
  const onPressAdvice = useCallback(() => {
    push(componentId, {
      component: {
        name: routes.ADVICE_NEW_PEST_ROUTE,
      },
    })
  }, [componentId])

  const onSearch = useCallback(value => setSearch(value), [setSearch])
  const onBack = useCallback(() => Navigation.pop(componentId), [componentId])
  const onSelect = useCallback(
    item => dispatch(selectFieldWithGrowingPeriod(item)),
    [dispatch]
  )
  const renderItem = useCallback(
    ({ item }) => (
      <AdviceFieldListItem
        item={item}
        isSelected={isSelected(selectedFieldsWithGrowingPeriod, item)}
        onSelect={onSelect}
      />
    ),
    [selectedFieldsWithGrowingPeriod, onSelect]
  )
  const extraParams = useMemo(() => ({ search }), [search]) // to make empty view working
  const list = useMemo(() => {
    // make a nice array with an id so we have a more normalized view
    // of the data
    let data = (selectedGroupedCrop.growingPeriods || []).map(
      ({ organizationField, ...growingPeriod }) => ({
        id: `${organizationField.id}_${growingPeriod.id}`,
        organizationField,
        growingPeriod,
      })
    )
    if (search) {
      // search on title and description in front-end since
      // there is no pagination or backend search for this
      data = data.filter(it =>
        [getTitle(it), getDescription(it)].some(s =>
          s.toLowerCase().includes(search.toLowerCase())
        )
      )
    }
    return { data }
  }, [selectedGroupedCrop, search])

  return (
    <GeneralList
      icon
      selected={selectedFieldsWithGrowingPeriod}
      renderEmptyWithZeroData
      loadListOnMount={false}
      onSearch={onSearch}
      list={list}
      searchPlaceholder={'Zoek op teeltlocaties'}
      renderItem={renderItem}
      extraParams={extraParams}
      appbarContent={
        <PageHeader
          componentId={componentId}
          back={onBack}
          title={
            <Appbar.Content
              title={`Selecteer teeltlocaties`}
              subtitle={`${selectedGroupedCrop.name}${
                selectedGroupedCrop.variety
                  ? ` (${selectedGroupedCrop.variety})`
                  : ''
              }`}
            />
          }
        />
      }
      fabLabel={'Adviseren'}
      onPressFab={onPressAdvice}
      onlyShowFabWhenSelected
    />
  )
}
