import actions from './actions'
import reducer from '../../GeneralListFunctions/reducerFactory'

export interface LocationType {
  id: string
  name: string
  exceptAttributes: LocationType[]
}

export default reducer<LocationType>(actions)
