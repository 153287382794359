import React, { Component } from 'react'
import { List } from 'react-native-paper'
import { View } from 'react-native'
import Croplist from './List/Croplist'
import ClosableModal from '../../components/ClosableModal'
import fonts from '../../fonts'
import { Translate } from 'react-translated'
import { filterTypesSorted } from '../../FilterType/config'

class SingleSelect extends Component {
  state = {
    modalVisible: false,
  }
  _hideModal = () => {
    this.setState({ modalVisible: false })
  }
  _showModal = () => {
    this.setState({ modalVisible: true })
  }
  _isSelected = item => {
    const valueId = this.props.value && this.props.value.id
    return item.id === valueId
  }
  _select = item => {
    this._hideModal()
    this.props.onSelect(item)
  }

  render() {
    const { modalVisible } = this.state
    const { value, editable } = this.props
    const filterType = filterTypesSorted.find(it => it.id === 'crop')

    return (
      <>
        <List.Item
          onPress={editable && this._showModal}
          title={
            value && value.name ? (
              `${[
                value.name,
                value.latinName ? `(${value.latinName})` : undefined,
              ]
                .filter(n => n)
                .join(' ')}`
            ) : editable ? (
              <Translate text="selectCrop" />
            ) : (
              <Translate text="noCropSelected" />
            )
          }
          titleStyle={{
            ...fonts.medium,
          }}
          description={
            value && value.name ? (
              <Translate text="crop" />
            ) : (
              <Translate text="noCropSelected" />
            )
          }
          right={p => editable && <List.Icon {...p} icon="chevron-down" />}
        />

        <ClosableModal
          isVisible={modalVisible}
          onBackButtonPress={this._hideModal}
          onBackdropPress={this._hideModal}
          onRequestClose={this._hideModal}
          onModalHide={this._hideModal}
          hideHeader={true}
        >
          <View style={{ marginBottom: 25, height: 600 }}>
            <Croplist
              {...this.props}
              singleSelect
              isSelected={this._isSelected}
              // searchIcon={'close'}
              filterType={filterType}
              editable={editable}
              parentId={
                value &&
                (value.parent && value.parent.id
                  ? value.parent.id
                  : value.parentId
                  ? value.parentId
                  : value.parent_id)
              }
              onBack={this._hideModal}
              onSelect={this._select}
              autoFocusSearch={true}
            />
          </View>
        </ClosableModal>
      </>
    )
  }
}

SingleSelect.defaultProps = {
  editable: true,
}

export default SingleSelect
