import React from 'react'
import Svg from './SvgAbstraction'
import Path from './PathAbstraction'

import { color, defaultProps, propTypes } from './standardVector'

export const width = 24
export const height = 24

const Vector = props => (
  <Svg
    width={props.width || width}
    height={props.height || height}
    className={props.className}
    viewBox="0 0 24 24"
  >
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M8.25,17.25h-6a1.5,1.5,0,0,1-1.5-1.5v-12a1.5,1.5,0,0,1,1.5-1.5h13.5a1.5,1.5,0,0,1,1.5,1.5v4.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M0.75 6.75L17.25 6.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M5.25 3.75L5.25 0.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M12.75 3.75L12.75 0.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M11.250 17.250 A6.000 6.000 0 1 0 23.250 17.250 A6.000 6.000 0 1 0 11.250 17.250 Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M19.902 17.25L17.25 17.25 17.25 14.599"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </Svg>
)

Vector.propTypes = propTypes
Vector.defaultProps = defaultProps

export default Vector
