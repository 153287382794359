import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'
import PT from 'prop-types'
// import Header from '../components/header/Header'

import { Button, Text, Appbar } from 'react-native-paper'
import AppbarResizer from '../WidthAndHeight/AppbarResizer'
import forgotPasswordHOC from 'react-auth/lib/hocs/forgotPasswordHOC'
import Fields from '../Form/Fields'
import FormContainer from '../Form/FormContainer'
import AppbarHeader from '../WidthAndHeight/AppbarHeader'
import Navigation, { push } from '../Navigation'
import { AUTH_SCREEN_ROUTE } from '../Routes.config'

const styles = StyleSheet.create({
  loginContainer: {
    flex: 1,
    maxWidth: 700,
    width: '100%',
    alignSelf: 'center',
  },

  loginForm: {
    maxWidth: 400,
    width: '100%',
    alignSelf: 'center',
    padding: 10,
    paddingTop: 60 * 2,
  },

  buttonBox: {
    marginTop: 16,
  },
  errorBox: {
    backgroundColor: 'red',
    padding: 8,
    borderRadius: 10,
  },
  succesBox: {
    // backgroundColor: '#3da945',
    padding: 8,
    // borderRadius: 10,
  },
  header: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  root: { backgroundColor: '#fff', flex: 1 },
})

const fieldProps = {
  style: { marginBottom: 16 },
}
const fields = [
  {
    name: 'email',
    label: 'Emailadres',
    type: 'email',
    fieldProps: { ...fieldProps, autoFocus: true },
  },
]
class ForgotPassword extends Component {
  _pop = () => {
    Navigation.pop(this.props.componentId)
  }
  _change = (name, value) => {
    this.props.onChangeField(name, value)
  }
  _submit = () => {
    this.props.onSubmit()
  }
  render() {
    const { sending, completed, error } = this.props
    return (
      <View style={styles.root}>
        <AppbarHeader style={styles.header} dark={true}>
          <AppbarResizer>
            <Appbar.BackAction color="#fff" onPress={this._pop} />
          </AppbarResizer>
        </AppbarHeader>

        <View style={styles.loginContainer} key="content">
          <View style={styles.loginForm}>
            {!!error && (
              <View style={styles.errorBox}>
                <Text
                  style={{
                    color: '#FFF',
                  }}
                >
                  Fout bij het opvragen van het wachtwoord. Neem contact op met
                  de beheerder.
                </Text>
              </View>
            )}

            {completed ? (
              <View style={styles.buttonBoxSmall}>
                <View style={styles.succesBox}>
                  <Text
                    style={
                      {
                        // color: '#FFF',
                      }
                    }
                  >
                    Er is een mail verstuurd naar {this.props.values.email} met
                    instructies om uw wachtwoord te veranderen.
                  </Text>
                </View>
                <View style={styles.buttonBox}>
                  <Button
                    onPress={() => {
                      push(this.props.componentId, {
                        component: {
                          name: AUTH_SCREEN_ROUTE,
                        },
                      })
                    }}
                    uppercase={false}
                    dark={true}
                  >
                    Terug naar Login pagina
                  </Button>
                </View>
              </View>
            ) : (
              <FormContainer key="form-container" onSubmit={this._submit}>
                <Fields
                  fields={fields}
                  values={this.props.values || {}}
                  onChange={this._change}
                  onSubmit={this._submit}
                />

                <View style={styles.buttonBox}>
                  <Button
                    loading={sending}
                    onPress={this._submit}
                    mode={'contained'}
                    uppercase={false}
                    dark={true}
                  >
                    {sending ? 'Wachtwoord opvragen..' : 'Wachtwoord opvragen'}
                  </Button>
                </View>
              </FormContainer>
            )}
          </View>
        </View>
      </View>
    )
  }
}

ForgotPassword.defaultProps = {
  errors: undefined,
}
ForgotPassword.propTypes = {
  onSubmit: PT.func.isRequired,
  onChangeField: PT.func.isRequired,
  sending: PT.bool.isRequired,
  errors: PT.arrayOf(
    PT.shape({
      message: PT.string.isRequired,
    })
  ),
}

export default forgotPasswordHOC(ForgotPassword)
