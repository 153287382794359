function isString(x) {
  return Object.prototype.toString.call(x) === '[object String]'
}
export const getErrorMessage = (
  error,
  defaultError = 'Er is iets foutgegaan'
) => {
  if (
    error &&
    error.response &&
    error.response.message &&
    isString(error.response.message) &&
    error.response.message !== '[object Object]'
  ) {
    return error.response.message
  }
  if (
    error &&
    error.message &&
    isString(error.message) &&
    error.message !== '[object Object]'
  ) {
    return error.message
  }
  // why do we get [object Object] from our fetcher?
  if (error && isString(error) && error !== '[object Object]') {
    return error
  }
  return defaultError
}
