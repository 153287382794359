import React, { useMemo } from 'react'
import Field, { isTextInput } from './Field'
import { pushCustomEvent } from '../Analytics/abstraction'

import { get } from 'lodash'

function Fields({
  fields,
  values,
  onChange,
  onSubmit = () => null,
  toggleHidePassword = undefined,
  hidePassword = undefined,
  errors = [],
  editable = true,
  stringTrueValue = undefined,
  canDelete = undefined,
}: {
  fields: any
  values: any
  onChange: (name: string, value: string) => any
  onSubmit: (key: string) => any
  toggleHidePassword?: () => any
  hidePassword?: boolean
  errors?: any[]
  editable?: boolean
  stringTrueValue?: any
  canDelete?: boolean
}) {
  let refs = {}
  const _ref = (name: string) => (input: string) => {
    refs = {
      ...refs,
      [name]: input,
    }
  }
  const _focus = (name: string) => () => {
    // @ts-ignore
    refs[name].focus()
  }
  const _blur = (name: string) => () => {
    // @ts-ignore
    refs[name].blur()
  }

  const enhancedFields = useMemo(
    () => {
      return fields.map(
        (
          field: {
            lastHard: any
            fieldProps: any
            error: any
            name: string
            type: any
          },
          i: number
        ) => {
          let enhancedFieldProps = { ...field.fieldProps } || {}

          field.error =
            errors.find(error => field.name === error.name) || undefined

          const isLastAndTextInput =
            (fields.length - 1 === i && isTextInput(field.type)) ||
            field.lastHard

          // Remember to set blurOnSubmit to false, to prevent keyboard flickering.
          // blurOnSubmit={false}
          enhancedFieldProps.blurOnSubmit = false

          // Worth to mention, that onSubmitEditing callback is called after blur event.
          // So the keyboard may go crazy if focused on next element immediately.
          // So it might be helpful to set blurOnSubmit={false} to all elements in form but
          // leave at true on last element, to allow Done button to blur the last input.
          if (isLastAndTextInput) {
            enhancedFieldProps.blurOnSubmit = field.lastHard ? false : true //?????
            enhancedFieldProps.onSubmitEditing = () => onSubmit(field.name)
            enhancedFieldProps.returnKeyType = 'send'
          }

          // Adding a Ref to second TextInput
          // ref={(input) => { this.secondTextInput = input; }}
          if (isTextInput(field.type)) {
            enhancedFieldProps.ref = _ref(field.name)
          }

          //Focussing all user fields to msg mixpanel
          enhancedFieldProps.onFocus = () => {
            console.log(field.name)
            pushCustomEvent('Focused field:', field.name)
            _focus(field.name)
          }

          // Bind focus function to first TextInput's onSubmitEditing event.
          // onSubmitEditing={() => { this.secondTextInput.focus(); }}
          if (!isLastAndTextInput) {
            const nextField = fields[i + 1]
            if (nextField && isTextInput(nextField.type)) {
              enhancedFieldProps.returnKeyType = 'next'
              enhancedFieldProps.onSubmitEditing = _focus(nextField.name)
            } else if (nextField) {
              // Blur on submit since next field is not a text input!
              enhancedFieldProps.onSubmitEditing = _blur(field.name)
              enhancedFieldProps.returnKeyType = 'next'
            }
          }
          return {
            field,
            enhancedFieldProps,
          }
        }
      )
    },
    // eslint-disable-next-line
    [fields, errors]
  )
  return enhancedFields.map(
    ({
      field,
      enhancedFieldProps,
    }: {
      field: {
        fieldProps?: any
        error?: any
        name: string
        type?: any
        options?: any
        label?: string
        canDelete?: boolean
        plainList?: boolean
        unitType?: string
      }
      enhancedFieldProps?: any
    }) => (
      <Field
        key={field.name}
        name={field.name}
        type={field.type}
        label={field.label}
        onChange={onChange}
        editable={editable}
        stringTrueValue={stringTrueValue}
        options={field.options}
        unitType={field.unitType}
        value={get(values, field.name)} // support . operator
        fieldProps={enhancedFieldProps}
        canDelete={field.canDelete}
        plainList={field.plainList}
        error={field.error}
        toggleHidePassword={toggleHidePassword}
        hidePassword={hidePassword}
      />
    )
  )
}

export default Fields
