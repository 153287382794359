import { actionCreator as ac } from '../../helpers/actionCreator'

const ns = (type) => `SWITCH_${type}`

export const CHECK = ns('CHECK')
export const check = ac(CHECK)

export const UNCHECK = ns('UNCHECK')
export const uncheck = ac(UNCHECK)

export const RESET = ns('RESET')
export const reset = ac(RESET)
