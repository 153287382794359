export default function round(value, decimals) {
  // Using the convert functions of JS Number.
  // e and e- are used in scientific notation.
  let num = Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
    .toString()
    // Replacing . with ,
    .replace(/\./g, ',')

  return num
}
