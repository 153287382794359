import { call, put, takeLatest } from 'redux-saga/effects'
import * as a from './actions'
import { fetchListener } from 'react-auth/lib/sagas/authentication'

function* loadPolygons({ payload: { fieldId, region } }) {
  try {
    const fieldResponse = yield call(fetchListener, {
      path: `field/field/${fieldId}`,
    })
    const loadedPayload = {
      data: {
        field: fieldResponse,
      },
    }

    yield put(a.loadedPolygons(loadedPayload))
  } catch (e) {
    console.log('Map saga error: ', e)
    yield put(a.loadedPolygons(e, null, true))
  }
}

export default function* saga() {
  yield takeLatest(a.LOAD_POLYGONS, loadPolygons)
}
