import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { loadPolygons } from './actions'
import MapView from '../Map/Map'
import MapUserLocation from '../Map/MapUserLocation'
import FieldsPolygons from './FieldsPolygons'
import Search from '../MapSearch/Search'
import LoadCurrentLocationButton from '../Map/LoadCurrentLocationButton'
import {
  findCustomFieldByCoordinates,
  findFieldByCoordinates,
  getAllCoordinatesFromField,
} from '../Map/helpers'
import MapError from '../Map/MapError'
import turf from 'turf'
import { Translate, Translator } from 'react-translated'
import BottomFab from '../WidthAndHeight/BottomFab'
import { Text } from 'react-native-paper'

function FieldsMap(props) {
  const {
    componentId,
    selectedField,
    selectedFields,
    selectedCustomFields,
    alreadySelectedFields,
    fullScreen = true,
    onHeaderIconPress,
    headerIcon,
    onPressField,
    editable = true,
    noCurrent = false,
    withoutInformation = false,
    hasReducerZoom = false,
    showDrawMenu = false,
    save = false,
    presetCenter,
    changeEntrance,
    finishChangeEntrance,
    children,
    portalActive,
    locations,
    setLocations,
  } = props
  // console.log('Render FieldsMap')
  const [snap, setSnap] = useState()

  const fieldCoordinates = useMemo(() => {
    const center =
      alreadySelectedFields &&
      alreadySelectedFields.data &&
      alreadySelectedFields.data.length > 0
        ? getAllCoordinatesFromField(alreadySelectedFields.data[0].field)
        : selectedField
        ? getAllCoordinatesFromField(selectedField)
        : selectedFields
        ? selectedFields[0] && selectedFields[0].field
          ? getAllCoordinatesFromField(selectedFields[0].field)
          : getAllCoordinatesFromField(selectedFields[0])
        : [5, 52]
    let realCenter
    if (center && center.length > 2) {
      const turfPolygon = turf.polygon([center])
      realCenter = turf.centroid(turfPolygon)
    }
    return realCenter ? realCenter.geometry.coordinates : [5, 52]
  }, [selectedField, alreadySelectedFields, selectedFields]) || [5, 52]

  const dispatch = useDispatch()
  const fields = useSelector(state => state.fieldMap.fieldData)
  const customFields = useSelector(state => state.fieldMap.customFields)
  const error = useSelector(state => state.fieldMap.error)
  const loadingFields = useSelector(state => state.fieldMap.loading)

  const customFieldsRef = React.useRef([])
  useEffect(() => {
    customFieldsRef.current = customFields
    return () => {}
  }, [customFields])

  const fieldsRef = React.useRef([])
  useEffect(() => {
    fieldsRef.current = fields
    return () => {}
  }, [fields])

  const [mapRef, setMap] = useState(undefined)
  const onRef = useCallback(c => c && setMap(c), [setMap])

  const onPress = useCallback(
    ({ latitude, longitude }) => {
      if (editable) {
        const matchedField = findFieldByCoordinates({
          fields: fieldsRef.current || [], // fix closure to old reference
          longitude,
          latitude,
        })
        const matchedCustomField = findCustomFieldByCoordinates({
          customFields: customFieldsRef.current || [], // fix closure to old reference
          longitude,
          latitude,
        })
        if (matchedCustomField && onPressField) {
          onPressField(matchedCustomField, true)
        } else if (matchedField && onPressField) {
          onPressField(matchedField)
        }
      }
    },
    [onPressField, editable]
  )

  const onInnerRegionChanged = useCallback(
    (region, zoom) => {
      if (zoom >= 13) {
        dispatch(loadPolygons({ region }))
      }
    },
    [dispatch]
  )

  const selectedFieldsPolygons = () => {
    let list = []
    if (
      selectedFields &&
      selectedFields.fields &&
      selectedFields.fields.length > 0
    ) {
      selectedFields.fields.forEach(f => {
        list = [...list, [f]]
      })
    } else if (selectedFields && selectedFields.length > 0) {
      selectedFields.forEach(f => {
        if (f?.fields) {
          list = [...list, f?.fields]
        } else if (f?.fields) {
          list = [...list, [f?.field]]
        } else {
          list = [...list, [f]]
        }
      })
    } else {
      return <FieldsPolygons selectedField={selectedField} />
    }
    return (
      <>
        {list.map(li => (
          <FieldsPolygons selectedFields={li} />
        ))}
      </>
    )
  }
  const [currentEditingLocation, setCurrentEditingLocation] = useState(null)
  useEffect(() => {
    if (locations?.length > 0) {
      if (!currentEditingLocation) {
        setCurrentEditingLocation(locations[0])
      }
    }
  }, [locations, currentEditingLocation, setCurrentEditingLocation])
  const saveAndNextLocation = newLocation => {
    setLocations(
      locations.map(l => {
        if (l?.number === newLocation?.number) {
          return newLocation
        }
        return l
      })
    )
    if (newLocation?.number < locations.length) {
      setCurrentEditingLocation(locations[newLocation?.number])
    } else {
      setCurrentEditingLocation(null)
    }
  }
  const skipAndNextLocation = newLocation => {
    if (newLocation?.number < locations.length) {
      setCurrentEditingLocation(locations[newLocation?.number])
    } else {
      setCurrentEditingLocation(null)
    }
  }

  // working fields
  const subscribeToUserLocation = !noCurrent
  return (
    <View style={styles.container}>
      <LoadCurrentLocationButton
        loading={loadingFields}
        portalActive={portalActive}
      />

      <MapView
        onRef={onRef}
        hasReducerZoom={hasReducerZoom}
        reducerZoom={[15]}
        withoutInformation={withoutInformation}
        onRegionChanged={onInnerRegionChanged}
        centerCoordinates={
          fieldCoordinates && !subscribeToUserLocation
            ? fieldCoordinates
            : undefined
        }
        dontUpdate={!fullScreen}
        presetCenter={presetCenter}
        onPressLatLong={onPress}
        fullScreen={fullScreen}
        showDrawMenu={showDrawMenu}
        showSnapMenu={changeEntrance}
        setSnap={setSnap}
        save={save}
        selectedField={selectedField}
        selectedFields={selectedFields}
        // subscribeToUserLocation={subscribeToUserLocation} // DO NOT UNCOMMENT THIS. This fixes searching for location on Android and iOS.
        render={permission => {
          return (
            <>
              {selectedFieldsPolygons()}
              {selectedCustomFields &&
                selectedCustomFields.length > 0 &&
                selectedCustomFields.map(cf => {
                  // const fieldArray = []
                  return (
                    <FieldsPolygons
                      selectedFields={cf}
                      fillColor={(cf[0] && cf[0].fillColor) || '#1DA1F2'}
                    />
                  )
                })}
              {children}

              {permission && (
                <MapUserLocation
                  subscribeToUserLocation={subscribeToUserLocation}
                  mapRef={mapRef}
                  portalActive={portalActive}
                />
              )}
              {changeEntrance && (
                <Translator>
                  {({ translate }) => (
                    <BottomFab
                      style={{ zIndex: 100 }}
                      label={translate({ text: 'save' })}
                      icon="check"
                      onPress={() => finishChangeEntrance(snap)}
                      type="submit"
                    />
                  )}
                </Translator>
              )}
              {withoutInformation && (
                <Translator>
                  {({ translate }) => (
                    <>
                      <View
                        style={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          left: 0,
                        }}
                      >
                        {currentEditingLocation?.number && (
                          <View
                            style={{
                              backgroundColor: '#FFF',
                              padding: 8,
                              borderRadius: 8,
                              margin: 8,
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 16,
                                color: '#000',
                              }}
                            >
                              {translate({ text: 'giveLocationFor' })}
                              {currentEditingLocation?.name
                                ? currentEditingLocation?.name
                                : `${currentEditingLocation?.unit?.name} ${currentEditingLocation?.number}`}
                              {translate({ text: 'stands' })}
                            </Text>
                          </View>
                        )}
                      </View>
                      <BottomFab
                        style={{
                          zIndex: 100,
                          marginBottom: 64,
                          backgroundColor: '#FFF',
                          color: '#000',
                        }}
                        label={
                          translate({ text: 'skip' }) +
                          ' & ' +
                          translate({ text: 'next' }).toLowerCase()
                        }
                        icon="chevron-right"
                        onPress={skipAndNextLocation}
                        type="submit"
                      />
                      <BottomFab
                        style={{ zIndex: 100 }}
                        label={
                          translate({ text: 'save' }) +
                          ' & ' +
                          translate({ text: 'next' }).toLowerCase()
                        }
                        icon="check"
                        onPress={saveAndNextLocation}
                        type="submit"
                      />
                    </>
                  )}
                </Translator>
              )}
            </>
          )
        }}
      />

      {!withoutInformation && (
        <Search
          withPadding
          fullScreen={fullScreen}
          componentId={componentId}
          onIconPress={onHeaderIconPress}
          icon={headerIcon}
        />
      )}
      {error && (
        <MapError
          fullScreen={fullScreen}
          label={<Translate text="errorParcels" />}
        />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
    borderRadius: 8,
  },
})

export default React.memo(FieldsMap)
