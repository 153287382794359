import React, { useCallback, useMemo, useState } from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import FieldsMap from '../../FieldMap/FieldsMap'
import Fields from '../../Form/Fields'
import { getCenterFromField } from '../../Map/helpers'
import { Translate } from 'react-translated'
import { Button, Text } from 'react-native-paper'
import Entrance from '../../FieldMap/Entrance'
import SectionsOnMap from '../../FieldMap/LocationsOnMap'

export const multiFields = [
  {
    name: 'name',
    label: <Translate text="name" />,
    type: 'text',
    // label: 'Opmerkingen',
    fieldProps: {
      style: { marginLeft: 6, marginRight: 6 },
    },
  },
  {
    name: 'growingPeriods',
    label: <Translate text="growingPeriods" />,
    type: 'growingPeriods',
  },
]

export const fields = [
  {
    name: 'organization',
    label: <Translate text="company" />,
    type: 'organizationSelect',
    plainList: true,
    fieldProps: {
      // style: { marginLeft: 6, marginRight: 6 },
    },
  },
  {
    name: 'name',
    label: <Translate text="name" />,
    type: 'text',
    // label: 'Opmerkingen',
    fieldProps: {
      style: { marginLeft: 6, marginRight: 6 },
    },
  },
  {
    name: 'growingPeriods',
    label: <Translate text="growingPeriods" />,
    type: 'growingPeriods',
  },
  // {
  //   name: 'customField.soilResearches',
  //   label: <Translate text={'soilResearches'} />,
  //   type: 'soilResearches',
  // },
]

function FieldObject({
  componentId,
  organizationField,
  change,
  index,
  next,
  multiple,
  type,
  withoutInformation,
  sections,
  setSections,
}) {
  const [entrance, setEntrance] = useState()
  const [changeEntranceMode, setChangeEntranceMode] = useState(false)
  const innerChange = useCallback(
    (name, value) => {
      let newOrganizationField
      if (name.includes('.')) {
        const split = name.split('.')
        const first = split[0]
        const second = split[1]
        newOrganizationField = {
          ...organizationField,
          [first]: {
            ...((organizationField && organizationField[first]) || {}),
            [second]: value,
          },
        }
      } else {
        newOrganizationField = {
          ...organizationField,
          [name]: value,
        }
      }
      if (name === 'growingPeriods') {
        newOrganizationField[name] = value.map(gp => ({
          ...gp,
          viaSingle: true,
        }))
      }
      if (name === 'soilResearches') {
        if (newOrganizationField.field) {
          newOrganizationField.customField = {
            organization: organizationField.organization,
            fields: [organizationField.field],
          }
          newOrganizationField.field = null
        }
        newOrganizationField.customField.soilResearches = value.map(sr => ({
          ...sr,
        }))
      }
      console.log(newOrganizationField)
      console.log('NEW FIELD')
      if (Array.isArray(organizationField)) {
        const arrayField = organizationField.map(it => {
          return { ...it, ...newOrganizationField }
        })
        change(index, arrayField, type)
      } else {
        change(index, newOrganizationField, type)
      }
    },
    [change, index, organizationField, type]
  )
  const fieldArray = useMemo(() => {
    return Array.isArray(organizationField) && organizationField.length > 0
      ? organizationField
      : organizationField?.fields
      ? organizationField?.fields
      : organizationField?.customField && organizationField?.customField?.fields
  }, [organizationField])

  const saveEntrance = useCallback(
    coords => {
      setChangeEntranceMode(false)

      if (coords) {
        const entranceObject = [
          {
            longitude: coords.geometry.coordinates[1],
            latitude: coords.geometry.coordinates[0],
          },
        ]
        let placeName
        fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${entranceObject[0].longitude},${entranceObject[0].latitude}.json?language=nl&access_token=pk.eyJ1IjoicHJvZmVjdHMiLCJhIjoiY2p1eTNkZjMwMDQ5NTRlb2F1cHY3bXpjNCJ9.PDaoYVEZ2sqPbqIVtfM2wA`
        )
          .then(res => res.json())
          .then(data => {
            let newOrganizationField
            placeName = data.features[0].place_name
            console.log(placeName)
            if (Array.isArray(organizationField)) {
              newOrganizationField = organizationField.map(of => {
                return {
                  ...of,
                  entrances: entranceObject,
                  name:
                    organizationField &&
                    organizationField.name &&
                    organizationField.name !== ''
                      ? organizationField.name
                      : placeName,
                }
              })
              change(index, newOrganizationField, 'customFieldArray')
            } else {
              newOrganizationField = {
                ...organizationField,
                entrances: entranceObject,
                name:
                  organizationField &&
                  organizationField.name &&
                  organizationField.name !== ''
                    ? organizationField.name
                    : placeName,
              }
              change(index, newOrganizationField, 'fieldArray')
            }
            setEntrance(entranceObject)
          })
      }
    },
    [change, index, organizationField]
  )
  console.log({ index, organizationField })
  return (
    <React.Fragment key={index}>
      <View style={styles.fieldsMapContainer}>
        <FieldsMap
          selectedField={organizationField.field}
          selectedFields={fieldArray}
          sections={sections}
          setSections={setSections}
          withoutInformation={withoutInformation}
          selectedCustomFields={organizationField}
          hasReducerZoom={true}
          componentId={componentId}
          headerIcon={''}
          entrance={withoutInformation ? undefined : entrance}
          changeEntrance={withoutInformation ? undefined : changeEntranceMode}
          finishChangeEntrance={withoutInformation ? undefined : saveEntrance}
          presetCenter={
            organizationField.field
              ? getCenterFromField(organizationField.field).geometry.coordinates
              : fieldArray && fieldArray[0].field
              ? getCenterFromField(fieldArray[0].field).geometry.coordinates
              : getCenterFromField(fieldArray[0]).coordinates
          }
          fullScreen={!!withoutInformation}
          noCurrent
        >
          {entrance && !withoutInformation && <Entrance entrance={entrance} />}
          {withoutInformation && <SectionsOnMap sections={sections} />}
        </FieldsMap>
        {Platform.OS === 'web' && !withoutInformation && (
          <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Button
              icon="pencil"
              style={{ marginTop: 16 }}
              onPress={() => setChangeEntranceMode(true)}
              color={'#3da945'}
              uppercase={false}
              mode={'outlined'}
            >
              <View>
                <Text style={{ color: '#3da945' }}>
                  {entrance ? (
                    <Translate text="changeEntrance" />
                  ) : (
                    <Translate text="addEntrance" />
                  )}
                </Text>
              </View>
            </Button>
          </View>
        )}
      </View>
      {!withoutInformation && (
        <View style={{ margin: 20 }}>
          <Fields
            fields={multiple ? multiFields : fields}
            values={
              Array.isArray(organizationField) && organizationField.length > 0
                ? organizationField[0]
                : organizationField
            }
            onChange={innerChange}
            onSubmit={next}
          />
        </View>
      )}
    </React.Fragment>
  )
}

const styles = StyleSheet.create({
  fieldsMapContainer: {
    height: 500,
    borderRadius: 8,
    margin: 16,
    overflow: 'hidden',
  },
})

export default React.memo(FieldObject)
