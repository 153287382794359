import React, { useEffect, useMemo } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import actions from './actions'

import { getProductData } from '../../Product/selectors'
import { sortByClassList } from '../../Product/Crop/ProductCropList'
import { Translator } from 'react-translated'
// import { IconButton } from 'react-native-paper'
import { DataTable, Text } from 'react-native-paper'
import Resizer from '../../WidthAndHeight/Resizer'
import { ScrollView, View } from 'react-native'
import One from '../../vectors/One'
import Two from '../../vectors/Two'
import Three from '../../vectors/Three'
import Four from '../../vectors/Four'
import { getFirstSelectedId } from '../../FilterType/config'

export function findHighestClass(arr) {
  let highest = { class: 0 }
  arr.forEach(item => {
    if (item.class > highest.class) {
      highest = item
    }
  })
  return highest
}
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

function ProductBeneficialOrganism({ list, productId, ...rest }) {
  const selectedCrop = useSelector(state =>
    getFirstSelectedId(state.filter.crop.selected)
  )

  const extraParams = useMemo(
    () => ({
      limit: 200,
      number: 200,
      productId: productId,
      preloadAll: true,
      sources: ['MANUAL'],
      cropId: selectedCrop,
    }),
    [productId, selectedCrop]
  )

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(actions.loadList({ params: extraParams }))
  }, [dispatch, extraParams])

  const memoizedList = useMemo(() => sortByClassList(list), [list])

  console.log({ memoizedList })
  return (
    <Translator>
      {({ translate }) => (
        <ScrollView>
          <Resizer>
            <DataTable>
              <DataTable.Header>
                <View style={{ width: '33%', justifyContent: 'center' }}>
                  <Text>
                  {translate({ text: 'beneficialOrganism' })}
                  </Text>
                </View>
                <View style={{ width: '33%', justifyContent: 'center' }}>
                  <Text>
                  {translate({ text: 'crop' })}
                  </Text>
                </View>
                <View
                  style={{
                    width: '16%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Text>
                  {translate({ text: 'phase' })}
                  </Text>
                </View>
                <View
                  style={{
                    width: '16%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Text>
                  {translate({ text: 'class' })}
                  </Text>
                </View>
              </DataTable.Header>
              {memoizedList?.data?.map((item, index) => {
                const beneficial = item?.beneficialOrganisms?.[0]
                return item?.mortalities?.map(ben => (
                  <DataTable.Row
                    key={index}
                    // style={{ backgroundColor: color('red'), opacity: 0.5 }}
                  >
                    <View style={{ width: '33%', justifyContent: 'center' }}>
                      <Text>
                      {(beneficial?.name?.la ? beneficial.name.la : '') +
                        (beneficial?.name?.nl
                          ? ` (${beneficial.name.nl})`
                          : '')}
                      </Text>
                    </View>
                    <View style={{ width: '33%', justifyContent: 'center' }}>
                      <Text>
                      {capitalizeFirstLetter(item?.cropDefinition?.name)}
                      </Text>
                    </View>
                    <View
                      style={{
                        width: '16%',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Text>
                      {capitalizeFirstLetter(ben?.stage)}
                      </Text>
                    </View>

                    <View
                      style={{
                        width: '16%',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {ben?.class === 1 ? (
                        <One width={24} height={24} color={'green'} />
                      ) : ben?.class === 2 ? (
                        <Two width={24} height={24} color={'orange'} />
                      ) : ben?.class === 3 ? (
                        <Three width={24} height={24} color={'orange'} />
                      ) : ben?.class === 4 ? (
                        <Four width={24} height={24} color={'red'} />
                      ) : null}
                    </View>
                  </DataTable.Row>
                ))
              })}
            </DataTable>
          </Resizer>
        </ScrollView>
      )}
    </Translator>
  )
}

const mapStateToProps = state => ({
  list: state.sideEffect.list,
  productId: getProductData(state).id,
})

export default React.memo(
  connect(mapStateToProps, actions)(ProductBeneficialOrganism)
)
