import React from 'react'
import { useWidthAndHeight } from './widthAndHeightHook'
export const mediumWidth = 700
export const largeWidth = 1150

export default (WrappedComponent) => {
  function HOC(props) {
    const { width, height, isMedium, isLarge } = useWidthAndHeight()

    return (
      <WrappedComponent
        {...props}
        width={width}
        height={height}
        isMedium={isMedium}
        isLarge={isLarge}
      />
    )
  }
  return HOC
}
