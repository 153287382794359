import { PDF_ROUTE } from './Routes.config'

export function getURLFromPushOptions(options) {
  let name = options.component.name
  if (name === PDF_ROUTE) {
    return options.component.passProps.url
  }
  if (options.component.passProps) {
    if (!name.endsWith('/')) {
      name += '/'
    }

    const passPropsAfterURL = Object.keys(options.component.passProps)
      .filter((key) => !!options.component.passProps[key])
      .filter((key) => !name.includes(':' + key))
      .map((key) => encodeURIComponent(options.component.passProps[key]))

    const replaceInURL = Object.keys(options.component.passProps)
      .filter((key) => !!options.component.passProps[key])
      .filter((key) => !!name.includes(':' + key))
      .map((key) => ({
        key,
        value: encodeURIComponent(options.component.passProps[key]),
      }))

    // console.log({ replaceInURL })

    name += passPropsAfterURL.join('/')
    replaceInURL.forEach(({ key, value }) => {
      name = name.replace(':' + key, encodeURIComponent(value))
    })
    return name
  }
  return options.component.name
}
