import 'dayjs/locale/nl' // load on demand
import dayjs, { Dayjs } from 'dayjs'
import cpf from 'dayjs/plugin/customParseFormat'
import lf from 'dayjs/plugin/localizedFormat'

dayjs.extend(cpf)
dayjs.extend(lf)

// creates a day name from a date string or timestamp
export const getFriendlyDate = (date: Dayjs, withTime = false): string => {
  // var now = dayjs()
  const createdAt = dayjs(date)

  // if (dayjs(date).isSame(now, 'year')) {
  //   return createdAt.locale('nl').format(withTime ? 'D MMMM H:mm' : 'D MMMM')
  // }
  return createdAt
    .locale('nl')
    .format(withTime ? 'DD-MM-YYYY H:mm' : 'DD-MM-YYYY')
}
export const getFriendlyShortDate = (date: string | number | Dayjs): string => {
  var now = dayjs()
  const createdAt = dayjs(date, 'YYYY-MM-DD HH:mm:ss')

  if (dayjs(date).isSame(now, 'day')) {
    return createdAt.locale('nl').format('HH:ss')
  } else if (dayjs(date).isSame(now, 'week')) {
    return createdAt.locale('nl').format('dd')
  } else if (dayjs(date).isSame(now, 'month')) {
    return createdAt.locale('nl').format('D MMMM')
  } else if (dayjs(date).isSame(now, 'year')) {
    return createdAt.locale('nl').format('D MMMM')
  }
  return createdAt.locale('nl').format('D MMMM YYYY')
}

export const getApplicationDate = (date: Dayjs): string => {
  var now = dayjs()
  const createdAt = dayjs(date, 'YYYY-MM-DD HH:mm:ss')

  if (dayjs(date).isSame(now, 'day')) {
    return 'Vandaag'
  } else if (dayjs(date).isSame(now, 'week')) {
    return createdAt.locale('nl').format('dddd')
  } else if (dayjs(date).isSame(now, 'month')) {
    return createdAt.locale('nl').format('D MMMM')
  } else if (dayjs(date).isSame(now, 'year')) {
    return createdAt.locale('nl').format('D MMMM')
  }
  return createdAt.locale('nl').format('D MMMM YYYY')
}

export const fromTimestamp = (timestamp: number) => {
  return dayjs.unix(timestamp)
}

export const setLocale = (locale: any) => {
  dayjs.locale(locale)
}

export default dayjs
