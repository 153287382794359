import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { connect } from 'react-redux'
import { Appbar, List, RadioButton, withTheme } from 'react-native-paper'
import actions from '../../OrganizationField/actions'
import registrationActions from '../actions'

import GeneralList from '../../GeneralListFunctions/GeneralList'
import PageHeader from '../../PageHeader'
import Resizer from '../../WidthAndHeight/Resizer'
import Navigation from '../../Navigation'
import { Translator } from 'react-translated'

class FieldsScreen extends Component {
  _press = organizationField => () => {
    this.props.setRegistrationData({
      organizationField,
      crop: organizationField.crop,
    })
    this.props.next(true)
  }
  _renderLeft = (item, isSelected) => () => {
    return (
      <View style={styles.leftContainer}>
        <RadioButton.Android
          status={isSelected ? 'checked' : 'unchecked'}
          onPress={this._press(item)}
        />
      </View>
    )
  }
  _onBack = () => {
    Navigation.pop(this.props.componentId)
  }
  _renderItem = ({ item }) => {
    const { selectedField } = this.props
    const { crop, variety } = item
    let descriptionA = []
    if (crop) {
      descriptionA.push(crop.name)
    }
    if (variety) {
      descriptionA.push(variety)
    }

    let selected = false
    if (selectedField) {
      selected = selectedField.id === item.id
    }
    return (
      <Resizer>
        <List.Item
          key={item.id}
          title={item.name || `Teeltlocatie #${item.id}`}
          description={descriptionA.join(', ')}
          onPress={this._press(item)}
          left={this._renderLeft(item, selected)}
        />
      </Resizer>
    )
  }
  _getParams = () => {
    return {}
  }
  render() {
    const { componentId, selectedField, advice, prev } = this.props
    if (advice?.object && advice.object.organizationField) {
      this.props.setRegistrationData({
        organizationField: advice.object.organizationField,
        crop: advice.object.organizationField.crop,
      })
      this.props.next()
    }
    return (
      <Translator>
        {({ translate }) => (
          <GeneralList
            {...this.props}
            extraParams={this._getParams()}
            searchPlaceholder={translate({ text: 'searchParcel' })}
            renderItem={this._renderItem}
            renderAboveHeader={this._renderAboveHeader}
            icon
            rerenderKey={selectedField && selectedField.id}
            appbarContent={
              <PageHeader
                componentId={componentId}
                back={prev}
                title={
                  <Appbar.Content title={translate({ text: 'chooseParcel' })} />
                }
              />
            }
            context={'activity'}
          />
        )}
      </Translator>
    )
  }
}
// eslint-disable-next-line
const styles = StyleSheet.create({})

const mapStateToProps = state => {
  return {
    list: state.organizationField.list,
    selectedField: state.organizationField.crud.object,
  }
}

export default connect(mapStateToProps, {
  ...actions,
  setRegistrationData: registrationActions.setData,
})(withTheme(FieldsScreen))
