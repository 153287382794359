import React from 'react'
import PT from 'prop-types'
import { Animated, View } from 'react-native'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { IconButton, Text, TouchableRipple } from 'react-native-paper'

import _ from 'lodash/fp'

import compose from 'recompose/compose'
import setPropTypes from 'recompose/setPropTypes'

import withProps from 'recompose/withProps'
import onlyUpdateForKeys from 'recompose/onlyUpdateForKeys'

import styles from './styles'

import { getItemLabel, getItemStatus } from '../Products/helper'
import { pin, unpin } from './actions'

import PinIcon from '../vectors/PinIcon'
import UnpinIcon from '../vectors/UnpinIcon'
import ProductStatus from '../Products/ProductStatus'
import MatchStatus from '../Products/MatchStatus'
import { push } from '../Navigation'
import { PRODUCT_ROUTE } from '../Routes.config'
import safeAreaHOC from '../WidthAndHeight/safeAreaHOC'

const getProduct = ({ product, props, isFixed, isFirst }) => {
  const ColView = isFixed ? Animated.View : View

  const status = product
    ? getItemStatus(
        product.introductionDate,
        product.lastDeliveryDate,
        product.lastUseDate,
        product.expirationDate,
        product.matchType
      )
    : undefined
  return (
    <ColView
      style={[
        styles.col,
        isFixed && styles.fixedCol,
        isFixed && { transform: [{ translateX: props.left }] },
        {
          padding: 5,
          paddingLeft: 10 + (isFirst ? props.safe.left : 0),
          backgroundColor: '#E9E8EC',
        },
      ]}
      key={product.id}
    >
      <View style={styles.productRow}>
        <TouchableRipple
          onPress={() =>
            push(props.componentId, {
              component: {
                name: PRODUCT_ROUTE,
                passProps: {
                  id: product.id,
                },
              },
            })
          }
          style={styles.productButton}
        >
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text style={styles.productName} numberOfLines={1}>
              {getItemLabel(product)}
            </Text>

            <View style={styles.statusComparison}>
              <ProductStatus status={status} product={product} />
              <MatchStatus status={product.matchType} />
            </View>
          </View>
        </TouchableRipple>

        <IconButton
          onPress={() => {
            if (isFixed) {
              props.unpin(product.id)
            } else {
              props.pin(product.id)
            }
          }}
          style={[styles.pinButton, isFixed && styles.fixedPinButton]}
          icon={() =>
            isFixed ? <UnpinIcon color={'blue'} /> : <PinIcon color={'blue'} />
          }
        />
      </View>
    </ColView>
  )
}

const ComparisonHeader = props => {
  let fixedProduct
  if (props.pinned) {
    fixedProduct = getProduct({
      product: props.pinned,
      props,
      isFixed: true,
      isFirst: true,
    })
  }

  return (
    <View style={styles.row}>
      {fixedProduct}
      {(props.products || []).map((product, i) =>
        getProduct({
          product,
          props,
          isFixed: false,
          isFirst: i === 0 && !props.pinned,
        })
      )}
    </View>
  )
}

const productPT = PT.shape({
  id: PT.oneOfType([PT.string, PT.number]).isRequired,
  name: PT.string.isRequired,
})

const enhance = compose(
  connect(
    _.flow(_.get('comparison.products'), _.pick(['entities', 'pinned'])),
    dispatch => bindActionCreators({ pin, unpin }, dispatch)
  ),
  onlyUpdateForKeys(['entities', 'pinned']),
  withProps(({ entities, pinned }) => ({
    products: _.values(_.omit(pinned, entities)),
    pinned: _.get(pinned, entities),
  })),
  setPropTypes({
    products: PT.arrayOf(productPT).isRequired,
    pinned: productPT,
    pin: PT.func.isRequired,
    //  registerScrollTranslate: PT.func.isRequired,
    className: PT.string,
  })
)

export default safeAreaHOC(enhance(ComparisonHeader))
