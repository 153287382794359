import React, { useCallback, useEffect, useState } from 'react'
import { Appbar, Button, Snackbar } from 'react-native-paper'
import GeneralListHeader from '../GeneralListFunctions/GeneralListHeader'
import PageHeader from '../PageHeader'
import Navigation from '../Navigation'
import { Translate, Translator } from 'react-translated'
import { useDispatch, useSelector } from 'react-redux'
import { logout, updateProfile } from 'react-auth/lib/actions/authentication'
import Resizer from '../WidthAndHeight/Resizer'

import { Alert, Platform, View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import Fields from '../Form/Fields'
import { usePrevious } from './ChangePassword'

const fieldProps = {
  style: { marginBottom: 16 },
}
const fields = [
  {
    key: 'text',
    name: 'email',
    type: 'email',
    label: 'E-mail',
    fieldProps: { ...fieldProps, disabled: true },
  },
  {
    key: 'text',
    name: 'first_name',
    type: 'text',
    label: <Translate text="firstName" />,
    fieldProps,
  },
  {
    key: 'text',
    name: 'preposition',
    type: 'text',
    label: <Translate text="preposition" />,
    fieldProps,
  },
  {
    key: 'text',
    name: 'last_name',
    type: 'text',
    label: <Translate text="lastName" />,
    fieldProps,
  },
  {
    key: 'text',
    name: 'language',
    type: 'language',
    label: <Translate text="lastName" />,
    fieldProps,
  },
]

function Account(props) {
  const { componentId } = props
  const user = useSelector(state => state.auth.user || {})
  const userReducer = useSelector(state => state.auth || {})
  const userCrud = user?.user
  const prevUpdating = usePrevious(userReducer?.updating)

  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [userState, setUserState] = useState(userCrud)

  useEffect(() => {
    if (
      userReducer?.updating !== prevUpdating &&
      !userReducer?.updating &&
      prevUpdating !== undefined
    ) {
      if (userReducer?.updatingError) {
        setError(true)
      } else {
        setSuccess(true)
      }
    }
  }, [prevUpdating, userReducer])

  const [disabled, setDisabled] = useState(true)

  const dispatch = useDispatch()

  const add = () => {
    dispatch(
      updateProfile({
        organization: user?.organization,
        user: userState,
      })
    )
  }

  const remove = () => {
    if (Platform.OS === 'web') {
      if (window.confirm('Are you sure you want to remove your account?')) {
        dispatch(logout())
      }
    } else {
      Alert.alert(
        'Are you sure you want to remove your account?',
        'This action cannot be undone.',
        [
          { text: 'Cancel', onPress: () => null, style: 'cancel' },
          { text: 'Yes', onPress: () => dispatch(logout()) },
        ]
      )
    }
  }

  const editField = useCallback(
    (key, value) => {
      setUserState({ ...userState, [key]: value })
    },
    [userState]
  )

  const back = useCallback(() => {
    Navigation.pop(componentId)
  }, [componentId])

  const listHeaderProps = {
    listKey: 'header',
    appbarContent: (
      <Translator>
        {({ translate }) => (
          <PageHeader
            back={back}
            componentId={componentId}
            title={
              <Appbar.Content
                title={translate({ text: 'myAccount' })}
                titleStyle={{
                  textAlign: 'center',
                }}
                style={{ flex: 1 }}
                subtitleStyle={{
                  textAlign: 'center',
                }}
              />
            }
          />
        )}
      </Translator>
    ),
    showSearch: false,
    autoFocusSearch: false,
    withoutHeaderBackground: true,
    onSearch: () => null,
    navbarTranslate: 0,
    navbarOpacity: 1,
    renderStickyBelowHeader: undefined,
    searchPlaceholder: '',
    searchIcon: '',
    onSearchIconPress: () => null,
    searchValue: '',
  }

  useEffect(() => {
    if (
      userState &&
      userState.first_name &&
      userState.first_name !== '' &&
      userState.last_name &&
      userState.last_name !== ''
    ) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [userState])

  return (
    <>
      <GeneralListHeader {...listHeaderProps} />
      <Translator>
        {({ translate }) => (
          <>
            <ScrollView>
              <>
                <Resizer
                  style={
                    Platform.OS === 'web'
                      ? { marginTop: 52, paddingBottom: 80 }
                      : { paddingBottom: 80 }
                  }
                >
                  <View style={{ padding: 16 }}>
                    <Fields
                      fields={fields}
                      values={userState}
                      onChange={editField}
                      onSubmit={add}
                    />
                    <Button
                      onPress={add}
                      mode={'contained'}
                      uppercase={false}
                      disabled={disabled}
                      loading={userReducer?.updating}
                    >
                      {translate({ text: 'save' })}
                    </Button>
                    <Button
                      onPress={remove}
                      style={{ marginTop: 36 }}
                      mode={'outlined'}
                      uppercase={false}
                      disabled={false}
                      loading={userReducer?.removing}
                    >
                      {translate({ text: 'removeYourAccount' })}
                    </Button>
                  </View>
                </Resizer>
              </>
            </ScrollView>
            <Snackbar
              visible={!!error}
              duration={30000}
              style={{ bottom: 100 }}
              onDismiss={() => setError(false)}
              action={{
                label: 'OK',
                onPress: () => {
                  setError(false)
                },
              }}
            >
              {translate({ text: 'saveError' })}
            </Snackbar>
            <Snackbar
              visible={!!success}
              duration={10000}
              style={{ bottom: 100 }}
              onDismiss={() => setSuccess(false)}
              action={{
                label: 'OK',
                onPress: () => {
                  setSuccess(false)
                },
              }}
            >
              {translate({ text: 'saveSuccess' })}
            </Snackbar>
          </>
        )}
      </Translator>
    </>
  )
}

export default Account
