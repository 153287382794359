import actions from './actions'
import reducer from '../../GeneralListFunctions/reducerFactory'
import {SprayNozzleType} from '../SprayNozzle/reducer'

export type DriftClassificationType = {
  classification: number
  nozzle: {
    driftClassification?: DriftClassificationInnerType
    dropletSizeClassification?: DropletSizeClassificationInnerType
    nozzle?: SprayNozzleType
  }
  endNozzle: {
    driftClassification?: DriftClassificationInnerType
    dropletSizeClassification?: DropletSizeClassificationInnerType
  }
  drivingSpeed: {
    max: number
  }
}
export type DriftClassificationInnerType = {
  min: number
}
export type DropletSizeClassificationInnerType = {
  min: '' | "XF" | "VF" | "F" | "M" | "C" | "VC" | "XC" | "UC"
}
export interface SprayTechniqueType {
  id: string
  name: string
  driftClassifications: DriftClassificationType[]
}

export default reducer<SprayTechniqueType>(actions)
