import a from './actions'
import { combineReducers } from 'redux'
import reducerFactory from '../../GeneralListFunctions/reducerFactory'

export interface DistributedProduct {
  createdAt: number
  admissionNumber: string
  id: string
  productId: string
}

export interface DistributionState {
  distributedProducts: DistributedProduct[]
  loading: boolean
  updating: boolean
  error: boolean
}

const initialState: DistributionState = {
  distributedProducts: [],
  loading: false,
  updating: false,
  error: false,
}

function selectReducer(
  state = initialState,
  { type, payload, error }: { type: string; payload: any; error: boolean }
): DistributionState {
  switch (type) {
    case a.SELECT: {
      return {
        ...state,
        //@ts-ignore
        distributedProducts:
          payload.admissionNumber && payload.admissionNumber !== ''
            ? state.distributedProducts.some(
                (ad) => ad.admissionNumber === payload.admissionNumber
              )
              ? state.distributedProducts.filter(
                  (ad) => ad.admissionNumber !== payload.admissionNumber
                )
              : [
                  ...state.distributedProducts,
                  { admissionNumber: payload.admissionNumber },
                ]
            : state.distributedProducts.some(
                (ad) => ad.productId === payload.id
              )
            ? state.distributedProducts.filter(
                (ad) => ad.productId !== payload.id
              )
            : [...state.distributedProducts, { productId: payload.id }],
      }
    }
    case a.CONFIRM: {
      return {
        ...state,
        error: false,
        updating: true,
      }
    }
    case a.CONFIRMED: {
      return error
        ? {
            ...state,
            error: true,
            updating: false,
          }
        : {
            ...state,
            error: false,
            updating: false,
            distributedProducts: payload.distributedProducts || [],
          }
    }
    case a.LOAD: {
      return {
        ...state,
        distributedProducts: [],
        loading: true,
      }
    }
    case a.LOADED: {
      return error
        ? {
            ...state,
            distributedProducts: [],
            loading: false,
            error: true,
          }
        : {
            ...state,
            distributedProducts: payload.distributedProducts || [],
            loading: false,
            error: false,
          }
    }

    default:
      return state
  }
}

export default combineReducers({
  select: selectReducer,
  list: reducerFactory<DistributedProduct>(a),
})
