import actions from './actions'
import reducer, {
  getDefaultState,
} from '../../GeneralListFunctions/reducerFactory'

export interface GrowingPlanType {
  id: string
  name: string
}
// const year = new Date().getFullYear()

const customState = {
  ...getDefaultState<GrowingPlanType>(),
  selected: [],
}
export default reducer<GrowingPlanType>(actions, customState)
