import { all, call } from 'redux-saga/effects'

import auth from 'react-auth/lib/sagas/authentication'
import forgotPassword from 'react-auth/lib/sagas/forgotPassword'
import register from './Auth/logic/saga'

import filterSaga from './FilterType/saga'
import productSaga from './Product/saga'
import productsSaga, { productListSaga } from './Products/saga'
import map from './Map/saga'
import field from './FieldMap/saga'
import fieldOrganization from './FieldOrganizationMap/saga'
import singleFieldSaga from './FieldOrganizationMap/single/saga'
import unit from './Form/Unit/saga'
import unitInterval from './Form/Unit/unitInterval/saga'
import registration, { customCrud } from './Registration/saga'
import growingPeriod from './Registration/GrowingPeriod/saga'
import organizationFieldGrowingPeriod from './OrganizationField/New/GrowingPeriod/saga'
import organizationField from './OrganizationField/saga'
import farmer from './Farmer/saga'
import groupedCrop from './GroupedCrop/saga'
import productSingleSelect from './Products/ProductSingleSelect/saga'
import additiveSingleSelect from './Products/AdditiveSingleSelect/saga'
import comparisonSaga from './Comparison/saga'
import crop from './Product/Crop/List/saga'
import pest from './Product/Pest/List/saga'
import contract from './GroupedCrop/Contract/List/saga'
import growingPeriodAttributes from './GroupedCrop/Attributes/List/saga'
import advice from './Advice/saga'
import adviceWizard from './Advice/Wizard/saga'
import newAdvice from './Advice/New/saga'
import registrationAdvice from './Registration/New/Advice/saga'
import acivity from './Activity/saga'
import distribution from './Distribution/saga'
import observation from './Observations/saga'
import beneficialOrganism from './BeneficialOrganism/List/saga'
import sideEffect from './BeneficialOrganism/SideEffect/saga'
import filterActions from './Products/FilterAction/saga'
import provider from './Auth/providerLogic/saga'
import extraInfoAdmin from './Auth/ExtraInfoAdmin/saga'
import sendInvite from './Farmer/Organization/User/Invite/saga'
import knpvPest from './KNPV/saga'

export default function* rootSaga() {
  yield all([
    call(registration),
    call(knpvPest),
    call(beneficialOrganism),
    call(auth),
    call(provider),
    call(sideEffect),
    call(adviceWizard),
    call(observation),
    call(growingPeriodAttributes),
    call(customCrud),
    call(acivity),
    call(growingPeriod),
    call(unit),
    call(unitInterval),
    call(distribution),
    call(pest),
    call(register),
    call(filterSaga),
    call(productSaga),
    call(additiveSingleSelect),
    call(registrationAdvice),
    call(organizationFieldGrowingPeriod),
    call(singleFieldSaga),
    call(crop),
    call(field),
    call(fieldOrganization),
    call(map),
    call(contract),
    call(organizationField),
    call(farmer),
    call(groupedCrop),
    call(productSingleSelect),
    call(productsSaga),
    call(productListSaga),
    call(forgotPassword),
    call(comparisonSaga),
    call(advice),
    call(newAdvice),
    call(filterActions),
    call(extraInfoAdmin),
    call(sendInvite),
  ])
}
