import React from 'react'
import { Text, View } from 'react-native'
class PdfView extends React.Component {
  render() {
    return (
      <View>
        <Text>Not implemented..</Text>
      </View>
    )
  }
}
export default PdfView
