export const getItemLabel = (item, noWcode) =>
  noWcode ? item.name : item.name + (item.wCode && ` (${item.wCode})`)

const seconds = new Date().getTime() / 1000

export const getItemStatus = (
  introductionDate,
  lastDeliveryDate,
  lastUseDate,
  expirationDate,
  matchType
) => {
  // time now

  // als de introductie datum na de huidige tijd ligt
  if (introductionDate && introductionDate > seconds) {
    return 3
  }

  let status = 1
  if (expirationDate && expirationDate < seconds) {
    status = 3
  }

  // het middel mag niet meer worden gebruikt.
  if (lastDeliveryDate && lastDeliveryDate < seconds) {
    status = 3
  }

  // laatst opgebruiktermijn dus als de boer het nog mag opmaken. Mag hetgene hierboven overschrijven
  if (
    lastUseDate &&
    lastUseDate > seconds &&
    expirationDate &&
    expirationDate < seconds
  ) {
    status = 2
  }

  if (expirationDate && expirationDate > (lastUseDate && lastUseDate)) {
    return 0
  }

  if (matchType === 'exemption') {
    status = 1
  }

  return status
}
