import React, { useCallback, useState } from 'react'
import { View } from 'react-native'
import { ActivityIndicator, Appbar, Text } from 'react-native-paper'
import actions, * as aO from './actions'
import detailHOC from '../../GeneralListFunctions/detailHOC'
import { push } from '../../Navigation'

import safeAreaHOC from '../../WidthAndHeight/safeAreaHOC'
import AppbarHeader from '../../WidthAndHeight/AppbarHeader'
import AppbarResizer from '../../WidthAndHeight/AppbarResizer'
import UserList from './User/UserList'
import OrganizationTab from './OrganizationTab'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-translated'
import { getLanguage, getTranslatedString } from '../../language'
// import SprayTechniqueList from './SprayMachine/SprayTechniqueList'
// import SprayNozzleList from './SprayNozzle/SprayNozzleList'
import { Tabs, TabScreen } from 'react-native-paper-tabs'
import {
  ADVISOR_ADD_FARMER_ROUTE,
  // ADVISOR_FARMER_ROUTE,
  ADVISOR_FARMERS_ROUTE,
} from '../../Routes.config'

function UserTab({ organizationId, componentId }) {
  const { loading, error, object } = useSelector(
    state => state.farmer.organization.crud
  )
  const add = () => {
    push(componentId, {
      component: {
        name: ADVISOR_ADD_FARMER_ROUTE,
        passProps: {
          organizationId: object.id,
          organizationName: object.name,
        },
      },
    })
  }
  if (loading || error) {
    return (
      <View style={{ padding: 12 }}>
        {loading && <Text>Laden</Text>}
        {error && (
          <Text>
            <Translate text="errorFarmerCompany" />
          </Text>
        )}
      </View>
    )
  }

  return (
    <UserList
      organizationId={organizationId}
      componentId={componentId}
      add={add}
    />
  )
}
// function SprayNozzleTab({ organizationId, add, componentId }) {
//   const { loading, error } = useSelector(
//     state => state.farmer.organization.crud
//   )
//   if (loading || error) {
//     return (
//       <View style={{ padding: 12 }}>
//         {loading && <Text><Translate text="loading" /></Text>}
//         {error && (
//           <Text>
//             <Translate text="errorFarmerCompany" />
//           </Text>
//         )}
//       </View>
//     )
//   }
//
//   return (
//     <SprayNozzleList
//       organizationId={organizationId}
//       componentId={componentId}
//       add={add}
//     />
//   )
// }
// function SprayMachineTab({ organizationId, add, componentId }) {
//   const { loading, error } = useSelector(
//     state => state.farmer.organization.crud
//   )
//   if (loading || error) {
//     return (
//       <View style={{ padding: 12 }}>
//         {loading && <Text><Translate text="loading" /></Text>}
//         {error && (
//           <Text>
//             <Translate text="errorFarmerCompany" />
//           </Text>
//         )}
//       </View>
//     )
//   }
//
//   return (
//     <SprayTechniqueList
//       organizationId={organizationId}
//       componentId={componentId}
//       add={add}
//     />
//   )
// }

const tabRoutes = lang => [
  {
    key: 'organization',
    // title: 'company',
    title: getTranslatedString('company', lang),
    accessibilityLabel: 'Company',
    component: OrganizationTab,
  },
  {
    key: 'users',
    // title: 'users',
    title: getTranslatedString('users', lang),
    accessibilityLabel: 'Users',
    component: UserTab,
  },
  // {
  //   key: 'sprayTechnique',
  //   // title: 'sprayTechnique',
  //   title: getTranslatedString('sprayTechnique', lang),
  //   accessibilityLabel: 'sprayTechnique',
  //   component: SprayMachineTab,
  // },
  // {
  //   key: 'sprayNozzle',
  //   // title: 'sprayNozzle',
  //   title: getTranslatedString('sprayNozzle', lang),
  //   accessibilityLabel: 'SprayNozzle',
  //   component: SprayNozzleTab,
  // },
]

function FarmerDetail({
  // onBack,
  onSubmit,
  onRemove,
  componentId,
  crud: { object, loading, removing, creating, updating },
  match,
  // id,
}) {
  const language = getLanguage()

  const title = (object && object.name) || <Translate text="editCompany" />
  const orgCrud = useSelector(state => state.farmer.organization.crud.object)

  const [initialOrganization, setInitialOrganization] = useState(object)
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (object !== initialOrganization) {
      setInitialOrganization(object)
    }
  }, [object, initialOrganization])
  //
  // const navigationState = useMemo(() => {
  //   return {
  //     index,
  //     routes: routing,
  //   }
  // }, [routing, index])

  const onInnerBack = useCallback(() => {
    push(componentId, {
      component: {
        name: ADVISOR_FARMERS_ROUTE,
      },
    })
  }, [componentId])
  //
  // const add = useCallback(() => {
  //   const name = initialOrganization && initialOrganization.name
  //   push(componentId, {
  //     component: {
  //       name: routes.ADVISOR_ADD_FARMER_ROUTE,
  //       passProps: {
  //         organizationId: id,
  //         organizationName: name || 'undefined',
  //         organization: initialOrganization,
  //       },
  //     },
  //   })
  // }, [componentId, initialOrganization, id])
  const innerSubmit = () => {
    onSubmit()
    dispatch(aO.default.update(orgCrud))
  }

  console.log({initialOrganization})
  return (
    <>
      <AppbarHeader
        key="header"
        dark
        style={{ elevation: 0, justifyContent: 'center' }}
      >
        <AppbarResizer>
          <Appbar.BackAction onPress={onInnerBack} color="#fff" />
          <Appbar.Content title={title} color="#fff" />
          {object && object.id && !loading ? (
            <Appbar.Action icon={'delete'} onPress={onRemove} color="#fff" />
          ) : null}
          {object && object.id && !loading ? (
            <Appbar.Action icon={'check'} onPress={innerSubmit} color="#fff" />
          ) : null}
          {loading || updating || removing || creating ? (
            <ActivityIndicator style={{ padding: 12 }} color={'#FFF'} />
          ) : null}
        </AppbarResizer>
      </AppbarHeader>

      <Tabs
        mode={'scrollable'}
        showLeadingSpace={false}
        uppercase={false}
        style={{ alignItems: 'center' }}
        defaultIndex={
          match && match.params && match.params.tab
            ? Number(match.params.tab)
            : 0
        }
        // onChangeIndex={newIndex => {
        //   push(componentId, {
        //     component: {
        //       name: ADVISOR_FARMER_ROUTE,
        //       passProps: {
        //         organizationId: initialOrganization && initialOrganization.id,
        //         tab: newIndex,
        //       },
        //     },
        //   })
        // }}
      >
        {tabRoutes(language).map(route => {
          // const lang = process.env.REACT_APP_API_LANG || 'nl'
          // const title = getTranslatedString(
          //   route.title,
          //   lang === 'nl_nl' ? 'nl' : lang
          // )
          const title = route.title
          return (
            <TabScreen label={title}>
              <View style={{ marginTop: 8, flex: 1 }}>
                <route.component
                  key={route.key}
                  componentId={componentId}
                  organizationId={initialOrganization && initialOrganization.id}
                  // jumpTo={route.}
                />
              </View>
            </TabScreen>
          )
        })}
      </Tabs>
    </>
  )
}
//
// const styles = StyleSheet.create({
//   scrollView: {
//     flex: 1,
//   },
//   fill: {
//     flex: 1,
//   },
//
//   tabBarContainer: {
//     backgroundColor: c.greenColor,
//     paddingTop: 0,
//     paddingBottom: 0,
//     elevation: 4,
//     shadowColor: 'black',
//     shadowOpacity: 0.1,
//     shadowRadius: StyleSheet.hairlineWidth,
//     shadowOffset: {
//       height: StyleSheet.hairlineWidth,
//       width: 0,
//     },
//   },
//   tabBar: {
//     backgroundColor: c.greenColor,
//     elevation: 0,
//     shadowOpacity: 0,
//   },
//   tabStyle: { width: 'auto' },
//   labelStyle: { color: '#FFF' },
//   indicatorStyle: { backgroundColor: '#FFF' },
// })

const mapStateToProps = state => ({
  crud: state.farmer.organization.crud,
})

export default safeAreaHOC(
  detailHOC(FarmerDetail, mapStateToProps, actions, {
    optinEdit: true,
    enableDelete: true,
  })
)
