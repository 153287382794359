import React, { useCallback, useEffect, useMemo } from 'react'
import { Platform, StyleSheet } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { Appbar, IconButton, Portal, Snackbar } from 'react-native-paper'
import { push } from '../Navigation'
import * as routes from '../Routes.config'
import actions from './actions'
import a from './actions'
import PageHeader from '../PageHeader'
import GeneralList from '../GeneralListFunctions/GeneralList'
import { useActionsAsFunctions } from '../helpers/actions'
import SafeListItem from '../WidthAndHeight/SafeListItem'
import { fromTimestamp, getFriendlyDate } from '../dayjs'
import { Translator } from 'react-translated'

function RegistrationScreen({ componentId }) {
  let list = useSelector(state => state.registration.list)
  const crud = useSelector(state => state.registration.crud)
  const duplicateCrud = useSelector(state => state.duplicateReducer)
  const dispatch = useDispatch()

  const onPressDuplicate = useCallback(
    item => {
      dispatch(a.duplicate(item.id))
      // history.push(routes.DUPLICATE_REGISTRATION_ROUTE)
    },
    [dispatch]
  )
  useEffect(() => {
    if (duplicateCrud.duplicated) {
      const duplicateItem = { ...duplicateCrud.data, id: undefined }
      dispatch(a.setData(duplicateItem))
      push(componentId, {
        component: {
          name: routes.DUPLICATE_REGISTRATION_ROUTE,
          passProps: {
            step: 3,
          },
        },
      })
    }
  }, [duplicateCrud, componentId, dispatch])

  const extraParams = useMemo(() => {
    return {
      sort: '-date',
      preloadAll: true,
      'filter[organizationIds]': undefined,
    }
  }, [])

  const onPressAdd = useCallback(
    () =>
      push(componentId, {
        component: {
          name: routes.NEW_REGISTRATION_ROUTE,
        },
      }),
    [componentId]
  )

  const renderItem = useCallback(
    ({ item }) => {
      let appliedProducts =
        item?.appliedProducts?.length > 0
          ? item?.appliedProducts
              .map(
                it =>
                  `${it?.product?.name} ter bestrijding van ${it?.pest?.name}`
              )
              .join(', ')
          : ''
      let title = `${item?.organization?.name}${
        appliedProducts.length > 0 ? ` - ${appliedProducts}` : ''
      }`

      return (
        <SafeListItem
          title={title}
          description={`${
            item.performedAt?.date
              ? getFriendlyDate(fromTimestamp(item.performedAt?.date))
              : getFriendlyDate(fromTimestamp(item.performedAt?.startTime)) +
                ' - ' +
                getFriendlyDate(fromTimestamp(item.performedAt?.endTime))
          }${
            item?.growingPeriod?.crop?.name &&
            item?.growingPeriod?.crop?.name.length > 0
              ? ` - ${item?.growingPeriod?.crop?.name}`
              : ''
          }${
            item?.growingPeriod?.organizationField?.name &&
            item?.growingPeriod?.organizationField?.name.length > 0
              ? ` - ${item?.growingPeriod?.organizationField?.name}`
              : ''
          }`}
          onPress={() => {
            if (Platform.OS !== 'web') {
              // We only push on native, because on web we use "to". This is because "to" has extra hover effects on web.
              push(componentId, {
                component: {
                  name: routes.EDIT_REGISTRATION_ROUTE,
                  passProps: {
                    id: item.id,
                    step: 5,
                  },
                },
              })
            }
          }}
          // left={
          //   <SelectionComponent
          //     selectType={'checkbox'}
          //     status={isSelected ? 'checked' : 'unchecked'}
          //     onPress={() => select(item)}
          //   />
          // }
          right={
            <>
              {
                <IconButton
                  icon={'content-copy'}
                  color="#000"
                  onPress={() => onPressDuplicate(item)}
                />
              }
            </>
          }
          accessibilityLabel={`Open ${item.name}`}
          to={routes.EDIT_REGISTRATION_ROUTE + item.id}
          style={styles.listItem}
        />
      )
    },
    [componentId, onPressDuplicate]
  )

  const [error, setError] = React.useState(undefined)

  React.useEffect(() => {
    if (crud.createAdviceError) {
      setError(crud.createAdviceErrorMessage)
    }
    if (crud.createdAdvice) {
      // setShowDownloadModal(true)
    }
  }, [crud])

  const dispatchedActions = useActionsAsFunctions(actions)

  return [
    <Translator>
      {({ translate }) => (
        <GeneralList
          key={'generalList'}
          {...dispatchedActions}
          list={list}
          searchPlaceholder={translate({ text: 'searchRegistration' })}
          renderItem={renderItem}
          icon
          appbarContent={
            <PageHeader
              // back={() => back()}
              componentId={componentId}
              title={
                <Appbar.Content
                  title={translate({ text: 'registrations' })}
                  titleStyle={{
                    textAlign: 'center',
                  }}
                  subtitleStyle={{
                    textAlign: 'center',
                  }}
                />
              }
            />
          }
          extraParams={extraParams}
          onPressFab={onPressAdd}
          fabIcon={'plus'}
          fabLabel={translate({ text: 'newRegistration' })}
        />
      )}
    </Translator>,
    <Portal key={'snackbar'}>
      <Snackbar
        visible={!!error}
        onDismiss={() => setError(undefined)}
        action={{
          label: 'OK',
          color: '#FFF',
          onPress: () => {
            setError(undefined)
          },
        }}
      >
        {error}
      </Snackbar>
    </Portal>,
  ]
}

const styles = StyleSheet.create({
  tabs: { flexDirection: 'row', paddingLeft: 5 },
  tab: {},
  tabText: {
    paddingLeft: 16,
    paddingRight: 16,
    textAlignVertical: 'center',
    letterSpacing: 0.5,
    height: 46,
    lineHeight: 46,
  },
  tabTextSelected: {
    fontWeight: 'bold',
  },
})

export default RegistrationScreen
