import { all, call } from 'redux-saga/effects'

import activeSubstance from './ActiveSubstance/saga'
import admissionCategory from './AdmissionCategory/saga'
import admissionHolder from './AdmissionHolder/saga'
import crop from './Crop/saga'
import pest from './Pest/saga'
import productType from './ProductType/saga'
import location from './Location/saga'
import sprayMode from './SprayMode/saga'
import advisorFarmer from './AdvisorFarmer/saga'

import groundType from './GroundType/saga'
import region from './Region/saga'
import sprayType from './SprayType/saga'
import sprayProduct from './SprayProduct/saga'
import airAssisting from './AirAssisting/saga'
import bbchScale from './BBCHScale/saga'
import sprayDirection from './SprayDirection/saga'
import resistance from './Resistance/saga'
import sprayNozzle from './SprayNozzle/saga'
import sprayTechnique from './SprayTechnique/saga'
import beneficialOrganism from './BeneficialOrganism/saga'
import knpvCrop from './KNPVCrop/saga'

export default function* rootSaga() {
  yield all([
    call(sprayNozzle),
    call(knpvCrop),
    call(activeSubstance),
    call(admissionCategory),
    call(admissionHolder),
    call(sprayTechnique),
    call(resistance),
    call(crop),
    call(pest),
    call(productType),
    call(location),
    call(sprayMode),
    call(advisorFarmer),
    call(groundType),
    call(region),
    call(sprayType),
    call(sprayProduct),
    call(airAssisting),
    call(bbchScale),
    call(sprayDirection),
    call(beneficialOrganism),
  ])
}
