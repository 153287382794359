import React, { useCallback, useEffect, useState } from 'react'
import { Keyboard, StyleSheet, View } from 'react-native'
import actions from '../actions'
import registrationActions from '../actions'
import adviceActions from './Advice/actions'
import InformationScreen from './InformationScreen'
import ActivityScreen from './ActivityScreen'
import Swiper from '../../components/Swiper'
import Navigation from '../../Navigation'
import detailHOC from '../../GeneralListFunctions/detailHOC'
import BottomFab from '../../WidthAndHeight/BottomFab'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Translate, Translator } from 'react-translated'
import FieldsScreen from './FieldsScreen'
import PerformedByScreen from './PerformedByScreen'
import GrowingPeriodScreen from './GrowingPeriodScreen'
// import AdviceOrManual from './AdviceOrManual'

const getData = (advice, onChange) => {
  return [
    {
      key: 'activity',
      title: 'Activiteit',
      component: ActivityScreen,
      showNextButton: false,
    },
    // {
    //   key: 'adviceOrManual',
    //   title: 'Advies',
    //   component: AdviceOrManual,
    //   showNextButton: true,
    // },
    {
      key: 'fields',
      title: 'Velden',
      advice: advice,
      component: FieldsScreen,
      showNextButton: false,
    },
    {
      key: 'growingPeriod',
      title: 'Teelt',
      advice: advice,
      component: GrowingPeriodScreen,
      showNextButton: false,
    },
    {
      key: 'performedBy',
      title: 'Uitvoerder',
      advice: advice,
      component: PerformedByScreen,
      showNextButton: true,
    },
    {
      key: 'information',
      title: <Translate text="composeRegistration" />,
      advice: advice,
      component: InformationScreen,
      showNextButton: true,
      onChange: value => onChange('stage', value),
    },
  ].filter(n => n)
}

function Detail({
  onRemove,
  crud,
  componentId,
  onSubmit,
  adviceId,
  advice,
  onChange,
  match,
}) {
  const [index, setIndex] = useState(
    match?.params?.step ? Number(match?.params?.step) : 0
  )
  const list = useSelector(state => state.activity.list)
  const dispatch = useDispatch()

  useEffect(() => {
    if (list?.data?.length === 1 && !crud?.object?.activity?.id) {
      dispatch(registrationActions.setData({ activity: list.data[0] }))
    }
  }, [dispatch, list, crud])

  const [skippedFirst, setSkippedFirst] = useState(false)
  const [fromAdvice, setFromAdvice] = useState(false)
  const swiper = React.createRef()
  const data = getData(advice, onChange)

  const pop = useCallback(() => {
    Navigation.pop(componentId)
  }, [componentId])

  const prev = useCallback(() => {
    setFromAdvice(false)
    Keyboard.dismiss()
    if (index === 0 || match?.params?.id || (index === 1 && skippedFirst)) {
      pop()
      return
    }
    if (fromAdvice && index === 4) {
      setIndex(1)
      return
    }
    if (index === 2 && advice.object) {
      setIndex(index - 2)
      return
    }
    setIndex(index - 1)
  }, [index, match, skippedFirst, fromAdvice, advice.object, pop])

  const to = useCallback(
    i => {
      setIndex(i)
    },
    [setIndex]
  )

  const next = useCallback(
    (skip = false, fromAdviceProp = false) => {
      Keyboard.dismiss()

      if (skip) {
        setSkippedFirst(true)
      } else if (fromAdviceProp) {
        setFromAdvice(true)
        setIndex(4)
        return
      }
      const isLast = data.length - 1 === index

      if (isLast) {
        onSubmit()
      } else {
        setIndex(index + 1)
      }
    },
    [
      data.length,
      index,
      onSubmit,
      // onChange, crud.object
    ]
  )

  let nextLabel = 'save'
  if (index === 1) {
    nextLabel = 'continueWithoutAdvice'
  } else if (data[index + 1]) {
    nextLabel = 'next'
  }
  const currentScreen = (data && data[index]) || {}
  useEffect(() => {
    if (adviceId) {
      dispatch(
        adviceActions.load({ id: adviceId }, { params: { limit: 3000 } })
      )
    }
  }, [adviceId, dispatch])

  useEffect(() => {
    if (match?.params?.id) {
      dispatch(actions.load({ id: match.params.id }))
    }
  }, [match, dispatch])

  return (
    <View style={{ flex: 1 }}>
      <Swiper
        data={data}
        ref={swiper}
        index={index}
        onIndexChange={to}
        onPop={pop}
        currentScreen={currentScreen}
        onNext={next}
        onPrev={prev}
        passItemProps={{ onRemove }}
      />

      {currentScreen.showNextButton ? (
        <Translator>
          {({ translate }) => (
            <BottomFab
              testID="next"
              style={[styles.fab]}
              label={translate({ text: nextLabel })}
              icon="chevron-right"
              onPress={next}
              type="submit"
              loading={crud.creating}
            />
          )}
        </Translator>
      ) : null}
      <View style={styles.navigationFixer} />
    </View>
  )
}

const styles = StyleSheet.create({
  navigationFixer: {
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    width: 25,
  },
  fab: {
    zIndex: 200,
  },
})

const mapStateToProps = state => ({
  crud: state.registration.crud,
  advice: state.registrationAdvice.crud,
})
export default detailHOC(
  connect(mapStateToProps, adviceActions)(Detail),
  mapStateToProps,
  actions,
  {
    optinEdit: true,
    enableDelete: true,
    dontLoadOnMount: true,
    // disableSafe: true,
    withoutAddedJSON: true,
  }
)
