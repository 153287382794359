import React, { useCallback, useEffect } from 'react'
import { View } from 'react-native'
import {
  ActivityIndicator,
  Appbar,
  Button,
  Portal,
  Snackbar,
  Text,
  TextInput,
} from 'react-native-paper'
// @ts-ignore
import { Translate } from 'react-translated'
import { useDispatch, useSelector } from 'react-redux'
import a from '../actions'
import Resizer from '../../WidthAndHeight/Resizer'
import FormContainer from '../../Form/FormContainer'
import Fields from '../../Form/Fields'
import safeAreaHOC from '../../WidthAndHeight/safeAreaHOC'
import widthAndHeightHOC from '../../WidthAndHeight/widthAndHeightHOC'
import Sample from './Sample'
import { push } from '../../Navigation'
import * as routes from '../../Routes.config'
import { globalConfirm } from '../../helpers/alert'
import { getLanguage } from '../../language'
import KeyboardAvoidingScrollView from '../../WidthAndHeight/KeyboardAvoidingScrollView'
import PageHeader from '../../PageHeader'
import GeneralListHeader from '../../GeneralListFunctions/GeneralListHeader'

const fields = (language: string) => [
  {
    name: 'date',
    type: 'date',
    fieldProps: {
      label: language === 'nl' ? 'Datum' : 'Date',
      style: { flex: 1 },
    },
  },
]

function ObservationEdit({
  prev,
  remove,
  next,
  componentId,
}: {
  prev: () => any
  remove: () => any
  next: () => any
  componentId: string
}) {
  const crud = useSelector((state: any) => state.observation.crud)

  const language = getLanguage()
  const dispatch = useDispatch()

  const change = useCallback(
    (key: any, value: any) => {
      dispatch(a.setData({ ...(crud.object || {}), [key]: value }))
    },
    [dispatch, crud]
  )
  const [innerSamples, setInnerSamples] = React.useState(
    (crud.object &&
      crud.object.samples &&
      crud.object.samples.map((sample: any) => {
        return {
          ...sample,
          type: sample?.pest?.id ? 'pest' : 'beneficialOrganism',
        }
      })) ||
      []
  )
  const [done, setDone] = React.useState(false)
  useEffect(() => {
    if (crud.object && crud.object.samples && !done) {
      setDone(true)
      const cleanSamples = crud.object.samples.map((sample: any) => {
        return {
          ...sample,
          type: sample.type
            ? sample.type
            : sample?.pest?.id
            ? 'pest'
            : 'beneficialOrganism',
        }
      })
      setInnerSamples(cleanSamples)
    }
  }, [crud.object, done, setDone])

  const removeSample = (index: number, type: 'beneficialOrganism' | 'pest') => {
    globalConfirm({
      title: language === 'nl' ? 'Bevestiging' : 'Confirm',
      message:
        language === 'nl'
          ? 'Weet u zeker dat u deze observatie wilt verwijderen?'
          : 'Do you want to delete this observation?',
      onConfirm: () => {
        const typeSamples = innerSamples.filter(
          (sample: any) => sample.type === type
        )
        const otherTypeSamples = innerSamples.filter(
          (sample: any) => sample.type !== type
        )
        const newTypeSamples = typeSamples
          .map((sample: any, i: number) => {
            if (i === index) {
              return null
            } else {
              return sample
            }
          })
          .filter((n: any) => n)
        dispatch(
          a.setData({
            ...(crud.object || {}),
            samples: [...otherTypeSamples, ...newTypeSamples],
          })
        )

        setInnerSamples([...otherTypeSamples, ...newTypeSamples])
      },
    })
  }

  const changeSample = (
    key: any,
    value: any,
    index: number,
    type: 'beneficialOrganism' | 'pest'
  ) => {
    const typeSamples = innerSamples.filter(
      (sample: any) => sample.type === type
    )
    const otherTypeSamples = innerSamples.filter(
      (sample: any) => sample.type !== type
    )
    const newTypeSamples = typeSamples.map((sample: any, i: number) => {
      if (i === index) {
        return {
          ...sample,
          [key]: value,
        }
      } else {
        return sample
      }
    })
    dispatch(
      a.setData({
        ...(crud.object || {}),
        samples: [...otherTypeSamples, ...newTypeSamples],
      })
    )

    setInnerSamples([...otherTypeSamples, ...newTypeSamples])
  }

  const addSample = useCallback(
    (type: 'beneficialOrganism' | 'pest') => {
      const newSamples = [...innerSamples, { type: type, isOpen: true }]
      dispatch(a.setData({ ...(crud.object || {}), samples: newSamples }))
      setInnerSamples(newSamples)
    },
    [dispatch, crud, setInnerSamples, innerSamples]
  )

  const [error, setError] = React.useState(undefined)

  React.useEffect(() => {
    if (crud.creatingError) {
      setError(crud.creatingErrorMessage)
    }
    if (crud.updatingError) {
      setError(crud.updatingErrorMessage)
    }
    if (crud.created || crud.updated) {
      push(componentId, {
        component: {
          name: routes.OBSERVATION_ROUTE,
        },
      })
    }
  }, [crud, componentId])

  return (
    <>
      <GeneralListHeader
        showSearch={false}
        searchValue={''}
        navbarTranslate={0}
        navbarOpacity={1}
        navbarWhiteOpacity={1}
        listKey={'header'}
        renderStickyBelowHeader={null}
        searchPlaceholder={''}
        searchIcon={''}
        onSearchIconPress={() => null}
        autoFocusSearch={false}
        withoutHeaderBackground={false}
        onSearch={() => null}
        appbarContent={
          <PageHeader
            back={() => prev()}
            componentId={componentId}
            title={
              <Appbar.Content title={<Translate text={'observation'} />} />
            }
          />
        }
      />
      <KeyboardAvoidingScrollView>
        <Resizer style={{ marginTop: 56, paddingBottom: 30 }}>
          {crud && crud.loading ? (
            <ActivityIndicator />
          ) : (
            <FormContainer onSubmit={next}>
              <View
                style={[{ marginTop: 8, paddingLeft: 16, paddingRight: 16 }]}
              >
                <Text
                  onPressIn={undefined}
                  onPressOut={undefined}
                  android_hyphenationFrequency={undefined}
                  style={{ fontSize: 18 }}
                >
                  <Translate text={'pests'} />
                </Text>
                {(innerSamples || [])
                  .filter((it: any) => {
                    return it && it.type === 'pest'
                  })
                  .map((sample: any, index: number) => {
                    return (
                      <View style={{ marginTop: 8, marginBottom: 8 }}>
                        <Sample
                          key={index}
                          onRemove={(index: number) =>
                            removeSample(index, 'pest')
                          }
                          type={'pest'}
                          cropId={crud?.object?.growingPeriod?.crop?.id}
                          onChange={(key: string, value: any) =>
                            changeSample(key, value, index, 'pest')
                          }
                          sample={sample}
                          index={index}
                        />
                      </View>
                    )
                  })}
                <Button
                  onPress={() => addSample('pest')}
                  style={{ alignSelf: 'flex-end', marginTop: 6 }}
                  icon={'plus'}
                >
                  <Translate text={'add'} />
                </Button>
              </View>
              <View
                style={[{ marginTop: 8, paddingLeft: 16, paddingRight: 16 }]}
              >
                <Text
                  onPressIn={undefined}
                  onPressOut={undefined}
                  android_hyphenationFrequency={undefined}
                  style={{ fontSize: 18 }}
                >
                  <Translate text={'beneficialOrganisms'} />{' '}
                </Text>
                {(innerSamples || [])
                  .filter((it: any) => it && it.type === 'beneficialOrganism')
                  .map((sample: any, index: number) => {
                    return (
                      <View style={{ marginTop: 8, marginBottom: 8 }}>
                        <Sample
                          key={index}
                          type={'beneficialOrganism'}
                          onRemove={(index: number) =>
                            removeSample(index, 'beneficialOrganism')
                          }
                          cropId={crud?.object?.growingPeriod?.crop?.id}
                          onChange={(key: string, value: any) =>
                            changeSample(
                              key,
                              value,
                              index,
                              'beneficialOrganism'
                            )
                          }
                          sample={sample}
                          index={index}
                        />
                      </View>
                    )
                  })}
                <Button
                  onPress={() => addSample('beneficialOrganism')}
                  style={{ alignSelf: 'flex-end', marginTop: 6 }}
                  icon={'plus'}
                >
                  <Translate text={'add'} />
                </Button>
              </View>

              <View
                style={[{ marginTop: 8, paddingLeft: 16, paddingRight: 16 }]}
              >
                <TextInput
                  onPressIn={undefined}
                  onPressOut={undefined}
                  autoComplete={undefined}
                  multiline
                  label={<Translate text={'note'} />}
                  style={{
                    backgroundColor: '#fff',
                  }}
                  value={(crud.object && crud.object.note) || ''}
                  onChangeText={value => change('note', value)}
                  textAlign={undefined}
                />
              </View>
              <View style={{ padding: 10, paddingBottom: 16 }}>
                <Fields
                  fields={fields(language)}
                  values={crud.object}
                  onChange={(key: string, value: any) => change(key, value)}
                  onSubmit={next}
                />
              </View>
            </FormContainer>
          )}
        </Resizer>
      </KeyboardAvoidingScrollView>
      <Portal key={'snackbar'}>
        <Snackbar
          visible={!!error}
          onDismiss={() => setError(undefined)}
          action={{
            label: 'OK',
            color: '#FFF',
            onPress: () => {
              setError(undefined)
            },
          }}
        >
          {error}
        </Snackbar>
      </Portal>
    </>
  )
}

export default widthAndHeightHOC(safeAreaHOC(ObservationEdit))
