import React from 'react'

import { propTypes, defaultProps, color } from './standardVector'

export const width = 11
export const height = 8

const Vector = props => (
  <svg
    width={props.width || width}
    height={props.height || height}
    className={props.className}
    viewBox="0 0 11 8"
  >
    <path
      fill={color(props.color)}
      fillRule="evenodd"
      d="M15,20 C14.7440777,20 14.4881554,19.9023689 14.2928932,19.7071068 L11.2928932,16.7071068 C10.9023689,16.3165825 10.9023689,15.6834175 11.2928932,15.2928932 C11.6834175,14.9023689 12.3165825,14.9023689 12.7071068,15.2928932 L15,17.5857864 L20.2928932,12.2928932 C20.6834175,11.9023689 21.3165825,11.9023689 21.7071068,12.2928932 C22.0976311,12.6834175 22.0976311,13.3165825 21.7071068,13.7071068 L15.7071068,19.7071068 C15.5118446,19.9023689 15.2559223,20 15,20 L15,20 Z"
      transform="translate(-11 -12)"
    />
  </svg>
)

Vector.propTypes = propTypes
Vector.defaultProps = defaultProps

export default Vector
