import React from 'react'
import { StyleSheet, View } from 'react-native'
import showLocation from '../helpers/showLocation'
import { Button, IconButton } from 'react-native-paper'
import { Translate } from 'react-translated'

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    justifyContent: 'center',
    flex: 1,
    minWidth: 10,
    marginLeft: 10,
  },
  buttonContent: { height: 42 },
})
const blueTheme = {
  colors: {
    text: '#4285F4',
    primary: '#4285F4',

    placeholder: '#4285F4',
  },
}

function RouteButton({ title, longitude, latitude, small }) {
  const onNavigate = () => {
    showLocation({
      latitude,
      longitude,
      title, // optional
      googleForceLatLon: true, // optionally force GoogleMaps to use the latlon for the query instead of the title
      // googlePlaceId: 'ChIJGVtI4by3t4kRr51d_Qm_x58', // optionally specify the google-place-id
      // alwaysIncludeGoogle: true, // optional, true will always add Google Maps to iOS and open in Safari, even if app is not installed (default: false)
      dialogTitle: 'Open op kaart', // optional (default: 'Open in Maps')
      dialogMessage: 'Welke app wil je gebruiken?', // optional (default: 'What app would you like to use?')
      cancelText: 'Annuleer', // optional (default: 'Cancel')
      // appsWhiteList: ['google-maps'], // optionally you can set which apps to show (default: will show all supported apps installed on device)
      // app: 'uber'  // optionally specify specific app to use
    })
  }

  return (
    <View style={styles.root}>
      {small ? (
        <IconButton
          icon={'directions'}
          // mode={'outlined'}
          onPress={onNavigate}
          uppercase={false}
          contentStyle={styles.buttonContent}
          theme={blueTheme}
        />
      ) : (
        <Button
          icon={'directions'}
          // mode={'outlined'}
          onPress={onNavigate}
          uppercase={false}
          contentStyle={styles.buttonContent}
          theme={blueTheme}
        >
          <Translate text="navigate" />
        </Button>
      )}
    </View>
  )
}

export default RouteButton
