import React, { Component } from 'react'
import { NativeModules, Platform } from 'react-native'
import { connect } from 'react-redux'
import translation from './translations'
import { Provider as Translation } from 'react-translated'
import { setLanguage } from './language'
import { setLocale } from './dayjs'
import { setLocaleNavigation } from './Navigation'
import { setFilterTypesSorted } from './FilterType/config'

function getLang() {
  const supportedLanguages = ['nl', 'fr', 'de', 'en']
  return Array.isArray(navigator.languages)
    ? navigator.languages.find(l => supportedLanguages.some(sl => sl === l))
    : navigator.language
}
export function getLanguageCode() {
  let locale
  if (Platform.OS === 'android') {
    locale = NativeModules.I18nManager.localeIdentifier // "fr_FR"
    console.log({ locale })
  } else if (Platform.OS === 'ios') {
    locale =
      NativeModules.SettingsManager.settings.AppleLocale ||
      (NativeModules.SettingsManager.settings.AppleLanguages &&
        NativeModules.SettingsManager.settings.AppleLanguages.length > 0 &&
        NativeModules.SettingsManager.settings.AppleLanguages[0])
  } else if (Platform.OS === 'web') {
    locale = getLang()
  }
  // some browsers
  if (locale && locale.length === 2) {
    return locale.toLowerCase()
  }
  // fr_FR -> needs to be fr
  if (locale && locale.length > 2) {
    return locale.substring(0, 2).toLowerCase()
  }
  // just return undefined
  return locale
}

export function getSupportedLanguage(fallback) {
  // For now, disable the locale, just use the user language
  const supportedLanguages = ['nl', 'en']
  // Start of temp code block, see comment above
  if (supportedLanguages.some(sp => sp === fallback)) {
    console.log(' setting fallback: ', fallback)
    return fallback || 'nl'
  }
  // End of temp code block
  const locale = getLanguageCode()
  // if locale is supported
  if (supportedLanguages.some(sp => sp === locale)) {
    console.log('Yes locale is supported')
    return fallback || locale
  }
  // fallback on user language
  return fallback || 'nl'
}
class TranslationWrapper extends Component {
  shouldComponentUpdate = nextProps => {
    if (nextProps.userLanguage !== this.props.userLanguage) {
      return true
    }
    return false
  }
  getLanguageWithFallBack = () => {}
  _refresh = () => {
    // Refresh libraries and dependencies to use the language
    const locale = getSupportedLanguage(this.props.userLanguage)
    setLanguage(locale)
    setFilterTypesSorted(locale)
    setLocale(locale)
    setLocaleNavigation(locale)
  }
  componentDidMount() {
    this._refresh()
  }
  componentDidUpdate() {
    this._refresh()
  }
  render() {
    const locale = getSupportedLanguage(this.props.userLanguage)
    return (
      <Translation language={locale} translation={translation}>
        {this.props.children}
      </Translation>
    )
  }
}
const mapStateToProps = state => ({
  userLanguage:
    state.auth.user && state.auth.user.user && state.auth.user.user.language,
})
export default connect(mapStateToProps)(TranslationWrapper)
