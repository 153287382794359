export default {
  Egg: {
    nl: 'Ei',
    en: 'Egg',
  },
  Larva: {
    nl: 'Larve',
    en: 'Larva',
  },
  Nymph: {
    nl: 'Nimf',
    en: 'Nymph',
  },
  noDecisions: {
    nl: 'Geen beslissingen gevonden',
    en: 'No decisions found',
  },
  drawOnMap: {
    en: 'Draw pins on map',
    nl: 'Geef locatie aan',
  },
  changeCrops: {
    nl: 'Bewerk teelt',
    en: 'Change crop',
  },
  Pupa: {
    nl: 'Pop',
    en: 'Pupa',
  },
  comingSoon: {
    nl: 'Deze informatie is binnenkort beschikbaar',
    en: 'This information will shortly be available',
  },
  toRuleOrganism: {
    nl: 'Te bestrijden organisme',
    en: 'Organism',
  },
  onboardingTitle: {
    nl: 'Welkom in de Agro4All app',
    en: 'Welcome',
  },
  onboardingDescription: {
    nl: 'De Adviesgenerator is een nieuwe tool die het adviesproces digitaliseert en overzichtelijk maakt. Welke gewasbescherming is de juiste voor een specifieke situatie? Welke chemische middelen zijn wettelijk toegestaan? De Adviesgenerator geeft antwoorden op deze vragen en geeft een duidelijk overzicht over de gewasbescherming op een bedrijf.\n',
    en: 'The advice generator is a new tool which provides the possibility to generate an advice. What crop protection is the right choice for your situation? What chemical products are legally allowed? The advice generator gives answers to these questions and provides a clear overview of the crop protection on a farm.\n',
  },
  mortalityInfo: {
    nl: 'Mortaliteit informatie',
    en: 'Mortality information',
  },
  onboardingButton: {
    nl: 'Starten',
    en: 'Start',
  },

  addTwo: {
    nl: 'Bijvoegen',
    en: 'Add',
  },
  packaging: {
    nl: 'Verpakkingsvorm',
    en: 'Packaging',
  },
  latinName: {
    nl: 'Latijnse naam',
    en: 'Latin name',
  },
  latin: {
    nl: 'Latijn',
    en: 'Latin',
  },
  alternativeNames: {
    nl: 'Alternatieve namen',
    en: 'Alternative names',
  },
  code: {
    nl: 'Code',
    en: 'Code',
  },
  seeSource: {
    nl: 'Bekijk bron',
    en: 'See source',
  },
  naming: {
    nl: 'Naamgeving',
    en: 'Naming',
  },
  cropName: {
    nl: 'Teeltnaam',
    en: 'Crop name',
  },
  preferredName: {
    nl: 'Voorkeursnaam',
    en: 'Preferred name',
  },
  appliances: {
    nl: 'Toepassingen',
    en: 'Appliances',
  },
  Population: {
    nl: 'Bevolking',
    en: 'Population',
  },
  Mummy: {
    nl: 'Moeder',
    en: 'Mummy',
  },
  Adult: {
    nl: 'Volwassen',
    en: 'Adult',
  },
  parcel: {
    en: 'Parcel',
    nl: 'Teeltlocatie',
  },
  export: {
    nl: 'Exporteer',
    en: 'Export',
  },
  parcels: {
    en: 'Parcels',
    nl: 'Teeltlocaties',
  },
  low: {
    en: 'Low',
    nl: 'Laag',
  },
  medium: {
    en: 'Medium',
    nl: 'Gemiddeld',
  },
  searchSubDivisions: {
    nl: 'Zoek op naam of nummer',
    en: 'Search by name or number',
  },
  high: {
    en: 'High',
    nl: 'Hoog',
  },
  parcelLow: {
    en: 'parcel',
    nl: 'teeltlocatie',
  },
  adjuvant: {
    nl: 'Toevoegingsstof',
    en: 'Additive',
  },
  removeYourAccount: {
    nl: 'Verwijder uw account',
    en: 'Remove your account',
  },
  addAdjuvants: {
    nl: 'Voeg toevoegingsstof toe',
    en: 'Add additive',
  },
  parcelsLow: {
    en: 'parcels',
    nl: 'teeltlocaties',
  },
  productInformation: {
    nl: 'Productinformatie',
    en: 'Product information',
  },
  generateAdvice: {
    nl: 'Adviesrapport',
    en: 'Advice',
  },
  somethingWentWrong: {
    nl: 'Er is iets mis gegaan tijdens het genereren van het wettelijk toegestane advies. Neem contact op met support@agro4all.com of uw contactpersoon. \n\nVermeldt daarbij de volgende error:\n',
    en: 'Something went wrong while generating the legally allowed advice. Contact support@agro4all.com or your contact person. \n\nNotify them with the following error message:\n',
  },
  makeAdvice: {
    nl: 'Personaliseer advies',
    en: 'Personalize advice',
  },
  noPestsOrBeneficialsInObservation: {
    nl: 'Er zijn geen ziekten, plagen, onkruiden of natuurlijke vijanden waargenomen in de gekozen waarneming. Het is dus niet nodig om hier een advies voor te genereren.',
    en: 'There are no pests or beneficial organisms selected in the chosen observation. There is no need to create an advice for this observation.',
  },
  noProductsAreAllowed: {
    nl: 'Er zijn geen gewasbeschermingsmiddelen wettelijk toegestaan voor de gevonden ziekten, plagen, onkruiden of natuurlijke vijanden. Het is dus niet mogelijk om een advies te genereren voor de gekozen waarneming.',
    en: 'No products are legally allowed for the pests or beneficial organisms that are observed. Because of that, it is not possible to generate an advice for the chosen observation.',
  },
  forWhatCompany: {
    nl: 'Voor welk bedrijf?',
    en: 'For what company?',
  },
  whichRegistration: {
    nl: 'Voor welke registratie',
    en: 'For what registration?',
  },
  whichObservation: {
    nl: 'Voor welke waarneming?',
    en: 'For what observation?',
  },
  adjustAdvice: {
    nl: 'Personaliseer je advies',
    en: 'Personalize your advice',
  },
  generatingAdvice: {
    nl: 'Het advies wordt gegenereerd...',
    en: 'The advice is being generated...',
  },
  generatedAdvice: {
    nl: 'Het advies is succesvol gegenereerd. Hieronder staan de wettelijke opties. Selecteer de middelen die je wilt adviseren per waargenomen ziekte, plaag of onkruid.',
    en: 'The advice has been generated successfully. Please make a selection of the products which you want to advice.',
  },
  downloadAdvice: {
    nl: 'Advies downloaden of versturen',
    en: 'Download or send advice',
  },
  doneWithAdvice: {
    nl: 'Je advies is klaar!',
    en: 'Your advice is ready!',
  },
  doneWithAdviceSub: {
    nl: 'Download en/of verstuur het advies',
    en: 'Download and/or send the advice',
  },
  send: {
    nl: 'Versturen',
    en: 'Send',
  },
  goHome: {
    nl: 'Terug naar de homepagina',
    en: 'Back to homepage',
  },
  advice: {
    nl: 'Advies',
    en: 'Advice',
  },
  noObservationsYet: {
    nl: 'Geen waarnemingen gevonden',
    en: 'No observations found',
  },
  doObservation: {
    nl: 'Waarneming',
    en: 'Observation',
  },
  yes: {
    nl: 'Ja',
    en: 'Yes',
  },
  no: {
    nl: 'Nee',
    en: 'No',
  },
  userTitle: {
    nl: 'Accountgegevens',
    en: 'Account details',
  },
  email: {
    nl: 'E-mailadres',
    en: 'E-mail address',
  },
  companyName: {
    nl: 'Bedrijfsnaam',
    en: 'Company name',
  },
  requestAccountButton: {
    nl: 'Registreren',
    en: 'Register',
  },
  registerAsPrivateButton: {
    nl: 'Registreren as particulier',
    en: 'Register as private individual',
  },
  requestAccountError: {
    nl: 'Er ging iets fout bij het inschrijven van uw account. Controleer of alle velden juist zijn ingevuld. Komt u er niet uit? Neem contact op met support@agro4all.com.',
    en: 'An error appeared while registering your account. Please check if all fields are filled in correctly. Still stuck? Please contact support@agro4all.com.',
  },
  register: {
    nl: 'Registreren',
    en: 'Register',
  },
  loginMicrosoft: {
    nl: 'Inloggen met Microsoft',
    en: 'Login with Microsoft',
  },
  loginMicrosoftPossible: {
    nl: 'Uw organizatie ondersteund inloggen met Microsoft',
    en: 'Your organization supports signing in using Microsoft',
  },
  continueWithMicrosoft: {
    nl: 'Doorgaan met Microsoft',
    en: 'Continue with Microsoft',
  },
  continueWithoutMicrosoft: {
    nl: 'Doorgaan zonder Microsoft',
    en: 'Continue without Microsoft',
  },
  login: {
    nl: 'Inloggen',
    en: 'Login',
  },
  loggingIn: {
    nl: 'Aan het inloggen...',
    en: 'Logging in...',
  },
  registerHere: {
    nl: 'Registreer hier',
    en: 'Register here',
  },
  requestAccount: {
    nl: 'Account aanvragen',
    en: 'Request account',
  },
  requestSuccess: {
    nl: 'Geslaagde inschrijving',
    en: 'Request successful',
  },
  errorKvk: {
    nl: 'Er ging iets mis tijdens het zoeken in de KvK. Vul het KvK nummer van uw bedrijf handmatig in.',
    en: "Something went wrong while searching the KvK. Please enter your company's identification number manually.",
  },
  noResults: {
    nl: 'Geen resultaten',
    en: 'No results',
  },
  noResultsDesc: {
    nl: 'Er zijn geen resultaten in de KvK gevonden. Vul handmatig het KvK nummer in om door te gaan.',
    en: 'There are no results in the KvK. Enter the identification number manually to proceed.',
  },
  noLanguageSelected: {
    nl: 'Geen taal geselecteerd',
    en: 'No language selected',
  },
  selectALanguage: {
    nl: 'Selecteer een taal',
    en: 'Select a language',
  },
  language: {
    nl: 'Taal',
    en: 'Language',
  },
  saveSuccess: {
    nl: 'Je account gegevens zijn succesvol opgeslagen.',
    en: 'Your account details have been saved successfully.',
  },
  saveOrganizationSuccess: {
    nl: 'Je organisatie gegevens zijn succesvol opgeslagen.',
    en: 'Your organization deatils have been saved successfully',
  },
  chooseAdvice: {
    nl: 'Kies een advies',
    en: 'Choose an advice',
  },
  searchRegistrations: {
    nl: 'Kies een advies',
    en: 'Choose an advice',
  },
  continueWithoutAdvice: {
    nl: 'Doorgaan zonder advies',
    en: 'Continue without advice',
  },
  searchAdvice: {
    nl: 'Doorzoek adviezen',
    en: 'Search for an advice',
  },
  minimumCharacters: {
    nl: 'Minimaal 40 tekens',
    en: 'Minimum 40 characters',
  },
  reasonSentence: {
    nl: 'Welke voordelen verwacht je?',
    en: 'What benefits do you expect?',
  },
  saveError: {
    nl: 'Er is iets fout gegaan tijdens het opslaan. Probeer het nogmaals, of neem contact op met support@agro4all.com.',
    en: 'Something went wrong while saving your account. Try again, or contact support@agro4all.com.',
  },
  searchKvK: {
    nl: 'Zoek in de KvK resultaten',
    en: 'Search in KvK',
  },
  myAccount: {
    nl: 'Mijn account',
    en: 'My account',
  },
  account: {
    nl: 'Account',
    en: 'Account',
  },
  myCompany: {
    nl: 'Mijn bedrijf',
    en: 'My company',
  },
  myUsers: {
    nl: 'Mijn gebruikers',
    en: 'My users',
  },
  validationError: {
    nl: 'Vul alle velden in, je KvK nummer is verplicht. Deze kun je invullen door te zoeken in de KvK. Als er geen resultaten zijn, kun je handmatig je KvK nummer invullen.',
    en: 'Please fill all fields. Your company identification number is required. You can fill this in by searching in the KvK. If there are no results, you can fill in your company identification number manually.',
  },
  requestAccountSuccess: {
    nl: 'Je accountaanvraag is ontvangen. Zodra deze is verwerkt, ontvang je een e-mail waarmee je een wachtwoord kunt instellen.',
    en: 'Your account request has been received. Once processed, you will receive an email allowing you to set a password.',
  },
  class: {
    nl: 'Klasse',
    en: 'Class',
  },
  city: {
    nl: 'Plaats',
    en: 'City',
  },
  street: {
    nl: 'Straatnaam',
    en: 'Streetname',
  },
  houseNumber: {
    nl: 'Huisnummer',
    en: 'House number',
  },
  country: {
    nl: 'Land',
    en: 'Country',
  },
  organizationTitle: {
    nl: 'Bedrijfsgegevens',
    en: 'Company details',
  },
  identificationNumber: {
    nl: 'KvK nummer',
    en: 'Identification number',
  },
  forgotPassword: {
    nl: 'Wachtwoord vergeten',
    en: 'Forgot password',
  },
  password: {
    nl: 'Wachtwoord',
    en: 'Password',
  },
  balanced: {
    nl: 'Gebalanceerd',
    en: 'Balanced',
  },
  noUserOrPass: {
    nl: 'Geen gebruikersnaam of wachtwoord ingevuld',
    en: 'No username or password entered',
  },
  noAccount: {
    nl: 'Nog geen account?',
    en: 'No account yet?',
  },
  phase: {
    nl: 'Fase',
    en: 'Phase',
  },
  noObservationsYetDesc: {
    nl: 'Er moet eerst een waarneming zijn om een advies te geven',
    en: 'Do an observation before generating an advice',
  },
  sendAdvice: {
    nl: 'Verstuur het advies',
    en: 'Send the advice',
  },
  createUser: {
    nl: 'Gebruiker toevoegen en uitnodigen',
    en: 'Add and invite user',
  },
  onTheLoads: {
    nl: 'Aan het laden...',
    en: 'Loading...',
  },
  downloadAdviceButton: {
    nl: 'Download het advies',
    en: 'Download the advice',
  },
  selectReceivers: {
    nl: 'Naar wie wil je het advies mailen?',
    en: 'Select the people who you want to send the advice to.',
  },
  userDoesNotExist: {
    nl: 'Staat de gebruiker er niet tussen? Voeg hem hier toe:',
    en: 'Does the user not exist? Add the user here:',
  },
  homeProductsButton: {
    nl: 'Bekijk alle middelen',
    en: 'Show products',
  },
  homeGenerateAdviceButton: {
    nl: 'Maak adviesrapport',
    en: 'Generate advice',
  },
  homeKNPVButton: {
    nl: 'Bekijk KNPV lijst',
    en: 'Show KNPV list',
  },
  homeGroupedCropsButton: {
    nl: 'Beheer teelten',
    en: 'Manage crops',
  },
  homeDistributionsButton: {
    nl: 'Beheer distributies',
    en: 'Manage distributions',
  },
  homeRegistrationsButton: {
    nl: 'Beheer registraties',
    en: 'Manage registrations',
  },
  homeOrganizationFieldsButton: {
    nl: 'Beheer teeltlocaties',
    en: 'Manage parcels',
  },
  homeParcelsButton: {
    nl: 'Bekijk teeltlocaties op kaart',
    en: 'Show parcels on map',
  },
  homeOrganizationsButton: {
    nl: 'Beheer bedrijven',
    en: 'Manage companies',
  },
  homeObservationsButton: {
    nl: 'Beheer waarnemingen',
    en: 'Manage observations',
  },
  needHelp: {
    nl: 'Hulp nodig?',
    en: 'Need help?',
  },
  observations: {
    nl: 'Observaties',
    en: 'Observations',
  },

  noPests: {
    en: 'No pests found',
    nl: 'Geen ziekten, plagen of onkruiden gevonden',
  },
  noBeneficials: {
    en: 'No beneficial organisms found',
    nl: 'Geen natuurlijke vijanden gevonden',
  },
  noPestsDescription: {
    en: 'With selected filter(s)',
    nl: 'Met huidig(e) filter(s)',
  },
  noCrops: {
    en: 'No crops found',
    nl: 'Geen teelten gevonden',
  },
  noCropsDescription: {
    en: 'With selected filter(s)',
    nl: 'Met huidig(e) filter(s)',
  },
  noRestrictions: {
    en: 'No additional restrictions',
    nl: 'Geen aanvullende toepassingsvoorwaarden',
  },
  noRestrictionsDescription: {
    en: 'With selected filter(s)',
    nl: 'Met huidig(e) filter(s)',
  },
  restriction: {
    en: 'Restriction',
    nl: 'Restrictie',
  },
  for: {
    en: 'for',
    nl: 'voor',
  },
  searchParcels: {
    en: 'Search parcels',
    nl: 'Zoek in teeltlocaties',
  },
  searchGrowingPeriods: {
    en: 'Search growing periods',
    nl: 'Zoek in teeltperioden',
  },
  row: {
    nl: 'Rij',
    en: 'Row',
  },
  pole: {
    nl: 'Paal',
    en: 'Pole',
  },
  singlePole: {
    nl: 'Enkele paal',
    en: 'Single pole',
  },
  fromToPole: {
    nl: 'Van - tot paal',
    en: 'From - to pole',
  },
  poleEg: {
    nl: 'Paal (bv. 15)',
    en: 'Pole (e.g. 15)',
  },
  rowEg: {
    nl: 'Rij (bv. 7a)',
    en: 'Row (e.g. 7a)',
  },
  left: {
    nl: 'Links',
    en: 'Left',
  },
  right: {
    nl: 'Rechts',
    en: 'Right',
  },
  fromPole: {
    nl: 'Vanaf paal (bv. 14)',
    en: 'From pole (e.g. 14)',
  },
  toPole: {
    nl: 'Tot paal (bv. 18)',
    en: 'To pole (e.g. 18)',
  },
  observation: {
    en: 'Observation',
    nl: 'Observatie',
  },
  home: {
    en: 'Home',
    nl: 'Menu',
  },
  searchGrowingPlan: {
    en: 'Search for a year',
    nl: 'Zoek op een jaar',
  },
  searchObservations: {
    en: 'Search for observations',
    nl: 'Zoek op observaties',
  },
  searchObservation: {
    en: 'Search for an observation',
    nl: 'Zoek op een waarneming',
  },
  growingPlans: {
    en: 'Growing plans',
    nl: 'Teeltjaren',
  },
  changeEntrance: {
    en: 'Change entrance',
    nl: 'Ingang aanpassen',
  },
  addEntrance: {
    en: 'Add entrance',
    nl: 'Ingang toevoegen',
  },
  growingPlan: {
    en: 'Growing plan',
    nl: 'Teeltjaar',
  },
  selectGrowingPlan: {
    en: 'Select a growing plan',
    nl: 'Selecteer een teeltjaar',
  },
  new: {
    en: 'New',
    nl: 'Nieuw',
  },
  showOnMap: {
    en: 'Show on map',
    nl: 'Toon op kaart',
  },
  showParcelsOnWorldMap: {
    en: 'Show parcels on world map',
    nl: 'Toon teeltlocaties op de wereldkaart',
  },
  growingPeriods: {
    en: 'Growing periods',
    nl: 'Teeltperioden',
  },
  addGrowingPeriod: {
    en: 'Add growing period',
    nl: 'Teeltperiode toevoegen',
  },
  growingPeriodsForAllParcels: {
    en: 'Growing periods (for all parcels)',
    nl: 'Teeltperioden (voor alle teeltlocaties)',
  },
  name: {
    en: 'Name',
    nl: 'Naam',
  },
  pestName: {
    en: 'Pest name',
    nl: 'Ziektenaam',
  },
  firstName: {
    en: 'First name',
    nl: 'Voornaam',
  },
  enterFirstName: {
    en: 'Enter a first name',
    nl: 'Voer een voornaam in',
  },
  preposition: {
    en: 'Preposition',
    nl: 'Tussenvoegsel',
  },
  lastName: {
    en: 'Last name',
    nl: 'Achternaam',
  },
  enterLastName: {
    en: 'Enter a last name',
    nl: 'Voer een achternaam in',
  },
  company: {
    en: 'Company',
    nl: 'Bedrijf',
  },
  companies: {
    en: 'Companies',
    nl: 'Bedrijven',
  },
  generalInfoAllParcels: {
    en: 'General info which applies to all parcels',
    nl: 'Algemene informatie die geldt voor alle teeltlocaties',
  },
  location: {
    en: 'Location',
    nl: 'Locatie',
  },
  growingLocation: {
    en: 'Growing system',
    nl: 'Teeltsysteem',
  },
  growingLocations: {
    en: 'Growing systems',
    nl: 'Teeltsystemen',
  },
  searchGrowingLocation: {
    en: 'Search for growing location',
    nl: 'Zoek op teeltsysteem',
  },
  admissionholder: {
    en: 'Admissionholder',
    nl: 'Toelatingshouder',
  },
  application: {
    en: 'Application',
    nl: 'Toepassing',
  },
  admissionholders: {
    en: 'Admissionholders',
    nl: 'Toelatingshouders',
  },
  searchAdmissionholder: {
    en: 'Search for admissionholder',
    nl: 'Zoek op toelatingshouder',
  },
  organizationName: {
    en: '',
    nl: '',
  },
  activeSubstance: {
    en: 'Active substance',
    nl: 'Actieve stof',
  },
  activeSubstances: {
    en: 'Active substances',
    nl: 'Actieve stoffen',
  },
  author: {
    en: 'Author',
    nl: 'Auteur',
  },
  active: {
    en: 'active',
    nl: 'actief',
  },
  inactive: {
    en: 'inactive',
    nl: 'inactief',
  },
  unknown: {
    en: 'unknown',
    nl: 'onbekend',
  },
  resistance: {
    en: 'Resistance',
    nl: 'Resistentie',
  },
  resistances: {
    en: 'Resistance codes',
    nl: 'Resistentie codes',
  },
  showResistance: {
    en: 'Hide FRAC, HRAC or IRAC codes',
    nl: 'Verberg FRAC, HRAC of IRAC codes',
  },
  searchProduct: {
    en: 'Search for a product',
    nl: 'Zoek op een product',
  },
  productType: {
    en: 'Product type',
    nl: 'Producttype',
  },
  bufferzonePresent: {
    en: 'Buffer zone to parcel border present',
    nl: 'Teeltvrije zone tot perceelsrand aanwezig',
  },
  titleInviteUser: {
    en: 'Invite user',
    nl: 'Gebruiker uitnodigen',
  },
  inviteUser: {
    en: 'Are you sure you want to invite this user?',
    nl: 'Weet je zeker dat je deze gebruiker wilt uitnodigen?',
  },
  inviteUserFailed: {
    en: 'Inviting user failed',
    nl: 'Uitnodigen van gebruiker mislukt',
  },
  successInviteUser: {
    en: 'User is successfully invited',
    nl: 'Gebruiker is succesvol uitgenodigd',
  },
  bufferzoneNotPresent: {
    en: 'Buffer zone to parcel border not present',
    nl: 'Teeltvrije zone tot perceelsrand afwezig',
  },
  measuredFrom: {
    nl: 'Gemeten vanaf het midden van de laatste gewasrij of de laatste plant in de rij tot aan de perceelsgrens',
    en: 'Measured from the middle of the last crop row or the last plant in the row until the parcel border',
  },
  bufferzoneInCM: {
    nl: 'Teeltvrije zone tot perceelsrand in cm',
    en: 'Buffer zone until parcel border in cm',
  },
  cropFreeZone: {
    nl: 'Teeltvrije zone',
    en: 'Buffer zone',
  },
  subDivisions: {
    nl: 'Onderverdeling',
    en: 'Subdivision',
  },
  noUnitSelected: {
    nl: 'Geen eenheid geselecteerd',
    en: 'No unit selected',
  },
  selectUnit: {
    nl: 'Selecteer een eenheid',
    en: 'Select a unit',
  },
  newParcel: {
    nl: 'Nieuwe teeltlocatie',
    en: 'New parcel',
  },
  requiredUnitAndAmount: {
    nl: 'Eenheid en hoeveelheid zijn verplicht om door te gaan',
    en: 'Unit and amount are required to continue',
  },
  cropNeededForRestrictionsFirst: {
    nl: 'Voor resultaten is een teelt vereist.',
    en: 'A crop is required to show results.',
  },
  cropNeededForRestrictionsSecond: {
    nl: 'Voeg deze toe via het ',
    en: 'Add a crop via the ',
  },
  cropNeededForRestrictionsLink: {
    nl: 'teelt filter',
    en: 'crop filter',
  },
  compare: {
    nl: 'Vergelijk',
    en: 'Compare',
  },
  bufferzoneToSurfaceWaterPresent: {
    nl: 'Teeltvrije zone tot oppervlaktewater aanwezig',
    en: 'Buffer zone to surface water present',
  },
  bufferzoneToSurfaceWaterNotPresent: {
    nl: 'Teeltvrije zone tot oppervlaktewater afwezig',
    en: 'Buffer zone to surface water not present',
  },
  measuredFromSurfaceWater: {
    nl: 'Gemeten vanaf het midden van de laatste gewasrij of de laatste plant in de rij tot aan de insteek van de sloot',
    en: 'Measured from the middle of the last crop row or the last plant in the row until the entry of the ditch',
  },
  bufferzoneToSurfaceWaterInCM: {
    nl: 'Teeltvrije zone tot oppervlaktewater in cm',
    en: 'Buffer zone until surface water in cm',
  },
  demeter: {
    nl: 'Demeter',
    en: 'Demeter',
  },
  productTypes: {
    en: 'Product types',
    nl: 'Producttypen',
  },
  admissionCategory: {
    en: 'Admission category',
    nl: 'Toelatingscategorie',
  },
  products: {
    en: 'Products',
    nl: 'Middelen',
  },
  admissionCategories: {
    en: 'Admission categories',
    nl: 'Toelatingscategorieën',
  },
  date: {
    en: 'Date',
    nl: 'Datum',
  },
  startTime: {
    en: 'Starttime',
    nl: 'Startijd',
  },
  endTime: {
    en: 'Endtime',
    nl: 'Eindtijd',
  },
  expirationDate: {
    en: 'Expired products',
    nl: 'Vervallen middelen',
  },
  expirationDates: {
    en: 'Expired products',
    nl: 'Vervallen middelen',
  },
  showExpirationDate: {
    en: 'Show expired products',
    nl: 'Toon vervallen middelen',
  },
  groundwaterProtectionArea: {
    en: 'Groundwater protection area',
    nl: 'Grondwaterbeschermingsgebied',
  },
  groundwaterProtectionAreas: {
    en: 'Groundwater protection areas',
    nl: 'Grondwaterbeschermingsgebieden',
  },
  insideGroundwaterProtectionArea: {
    en: 'Inside groundwater protection area',
    nl: 'In een grondwaterbeschermingsgebied',
  },
  outsideGroundwaterProtectionArea: {
    en: 'Outside groundwater protection area',
    nl: 'Buiten een grondwaterbeschermingsgebied',
  },
  parcelCloseToWater: {
    en: 'Surface water',
    nl: 'Oppervlaktewater',
  },
  trueParcelCloseToWater: {
    en: 'Located at surface water',
    nl: 'Gelegen aan oppervlaktewater',
  },
  falseParcelCloseToWater: {
    en: 'Not located at surface water',
    nl: 'Niet gelegen aan oppervlaktewater',
  },
  farmer: {
    en: 'Company',
    nl: 'Bedrijf',
  },
  addSomething: {
    en: 'Add something',
    nl: 'Voeg iets toe',
  },
  farmers: {
    en: 'Companies',
    nl: 'Bedrijven',
  },
  selectFarmer: {
    en: 'Select a company',
    nl: 'Selecteer een bedrijf',
  },
  nothingSelected: {
    en: 'Nothing selected',
    nl: 'Niets geselecteerd',
  },
  type: {
    en: 'Company type',
    nl: 'Type bedrijf',
  },
  types: {
    en: 'Company types',
    nl: 'Type bedrijven',
  },
  selectType: {
    en: 'Select a company type',
    nl: 'Selecteer een type bedrijf',
  },
  contract: {
    en: 'Contract',
    nl: 'Contract',
  },
  selectResource: {
    en: 'Select a product',
    nl: 'Selecteer een middel',
  },
  selectProduct: {
    en: 'Select a product',
    nl: 'Selecteer een product',
  },
  searchContract: {
    en: 'Search for contract number',
    nl: 'Zoek op contractnummer',
  },
  selectContract: {
    en: 'Select a contract',
    nl: 'Selecteer een contract',
  },
  bufferZone: {
    en: 'Buffer zone',
    nl: 'Teeltvrije zone',
  },
  bufferZones: {
    en: 'Buffer zones',
    nl: 'Teeltvrije zones',
  },
  searchBufferZone: {
    en: 'Search in buffer zones',
    nl: 'Zoek op teeltvrije zones',
  },
  sprayDate: {
    en: 'Application date',
    nl: 'Toepassingsdatum',
  },
  sprayDates: {
    en: 'Application dates',
    nl: 'Toepassingsdatums',
  },
  searchSprayDate: {
    en: 'Search for application date',
    nl: 'Zoek op toepassingsdatums',
  },
  deselectAll: {
    nl: 'Deselecteer alles',
    en: 'Deselect all',
  },
  selectAll: {
    nl: 'Selecteer alles',
    en: 'Select all',
  },
  deselect: {
    nl: 'Deselecteer',
    en: 'Deselect',
  },
  select: {
    nl: 'Selecteer',
    en: 'Select',
  },
  done: {
    nl: 'Klaar',
    en: 'Done',
  },
  search: {
    nl: 'Zoeken',
    en: 'Search',
  },
  sprayDirection: {
    en: 'Spray direction',
    nl: 'Spuitrichting',
  },
  sprayDirections: {
    en: 'Spray directions',
    nl: 'Spuitrichtingen',
  },
  searchSprayDirections: {
    en: 'Search for spray directions',
    nl: 'Zoek op spuitrichting',
  },
  sprayTechnique: {
    en: 'Spray technique',
    nl: 'Toepassingstechniek',
  },
  sprayTechniques: {
    en: 'Spray techniques',
    nl: 'Toepassingstechnieken',
  },
  searchSprayTechnique: {
    en: 'Search for spray technique',
    nl: 'Zoek op toepassingstechniek',
  },
  sprayNozzle: {
    en: 'Spray nozzle',
    nl: 'Spuitdop',
  },
  sprayNozzles: {
    en: 'Spray nozzles',
    nl: 'Spuitdoppen',
  },
  searchSprayNozzle: {
    en: 'Search for spray nozzle',
    nl: 'Zoek op spuitdop',
  },
  distributed: {
    en: 'Distributed product',
    nl: 'Gedistribueerde middelen',
  },
  distributedMultiple: {
    en: 'Distributed products',
    nl: 'Gedistribueerde middelen',
  },
  pastFutureDistributed: {
    en: 'Distributed',
    nl: 'Gedistribueerd',
  },
  showDistributed: {
    en: 'Show only distributed products',
    nl: 'Toon alleen gedistribueerde middelen',
  },
  certification: {
    en: 'Certification',
    nl: 'Keurmerk',
  },
  certifications: {
    en: 'Certifications',
    nl: 'Keurmerken',
  },
  showCertification: {
    en: 'Filter certifications',
    nl: 'Filter keurmerken',
  },
  save: {
    en: 'Save',
    nl: 'Opslaan',
  },
  saveAndQuit: {
    en: 'Save and quit',
    nl: 'Opslaan en afronden',
  },
  addCrops: {
    en: 'Choose crops for selection',
    nl: 'Kies gewas voor selectie',
  },
  ok: {
    en: 'Ok',
    nl: 'Ok',
  },
  cancel: {
    en: 'Cancel',
    nl: 'Annuleren',
  },
  emptyCropForGP: {
    en: 'Are you sure you want to continue?',
    nl: 'Weet je zeker dat je door wilt gaan?',
  },
  emptyCropForGPMessage: {
    en: 'There are still some locations without crop. You can add these later as well.',
    nl: 'Er zijn nog enkele locaties zonder gewas. Je kunt deze ook later nog toevoegen.',
  },
  dontSave: {
    en: "Don't save",
    nl: 'Niet opslaan',
  },
  hideDemeterAndSkal: {
    en: 'Hide Demeter and Skal',
    nl: 'Verberg Demeter en Skal',
  },
  Next: {
    en: 'Next',
    nl: 'Ga verder',
  },
  next: {
    en: 'Next',
    nl: 'Volgende',
  },
  mergeParcel: {
    en: 'Merge parcel',
    nl: 'Teeltlocatie samenvoegen',
  },
  mergeParcels: {
    en: 'Merge parcels',
    nl: 'Teeltlocaties samenvoegen',
  },
  drawParcel: {
    en: 'Draw parcel',
    nl: 'Teken teeltlocatie',
  },
  giveLocationFor: {
    en: 'Click in the chosen area where ',
    nl: 'Klik in het gekozen gebied waar ',
  },
  stands: {
    en: ' is located',
    nl: ' staat',
  },
  selectMultipleParcels: {
    en: 'Select multiple parcels',
    nl: 'Selecteer meerdere teeltlocaties',
  },
  searchPlaceOrAddress: {
    en: 'Search place or address',
    nl: 'Zoek plaats of adres',
  },
  searchForCompanies: {
    en: 'Search for companies',
    nl: 'Zoek op bedrijven',
  },
  searchCompany: {
    en: 'Search for a company',
    nl: 'Zoek op een bedrijf',
  },
  filterByCompany: {
    en: 'Filter by company',
    nl: 'Filter op bedrijf',
  },
  editCompany: {
    en: 'Edit company',
    nl: 'Wijzig bedrijf',
  },
  integrated: {
    nl: 'Geïntegreerd',
    en: 'Integrated',
  },
  biological: {
    nl: 'Biologisch',
    en: 'Biological',
  },
  soilResearchAdd: {
    nl: 'Bodemonderzoek toevoegen',
    en: 'Add soil research',
  },
  add: {
    nl: 'Toevoegen',
    en: 'Add',
  },
  selectPest: {
    en: 'Select a target',
    nl: 'Selecteer een ziekte, plaag of onkruid',
  },
  noPestSelected: {
    en: 'No target selected',
    nl: 'Geen ziekte, plaag of onkruid geselecteerd',
  },
  selectBeneficialOrganism: {
    en: 'Select a beneficial organism',
    nl: 'Selecteer een natuurlijke vijand',
  },
  noBeneficialOrganismSelected: {
    en: 'No beneficial organism selected',
    nl: 'Geen natuurlijke vijand geselecteerd',
  },
  registrationDate: {
    nl: 'Registratiedatum',
    en: 'Registration date',
  },
  cadmium: {
    nl: 'Cadmium waarde (ppm)',
    en: 'Cadmium amount (ppm)',
  },
  soilResearches: {
    nl: 'Bodemonderzoeken',
    en: 'Soil researches',
  },
  amount: {
    nl: 'Aantal',
    en: 'Amount',
  },
  metricAmount: {
    nl: 'Metrische waarde (bv. per m3)',
    en: 'Metric amount (e.g. per m3)',
  },
  chemical: {
    nl: 'Chemisch',
    en: 'Chemical',
  },
  dosageProduct: {
    nl: 'Dosering van product',
    en: 'Dosage of the product',
  },
  unit: {
    nl: 'Eenheid',
    en: 'Unit',
  },
  registrationDone: {
    nl: 'Registratie is afgerond',
    en: 'Registration is done',
  },
  composeRegistration: {
    nl: 'Registratie samenstellen',
    en: 'Compose registration',
  },
  compositionSubstance: {
    nl: 'Samenstelling van de substantie',
    en: 'Composition of the substance',
  },
  growingStrategy: {
    en: 'Growing strategy',
    nl: 'Teeltstrategie',
  },
  connectCompany: {
    nl: 'Koppel bedrijf',
    en: 'Connect company',
  },
  selectDate: {
    nl: 'Selecteer een datum',
    en: 'Select a date',
  },
  addCompany: {
    en: 'Add company',
    nl: 'Bedrijf toevoegen',
  },
  companyTypes: {
    en: 'Company types',
    nl: 'Type bedrijven',
  },
  pest: {
    en: 'Target',
    nl: 'Ziekte, plaag of onkruid',
  },
  pests: {
    en: 'Targets',
    nl: 'Ziekten, plagen of onkruiden',
  },
  extraInfoError: {
    nl: 'Er ging iets fout bij het opsturen van de aanvullende informatie. Controleer of alle velden juist zijn ingevuld. Komt u er niet uit? Neem contact op met support@agro4all.com.',
    en: 'An error appeared while updating your information. Please check if all fields are filled in correctly. Still stuck? Please contact support@agro4all.com.',
  },
  extraInfoSuccess: {
    en: 'Thank you for the extra information. Once the information is verified, you will receive an email containing a link to choose a password.',
    nl: 'Bedankt voor de extra informatie. Zodra deze is verwerkt, ontvang je een e-mail waarmee je een wachtwoord kunt instellen.',
  },
  searchPest: {
    en: 'Search for target',
    nl: 'Zoek op ziekte, plaag of onkruid',
  },
  knpvSearchPest: {
    en: 'Search for target',
    nl: 'Zoek op ziekte of plaag',
  },
  knpvSideMenu: {
    en: 'KNPV list',
    nl: 'KNPV lijst',
  },
  beneficialOrganism: {
    en: 'Beneficial organism',
    nl: 'Natuurlijke vijand',
  },
  knpvPests: {
    en: 'KNPV Pest list',
    nl: 'KNPV Aantaster lijst',
  },
  infestation: {
    en: 'Infestation',
    nl: 'Aantaster',
  },
  beneficialOrganisms: {
    en: 'Beneficial organisms',
    nl: 'Natuurlijke vijanden',
  },
  sideEffect: {
    en: 'Side effect',
    nl: 'Neveneffect',
  },
  sideEffects: {
    en: 'Side effects',
    nl: 'Neveneffecten',
  },
  searchBeneficialOrganism: {
    en: 'Search for beneficial organism',
    nl: 'Zoek op natuurlijke vijand',
  },
  searchType: {
    en: 'Search for type',
    nl: 'Zoek op een type',
  },
  editFarmer: {
    en: 'Edit farmer',
    nl: 'Teler aanpassen',
  },
  editUser: {
    en: 'Edit user',
    nl: 'Gebruiker aanpassen',
  },
  addUser: {
    en: 'Add user',
    nl: 'Gebruiker toevoegen',
  },
  addNewUser: {
    en: 'Add a new user',
    nl: 'Voeg een nieuwe gebruiker toe',
  },
  approval: {
    nl: 'Ik denk graag mee in de doorontwikkeling van nieuwe functionaliteiten',
    en: 'I would like to help in the development of new features',
  },
  searchTerm: {
    en: ' search term',
    nl: 'e zoekterm',
  },
  admissionNr: {
    en: 'AdmissionNr',
    nl: 'Toelatingnr',
  },
  addedOn: {
    en: 'Added on',
    nl: 'Toegevoegd op',
  },
  newObservation: {
    en: 'New observation',
    nl: 'Nieuwe waarneming',
  },
  item: {
    en: 'Item',
    nl: 'Nummer',
  },
  // generateAdvice: {
  //   nl: 'Advies genereren',
  //   en: 'Generate advice',
  // },
  chooseLanguageAdvice: {
    nl: 'Kies een taal voor het advies',
    en: 'Choose a language for the advice',
  },
  users: {
    en: 'Users',
    nl: 'Gebruikers',
  },
  noUsers: {
    en: 'No users',
    nl: 'Geen gebruikers',
  },
  addNozzle: {
    en: 'Add nozzles',
    nl: 'Voeg spuitdoppen toe',
  },
  noNozzles: {
    en: 'No spray nozzles',
    nl: 'Geen spuitdoppen',
  },
  addTechnique: {
    en: 'Add techniques',
    nl: 'Voeg toepassingstechnieken toe',
  },
  noTechniques: {
    en: 'No spray techniques',
    nl: 'Geen toepassingstechnieken',
  },
  variety: {
    en: 'Variety',
    nl: 'Ras',
  },
  note: {
    en: 'Note',
    nl: 'Opmerking',
  },
  sowDate: {
    en: 'Sowing date',
    nl: 'Zaaidatum',
  },
  harvestDate: {
    en: 'Harvesting date',
    nl: 'Oogst datum',
  },
  contractNumber: {
    en: 'Contract number',
    nl: 'Contractnummer',
  },
  selectContractNumber: {
    en: 'Select a contract number',
    nl: 'Kies een contractnummer',
  },
  noContractSelected: {
    en: 'No contract selected',
    nl: 'Geen contract geselecteerd',
  },
  selectAttributes: {
    en: 'Specify crop',
    nl: 'Verbijzonder teelt',
  },
  noAttributesSelected: {
    en: 'No specifications selected',
    nl: 'Geen verbijzonderingen geselecteerd',
  },
  attributes: {
    en: 'Specifications',
    nl: 'Verbijzonderingen',
  },
  searchAttributes: {
    en: 'Search in specifications',
    nl: 'Zoek in verbijzonderingen',
  },
  selectCrop: {
    en: 'Select a crop',
    nl: 'Selecteer een teelt',
  },
  selectCropAndPest: {
    en: 'In order to see results, you need to select a crop and a pest.',
    nl: 'Voor resultaten is een teelt en ziekte, plaag of onkruid vereist.',
  },
  addTheseViaThe: {
    en: 'Add these via the ',
    nl: 'Voeg deze toe via de ',
  },
  searchActivity: {
    en: 'Search for an activity',
    nl: 'Zoek op een activiteit',
  },
  searchRegistration: {
    en: 'Search for a registration',
    nl: 'Zoek een registratie',
  },
  registered: {
    nl: 'Geregistreerd',
    en: 'Registered',
  },
  chooseActivity: {
    en: 'Choose an activity',
    nl: 'Kies een activiteit',
  },
  cropAndPest: {
    en: 'crop and pest ',
    nl: 'teelt en ziekte, plaag of onkruid ',
  },
  buttonsAbove: {
    en: 'buttons above.',
    nl: 'knoppen hierboven.',
  },
  noDosagesFound: {
    en: 'No dosages found',
    nl: 'Geen doseringen gevonden',
  },
  dosage: {
    nl: 'dosering',
    en: 'dosage',
  },
  differentDosages: {
    nl: 'verschillende doseringen',
    en: 'different dosages',
  },
  noCropSelected: {
    en: 'No crop selected',
    nl: 'Geen teelt geselecteerd',
  },
  share: {
    en: 'Share location',
    nl: 'Locatie delen',
  },
  bedekt: {
    en: 'Covered cultivation',
    nl: 'Bedekte teelt',
  },
  onbedekt: {
    en: 'Unlit cultivation',
    nl: 'Onbedekte teelt',
  },
  belicht: {
    en: 'Lit cultivation',
    nl: 'Belichte teelt',
  },
  onbelicht: {
    en: 'Uncovered cultivation',
    nl: 'Onbelichte teelt',
  },
  locationCopied: {
    en: 'Location copied',
    nl: 'Locatie gekopieerd',
  },
  searchCrop: {
    en: 'Search for crop',
    nl: 'Zoek op gewas',
  },
  crop: {
    en: 'Crop',
    nl: 'Teelt',
  },
  crops: {
    en: 'Crops',
    nl: 'Gewas of teeltobjecten',
  },
  cropsSideMenu: {
    en: 'Crops',
    nl: 'Teelten',
  },
  cropsOnMapSideMenu: {
    nl: 'Teelten op kaart',
    en: 'Parcels on map',
  },
  teler: {
    en: 'Farmer',
    nl: 'Teler',
  },
  general: {
    en: 'General',
    nl: 'Algemeen',
  },
  substance: {
    en: 'Substance',
    nl: 'Stof',
  },
  decision: {
    en: 'Decision',
    nl: 'Beslissing',
  },
  safety: {
    en: 'Safety',
    nl: 'Veiligheid',
  },
  version: {
    en: 'Version',
    nl: 'Versie',
  },
  advices: {
    en: 'Advices',
    nl: 'Adviezen',
  },
  prescription: {
    en: 'Prescription',
    nl: 'Gebruiksvoorschrift',
  },
  safetySheet: {
    en: 'Safety data sheet',
    nl: 'Veiligheidsblad',
  },
  label: {
    en: 'Label',
    nl: 'Commerciëel etiket',
  },
  dutch: {
    en: 'Dutch',
    nl: 'Nederlands',
  },
  english: {
    en: 'English',
    nl: 'Engels',
  },
  adviceIsGenerated: {
    nl: 'Het advies is gegenereerd',
    en: 'The advice is generated',
  },
  adviceFindBack: {
    nl: 'Het advies is terug te vinden in de lijst met adviezen. U kunt het advies hieronder direct openen.',
    en: 'You can find the advice in the list of advices. Below you can download the advice directly.',
  },
  openAdvicePDF: {
    nl: 'Open advies PDF',
    en: 'Open advice PDF',
  },

  // Product details table
  admissionHolder: {
    en: 'Admission holder',
    nl: 'Toelatingshouder',
  },
  distributors: {
    en: 'Distributors',
    nl: 'Distributeurs',
  },
  manufacturer: {
    en: 'Manufacturer',
    nl: 'Fabrikant',
  },
  wCode: {
    en: 'W-code',
    nl: 'W-code',
  },
  admissionNumber: {
    en: 'Admission number',
    nl: 'Toelatingsnummer',
  },
  admissionProductType: {
    en: 'Admission product type',
    nl: 'Toelatingsbasis',
  },
  admissionType: {
    en: 'Admission type',
    nl: 'Toelatingstype',
  },
  introductionDate: {
    en: 'Introduction date',
    nl: 'Introductiedatum',
  },
  changedDate: {
    en: 'Last change date',
    nl: 'Wijzigingsdatum',
  },
  expirationDateProduct: {
    en: 'Expiration date',
    nl: 'Expiratiedatum',
  },
  lastDeliveryDate: {
    en: 'Last delivery date',
    nl: 'Aflevertermijn',
  },
  lastUseDate: {
    en: 'Last use date',
    nl: 'Opgebruiktermijn',
  },
  useBy: {
    en: 'Use by',
    nl: 'Te gebruiken door',
  },
  fracCode: {
    en: 'FRAC code',
    nl: 'FRAC code',
  },
  hracCode: {
    en: 'HRAC code',
    nl: 'HRAC code',
  },
  iracCode: {
    en: 'IRAC code',
    nl: 'IRAC code',
  },
  planetProof: {
    en: 'PlanetProof',
    nl: 'PlanetProof',
  },
  mps: {
    en: 'MPS',
    nl: 'MPS',
  },
  none: {
    en: 'Without certification',
    nl: 'Geen keurmerk',
  },
  skal: {
    en: 'Skal',
    nl: 'Skal',
  },
  skip: {
    en: 'Skip',
    nl: 'Overslaan',
  },
  euRegistrationNumber: {
    en: 'European registration number',
    nl: 'Europees registratienummer',
  },
  phoneNumber: {
    en: '06-number',
    nl: '06-nummer',
  },
  enterPhoneNumber: {
    en: 'Enter a valid phone number',
    nl: 'Vul een geldig telefoonnummer in',
  },
  lowRisk: {
    en: 'Low risk',
    nl: 'Laag risico',
  },
  moa: {
    en: 'Mode of Action',
    nl: 'Mode of Action',
  },

  registrations: {
    en: 'Registrations',
    nl: 'Registraties',
  },
  newRegistration: {
    en: 'New registration',
    nl: 'Nieuwe registratie',
  },
  distributions: {
    en: 'Distributions',
    nl: 'Distributies',
  },
  intensitivity: {
    en: 'Intensitivity',
    nl: 'Intensiviteit',
  },
  distribution: {
    en: 'Distribution',
    nl: 'Distributie',
  },
  distributionSave: {
    en: 'Save distribution',
    nl: 'Distributie opslaan',
  },
  admissionInvalid: {
    en: 'This admission is not valid anymore. The admission holder can sell the product until ',
    nl: `De toelating is niet meer geldig. De toelatingshouder mag het middel verkopen t/m `,
  },
  productExpired: {
    en: 'This product with this subversion is expired and it is not allow to apply this product anymore!',
    nl: `Dit middel met dit W-nummer is vervallen en mag niet meer worden toegepast!`,
  },
  farmerCompany: {
    en: 'Farmer',
    nl: 'Landbouwbedrijf',
  },
  horticulturistCompany: {
    en: 'Horticulturist',
    nl: 'Tuinbouwbedrijf',
  },
  contractorCompany: {
    en: 'Contractor',
    nl: 'Loonbedrijf',
  },
  chooseTime: {
    en: 'Choose a time',
    nl: 'Kies een tijd',
  },
  transporterCompany: {
    en: 'Transporter',
    nl: 'Transportbedrijf',
  },
  addWithoutKvKSearch: {
    en: 'Add without KvK search',
    nl: 'Toevoegen zonder KvK koppeling',
  },
  unsavedWork: {
    en: 'You have unsaved work. Are you sure you want to continue?',
    nl: 'U heeft niet opgeslagen werk. Weet u zeker dat u terug wilt gaan?',
  },
  invite: {
    en: 'Invite',
    nl: 'Uitnodigen',
  },
  inviteFarmer: {
    en: 'Invite user',
    nl: 'Gebruiker uitnodigen',
  },
  navigate: {
    en: 'Navigate',
    nl: 'Navigeer',
  },
  area: {
    en: 'Area',
    nl: 'Oppervlakte',
  },
  searchProductName: {
    en: 'Search for product name',
    nl: 'Zoek op productnaam',
  },
  loading: {
    en: 'Loading..',
    nl: 'Laden..',
  },
  errorFarmerCompany: {
    en: 'Error loading farmer',
    nl: 'Fout bij laden landbouwbedrijf',
  },
  chooseGrowingPeriod: {
    en: 'Choose a growingperiod',
    nl: 'Kies een teelt',
  },
  searchPerformer: {
    en: 'Search a performer',
    nl: 'Zoek een uitvoerder',
  },
  registrationPerformer: {
    en: 'Who performed the task',
    nl: 'Wie heeft de taak uitgevoerd',
  },
  addProduct: {
    en: 'Add a product',
    nl: 'Voeg een product toe',
  },
  searchGrowingPeriod: {
    en: 'Search a growingperiod',
    nl: 'Zoek een teelt',
  },
  searchParcel: {
    en: 'Search a parcel',
    nl: 'Zoek een teeltlocatie',
  },
  chooseParcel: {
    en: 'Choose a parcel',
    nl: 'Kies een teeltlocatie',
  },
  chooseSection: {
    en: 'Choose a section',
    nl: 'Kies een sectie',
  },
  searchSection: {
    en: 'Search a section',
    nl: 'Zoek een sectie',
  },
  errorParcel: {
    en: 'Error loading parcel',
    nl: 'Fout bij laden teeltlocatie',
  },
  errorParcels: {
    en: 'Error loading parcels',
    nl: 'Fout bij laden teeltlocaties',
  },
  noResult: {
    en: 'No result',
    nl: 'Geen resultaat',
  },
  tryAnother: {
    en: 'Try another',
    nl: 'Probeer een ander',
  },
  cleanStart: {
    en: 'A clean start',
    nl: 'Een schone start',
  },
  addObject: {
    en: 'Add an object',
    nl: 'Voeg een object toe',
  },
  parcelInfo: {
    en: 'Parcel information',
    nl: 'Teeltlocatie informatie',
  },
  missingItem: {
    en: 'No items found',
    nl: 'Geen items gevonden',
  },
  problem: {
    en: 'Something missing or incorrect?',
    nl: 'Ontbreekt er iets of klopt er iets niet?'
  },
  missingItemDescription: {
    en: 'Please check the spelling or try different keywords to find results, or report a missing item to Agro4all',
    nl: 'Controleer de spelling of probeer andere zoekwoorden om resulaten te vinden, of meld een missend item aan Agro4All',
  },
  problemDescription: {
    en: 'Help us improve the data. Report if information is missing or wrong so we can update it',
    nl: 'Help ons de gegevens te verbeteren. Meld het als er informatie ontbreekt of fout is, zodat we dit kunnen bijwerken',
  },
  reportMissingItem: {
    en: 'Report a missing item',
    nl: 'Meld een missend item',
  },
  reportProblem: {
    en: 'Report a problem',
    nl: 'Meld een probleem',
  },
  emailSubjectMissingItem: {
    en: 'App: missing item',
    nl: 'App: missend item',
  },
  emailSubjectProblem: {
    en: 'App: problem',
    nl: 'App: probleem',
  },
  emailTextMissingItem: {
    en: 'I want to report that an item is missing.\nThe missing item is: ...',
    nl: 'Ik wil melden dat er een item mist.\nHet missende item is: ...',
  },
  emailIssueRelatedTo: {
    en: 'The issue is related to:',
    nl: 'Het probleem is gerelateerd aan:',
  },
  emailTextProblem: {
    en: 'I want to report that there is something missing or that there is a problem in the app.\nThe missing item or problem is: ...',
    nl: 'Ik wil melden dat er een iets ontbreekt of dat er een probleem is in de app.\nHet missende item is of probleem is: ...',
  },
  emailError: {
    en: 'No email app can be opened.\n\nPlease send the following email to: ',
    nl: 'Er kan geen email app geopend worden.\n\nStuur de volgende email naar: ',
  },
  copyEmailTemplate: {
    en: 'Copy email',
    nl: 'Kopieer email',
  },
}
