import { useSelector } from 'react-redux'
import { RootState } from '../reducers'

export const mediumWidth = 700
export const largeWidth = 1150

interface WidthAndHeightValues {}

export function useWidthAndHeight(): WidthAndHeightValues {
  const width = useSelector((state: RootState) => state.widthAndHeight.width)
  const height = useSelector((state: RootState) => state.widthAndHeight.height)
  const isMedium = width >= mediumWidth
  const isLarge = width >= largeWidth
  return {
    width,
    height,
    isMedium,
    isLarge,
  }
}
