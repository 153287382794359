import redirectWhenAuthenticatedHOC from 'react-auth/lib/hocs/redirectWhenAuthenticatedHOC'
import requireLoginHOC from 'react-auth/lib/hocs/requireLoginHOC'
import { pushAuth, pushHome } from './Navigation'

import { Platform } from 'react-native'
import paramsAsProp from './paramsAsPropsHOC'

import ComparisonScreen from './Comparison'
import FilterModalScreen from './FilterType'
import ProductScreen from './Product'

import ProductsListScreen from './Products/ProductsListScreen'
import Logout from './Auth/Logout'
import PdfScreen from './Pdf/Pdf'
import ConnectedScreen from './Auth/ConnectedLogin'
import ForgotScreen from './Auth/ForgotPassword'
import RegisterScreen from './Auth/Register'
import AccountScreen from './Auth/Account'
import OrganizationScreen from './Auth/Organization'
import ChangeScreen from './Auth/ChangePassword'
import SignInScreen from './Auth'
import TermsScreen from './TermsScreen'
import SideMenu from './SideMenu'
import WrongSubscription from './WrongSubscription'
import HomeScreen from './Home'
import extraInfoAdmin from './Auth/ExtraInfoAdmin/ExtraInfoAdmin'

// teeltlocaties
import FieldListScreen from './OrganizationField/FieldListScreen'
import FieldNewScreen from './OrganizationField/New'
import GrowingPeriodNewScreen from './GroupedCrop/New'
import FieldDetailScreen from './OrganizationField/FieldDetailScreen'
import FieldRouteScreen from './OrganizationField/FieldRouteScreen'

// boerenbedrijven
import FarmersListForAdvisorScreen from './Farmer/Advisor/FarmersListForAdvisorScreen'
import AddFarmer from './Farmer/Organization/AddFarmer'
import EditFarmer from './Farmer/Organization/EditFarmer'
import EditSingleFarmer from './Farmer/Organization/User/EditFarmer'
import InviteFarmer from './Farmer/Organization/User/InviteFarmer'
import EditUsers from './Auth/Users/EditUsers'
import UserList from './Auth/Users/UserList'
import InviteUser from './Auth/Users/InviteUser'
// import EditFarmer from './Auth/Users/EditFarmer'
// import EditSingleFarmer from './Auth/Users/EditFarmer'
// teelten
import GroupedCropsListScreen from './GroupedCrop/GroupedCropsListScreen'
import GroupedCropOnMap from './GroupedCrop/GroupedCropOnMap'

// distributie
import DistributionListScreen from './Distribution/DistributionListScreen'

// adviezen
import AdviceListScreen from './Advice/AdvicesListScreen'
import AdviceGroupedCropsScreen from './Advice/New/AdviceGroupedCropsScreen'
import AdviceLinesScreen from './Advice/New/AdviceLinesScreen'
import AdvicePestsScreen from './Advice/New/AdvicePestsScreen'
import AdviceProductsScreen from './Advice/New/AdviceProductsScreen'
import AdviceFieldsScreen from './Advice/New/AdviceFieldsScreen'
import AdviceDetailScreen from './Advice/AdviceDetailScreen'
// import FieldsOrganizationMap from './FieldOrganizationMap/single/SingleFieldMap'
import FieldsOrganizationMapScreen from './FieldOrganizationMap/FieldsOrganizationMapScreen'
import RegistrationDetailScreen from './Registration/RegistrationDetailScreen'
import RegistrationScreen from './Registration/RegistrationScreen'
import NewRegistrationScreen from './Registration/New'
import InformationScreen from './Registration/New/InformationScreen'

import ObservationScreen from './Observations/ObservationScreen'
import NewObservationScreen from './Observations/New'

import RedirectWithFilters from './Products/RedirectWithFilters'
import AdviceStepper from './Observations/AdviceStepper'
import AdviceWizardStepper from './Advice/Wizard/AdviceWizardStepper'
import AdvicePDFViewer from './Advice/Wizard/AdvicePDFViewer'
import OnboardingScreen from './OnboardingScreen'
import PestDetailScreen from './KNPV/PestDetailScreen'
import PestList from './KNPV/PestList'

const redirectWhenAuthenticated = component => {
  return redirectWhenAuthenticatedHOC(component, pushHome)
}
export const routeObjectToWeb = lr => ({
  key: lr.path,
  path: lr.pathOnWeb || lr.path,
  exact: lr.exact,
  component: lr.component,
})
const requireLogin = component => {
  if (process.env.REACT_APP_DISABLE_AUTHENTICATION === 'true') {
    return component
  }
  return requireLoginHOC(component, pushAuth, null, true)
}

export const AUTH_SCREEN_ROUTE = '/auth'
export const HOME_ROUTE = `/home`

export const WRONG_SUBSCRIPTION_ROUTE = '/wrong-subscription'

export const ONBOARDING_ROUTE = '/onboarding'
export const PRODUCTS_ROUTE = `/product`
export const REDIRECT_WITH_FILTERS_ROUTE = `/redirect-with-filters`
export const PRODUCT_ROUTE = `/product/`
export const COMPARISON_ROUTE = `/product/comparison/`

export const LOGOUT_ROUTE = '/logout'

export const OBSERVATION_ROUTE = '/observations'
export const NEW_OBSERVATION_ROUTE = '/observations/new/'
export const KNPV_LIST_ROUTE = '/knpv-list/'
export const KNPV_DETAIL_ROUTE = '/knpv/'

export const EXTRA_INFO_ADMIN_ROUTE = '/extra-info'

export const CONNECTED_LOGIN_ROUTE = '/connected-login'
export const FORGOT_PASSWORD_ROUTE = '/auth/forgot-password'
export const REGISTER_SCREEN_ROUTE = '/auth/register'
export const ACCOUNT_SCREEN_ROUTE = '/account'
export const ORGANIZATION_SCREEN_ROUTE = '/organization'
export const CHANGE_PASSWORD_ROUTE = '/change-password'
export const LOGIN_ROUTE = '/auth'
export const TERMS_ROUTE = '/terms'
export const ADVICE_PDF_ROUTE = '/advice-pdf/'
export const OBSERVATION_ADVICE_STEPPER_ROUTE = '/observation/advice/'

// export const ORGANIZATION_FIELD_FARMERS_ROUTE = `/organization-field/farmer`
export const FIELDS_ROUTE = '/field-list/'
export const FIELD_DETAIL_ROUTE = '/field/edit/'
export const FIELD_NAVIGATION_ROUTE = '/route/'
export const NEW_FIELD_ROUTE = '/field/edit/new/'
export const ORGANIZATION_FIELDS_ON_MAP_ROUTE =
  '/organization-field/farmer/map/'

export const DISTRIBUTION_LIST_ROUTE = '/distribution'

export const ADVICE_LIST_ROUTE = '/advice'
export const ADVICE_NEW_CROP_ROUTE = '/advice/new/crop/'
export const ADVICE_NEW_FIELD_ROUTE = '/advice/new/crop/field/'
export const ADVICE_NEW_PEST_ROUTE = '/advice/new/crop/field/pest/'
export const ADVICE_NEW_PRODUCT_ROUTE = '/advice/new/crop/field/pest/product/'
export const ADVICE_NEW_LINES_ROUTE =
  '/advice/new/crop/field/pest/product/lines/'
export const ADVICE_DETAIL_ROUTE = '/advice/'
export const ADVICE_WIZARD_ROUTE = '/advice/wizard/'

export const ADVISOR_FARMERS_ROUTE = '/advisor/farmer'
export const ADVISOR_FARMER_ROUTE = '/advisor/farmer/'
export const ADVISOR_ADD_FARMER_ROUTE = '/advisor/farmer/add/'

export const EDIT_FARMER_ROUTE = '/advisor/farmer/edit/:organizationId/:id'
export const NEW_ADVISOR_FARMER_ROUTE = '/advisor/farmer/new/'

export const GROUPED_CROP_ROUTE = '/grouped-crop'
export const GROUPED_CROP_ON_MAP_ROUTE = '/grouped-crop/field/'

export const PDF_ROUTE = '/pdf/'

export const FILTER_MODAL_ROUTE = '/:context/filter/'
export const SIDE_MENU_COMPONENT = 'sidemenu'

// Registration routes
export const ORGANIZATION_USERS_ROUTE = '/organization/users'
export const EDIT_ORGANIZATION_USER_ROUTE =
  '/organization/users/edit/:organizationId/:id'
export const ADD_ORGANIZATION_USER_ROUTE = '/organization/users/add'

export const REGISTRATION_ROUTE = '/registration'
export const NEW_REGISTRATION_ROUTE = '/registration/new/'
export const EDIT_REGISTRATION_ROUTE = '/registration/edit/'
export const DUPLICATE_REGISTRATION_ROUTE = '/registration/new/'
export const NEW_GROWING_PERIOD_SCREEN = '/field/growing-period/new/'

const isWeb = Platform.OS === 'web'
const wrapDetailRoute = component => {
  if (isWeb) {
    // same behaviour as native params as props instead of url
    return paramsAsProp(component)
  }
  return component
}

export const listRoutes = [
  {
    path: AUTH_SCREEN_ROUTE,
    exact: true,
    deepLinkWithoutLogin: true,
    component: redirectWhenAuthenticated(SignInScreen),
  },
  {
    path: HOME_ROUTE,
    exact: false,
    component: requireLogin(HomeScreen),
  },
  {
    path: PRODUCTS_ROUTE,
    exact: false,
    component: requireLogin(ProductsListScreen),
  },
  {
    path: KNPV_LIST_ROUTE,
    pathOnWeb: `${KNPV_LIST_ROUTE}:parentId?`,
    exact: true,
    component: wrapDetailRoute(requireLogin(PestList)),
  },
  {
    path: DISTRIBUTION_LIST_ROUTE,
    exactOnSmallScreen: true,
    exact: true,
    component: requireLogin(DistributionListScreen),
  },
  {
    path: OBSERVATION_ROUTE,
    exactOnSmallScreen: true,
    exact: true,
    component: requireLogin(ObservationScreen),
  },
  {
    path: REGISTRATION_ROUTE,
    exactOnSmallScreen: true,
    exact: true,
    component: requireLogin(RegistrationScreen),
  },
  {
    path: WRONG_SUBSCRIPTION_ROUTE,
    exactOnSmallScreen: true,
    exact: true,
    component: requireLogin(WrongSubscription),
  },
  {
    path: ONBOARDING_ROUTE,
    exactOnSmallScreen: true,
    exact: true,
    component: requireLogin(OnboardingScreen),
  },

  {
    path: ADVICE_LIST_ROUTE,
    exactOnSmallScreen: true,
    exact: true,
    component: requireLogin(AdviceListScreen),
  },

  {
    path: ADVISOR_FARMERS_ROUTE,
    exactOnSmallScreen: true,
    exact: true,
    component: requireLogin(FarmersListForAdvisorScreen),
  },
  {
    path: GROUPED_CROP_ROUTE,
    exactOnSmallScreen: true,
    exact: false,
    component: requireLogin(GroupedCropsListScreen),
  },
  {
    path: LOGOUT_ROUTE,
    exact: true,
    component: requireLogin(Logout),
  },
  {
    path: TERMS_ROUTE,
    exact: true,
    component: requireLogin(TermsScreen),
  },
  {
    path: ADVICE_PDF_ROUTE,
    pathOnWeb: `${ADVICE_PDF_ROUTE}:url`,
    exact: true,
    component: wrapDetailRoute(requireLogin(AdvicePDFViewer)),
  },
  {
    path: PDF_ROUTE,
    pathOnWeb: `${PDF_ROUTE}:url/:type?`,
    exact: true,
    component: wrapDetailRoute(PdfScreen),
  },
  {
    path: OBSERVATION_ADVICE_STEPPER_ROUTE,
    pathOnWeb: `${OBSERVATION_ADVICE_STEPPER_ROUTE}:id?`,
    exact: true,
    component: wrapDetailRoute(AdviceStepper),
  },
  {
    path: SIDE_MENU_COMPONENT,
    pathOnWeb: `${SIDE_MENU_COMPONENT}`,
    exact: true,
    component: SideMenu,
  },
  {
    path: CHANGE_PASSWORD_ROUTE,
    deepLinkWithoutLogin: true,
    pathOnWeb: `${CHANGE_PASSWORD_ROUTE}/:secret?`,
    exact: true,
    component: ChangeScreen,
  },
  {
    path: EXTRA_INFO_ADMIN_ROUTE,
    deepLinkWithoutLogin: true,
    pathOnWeb: `${EXTRA_INFO_ADMIN_ROUTE}/:secret?`,
    exact: true,
    component: wrapDetailRoute(extraInfoAdmin),
  },
]

export const detailRoutes = [
  // {
  //   path: `${NEW_REGISTRATION_ROUTE}/:adviceId?`,
  //   exact: true,
  //   component: wrapDetailRoute(requireLogin(NewRegistrationScreen)),
  // },
  {
    path: COMPARISON_ROUTE,
    pathOnWeb: `${COMPARISON_ROUTE}:ids`,
    exact: true,
    component: wrapDetailRoute(requireLogin(ComparisonScreen)),
  },
  {
    path: FORGOT_PASSWORD_ROUTE,
    exact: true,
    component: wrapDetailRoute(ForgotScreen),
  },
  {
    path: REGISTER_SCREEN_ROUTE,
    exact: true,
    deepLinkWithoutLogin: true,
    component: wrapDetailRoute(RegisterScreen),
  },
  {
    path: ACCOUNT_SCREEN_ROUTE,
    exact: true,
    component: wrapDetailRoute(requireLogin(AccountScreen)),
  },
  {
    path: ORGANIZATION_SCREEN_ROUTE,
    exact: true,
    component: wrapDetailRoute(requireLogin(OrganizationScreen)),
  },
  {
    path: NEW_FIELD_ROUTE,
    pathOnWeb: `${NEW_FIELD_ROUTE}:fromRoute?`,
    exact: true,
    component: wrapDetailRoute(requireLogin(FieldNewScreen)),
  },
  {
    path: NEW_GROWING_PERIOD_SCREEN,
    pathOnWeb: `${NEW_GROWING_PERIOD_SCREEN}:fromRoute?`,
    exact: true,
    component: wrapDetailRoute(requireLogin(GrowingPeriodNewScreen)),
  },
  {
    path: FIELD_DETAIL_ROUTE,
    pathOnWeb: `${FIELD_DETAIL_ROUTE}:id`,
    exact: true,
    component: wrapDetailRoute(requireLogin(FieldDetailScreen)),
  },
  {
    path: FIELD_NAVIGATION_ROUTE,
    pathOnWeb: `${FIELD_NAVIGATION_ROUTE}:fieldCoordinates/:entrance?`,
    exact: true,
    component: wrapDetailRoute(FieldRouteScreen),
  },
  {
    path: ADVICE_WIZARD_ROUTE,
    pathOnWeb: `${ADVICE_WIZARD_ROUTE}:step?/:companyId?/:observationId?/:adviceId?`,
    exact: true,
    component: wrapDetailRoute(requireLogin(AdviceWizardStepper)),
  },
  {
    path: FIELDS_ROUTE,
    pathOnWeb: `${FIELDS_ROUTE}:fromRoute?`,
    exact: true,
    component: wrapDetailRoute(requireLogin(FieldListScreen)),
  },
  //
  // New advice screens
  //
  {
    path: ADVICE_NEW_CROP_ROUTE,
    exact: true,
    component: wrapDetailRoute(requireLogin(AdviceGroupedCropsScreen)),
  },
  {
    path: ORGANIZATION_FIELDS_ON_MAP_ROUTE,
    exact: true,
    component: wrapDetailRoute(requireLogin(FieldsOrganizationMapScreen)),
  },
  {
    path: NEW_REGISTRATION_ROUTE,
    pathOnWeb: `${NEW_REGISTRATION_ROUTE}:step?/:id?`,
    exact: true,
    component: wrapDetailRoute(requireLogin(NewRegistrationScreen)),
  },
  {
    path: EDIT_REGISTRATION_ROUTE,
    pathOnWeb: `${EDIT_REGISTRATION_ROUTE}:id?`,
    exact: true,
    component: wrapDetailRoute(requireLogin(RegistrationDetailScreen)),
  },
  {
    path: DUPLICATE_REGISTRATION_ROUTE,
    pathOnWeb: `${DUPLICATE_REGISTRATION_ROUTE}:step?/:id?`,
    exact: true,
    component: wrapDetailRoute(requireLogin(InformationScreen)),
  },
  {
    path: NEW_OBSERVATION_ROUTE,
    pathOnWeb: `${NEW_OBSERVATION_ROUTE}:step?/:id?`,
    exact: true,
    component: wrapDetailRoute(requireLogin(NewObservationScreen)),
  },
  {
    path: ADVICE_NEW_FIELD_ROUTE,
    exact: true,
    component: wrapDetailRoute(requireLogin(AdviceFieldsScreen)),
  },
  {
    path: ADVICE_NEW_PEST_ROUTE,
    exact: true,
    component: wrapDetailRoute(requireLogin(AdvicePestsScreen)),
  },
  {
    path: ADVICE_NEW_PRODUCT_ROUTE,
    exact: true,
    component: wrapDetailRoute(requireLogin(AdviceProductsScreen)),
  },
  {
    path: ADVICE_NEW_LINES_ROUTE,
    exact: true,
    component: wrapDetailRoute(requireLogin(AdviceLinesScreen)),
  },
  //
  // End of new advice screens
  //
  {
    path: ADVISOR_ADD_FARMER_ROUTE,
    pathOnWeb: `${ADVISOR_ADD_FARMER_ROUTE}:organizationId/:organizationName`,
    exact: true,
    component: wrapDetailRoute(requireLogin(InviteFarmer)),
  },
  {
    path: ADD_ORGANIZATION_USER_ROUTE,
    pathOnWeb: `${ADD_ORGANIZATION_USER_ROUTE}`,
    exact: true,
    component: wrapDetailRoute(requireLogin(InviteUser)),
  },
  {
    path: NEW_ADVISOR_FARMER_ROUTE,
    exact: true,
    component: wrapDetailRoute(requireLogin(AddFarmer)),
  },
  {
    path: ADVISOR_FARMER_ROUTE,
    pathOnWeb: `${ADVISOR_FARMER_ROUTE}:id/:tab?`,
    exact: true,
    component: wrapDetailRoute(requireLogin(EditFarmer)),
  },
  {
    path: ORGANIZATION_USERS_ROUTE,
    pathOnWeb: `${ORGANIZATION_USERS_ROUTE}`,
    exact: true,
    component: wrapDetailRoute(requireLogin(UserList)),
  },
  {
    path: EDIT_ORGANIZATION_USER_ROUTE,
    pathOnWeb: `${EDIT_ORGANIZATION_USER_ROUTE}`,
    exact: true,
    component: wrapDetailRoute(requireLogin(EditUsers)),
  },
  {
    path: EDIT_FARMER_ROUTE,
    pathOnWeb: EDIT_FARMER_ROUTE,
    exact: true,
    component: wrapDetailRoute(requireLogin(EditSingleFarmer)),
  },
  {
    path: GROUPED_CROP_ON_MAP_ROUTE,
    pathOnWeb: `${GROUPED_CROP_ON_MAP_ROUTE}:fieldId/:fieldName?`,
    exact: true,
    component: wrapDetailRoute(requireLogin(GroupedCropOnMap)),
  },
  {
    path: REDIRECT_WITH_FILTERS_ROUTE,
    pathOnWeb: `${REDIRECT_WITH_FILTERS_ROUTE}`,
    exact: false,
    component: wrapDetailRoute(requireLogin(RedirectWithFilters)),
  },
  {
    path: FILTER_MODAL_ROUTE,
    pathOnWeb: `${FILTER_MODAL_ROUTE}:activeId/:parentId?`,
    exact: true,
    component: wrapDetailRoute(requireLogin(FilterModalScreen)),
  },

  {
    path: PRODUCT_ROUTE,
    pathOnWeb: `${PRODUCT_ROUTE}:id`,
    exact: true,
    component: wrapDetailRoute(requireLogin(ProductScreen)),
  },
  {
    path: KNPV_DETAIL_ROUTE,
    pathOnWeb: `${KNPV_DETAIL_ROUTE}:id`,
    exact: true,
    component: wrapDetailRoute(requireLogin(PestDetailScreen)),
  },
  {
    path: ADVICE_DETAIL_ROUTE,
    pathOnWeb: `${ADVICE_DETAIL_ROUTE}:id`,
    exact: true,
    component: wrapDetailRoute(requireLogin(AdviceDetailScreen)),
  },

  {
    path: CONNECTED_LOGIN_ROUTE,
    pathOnWeb: `${CONNECTED_LOGIN_ROUTE}`,
    exact: true,
    deepLinkWithoutLogin: true,

    component: wrapDetailRoute(ConnectedScreen),
  },
]

export const allRoutes = detailRoutes.concat(listRoutes)
