import React from 'react'
import FilterTool from './FilterType/FilterTool'

function AdvisorFilter({ componentId, context, key }) {
  // const user = useSelector(state => idx(state, _ => _.auth.user.user) || {})
  // if (!hasRole(user, 'farmer') && !hasRole(user, 'horticulturist')) {
  return (
    <FilterTool
      componentId={componentId}
      context={context}
      key={key}
      withoutText
    />
  )
  // } else {
  //   return null
  // }
}

export default AdvisorFilter
