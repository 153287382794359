import { actionCreator as ac } from '../actionCreator'

const ns = type => `COMPARISON_${type}`

export const LOAD = ns('LOAD')
export const LOADED = ns('LOADED')
export const LOAD_DOSAGES = ns('LOAD_DOSAGES')
export const LOADED_DOSAGES = ns('LOADED_DOSAGES')
export const PIN = ns('PIN')
export const UNPIN = ns('UNPIN')

export const load = ac(LOAD)
export const loaded = ac(LOADED)
export const loadDosages = ac(LOAD_DOSAGES)
export const loadedDosages = ac(LOADED_DOSAGES)
export const pin = ac(PIN)
export const unpin = ac(UNPIN)
