import React from 'react'
import SafeListItem from './SafeListItem'
import Resizer from './Resizer'

export default function Touchable(props) {
  return (
    <Resizer>
      <SafeListItem {...props} />
    </Resizer>
  )
}
