import React from 'react'
import { Animated, Dimensions, Platform, ScrollView, View } from 'react-native'
import { ActivityIndicator, Text, TouchableRipple } from 'react-native-paper'

import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import ReadMore from './ReadMore'
import styles from './styles'
import comparisonHOC from './comparisonHOC'
import safeAreaHOC from '../WidthAndHeight/safeAreaHOC'
import { Translate } from 'react-translated'

const showLabel = (mIndex, isFixed) => {
  if (Platform.OS === 'android') {
    return isFixed ? mIndex === 0 : true
  }
  return mIndex === 0
}

const capitalizeFirstLetter = string =>
  string.charAt(0).toUpperCase() + string.slice(1)

const { width } = Dimensions.get('window')

const Comparison = props => {
  return (
    <ScrollView decelerationRate={'fast'}>
      {props.matchRows.length === 0 && (
        <View style={styles.row}>
          <View style={styles.helpText}>
            {props.loading && (
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: 16,
                  paddingLeft: 16 + props.safe.left,
                }}
              >
                <ActivityIndicator />
                <Text style={{ paddingLeft: 16 }}>
                  <Translate text={'loading'} />
                </Text>
              </View>
            )}
            {!props.loading && (
              <View
                style={{
                  width,
                  padding: 16,
                  paddingLeft: 10 + props.safe.left,
                }}
              >
                <Text
                  style={{ fontSize: 16, marginBottom: 10, fontWeight: '700' }}
                >
                  <Translate text={'noDosagesFound'} />
                </Text>
                <Text>
                  <Translate text={'selectCropAndPest'} />
                </Text>
                <Text>
                  <Translate text={'addTheseViaThe'} />
                  <Text style={styles.filterLabel}>
                    <Translate text={'cropAndPest'} />
                  </Text>
                  <Translate text={'buttonsAbove'} />
                </Text>
              </View>
            )}
          </View>
        </View>
      )}
      {props.matchRows.map(row => (
        <View style={styles.row} key={row.key}>
          {row.tds.map((td, tIndex) => {
            const isFixed = td.pinned
            const ColView = isFixed ? Animated.View : View

            if (td.isSpecification) {
              return (
                <Animated.View
                  key={td.key}
                  style={{ transform: [{ translateX: props.left }] }}
                >
                  <TouchableRipple
                    style={[
                      styles.col,
                      styles.specificationRow,
                      { width: td.colSpan * 230 },
                      styles.fixed,
                    ]}
                    testID={`toggle_${tIndex}`}
                    accessibilityTraits={'button'}
                    accessibilityComponentType={'button'}
                    accessibilityRole={'button'}
                    onPress={
                      td.isSpecification
                        ? () => props.toggleDosage(td.mIndex)
                        : () => {}
                    }
                  >
                    <View style={styles.specificationView}>
                      {td.isCollapsed ? (
                        <Icon
                          size={20}
                          style={{ marginRight: 10 }}
                          name={'chevron-up'}
                        />
                      ) : (
                        <Icon
                          size={20}
                          style={{ marginRight: 10 }}
                          name={'chevron-down'}
                        />
                      )}
                      <Text style={styles.specificationText}>
                        {td.values && capitalizeFirstLetter(td.values[0].text)}
                      </Text>
                    </View>
                  </TouchableRipple>
                </Animated.View>
              )
            }
            return (
              <ColView
                key={td.key}
                style={[
                  styles.col,
                  { width: td.colSpan * 230 },
                  isFixed && styles.fixedCol,
                  isFixed && { transform: [{ translateX: props.left }] },
                ]}
              >
                {showLabel(tIndex, isFixed) && (
                  <Animated.View
                    style={[
                      styles.labelView,
                      Platform.OS !== 'android' &&
                        !isFixed && { transform: [{ translateX: props.left }] },
                    ]}
                  >
                    <Text style={styles.label}>{td.label}</Text>
                  </Animated.View>
                )}

                {td.values && td.values.length > 0 && (
                  <ReadMore numberOfLines={3} style={{ marginTop: 10 }}>
                    {td.values.map((value, vIndex) => (
                      <Text
                        style={[styles.value, { marginTop: 0 }]}
                        key={vIndex}
                      >
                        {value.before && (
                          <Text style={styles.countLabel}>
                            {vIndex !== 0 && '\n'}
                            {value.before}
                            {'  '}
                          </Text>
                        )}
                        {`${
                          value && value.text && value.text !== ''
                            ? value.text
                            : '-'
                        }`}
                      </Text>
                    ))}
                  </ReadMore>
                )}
                {td.values.length === 0 && <Text style={styles.value}>-</Text>}
              </ColView>
            )
          })}
        </View>
      ))}

      <Animated.View
        style={[
          styles.specificationRow,
          { width: props.ids.length * 230 },
          styles.fixed,
          { transform: [{ translateX: props.left }] },
        ]}
      >
        <View
          style={[
            styles.specificationView,
            { padding: 10, paddingLeft: 20 + props.safe.left },
          ]}
        >
          <Text style={styles.specificationText}>
            <Translate text={'productInformation'} />
          </Text>
        </View>
      </Animated.View>
      {props.rows.map(({ key, label, results }, rIndex) => (
        <View style={styles.row} key={key}>
          {results &&
            results.map((result, mIndex) => {
              // const isFixed = props.pinned && mIndex === 0
              const isFirst = mIndex === 0
              const isFixed = isFirst && props.pinned
              const ColView = isFixed ? Animated.View : View
              const FirstView = !isFixed ? Animated.View : View
              return (
                <ColView
                  style={[
                    styles.col,
                    isFixed && styles.fixedCol,
                    isFixed && { transform: [{ translateX: props.left }] },
                    isFirst && { paddingLeft: 10 + props.safe.left },
                  ]}
                  key={`${rIndex}_${mIndex}`}
                >
                  {showLabel(mIndex, isFixed) && (
                    <FirstView
                      style={[
                        styles.labelView,
                        { left: 10 + props.safe.left },
                        Platform.OS !== 'android' &&
                          !isFixed && {
                            transform: [{ translateX: props.left }],
                          },
                      ]}
                    >
                      <Text style={styles.label}>{label}</Text>
                    </FirstView>
                  )}
                  <Text style={styles.value}>
                    {result && result.hasValue ? result.value : '-'}
                  </Text>
                </ColView>
              )
            })}
        </View>
      ))}
      <View style={{ height: props.safe.bottom }} />
    </ScrollView>
  )
}

// Comparison.DefaultProps = {
//
// }

export default safeAreaHOC(comparisonHOC(Comparison))
