export function getAreaFromGrowingPeriod(growingPeriod) {
  if (
    (!growingPeriod ||
      !growingPeriod.organizationField ||
      !growingPeriod.organizationField.field) &&
    (!growingPeriod.organizationField.sections ||
      growingPeriod.organizationField.sections.length === 0)
  ) {
    return 0
  }
  if (
    !growingPeriod ||
    !growingPeriod.organizationField ||
    !growingPeriod.organizationField.field
  ) {
    return growingPeriod.organizationField.sections.reduce(
      (accumulator, current) => {
        if (current?.area) {
          return accumulator + current.area
        } else if (current?.field?.area) {
          return accumulator + current.field.area
        }
        return accumulator + current.area
      },
      0
    )
  }
  return growingPeriod.organizationField.field.area || 0
}

export function getTotalAreaOfGrowingPeriods(growingPeriods) {
  return (
    (growingPeriods || []).reduce((accumulator, current) => {
      return accumulator + getAreaFromGrowingPeriod(current)
    }, 0) || 0
  )
}

export function getAreaFromField(field) {
  if (
    (!field || !field.field || !field.field.area) &&
    (!field?.sections || field?.sections.length === 0) &&
    (!field || field.area === 0)
  ) {
    return 0
  }
  if (
    (!field?.field?.area || field?.field?.area === 0) &&
    (!field?.area || field?.area === 0)
  ) {
    return field?.sections?.reduce((accumulator, current) => {
      if (current?.area?.amount && current?.area?.amount > 0) {
        return accumulator + current.area?.amount
      } else if (current?.field?.area && !isNaN(current?.field?.area)) {
        return accumulator + current.field.area
      } else if (current?.area && !isNaN(current?.area)) {
        return accumulator + current.area
      }
      return accumulator + 0
    }, 0)
  }
  return field?.field?.area || field?.area || 0
}

export function getTotalAreaOfField(field) {
  return getAreaFromField(field) || 0
}
