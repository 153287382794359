import { actionCreator as ac } from '../actionCreator'

const ns = type => `FIELD_${type}`

export const LOAD_POLYGONS = ns('LOAD_POLYGONS')
export const loadPolygons = ac(LOAD_POLYGONS)

export const LOADED_POLYGONS = ns('LOADED_POLYGONS')
export const loadedPolygons = ac(LOADED_POLYGONS)

export const ADD_CUSTOM_FIELD = ns('ADD_CUSTOM_FIELD')
export const addCustomField = ac(ADD_CUSTOM_FIELD)
