import React, { Component } from 'react'
import { connect } from 'react-redux'
import Navigation from '../Navigation'
import { set } from 'lodash'
import { globalConfirm } from '../helpers/alert'

export default (
  WrappedComponent,
  mapStateToProps,
  actions,
  config = {
    enableDelete: true,
    optinEdit: false,
    withoutAddedJSON: false,
    dontLoadOnMount: false,
  }
) => {
  class HOC extends Component {
    state = {
      optinEdit: !!config.optinEdit,
      editing: !config.optinEdit,
      completeForm: false,
      fields: {
        first_name: '',
        last_name: '',
        email: '',
      },
    }
    UNSAFE_componentWillMount() {
      const { id } = this.props
      if (!config.dontLoadOnMount) {
        this.props.load(
          { id },
          {
            cacheFromList: true,
            params: {
              limit: 1000,
            },
          }
        )
      }
    }
    componentDidUpdate(prevProps) {
      const prevId = prevProps.id
      const {
        componentId,
        id,
        popToRootOnRemove,
        popToRootOnUpdate,
        onUpdated,
        onCreated,
        onRemoved,
      } = this.props
      const { popToRootOnCreate } = config

      if (id !== prevId) {
        this.props.load(
          { id },
          { cacheFromList: true, params: { preloadAll: true } }
        )
      }

      const {
        updating,
        updatingError,
        creating,
        creatingError,
        removing,
        removingError,
        created,
      } = this.props.crud
      if (prevProps.crud.updating && !updating && !updatingError) {
        onUpdated && onUpdated(prevProps.crud.object)
        console.log({ popToRootOnUpdate })
        if (popToRootOnUpdate) {
          Navigation.pop(componentId)
        }
      }
      if (created && !creating && !creatingError) {
        onCreated && onCreated(prevProps.crud.object)
        console.log({
          popToRootOnCreate,
          propsPop: this.props.popToRootOnCreate,
        })
        if (popToRootOnCreate || this.props.popToRootOnCreate) {
          Navigation.pop(componentId)
        }
      }
      if (prevProps.crud.removing && !removing && !removingError) {
        onRemoved && onRemoved(prevProps.crud.object)
        if (popToRootOnRemove) {
          Navigation.pop(componentId)
        }
      }
    }
    _change = (name, value) => {
      let object = this.props.crud.object || {}
      if (name.includes('.')) {
        set(object, name, value)

        this.props.setData(object)
      } else {
        // object[name] = value
        this.props.setData({ [name]: value })
        this.setState(prevState => ({
          fields: { ...prevState.fields, [name]: value },
        }))
      }

      if (
        this.state.fields.first_name.length > 1 &&
        this.state.fields.last_name.length > 1 &&
        this.state.fields.email.includes('@') &&
        this.state.fields.email.includes('.')
      ) {
        this.setState({
          completeForm: true,
        })
      } else {
        this.setState({
          completeForm: false,
        })
      }
    }
    _set = v => {
      this.props.setData(v)
    }
    _edit = () => {
      this.setState(prevState => ({
        editing: !prevState.editing,
      }))
    }
    _submit = ({ payload, meta } = {}) => {
      let { id } = this.props
      if (!config.withoutAddedJSON) {
        let amount = ''
        let name = ''
        let organization = null
        let status = null
        let fieldArray = null
        let customField = null
        let performedAt = null
        if (this.props.crud.object) {
          name = this.props.crud.object.name || ''
          organization = this.props.crud.object.organization || null
          status = this.props.crud.object.status || null
          fieldArray = this.props.crud.object.fieldArray || null
          customField = this.props.crud.object.customField || null
          performedAt = this.props.crud.object.performedAt || null
          if (this.props.crud.object.amount !== null) {
            amount = `${this.props.crud.object.amount}`
          }
        }
        if (
          this.props.crud.object &&
          this.props.crud.object.growingPeriod &&
          this.props.crud.object.growingPeriod.crop &&
          this.props.crud.object.growingPeriod.crop.name !== ''
        ) {
          name = this.props.crud.object.growingPeriod.crop.name
        } else if (
          this.props.crud.object &&
          this.props.crud.object.organizationField &&
          this.props.crud.object.organizationField.name !== ''
        ) {
          name = this.props.crud.object.organizationField.name
        }
        if (
          this.props.crud.object &&
          this.props.crud.object.organizationField &&
          this.props.crud.object.organizationField.organization
        ) {
          organization = this.props.crud.object.organizationField.organization
        }
        if (this.props.crud.object && !this.props.crud.object.status) {
          status = 'completed'
        }
        console.log({ performedAt: this.props.crud.object?.performedAt })
        if (
          this.props.crud.object?.performedAt?.date &&
          this.props.crud.object?.performedAt?.startTime &&
          this.props.crud.object?.performedAt?.endTime
        ) {
          performedAt = { ...performedAt, startTime: undefined }
        }

        this.props.setData({
          amount,
          name,
          organization,
          status,
          fieldArray,
          customField,
          performedAt,
        })
      }

      console.log({ id })
      // this ^^
      if (id) {
        this.props.update(payload, meta)
      } else {
        this.props.create(payload, meta)
      }

      if (config.optinEdit) {
        this.setState({
          editing: false,
        })
      }
    }
    _removeConfirmed = () => {
      const { id, organizationId } = this.props
      if (!id && organizationId) {
        this.props.remove({ id: organizationId })
      } else {
        this.props.remove({ id })
      }
    }
    _remove = () => {
      globalConfirm({
        title: 'Verwijderen',
        message: 'Weet u zeker het zeker?',
        onConfirm: () => {
          this._removeConfirmed()
        },
      })
    }
    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          onSet={this._set}
          onChange={this._change}
          onSubmit={this._submit}
          onRemove={this._remove}
          completeForm={this.state.completeForm}
        />
      )
    }
  }
  HOC.defaultProps = {
    popToRootOnRemove: true,
    popToRootOnCreate: true,
    popToRootOnUpdate: true,
  }

  const connectedHOC = connect(mapStateToProps, actions)(HOC)

  if (WrappedComponent) {
    connectedHOC.options = WrappedComponent.options
  }
  return connectedHOC
}
