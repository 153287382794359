import React, { Component, useState } from 'react'
import {
  Platform,
  StyleSheet,
  TextInput as TextInputRaw,
  View,
} from 'react-native'

import { IconButton, Text, withTheme } from 'react-native-paper'

function validateFloat(value) {
  const backendSeperator = '.'
  let newValue = value.replace(',', backendSeperator).replace(/[A-Za-z]/g, '')

  if (newValue.includes(backendSeperator)) {
    const beforeAndAfterArray = newValue.split(backendSeperator)
    return `${beforeAndAfterArray[0]}.${beforeAndAfterArray[1]}`
  }

  return newValue
}
function whatDecimalSeparator() {
  const n = 1.1
  const separator = n.toLocaleString().substring(1, 2)
  return separator
}
class SizeInput extends Component {
  render() {
    const { value } = this.props
    return (
      <TextInputRaw
        keyboardType={Platform.OS === 'web' ? 'default' : 'number-pad'}
        {...this.props}
        value={`${value || value === 0 ? value : ''}`.replace(
          ',',
          whatDecimalSeparator()
        )}
      />
    )
  }
}

function Amount({ onChange, step, value, style, editable = true, label }) {
  const [innerValue, setInnerValue] = useState(value)
  const changeSurface = value => {
    setInnerValue(validateFloat(value))
    onChange(Number(validateFloat(value)))
  }
  const add = () => {
    const innerStep = step ? step : 0.5
    const newWorth = (Number(value) || 0) + Number(innerStep)
    setInnerValue(Number(newWorth.toFixed(3)))
    onChange(Number(newWorth.toFixed(3)))
  }
  const remove = () => {
    if (value === 0) return
    const innerStep = step ? step : 0.5
    const newWorth = (Number(value) || 0) - Number(innerStep)
    setInnerValue(Number(newWorth.toFixed(3)))
    onChange(Number(newWorth.toFixed(3)))
  }

  return (
    <View style={style}>
      <Text style={styles.label}>{label}</Text>
      <View
        style={[
          styles.amountRow,
          styles.padding,
          { paddingBottom: 6, paddingTop: 6 },
        ]}
      >
        {editable ? (
          <IconButton
            icon="minus"
            style={[styles.amountButton, styles.amountRemove]}
            onPress={remove}
          />
        ) : null}

        <SizeInput
          label={'Bijv. 10'}
          style={styles.amountInput}
          value={innerValue}
          // disabled={true}
          onChangeText={changeSurface}
        />
        {editable ? (
          <IconButton
            icon="plus"
            style={[styles.amountButton, styles.amountRemove]}
            onPress={add}
          />
        ) : null}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  scrollViewContent: {
    margin: 16,
  },
  resizer: {
    maxWidth: 600,
    width: '100%',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  padding: {
    // padding: 16,
  },
  paddingScroll: {
    paddingBottom: 100,
  },
  label: {
    fontSize: 14,
    paddingLeft: 56,
    paddingRight: 56,
    textAlign: 'center',
    fontWeight: '500',
    color: '#A4A4A4',
  },
  amountRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  amountInput: {
    backgroundColor: '#fff',
    flex: 1,
    fontFamily: 'Montserrat',
    fontSize: 18,
    borderWidth: 1,
    borderColor: '#EDEDED',
    textAlign: 'center',
    borderRadius: 46 / 2,
    height: 46,
    fontWeight: 'bold',
  },
  amountButton: {
    borderWidth: 1,
    borderColor: '#EDEDED',
    borderRadius: 46 / 2,
    height: 46,
    width: 46,
  },
  amountRemove: {},
  amountAdd: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
  },
})

export default withTheme(Amount)
