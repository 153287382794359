import * as React from 'react'
import { StyleSheet } from 'react-native'
import RModal, { Styles } from 'react-modal'
RModal.setAppElement('#root')
export const wrap = (c: any) => c

const customStyles: Styles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.4)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  content: {
    position: 'static',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',

    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent',
    width: 600,
    border: 0,
  },
}

export default function Modal({
  children,
  isVisible,
  onModalHide,
  style,
}: {
  children: any
  isVisible: boolean
  onModalHide: () => any
  style: any
}) {
  return (
      <RModal
        isOpen={isVisible}
        onRequestClose={onModalHide}
        style={{ ...customStyles, ...StyleSheet.flatten(style) }}
        contentLabel="Example Modal"
      >
        {children}
      </RModal>
  )
}
