import c from './constants'
import { DarkTheme, DefaultTheme } from 'react-native-paper'

// Light theme colors
export const lightColors = {
  ...DefaultTheme.colors,
  background: '#FFFFFF',
  primary: c.greenColor,
  text: '#121212',
  // text: '#08325C',
  error: '#D32F2F',
  accent: c.greenColor,
}

// Dark theme colors
export const darkColors = {
  ...DarkTheme.colors,
  background: '#121212',
  primary: c.greenColor,
  text: '#FFFFFF',
  error: '#EF9A9A',
  accent: c.greenColor,
  surface: '#202329',
}
