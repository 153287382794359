import React from 'react'
import { StyleSheet, View, Platform } from 'react-native'
import Modal from '../Modal'

import { useSafeArea } from '../WidthAndHeight/safeAreaHook'
import { useWidthAndHeight } from '../WidthAndHeight/widthAndHeightHook'

const styles = StyleSheet.create({
  modal: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    alignSelf: 'center',
    justifyContent: 'flex-end',
    margin: 0,
  },
  modalInner: {
    maxWidth: 600,
    width: '100%',
    backgroundColor: '#FFF',
    zIndex: 5,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    alignSelf: 'center',
  },
})

function SimpleSwipableModal({
  children,
  isVisible,
  onRequestClose,
  withGutter = true,
}) {
  const { safe } = useSafeArea()
  const { height } = useWidthAndHeight()

  return (
    <Modal
      key="modal"
      useNativeDriver
      style={[styles.modal, { paddingTop: 56 + safe.top }]}
      isVisible={isVisible}
      onBackdropPress={onRequestClose}
      onModalHide={onRequestClose}
      // onSwipeComplete={onRequestClose}
      // onSwipeStart={onRequestClose}
      onBackButtonPress={onRequestClose}
      onRequestClose={onRequestClose}
      // swipeDirection={'down'}
      // swipeThreshold={50}
      hideModalContentWhileAnimating={true}
      animationOutTiming={150}
      // propagateSwipe={true}
      deviceHeight={height}
      hasBackdrop={false}
    >
      <View
        style={[
          styles.modalInner,
          Platform.OS === 'web' && { maxHeight: height - 56 },
          withGutter && { padding: 24, overflow: 'hidden' },
        ]}
      >
        {children}
      </View>
    </Modal>
  )
}

export default SimpleSwipableModal
