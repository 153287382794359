export const getProductData = state => {
  const product =
    (state.products.crud.object && state.products.crud.object) || {}

  // Mehh we also have one api call which has the data inside the product object
  if (product.product) {
    return product.product
  }
  return product
}
