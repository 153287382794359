import React, { Component } from 'react'
import { StyleSheet, TouchableHighlight, View } from 'react-native'
import actions from './actions'
import Fields from '../Form/Fields'
import FormContainer from '../Form/FormContainer'
import detailHOC from '../GeneralListFunctions/detailHOC'

import Navigation, { push } from '../Navigation'
import * as routes from '../Routes.config'
import safeAreaHOC from '../WidthAndHeight/safeAreaHOC'
import {
  ActivityIndicator,
  Appbar,
  Text,
  Title,
  withTheme,
} from 'react-native-paper'
import KeyboardAvoidingScrollView from '../WidthAndHeight/KeyboardAvoidingScrollView'
import AppbarHeader from '../WidthAndHeight/AppbarHeader'
import AppbarResizer from '../WidthAndHeight/AppbarResizer'
import Lines from './Lines'
import Resizer from '../WidthAndHeight/Resizer'
import DurationPicker from '../components/DurationPicker'
import { getTimes } from './helpers'
import BottomFab from '../WidthAndHeight/BottomFab'
import { hasRole } from '../helpers/role'
import idx from 'idx'
// import RegistrationConceptStatus from './RegistrationConceptStatus'

class RegistrationDetailScreen extends Component {
  _back = () => {
    Navigation.pop(this.props.componentId)
  }
  _changeLines = value => {
    this.props.setData({ appliedProducts: value })
  }
  _changeDuration = values => {
    let newPerformedAt =
      (this.props.crud &&
        this.props.crud.object &&
        this.props.crud.object.performedAt) ||
      {}
    Object.keys(values).forEach(key => {
      if (key === 'startTime') {
        newPerformedAt['date'] = values[key]
      } else {
        newPerformedAt[key] = values[key]
      }
    })
    this.props.setData({ performedAt: newPerformedAt })
  }
  _change = (name, value) => {
    this.props.setData({ [name]: value })
  }
  _showAdvice = () => {
    const adviceId =
      this.props.crud.object.advice && this.props.crud.object.advice.id
    push(this.props.componentId, {
      component: {
        name: routes.ADVICE_DETAIL_ROUTE,
        passProps: {
          id: adviceId,
        },
      },
    })
  }
  _changeStatus = () => {
    const { crud } = this.props

    this.props.setData({
      stage: crud.object.stage === 'FINAL' ? 'DRAFT' : 'FINAL',
    })
  }
  render() {
    const { crud, safePadding, user, theme } = this.props
    const { loading, removing, creating, updating, loaded } = this.props.crud
    const object = crud.object || {}

    const title =
      (object &&
        object.appliedProducts &&
        `Registratie: ${object.appliedProducts
          .map(it => it.product && it.product.name)
          .join(', ')} - ${object.organization.name}`) ||
      'Registratie'
    const { endTime, date } = getTimes(object || {})
    const isFarmer = hasRole(user, 'farmer')
    const isHorticulturist = hasRole(user, 'horticulturist')
    const subTitle =
      object &&
      object.performedBy &&
      object.performedBy.user &&
      object.performedBy.user.id &&
      object.performedBy.user.first_name &&
      object.performedBy.user.first_name !== ''
        ? `Uitgevoerd door ${object.performedBy.user.first_name} ${object.performedBy.user.preposition} ${object.performedBy.user.last_name}`
        : ''

    return (
      <View style={[safePadding, styles.container]}>
        <AppbarHeader
          key="header"
          dark
          style={{ elevation: 0, justifyContent: 'center' }}
        >
          <AppbarResizer>
            <Appbar.BackAction onPress={this._back} color="#fff" />
            <Appbar.Content title={title} color="#fff" />
            {object && object.id && !loading ? (
              <Appbar.Action
                icon={'delete'}
                onPress={this.props.onRemove}
                color="#fff"
              />
            ) : null}
            {loading || updating || removing || creating ? (
              <ActivityIndicator style={{ padding: 12 }} />
            ) : (
              <Appbar.Action
                icon={'check'}
                onPress={this.props.onSubmit}
                color="#fff"
              />
            )}
          </AppbarResizer>
        </AppbarHeader>
        <KeyboardAvoidingScrollView>
          <Resizer style={[styles.col, styles.lineContainer]}>
            <FormContainer onSubmit={this.props.next}>
              <View style={{ padding: 12, alignItems: 'center' }}>
                <Title>
                  {object && object.activity && object.activity.name}
                </Title>
                <Text>{subTitle}</Text>
                {object.advice && object.advice.id !== '' && (
                  <>
                    <Text style={{ margin: 6 }}>
                      Deze registratie is gebaseerd op een gegeven advies
                    </Text>
                    <View
                      style={{
                        flexDirection: 'row',
                        justifyContent: 'center',
                        height: 38,
                        borderRadius: theme.roundness,
                        borderWidth: StyleSheet.hairlineWidth,
                        borderColor: 'rgba(0,0,0,0.29)',
                        overflow: 'hidden',
                        padding: 8,
                        margin: 6,
                      }}
                    >
                      <TouchableHighlight
                        onPress={this._showAdvice}
                        style={{
                          backgroundColor: '#fff',
                          borderTopLeftRadius: theme.roundness,
                          borderBottomLeftRadius: theme.roundness,
                          borderRightWidth: 0,
                          borderRightColor: '#EDEDED',
                          justifyContent: 'center',
                        }}
                        underlayColor={'rgba(0,0,0,0.3)'}
                      >
                        <View
                          style={{
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingLeft: 5,
                            paddingRight: 5,
                          }}
                        >
                          <Text>Advies inzien</Text>
                        </View>
                      </TouchableHighlight>
                    </View>
                  </>
                )}
              </View>
              <View style={[styles.row, styles.secondRow, { zIndex: 1000 }]}>
                {loaded ? (
                  <DurationPicker
                    onChange={this._changeDuration}
                    startTime={date}
                    endTime={endTime}
                    changeOnMount
                    date={date}
                  />
                ) : null}
              </View>
              <Lines
                onChange={this._changeLines}
                value={(object && object.appliedProducts) || []}
              />
              <View style={[styles.row, styles.secondRow]}>
                <Fields
                  fields={[
                    {
                      name: 'note',
                      label: 'Opmerking',
                      type: 'text',
                      fieldProps: {
                        style: {
                          flex: 1,
                          marginTop: 12,
                        },
                      },
                    },
                  ]}
                  values={object}
                  editable={true}
                  onChange={this._change}
                  onSubmit={() => null}
                />
              </View>
              {/*<RegistrationConceptStatus*/}
              {/*  value={object && object.stage}*/}
              {/*  onChange={value => this.props.onChange('stage', value)}*/}
              {/*/>*/}
              <View style={styles.paddingScroll} />
            </FormContainer>
          </Resizer>
        </KeyboardAvoidingScrollView>
        {isFarmer || isHorticulturist ? (
          <BottomFab
            style={[styles.fab]}
            label={'Opslaan'}
            icon="chevron-right"
            onPress={this.props.onSubmit}
            type="submit"
            loading={crud.creating}
          />
        ) : null}
      </View>
    )
  }
}
//eslint-disable-next-line
const styles = StyleSheet.create({
  lineContainer: {
    position: 'relative',
  },

  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 24,
  },
  secondRow: {
    paddingLeft: 6,
    zIndex: 2,
  },
  paddingScroll: {
    paddingBottom: 100,
  },
  fab: {
    position: 'absolute',
    right: 16,
    bottom: 16,
    zIndex: 200,
  },
  navigationFixer: {
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    width: 25,
  },
  container: {
    height: '100%',
  },
})

const mapStateToProps = state => ({
  crud: state.registration.crud,
  auth: state.auth,
  user: idx(state, _ => _.auth.user.user),
})

export default withTheme(
  safeAreaHOC(
    detailHOC(RegistrationDetailScreen, mapStateToProps, actions, {
      optinEdit: true,
      enableDelete: true,
    })
  )
)
