import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { connect } from 'react-redux'
import actions from '../actions'
import Fields from '../../Form/Fields'
import FormContainer from '../../Form/FormContainer'
import safeAreaHOC from '../../WidthAndHeight/safeAreaHOC'
import KeyboardAvoidingScrollView from '../../WidthAndHeight/KeyboardAvoidingScrollView'
import Resizer from '../../WidthAndHeight/Resizer'
import AppbarHeader from '../../WidthAndHeight/AppbarHeader'
import AppbarResizer from '../../WidthAndHeight/AppbarResizer'
import { Appbar } from 'react-native-paper'
import Title from '../../Registration/New/Title'
import { Translate } from 'react-translated'

export const overAllFields = [
  {
    name: 'organization',
    label: <Translate text="company" />,
    type: 'organizationSelect',
    plainList: true,
    fieldProps: {
      // style: { marginLeft: 6, marginRight: 6 },
    },
  },
  {
    name: 'growingPeriods',
    label: <Translate text="growingPeriodsForAllParcels" />,
    type: 'growingPeriods',
    canDelete: true,
  },
  // {
  //   name: 'owningStatus',
  //   label: 'Eigenaarstatus',
  //   type: 'options',
  //   options: [
  //     { key: 'yes', title: 'Op naam', value: 'owned' },
  //     { key: 'no', title: 'Niet op naam', value: 'not_owned' },
  //   ],
  //   fieldProps: {},
  // },
  // {
  //   name: 'variety',
  //   label: 'Ras',
  //   type: 'text',
  //   fieldProps: {
  //     style: { marginLeft: 6, marginRight: 6 },
  //   },
  // },
]

class InformationScreen extends Component {
  _change = (index, newObject, type) => {
    console.log({ index, newObject, type })
    const fieldArray =
      type === 'fieldArray'
        ? this.props.fieldArray.map((oldObject, currentIndex) =>
            currentIndex === index ? newObject : oldObject
          )
        : this.props.customFieldArray.map((oldObject, currentIndex) =>
            currentIndex === index ? newObject : oldObject
          )
    if (type === 'fieldArray') {
      this.props.setData({
        fieldArray,
      })
    } else {
      this.props.setData({
        customFieldArray: fieldArray,
      })
    }
  }
  _changeAll = (name, oldValue) => {
    let value = []
    for (let i = 0; i < oldValue.length; i++) {
      const item = {
        ...oldValue[i],
        viaDouble: true,
      }
      value = [...value, item]
    }

    this.props.setData({
      ...this.props.object,
      [name]: value,
      fieldArray: this.props.fieldArray.map(it => {
        if (name === 'organization') {
          return {
            ...it,
            [name]: value,
          }
        }
        const oldItems =
          it.growingPeriods &&
          it.growingPeriods.filter(gp => !!gp.viaSingle && !gp.viaDouble)
        const newFullGrowingPeriods = [...value, ...(oldItems || [])]
        return {
          ...it,
          [name]: newFullGrowingPeriods,
        }
      }),
    })
  }
  render() {
    const { object, title, prev } = this.props
    const totalSize =
      object && object.fieldArray
        ? object.fieldArray.length + object.customFieldArray
          ? object.customFieldArray.length
          : 0
        : 0

    return (
      <>
        <AppbarHeader
          key="header"
          dark
          style={{ elevation: 0, justifyContent: 'center' }}
        >
          <AppbarResizer>
            <Appbar.BackAction onPress={prev} color="#fff" />
            <Appbar.Content title={title} color="#fff" />

            {/* <Appbar.Action icon={'delete'} onPress={onRemove} color="#fff" /> */}
          </AppbarResizer>
        </AppbarHeader>
        <KeyboardAvoidingScrollView>
          <Resizer>
            <FormContainer onSubmit={this.props.next}>
              {totalSize > 1 ? (
                <>
                  <Title title={<Translate text="generalInfoAllParcels" />} />
                  <Fields
                    fields={overAllFields}
                    values={object}
                    onChange={this._changeAll}
                    onSubmit={this._changeAll}
                  />
                </>
              ) : null}
            </FormContainer>
          </Resizer>
          <View style={styles.paddingScroll} />
        </KeyboardAvoidingScrollView>
      </>
    )
  }
}

InformationScreen.propTypes = {}
InformationScreen.defaultProps = {}

const styles = StyleSheet.create({
  paddingScroll: {
    paddingBottom: 200,
  },
  fieldsMapContainer: {
    height: 350,
    borderRadius: 8,
    margin: 16,
    overflow: 'hidden',
  },
})

const mapStateToProps = state => ({
  object: state.organizationField.crud.object,
  fieldArray:
    state.organizationField.crud &&
    state.organizationField.crud.object &&
    state.organizationField.crud.object.fieldArray,
  customFieldArray:
    state.organizationField.crud &&
    state.organizationField.crud.object &&
    state.organizationField.crud.object.customFieldArray,
})
export default safeAreaHOC(connect(mapStateToProps, actions)(InformationScreen))
