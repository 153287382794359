import React, { useCallback, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { Title } from 'react-native-paper'
import FieldsOrganizationMap from '../FieldOrganizationMap/single/SingleFieldMap'
import Navigation from '../Navigation'
import SimpleSwipableModal from '../Notifications/SimpleSwipableModal'
import RouteButton from '../FieldOrganizationMap/RouteButton'
import { getLongitudeAndLatitudeFromField } from '../Map/helpers'

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  spacer: { flex: 1 },
})

function GroupedCropOnMap({ fieldId, fieldName, componentId }) {
  const [isVisible, setIsVisible] = useState(false)
  const [selectedField, setSelectedField] = useState(undefined)
  const onClose = useCallback(() => setIsVisible(false), [setIsVisible])
  const onPressField = useCallback(
    field => {
      setIsVisible(true)
      setSelectedField(field)
    },
    [setIsVisible, setSelectedField]
  )
  const onHeaderIconPress = useCallback(() => {
    Navigation.pop(componentId)
  }, [componentId])

  const { organizationField, ...field } = selectedField || {}
  // alert('here')
  return (
    <>
      <FieldsOrganizationMap
        onPressField={onPressField}
        componentId={componentId}
        onHeaderIconPress={onHeaderIconPress}
        headerIcon={'arrow-left'}
        fieldId={fieldId}
      />

      <SimpleSwipableModal isVisible={isVisible} onRequestClose={onClose}>
        {field.field && (
          <>
            {/* <Text>Teeltlocatie: {organizationField.name}</Text> */}

            <View style={styles.root}>
              <Title>{fieldName}</Title>
              <View style={styles.spacer}></View>
              <RouteButton
                {...getLongitudeAndLatitudeFromField(field.field)}
                title={fieldName}
              />
            </View>
          </>
        )}
      </SimpleSwipableModal>
    </>
  )
}
export default GroupedCropOnMap
