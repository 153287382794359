import React from 'react'
import Svg from './SvgAbstraction'
import Path from './PathAbstraction'

import { color, defaultProps, propTypes } from './standardVector'

export const width = 24
export const height = 24

const Vector = props => (
  <Svg
    width={props.width || width}
    height={props.height || height}
    className={props.className}
    viewBox="0 0 24 24"
  >
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M10 14.6667C10.2746 15.4435 11.1292 16 12 16C13.1046 16 14 15.1046 14 14V13.6667C14 12.5621 13.1046 11.6667 12 11.6667H11.9333C12.8906 11.6667 13.6667 10.8906 13.6667 9.93335V9.66666C13.6667 8.74619 12.9205 8 12 8C11.4547 8 10.9706 8.26185 10.6665 8.66667"
    ></Path>
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M12 23.25C18.2132 23.25 23.25 18.2132 23.25 12C23.25 5.7868 18.2132 0.75 12 0.75C5.7868 0.75 0.75 5.7868 0.75 12C0.75 18.2132 5.7868 23.25 12 23.25Z"
    ></Path>
  </Svg>
)

Vector.propTypes = propTypes
Vector.defaultProps = defaultProps

export default Vector
