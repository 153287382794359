import { createStore, compose, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import AsyncStorage from '@react-native-community/async-storage'

import createSagaMiddleware from 'redux-saga'
import rootReducer from './reducers'
import mySaga from './sagas'

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose

const initialState = {}

const whiteList = ['auth', 'authSettings', 'filter']

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  whitelist: whiteList,
  whiteList,
  version: 10,
  migrate: (state) => {
    console.log('Migration Running!')
    return Promise.resolve(state)
  },
}

export default function configureStore() {
  // create the saga middleware
  const sagaMiddleware = createSagaMiddleware()

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  // mount it on the Store
  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  )

  let persistor = persistStore(store)

  // then run the saga
  sagaMiddleware.run(mySaga)

  return { store, persistor }
}
