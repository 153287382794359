import React from 'react'
import { StyleSheet, View } from 'react-native'

const styles = StyleSheet.create({
  resizer: {
    maxWidth: 700,
    width: '100%',
    alignSelf: 'center',
  },
  big: {
    maxWidth: 1800,
  },
  medium: {
    maxWidth: 850,
  },
})

export default function Resizer({ style, big, medium, ...rest }: any) {
  return (
    <View
      style={[
        styles.resizer,
        big && styles.big,
        medium && styles.medium,
        style,
      ]}
      {...rest}
    />
  )
}
