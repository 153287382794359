import React, { Component } from 'react'
import Pdf from '../../Pdf/Pdf'
import { getLanguage } from '../../language'

class AdvicePDFViewer extends Component {
  render() {
    const language = getLanguage()
    console.log(this.props)
    return (
      <Pdf
        title={language === 'nl' ? 'Adviesrapport' : 'Advice'}
        enableDrawer
        {...this.props}
      />
    )
  }
}

export default AdvicePDFViewer
