import React from 'react'

const capitalizeFirstLetter = string => {
  return `${string}`.charAt(0).toUpperCase() + `${string}`.slice(1)
}

export default function PathAbstraction(props) {
  const newProps = Object.keys(props).reduce(function(result, key) {
    const newKey = key.split("-").map((element, index) => index === 0 ? element : capitalizeFirstLetter(element)).join('')
    result[newKey] = props[key]
    return result
  }, {})
  return <path {...newProps} />
}
