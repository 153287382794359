import React from 'react'
import Polygon from '../../Map/Polygon'
import { geoJSON } from '../../Map/helpers'

function SinglePolygon({ field }) {
  if (!field) {
    return null
  }
  const shape = geoJSON([field])
  return (
    <Polygon
      key={'field_border'}
      id={'field_border'}
      shape={shape}
      fillColor={'#1DA1F2'}
      fillOpacity={0.5}
      strokeWeight={2}
      strokeColor={'#000000'}
      zIndex={2}
    />
  )
}

export default SinglePolygon
