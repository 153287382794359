import React from 'react'

export function getParamsAsObject(matchParams) {
  let params = {}
  Object.keys(matchParams || {}).forEach(key => {
    let value = decodeURIComponent(matchParams[key])
    if (value === 'undefined') {
      value = undefined
    }
    params[key] = value
  })
  return params
}

export default WrappedComponent => {
  function HOC(props) {
    return (
      <WrappedComponent {...props} {...getParamsAsObject(props.match?.params)} />
    )
  }
  return HOC
}
