import { createBrowserHistory } from 'history'
import { pushAnalytics } from './Analytics/abstraction'
import { PDF_ROUTE } from './Routes.config'
import { getURLFromPushOptions } from './Navigation.global'

let lastTime = 0

export const history = createBrowserHistory()

export function switchMenu(path) {
  history.push(path)
}

export function mergeOptions(componentId, options = {}) {}

export function push(componentId, options) {
  // if (!componentId) {
  //   alert('push will not work on native since componentId is not set')
  // }
  pushAnalytics(options)

  let name = options.component.name
  if (name === PDF_ROUTE) {
    window.open(options.component.passProps.url, '_blank')
    return
  }

  // sometimes we have an url in button so we don't want to push
  if (options.ignoreOnWeb) {
    return false
  }

  history.push(getURLFromPushOptions(options))
}

const openScreen = (props, navigateTo, redirectUrl) => {
  if (navigateTo && props.location.pathname !== `/${navigateTo}`) {
    props.history.replace(`/${navigateTo}`)
    return
  }
  props.history.replace('/')
}

export function closeMenu() {
  const now = new Date().getTime()
  // Fix strange race effect
  if (now - lastTime > 400) {
    const sideContainer = document.getElementsByClassName('side-container')

    if (sideContainer && sideContainer[0]) {
      sideContainer[0].classList.remove('opened')
    }
  }
}

export function openMenu() {
  lastTime = new Date().getTime()
  const sideContainer = document.getElementsByClassName('side-container')

  if (sideContainer && sideContainer[0]) {
    sideContainer[0].classList.add('opened')
  }
}

export function pushAuth(props) {
  const redirectUrl = props.location.pathname
  if (redirectUrl.includes('logout')) {
    openScreen(props, 'auth')
  } else {
    openScreen(props, `auth?redirectUrl=${redirectUrl}`)
  }
}
export function pushHome(props) {
  const params = new URLSearchParams(props.location.search)
  const redirectUrl = params.get('redirectUrl') || '/'
  if (redirectUrl.includes('http')) {
    window.location.assign(redirectUrl)
  }
  openScreen(props, redirectUrl.substr(1))
}

// let locale = 'en'
export const setLocaleNavigation = l => {
  // locale = l
}

export default {
  pop: componentId => {
    // if (!componentId) {
    //   alert('pop to root will not work on native since componentId is not set')
    // }
    history.goBack()
  },
  popTo: () => {},
  popToRoot: componentId => {
    // if (!componentId) {
    //   alert('pop to root will not work on native since componentId is not set')
    // }

    // This breaks filter in some ways?
    const pathNameArray = window.location.pathname.split('/').filter(r => !!r)

    if (pathNameArray.length > 2) {
      const newUrl = '/' + pathNameArray[0]
      history.push(newUrl)
      return
    }
    history.push('/')
  },
}
