import actions from './actions'
import saga from '../GeneralListFunctions/sagaFactory'

import { call, put, takeLatest } from 'redux-saga/effects'
import { fetchListener } from 'react-auth/lib/sagas/authentication'

function* duplicate({ payload }) {
  try {
    console.log(payload)
    const response = yield call(fetchListener, {
      path: `registration/${payload}`,
      method: 'GET',
    })

    yield put(actions.duplicated(response))
  } catch (e) {
    console.log('error:')
    console.log(e)
    yield put(actions.duplicated(e, null, true))
  }
}

export function* customCrud() {
  yield takeLatest(actions.DUPLICATE, duplicate)
}

export default () =>
  saga(
    actions,
    () => 'registration',
    state => state.registration
  )
