import * as a from './actions'
import { RestrictionSummaryType } from '../Products/ItemNoteIcons'

export interface NotificationsStateType {
  open: boolean
  messages: string[]
  restrictionSummary: RestrictionSummaryType | undefined
  openNotifications: boolean
  notifications: string[]
}

const initialState: NotificationsStateType = {
  open: false,
  openNotifications: false,
  notifications: [],
  messages: [],
  restrictionSummary: undefined,
}

function reducer(
  state = initialState,
  {
    type,
    payload,
  }: {
    type: string
    payload: any
  }
): NotificationsStateType {
  switch (type) {
    case a.DISMISS: {
      return initialState
    }
    case a.SET_MESSAGES: {
      return {
        ...state,
        open: true,
        messages: payload || [],
        restrictionSummary: undefined,
      }
    }
    case a.SET_NOTIFCATIONS: {
      return {
        ...state,
        openNotifications: true,
        notifications: payload || [],
      }
    }
    case a.SET_RESTRICTIONS: {
      return {
        ...state,
        open: true,
        restrictionSummary: payload,
        messages: [],
      }
    }
    default:
      return state
  }
}

export default reducer
