import React from 'react'

import { propTypes, defaultProps, color } from './standardVector'

export const width = 14
export const height = 14

const Vector = props => (
  <svg
    width={props.width || width}
    height={props.height || height}
    className={props.className}
    viewBox="0 0 14 14"
  >
    <path
      fill={color(props.color)}
      fillRule="evenodd"
      d="M17.5857864,16 L15.2928932,13.7071068 C14.9023689,13.3165825 14.9023689,12.6834175 15.2928932,12.2928932 C15.6834175,11.9023689 16.3165825,11.9023689 16.7071068,12.2928932 L19,14.5857864 L21.2928932,12.2928932 C21.6834175,11.9023689 22.3165825,11.9023689 22.7071068,12.2928932 C23.0976311,12.6834175 23.0976311,13.3165825 22.7071068,13.7071068 L20.4142136,16 L22.7071068,18.2928932 C23.0976311,18.6834175 23.0976311,19.3165825 22.7071068,19.7071068 C22.3165825,20.0976311 21.6834175,20.0976311 21.2928932,19.7071068 L19,17.4142136 L16.7071068,19.7071068 C16.3165825,20.0976311 15.6834175,20.0976311 15.2928932,19.7071068 C14.9023689,19.3165825 14.9023689,18.6834175 15.2928932,18.2928932 L17.5857864,16 Z M9,10.0068455 C9,9.45078007 9.44386482,9 10,9 C10.5522847,9 11,9.44994876 11,10.0068455 L11,21.9931545 C11,22.5492199 10.5561352,23 10,23 C9.44771525,23 9,22.5500512 9,21.9931545 L9,10.0068455 Z"
      transform="translate(-9 -9)"
    />
  </svg>
)

Vector.propTypes = propTypes
Vector.defaultProps = defaultProps

export default Vector
