import React from 'react'
import Svg from './SvgAbstraction'
import Path from './PathAbstraction'

import { propTypes, defaultProps, color } from './standardVector'

export const width = 24
export const height = 24

const Vector = props => (
  <Svg
    width={props.width || width}
    height={props.height || height}
    className={props.className}
    viewBox="0 0 24 24"
  >
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M10.719 5.2C10.1981 4.13386 9.33148 3.27557 8.26035 2.76502C7.18921 2.25446 5.97674 2.12175 4.82052 2.38851C3.66431 2.65526 2.63253 3.30576 1.89335 4.23398C1.15416 5.1622 0.751151 6.31342 0.75 7.5V23.25H3.75"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M0.75 12.75H3.75"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M0.75 8.25H9"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M21.75 15V23.25H6.75V15"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M5.25 16.5L13.189 8.561C13.3283 8.42161 13.4937 8.31104 13.6758 8.2356C13.8578 8.16015 14.0529 8.12132 14.25 8.12132C14.4471 8.12132 14.6422 8.16015 14.8242 8.2356C15.0063 8.31104 15.1717 8.42161 15.311 8.561L23.25 16.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M14.25 13.125C14.0429 13.125 13.875 12.9571 13.875 12.75C13.875 12.5429 14.0429 12.375 14.25 12.375"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M14.25 13.125C14.4571 13.125 14.625 12.9571 14.625 12.75C14.625 12.5429 14.4571 12.375 14.25 12.375"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M17.25 17.25H11.25C10.8522 17.25 10.4706 17.408 10.1893 17.6893C9.90804 17.9706 9.75 18.3522 9.75 18.75V23.25H18.75V18.75C18.75 18.3522 18.592 17.9706 18.3107 17.6893C18.0294 17.408 17.6478 17.25 17.25 17.25Z"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
  </Svg>
)

Vector.propTypes = propTypes
Vector.defaultProps = defaultProps

export default Vector
