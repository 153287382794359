import React from 'react'
import Svg from './SvgAbstraction'
import Path from './PathAbstraction'

import { color, defaultProps, propTypes } from './standardVector'

export const width = 24
export const height = 24

const Vector = props => (
  <Svg
    width={props.width || width}
    height={props.height || height}
    className={props.className}
    viewBox="0 0 24 24"
  >
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M15.75 8.25007V1.50007C15.7496 1.37911 15.72 1.26005 15.6636 1.15302C15.6073 1.046 15.5259 0.954187 15.4264 0.885425C15.3269 0.816663 15.2122 0.772988 15.0922 0.758125C14.9721 0.743262 14.8503 0.757652 14.737 0.800068L6.487 5.07007C6.34434 5.12348 6.22133 5.21907 6.13434 5.34412C6.04734 5.46917 6.00048 5.61774 6 5.77007V9.75007"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></Path>
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M13.5 5.25H15.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></Path>
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M12 8.25H15.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></Path>
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M0.75 12.75H3"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></Path>
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M0.75 15.75H4.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></Path>
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M8.25 4.15598V0.750977"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></Path>
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M8.25 23.25V11.25C8.25 10.8522 8.09196 10.4706 7.81066 10.1893C7.52936 9.90804 7.14782 9.75 6.75 9.75H2.25C1.85218 9.75 1.47064 9.90804 1.18934 10.1893C0.908035 10.4706 0.75 10.8522 0.75 11.25V23.25H12.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></Path>
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M18.75 11.25C19.9435 11.25 21.0881 11.7241 21.932 12.568C22.7759 13.4119 23.25 14.5565 23.25 15.75C23.25 17.671 20.562 21.4 19.341 22.962C19.2709 23.0518 19.1812 23.1244 19.0788 23.1743C18.9764 23.2243 18.8639 23.2502 18.75 23.2502C18.6361 23.2502 18.5236 23.2243 18.4212 23.1743C18.3188 23.1244 18.2291 23.0518 18.159 22.962C16.938 21.4 14.25 17.671 14.25 15.75C14.25 14.5565 14.7241 13.4119 15.568 12.568C16.4119 11.7241 17.5565 11.25 18.75 11.25V11.25Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></Path>
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M18.75 16.125C18.5429 16.125 18.375 15.9571 18.375 15.75C18.375 15.5429 18.5429 15.375 18.75 15.375"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></Path>
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M18.75 16.125C18.9571 16.125 19.125 15.9571 19.125 15.75C19.125 15.5429 18.9571 15.375 18.75 15.375"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></Path>
  </Svg>
)

Vector.propTypes = propTypes
Vector.defaultProps = defaultProps

export default Vector
