import actions from './actions'
import reducer from '../GeneralListFunctions/reducerFactory'

export interface ProductType {
  id: string
  name: string
  wCode: string
}

export default reducer<ProductType>(actions)
