import actions from './actions'
import reducer, {
  getDefaultState,
} from '../../GeneralListFunctions/reducerFactory'

export interface AdmissionCategoryType {
  id: number
  name: string
}

const customState = {
  ...getDefaultState<AdmissionCategoryType>(),
  selected: [
    // { id: 0, name: 'Gewasbeschermingsmiddelen' },
    // { id: 2, name: 'Toevoegingsstoffen' },
  ], // Do this to default select these 2 filters, so no biociden // UPDATE 16-11-2022: Not needed anymore
}

export default reducer<AdmissionCategoryType>(actions, customState)
