import * as React from 'react'

import { useDispatch } from 'react-redux'

// TODO: improve as real return type is keyOfT

export function useActionsAsFunctions<T>(actions: T): T {
  const dispatch = useDispatch()
  const actionsAsProps = React.useMemo(() => {
    // console.log('re-calculate actions')
    //@ts-ignore
    let functionMap: T = {}
    Object.keys(actions)
      .filter((actionKey) => {
        // @ts-ignore
        const action = actions[actionKey]
        return typeof action === 'function'
      })
      .forEach((actionKey: string) => {
        // @ts-ignore
        const action = actions[actionKey]
        // @ts-ignore
        functionMap[actionKey] = (
          payload?: string,
          meta?: any,
          error?: any
        ) => {
          dispatch(action(payload, meta, error))
        }
      })
    return functionMap as any
  }, [actions, dispatch])

  // console.log({ actionsAsProps })

  return actionsAsProps as T
}
