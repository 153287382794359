import React, { Component } from 'react'
import axios from 'axios'
import { debounce } from 'lodash'
import { connect } from 'react-redux'
import { Keyboard, StyleSheet, View } from 'react-native'
import { IconButton, Searchbar, TouchableRipple } from 'react-native-paper'
import { getStatusBarHeight } from 'react-native-iphone-x-helper'
import { setSearch } from './actions'
import { loadMyLocation, setCenter } from '../Map/actions'
import config from '../Map/config'
import HighlightText from '../components/HighlightText'
import fonts from '../fonts'
import { Translator } from 'react-translated'

const styles = StyleSheet.create({
  full: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.4)',
    flex: 1,
    zIndex: 10,
  },

  container: {
    position: 'absolute',
    paddingLeft: 4,
    paddingRight: 4,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 2000,
  },
  containerLeftPadding: {
    position: 'absolute',
  },

  containerPadding: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  searchBar: { borderRadius: 10, elevation: 0, shadowOpacity: 0 },
  results: {
    backgroundColor: '#FFF',
    marginTop: -10,
    paddingTop: 10,
    left: 0,
    right: 0,
    width: '100%',
    zIndex: 10001,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    position: 'relative',
  },
  item: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
    paddingBottom: 16,
  },
  text: {
    color: '#000',
    ...fonts.medium,
    fontSize: 15,
  },
  highlightStyle: {
    ...fonts.light,
    color: '#525252',
  },

  searchBarContainer: {
    flexDirection: 'row',
    height: 56,
    alignItems: 'center',
  },
  searchInputContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  myLocationButton: {
    marginLeft: 16 / 2,
  },
  resultContainer: {
    position: 'relative',
    zIndex: 10000,
  },
})

const getLabelName = (result) => result.place_name || result.text

class MapHeader extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      results: [],
      error: false,
      hasOnceOpened: false,
      opened: false,
      enterPressed: false,
    }
    this._changeTextDebounced = debounce(this._load, 600)
  }
  _changeText = (value) => {
    this.props.setSearch({ value })
    this._changeTextDebounced(value)
  }

  _pressedEnter = () => {
    this.setState({
      enterPressed: true,
    })
  }

  _load = async (value) => {
    this.setState(
      {
        loading: true,
      },
      () => {
        this._search(value)
      }
    )
  }
  _search = async (value) => {
    try {
      if (!value || value.length === 0) {
        this.setState({
          loading: false,
          error: false,
          results: [],
        })
        return
      }
      // longitude,latitude
      const { language, country, center } = this.props
      // center contains long, lat
      const longitude = center && center[0]
      const latitude = center && center[1]

      const proximity =
        center && longitude && latitude
          ? `${longitude}, ${latitude}`
          : undefined
      const response = await axios({
        url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          value
        )}.json`,
        params: {
          access_token: config.accessToken,
          proximity,
          language, // taal instelling
          country,
        },
      })
      if (this.state.enterPressed && response.data.features.length > 0) {
        this._resultPress(response.data.features[0])()
      }
      this.setState({
        loading: false,
        results: response.data.features,
      })
    } catch (error) {
      console.log(error)
      this.setState({
        loading: false,
        error: true,
      })
    }
  }

  _submit = () => {
    this.setState({
      opened: true,
    })
  }

  _focus = () => {
    this.setState({
      opened: true,
    })
    if (!this.state.hasOnceOpened) {
      this.setState({
        hasOnceOpened: true,
      })
    }
    const { value } = this.props
    if (value && value.length > 0) {
      this._load(value)
    }
  }
  _blur = () => {
    this.setState({
      opened: false,
    })
  }
  _close = () => {
    Keyboard.dismiss()
    this.setState({
      opened: false,
    })
  }
  _resultPress = (result) => () => {
    console.log(result)
    this.props.onResultPressed && this.props.onResultPressed(result)
    this.props.setCenter(result.center)

    this.props.setSearch({ ...result, value: getLabelName(result) })
    this._close()
    this.setState({
      hasOnceOpened: false,
      enterPressed: false,
    })
  }
  _loadMyLocation = () => {
    this.props.onMyLocationPressed && this.props.onMyLocationPressed()
    this.props.loadMyLocation()
  }

  render() {
    const {
      icon,
      // hideBackdrop,
      onIconPress,
      value,
      showMyLocationButton,
      withPadding,
      leftPadding,
      fullScreen,
    } = this.props

    const {
      results,
      // opened,
      hasOnceOpened,
    } = this.state
    const hasResults = results.length > 0
    return [
      <View
        style={[
          styles.container,
          withPadding && styles.containerPadding,
          withPadding && {
            paddingTop: 8 + (fullScreen ? getStatusBarHeight(false) || 0 : 0),
          },
          leftPadding && styles.containerLeftPadding,
          leftPadding && { paddingLeft: leftPadding + 16 },
        ]}
        pointerEvents={'box-none'}
        key={'search-container'}
      >
        <View style={styles.searchBarContainer}>
          <View style={styles.searchInputContainer}>
            <Translator>
              {({ translate }) => (
                <Searchbar
                  onSubmitEditing={this._pressedEnter}
                  placeholder={translate({ text: 'searchPlaceOrAddress' })}
                  onChangeText={this._changeText}
                  icon={icon}
                  style={{
                    flex: 1,
                    borderRadius: 10,
                    elevation: 0,
                    shadowOpacity: 0,
                  }}
                  inputStyle={{
                    fontSize: 16,
                  }}
                  onIconPress={onIconPress}
                  value={value}
                  // onSubmitEditing={this._focus} //  See comment here https://trello.com/c/zJk3Iklz
                  onFocus={this._focus}
                  // onBlur={this._blur}
                />
              )}
            </Translator>
            {showMyLocationButton && (
              <View style={styles.myLocationButton}>
                <IconButton
                  icon="my-location"
                  size={24}
                  color="#000000"
                  onPress={this._loadMyLocation}
                />
              </View>
            )}
          </View>
        </View>

        {hasResults && hasOnceOpened && (
          <View style={styles.results}>
            {results.map((result) => (
              <TouchableRipple
                key={result.id}
                onPress={this._resultPress(result)}
                rippleColor="rgba(0, 0, 0, .32)"
                style={styles.item}
                accessibilityTraits={'button'}
                accessibilityComponentType={'button'}
                accessibilityRole={'button'}
              >
                <HighlightText
                  style={styles.text}
                  highlightStyle={styles.highlightStyle}
                  highlight={value}
                  label={getLabelName(result)}
                />
              </TouchableRipple>
            ))}
          </View>
        )}
      </View>,
      // !hideBackdrop && opened && hasResults && (
      //   <TouchableWithoutFeedback
      //     onPress={this._close}
      //     key={'backdrop'}
      //     // style={styles.resultContainer}
      //   >
      //     <View style={styles.full} />
      //   </TouchableWithoutFeedback>
      // ),
    ]
  }
}
MapHeader.defaultProps = {
  icon: 'magnify',
}

const mapStateToProps = (state, props) => ({
  language: 'nl',
  country: 'nl',
  value: state.search.data.value,
  center: state.map.center,
  loadedOnce: state.map.loadedOnce,
})
export default connect(mapStateToProps, {
  setSearch,
  loadMyLocation,
  setCenter,
})(MapHeader)
