import { StyleSheet } from 'react-native'
import c from '../constants'
import fonts from '../fonts'

const styles = StyleSheet.create({
  toolbar: {
    flexDirection: 'row',
    backgroundColor: c.greenColor,
    alignItems: 'center',
    height: c.HEADER_HEIGHT,
    // paddingTop: 0,
    paddingBottom: 0,
    left: 0,
    right: 0,
    paddingRight: c.textOffset / 2,
  },
  backButton: {
    height: c.HEADER_HEIGHT,
    // paddingTop: 0,
    paddingLeft: c.textOffset / 2 + 10,
    paddingRight: 15,
  },
  fixed: {
    zIndex: 200,
  },
  parent: {
    flexDirection: 'row',
  },
  fixedCol: {
    zIndex: 200,
    borderRightWidth: 2,
    backgroundColor: '#fff',
  },

  row: {
    flexDirection: 'row',
    borderBottomColor: '#EDEDED',
    borderBottomWidth: 1,
  },
  productName: {
    flexDirection: 'row',
    fontSize: 13,
    flex: 1,
  },
  statusComparison: {
    overflow: 'hidden',
    flexDirection: 'row',
    paddingRight: 8,
  },
  col: {
    width: 230,
    padding: 10,

    borderRightColor: '#EDEDED',
    borderRightWidth: 1,
    position: 'relative',
  },
  filterLabel: {
    ...fonts.medium,
    color: c.greenColor,
  },
  labelView: {
    top: 2,
    left: 10,
    position: 'absolute',
    zIndex: 2000,
    backgroundColor: '#fff',
  },
  label: {
    fontSize: 13,
    color: '#9C9C9C',
  },
  value: {
    marginTop: 10,
    fontSize: 14,
    color: '#474747',
  },
  productRow: {
    flexDirection: 'row',

    alignItems: 'center',
  },
  noteIcon: {
    position: 'relative',
    overflow: 'visible',
    zIndex: 200,
    paddingLeft: 5,
    paddingRight: 5,
    alignSelf: 'flex-end',
  },
  badge: {
    position: 'absolute',
    top: -20,
    right: 5,
    fontSize: 12,
    color: '#222',
    backgroundColor: c.lightBorderColor,
    borderRadius: 9,
    overflow: 'hidden',
    width: 18,
    height: 18,
    textAlign: 'center',
    // lineHeight: 18,
  },
  pinButton: {
    // padding: 10,
    backgroundColor: '#fff',
    // borderColor: '#EDEDED',
    // borderWidth: 1,
    borderRadius: 5,
  },
  fixedPinButton: {
    borderColor: c.greenColor,
    backgroundColor: c.greenColor,
  },
  productButton: {
    height: 36,
    // lineHeight: 36,
    flexDirection: 'row',
    alignItems: 'center',
    // justifyContent: 'center',
    flex: 1,
    // maxWidth: '100%',
  },
  specificationRow: {
    backgroundColor: '#E9E8EC',
    borderBottomColor: '#ccc',
  },
  specificationView: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  specificationText: {
    fontSize: 16,
  },
  countLabel: {
    ...fonts.medium,
    color: c.greenColor,
  },
  productText: {},
})
export default styles
