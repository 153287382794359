import React, { PureComponent } from 'react'
import { Appbar } from 'react-native-paper'
import Navigation from '../Navigation'
import AppbarResizer from '../WidthAndHeight/AppbarResizer'

class FilterHeader extends PureComponent {
  _back = () => {
    if (this.props.onBack) {
      this.props.onBack()
    } else {
      Navigation.pop(this.props.componentId)
    }
  }

  render() {
    const { filterType, style, label } = this.props
    return (
      <Appbar
        dark={true}
        style={[
          {
            elevation: 0,
            backgroundColor: 'transparent',
            justifyContent: 'center',
          },
          style,
        ]}
      >
        <AppbarResizer>
          <Appbar.BackAction onPress={this._back} color="#fff" />

          <Appbar.Content
            title={label ? label : filterType.label}
            subtitle={filterType.callToActionDescription}
            color="#fff"
          />
        </AppbarResizer>
      </Appbar>
    )
  }
}

export default FilterHeader
