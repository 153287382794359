import * as a from './actions'

const initialFieldState = {
  data: {},
  fieldData: [],
  loaded: false,
  error: false,
  loading: false,
}
const fieldReducer = (
  state = initialFieldState,
  { type, payload, error, meta }
) => {
  switch (type) {
    case 'RESET': {
      return initialFieldState
    }

    case a.LOAD_POLYGONS: {
      return {
        ...state,
        loading: true,
        loaded: false,
      }
    }

    case a.LOADED_POLYGONS: {
      if (error) {
        return {
          ...state,
          data: {},
          error: true,
          loading: false,
          loaded: true,
        }
      }
      return {
        ...state,
        data: payload.data,
        fieldData: payload.fieldData,
        error: false,
        loading: false,
        loaded: true,
      }
    }

    default:
      return state
  }
}

export default fieldReducer
