import * as a from './actions'

const initialLocationState = {
  loading: false,
  loadedOnce: false,
  error: false,
  zoom: undefined,
  center: undefined,
}

const locationReducer = (
  state = initialLocationState,
  { type, payload, error, meta }
) => {
  switch (type) {
    case 'RESET': {
      return initialLocationState
    }
    case a.LOAD_MY_LOCATION: {
      return {
        ...state,
        error: false,
        loading: true,
        fromButton: payload,
      }
    }
    case a.LOADED_MY_LOCATION: {
      if (!Array.isArray(payload)) {
        console.log('LOADED_MY_LOCATION: coordinates is no array', payload)
        error = true
      }
      if (payload.length !== 2) {
        console.log('LOADED_MY_LOCATION: coordinates is no array', payload)
        error = true
      }
      if (error) {
        return { ...state, error: true, loading: false }
      }

      return {
        ...state,
        loadedOnce: true,
        error: false,
        loading: false,
        center: payload,
      }
    }
    case a.SET_CENTER: {
      return {
        ...state,
        center: payload,
        zoom: 14,
      }
    }
    default:
      return state
  }
}

export default locationReducer
