import React from 'react'
import Svg from './SvgAbstraction'
import Path from './PathAbstraction'

import { propTypes, defaultProps, color } from './standardVector'

export const width = 24
export const height = 24

const Vector = props => (
  <Svg
    width={props.width || width}
    height={props.height || height}
    className={props.className}
    viewBox="0 0 24 24"
  >
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M20.251,7.585A8.283,8.283,0,0,0,12,15.9,8.283,8.283,0,0,0,20.251,7.585Z"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M14.171,10.279A9.238,9.238,0,0,0,12.011.751a9.238,9.238,0,0,0-2.147,9.565"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M3.751,7.585A8.283,8.283,0,0,1,12,15.9,8.283,8.283,0,0,1,3.751,7.585Z"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M12.001 19.501L12.001 15.9"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M.751,23.25a18.754,18.754,0,0,1,22.5,0"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
  </Svg>
)

Vector.propTypes = propTypes
Vector.defaultProps = defaultProps

export default Vector
