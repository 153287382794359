import React from 'react'
import PT from 'prop-types'
import {View, StyleSheet} from 'react-native'
import {Text} from 'react-native-paper'

import c from '../constants'
import fonts from '../fonts'

const capitalizeFirstLetter = string => {
    return `${string}`.charAt(0).toUpperCase() + `${string}`.slice(1)
}

const styles = StyleSheet.create({
    border: {
        borderTopWidth: 1,
        borderTopColor: 'rgba(0,0,0,0.1)',
    },
    label: {
        paddingTop: 8,
        fontSize: 15,
        ...fonts.light,
        color: '#595959',
    },
    value: {
        fontSize: 15,
        color: '#222',
        paddingTop: 8,
        paddingBottom: 8,
    },
    row: {
        paddingLeft: c.textOffset,
        paddingRight: c.textOffset,
    },
})
const LabelRow = props => (
    <View style={styles.row}>
        <View style={props.enableBorderTop && styles.border}>
            <Text style={styles.label}>{props.label}</Text>
        </View>
        <Text style={styles.value} selectable>
            {capitalizeFirstLetter(props.value)}
        </Text>
    </View>
)

LabelRow.propTypes = {
    label: PT.node.isRequired,
    value: PT.string.isRequired,
}

LabelRow.defaultProps = {}

export default LabelRow
