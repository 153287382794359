import a from './actions'

export type Certification = {
  [key: string]: boolean
}
export interface CertificationState {
  selectedKeyValue: Certification
}

const initialState: CertificationState = {
  selectedKeyValue: {},
}

function selectReducer(
  state = initialState,
  { type, payload, error }: { type: string; payload: any; error: boolean }
): CertificationState {
  switch (type) {
    case a.SELECT_KEY_VALUE: {
      return {
        ...state,
        selectedKeyValue: payload,
      }
    }
    case a.REMOVE_SELECTED_KEY_VALUE: {
      return {
        selectedKeyValue: {},
      }
    }

    default:
      return state
  }
}

export default selectReducer
