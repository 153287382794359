import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import GroupedCropsSelectScreen from '../../GroupedCrop/GroupedCropsSelectScreen'
import Navigation, { push } from '../../Navigation'
import { selectGroupedCrop } from './actions'
import * as routes from '../../Routes.config'
import filterCropActions from '../../FilterType/Crop/actions'

export default function AdviceGroupedCropsScreen({ componentId }) {
  const dispatch = useDispatch()
  const onSelect = useCallback(
    crop => {
      // add this crop to the extra state
      dispatch(selectGroupedCrop(crop))

      // add this crop to the context filters
      // extract the crop from the growing period
      const originalCrop = (crop.growingPeriods || [])
        .map(({ crop }) => crop)
        .find(n => n)

      // if crop exist in growing periods, let's select this filter
      if (originalCrop) {
        dispatch(filterCropActions.select(originalCrop, { singleSelect: true }))
      }

      // go to field select screen
      push(componentId, {
        component: {
          name: routes.ADVICE_NEW_FIELD_ROUTE,
        },
      })
    },
    [componentId, dispatch]
  )
  const onBack = useCallback(() => Navigation.pop(componentId), [componentId])

  return (
    <GroupedCropsSelectScreen
      componentId={componentId}
      onBack={onBack}
      onSelect={onSelect}
      title={'Kies teelt'}
    />
  )
}
