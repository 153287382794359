import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Button, IconButton, Title } from 'react-native-paper'
import Fields from '../Form/Fields'
import { Translate } from 'react-translated'

const baseLine = {
  registrationDate: Math.floor(new Date() / 1000),
  unit: 'ppm',
  type: 'cadmium',
}
const fields = [
  {
    name: 'registrationDate',
    type: 'date',
    fieldProps: {
      label: <Translate text={'registrationDate'} />,
      style: { flex: 1 },
    },
  },
]
const secondFields = [
  {
    name: 'value',
    label: <Translate text={'cadmium'} />,
    type: 'amount',
    fieldProps: {
      style: { paddingTop: 6, marginLeft: 6, marginRight: 6 },
      step: 0.001,
    },
  },
  {
    name: 'unit',
    label: 'Unit',
    type: 'text',
    value: 'ppm',
    fieldProps: {
      style: { display: 'none' },
    },
  },
  {
    name: 'type',
    label: 'Type',
    type: 'text',
    value: 'cadmium',
    fieldProps: {
      style: { display: 'none' },
    },
  },
]
function SoilResearches({
  label,
  value,
  onChange,
  next,
  editable = true,
  canDelete,
}) {
  const _addLine = () => {
    onChange([...(value || []), baseLine])
  }
  const removeLine = line => () => {
    onChange((value || []).filter(l => l !== line))
  }
  const changeLine = line => (name, objectValue) => {
    onChange(
      (value || []).map(l =>
        l === line
          ? {
              ...l,
              [name]: objectValue,
            }
          : l
      )
    )
  }

  const lines = value || []
  return (
    <View style={styles.root}>
      {label && <Title style={styles.title}>{label}</Title>}
      {lines.map((line, lineIndex) => {
        return (
          <View
            key={lineIndex}
            style={[
              styles.col,
              styles.lineContainer,
              // { zIndex: 100 + lines.length - lineIndex },
            ]}
          >
            <View style={styles.rowJustifyEnd}>
              {lines.length > 1 && editable ? (
                <View style={[styles.col, styles.closeContainer]}>
                  <IconButton icon={'minus'} onPress={removeLine(line)} />
                </View>
              ) : lines.length === 1 && editable && canDelete ? (
                <View style={[styles.col, styles.closeContainer]}>
                  <IconButton icon={'minus'} onPress={removeLine(line)} />
                </View>
              ) : null}
            </View>

            <View style={[styles.row, styles.secondRow]}>
              <Fields
                fields={fields}
                values={line}
                onChange={changeLine(line)}
                onSubmit={next}
                editable={editable}
              />
            </View>
            <Fields
              fields={secondFields}
              values={line}
              onChange={changeLine(line)}
              onSubmit={next}
              editable={editable}
            />
          </View>
        )
      })}
      {editable ? (
        <View style={styles.bottom}>
          <Button icon="plus" onPress={_addLine} uppercase={false}>
            <Translate text={'soilResearchAdd'} />
          </Button>
        </View>
      ) : null}
    </View>
  )
}

const fixedZIndex = undefined

const styles = StyleSheet.create({
  title: {
    padding: 16,
    marginBottom: 0,
    paddingBottom: 0,
    marginTop: 12,
  },
  root: {
    zIndex: fixedZIndex,
    // flex: 1,
    position: 'relative',
    flexDirection: 'column',
  },
  bottom: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: 16,
    paddingBottom: 16,
    marginBottom: 56,
  },
  row: {
    marginTop: 10,
    marginBottom: 10,
  },
  rowJustifyEnd: { flexDirection: 'row', justifyContent: 'flex-end' },
  full: {
    flex: 1,
  },
  closeContainer: { padding: 6 },
  secondRow: {
    paddingLeft: 6,
    zIndex: fixedZIndex,
  },
  lineContainer: {
    position: 'relative',
    zIndex: fixedZIndex,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    backgroundColor: '#fff',
    paddingTop: 0,
    paddingBottom: 16,
    marginBottom: 16,
    marginTop: 24,
    margin: 6,
    borderRadius: 5,
  },
})

export default SoilResearches
