import React from 'react'
import { Text } from 'react-native-paper'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'

class ReadMore extends React.Component {
  constructor({ initialHeight }) {
    super()
    this.state = { initialHeight, maxHeight: initialHeight }
  }

  _toggle = () => {
    let { maxHeight, initialHeight } = this.state

    //if expanded, close it
    if (maxHeight !== initialHeight) {
      return this.setState({ maxHeight: initialHeight })
    }

    let height = this.calculateHeight()

    //set the full height
    this.setState({ maxHeight: height })
  }

  calculateHeight = () => {
    //calculate height of all the children
    let children = [...this.container.children]
    let height = 0
    children.forEach((child) => (height += child.offsetHeight))

    return height
  }

  componentDidMount() {
    if (this.calculateHeight() <= this.props.initialHeight) {
      this.setState({ hideReadMore: true })
    }
  }
  _readMore = () => {}

  render() {
    let { children, blurStyle, overhangSize, style } = this.props
    let { maxHeight, initialHeight, hideReadMore } = this.state
    let open = maxHeight !== initialHeight

    return (
      <React.Fragment>
        <div
          className="readmore container"
          style={{
            // display: 'flex',
            maxHeight: open ? maxHeight : maxHeight - overhangSize,
            transition: 'max-height .5s ease',
            position: 'relative',
            overflow: 'hidden',
            ...style,
          }}
          ref={(el) => (this.container = el)}
        >
          {children}
          {hideReadMore ? null : (
            <div
              className="readmore overhang"
              style={{
                transition: 'opacity 0.25s',
                opacity: open ? 0 : 1,
                backgroundImage:
                  'linear-gradient(to bottom, rgba(255, 255, 255, 0.44), #ffffff )',
                content: '',
                height: `${overhangSize}px`,
                width: '100%',
                position: 'absolute',
                bottom: '0',
                left: '0',
                ...blurStyle,
              }}
            />
          )}
        </div>

        {hideReadMore ? null : (
          <Text
            onClick={this._toggle}
            style={{
              paddingTop: 6,
              paddingBottom: 6,
              lineHeight: 40,
              height: 40,
              maxHeight: 40,
              minHeight: 40,
              overflow: 'hidden',
              // cursor: 'pointer',
              // textAlign: 'center',
            }}
          >
            {open ? 'Minder lezen' : 'Meer lezen'}
            {open ? (
              <Icon
                name="chevron-up"
                style={{ position: 'absolute', right: 0 }}
                size={16}
              />
            ) : (
              <Icon
                name="chevron-down"
                style={{ position: 'absolute', right: 0 }}
                size={16}
              />
            )}
          </Text>
        )}
      </React.Fragment>
    )
  }
}

ReadMore.defaultProps = {
  initialHeight: 150,
  overhangSize: 50,
}

export default ReadMore
