import React, { useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { distance } from './helpers'

export default function MapUserLocationHOC(WrappedComponent) {
  function Enhanced(props) {
    const [previousLocation, setPreviousLocation] = useState(null)

    const [onDebouncedUpdate] = useDebouncedCallback(
      // function
      event => {
        const coords = event && event.coords
        if (!coords) {
          console.log('no args to debounced location update')
          return
        }
        // console.log('debounced update', coords)

        let needsUpdate = false
        if (previousLocation) {
          const dist = distance(previousLocation, coords, 'CM')
          if (dist > 30) {
            needsUpdate = true
          }
        } else {
          needsUpdate = true
        }

        if (needsUpdate) {
          setPreviousLocation(coords)

          // let other component know user location has changed
          props.onUserLocationUpdate && props.onUserLocationUpdate(coords)
        }
      },
      // delay in ms
      600,
      { maxWait: 2000 }
    )

    return <WrappedComponent {...props} onDebouncedUpdate={onDebouncedUpdate} />
  }
  return Enhanced
}
