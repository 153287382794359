import React, { useState, useCallback } from 'react'
import { List } from 'react-native-paper'
import fonts from '../../fonts'
import ChooseFarmerForAdvisorModal from './ChooseFarmerForAdvisorModal'
import { Translate } from 'react-translated'

export default function FarmerListForAdvisorSingleSelect({
  componentId,
  editable,
  value,
  onSelect,
  plainList,
}) {
  const [isVisible, setIsVisible] = useState(false)
  const showModal = useCallback(() => setIsVisible(true), [setIsVisible])
  const hideModal = useCallback(() => setIsVisible(false), [setIsVisible])

  return (
    <>
      <List.Item
        onPress={showModal}
        title={value ? value.name : <Translate text="selectFarmer" />}
        titleStyle={{
          ...fonts.medium,
        }}
        description={
          value && value.name ? (
            <Translate text="company" />
          ) : (
            <Translate text="nothingSelected" />
          )
        }
        right={(p) => editable && <List.Icon {...p} icon="chevron-down" />}
      />
      <ChooseFarmerForAdvisorModal
        isVisible={isVisible}
        componentId={componentId}
        onRequestClose={hideModal}
        onPressItem={onSelect}
        onChange={onSelect}
        plainList={plainList}
      />
    </>
  )
}
