import React, { useRef } from 'react'
import { StyleSheet } from 'react-native'
import { withTheme } from 'react-native-paper'
import safeAreaHOC from '../WidthAndHeight/safeAreaHOC'
import Searchbar from '../components/Searchbar'
import { Translator } from 'react-translated'

const styles = StyleSheet.create({
  root: {
    backgroundColor: '#FFF',
    marginTop: 0,
    marginBottom: 0,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.15,
    shadowRadius: 3.84,
  },
  inputStyle: {
    fontSize: 16,
  },
})

function SearchHeader({
  value,
  searchPlaceholder,
  safeMargin,
  searchIcon,
  autoFocusSearch,
  onIconPress,
  onSearch,
}) {
  const [changedText, setChangedText] = React.useState(false)
  const debouncedSearch = value => {
    onSearch(value)
  }

  const search = value => {
    setChangedText(true)
    onSearch(value)
  }

  const searchRef = useRef()
  // // below code is not working with filter dialog.
  // useEffect(() => {
  //   if (Platform.OS === 'web') {
  //     function onPress(event) {
  //       searchRef.current && searchRef.current.focus()
  //     }
  //     window.addEventListener('keypress', onPress)
  //     return () => {
  //       window.removeEventListener('keypress', onPress)
  //     }
  //   }
  // }, [])

  return (
    <Translator>
      {({ translate }) => (
        <Searchbar
          searchRef={searchRef}
          key="search-bar"
          testID={'search'}
          onSubmitEditing={() => (!changedText ? debouncedSearch(value) : null)}
          placeholder={searchPlaceholder || translate({ text: 'search' })}
          onChangeText={search}
          style={[safeMargin, styles.root]}
          inputStyle={styles.inputStyle}
          value={value}
          autoCorrect={false}
          autoFocus={autoFocusSearch}
          icon={searchIcon || 'magnify'}
          onIconPress={onIconPress}
        />
      )}
    </Translator>
  )
}

export default safeAreaHOC(withTheme(SearchHeader))
