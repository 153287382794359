import {call, put, select, takeLatest} from 'redux-saga/effects'
import {fetchListener} from 'react-auth/lib/sagas/authentication'
import * as a from './actions'
import {filterPageParamsSelector} from '../FilterType/config'

function* loadSaga(action) {
    try {
        yield put(a.loadDosages(action.payload))

        const ids = action.payload

        const cachedProducts = yield select(state =>
            state.products.list.data
                .filter(product => ids.some(id => product.id === id))
                .map(product => ({product: {product}}))
        )

        if (cachedProducts.length === ids.length) {
            yield put(a.loaded(cachedProducts, {overrideLoading: true}))
        }

        const response = yield call(fetchListener, {
            path: 'product',
            params: {
                ids
            },
        })

        yield put(a.loaded(response.data))
    } catch (e) {
        console.log(e)
        yield put(a.loaded(e))
    }
}

// http://192.168.178.28:9966//comparison/151bf746-1468-4877-55d5-41599c6328a4,62b5c996-5e3c-4c3f-47fd-51efa36be680,0365c8d1-883a-4137-6d54-873593310554,266e0269-b36a-42d5-571f-554fd13d790b,e25b2487-4964-4e87-6cbd-b24c447b6b9a,9a40e10c-5276-40df-6dda-273c1b85b795?_k=m253z9
function* loadDosagesSaga(action) {
    try {
        let params = {
            ids: action.payload,
        }

        const filterParams = yield select(state => filterPageParamsSelector(state))
        params = {
            ...filterParams,
            ...params,
        }
        if (!params.crop || !params.pest) {
            throw Error('Geen crop en pest geselecteerd')
        }
        const dosages = yield call(fetchListener, {
            path: 'product/dosage',
            params,
        })

        // console.log(dosages)
        // const dosages = dosagesCache

        yield put(a.loadedDosages(dosages))
    } catch (e) {
        console.log(e)
        yield put(a.loadedDosages(e))
    }
}

export default function* rootSaga() {
    yield takeLatest(a.LOAD, loadSaga)
    yield takeLatest(a.LOAD_DOSAGES, loadDosagesSaga)
}
