import React from 'react'
import Svg from './SvgAbstraction'
import Rect from './RectAbstraction'
import Line from './LineAbstraction'

import { color, defaultProps, propTypes } from './standardVector'

export const width = 24
export const height = 24

const Vector = props => (
  <Svg
    width={props.width || width}
    height={props.height || height}
    className={props.className}
    viewBox="0 0 24 24"
  >
    <Rect
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      x="0.752"
      y="3.75"
      width="22.5"
      height="19.5"
      rx="1.5"
      ry="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Line
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      x1="0.752"
      y1="9.75"
      x2="23.252"
      y2="9.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Line
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      x1="6.752"
      y1="6"
      x2="6.752"
      y2="0.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Line
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      x1="17.252"
      y1="6"
      x2="17.252"
      y2="0.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </Svg>
)

Vector.propTypes = propTypes
Vector.defaultProps = defaultProps

export default Vector
