import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { StyleSheet, View } from 'react-native'
import { getProductData } from './selectors'
import LabelRow from './LabelRow'
import safeAreaHOC from '../WidthAndHeight/safeAreaHOC'
import Resizer from '../WidthAndHeight/Resizer'
import KeyboardAvoidingScrollView from '../WidthAndHeight/KeyboardAvoidingScrollView'
import c from '../constants'
import { attributes as asAttributes } from '../Comparison/activeSubstanceDetails'
import { Translate } from 'react-translated'
import { Text } from 'react-native-paper'
import { getLanguage } from '../language'

const safeSettings = { bottom: true, left: true, right: true }

class ProductActiveSubstances extends PureComponent {
  render() {
    const {
      activeSubstances,
      isMedium,
      // planetProof
    } = this.props
    const language = getLanguage()
    return (
      <KeyboardAvoidingScrollView
        style={styles.fill}
        safeSettings={safeSettings}
        // contentContainerStyle={{ flex: 1 }}
      >
        <View>
          <Resizer>
            <View
              style={[isMedium && { flexDirection: 'row', flexWrap: 'wrap' }]}
            >
              {!activeSubstances || activeSubstances.length === 0 ? (
                <Text style={styles.container}>
                  Geen actieve stoffen gevonden
                </Text>
              ) : (
                (activeSubstances || []).map((activeSubstance, index) => {
                  return (
                    <View style={{ marginBottom: 50 }}>
                      {asAttributes(language).map((attribute, aIndex) => {
                        const parsed = attribute.parse(activeSubstance)
                        const text = parsed.value
                        return (
                          <View
                            style={[isMedium && { width: '50%' }]}
                            key={aIndex + '_' + index}
                          >
                            <LabelRow
                              key={aIndex}
                              enableBorderTop={
                                isMedium
                                  ? aIndex !== 0 && aIndex !== 1
                                  : aIndex !== 0
                              }
                              label={<Translate text={attribute.label} />}
                              value={`${
                                aIndex === 0
                                  ? activeSubstance && activeSubstance.amount
                                  : ''
                              }${
                                aIndex === 0
                                  ? activeSubstance &&
                                    `${activeSubstance.unit} `
                                  : ''
                              }${text}`}
                            />
                          </View>
                        )
                      })}
                      {/*{planetProofAttributes.map((attribute, aIndex) => {*/}
                      {/*    const parsed = attribute.parse(planetProof)*/}
                      {/*    const text = parsed.value*/}
                      {/*    return parsed.hasValue && (*/}
                      {/*        <View style={[isMedium && {width: '50%'}]}*/}
                      {/*              key={aIndex + '_' + index}>*/}
                      {/*            <LabelRow*/}
                      {/*                key={aIndex}*/}
                      {/*                enableBorderTop={*/}
                      {/*                    isMedium ? aIndex !== 0 && aIndex !== 1 : aIndex !== 0*/}
                      {/*                }*/}
                      {/*                label={attribute.label}*/}
                      {/*                value={`${text}`}*/}
                      {/*            />*/}
                      {/*        </View>*/}
                      {/*    )*/}
                      {/*})}*/}
                    </View>
                  )
                })
              )}
            </View>
          </Resizer>
        </View>
      </KeyboardAvoidingScrollView>
    )
  }
}

const styles = StyleSheet.create({
  fill: {
    flex: 1,
  },
  sideFixer: {
    width: 20,
    position: 'absolute',
    top: 0,
    bottom: 0,
    height: null,
  },
  tabBarContainer: {
    backgroundColor: c.greenColor,
    paddingTop: 0,
    paddingBottom: 0,
    elevation: 4,
    shadowColor: 'black',
    shadowOpacity: 0.1,
    shadowRadius: StyleSheet.hairlineWidth,
    shadowOffset: {
      height: StyleSheet.hairlineWidth,
      width: 0,
    },
  },
  container: {
    padding: c.textOffset / 2,
  },
  tabBar: {
    backgroundColor: c.greenColor,
    elevation: 0,
    shadowOpacity: 0,
  },
  tabStyle: { width: 'auto' },
  labelStyle: { color: '#FFF' },
  indicatorStyle: { backgroundColor: '#FFF' },
})

const mapStateToProps = state => ({
  activeSubstances: getProductData(state).activeSubstances,
  planetProof: getProductData(state).planetProof,
})

export default safeAreaHOC(connect(mapStateToProps)(ProductActiveSubstances))
