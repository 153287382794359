import React, { useCallback, useEffect, useMemo } from 'react'
import actions from './actions'
import PageHeader from '../PageHeader'
import DeepLink from '../DeepLink'
import GeneralList from '../GeneralListFunctions/GeneralList'

import { Appbar } from 'react-native-paper'
import { connect, useDispatch } from 'react-redux'
import { getFirstSelectedId } from '../FilterType/config'
import GroupedCropItem from './GroupedCropItem'
import AdvisorFilter from '../AdvisorFilter'
import { loadMyLocation } from '../Map/actions'
import { hasRole } from '../helpers/role'

function GroupedCropsSelectScreen(props) {
  const { selectedFarmerId, componentId, onSelect, onBack, title, user } = props

  const isContractor = hasRole(user, 'contractor')
  const isTransporter = hasRole(user, 'transporter')
  const extraParams = useMemo(() => {
    return isContractor || isTransporter
      ? {
        _organizationId: selectedFarmerId,
      }
      : {
        // onlyUserManaged: true,
        _organizationId: selectedFarmerId,
      }
  }, [selectedFarmerId, isContractor, isTransporter])
  const dispatch = useDispatch()

  const renderItem = useCallback(
    ({ item }) => (
      <GroupedCropItem
        item={item}
        componentId={componentId}
        onPress={onSelect}
      />
    ),
    [componentId, onSelect],
  )

  useEffect(() => {
    dispatch(loadMyLocation())
  }, [dispatch])

  const renderStickyBelowHeader = useCallback(() => {
    return (
      <AdvisorFilter
        key="filter-tool"
        componentId={componentId}
        context="grouped-crop"
      />
    )
  }, [componentId])

  return (
    <>
      <GeneralList
        {...props}
        searchPlaceholder={`Search for crops`}
        renderItem={renderItem}
        icon
        renderStickyBelowHeader={renderStickyBelowHeader}
        appbarContent={
          <PageHeader
            back={onBack}
            componentId={componentId}
            title={<Appbar.Content title={title || `Crops`} />}
          />
        }
        extraParams={extraParams}
        preloads={[
          'organizationField',
          'organizationField.field',
          'organizationField.organization',
          'crop',
        ]}
      />
      <DeepLink loggedIn={true} componentId={componentId} />
    </>
  )
}

const mapStateToProps = state => ({
  list: state.groupedCrop.list,
  showOnMapButton: state.groupedCrop.list.data.length > 0,
  selectedFarmerId: getFirstSelectedId(state.filter.advisorFarmer.selected),
  user: state.auth.user.user,
})

export default connect(
  mapStateToProps,
  { ...actions, loadMyLocation },
)(GroupedCropsSelectScreen)
