import { actionCreator as ac } from '../actionCreator'

const ns = type => `MAP_${type}`

export const SET_CENTER = ns('SET_CENTER')
export const setCenter = ac(SET_CENTER)

export const LOAD_MY_LOCATION = ns('LOAD_MY_LOCATION')
export const loadMyLocation = ac(LOAD_MY_LOCATION)

export const LOADED_MY_LOCATION = ns('LOADED_MY_LOCATION')
export const loadedMyLocation = ac(LOADED_MY_LOCATION)
