import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Text } from 'react-native-paper'
import fonts from '../fonts'
import { Translate, Translator } from 'react-translated'

const styles = StyleSheet.create({
  emptyTitle: { fontSize: 19, ...fonts.medium, textAlign: 'center' },
  emptyDescription: { color: '#525252', marginTop: 6, textAlign: 'center' },
  emptyImage: { width: 90, height: 90, marginBottom: 56 },
  emptyContainer: {
    flex: 1,

    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
    paddingTop: 56,
    paddingBottom: 56,
  },
  smallEmptyContainer: {
    flex: 1,

    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
    paddingTop: 16,
    paddingBottom: 16,
  },
})
function Empty({
  isReallyEmpty,
  search,
  customTitle,
  customDescription,
  smallEmpty = false,
}) {
  return (
    <View
      key="empty"
      style={[styles.emptyContainer, smallEmpty && styles.smallEmptyContainer]}
    >
      <Text style={styles.emptyTitle}>
        {customTitle ? (
          customTitle
        ) : isReallyEmpty ? (
          <Translate text={'cleanStart'} />
        ) : (
          <Translator>
            {({ translate }) =>
              `${translate({ text: 'noResult' })} ${
                search ? `${translate({ text: 'for' })}: ` + search : ''
              }`
            }
          </Translator>
        )}
      </Text>
      <Text style={styles.emptyDescription}>
        {customDescription ? (
          customDescription
        ) : isReallyEmpty ? (
          <Translate text={'addSomething'} />
        ) : (
          <Translator>
            {({ translate }) =>
              `${`${translate({ text: 'tryAnother' })}${
                search ? translate({ text: 'searchTerm' }) : ' filter'
              }`}`
            }
          </Translator>
        )}
      </Text>
    </View>
  )
}

export default React.memo(Empty)
