import * as React from 'react'
import { useSelector } from 'react-redux'
import {
  getStatusBarHeight,
  getBottomSpace,
} from 'react-native-iphone-x-helper'
import { Platform } from 'react-native'
import { RootState } from '../reducers'

export const mediumWidth = 700
export const largeWidth = 1100

interface SafeValues {
  safe: {
    left: number
    right: number
    top: number
    bottom: number
  }
  safePadding: {
    paddingLeft: number
    paddingRight: number
    paddingTop: number
    paddingBottom: number
  }
  safeMargin: {
    marginLeft: number
    marginRight: number
    marginTop: number
    marginBottom: number
  }
}

export function useSafeArea(): SafeValues {
  const height = useSelector((state: RootState) => state.widthAndHeight.height)
  const width = useSelector((state: RootState) => state.widthAndHeight.width)

  const isLandscape = width > height

  let left = 0
  let right = 0
  let top = 0
  let bottom = 0

  if (Platform.OS === 'ios') {
    const statusBarHeight = getStatusBarHeight(true) || 0
    const bottomHeight = getBottomSpace() || 0
    if (isLandscape) {
      left = bottomHeight
      right = bottomHeight
      bottom = bottomHeight
    } else {
      top = statusBarHeight
      bottom = bottomHeight
    }
  }

  if (Platform.OS === 'android') {
    const statusBarHeight = getStatusBarHeight() || 0
    top = statusBarHeight
  }

  const safeProperties = React.useMemo(
    () => ({
      left,
      right,
      top,
      bottom,
    }),
    [left, right, top, bottom]
  )
  const safePadding = React.useMemo(
    () => ({
      paddingLeft: left,
      paddingRight: right,
      paddingTop: top,
      paddingBottom: bottom,
    }),
    [left, right, top, bottom]
  )
  const safeMargin = React.useMemo(
    () => ({
      marginLeft: left,
      marginRight: right,
      marginTop: top,
      marginBottom: bottom,
    }),
    [left, right, top, bottom]
  )

  return {
    safe: safeProperties,
    safePadding,
    safeMargin,
  }
}
