import { ScrollView, View } from 'react-native'
import { Button, Text, Title } from 'react-native-paper'
import React, { useEffect } from 'react'
import { SimpleBackgroundHeader } from './GeneralListFunctions/GeneralListHeader'
import PageHeader from './PageHeader'
import Resizer from './WidthAndHeight/Resizer'
import { useDispatch, useSelector } from 'react-redux'
import { push } from './Navigation'
import * as routes from './Routes.config'
// @ts-ignore
import { Translate } from 'react-translated'
import a from './Farmer/Organization/User/List/actions'

function OnboardingScreen({ componentId }: { componentId: string }) {
  const dispatch = useDispatch()
  const authUser = useSelector((state: any) => state.auth && state.auth.user)
  useEffect(() => {
    const user = (authUser && authUser.user) || {}
    if (
      user &&
      user.attributes &&
      user.attributes.length > 0 &&
      user.attributes.find(
        (att: { key: string; value: string }) =>
          att.key === 'onboardingDone' && att.value === 'true'
      )
    ) {
      push(componentId, {
        component: {
          name: routes.HOME_ROUTE,
        },
      })
    } else {
      dispatch(
        a.update({
          extraBody: {
            ...user,
            attributes: [
              { key: 'onboardingDone', value: 'true', type: 'string' },
            ],
          },
        })
      )
    }
  }, [authUser, componentId, dispatch])
  return (
    <>
      <SimpleBackgroundHeader
        key={`SimpleBackgroundHeader}`}
        absolute={false}
        showSearch={false}
        appbarContent={
          <PageHeader
            componentId={componentId}
            title={''}
            // back={() => innerLogout() as any}
            withoutOnPressLogo={true}
          />
        }
        zIndex
      />
      <ScrollView style={{ paddingTop: 16 }}>
        <Resizer style={{ flex: 1 }}>
          <View>
            <Title>
              <Translate text={'onboardingTitle'} />
            </Title>
            <Text
              style={{ flexDirection: 'row', marginBottom: 32 }}
              onPressIn={undefined}
              onPressOut={undefined}
              android_hyphenationFrequency={undefined}
            >
              <Translate text="onboardingDescription" />
            </Text>
            <View style={{ flexDirection: 'column', marginBottom: 32 }}>
              <Text
                onPressIn={undefined}
                onPressOut={undefined}
                android_hyphenationFrequency={undefined}
              >
                ✔ Alle chemische én biologische middelen op één plek
              </Text>

              <Text
                onPressIn={undefined}
                onPressOut={undefined}
                android_hyphenationFrequency={undefined}
              >
                ✔ Snel en eenvoudig filteren op jouw situatie
              </Text>

              <Text
                onPressIn={undefined}
                onPressOut={undefined}
                android_hyphenationFrequency={undefined}
              >
                ✔ Wettelijke en actuele informatie conform CTGB
              </Text>

              <Text
                onPressIn={undefined}
                onPressOut={undefined}
                android_hyphenationFrequency={undefined}
              >
                ✔ Alle teelten en plagen in Nederland
              </Text>
            </View>
            <Button
              mode="contained"
              onPress={() => {
                push(componentId, {
                  component: {
                    name: routes.HOME_ROUTE,
                  },
                })
              }}
            >
              <Translate text="onboardingButton" />
            </Button>
          </View>
        </Resizer>
      </ScrollView>
    </>
  )
}
export default OnboardingScreen
