import { Platform, Alert } from 'react-native'

export function globalAlert({ title, message }) {
  if (Platform.OS === 'web') {
    //eslint-disable-next-line
    alert(message)
  } else {
    Alert.alert(
      title,
      message,
      [
        {
          text: 'OK',
          onPress: () => {},
        },
      ],
      { cancelable: true }
    )
  }
}

export function globalConfirm({ title, message, onConfirm }) {
  if (Platform.OS === 'web') {
    //eslint-disable-next-line
    if (confirm(message)) {
      onConfirm()
    }
  } else {
    Alert.alert(
      title,
      message,
      [
        {
          text: 'Annuleer',
          style: 'cancel',
        },
        {
          text: 'OK',
          onPress: () => onConfirm(),
        },
      ],
      { cancelable: true }
    )
  }
}
