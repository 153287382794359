import React, { useCallback } from 'react'
import { StyleSheet, View } from 'react-native'
import actions from '../../Farmer/Organization/User/List/actions'
import Fields from '../../Form/Fields'
import FormContainer from '../../Form/FormContainer'
import detailHOC from '../../GeneralListFunctions/detailHOC'
import safeAreaHOC from '../../WidthAndHeight/safeAreaHOC'
import { ActivityIndicator, Appbar } from 'react-native-paper'
import KeyboardAvoidingScrollView from '../../WidthAndHeight/KeyboardAvoidingScrollView'
import AppbarHeader from '../../WidthAndHeight/AppbarHeader'
import AppbarResizer from '../../WidthAndHeight/AppbarResizer'
import Navigation from '../../Navigation'
import BottomFab from '../../WidthAndHeight/BottomFab'
import { Translate, Translator } from 'react-translated'

const fieldProps = {
  style: { marginBottom: 16 },
}
const fields = [
  {
    key: 'text',
    name: 'first_name',
    type: 'text',
    label: <Translate text="firstName" />,
    fieldProps,
  },
  {
    key: 'text',
    name: 'preposition',
    type: 'text',
    label: <Translate text="preposition" />,
    fieldProps,
  },
  {
    key: 'text',
    name: 'last_name',
    type: 'text',
    label: <Translate text="lastName" />,
    fieldProps,
  },
  {
    key: 'text',
    name: 'email',
    type: 'email',
    label: 'E-mail',
    fieldProps,
  },
]
function EditUsers({
  onBack,
  onSubmit,
  onChange,
  onRemove,
  componentId,
  crud: { object, loading, removing, creating, updating },
  safePadding,
  completeForm,

  organization,
}) {
  const title = (object && object.name) || <Translate text="editUser" />
  const onInnerBack = useCallback(() => {
    if (onBack) {
      onBack()
    } else {
      Navigation.pop(componentId)
    }
  }, [onBack, componentId])
  const submit = useCallback(() => {
    const organizationId =
      organization && organization.object && organization.object.id
    const meta = {
      params: {
        embed: ['organization'],
        sendInvite: organizationId ? false : true,
        'roles[]':
          organization &&
          organization.object &&
          organization.object.settings &&
          organization.object.settings.type
            ? organization.object.settings.type
            : 'farmer',
      },
      extraBody: {},
    }

    if (organizationId) {
      meta.extraBody.organization_id = Number(organizationId)
      meta.params.organizationId = Number(organizationId)
    }

    onSubmit({ meta })
  }, [onSubmit, organization])
  return (
    <>
      <AppbarHeader
        key="header"
        dark
        style={{ elevation: 0, justifyContent: 'center' }}
      >
        <AppbarResizer>
          <Appbar.BackAction onPress={onInnerBack} color="#fff" />
          <Appbar.Content title={title} color="#fff" />
          {object && object.id && !loading ? (
            <Appbar.Action icon={'delete'} onPress={onRemove} color="#fff" />
          ) : null}
          {loading || updating || removing || creating ? (
            <ActivityIndicator style={{ padding: 12 }} color={'#FFF'} />
          ) : null}
        </AppbarResizer>
      </AppbarHeader>
      <View style={[safePadding, { backgroundColor: '#fff', flex: 1 }]}>
        <KeyboardAvoidingScrollView style={styles.scrollView}>
          <View style={styles.innerScroll}>
            <FormContainer onSubmit={submit}>
              <View style={{ height: 10 }} />
              <Fields
                fields={fields}
                values={object}
                onChange={onChange}
                onSubmit={submit}
              />
            </FormContainer>
          </View>
          <Translator>
            {({ translate }) => (
              <BottomFab
                icon={'check'}
                label={translate({ text: 'save' })}
                onPress={submit}
              />
            )}
          </Translator>
        </KeyboardAvoidingScrollView>
      </View>
    </>
  )
}
const styles = StyleSheet.create({
  scrollView: {
    flexDirection: 'column',
  },
  innerScroll: {
    maxWidth: 600,
    alignSelf: 'center',
    width: '100%',
  },

  addFab: {
    marginTop: 56 + 24,
    maxWidth: 600,
    alignSelf: 'center',
    width: '100%',
  },
})
const mapStateToProps = state => ({
  crud: state.farmer.userList.crud,
  organization: state.farmer.organization.crud,
})
export default safeAreaHOC(
  detailHOC(EditUsers, mapStateToProps, actions, {
    optinEdit: true,
    enableDelete: true,
    withoutAddedJSON: true,
  })
)
