import React from 'react'
import Point from '../Map/Point'
import { useSelector } from 'react-redux'

function Entrance({ entrance }) {
  // Do not remove this, we need to rerender the entrace whenever the data.field (all fields) are loaded
  // Because mapbox will always give a later rendered item a higher zIndex
  // https://github.com/nitaliano/react-native-mapbox-gl/issues/970
  const data = useSelector((state) => state.fieldMap.data)
  return (
    <>
      {data.field && (
        <Point
          key={'entrance_fix'}
          id={'entrance'}
          shape={entrance}
          zIndex={100}
        />
      )}
      <Point key={'entrance'} id={'entrance'} shape={entrance} zIndex={100} />
    </>
  )
}

export default React.memo(Entrance)
