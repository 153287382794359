import { flatten } from 'lodash'
import polygonPoint from 'intersects/polygon-point'
import turf from 'turf'

// TODO: center of field

export function getCenterFromField(field) {
  const coordinates = getAllCoordinatesFromField(field)
  const turfPolygon = turf.polygon([coordinates])
  const center = turf.centroid(turfPolygon)
  return center
}

export function getLongitudeAndLatitudeFromField(entrances, field) {
  if (entrances && entrances.length > 0) {
    return entrances[0]
  }
  return field && field.coordinates && field.coordinates.length > 0
    ? {
        longitude: field.coordinates[0].longitude,
        latitude: field.coordinates[0].latitude,
      }
    : undefined
}

export function getCoordinatesFromField(field) {
  const { longitude, latitude } = getLongitudeAndLatitudeFromField(field) || {}
  return longitude && latitude ? [longitude, latitude] : undefined
}

export function getAllCoordinatesFromField(field) {
  return field && field.coordinates && field.coordinates.length > 0
    ? field.coordinates.map(it => {
        return [it.longitude, it.latitude]
      })
    : undefined
}

export function geoJSON(data) {
  return {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'MultiPolygon',
          coordinates: data.map(item => {
            // console.log(item)
            if (item?.coordinates && item?.coordinates.length > 0) {
              return convertToPaths(item.coordinates, item.holes)
            }
            if (item?.field) {
              return convertToPaths(item.field.coordinates, item.field.holes)
            }
            return []
          }),
        },
      },
    ],
  }
}

export function convertToPaths(coordinates, holes) {
  let paths = [convertCoords(coordinates)]

  if (holes) {
    holes.forEach(hole => {
      if (hole.coordinates) {
        paths.push(convertCoords(hole.coordinates))
      }
    })
  }

  return paths
}

export function convertCoords(c) {
  return c.map(item => [item.longitude, item.latitude])
}

export function findFieldByCoordinates({ fields, latitude, longitude }) {
  return fields.find((it, i) =>
    polygonPoint(
      flatten(
        (it.coordinates || it.field.coordinates || []).map(co => [
          co.latitude,
          co.longitude,
        ])
      ),
      latitude,
      longitude,
      0.00000001
    )
  )
}

export function findCustomFieldByCoordinates({
  customFields,
  latitude,
  longitude,
}) {
  return customFields.find(customField =>
    customField.fields.find(it =>
      polygonPoint(
        flatten(
          (it.coordinates || it.field.coordinates || []).map(co => [
            co.latitude,
            co.longitude,
          ])
        ),
        latitude,
        longitude,
        0.00000001
      )
    )
  )
}

// https://www.geodatasource.com/developers/javascript
export function distance(location1, location2, unit) {
  const lat1 = location1.latitude
  const lon1 = location1.longitude

  const lat2 = location2.latitude
  const lon2 = location2.longitude
  if (lat1 === lat2 && lon1 === lon2) {
    return 0
  } else {
    var radlat1 = (Math.PI * lat1) / 180
    var radlat2 = (Math.PI * lat2) / 180
    var theta = lon1 - lon2
    var radtheta = (Math.PI * theta) / 180
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
    if (dist > 1) {
      dist = 1
    }
    dist = Math.acos(dist)
    dist = (dist * 180) / Math.PI
    dist = dist * 60 * 1.1515

    if (unit === 'K') {
      dist = dist * 1.609344
    }
    if (unit === 'M') {
      dist = dist * 1.609344 * 1000
    }
    if (unit === 'CM') {
      dist = dist * 1.609344 * 1000 * 100
    }
    if (unit === 'N') {
      dist = dist * 0.8684
    }
    return dist
  }
}
