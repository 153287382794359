import React, { useCallback, useEffect, useState } from 'react'
import {
  ActivityIndicator,
  Appbar,
  RadioButton,
  Text,
} from 'react-native-paper'
import GeneralListHeader from '../../GeneralListFunctions/GeneralListHeader'
import PageHeader from '../../PageHeader'
import Navigation, { push } from '../../Navigation'
import { Translator } from 'react-translated'
import { useDispatch, useSelector } from 'react-redux'
import actions from '../../Observations/actions'
import a from './actions'
import adviceActions from '../actions'
import Resizer from '../../WidthAndHeight/Resizer'
import Title from '../../Observations/New/Title'
import Box from '../../components/Box'
import SafeListItem from '../../WidthAndHeight/SafeListItem'
import { Platform, ScrollView, View } from 'react-native'
import BottomFab from '../../WidthAndHeight/BottomFab'
import * as routes from '../../Routes.config'

function AdjustAdvice(props) {
  const { componentId, observationId, companyId } = props
  const crud = useSelector(state => state.observation.crud)
  const wizardAdvice = useSelector(state => state.advice.wizard.crud)
  const advice = useSelector(state => state.advice.listAndCrud.crud)
  const loading = crud.creatingAdvice
  const success = crud.createdAdvice

  console.log({ crud })

  const [hasLoaded, setHasLoaded] = useState(false)
  const dispatch = useDispatch()
  const createAdvice = useCallback(
    lang => {
      dispatch(
        actions.createAdvice(undefined, {
          extraBody: { observations: [{ id: observationId }] },
          params: { language: lang },
        })
      )
    },
    [dispatch, observationId]
  )
  useEffect(() => {
    // This makes sure that when the advice is generated, the advice ID is saved in the correct space (the one of the wizard/stepper)
    if (success && crud && crud.advice && crud.advice.id) {
      dispatch(
        a.setData({
          advice: {
            ...crud?.advice,
            lines: crud?.advice?.lines?.map(line => {
              return {
                ...line,
                products: [],
              }
            }),
          },
        })
      )
    }
  }, [crud, success, dispatch])
  useEffect(() => {
    if (!hasLoaded) {
      setHasLoaded(true)
      createAdvice('nl')
    }
  }, [hasLoaded, createAdvice])

  const back = useCallback(() => {
    Navigation.pop(componentId)
  }, [componentId])

  const listHeaderProps = {
    listKey: 'header',
    appbarContent: (
      <Translator>
        {({ translate }) => (
          <PageHeader
            back={back}
            componentId={componentId}
            title={
              <Appbar.Content
                title={translate({ text: 'generateAdvice' })}
                titleStyle={{
                  textAlign: 'center',
                }}
                style={{ flex: 1 }}
                subtitleStyle={{
                  textAlign: 'center',
                }}
                subtitle={translate({ text: 'adjustAdvice' })}
              />
            }
          />
        )}
      </Translator>
    ),
    showSearch: false,
    autoFocusSearch: false,
    withoutHeaderBackground: true,
    onSearch: () => null,
    navbarTranslate: 0,
    navbarOpacity: 1,
    renderStickyBelowHeader: undefined,
    searchPlaceholder: '',
    searchIcon: '',
    onSearchIconPress: () => null,
    searchValue: '',
  }

  const onPressProductSample = (sampleIndex, product) => {
    dispatch(
      a.setData({
        advice: {
          ...(wizardAdvice &&
            wizardAdvice.object &&
            wizardAdvice.object.advice),
          lines: (
            (wizardAdvice &&
              wizardAdvice.object &&
              wizardAdvice.object.advice &&
              wizardAdvice.object.advice.lines) ||
            []
          )
            .map((line, i) =>
              i === sampleIndex ? { ...line, products: [product] } : line
            )
            .filter(n => n),
        },
      })
    )
  }

  const saveAdvice = () => {
    if (wizardAdvice?.object?.advice) {
      // dispatch(adviceActions.setData(wizardAdvice?.object?.advice))
      dispatch(
        adviceActions.update({ extraBody: wizardAdvice?.object?.advice })
      )
    }
  }

  useEffect(() => {
    if (advice && advice.updated) {
      push(componentId, {
        component: {
          name: routes.ADVICE_WIZARD_ROUTE,
          passProps: {
            step: 3,
            companyId: companyId,
            observationId: observationId,
            adviceId: advice?.object?.id,
          },
        },
      })
    }
  }, [advice, companyId, observationId, componentId])

  return (
    <>
      <GeneralListHeader {...listHeaderProps} />
      <Translator>
        {({ translate }) => (
          <>
            <ScrollView>
              <Resizer
                style={
                  Platform.OS === 'web'
                    ? { marginTop: 52, paddingBottom: 80 }
                    : { paddingBottom: 80 }
                }
              >
                <Title
                  title={translate({ text: 'adjustAdvice' })}
                  titleStyle={{ color: '#000' }}
                />
                {!!loading ? (
                  <>
                    <Text style={{ paddingBottom: 16, paddingLeft: 16 }}>
                      {translate({ text: 'generatingAdvice' })}
                    </Text>
                    <ActivityIndicator loading={loading} size={'large'} />
                  </>
                ) : success ? (
                  <>
                    {!(crud && crud.advice && crud.advice.lines) ? (
                      <Text style={{ paddingLeft: 16 }}>
                        {translate({
                          text: 'noPestsOrBeneficialsInObservation',
                        })}
                      </Text>
                    ) : crud &&
                      crud.advice &&
                      crud.advice.lines &&
                      crud.advice.lines.length > 0 &&
                      crud.advice.lines.some(
                        line => !line?.products || !(line?.products?.length > 0)
                      ) ? (
                      <Text style={{ paddingLeft: 16 }}>
                        {translate({ text: 'noProductsAreAllowed' })}
                      </Text>
                    ) : (
                      <Text style={{ paddingLeft: 16 }}>
                        {translate({ text: 'generatedAdvice' })}
                      </Text>
                    )}
                    {crud &&
                      crud.advice &&
                      crud.advice.lines &&
                      crud.advice.lines.length > 0 &&
                      crud.advice.lines.map((sample, index) => {
                        return (
                          <Box
                            title={`${
                              !(
                                sample?.beneficialOrganism?.name ||
                                sample?.pest?.name
                              )
                                ? sample.type === 'pest'
                                  ? `${translate({ text: 'pest' })} ${
                                      index + 1
                                    }`
                                  : `${translate({
                                      text: 'beneficialOrganism',
                                    })} ${index + 1}`
                                : ''
                            }${
                              sample.type === 'beneficialOrganism'
                                ? sample?.beneficialOrganism?.name
                                  ? `${
                                      sample?.beneficialOrganism?.name?.nl &&
                                      sample?.beneficialOrganism?.name?.la
                                        ? `${sample?.beneficialOrganism?.name?.nl} (${sample?.beneficialOrganism?.name?.la})`
                                        : sample?.beneficialOrganism?.name?.nl
                                        ? sample?.beneficialOrganism?.name?.nl
                                        : sample?.beneficialOrganism?.name?.la
                                        ? sample?.beneficialOrganism?.name?.la
                                        : ''
                                    }${
                                      sample?.location?.row
                                        ? ` | ${translate({ text: 'row' })} ${
                                            sample?.location?.row
                                          }`
                                        : ''
                                    }${
                                      sample?.location &&
                                      (sample?.location?.post ||
                                        sample?.location?.range?.start ||
                                        sample?.location?.range?.end)
                                        ? sample?.location?.post
                                          ? `${
                                              !sample?.location?.row ? ' |' : ''
                                            } ${translate({ text: 'pole' })} ${
                                              sample?.location?.post
                                            }`
                                          : sample?.location?.range?.start
                                          ? `${
                                              !sample?.location?.row ? ' |' : ''
                                            } ${translate({ text: 'pole' })} ${
                                              sample?.location?.range?.start
                                            }${
                                              sample?.location?.range?.end
                                                ? `-${sample?.location?.range?.end}`
                                                : ''
                                            }`
                                          : ''
                                        : ''
                                    }`
                                  : ''
                                : sample?.pest?.name
                                ? `${sample?.pest?.name}${
                                    sample?.location?.row
                                      ? ` | ${translate({ text: 'row' })} ${
                                          sample?.location?.row
                                        }`
                                      : ''
                                  }${
                                    sample?.location &&
                                    (sample?.location?.post ||
                                      sample?.location?.range?.start ||
                                      sample?.location?.range?.end)
                                      ? sample?.location?.post
                                        ? `${
                                            !sample?.location?.row ? ' |' : ''
                                          } ${translate({ text: 'pole' })} ${
                                            sample?.location?.post
                                          }`
                                        : sample?.location?.range?.start
                                        ? `${
                                            !sample?.location?.row ? ' |' : ''
                                          } ${translate({ text: 'pole' })} ${
                                            sample?.location?.range?.start
                                          }${
                                            sample?.location?.range?.end
                                              ? `-${sample?.location?.range?.end}`
                                              : ''
                                          }`
                                        : ''
                                      : ''
                                  }`
                                : ''
                            }`}
                            style={{ margin: 16 }}
                          >
                            {sample?.products &&
                              sample?.products.map(product => {
                                const wizLines =
                                  wizardAdvice?.object?.advice?.lines
                                const correspondingWizLine = wizLines?.find(
                                  (line, lineIndex) => lineIndex === index
                                ) || { products: [] }
                                const isSelected =
                                  correspondingWizLine.products.find(
                                    prod =>
                                      prod?.product?.id === product?.product?.id
                                  )
                                return (
                                  <SafeListItem
                                    title={`${product?.product?.name}${
                                      product?.product?.wCode &&
                                      product?.product?.wCode !== ''
                                        ? ` (${product?.product?.wCode})`
                                        : ''
                                    }`}
                                    description={
                                      product?.dosages &&
                                      product?.dosages?.length > 0
                                        ? product?.dosages?.length === 1
                                          ? `${
                                              product?.dosages?.length
                                            } ${translate({ text: 'dosage' })}`
                                          : `${
                                              product?.dosages?.length
                                            } ${translate({
                                              text: 'differentDosages',
                                            })}`
                                        : `${translate({
                                            text: 'noDosagesFound',
                                          })}`
                                    }
                                    onPress={() =>
                                      onPressProductSample(index, product)
                                    }
                                    left={
                                      <View>
                                        <RadioButton.Android
                                          status={
                                            isSelected ? 'checked' : 'unchecked'
                                          }
                                          onPress={() =>
                                            onPressProductSample(index, product)
                                          }
                                        />
                                      </View>
                                    }
                                  />
                                )
                              })}
                          </Box>
                        )
                      })}
                  </>
                ) : (
                  <>
                    {crud.createAdviceErrorMessage ? (
                      <Text style={{ paddingLeft: 16 }}>
                        {translate({ text: 'somethingWentWrong' })}
                        {crud.createAdviceErrorMessage}
                      </Text>
                    ) : null}
                  </>
                )}
              </Resizer>
            </ScrollView>
            <BottomFab
              icon={'check'}
              label={translate({ text: 'makeAdvice' })}
              loading={advice?.updating}
              onPress={saveAdvice}
              disabled={
                wizardAdvice?.object?.advice?.lines?.some(
                  line => line?.products?.length === 0
                ) ||
                loading ||
                !(crud && crud.advice && crud.advice.lines) ||
                !!crud.createAdviceError
              }
            />
          </>
        )}
      </Translator>
    </>
  )
}

export default AdjustAdvice
