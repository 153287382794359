import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { List, Menu } from 'react-native-paper'
import { getLanguage } from '../language'

const styles = StyleSheet.create({
  root: {
    // flexDirection: 'row',
    // height: 56,
    // alignItems: 'center',
    // flex: 1,
  },
  labelButton: {
    flex: 1,
  },
  label: {
    padding: 8,
    marginLeft: 6,
  },
})

const rightFunc = p => <List.Icon {...p} icon="chevron-down" />

class Options extends Component {
  state = {
    visible: false,
  }
  _openMenu = () => this.setState({ visible: true })
  _closeMenu = () => this.setState({ visible: false })
  _select = option => () => {
    this._closeMenu()
    this.props.onChange(option.symbol ? option : option.value)
  }

  render() {
    const { value, label, style, editable } = this.props
    const options = this.props.options || []
    const selectedOption = options.find(option =>
      option && option.id
        ? option.id === (value && value.id)
        : option.value === value
    )
    const language = getLanguage()
    return (
      <View style={[styles.root, style]}>
        <Menu
          visible={this.state.visible}
          onDismiss={this._closeMenu}
          anchor={
            <List.Item
              onPress={this._openMenu}
              title={
                selectedOption && selectedOption.name
                  ? language === 'nl'
                    ? selectedOption.name.nl
                      ? selectedOption.name.nl
                      : selectedOption.name.en
                      ? selectedOption.name.en
                      : selectedOption.title && selectedOption.title
                    : selectedOption.name.en
                    ? selectedOption.name.en
                    : selectedOption.name.nl
                    ? selectedOption.name.nl
                    : selectedOption.title && selectedOption.title
                  : label
              }
              description={selectedOption ? label : ''}
              right={editable ? rightFunc : null}
              disabled={!editable}
            />
          }
        >
          {options.map((option, index) => (
            <Menu.Item
              key={`${index}_${options.title}`}
              onPress={this._select(option)}
              title={
                option.name && language === 'nl'
                  ? option.name.nl
                    ? option.name.nl
                    : option.name.en
                    ? option.name.en
                    : option.title
                  : option.name.en
                  ? option.name.en
                  : option.name.nl
                  ? option.name.nl
                  : option.title
              }
              description={option.symbol ? option.symbol : option.description}
            />
          ))}
        </Menu>
      </View>
    )
  }
}

Options.defaultProps = {
  editable: true,
}
export default Options
