import { combineReducers } from 'redux'

import activeSubstance from './ActiveSubstance/reducer'
import admissionCategory from './AdmissionCategory/reducer'
import admissionHolder from './AdmissionHolder/reducer'
import crop from './Crop/reducer'
import knpvCrop from './KNPVCrop/reducer'
import pest from './Pest/reducer'
import productType from './ProductType/reducer'
import location from './Location/reducer'
import sprayMode from './SprayMode/reducer'
import switches from './Switches/reducer'
import advisorFarmer from './AdvisorFarmer/reducer'
import contract from '../GroupedCrop/Contract/List/reducer'
import organizationType from './OrganizationType/reducer'

import groundType from './GroundType/reducer'
import region from './Region/reducer'
import sprayType from './SprayType/reducer'
import sprayProduct from './SprayProduct/reducer'
import airAssisting from './AirAssisting/reducer'
import bbchScale from './BBCHScale/reducer'
import sprayDirection from './SprayDirection/reducer'
import groundwaterProtectionArea from './GroundWaterProtectionArea/reducer'
import sprayDate from './SprayDate/reducer'
import parcelCloseToWater from './ParcelCloseToWater/reducer'
import cropFreeBufferZone from './CropFreeBufferZone/reducer'
import aquaticBufferZone from './ParcelCloseToWater/AquaticBufferZone/reducer'
import resistance from './Resistance/reducer'
import certification from './Switches/Certification/reducer'
import sprayNozzle from './SprayNozzle/reducer'
import growingPlan from './GrowingPlan/reducer'
import sprayTechnique from './SprayTechnique/reducer'
import beneficialOrganism from './BeneficialOrganism/reducer'

const filterReducer = combineReducers({
  activeSubstance,
  admissionHolder,
  beneficialOrganism,
  admissionCategory,
  growingPlan,
  sprayNozzle,
  sprayTechnique,
  crop,
  knpvCrop,
  sprayMode,
  pest,
  productType,
  location,
  switches,
  advisorFarmer,
  organizationType,
  contract,
  groundType,
  region,
  sprayType,
  sprayProduct,
  airAssisting,
  bbchScale,
  sprayDirection,
  groundwaterProtectionArea,
  sprayDate,
  parcelCloseToWater,
  cropFreeBufferZone,
  aquaticBufferZone,
  resistance,
  certification,
})

export default (state: any, action: any) =>
  action.type.includes('RESET')
    ? filterReducer(undefined, action)
    : filterReducer(state, action)
