import React, { useCallback } from 'react'
import { StyleSheet } from 'react-native'
import Resizer from '../../WidthAndHeight/Resizer'
import SafeListItem from '../../WidthAndHeight/SafeListItem'
import { useDispatch } from 'react-redux'
import { setMessages } from '../../Notifications/actions'

import { IconButton } from 'react-native-paper'
import { getLanguage } from '../../language'
// eslint-disable-next-line
const styles = StyleSheet.create({
  rightContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

const enumTranslationNL = {
  DOWNWARDS: 'neerwaarts',
  UP_AND_SIDEWAYS: 'op- en zijwaarts',
}
const enumTranslationEN = {
  DOWNWARDS: 'downwards',
  UP_AND_SIDEWAYS: 'up and sideways',
}
const monthEnumConverter = {
  1: 'jan.',
  2: 'feb.',
  3: 'mrt.',
  4: 'apr.',
  5: 'mei',
  6: 'jun.',
  7: 'jul.',
  8: 'aug.',
  9: 'sept.',
  10: 'okt.',
  11: 'nov.',
  12: 'dec.',
}
const comparisonDosageConverter = {
  eq: 'gelijk aan',
  ne: 'niet gelijk aan',
  gt: 'groter dan',
  ge: 'groter dan of gelijk aan',
  lt: 'kleiner dan',
  le: 'kleiner dan of gelijk aan',
}
const comparisonTimeConverter = {
  eq: 'op',
  ne: 'niet op',
  gt: 'na',
  ge: 'vanaf',
  lt: 'tot',
  le: 'tot en met',
}

function ProductRestrictionItem({ item }) {
  const lang = getLanguage()
  const convertToDate = str => {
    const split = str.split('-')
    const day = Number(split[0])
    const month = Number(split[1])

    return `${day} ${monthEnumConverter[month]}`
  }
  let condition = ''
  item.conditions &&
    item.conditions.forEach(cond => {
      if (cond.crops) {
        cond.crops.forEach(crop => {
          if (crop.attributes && crop.attributes.length > 0) {
            condition +=
              (condition === '' ? 'In de ' : ', en in de ') +
              crop.attributes
                .map(attr => attr.name)
                .filter(n => n)
                .join(', ') +
              (crop.name ? ' van ' + crop.name : '')
          } else {
            condition +=
              (condition === ''
                ? 'In de teelt van '
                : ', en in de teelt van ') + crop.name
          }
        })
      }
      if (cond.timeOfApplication) {
        let timeOfApplicationString = ''
        if (cond.timeOfApplication.from) {
          timeOfApplicationString +=
            comparisonTimeConverter[cond.timeOfApplication.fromOperator] +
            ' ' +
            convertToDate(cond.timeOfApplication.from)
        }
        if (cond.timeOfApplication.to) {
          if (timeOfApplicationString !== '') {
            timeOfApplicationString += ' '
          }
          timeOfApplicationString +=
            comparisonTimeConverter[cond.timeOfApplication.toOperator] +
            ' ' +
            convertToDate(cond.timeOfApplication.to)
        }
        condition +=
          condition === ''
            ? timeOfApplicationString.charAt(0).toLocaleUpperCase() +
              timeOfApplicationString.slice(1)
            : ' ' + timeOfApplicationString
      }
      if (cond.dosage) {
        condition += (condition === '' ? 'Met' : ' met') + ' een toepassing '
        if (cond.dosage.min) {
          condition +=
            comparisonDosageConverter[cond.dosage.min.operator] +
            ' ' +
            cond.dosage.min.value +
            ' ' +
            cond.dosage.min.unit
        }
        if (cond.dosage.max) {
          if (cond.dosage.min) {
            condition += ' en '
          }
          condition +=
            comparisonDosageConverter[cond.dosage.max.operator] +
            ' ' +
            cond.dosage.max.value +
            ' ' +
            cond.dosage.max.unit
        }
      }
      if (cond.groundwaterProtectionArea) {
        condition +=
          (condition === '' ? 'In' : ' in') + ' grondwaterbeschermingsgebieden'
      } else if (cond.groundwaterProtectionArea === false) {
        condition +=
          (condition === '' ? 'Niet' : ' niet') +
          ' in grondwaterbeschermingsgebieden'
      }
      if (cond.surfaceWater) {
        condition += (condition === '' ? 'Aan' : ' aan') + ' oppervlaktewater'
      } else if (cond.surfaceWater === false) {
        condition +=
          (condition === '' ? 'Niet' : ' niet') + ' aan oppervlaktewater'
      }
      if (cond.sprayDirection) {
        condition +=
          (condition === '' ? 'Bij' : ' bij') +
          ' ' +
          (lang === 'nl'
            ? enumTranslationNL[cond.sprayDirection]
            : enumTranslationEN[cond.sprayDirection]) +
          ' spuiten'
      }
    })
  let outerTitle = ''
  const listItems =
    item.options &&
    item.options.map((option, i) => {
      let title = ''
      if (option.technique) {
        if (option.technique.driftReduction) {
          title += option.technique.driftReduction.min + '% DRT'
        }
        // Loop over spray techniques.
        if (option.technique.specific) {
          option.technique.specific.forEach(specific => {
            if (specific.name && specific.name.length > 0) {
              title +=
                (title === '' ? 'Toepassingstechniek ' : ' of ') + specific.name
            }
          })
        }
      }
      if (option.nozzle) {
        if (option.nozzle.specific) {
          title +=
            title === ''
              ? option.nozzle.specific.name
              : ' + ' + option.nozzle.specific.name
        }
        if (option.nozzle.driftClassification) {
          title +=
            title === ''
              ? 'Een spuitdop met minimaal ' +
                option.nozzle.driftClassification.min +
                '% DRD'
              : ' + een spuitdop met minimaal ' +
                option.nozzle.driftClassification.min +
                '% DRD'
        }
        if (option.nozzle.dropletSizeClassification) {
          title +=
            title === ''
              ? 'Een spuitdop met minimaal druppelgrootte ' +
                option.nozzle.dropletSizeClassification.min
              : ' + een spuitdop met minimaal druppelgrootte ' +
                option.nozzle.dropletSizeClassification.min
        }
        if (option.nozzle.sprayAngle) {
          title +=
            title === ''
              ? 'Een spuithoek van ' + option.nozzle.sprayAngle.min &&
                option.nozzle.sprayAngle.max
                ? 'minimaal ' +
                  option.nozzle.sprayAngle.min +
                  ' graden en maximaal ' +
                  option.nozzle.sprayAngle.max +
                  ' graden'
                : option.nozzle.sprayAngle.min
                ? 'minimaal ' + option.nozzle.sprayAngle.min + ' graden'
                : 'maximaal ' + option.nozzle.sprayAngle.max + ' graden'
              : ' + een spuithoek van ' + option.nozzle.sprayAngle.min &&
                option.nozzle.sprayAngle.max
              ? 'minimaal ' +
                option.nozzle.sprayAngle.min +
                ' graden en maximaal ' +
                option.nozzle.sprayAngle.max +
                ' graden'
              : option.nozzle.sprayAngle.min
              ? 'minimaal ' + option.nozzle.sprayAngle.min + ' graden'
              : 'maximaal ' + option.nozzle.sprayAngle.max + ' graden'
        }
      }

      if (option.endNozzle) {
        if (option.endNozzle.specific) {
          title +=
            title === ''
              ? option.endNozzle.specific.name
              : ' + ' + option.endNozzle.specific.name
        }
        if (option.endNozzle.driftClassification) {
          title +=
            title === ''
              ? 'Een kantdop met minimaal ' +
                option.endNozzle.driftClassification.min +
                '% DRD'
              : ' + een kantdop met minimaal ' +
                option.endNozzle.driftClassification.min +
                '% DRD'
        }
        if (option.endNozzle.dropletSizeClassification) {
          title +=
            title === ''
              ? 'Een kantdop met minimaal druppelgrootte ' +
                option.endNozzle.dropletSizeClassification.min
              : ' + een kantdop met minimaal druppelgrootte ' +
                option.endNozzle.dropletSizeClassification.min
        }
        if (option.endNozzle.sprayAngle) {
          title +=
            title === ''
              ? 'Een spuithoek van ' + option.endNozzle.sprayAngle.min &&
                option.endNozzle.sprayAngle.max
                ? 'minimaal ' +
                  option.endNozzle.sprayAngle.min +
                  ' graden en maximaal ' +
                  option.endNozzle.sprayAngle.max +
                  ' graden'
                : option.endNozzle.sprayAngle.min
                ? 'minimaal ' + option.endNozzle.sprayAngle.min + ' graden'
                : 'maximaal ' + option.endNozzle.sprayAngle.max + ' graden'
              : ' + een spuithoek van ' + option.endNozzle.sprayAngle.min &&
                option.endNozzle.sprayAngle.max
              ? 'minimaal ' +
                option.endNozzle.sprayAngle.min +
                ' graden en maximaal ' +
                option.endNozzle.sprayAngle.max +
                ' graden'
              : option.endNozzle.sprayAngle.min
              ? 'minimaal ' + option.endNozzle.sprayAngle.min + ' graden'
              : 'maximaal ' + option.endNozzle.sprayAngle.max + ' graden'
        }
        if (option.endNozzle.unspecified) {
          title += title === '' ? 'Een kantdop' : ' + een kantdop'
        }
      }
      if (option.sprayHeight) {
        title +=
          title === ''
            ? 'Spuitboomhoogte van maximaal ' + option.sprayHeight.max + 'cm'
            : ' + spuitboomhoogte van maximaal ' + option.sprayHeight.max + 'cm'
      }
      if (option.groundSpeed) {
        title +=
          title === ''
            ? 'Rijsnelheid van maximaal ' + option.groundSpeed.max + 'km/u'
            : ' + rijsnelheid van maximaal ' + option.groundSpeed.max + 'km/u'
      }
      if (option.nozzleSpacing) {
        title +=
          title === ''
            ? 'Spuitdopafstand van maximaal ' + option.nozzleSpacing.max + 'cm'
            : ' + spuitdopafstand van maximaal ' +
              option.nozzleSpacing.max +
              'cm'
      }
      if (option.cropFreeBufferZone) {
        title +=
          title === ''
            ? 'Teeltvrije zone tot teeltlocatiesrand van ' +
              option.cropFreeBufferZone +
              'cm'
            : ' + teeltvrije zone tot teeltlocatiesrand van ' +
              option.cropFreeBufferZone +
              'cm'
      }
      if (option.aquaticBufferZone) {
        title +=
          title === ''
            ? 'Teeltvrije zone tot oppervlaktewater van ' +
              option.aquaticBufferZone +
              'cm'
            : ' + teeltvrije zone tot oppervlaktewater van ' +
              option.aquaticBufferZone +
              'cm'
      }
      if (option.timeOfApplication) {
        let timeOfApplicationString = ''
        if (option.timeOfApplication.from) {
          timeOfApplicationString +=
            comparisonTimeConverter[option.timeOfApplication.fromOperator] +
            ' ' +
            convertToDate(option.timeOfApplication.from)
        }
        if (option.timeOfApplication.to) {
          if (timeOfApplicationString !== '') {
            timeOfApplicationString += ' '
          }
          timeOfApplicationString +=
            comparisonTimeConverter[option.timeOfApplication.toOperator] +
            ' ' +
            convertToDate(option.timeOfApplication.to)
        }
        title +=
          title === ''
            ? timeOfApplicationString.charAt(0).toLocaleUpperCase() +
              timeOfApplicationString.slice(1)
            : ' ' + timeOfApplicationString
      }
      if (option.numberOfTreatments) {
        if (option.numberOfTreatments.max) {
          title +=
            title === ''
              ? 'Maximaal ' + option.numberOfTreatments.max + 'x toepassen'
              : ' maximaal ' + option.numberOfTreatments.max + 'x toepassen'
        }
      }
      outerTitle += title.charAt(0).toLocaleLowerCase() + title.slice(1)
      return <SafeListItem title={`${i + 1} - ` + title} lineNumber={10} />
    })
  const dispatch = useDispatch()

  const onPress = useCallback(
    raw => {
      dispatch(setMessages([raw]))
    },
    [dispatch]
  )
  return (
    <Resizer>
      <SafeListItem
        title={
          condition === ''
            ? outerTitle.charAt(0).toUpperCase() + outerTitle.slice(1) + '.'
            : item.options.length === 0
            ? 'Verboden ' +
              condition.charAt(0).toLowerCase() +
              condition.slice(1) +
              '.'
            : item.options.length === 1
            ? condition + ' ' + outerTitle + '.'
            : condition + ':'
        }
        lineNumber={10}
        right={
          <IconButton
            onPress={() => onPress([item.raw])}
            icon="information-outline"
          />
        }
      />
      {item.options.length === 1 ? null : listItems}
      {/*br deleted because off crash of native apps*/}
      {/*<br />*/}
    </Resizer>
  )
}

export default ProductRestrictionItem
