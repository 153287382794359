import actions from './actions'
import organizationActions from '../../Farmer/Organization/actions'

import saga from '../../GeneralListFunctions/sagaFactory'

export default () =>
  saga(
    actions,
    () => 'v1/auth/organization/manage',
    state => state.filter.advisorFarmer,
    {
      reloadActions: [organizationActions.CREATED, organizationActions.REMOVED],
    }
  )
