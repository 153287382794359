import React from 'react'
import Svg from './SvgAbstraction'
import Path from './PathAbstraction'

import { color, defaultProps, propTypes } from './standardVector'

export const width = 24
export const height = 24

const Vector = props => (
  <Svg
    width={props.width || width}
    height={props.height || height}
    className={props.className}
    viewBox="0 0 24 24"
  >
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M7.66803 12.5L8.25003 6.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M16.332 12.5L15.75 6.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M9.25 3.5H14.75C15.0152 3.5 15.2696 3.60536 15.4571 3.79289C15.6446 3.98043 15.75 4.23478 15.75 4.5V6.5H8.25V4.5C8.25 4.23478 8.35536 3.98043 8.54289 3.79289C8.73043 3.60536 8.98478 3.5 9.25 3.5V3.5Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M9.25 12.5H7.25C6.97386 12.5 6.75 12.7239 6.75 13V19.5C6.75 19.7761 6.97386 20 7.25 20H9.25C9.52614 20 9.75 19.7761 9.75 19.5V13C9.75 12.7239 9.52614 12.5 9.25 12.5Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M6.75 16.25H9.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M16.75 12.5H14.75C14.4739 12.5 14.25 12.7239 14.25 13V19.5C14.25 19.7761 14.4739 20 14.75 20H16.75C17.0261 20 17.25 19.7761 17.25 19.5V13C17.25 12.7239 17.0261 12.5 16.75 12.5Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M14.25 16.25H17.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M9.75 14.75H14.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M6.75 13.25H0.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M23.25 13.25H17.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M2.625 17C2.41789 17 2.25 16.8321 2.25 16.625C2.25 16.4179 2.41789 16.25 2.625 16.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M2.625 17C2.83211 17 3 16.8321 3 16.625C3 16.4179 2.83211 16.25 2.625 16.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M2.625 20C2.41789 20 2.25 19.8321 2.25 19.625C2.25 19.4179 2.41789 19.25 2.625 19.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M2.625 20C2.83211 20 3 19.8321 3 19.625C3 19.4179 2.83211 19.25 2.625 19.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M21.375 17C21.1679 17 21 16.8321 21 16.625C21 16.4179 21.1679 16.25 21.375 16.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M21.375 17C21.5821 17 21.75 16.8321 21.75 16.625C21.75 16.4179 21.5821 16.25 21.375 16.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M21.375 20C21.1679 20 21 19.8321 21 19.625C21 19.4179 21.1679 19.25 21.375 19.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M21.375 20C21.5821 20 21.75 19.8321 21.75 19.625C21.75 19.4179 21.5821 19.25 21.375 19.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </Svg>
)

Vector.propTypes = propTypes
Vector.defaultProps = defaultProps

export default Vector
