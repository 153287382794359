import React from 'react'

export default function FiltersContainer({ children }) {
  return (
    <div
      className={'filters-container-scrollable'}
      // style={{ backgroundColor: '#2d1c5d' }}
    >
      {children}
    </div>
  )
}
