import { actionCreator as ac } from '../../actionCreator'
import { updatableListActions } from '../../GeneralListFunctions/actionFactory'

const listActions = updatableListActions('DISTRIBUTION')

const ns = (type) => `DISTRIBUTION_${type}`

const SELECT = ns('SELECT')
const select = ac(SELECT)

const CONFIRM = ns('CONFIRM')
const confirm = ac(CONFIRM)

const CONFIRMED = ns('CONFIRMED')
const confirmed = ac(CONFIRMED)

export default {
  ...listActions,
  SELECT,
  select,
  CONFIRM,
  confirm,
  CONFIRMED,
  confirmed,
}
