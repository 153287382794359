import { Platform } from 'react-native'

const fonts = Platform.select<any>({
  web: {
    regular: {
      fontFamily: 'Montserrat',
      fontWeight: '400',
    },
    medium: {
      fontFamily: 'Montserrat',
      fontWeight: '400',
    },
    light: {
      fontFamily: 'Montserrat',
      fontWeight: '300',
    },
    thin: {
      fontFamily: 'Montserrat',
      fontWeight: '100',
    },
  },
  ios: {
    regular: {
      fontFamily: 'Montserrat',
      fontWeight: '500',
    },
    medium: {
      fontFamily: 'Montserrat',
      fontWeight: '600',
    },
    light: {
      fontFamily: 'Montserrat',
      fontWeight: '400',
    },
    thin: {
      fontFamily: 'Montserrat',
      fontWeight: '100',
    },
  },
  default: {
    regular: {
      fontFamily: 'Montserrat-Medium',
      fontWeight: 'normal',
    },
    medium: {
      fontFamily: 'Montserrat-SemiBold',
      fontWeight: 'normal',
    },
    light: {
      fontFamily: 'Montserrat-Regular',
      fontWeight: 'normal',
    },
    thin: {
      fontFamily: 'Montserrat-Thin',
      fontWeight: 'normal',
    },
  },
})

export default fonts

// const fonts = Platform.select<any>({
//   web: {
//     regular: {
//       fontFamily: 'Jost',
//       fontWeight: '400',
//     },
//     medium: {
//       fontFamily: 'Jost',
//       fontWeight: '500',
//     },
//     light: {
//       fontFamily: 'Jost',
//       fontWeight: '300',
//     },
//     thin: {
//       fontFamily: 'Jost',
//       fontWeight: '100',
//     },
//   },
//   ios: {
//     regular: {
//       fontFamily: 'Jost',
//       fontWeight: '500',
//     },
//     medium: {
//       fontFamily: 'Jost',
//       fontWeight: '600',
//     },
//     light: {
//       fontFamily: 'Jost',
//       fontWeight: '400',
//     },
//     thin: {
//       fontFamily: 'Jost',
//       fontWeight: '100',
//     },
//   },
//   default: {
//     regular: {
//       fontFamily: 'Jost-Medium',
//       fontWeight: 'normal',
//     },
//     medium: {
//       fontFamily: 'Jost-SemiBold',
//       fontWeight: 'normal',
//     },
//     light: {
//       fontFamily: 'Jost-Regular',
//       fontWeight: 'normal',
//     },
//     thin: {
//       fontFamily: 'Jost-Thin',
//       fontWeight: 'normal',
//     },
//   },
// })
//
// export default fonts
