import React from 'react'
import Svg from './SvgAbstraction'
import Path from './PathAbstraction'

import { color, defaultProps, propTypes } from './standardVector'

export const width = 24
export const height = 24

const Vector = props => (
  <Svg
    width={props.width || width}
    height={props.height || height}
    className={props.className}
    viewBox="0 0 24 24"
  >
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M.75,23.25V1.941A1.108,1.108,0,0,1,1.75.75h4a1.108,1.108,0,0,1,1,1.191V23.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M6.75 4.5L4.5 4.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M6.75 8.25L4.5 8.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M6.75 15.75L4.5 15.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M6.75 12L4.5 12"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M6.75 19.532L4.5 19.532"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M23.25,16.944a3.185,3.185,0,0,1-2.759-1.718h0a4.4,4.4,0,0,1-3.265,1.718,3.834,3.834,0,0,1-3.011-1.718h0S12.541,17.35,10.5,16.914"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M23.25,21.753a3.185,3.185,0,0,1-2.759-1.717h0a4.4,4.4,0,0,1-3.265,1.717,3.834,3.834,0,0,1-3.011-1.717h0s-1.668,2.123-3.709,1.687"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M23.25,12.135a3.185,3.185,0,0,1-2.759-1.718h0a4.4,4.4,0,0,1-3.265,1.718,3.834,3.834,0,0,1-3.011-1.718h0s-1.668,2.124-3.709,1.688"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </Svg>
)

Vector.propTypes = propTypes
Vector.defaultProps = defaultProps

export default Vector
