import React, { PureComponent } from 'react'
import { StyleSheet, View } from 'react-native'
import { connect } from 'react-redux'
import { List } from 'react-native-paper'
import { push } from '../Navigation'
import { PDF_ROUTE } from '../Routes.config'
import { getProductData } from './selectors'
import { Translate, Translator } from 'react-translated'
import DocumentIcon from "../vectors/Document";

const styles = StyleSheet.create({
  root: {
    // paddingTop: 20,
    // borderBottomColor: '#EDEDED',
    // backgroundColor: '#FCFCFC',
    // borderBottomWidth: 1,
    marginBottom: 14,
  },
  icon: {
      width: 24,
      height: 24,
      margin: 12,
  },
  listItem: {
    borderRadius: 10,
    margin: 16,
    marginBottom: 16 / 2,
    borderColor: '#EDEDED',
    borderWidth: 1,
  },
})

class Document extends PureComponent {
  _press = () => {
    this.props.onPress(this.props.document)
  }
  render() {
    const { document, testID } = this.props
    console.log(document)
    return (
      <Translator>
        {({ translate }) => (
          <List.Item
            key={document.key}
            testID={testID}
            title={document.label}
            description={
              document.version
                ? `${translate({ text: 'version' })}: ${document.version}`
                : ''
            }
            onPress={this._press}
            style={styles.listItem}
            left={() =>
              <View style={styles.icon}>
                <DocumentIcon color={'blue'} />
              </View>
            }
          />
        )}
      </Translator>
    )
  }
}

export function getPDFLink(object) {
  let link = undefined
  console.log(object)
  if (
    object &&
    object.links &&
    object.links.length > 0 &&
    object.links.find(link => link.type === 'pdf')
  ) {
    console.log({ links: object.links })
    const found = object.links.find(link => link.type === 'pdf')
    link = found && found.url
  } else if (object && object.sourceUrl) {
    link = object.sourceUrl
  }
  console.log({ link })

  return link
}

class ProductSummary extends PureComponent {
  _click = doc => {
    const link = getPDFLink(doc)
    if (!link) {
      return
    }

    push(this.props.componentId, {
      component: {
        name: PDF_ROUTE,
        passProps: {
          url: encodeURI(link),
          title: this.props.name,
          type: encodeURI(doc.key),
        },
      },
    })
  }
  render() {
    const { loading, documents } = this.props
    return (
      <View style={styles.root}>
        {loading && <List.Item title={'Laden'} />}
        {!loading && documents.length === 0 && (
          <List.Item
            title={'Geen documenten'}
            description={'Geen documenten beschikbaar'}
          />
        )}
        {documents.map(document => (
          <Document
            testID={document.key}
            key={document.key}
            document={document}
            onPress={this._click}
          />
        ))}
      </View>
    )
  }
}

export const getOrder = type => {
  if (type === 'prescription') {
    return 10
  }
  if (type === 'safety_datasheet') {
    return 20
  }
  if (type === 'label') {
    return 30
  }
  return 100
}
export const getLabel = type => {
  if (type === 'prescription') {
    return <Translate text={'prescription'} />
  }
  if (type === 'safety_datasheet') {
    return <Translate text={'safetySheet'} />
  }
  if (type === 'label') {
    return <Translate text={'label'} />
  }

  return `${type}`
}
export const sortDocumentFunc = (a, b) => getOrder(a.type) - getOrder(b.type)

// push again
const mapStateToProps = state => {
  const loading = state.products.crud.loading
  const product = getProductData(state)
  const documentsWithLabel = (product.documents || []).map(document => ({
    ...document,
    label: getLabel(document.key),
  }))

  documentsWithLabel.sort(sortDocumentFunc)

  return {
    name: product.name,
    documents: documentsWithLabel,
    loading,
  }
}

export default connect(mapStateToProps)(ProductSummary)
