import React, { Component } from 'react'
import { Platform, Pressable, View } from 'react-native'
import { IconButton, Text, Title } from 'react-native-paper'

class Box extends Component<any, any> {
  constructor(props: any) {
    super(props)

    this.state = { expanded: !!props.expanded || !props.expandable }
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (
      nextProps.expanded !== this.state.expanded &&
      (nextProps.expanded || nextProps.force)
    ) {
      this.setState({
        expanded: nextProps.expanded,
      })
    }
  }
  _toggle = () => {
    const expanded = !this.state.expanded
    this.setState({ expanded })
    this.props.onExpand(expanded)
  }

  render() {
    const onClick = this.props.expandable ? { onClick: this._toggle } : {}
    // const onClickIfNotExpanded =
    //   this.props.expandable && !this.props.expanded
    //     ? { onClick: this._toggle }
    //     : {}
    const { props } = this

    return (
      <View
        style={[
          {
            backgroundColor: '#FFF',
            shadowColor: '#000',
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,

            elevation: 5,
            borderRadius: 5,
          },
          props.expandable &&
            !props.expanded &&
            Platform.OS === 'web' && { cursor: 'pointer' },
          props.style,
        ]}
      >
        <View
          style={[
            {
              flexDirection: 'row',
              alignItems: 'center',
              padding: props.noPadding ? 0 : props.smallPadding ? 8 : 16,
              paddingLeft: props.noPadding ? 0 : props.smallPadding ? 16 : 16,
            },
            // @ts-ignore
            props.expandable && Platform.OS === 'web' && { cursor: 'pointer' },
          ]}
        >
          {props.noTitle ? null : (
            <Pressable onPress={onClick?.onClick}>
              <View
                style={
                  Platform.OS === 'web'
                    ? { flex: 1, maxWidth: 500 }
                    : { maxWidth: '77%' }
                }
                {...onClick}
              >
                <Title style={props.smallPadding && { fontSize: 16 }}>
                  {props.title}
                </Title>
                {props.subTitle && (
                  <Text
                    onTextLayout={undefined}
                    dataDetectorType={undefined}
                    onPressIn={undefined}
                    onPressOut={undefined}
                    android_hyphenationFrequency={undefined}
                  >
                    {props.subTitle}
                  </Text>
                )}
              </View>
            </Pressable>
          )}
          {props.icons && props.icons}

          {props.badge && (
            <View
              style={{
                height: 27,
                width: 27,
                backgroundColor: '#dadada',
                // borderRadius: '100%',
                // display: 'inline-block',
                // verticalAlign: 'middle',
                marginTop: 2.5,
                // fontSize: 13,
              }}
            >
              {props.badgeCount}
            </View>
          )}

          {this.props.removeAction &&
          this.props.removeAble &&
          this.props.expandable ? (
            <View
              style={{
                alignItems: 'center',
                justifyContent: 'flex-end',
                height: 32,
                flex: 1,
                // marginRight: 48,
                zIndex: 100,
                flexDirection: 'row',
              }}
            >
              <IconButton icon={'delete'} onPress={this.props.removeAction} />
              {this.state.expanded ? (
                <IconButton icon={'chevron-up'} onPress={this._toggle} />
              ) : (
                <IconButton icon={'chevron-down'} onPress={this._toggle} />
              )}
            </View>
          ) : (
            <>
              {this.props.removeAction && this.props.removeAble && (
                <View
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: 0,
                    height: 32,
                    marginLeft: 16,
                    zIndex: 100,
                  }}
                >
                  <IconButton
                    icon={'delete'}
                    onPress={this.props.removeAction}
                  />
                </View>
              )}

              {this.props.expandable && (
                <View
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: 0,
                    height: 32,
                    marginRight: 16,
                    zIndex: 100,
                  }}
                >
                  {this.state.expanded ? (
                    <IconButton icon={'chevron-up'} onPress={this._toggle} />
                  ) : (
                    <IconButton icon={'chevron-down'} onPress={this._toggle} />
                  )}
                </View>
              )}
            </>
          )}
        </View>
        {this.state.expanded && (
          <View
            style={{
              padding: props.noPadding ? 0 : props.smallPadding ? 8 : 16,
            }}
          >
            {props.children}
          </View>
        )}
      </View>
    )
  }
}

export default Box
