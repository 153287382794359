import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { connect } from 'react-redux'
import { Appbar, List, RadioButton, withTheme } from 'react-native-paper'
import actions from '../GrowingPeriod/actions'
import registrationActions from '../actions'

import GeneralList from '../../GeneralListFunctions/GeneralList'
import PageHeader from '../../PageHeader'
import Resizer from '../../WidthAndHeight/Resizer'
import Navigation from '../../Navigation'
import { fromTimestamp, getFriendlyDate } from '../../dayjs'
import { Translator } from 'react-translated'

class GrowingPeriodScreen extends Component {
  _press = growingPeriod => {
    this.props.setRegistrationData({
      growingPeriod,
    })
    this.props.next()
  }

  _renderLeft = (item, isSelected) => () => {
    return (
      <View style={styles.leftContainer}>
        <RadioButton.Android
          status={isSelected ? 'checked' : 'unchecked'}
          onPress={() => this._press(item)}
        />
      </View>
    )
  }

  _onBack = () => {
    Navigation.pop(this.props.componentId)
  }
  _renderItem = ({ item }) => {
    const { variety, startDate, endDate } = item
    let descriptionA = []
    if (variety) {
      descriptionA.push(variety)
    }
    if (startDate || endDate) {
      descriptionA.push(
        getFriendlyDate(fromTimestamp(startDate)) +
          ' t/m ' +
          getFriendlyDate(fromTimestamp(endDate))
      )
    }

    const isSelected = this.props.selectedGrowingPeriods?.id === item.id

    return (
      <Resizer>
        <List.Item
          key={item.id}
          title={item?.crop?.name}
          description={descriptionA.join(', ')}
          onPress={() => this._press(item)}
          left={this._renderLeft(item, isSelected)}
        />
      </Resizer>
    )
  }

  _getParams = () => {
    return {}
  }
  render() {
    const { componentId, prev } = this.props

    return (
      <Translator>
        {({ translate }) => (
          <GeneralList
            {...this.props}
            list={{
              data: this.props.growingPeriods,
            }}
            extraParams={this._getParams()}
            searchPlaceholder={translate({ text: 'searchGrowingPeriod' })}
            renderItem={this._renderItem}
            icon
            appbarContent={
              <PageHeader
                componentId={componentId}
                back={prev}
                title={
                  <Appbar.Content
                    title={translate({ text: 'chooseGrowingPeriod' })}
                  />
                }
              />
            }
            context={'activity'}
            // onPressFab={this.props.next}
            // fabIcon={'chevron-right'}
            // fabLabel={translate({ text: 'Next' })}
          />
        )}
      </Translator>
    )
  }
}
// eslint-disable-next-line
const styles = StyleSheet.create({})
function groupByKey(array, key) {
  const val = array?.reduce((hash, obj) => {
    if (obj?.[key] === undefined) return hash
    return Object.assign(hash, {
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    })
  }, {})
  // Create new array from grouped object's values and only use the first item of each array
  return Object.values(val).map(item => item[0])
}
const mapStateToProps = state => {
  return {
    growingPeriods: [
      ...(state?.registration?.crud?.object?.organizationField
        ?.growingPeriods ||
        state?.registration?.crud?.object?.growingPeriods ||
        []),
      ...groupByKey(
        (
          state?.registration?.crud?.object?.organizationField?.sections?.map(
            section => section.growingPeriods
          ) || []
        ).flat(),
        'id'
      ),
    ],
    selectedGrowingPeriods: state?.registration?.crud?.object?.growingPeriod,
  }
}

export default connect(mapStateToProps, {
  ...actions,
  setRegistrationData: registrationActions.setData,
})(withTheme(GrowingPeriodScreen))
