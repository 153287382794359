import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'
import { connect } from 'react-redux'
import { withTheme, RadioButton } from 'react-native-paper'
import actions from './actions'
import GeneralList from '../../../GeneralListFunctions/GeneralList'
import ItemInList from '../../../FilterType/ItemInList'
import FilterHeader from '../../../FilterType/FilterHeader'
import fonts from '../../../fonts'
import Navigation from '../../../Navigation'

class ContractListScreen extends Component {
  _getParams = (ignore, inputSearch) => {
    const { list } = this.props
    let search = inputSearch
    if (search === undefined) {
      search = list.params && list.params.search
    }

    let params = {
      ...list.params,
      sort: 'number',
      search,
      [`search[name]`]: search,
      name: search,
    }

    return params
  }
  _select = (item) => {
    const {
      growingPeriod,
      organizationField,
      organization,
      filter,
      onSelect,
      componentId,
    } = this.props
    if (onSelect) {
      onSelect(item)
    } else {
      let org =
        organizationField &&
        organizationField.object &&
        organizationField.object.organization

      if (
        !org &&
        organizationField.object &&
        organizationField.object.fieldArray &&
        organizationField.object.fieldArray.length > 0
      ) {
        org = organizationField.object.fieldArray[0].organization
      }
      if (filter) {
        this.props.select(item, { singleSelect: true })
        Navigation.pop(componentId)
      } else {
        this.props.setData({
          ...item,
          growingPeriods: [growingPeriod],
          growerOrganization: org,
          consumerOrganization: organization,
        })
      }
    }
  }

  _createFromSearch = () => {
    const { growingPeriod, list, organizationField, organization } = this.props
    let org = organizationField.object.organization

    if (
      !org &&
      organizationField.object.fieldArray &&
      organizationField.object.fieldArray.length > 0
    ) {
      org = organizationField.object.fieldArray[0].organization
    }
    this.props.create({
      number: list.params.search,
      growingPeriods: [growingPeriod],
      growerOrganization: org,
      consumerOrganization: organization,
    })
  }

  _renderLeft = (item, isSelected) => () => {
    return (
      <View style={styles.leftContainer}>
        <RadioButton.Android
          status={isSelected ? 'checked' : 'unchecked'}
          onPress={this._select(item)}
        />
      </View>
    )
  }
  _renderItem = ({ item }) => {
    let isSelected = false
    if (!this.props.filter) {
      isSelected = this.props.isSelected(item)
    } else {
      const { selected } = this.props
      isSelected = selected.some((it) => it.id === item.id)
    }
    return (
      <ItemInList
        onSelect={this._select}
        onPress={this._select}
        item={item}
        filterType={this.props.filterType}
        isSelected={isSelected}
      />
    )
  }
  render() {
    const { componentId, onBack, list, filter, filterType } = this.props
    return (
      <View style={{ marginBottom: 25, height: 600 }}>
        <GeneralList
          {...this.props}
          searchPlaceholder={filterType.searchPlaceholder}
          renderItem={this._renderItem}
          loadListOnMount={this.props.editable}
          list={list}
          icon
          appbarContent={
            <FilterHeader
              onBack={onBack}
              filterType={filterType}
              componentId={componentId}
            />
          }
          getParams={this._getParams}
          fabLabel={
            list &&
            list.data.length === 0 &&
            list.params &&
            list.params.search &&
            !list.loadingList &&
            !list.loadingListError &&
            !filter
              ? 'Aanmaken'
              : undefined
          }
          onPressFab={
            list &&
            list.data.length === 0 &&
            list.params &&
            list.params.search &&
            !list.loadingList &&
            !list.loadingListError &&
            !filter &&
            (this.props.organizationField.object.organization ||
              (this.props.organizationField.object.fieldArray &&
                this.props.organizationField.object.fieldArray.length > 0 &&
                this.props.organizationField.object.fieldArray[0].organization))
              ? this._createFromSearch
              : undefined
          }
        />
      </View>
    )
  }
}
const styles = StyleSheet.create({
  leftContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 13,
  },
  rightContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 13,
  },
  rightText: {
    paddingRight: 6,
    ...fonts.medium,
  },
})

const mapStateToProps = (state) => ({
  list: state.contract.list,
  organizationField: state.organizationField.crud,
  organization: state.auth.user.organization,
  selected: state.filter.contract.selected,
})

export default connect(mapStateToProps, actions)(withTheme(ContractListScreen))
