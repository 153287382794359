import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Redirect, Router, Switch, Route } from 'react-router-dom'
import { history } from './Navigation.web'
import { listRoutes, detailRoutes } from './Routes.config'
import { closeMenu } from './Navigation.web'
import SideMenu from './SideMenu'

const styles = StyleSheet.create({
  root: {
    flexDirection: 'column',
    overflow: 'hidden',
    minHeight: '100vh',
    height: '100vh',
    maxHeight: '100vh',
  },
})

const renderRoutes = (routes) =>
  routes.map((lr) => (
    <Route
      key={lr.pathOnWeb || lr.path}
      path={lr.pathOnWeb || lr.path}
      exact={lr.exact}
      component={lr.component}
    />
  ))

function AppRouter() {
  // return allRoutes
  //   .filter(r => !r.path.includes('auth'))
  //   .map(r => (
  //     <r.component
  //       match={{ params: {} }}
  //       location={{ pathname: '', search: '?code=1' }}
  //       history={{ replace: () => {} }}
  //     />
  //   ))
  return (
    <Router history={history}>
      <View style={styles.root}>
        <View style={{ zIndex: 1001 }}>
          <div className="side-container" onClick={closeMenu}>
            <div className="side">
              <View style={styles.side}>
                <SideMenu style={{ height: '100vh' }} />
              </View>
            </div>
          </div>
        </View>
        <View style={{ flex: 1 }}>
          <Switch>
            {renderRoutes(listRoutes)}
            <Redirect from="/" to="/home" exact />
          </Switch>
        </View>
        <View
          style={{
            position: 'absolute',
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
            zIndex: 1000,
          }}
          pointerEvents="box-none"
        >
          <Switch>{renderRoutes(detailRoutes)}</Switch>
        </View>
      </View>
    </Router>
  )
}
export default AppRouter
