import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { connect } from 'react-redux'
import {
  Checkbox,
  IconButton,
  RadioButton,
  Text,
  withTheme,
} from 'react-native-paper'
import actions from './actions'
import GeneralList from '../../GeneralListFunctions/GeneralList'
import ItemInTree from '../../FilterType/ItemInTree'
import FilterHeader from '../../FilterType/FilterHeader'
import fonts from '../../fonts'
import ItemParent from '../../FilterType/ItemParent'

const isZero = value => !value || value === 0

class BeneficialOrganismList extends Component {
  _goUp = parentId => {
    if (parentId) {
      this._load(parentId || null)
    } else {
      this._load(this.props.tree[0].parent.id || null)
    }
  }
  _renderAboveHeader = () => {
    const { tree, parentId } = this.props
    const parent = tree && tree[0]
    console.log(parentId)
    if (parent) {
      return (
        <ItemParent
          title={this.props.filterType.getItemLabel(parent)}
          description={this.props.filterType.getItemSubLabel(parent)}
          onPress={this._goUp}
        />
      )
    } else if (parentId) {
      return <ItemParent title={'Terug'} onPress={() => this._goUp(parentId)} />
    }
    return null
  }
  _getParams = (ignore, inputSearch, overrideId) => {
    const { list, parentId } = this.props
    let search = inputSearch
    if (search === undefined) {
      search = list.params && list.params.search
    }

    let withId = overrideId === undefined

    let params = {
      ...list.params,
      sort: 'name',
      preloadAll: true,
      search,
      [`search[name]`]: search ? search : '',
      name: search,
      'filter[parentIds][]': withId ? parentId : overrideId,
    }

    return params
  }
  _load = itemId => {
    this.props.loadList({
      params: this._getParams(undefined, '', itemId),
    })
  }
  _press = item => {
    this._load(item.id)
  }
  _select = item => {
    this.props.onSelect(item)
  }

  _renderLeft = (item, isSelected) => () => {
    let SelectionElement

    if (this.props.singleSelect) {
      SelectionElement = RadioButton.Android
    } else if (this.props.multiSelect) {
      SelectionElement = Checkbox.Android
    }

    if (SelectionElement) {
      return (
        <View style={styles.leftContainer}>
          <SelectionElement
            status={isSelected ? 'checked' : 'unchecked'}
            onPress={this._select(item)}
          />
        </View>
      )
    }
    return null
  }
  _renderRight = item => () => {
    if (isZero(item.number_children)) {
      return null
    }
    return (
      <View style={styles.rightContainer}>
        <Text style={styles.rightText}>{item.number_children}</Text>
        <IconButton
          icon={'chevron-right'}
          size={24}
          style={{ padding: 0, margin: 0 }}
        />
      </View>
    )
  }
  _renderItem = ({ item }) => {
    // const { componentId, theme } = this.props
    const isSelected = this.props.isSelected(item)
    return (
      <ItemInTree
        onSelect={this._select}
        onPress={this._press}
        item={item}
        filterType={this.props.filterType}
        isSelected={isSelected}
      />
    )
  }

  render() {
    const { componentId, onBack } = this.props
    return (
      <GeneralList
        {...this.props}
        searchPlaceholder={this.props.filterType.searchPlaceholder}
        renderAboveHeader={this._renderAboveHeader}
        renderItem={this._renderItem}
        loadListOnMount={this.props.editable}
        icon
        appbarContent={
          <FilterHeader
            onBack={onBack}
            filterType={this.props.filterType}
            componentId={componentId}
          />
        }
        getParams={this._getParams}
      />
    )
  }
}

const styles = StyleSheet.create({
  leftContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 13,
  },
  rightContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 13,
  },
  rightText: {
    paddingRight: 6,
    ...fonts.medium,
  },
})

const mapStateToProps = state => ({
  list: state.beneficialOrganism.list,
  tree: state.beneficialOrganism.list.tree,
})

export default connect(
  mapStateToProps,
  actions
)(withTheme(BeneficialOrganismList))
