import { useEffect } from 'react'

import { GeolocateControl } from 'mapbox-gl'
import MapUserLocationHOC from './MapUserLocationHOC'
import config from './config'

function MapUserLocation({
  mapRef,
  subscribeToUserLocation,
  onDebouncedUpdate,
  bounds,
}) {
  useEffect(() => {
    if (mapRef) {
      const geolocate = new GeolocateControl({
        positionOptions: {
          enableHighAccuracy: false,
          maximumAge: 10000000,
          timeout: 6000, // 6 seconds
        },

        // easing: {
        //   duration: 0,
        //   animate: false,
        // },
        fitBoundsOptions: {
          linear: true,
          maxZoom: config.initialZoomLevel,
          zoom: config.initialZoomLevel,
        },
        // trackUserLocation: true,
        showUserLocation: true,
      })
      geolocate.on('geolocate', event => {
        // let map animate...
        onDebouncedUpdate(event)
      })
      geolocate.on('error', error => {
        console.log({ error })
      })
      geolocate.on('success', success => {
        console.log({ success })
      })
      geolocate.on('trackuserlocationstart', trackuserlocationstart => {
        console.log({ trackuserlocationstart })
      })
      geolocate.on('trackuserlocationend', trackuserlocationend => {
        console.log({ trackuserlocationend })
      })
      mapRef.addControl(geolocate)
      if (subscribeToUserLocation) {
        setTimeout(() => {
          geolocate.trigger()
        }, 150)
      }
    }
  }, [mapRef, subscribeToUserLocation, onDebouncedUpdate])

  return null
}
export default MapUserLocationHOC(MapUserLocation)
