import * as a from './actions'

const initialState = false
const authSettings = (state = initialState, { type, payload, error, meta }) => {
  switch (type) {
    case a.TOGGLE: {
      return !state
    }
    default:
      return state
  }
}

export default authSettings
