import { combineReducers } from 'redux'

import crop from './Crop/reducer'
import pest from './Pest/reducer'
import restriction from './Restriction/reducer'

export default combineReducers({
  crop,
  pest,
  restriction,
})
