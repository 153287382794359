import React, { useCallback, useEffect, useState } from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import {
  ActivityIndicator,
  Appbar,
  Button,
  List,
  Snackbar,
  Text,
} from 'react-native-paper'
import actions from './actions'
import a from './actions'
import showLocation from '../helpers/showLocation'
import Fields from '../Form/Fields'
import FormContainer from '../Form/FormContainer'
import detailHOC from '../GeneralListFunctions/detailHOC'

import safeAreaHOC from '../WidthAndHeight/safeAreaHOC'
import Navigation from '../Navigation'
import { fields } from './New/FieldObject'
import FieldsMap from '../FieldMap/FieldsMap'
import KeyboardAvoidingScrollView from '../WidthAndHeight/KeyboardAvoidingScrollView'
import AppbarHeader from '../WidthAndHeight/AppbarHeader'
import AppbarResizer from '../WidthAndHeight/AppbarResizer'
import { getLongitudeAndLatitudeFromField } from '../Map/helpers'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Translate, Translator } from 'react-translated'
import Entrance from '../FieldMap/Entrance'
import { usePrevious } from '../Auth/ChangePassword'
import { getLanguage } from '../language'
import { shareLocation } from '../helpers/shareLocation'
import AddCrops from './New/AddCrops'
import gpActions from './New/GrowingPeriod/actions'
import { findActiveGrowingPeriod } from './FieldListItem'
import Checkbox from '../components/Checkbox'
import BottomFab from '../WidthAndHeight/BottomFab'

function OrganizationFieldDetailScreen({
  crud: {
    loading,
    updating,
    removing,
    creating,
    loaded,
    loadingError,
    loadingErrorMessage,
    updatingError,
    updatingErrorMessage,
    removingError,
    removingErrorMessage,
    object,
    creatingError,
    creatingErrorMessage,
  },
  gpCrud,
  componentId,
  setData,
  onBack,
  onChange,
  onSubmit,
  selectedField,
  selectedFields,
  onRemove,
}) {
  const [changed, setChanged] = useState(false)
  const [entrance, setEntrance] = useState()
  const [changeEntranceMode, setChangeEntranceMode] = useState(false)
  const [firstLoad, setLoaded] = useState(false)
  const [copied, setCopied] = useState(false)
  const oldObject = usePrevious(object)
  const language = getLanguage()

  const [showCrops, setShowCrops] = React.useState(false)
  const organizationField = useSelector(
    state => state.organizationField.crud.object
  )
  const crud = useSelector(state => state.organizationField.crud)

  const dispatch = useDispatch()
  const selectSection = useCallback(
    (item, originalItem) => {
      // Check if the selectedSections all contain the same active growing period ID and if the new section also contains that ID
      // If the new section does not contain that ID, remove all other sections
      // If the new section does contain that ID, just add it to the selected sections

      const activeGrowingPeriod = findActiveGrowingPeriod(item?.growingPeriods)
      let selectedSections = organizationField?.selectedSections || []

      if (activeGrowingPeriod) {
        const allSelectedSectionsHaveActiveGrowingPeriod =
          selectedSections.every(section => {
            const sectionActiveGrowingPeriod = findActiveGrowingPeriod(
              section?.growingPeriods
            )
            return (
              sectionActiveGrowingPeriod &&
              sectionActiveGrowingPeriod.id === activeGrowingPeriod.id
            )
          })

        if (!allSelectedSectionsHaveActiveGrowingPeriod) {
          if (selectedSections.some(selLoc => selLoc.id === item.id)) {
            selectedSections = selectedSections.filter(
              selLoc => selLoc.id !== item.id
            )
          } else {
            selectedSections = [item]
          }
        } else {
          if (selectedSections.some(selLoc => selLoc.id === item.id)) {
            selectedSections = selectedSections.filter(
              selLoc => selLoc.id !== item.id
            )
          } else {
            selectedSections.push(item)
          }
        }
      } else {
        if (selectedSections.some(selLoc => selLoc.id === item.id)) {
          selectedSections = selectedSections.filter(
            selLoc => selLoc.id !== item.id
          )
        } else {
          selectedSections.push(item)
        }
      }

      dispatch(
        a.setData({
          ...originalItem,
          selectedSections,
        })
      )
    },
    [organizationField, dispatch]
  )

  const back = useCallback(() => {
    if (changed) {
      if (Platform.OS === 'web') {
        if (
          window.confirm(
            language === 'nl'
              ? 'U heeft niet opgeslagen werk. Weet u zeker dat u terug wilt gaan?'
              : 'You have unsaved work. Are you sure you want to leave the page?'
          )
        ) {
          if (onBack) {
            onBack()
          } else {
            Navigation.pop(componentId)
          }
        } else {
          // Do nothing!
        }
      }
    } else {
      if (onBack) {
        onBack()
      } else {
        Navigation.pop(componentId)
      }
    }
  }, [componentId, onBack, changed, language])

  useEffect(() => {
    if (
      !firstLoad &&
      object &&
      Object.keys(object).length > 0 &&
      object?.entrances
    ) {
      console.log({ entrance, object })
      setLoaded(true)
      setEntrance(object.entrances)
    }
  }, [firstLoad, object, entrance])

  useEffect(() => {
    if (oldObject !== object) {
      setEntrance(object?.entrances)
    }
  }, [oldObject, object])

  const selectField = useCallback(
    field => {
      setData({
        field,
        entrances: null,
      })
      setEntrance(undefined)
    },
    [setData]
  )
  const saveEntrance = useCallback(
    coords => {
      setChangeEntranceMode(false)
      if (coords) {
        const entranceObject = [
          {
            longitude: coords.geometry.coordinates[1],
            latitude: coords.geometry.coordinates[0],
          },
        ]
        let placeName
        console.log('placeName')

        fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${entranceObject[0].longitude},${entranceObject[0].latitude}.json?language=nl&access_token=pk.eyJ1IjoicHJvZmVjdHMiLCJhIjoiY2p1eTNkZjMwMDQ5NTRlb2F1cHY3bXpjNCJ9.PDaoYVEZ2sqPbqIVtfM2wA`
        )
          .then(res => res.json())
          .then(data => {
            placeName = data.features[0].place_name
            console.log(placeName)

            setEntrance(entranceObject)
            setData({
              entrances: entranceObject,
              name:
                object && object.name && object.name !== ''
                  ? object.name
                  : placeName,
            })
          })
        setEntrance(entranceObject)
      }
    },
    [setData, object]
  )

  const change = useCallback(
    (name, value) => {
      onChange(name, value)
      setChanged(true)
    },
    [onChange]
  )
  const submit = useCallback(() => {
    let newObject = object
    if (
      object &&
      object?.growingPeriods &&
      object?.growingPeriods?.length > 0
    ) {
      dispatch(
        gpActions.create({
          growingPeriods: object?.growingPeriods.map(gp => {
            return {
              ...gp,
              certifications: [
                ...new Set([
                  ...(gp?.certifications || []),
                  gp?.demeter ? 'DEMETER' : undefined,
                  gp?.skal ? 'SKAL' : undefined,
                  gp?.mps ? 'MPS' : undefined,
                  gp?.planetProof ? 'PLANETPROOF' : undefined,
                ]),
              ].filter(n => n),

              startDate: Math.round(gp?.startDate / 1000),
              endDate: Math.round(gp?.endDate / 1000),
              organizationField: {
                id: crud.object.id,
                // sections: crud.object.selectedSections.map(section => {
                //   return {
                //     id: section.id,
                //   }
                // }),
              },
            }
          }),
          growingPeriodsWithIDButShouldBePostedBecauseOtherSectionsWithThisGrowingPeriodIDAreNotSelected:
            [],
        })
      )
    } else {
      newObject = {
        ...object,
        growingPeriods: object?.growingPeriods?.map(gp => {
          return {
            ...gp,
            endDate: Math.round(gp.endDate / 1000),
            startDate: Math.round(gp.startDate / 1000),
          }
        }),
      }
      if (!newObject.id) {
        onSubmit({ payload: newObject })
      } else {
        onSubmit({ payload: { extraBody: newObject } })
      }
      setChanged(false)
    }
  }, [onSubmit, object, dispatch, crud.object])

  const [hasUpdatedAfterGP, setHasUpdatedAfterGP] = useState(false)
  useEffect(() => {
    if (gpCrud?.created && !hasUpdatedAfterGP) {
      setHasUpdatedAfterGP(true)
      let newObject = object
      if (
        object &&
        object?.growingPeriods &&
        object?.growingPeriods.length > 0
      ) {
        newObject = {
          ...object,
          growingPeriods: object?.growingPeriods.map(gp => {
            return {
              ...gp,
              endDate: Math.round(gp.endDate / 1000),
              startDate: Math.round(gp.startDate / 1000),
            }
          }),
        }
      }
      if (!newObject.id) {
        onSubmit({ payload: newObject })
      } else {
        onSubmit({ payload: { extraBody: newObject } })
      }
      setChanged(false)
    }
    return () => {
      setHasUpdatedAfterGP(false)
      dispatch(gpActions.reset())
    }
  }, [object, onSubmit, gpCrud, hasUpdatedAfterGP, dispatch])

  const title = (object && object.name) || '...'

  const share = useCallback(() => {
    // TODO: Base64 encode de coordinaten van object.field.coordinates en voeg deze toe aan de passprops
    // TODO: Base64 encode de entrance, of een random coordinaat van de object.field.coordinates en voeg deze toe aan de passprops.
    const obj = entrance
      ? {
          longitude: entrance[0].longitude,
          latitude: entrance[0].latitude,
        }
      : getLongitudeAndLatitudeFromField(
          null,
          object && object.fields && object.fields.length === 1
            ? object.fields[0]
            : object.field
        ) || {
          longitude: undefined,
          latitude: undefined,
        }
    const { longitude, latitude } = obj
    shareLocation({
      longitude,
      latitude,
      name: (object && object.name) || 'Location',
    })
    // TODO: localhost vervangen door normale URL
    // navigator.clipboard.writeText(
    //   `http://localhost:3000/route/base64field/base64entrance`
    // )
    if (Platform.OS === 'web') {
      setCopied(true)
    }
  }, [entrance, object])

  const navigate = () => {
    const obj = entrance
      ? {
          longitude: entrance[0].longitude,
          latitude: entrance[0].latitude,
        }
      : getLongitudeAndLatitudeFromField(
          null,
          object && object.fields && object.fields.length === 1
            ? object.fields[0]
            : object.field
        ) || {
          longitude: undefined,
          latitude: undefined,
        }
    const { longitude, latitude } = obj

    console.log(object)
    showLocation({
      latitude,
      longitude,
      title, // optional
      googleForceLatLon: true, // optionally force GoogleMaps to use the latlon for the query instead of the title
      // googlePlaceId: 'ChIJGVtI4by3t4kRr51d_Qm_x58', // optionally specify the google-place-id
      // alwaysIncludeGoogle: true, // optional, true will always add Google Maps to iOS and open in Safari, even if app is not installed (default: false)
      dialogTitle: 'Open op kaart', // optional (default: 'Open in Maps')
      dialogMessage: 'Welke app wil je gebruiken?', // optional (default: 'What app would you like to use?')
      cancelText: 'Annuleer', // optional (default: 'Cancel')
      // appsWhiteList: ['google-maps'], // optionally you can set which apps to show (default: will show all supported apps installed on device)
      // app: 'uber'  // optionally specify specific app to use
    })
  }
  return [
    <>
      {showCrops ? (
        <AddCrops
          onFinish={(growingPeriods, onlyBack) => {
            if (onlyBack) {
              dispatch(
                actions.setData({
                  ...crud.object,
                  selectedSections: [],
                })
              )
              setShowCrops(false)
              return
            }
            const notSelectedSections = (crud.object.sections || []).filter(
              section =>
                !crud.object.selectedSections.some(
                  selSection => selSection.id === section.id
                )
            )
            const growingPeriodsWithIDButShouldBePostedBecauseOtherSectionsWithThisGrowingPeriodIDAreNotSelected =
              growingPeriods.filter(
                newGp =>
                  notSelectedSections?.some(section =>
                    section?.growingPeriods?.some(gp => newGp.id === gp.id)
                  ) && newGp.id
              )
            const allSectionsWithThatGrowingPeriod =
              notSelectedSections?.filter(section =>
                section?.growingPeriods?.some(gp2 =>
                  growingPeriodsWithIDButShouldBePostedBecauseOtherSectionsWithThisGrowingPeriodIDAreNotSelected.some(
                    gp => gp.id === gp2.id
                  )
                )
              )

            dispatch(
              gpActions.create({
                growingPeriods: growingPeriods.map(gp => {
                  return {
                    ...gp,
                    certifications: [
                      ...new Set([
                        ...(gp?.certifications || []),
                        gp?.demeter ? 'DEMETER' : undefined,
                        gp?.skal ? 'SKAL' : undefined,
                        gp?.mps ? 'MPS' : undefined,
                        gp?.planetProof ? 'PLANETPROOF' : undefined,
                      ]),
                    ].filter(n => n),

                    startDate: Math.round(gp?.startDate / 1000),
                    endDate: Math.round(gp?.endDate / 1000),
                    organizationField: {
                      // id: crud.object.id,
                      sections: crud.object.selectedSections.map(section => {
                        return {
                          id: section.id,
                        }
                      }),
                    },
                  }
                }),
                growingPeriodsWithIDButShouldBePostedBecauseOtherSectionsWithThisGrowingPeriodIDAreNotSelected:
                  growingPeriodsWithIDButShouldBePostedBecauseOtherSectionsWithThisGrowingPeriodIDAreNotSelected?.map(
                    gp => {
                      return {
                        ...gp,
                        certifications: [
                          ...new Set([
                            ...(gp?.certifications || []),
                            gp?.demeter ? 'DEMETER' : undefined,
                            gp?.skal ? 'SKAL' : undefined,
                            gp?.mps ? 'MPS' : undefined,
                            gp?.planetProof ? 'PLANETPROOF' : undefined,
                          ]),
                        ].filter(n => n),

                        startDate: Math.round(gp?.startDate / 1000),
                        endDate: Math.round(gp?.endDate / 1000),
                        allSectionsWithThatGrowingPeriod:
                          allSectionsWithThatGrowingPeriod,
                        organizationField: {
                          // id: crud.object.id,
                          sections: crud.object.selectedSections.map(
                            section => {
                              return {
                                id: section.id,
                              }
                            }
                          ),
                        },
                      }
                    }
                  ),
              })
            )
            setShowCrops(false)
          }}
        />
      ) : (
        <>
          <AppbarHeader
            key="header"
            dark
            style={{ elevation: 0, justifyContent: 'center' }}
          >
            <AppbarResizer>
              <Appbar.BackAction onPress={back} color="#fff" />
              <Appbar.Content title={title} color="#fff" />

              {object && object.id && !loading ? (
                <Appbar.Action
                  icon={'delete'}
                  onPress={onRemove}
                  color="#fff"
                />
              ) : null}

              {loading || updating || removing || creating ? (
                <ActivityIndicator style={{ padding: 12 }} color={'#FFF'} />
              ) : (
                <>
                  <Appbar.Action
                    icon={'directions'}
                    onPress={navigate}
                    color="#FFF"
                  />
                  <Appbar.Action icon={'check'} onPress={submit} color="#fff" />
                </>
              )}
            </AppbarResizer>
          </AppbarHeader>
          <View style={styles.formContainer}>
            <KeyboardAvoidingScrollView safeSettings={{ bottom: true }}>
              <View style={styles.fieldsMap}>
                {loadingError ||
                updatingError ||
                removingError ||
                creatingError ? (
                  <Text>
                    {loadingErrorMessage ||
                      updatingErrorMessage ||
                      removingErrorMessage ||
                      creatingErrorMessage}
                  </Text>
                ) : null}
              </View>

              <View
                style={[
                  Platform.OS === 'web'
                    ? {
                        maxWidth: 800,
                      }
                    : {
                        height: 350,
                        borderRadius: 8,
                        margin: 16,
                        overflow: 'hidden',
                      },
                  Platform.OS === 'web' && styles.fieldsMapContainer,
                ]}
              >
                <FieldsMap
                  onPressField={selectField}
                  selectedField={selectedField}
                  selectedFields={
                    (object && object.fields && [object]) ||
                    selectedFields ||
                    object?.sections?.map(sec => sec?.field).filter(n => n)
                      ?.length > 0
                      ? object?.sections?.map(sec => sec?.field).filter(n => n)
                      : undefined
                  }
                  entrance={entrance}
                  changeEntrance={changeEntranceMode}
                  finishChangeEntrance={saveEntrance}
                  hasReducerZoom
                  componentId={componentId}
                  headerIcon={''}
                  loaded={loaded}
                  fullScreen={false}
                  loadOnlyWhenCenter
                  noCurrent
                >
                  {entrance && <Entrance entrance={entrance} />}
                </FieldsMap>
                <View style={styles.fieldsMap}>
                  <View
                    style={{ flexDirection: 'row', justifyContent: 'flex-end' }}
                  >
                    {Platform.OS === 'web' && (
                      <Button
                        icon="pencil"
                        style={{ marginTop: 16 }}
                        onPress={() => setChangeEntranceMode(true)}
                        color={'#3da945'}
                        uppercase={false}
                        mode={'outlined'}
                      >
                        <View>
                          <Text style={{ color: '#3da945' }}>
                            {entrance ? (
                              <Translate text="changeEntrance" />
                            ) : (
                              <Translate text="addEntrance" />
                            )}
                          </Text>
                        </View>
                      </Button>
                    )}
                    <Button
                      icon="share"
                      style={{ marginTop: 16, marginLeft: 8 }}
                      onPress={share}
                      color={'#3da945'}
                      uppercase={false}
                      mode={'outlined'}
                    >
                      <Translate text="share" />
                    </Button>
                  </View>
                </View>
              </View>
              <View style={styles.formInner}>
                <FormContainer onSubmit={submit}>
                  <View style={styles.whiteSpace} />
                  <Fields
                    fields={fields}
                    values={object}
                    onChange={change}
                    onSubmit={submit}
                  />
                  {organizationField?.sections?.map(section => {
                    const activeGrowingPeriod = findActiveGrowingPeriod(
                      section?.growingPeriods?.map(gp => {
                        return {
                          ...gp,
                          startDate:
                            gp?.startDate * 1000 || new Date().getTime(),
                          endDate: gp?.endDate * 1000 || new Date().getTime(),
                        }
                      })
                    )
                    return (
                      <List.Item
                        key={section?.id}
                        title={section?.name}
                        description={activeGrowingPeriod?.crop?.name}
                        onPress={() => {
                          selectSection({
                            ...section,
                            growingPeriods: section?.growingPeriods?.map(gp => {
                              return {
                                ...gp,
                                startDate:
                                  gp?.startDate * 1000 || new Date().getTime(),
                                endDate:
                                  gp?.endDate * 1000 || new Date().getTime(),
                              }
                            }),
                          })
                        }}
                        left={() => (
                          <Checkbox
                            style={{ marginTop: 4, marginRight: 4 }}
                            status={
                              (organizationField?.selectedSections || [])?.some(
                                selSec => selSec?.id === section?.id
                              )
                                ? 'checked'
                                : 'unchecked'
                            }
                            // onPress={() => {
                            //   console.log('SELECT CHECK, ', section)
                            // }}
                          />
                        )}
                      />
                    )
                  })}
                </FormContainer>
              </View>
            </KeyboardAvoidingScrollView>
            <Snackbar
              style={{
                position: 'absolute',
                bottom: 16,
                width: 400,
              }}
              visible={copied}
              onDismiss={() => setCopied(false)}
              duration={3000}
              theme={{ colors: { accent: '#FFF' } }}
              action={{
                label: 'OK',
                onPress: () => setCopied(false),
              }}
              wrapperStyle={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Translate text={'locationCopied'} />
            </Snackbar>
            {!(
              !organizationField?.selectedSections ||
              organizationField?.selectedSections?.length === 0
            ) && (
              <Translator>
                {({ translate }) => (
                  <BottomFab
                    testID="addCrops"
                    style={{ marginBottom: 64, zIndex: 200 }}
                    label={translate({ text: 'changeCrops' })}
                    icon="pencil"
                    onPress={() => setShowCrops(true)}
                    loading={crud.updating}
                  />
                )}
              </Translator>
            )}
          </View>
        </>
      )}
    </>,
  ]
}
const styles = StyleSheet.create({
  padding: { padding: 12 },
  whiteSpace: { height: 16 * 2 },
  formContainer: {
    backgroundColor: '#fff',
    zIndex: 100,
    flex: 1,
  },
  formInner: {
    maxWidth: 600,
    alignSelf: 'center',
    width: '100%',
  },
  fieldsMapContainer: {
    height: 500,
    borderRadius: 8,
    margin: 16,
    marginBottom: 0,
    overflow: 'hidden',
    maxWidth: 600,
    alignSelf: 'center',
    width: '100%',
  },
  fieldsMap: {
    maxWidth: 600,
    alignSelf: 'center',
    width: '100%',
  },
})

const mapStateToProps = state => ({
  crud: state.organizationField.crud,
  gpCrud: state.organizationFieldGrowingPeriod.crud,
  selectedField:
    state.organizationField.crud.object &&
    state.organizationField.crud.object.field,
  selectedFields:
    state.organizationField.crud.object &&
    state.organizationField.crud.object.customField &&
    state.organizationField.crud.object.customField.fields,
  contractObject: state.contract.crud.object,
})

export default connect(
  null,
  actions
)(
  safeAreaHOC(
    detailHOC(OrganizationFieldDetailScreen, mapStateToProps, actions, {
      optinEdit: true,
      enableDelete: true,
    })
  )
)
