import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import actions from './actions'
import GeneralList from '../../../GeneralListFunctions/GeneralList'
import FilterHeader from '../../FilterHeader'
import Navigation from '../../../Navigation'

import ItemInList from '../../ItemInList'
import { RootState } from '../../../reducers'
import { FilterType } from '../../config'
import { getInitialListState } from '../../../GeneralListFunctions/reducerFactory'
import { pushCustomEvent } from '../../../Analytics/abstraction'
// @ts-ignore
import { Translate } from 'react-translated'
import { getLanguage, getTranslatedString } from '../../../language'

type Category = {
  id: number
  name: string
  filter: string
}

export const categories = (
  lang: string,
  isProductPage: boolean = false
): Category[] => {
  return [
    {
      id: 1,
      name: getTranslatedString('planetProof', lang),
      filter: 'planetProof',
    },
    { id: 2, name: getTranslatedString('mps', lang), filter: 'mps' },
    { id: 3, name: getTranslatedString('skal', lang), filter: 'skal' },
    !isProductPage
      ? {
          id: 4,
          name: getTranslatedString('demeter', lang),
          filter: 'demeter',
        }
      : null,
    { id: 5, name: getTranslatedString('none', lang), filter: 'none' },
  ].filter(n => n) as Category[]
}

const categoriesList = (lang: string, isProductPage: boolean) => {
  return {
    ...getInitialListState<Category>(),
    data: categories(lang, isProductPage),
    loadingList: false,
    loadingMoreList: false,
    loadedList: true,
  }
}

function List({
  componentId,
  filterType,
  context,
}: {
  componentId: string
  filterType: FilterType
  context: string
}) {
  const selectedArr = useSelector(
    (state: RootState) => state.filter.certification.selectedKeyValue
  )

  const lang = getLanguage()
  console.log({ context })
  const isProductPage = context === 'farmer-product' || context === 'product'

  const list = categoriesList(lang, isProductPage)
  const dispatch = useDispatch()

  const pop = () => {
    Navigation.pop(componentId)
  }

  const select = React.useCallback(
    (
      item: {
        [key: string]: boolean
      },
      isSelected: boolean,
      name: string,
      changed: string
    ) => {
      let newItem = item
      if (changed === 'skal') {
        newItem = { ...newItem, demeter: false }
      } else if (changed === 'demeter') {
        newItem = { ...newItem, skal: false }
      }
      dispatch(actions.selectKeyValue(newItem))
      if (isSelected) {
        pushCustomEvent(`Filter disabled:`, `Keurmerk ${name}`)
      } else {
        pushCustomEvent(`Filter enabled:`, `Keurmerk ${name}`)
      }
    },
    [dispatch]
  )

  const renderItem = React.useCallback(
    ({ item }: { item: Category }) => {
      const isSelected =
        selectedArr &&
        Object.keys(selectedArr).some(key => {
          const it = selectedArr[item.filter]
          if (it && key === item.filter) {
            return true
          }
          return false
        })
      const newItem = { ...item, name: <Translate text={item.name} /> }
      return (
        <ItemInList
          onPress={() =>
            select(
              {
                ...selectedArr,
                [item.filter]: !isSelected,
              },
              isSelected,
              item.name,
              item.filter
            )
          }
          onSelect={() =>
            select(
              {
                ...selectedArr,
                [item.filter]: !isSelected,
              },
              isSelected,
              item.name,
              item.filter
            )
          }
          item={newItem}
          filterType={filterType}
          isSelected={isSelected}
          selectType="checkbox"
        />
      )
    },
    [filterType, selectedArr, select]
  )

  return (
    // @ts-ignore
    <GeneralList<Category>
      list={list}
      searchPlaceholder={filterType.searchPlaceholder}
      showSearch={false}
      renderItem={renderItem}
      icon
      appbarContent={
        <FilterHeader
          filterType={filterType}
          componentId={componentId}
          onBack={pop}
        />
      }
      onPressFab={pop}
      loadListOnMount={false}
      onlyShowFabWhenSelected={false}
    />
  )
}

export default List
