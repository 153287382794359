import React, { useCallback } from 'react'
import { StyleSheet } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import BottomFab from '../WidthAndHeight/BottomFab'
import { loadMyLocation } from '../Map/actions'

const styles = StyleSheet.create({
  currentLocationButton: {
    zIndex: 2,
    backgroundColor: '#FFF',
    marginBottom: 28,
  },
})
export default function LoadCurrentLocationButton({ loading, portalActive }) {
  const loadingMyLocation = useSelector(state => state.map.loading)
  const dispatch = useDispatch()
  const onPress = useCallback(() => {
    dispatch(loadMyLocation(true))
  }, [dispatch])

  return (
    <>
      <BottomFab
        loading={loadingMyLocation || loading}
        style={[
          styles.currentLocationButton,
          // portalActive && Platform.OS !== 'web' && { marginBottom: 125 },
        ]}
        align={'left'}
        color={'#565656'}
        icon={'crosshairs-gps'}
        onPress={onPress}
      />
    </>
  )
}
