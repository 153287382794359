import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { Appbar } from 'react-native-paper'

import { connect } from 'react-redux'
import { load } from './actions'
import ProductSummary from './ProductSummary'
import ProductDetails from './ProductDetails'
import ProductDecisions from './ProductDecisions'
import ProductCrops from './Crop/ProductCropList'
import ProductPests from './Pest/ProductPestList'
import ProductBeneficialOrganism from '../BeneficialOrganism/SideEffect/ProductBeneficialOrganism'
import ProductRestrictions from './Restriction/ProductRestrictionList'
import ProductActiveSubstances from './ProductActiveSubstances'
import ProductSafetyCodes from './ProductSafetyCodes'
import Navigation from '../Navigation'
import widthAndHeightHOC from '../WidthAndHeight/widthAndHeightHOC'
import { getItemLabel } from '../Products/helper'
import { getProductData } from './selectors'
import c from '../constants'
import KeyboardAvoidingScrollView from '../WidthAndHeight/KeyboardAvoidingScrollView'
import safeAreaHOC from '../WidthAndHeight/safeAreaHOC'
import Resizer from '../WidthAndHeight/Resizer'

import { Tabs, TabScreen } from 'react-native-paper-tabs'
import AppbarHeader from '../WidthAndHeight/AppbarHeader'
import AppbarResizer from '../WidthAndHeight/AppbarResizer'
import { getLanguage, getTranslatedString } from '../language'
import ComingSoon from './ComingSoon'

const safeSettings = { bottom: true, left: true, right: true }

function FirstTab({ componentId, isBio }) {
  return (
    <KeyboardAvoidingScrollView
      style={styles.fill}
      safeSettings={safeSettings}
      // contentContainerStyle={{ flex: 1 }}
    >
      <View>
        <Resizer>
          <ProductSummary componentId={componentId} />
          <ProductDetails componentId={componentId} isBio={isBio} />
        </Resizer>
      </View>
    </KeyboardAvoidingScrollView>
  )
}

const routes = (lang, isBio) => {
  if (isBio) {
    return [
      {
        key: 'details',
        title: 'general',
        accessibilityLabel: getTranslatedString('general', lang),
        component: FirstTab,
        isBio,
      },
      {
        key: 'packaging',
        title: 'packaging',
        accessibilityLabel: getTranslatedString('packaging', lang),
        component: ComingSoon,
        isBio,
      },
      {
        key: 'pests',
        title: 'toRuleOrganism',
        accessibilityLabel: getTranslatedString('toRuleOrganism', lang),
        component: ProductPests,
        isBio,
      },
      {
        key: 'sideEffect',
        title: 'sideEffect',
        accessibilityLabel: getTranslatedString('sideEffect', lang),
        component: ComingSoon,
      },
      {
        key: 'decisions',
        title: 'decision',
        accessibilityLabel: getTranslatedString('decision', lang),
        component: ProductDecisions,
      },
      {
        key: 'add',
        title: 'add',
        accessibilityLabel: getTranslatedString('add', lang),
        component: ComingSoon,
      },
    ]
  } else {
    return [
      {
        key: 'details',
        title: 'general',
        accessibilityLabel: getTranslatedString('general', lang),
        component: FirstTab,
      },
      {
        key: 'activeSubstances',
        title: 'substance',
        accessibilityLabel: getTranslatedString('substance', lang),
        component: ProductActiveSubstances,
      },
      {
        key: 'crops',
        title: 'crop',
        accessibilityLabel: getTranslatedString('crop', lang),
        component: ProductCrops,
      },
      {
        key: 'pests',
        title: 'pest',
        accessibilityLabel: getTranslatedString('pest', lang),
        component: ProductPests,
      },
      {
        key: 'sideEffect',
        title: 'sideEffect',
        accessibilityLabel: getTranslatedString('sideEffect', lang),
        component: ProductBeneficialOrganism,
      },
      {
        key: 'decisions',
        title: 'decision',
        accessibilityLabel: getTranslatedString('decision', lang),
        component: ProductDecisions,
      },
      {
        key: 'safetyCodes',
        title: 'safety',
        accessibilityLabel: getTranslatedString('safety', lang),
        component: ProductSafetyCodes,
      },
      {
        key: 'restrictions',
        title: 'restriction',
        accessibilityLabel: getTranslatedString('restriction', lang),
        component: ProductRestrictions,
      },
    ]
  }
}

class Product extends Component {
  state = {
    loaded: true,
  }

  // componentDidMount() {
  //   if (isWeb) {
  //     setTimeout(() => {
  //       this.setState({ loaded: true })
  //     }, 0)
  //   }
  // }

  UNSAFE_componentWillMount() {
    const { id } = this.props
    this.props.load(
      { id },
      { cacheFromList: true, fetchOptions: { path: `product/${id}` } }
    )
  }
  _back = () => {
    // do not change this to popToRoot
    // very slow
    Navigation.pop(this.props.componentId)
  }

  render() {
    const { safe, product } = this.props
    const language = getLanguage()

    const isBio =
      product.admissionCategory ===
      'Natuurlijke vijanden en andere biologische bestrijders'
    console.log({ product })
    if (!this.state.loaded) {
      return (
        <>
          <AppbarHeader
            key="header"
            dark
            style={{
              paddingBottom: 56,
              height: safe.top + 56 + 54,
              justifyContent: 'center',
            }}
          >
            <AppbarResizer>
              <Appbar.BackAction
                onPress={this._back}
                color="#fff"
                testID={'back'}
              />
              <Appbar.Content title={this.props.title} color="#fff" />
            </AppbarResizer>
          </AppbarHeader>
          <View style={{ backgroundColor: '#fff', flex: 1 }} />
        </>
      )
    }
    return (
      <>
        <AppbarHeader dark style={{ elevation: 0, justifyContent: 'center' }}>
          <AppbarResizer>
            <Appbar.BackAction
              onPress={this._back}
              color="#fff"
              testID={'back'}
            />
            <Appbar.Content title={this.props.title} color="#fff" />
          </AppbarResizer>
        </AppbarHeader>
        <Tabs
          mode={'scrollable'}
          showLeadingSpace={false}
          uppercase={false}
          style={{ alignItems: 'center' }}
        >
          {routes(language, isBio).map(route => (
            <TabScreen label={route.accessibilityLabel}>
              <View style={{ flex: 1, backgroundColor: '#FFF', width: '100%' }}>
                <route.component
                  key={route.key}
                  componentId={this.props.componentId}
                  jumpTo={this.props.jumpTo}
                />
              </View>
            </TabScreen>
          ))}
        </Tabs>
        <View style={styles.sideFixer} />
      </>
    )
  }
}

const styles = StyleSheet.create({
  fill: {
    flex: 1,
  },
  sideFixer: {
    width: 20,
    position: 'absolute',
    top: 0,
    bottom: 0,
    height: null,
  },
  tabBarContainer: {
    backgroundColor: c.greenColor,
    paddingTop: 0,
    paddingBottom: 0,
    elevation: 4,
    shadowColor: 'black',
    shadowOpacity: 0.1,
    shadowRadius: StyleSheet.hairlineWidth,
    shadowOffset: {
      height: StyleSheet.hairlineWidth,
      width: 0,
    },
  },
  tabBar: {
    backgroundColor: c.greenColor,
    elevation: 0,
    shadowOpacity: 0,
  },
  tabStyle: { width: 'auto' },
  labelStyle: { color: '#FFF' },
  indicatorStyle: { backgroundColor: '#FFF' },
})

const mapStateToProps = state => ({
  title: getItemLabel(getProductData(state)) || '',
  product: getProductData(state),
})
export default safeAreaHOC(
  widthAndHeightHOC(connect(mapStateToProps, { load })(Product))
)
