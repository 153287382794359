import React, { useCallback } from 'react'
import AddFarmerModal from './AddFarmerModal'

function ChooseFarmerModal({ onRequestClose, componentId, isVisible }) {
  // const dispatch = useDispatch()
  const onCreated = useCallback(() => {
    onRequestClose()
    // dispatch(aN.setMessages(['Het bedrijf is succesvol aangemaakt.']))
  }, [onRequestClose])
  const onPressItem = useCallback(() => {
    onRequestClose()
  }, [onRequestClose])

  return (
    <AddFarmerModal
      isVisible={isVisible}
      componentId={componentId}
      onRequestClose={onRequestClose}
      onBack={onRequestClose}
      onCreated={onCreated}
      onPressItem={onPressItem}
    />
  )
}

export default ChooseFarmerModal
