import actions from './actions'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GeneralList from '../GeneralListFunctions/GeneralList'
import {Keyboard, Platform, StyleSheet, View} from 'react-native'
import ItemParent from '../FilterType/ItemParent'
import { useActionsAsFunctions } from '../helpers/actions'
import Resizer from '../WidthAndHeight/Resizer'
import { Appbar, Text } from 'react-native-paper'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import SafeListItem from '../WidthAndHeight/SafeListItem'
import fonts from '../fonts'
import { Translator } from 'react-translated'
import FilterTool from '../FilterType/FilterTool'
import PageHeader from '../PageHeader'
import Navigation, { push } from '../Navigation'
import * as routes from '../Routes.config'
import { usePrevious } from '../Auth/ChangePassword'
import Contact from "../Contact/Contact";

function PestList({ componentId, parentId }) {
  const [parent, setParent] = useState(null)
  const [parentIdInner, setParentId] = useState(parentId)
  const crud = useSelector(state => state.knpv.crud)
  const list = useSelector(state => state.knpv.list)
  const knpvCrop = useSelector(state => state.filter.knpvCrop)
  const dispatch = useDispatch()
  const prevParentId = usePrevious(parentId)
  useEffect(() => {
    if (parentId !== prevParentId) {
      setParentId(parentId)
      if (!parentId) {
        setParent(null)
      }
    }
  }, [parentId, dispatch, prevParentId])
  useEffect(() => {
    if (parentId && !parent) {
      dispatch(actions.load(parentId))
    }
  }, [parentId, parent, dispatch])
  useEffect(() => {
    if (crud?.loaded) {
      setParent(crud?.object)
    }
  }, [crud])

  const back = useCallback(() => {
    Keyboard.dismiss()
    if (Platform.OS === 'web') {
      Navigation.pop(componentId)
    } else {
      setParentId(null)
      setParent(null)
    }
  }, [setParentId, setParent, componentId])

  const renderAboveHeader = useCallback(() => {
    const preferredNameNL = parent?.names?.find(
      name => name?.isPreferred && name?.language === 'nl'
    )?.name
      ? parent?.names?.find(
          name => name?.isPreferred && name?.language === 'nl'
        )?.name
      : parent?.names?.find(
          name => !name?.isPreferred && name?.language === 'nl'
        )?.name

    const preferredNameLA = parent?.names?.find(
      name => name?.isPreferred && name?.language === 'la'
    )?.name
      ? parent?.names?.find(
          name => name?.isPreferred && name?.language === 'la'
        )?.name
      : parent?.names?.find(
          name => !name?.isPreferred && name?.language === 'la'
        )?.name

    return parent ? (
      <ItemParent
        title={
          crud?.loading
            ? ''
            : `${
                preferredNameLA
                  ? preferredNameLA
                  : preferredNameNL
                  ? preferredNameNL
                  : ''
              }${
                preferredNameLA && preferredNameNL
                  ? ` (${preferredNameNL})`
                  : ''
              }`
        }
        onPress={back}
      />
    ) : null
  }, [back, parent, crud])

  const onPress = useCallback(
    // Used for native and web
    item => {
      Keyboard.dismiss()
      if (item?.numberChildren === 0 || !item?.numberChildren) {
        push(componentId, {
          component: {
            name: routes.KNPV_DETAIL_ROUTE,
            passProps: {
              id: item.id,
            },
          },
        })
        return
      }
      setParentId(item.id)
      setParent(item)
    },
    [componentId]
  )

  const extraParams = useMemo(() => {
    console.log({ parentIdInner, parentId, knpvCrop })
    return {
      id: parentIdInner || parentId || null,
      listId: 'f0da5f5b-97da-4b1a-7379-5d81b917e085', // KNPV Pest list id
      types: ['INFESTATION'],
      sort: 'name',
      cropIds:
        [knpvCrop?.selected?.[0]?.id].filter(n => n).length > 0
          ? [knpvCrop?.selected?.[0]?.id]
          : null,
    }
  }, [parentIdInner, parentId, knpvCrop])

  const renderItem = ({ item, index }) => {
    const preferredNameNL = item?.names?.find(
      name => name?.language === 'nl' && name?.isPreferred
    )
    const preferredNameLA = item?.names?.find(
      name => name?.language === 'la' && name?.isPreferred
    )
    return (
      <Resizer>
        <SafeListItem
          title={preferredNameNL?.name || preferredNameLA?.name || item?.name}
          description={preferredNameLA?.name || item?.latinName}
          onPress={() => {
            if (Platform.OS !== 'web') {
              // We only push on native, because on web we use "to". This is because "to" has extra hover effects on web.
              onPress(item)
            }
          }}
          to={
            !(item?.numberChildren === 0 || !item?.numberChildren)
              ? `/knpv-list/${item.id}`
              : `/knpv/${item.id}`
          }
          style={{ flex: 1, zIndex: 1999 }}
          right={
            item?.numberChildren > 0 && (
              <View style={styles.rightContainer}>
                <Text style={styles.rightText}>{item.numberChildren}</Text>
                <Icon name={'chevron-right'} size={24} />
              </View>
            )
          }
        />
      </Resizer>
    )
  }
  const renderStickyBelowHeader = () => {
    return (
      <FilterTool
        key="filter-tool"
        componentId={componentId}
        context={'knpv'}
      />
    )
  }
  const actionsAsFunctions = useActionsAsFunctions(actions)
  return (
    <Translator>
      {({ translate }) => (
        <GeneralList
          {...actionsAsFunctions}
          list={list}
          // selected={selected}
          searchPlaceholder={translate({ text: 'knpvSearchPest' })}
          renderAboveHeader={renderAboveHeader}
          renderItem={renderItem}
          appbarContent={
            <PageHeader
              back={parentId || parentIdInner ? back : null}
              componentId={componentId}
              title={
                <Appbar.Content
                  title={translate({ text: 'knpvPests' })}
                  titleStyle={{
                    textAlign: 'center',
                  }}
                  subtitleStyle={{
                    textAlign: 'center',
                  }}
                />
              }
            />
          }
          extraParams={extraParams}
          icon
          renderStickyBelowHeader={renderStickyBelowHeader}
          sort={'name'}
          renderBelowFooter={() => <Contact problem/>}
          renderEmptyState={() => <Contact missing/>}
          disableRenderBelowFooterWhenEmpty
          // appbarContent={
          // <FilterHeader filterType={filterType} componentId={componentId} />
          //}
        />
      )}
    </Translator>
  )
}

const styles = StyleSheet.create({
  leftContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 5,
  },
  rightContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 13,
  },
  rightText: {
    paddingRight: 6,
    ...fonts.medium,
  },
})
export default PestList
