import React, { PureComponent } from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import Agro4allLogo from '../vectors/Agro4allLogo'

const styles = StyleSheet.create({
  root: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    // marginLeft: 13, ONLY ENABLE ON THE PLATFORM WHICH IS IS BUGGY
    // DO NO ENABLE MARGIN ON iOS
  },
})

class HeaderLogo extends PureComponent {
  render() {
    return (
      <View
        source={
          Platform.OS === 'web'
            ? require('../../img/webp/header_background.webp')
            : require('../../img/header_background.png')
        }
        style={styles.root}
      >
        <Agro4allLogo withoutOnPressLogo={this.props.withoutOnPressLogo} />
      </View>
    )
  }
}

export default HeaderLogo
