import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'
import { Text } from 'react-native-paper'
import PropTypes from 'prop-types'
import Resizer from '../WidthAndHeight/Resizer'

const styles = StyleSheet.create({
  container: {
    padding: 15,
    margin: 0,
    borderRadius: 10,
    paddingLeft: 25,
    paddingRight: 25,
    backgroundColor: 'red',
  },
  text: { color: 'white' },
})

function isString(obj) {
  return Object.prototype.toString.call(obj) === '[object String]'
}
const errToString = (pError) => {
  let error = ''

  if (pError === undefined) {
    return 'Onbekende fout'
  }
  if (isString(pError)) {
    error = pError
  }
  if (pError && pError.message && isString(pError.message)) {
    error = pError.message
  }

  if (error === 'Network request failed') {
    console.log(error)
    return 'Geen internetverbinding'
  }

  return isString(error) ? error : 'Onbekende fout'
}

class ErrorMessages extends Component {
  render() {
    const messages = this.props.messages || []
    if (messages.length === 0) {
      return null
    }

    return (
      <Resizer>
        <View style={styles.container}>
          {(messages || []).map((error, i) => (
            <Text style={styles.text} key={i}>
              {errToString(error)}
            </Text>
          ))}
        </View>
      </Resizer>
    )
  }
}
ErrorMessages.propTypes = {
  messages: PropTypes.array,
}
ErrorMessages.defaultProps = {
  messages: [],
}

export default ErrorMessages
