import * as a from './actions'
import { combineReducers } from 'redux'

const selectedGroupedCrop = (state = null, { type, payload }) => {
  switch (type) {
    case a.SELECT_GROUPED_CROP:
      return payload

    default:
      return state
  }
}

const isSelected = (selected, item) => selected.some((it) => it.id === item.id)

const selectedFieldsWithGrowingPeriod = (state = [], { type, payload }) => {
  switch (type) {
    case a.SELECT_FIELD_WITH_GROWING_PERIOD:
      return isSelected(state, payload)
        ? state.filter((it) => it.id !== payload.id)
        : [...state, payload]

    default:
      return state
  }
}
const maker = (
  state = {
    creating: false,
    creatingError: false,
    created: false,
  },
  { type, error }
) => {
  switch (type) {
    case a.SELECT_FIELD_WITH_GROWING_PERIOD:
      return {
        creating: false,
        creatingError: false,
        created: false,
      }
    case a.CREATE:
      return {
        creating: true,
        creatingError: false,
        created: false,
      }
    case a.CREATED:
      return {
        creating: false,
        creatingError: error,
        created: error ? false : true,
      }
    default:
      return state
  }
}

export default combineReducers({
  maker,
  selectedGroupedCrop,
  selectedFieldsWithGrowingPeriod,
})
