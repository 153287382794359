import React, {  useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import actions from './actions'
import { useActionsAsFunctions } from '../../helpers/actions'
import GeneralList from '../../GeneralListFunctions/GeneralList'
import ItemInList from '../ItemInList'
import FilterHeader from '../FilterHeader'
import Navigation from '../../Navigation'
import { FilterType, mapIds } from '../config'
import { AdmissionHolderType } from './reducer'
import { RootState } from '../../reducers'
import { usePrevious } from '../../Auth/ChangePassword'

export default function AdmissionHolderList({
  componentId,
  filterType,
}: {
  componentId: string
  filterType: FilterType
}) {
  const selected = useSelector(
    (state: RootState) => state.filter.admissionHolder.selected
  )
  const list = useSelector(
    (state: RootState) => state.filter.admissionHolder.list
  )
  const admissionCategories = useSelector(
    (state: RootState) => state.filter.admissionCategory.selected
  )
  const expirationDateFrom = useSelector(
    (state: RootState) => state.filter.switches.expirationDate
  )
  const previousExpirationDateFrom = usePrevious(expirationDateFrom)
  const pop = () => {
    Navigation.pop(componentId)
  }
  const [extraParams, setExtraParams]:any = useState({admissionCategories: mapIds(admissionCategories)})

  const actionAsDispatchers = useActionsAsFunctions(actions)

  useEffect(() => {
    if (expirationDateFrom !== previousExpirationDateFrom)  {
      setExtraParams({
        ...extraParams,
        expirationDateFrom: expirationDateFrom ? null : Math.floor(
          // @ts-ignore
          new Date()
        )
      })
    }
  },[expirationDateFrom,previousExpirationDateFrom,extraParams])

  const renderItem = ({ item }: { item: AdmissionHolderType }) => {
    return (
      <ItemInList
        onPress={actionAsDispatchers.select}
        onSelect={actionAsDispatchers.select}
        item={item}
        filterType={filterType}
        isSelected={selected.some((it) => it.id === item.id)}
        selectType="checkbox"
      />
    )
  }

  return (
    <GeneralList<AdmissionHolderType>
      list={list}
      selected={selected}
      componentId={componentId}
      searchPlaceholder={filterType.searchPlaceholder}
      renderItem={renderItem}
      icon
      appbarContent={
        <FilterHeader filterType={filterType} componentId={componentId} />
      }
      onPressFab={pop}
      selectAll
      {...actionAsDispatchers}
      extraParams={extraParams}
    />
  )
}
