import React, { PureComponent } from 'react'
import PT from 'prop-types'
import { View } from 'react-native'
import { connect } from 'react-redux'
import LabelRow from './LabelRow'
import { processProduct } from './config'
import widthAndHeightHOC from '../WidthAndHeight/widthAndHeightHOC'
import { getProductData } from './selectors'
import { Translate } from 'react-translated'
import { getLanguage } from '../language'

class ProductDetails extends PureComponent {
  render() {
    const { attributes, isMedium } = this.props
    return (
      <View style={[isMedium && { flexDirection: 'row', flexWrap: 'wrap' }]}>
        {attributes.map(({ label, value, hasValue }, index) => (
          <View style={[isMedium && { width: '50%' }]} key={index}>
            <LabelRow
              key={index}
              enableBorderTop={
                isMedium ? index !== 0 && index !== 1 : index !== 0
              }
              label={<Translate text={label} />}
              value={hasValue ? value : '-'}
            />
          </View>
        ))}
      </View>
    )
  }
}

ProductDetails.propTypes = {
  attributes: PT.arrayOf(
    PT.shape({
      label: PT.node.isRequired,
      value: PT.string.isRequired,
      hasValue: PT.bool.isRequired,
    })
  ).isRequired,
}

const mapStateToProps = state => {
  const language = getLanguage()
  const isBio =
    getProductData(state).admissionCategory ===
    'Natuurlijke vijanden en andere biologische bestrijders'
  return {
    attributes: processProduct(getProductData(state), language, isBio),
  }
}

export default widthAndHeightHOC(connect(mapStateToProps)(ProductDetails))
