import api from 'react-api'

export const download = async ({ url, fileName, params, token }) => {
  //   console.log(' Download.web.js')
  try {
    const response = await api({
      path: url,
      params,
      method: 'GET',
      type: 'blob',
      token,
    })

    const wUrl = window.URL.createObjectURL(response)
    const link = document.createElement('a')
    link.href = wUrl
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
  } catch (error) {
    alert('Kon bestand niet downloaden')
    console.log('Kon bestand niet downloaden', { error })
  }
}
