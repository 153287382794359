import actions from './actions'
import reducerFactory from '../../GeneralListFunctions/reducerFactory'

interface Product {
  id: string
  name: string
}

const reducer = reducerFactory<Product>(actions)
export default reducer
