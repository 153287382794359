import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import actions from './actions'
import matchActions from '../actions'
import GeneralList from '../../../GeneralListFunctions/GeneralList'
import ItemInTree from '../../../FilterType/ItemInTree'
import ItemParent from '../../../FilterType/ItemParent'
import FilterHeader from '../../../FilterType/FilterHeader'
import { useActionsAsFunctions } from '../../../helpers/actions'

function PestListScreen({
  filterType,
  parentId,
  extraParams,
  matchRoute,
  onSelect,
  isSelected,
  componentId,
  onBack,
  editable,
}) {
  const dispatch = useDispatch()
  const list = useSelector(state => state.pest.list)
  const tree = useSelector(state => state.pest.list.tree)
  const matchList = useSelector(state => state.product.pest.list)
  const matchTree = useSelector(state => state.product.pest.list.tree)
  const goUp = () => {
    if (matchRoute) {
      load(
        (matchTree[0]?.parent && matchTree[0].parent.id
          ? matchTree[0].parent.id
          : matchTree[0]?.parentId
          ? matchTree[0].parentId
          : matchTree[0]?.parent_id) || null
      )
    } else {
      load(
        (tree[0]?.parent && tree[0].parent.id
          ? tree[0].parent.id
          : tree[0]?.parentId
          ? tree[0].parentId
          : tree[0]?.parent_id) || null
      )
    }
  }

  const renderAboveHeader = () => {
    const parent = matchRoute ? matchTree && matchTree[0] : tree && tree[0]
    if (parent) {
      return (
        <ItemParent
          title={filterType.getItemLabel(parent)}
          description={filterType.getItemSubLabel(parent)}
          onPress={goUp}
        />
      )
    }
    return null
  }

  const getParams = (ignore, inputSearch, overrideId) => {
    let search = inputSearch
    if (search === undefined) {
      search = matchRoute
        ? matchList.params && matchList.params.search
        : list.params && list.params.search
    }

    let withId = overrideId === undefined
    console.log(overrideId)
    let params = {
      ...(matchRoute ? matchList.params : list.params),
      sort: 'name',
      search,
      [`search[name]`]: search,
      name: search,
      id: withId ? parentId : overrideId,
      ...(extraParams || {}),
    }

    return params
  }
  const load = itemId => {
    console.log(itemId)
    if (matchRoute) {
      dispatch(
        matchActions.loadList({
          params: getParams(undefined, '', itemId),
        })
      )
    } else {
      dispatch(
        actions.loadList({
          params: getParams(undefined, '', itemId),
        })
      )
    }
  }
  const press = item => {
    load(item.id)
  }
  const select = item => {
    onSelect(item)
  }

  const renderItem = ({ item }) => {
    const innerIsSelected = isSelected(item)
    return (
      <ItemInTree
        onSelect={select}
        onPress={press}
        item={item}
        filterType={filterType}
        isSelected={innerIsSelected}
      />
    )
  }
  const actionsAsFunction = useActionsAsFunctions(actions)
  const matchActionsAsFunction = useActionsAsFunctions(matchActions)
  return matchRoute ? (
    <GeneralList
      {...matchActionsAsFunction}
      list={matchList}
      tree={matchTree}
      searchPlaceholder={filterType.searchPlaceholder}
      renderAboveHeader={renderAboveHeader}
      renderItem={renderItem}
      loadListOnMount={editable}
      icon
      appbarContent={
        <FilterHeader
          onBack={onBack}
          filterType={filterType}
          componentId={componentId}
        />
      }
      getParams={getParams}
    />
  ) : (
    <GeneralList
      {...actionsAsFunction}
      list={list}
      tree={tree}
      searchPlaceholder={filterType.searchPlaceholder}
      renderAboveHeader={renderAboveHeader}
      renderItem={renderItem}
      loadListOnMount={editable}
      icon
      appbarContent={
        <FilterHeader
          onBack={onBack}
          filterType={filterType}
          componentId={componentId}
        />
      }
      getParams={getParams}
    />
  )
}

export default PestListScreen
