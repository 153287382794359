import * as React from 'react'
import { Keyboard, StyleSheet, TouchableOpacity } from 'react-native'
import { useDispatch } from 'react-redux'
import { setMessages } from '../Notifications/actions'

import { pushCustomEvent } from '../Analytics/abstraction'
import { getItemLabel } from './helper'
import GroundwaterProtectionIcon from '../vectors/GroundwaterProtection'
import SurfaceWaterIcon from '../vectors/SurfaceWater'

interface ConditionText {
  text: string
  status: 'unknown' | 'false' | 'true'
}

interface RestrictionGeneratedType {
  restrictionValues: string[] | null
  conditionsValues: ConditionText[] | null
}

type ConditionValueTypeType =
  | 'undefined'
  | 'dosage'
  | 'amount_product'
  | 'date'
  | 'bufferZone'
  | 'parcel_close_to_water'
  | 'ground_type'
  | 'sprayer_type'
  | 'spray_pressure'
  | 'height_nozzles'
  | 'bbch_scale'
  | 'driving_speed'
  | 'wind_speed'
  | 'spray_direction'
  | 'nozzle_distance'
  | 'region'
  | 'attractive_to_bees'
  | 'groundwater_protection_area'
  | 'spray_product'
  | 'air_assisting'
  | 'distance_to_ditch'

type ConditionType = {
  valueType: ConditionValueTypeType
}
type GroupedStatusType = 'unknown' | 'false' | 'true'

export interface RestrictionType {
  generated?: RestrictionGeneratedType
  conditions: ConditionType[]
  groupedStatus: GroupedStatusType
  productForbidden: boolean
  originalSentence: string
}

export interface RestrictionSummaryType {
  maxDriftReductionCap: number
  maxDriftReductionTechnique: number
  restrictions?: RestrictionType[]
}

// TODO we need another approach to the modal where we can push restrictions or something

const ItemNoteIcons = ({
  restrictionSummary,
  product,
  id,
}: {
  restrictionSummary: RestrictionSummaryType
  product: any
  id: string
}) => {
  const dispatch = useDispatch()
  const restrictionList = restrictionSummary?.restrictions || []

  const onPress = (type: string) => {
    Keyboard.dismiss()
    const itemString = restrictionList.find(r =>
      r.conditions.some(c => c.valueType === type)
    )
    dispatch(setMessages([itemString!.originalSentence]))
    if (product) {
      pushCustomEvent(
        'Pressed restrictions icon on product:',
        `${getItemLabel(product)}`
      )
    } else {
      pushCustomEvent(
        'Pressed restrictions icon on crop page of product detail'
      )
    }
  }

  const hasGroundWater = restrictionList.some(r =>
    r.conditions.some(c => c.valueType === 'groundwater_protection_area')
  )
  const hasParcelCloseToWater = restrictionList.some(r =>
    r.conditions.some(c => c.valueType === 'parcel_close_to_water')
  )

  let fullIconMap: any[] = []
  if (hasGroundWater) {
    fullIconMap = [
      ...fullIconMap,
      <TouchableOpacity
        style={styles.icon}
        onPress={() => onPress('groundwater_protection_area')}
      >
        <GroundwaterProtectionIcon color={'blue'} />
      </TouchableOpacity>,
    ]
  }
  if (hasParcelCloseToWater) {
    fullIconMap = [
      ...fullIconMap,
      <TouchableOpacity
        style={styles.icon}
        onPress={() => onPress('parcel_close_to_water') }
      >
        <SurfaceWaterIcon color={'blue'} />
      </TouchableOpacity>,
    ]
  }
  return fullIconMap
}

const styles = StyleSheet.create({
  icon: {
    width: 24,
    height: 24,
    margin: 12,
  },
})
export default ItemNoteIcons
