import React, {useState} from 'react'
import {Linking, StyleSheet, View} from "react-native";
import {Button, Card, Title, Paragraph, Portal, Dialog} from 'react-native-paper';
import Clipboard from '@react-native-clipboard/clipboard'
import {getLanguage, getTranslatedString} from "../language";
// @ts-ignore
import {Translate} from 'react-translated';
import constants from "../constants";

// NOTE: keep text aligned to the left, otherwise the spaces will appear in the email!

const emailTemplateNL = (emailText: string) => `Beste Agro4All medewerker,

${emailText}

Met vriendelijke groet,
`

const emailTemplateEN = (emailText: string) => `Dear Agro4All employee,

${emailText}

Kind regards,
`

function Contact({
  missing,
  problem,
  relatedTo,
  customTitle,
  customDescription,
  customActionText,
  customEmailSubject,
  customEmailText,
  customImage
} : {
  missing?: boolean,
  problem?: boolean,
  relatedTo?: string,
  customTitle?: string,
  customDescription?: string,
  customActionText?: string,
  customEmailSubject?: string,
  customEmailText?: string,
  customImage?: any
}) {
  const [error, setError] = useState(false)
  const onDismiss = () => setError(false)

  const lang = getLanguage()

  const title = customTitle ? customTitle :
    (missing ? <Translate text={'missingItem'} /> :
      (problem ? <Translate text={'problem'} /> : null)
    )

  const description = customDescription ? customDescription :
    (missing ? <Translate text={'missingItemDescription'} /> :
        (problem ? <Translate text={'problemDescription'} /> : null)
    )

  const actionText = customActionText ? customActionText :
    (missing ? <Translate text={'reportMissingItem'} /> :
        (problem ? <Translate text={'reportProblem'} /> : null)
    )

  // Don't use <Translate /> for email, as it will appear as [object Object]

  const emailSubject = customEmailSubject ? customEmailSubject :
    (missing ? getTranslatedString('emailSubjectMissingItem', lang) :
        (problem ? getTranslatedString('emailSubjectProblem', lang) : null)
    )

  const emailRelatedToText = relatedTo ? `${getTranslatedString('emailIssueRelatedTo', lang)} ${relatedTo}\n\n` : null

  const emailText = (emailRelatedToText ? emailRelatedToText : '').concat(
      customEmailText ? customEmailText :
    (missing ? getTranslatedString('emailTextMissingItem', lang) :
        (problem ? getTranslatedString('emailTextProblem', lang) : null)
    ))

  // TODO: Add images
  const image = customImage ? customImage :
    (missing ? null :
        (problem ? null : null)
    )

  const emailTemplate = (lang === 'nl' ?
          emailTemplateNL(emailText) :
          emailTemplateEN(emailText)
  )

  const onPress = () => {
    const emailTemplateAsURL = emailTemplate.replace(/(\n)/g, '%0D%0A')
    const url = `mailto:${constants.dataIssueEmailAddress}?subject=${emailSubject}&body=${emailTemplateAsURL}`
    Linking.openURL(url).catch(() => {
      setError(true)
    })
  }

  const copyEmailAddress = () => {
    Clipboard.setString(constants.dataIssueEmailAddress)
  }

  const copyEmailTemplate = () => {
    Clipboard.setString(emailTemplate);
  }

  return (
    <View style={styles.container}>
      <Card style={styles.card}>
        {image && (
          // @ts-ignore
          <Card.Cover source={image} />
        )}
        <Card.Content>
          {title && (
            <Title style={styles.title}>{title}</Title>
          )}
          {description && (
            <Paragraph>{description}</Paragraph>)
          }
        </Card.Content>
        {actionText && (
          <Card.Actions style={styles.actions}>
            <Button mode={'outlined'} style={styles.button} onPress={onPress}>
              {actionText}
            </Button>
          </Card.Actions>
        )}
      </Card>
      {
        error && (
          <Portal>
            <Dialog visible={error} onDismiss={onDismiss} style={styles.dialog}>
              <Dialog.Content>
                <Paragraph><Translate text={'emailError'}/></Paragraph>
                <Paragraph
                    style={{ color: 'blue', }}
                    selectable={true}
                    onPress={copyEmailAddress}
                    onLongPress={copyEmailAddress}
                >
                  {constants.dataIssueEmailAddress}
                </Paragraph>
                <Paragraph
                    selectable={true}
                    style={styles.email}
                    onPress={copyEmailTemplate}
                    onLongPress={copyEmailTemplate}
                >
                  {emailTemplate}
                </Paragraph>
              </Dialog.Content>
              <Dialog.Actions style={styles.actionButtons}>
                <Button onPress={copyEmailTemplate}><Translate text={'copyEmailTemplate'}/></Button>
                <Button onPress={onDismiss}><Translate text={'done'}/></Button>
              </Dialog.Actions>
            </Dialog>
          </Portal>
        )
      }
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 50,
    paddingBottom: 2,
  },
  card: {
    width: '100%',
    maxWidth: 600,
  },
  title: {
    marginTop: 10,
    fontWeight: 'bold',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  dialog: {
    paddingBottom: 5,
  },
  email: {
    padding: 15,
  },
  actionButtons: {
    paddingRight: 15,
  },
  button: {
    width: '100%',
  },
})

export default Contact
