import React, { Component } from 'react'

import { TouchableRipple, Appbar } from 'react-native-paper'
import Resizer from '../WidthAndHeight/Resizer'

class ItemParent extends Component {
  render() {
    const { onPress, title, description } = this.props
    return (
      <Resizer>
        <TouchableRipple key="title" onPress={onPress}>
          <Appbar
            dark={false}
            style={{
              backgroundColor: 'transparent',
              elevation: 0,
              paddingTop: 0,
              top: 0,
            }}
            pointerEvents="none"
          >
            <Appbar.Action icon={'chevron-up'} />
            <Appbar.Content
              title={title}
              description={description}
              style={{ alignItems: 'flex-start' }}
            />
          </Appbar>
        </TouchableRipple>
      </Resizer>
    )
  }
}

ItemParent.propTypes = {}
ItemParent.defaultProps = {}

export default ItemParent
