import { combineReducers } from 'redux'

import actions from './actions'
import reducer from '../GeneralListFunctions/reducerFactory'
import extra from './New/reducer'
import wizard from './Wizard/reducer'

interface AdviceType {
  id: string
  // TODO: add all fields here
}

const listAndCrud = reducer<AdviceType>(actions)
export default combineReducers({
  extra,
  listAndCrud,
  wizard,
})
