const ns = (type: string) => `AQUATIC_BUFFERZONE_${type}`

export const CHANGE = ns('CHANGE')
export const change = (bufferZone: {
  width?: number | null
  present?: boolean | null
}) => ({
  type: CHANGE,
  payload: bufferZone,
})

export const REMOVE = ns('REMOVE')
export const remove = () => ({
  type: REMOVE,
})

export const RESET = ns('RESET')
export const reset = () => ({
  type: RESET,
})
