import { all, call } from 'redux-saga/effects'

import productDistribution from './product/saga'
import distribution, { selectDistributedProductList } from './distribution/saga'

export default function* rootSaga() {
  yield all([
    call(productDistribution),
    call(distribution),
    call(selectDistributedProductList),
  ])
}
