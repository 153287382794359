import React from 'react'
import { StyleSheet, View } from 'react-native'
import MatchStatus from '../../Products/MatchStatus'
import ItemNoteIcons from '../../Products/ItemNoteIcons'
import Resizer from '../../WidthAndHeight/Resizer'
import SafeListItem from '../../WidthAndHeight/SafeListItem'

const styles = StyleSheet.create({
  rightContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

function ProductCropItem({ item }) {
  return (
    <Resizer>
      <SafeListItem
        title={getItemLabel(item)}
        description={item.latinName}
        right={
          <View style={styles.rightContainer}>
            <MatchStatus status={item.matchType} match={item} />
            <ItemNoteIcons
              notes={item.notes}
              restrictionSummary={item.restrictionSummary}
            />
          </View>
        }
      />
    </Resizer>
  )
}

const getItemLabel = item => {
  let itemName = item.name.trim()
  if (item.attributes && item.attributes.length > 0) {
    if (item.exceptedAttributes && item.exceptedAttributes.length > 0) {
      itemName = `${itemName} (${item.attributes
        .map(attribute => attribute.name.toLowerCase())
        .join(', ')} m.u.v. ${item.exceptedAttributes
        .map(attribute => attribute.name.toLowerCase())
        .join(', ')})`
    } else {
      itemName = `${itemName} (${item.attributes
        .map(attribute => attribute.name.toLowerCase())
        .join(', ')})`
    }
  }

  if (item.except_crops && item.except_crops.length > 0) {
    itemName = `${itemName} m.u.v. `

    item.except_crops.forEach(exceptCrop => {
      itemName += `${exceptCrop.name.toLowerCase()}`
      if (exceptCrop.attributes && exceptCrop.attributes.length > 0) {
        itemName += ` (${exceptCrop.attributes
          .map(attribute => attribute.name.toLowerCase())
          .join(', ')}), `
      } else {
        itemName += ', '
      }
    })
  }

  return itemName.replace(/,\s*$/, '')
}
export default ProductCropItem
