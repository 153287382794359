import React, { useCallback, useEffect } from 'react'
import { View } from 'react-native'
import { filterTypesSorted } from '../../FilterType/config'
import SingleSelect from '../../Product/Pest/SingleSelect'
import BeneficialOrganismSingleSelect from '../../BeneficialOrganism/SingleSelect'
import {
  Button,
  Divider,
  RadioButton,
  Text,
  TextInput,
  Title,
} from 'react-native-paper'
import Box from '../../components/Box'
import c from '../../constants'
// @ts-ignore
import { Translator } from 'react-translated'
import { getLanguage } from '../../language'

function Sample({
  sample,
  onChange,
  onRemove,
  index,
  cropId,
  type,
}: {
  sample: any
  index: number
  onChange: (key: string, value: any, index: number) => any
  onRemove: (index: number) => any
  cropId?: string
  type: 'beneficialOrganism' | 'pest'
}) {
  const [innerSelected, setInnerSelected] = React.useState(
    sample &&
      sample.location &&
      sample.location.floorplan &&
      sample.location.floorplan.range &&
      (sample.location.floorplan.range.start ||
        sample.location.floorplan.range.end)
      ? 1
      : 0
  )
  const change = useCallback(
    (key: any, value: any) => {
      onChange(key, value, index)
    },
    [index, onChange]
  )
  const filterType = filterTypesSorted.find(it => it.id === 'pest')
  const filterTypeBeneficialOrganism = filterTypesSorted.find(
    it => it.id === 'beneficialOrganism'
  )

  const [intensity, setIntensity] = React.useState(
    sample?.density?.intensity || ''
  )
  console.log(sample)
  const [stage, setStage] = React.useState(
    sample?.beneficialOrganism?.stage || ''
  )
  const [balanced, setBalanced] = React.useState(
    `${sample?.beneficialOrganism?.balanced}` || ''
  )

  useEffect(() => {
    if (sample?.density?.intensity) {
      setIntensity(sample.density.intensity.toLowerCase())
    }
  }, [sample])

  const selectIntensity = (value: string) => {
    onChange('density', { ...sample.density, intensity: value }, index)
    setIntensity(value)
  }
  const selectStage = (value: string) => {
    onChange(
      'beneficialOrganism',
      { ...sample.beneficialOrganism, stage: value },
      index
    )
    setStage(value)
  }
  const selectBalanced = (value: string) => {
    onChange(
      'beneficialOrganism',
      { ...sample.beneficialOrganism, balanced: !!(value === 'true') },
      index
    )
    setBalanced(value)
  }

  const [section, setSection] = React.useState(
    sample?.location?.floorplan?.section || ''
  )

  const selectSection = (value: string) => {
    onChange(
      'location',
      {
        ...sample.location,
        floorplan: { ...(sample?.location?.floorplan || {}), section: value },
      },
      index
    )
    setSection(value)
  }
  const changePost = (value: string) => {
    onChange(
      'location',
      {
        ...sample.location,
        floorplan: {
          ...(sample?.location?.floorplan || {}),
          post: value,
          range: undefined,
        },
      },
      index
    )
  }
  const changeFromToPost = (key: string, value: string) => {
    onChange(
      'location',
      {
        ...sample.location,
        floorplan: {
          ...(sample?.location?.floorplan || {}),
          range: {
            ...(sample?.location?.floorplan?.range || {}),
            [key]: value,
          },
          post: undefined,
        },
      },
      index
    )
  }

  const changeRow = (value: string) => {
    onChange(
      'location',
      {
        ...sample.location,
        floorplan: { ...(sample?.location?.floorplan || {}), row: value },
      },
      index
    )
  }

  const changeCount = (value: string) => {
    onChange(
      'density',
      { ...sample.density, count: Number(value.replace(/[^0-9]/g, '')) },
      index
    )
  }

  const changeUnit = (value: string) => {
    onChange('density', { ...sample.density, unit: value }, index)
  }
  const language = getLanguage()
  return (
    <Translator>
      {({
        translate,
      }: {
        translate: ({ text }: { text: string }) => string
      }) => (
        <Box
          key={index}
          smallPadding
          style={{ flex: 1 }}
          expandable
          onExpand={() => {
            change('isOpen', false)
          }}
          removeAble
          removeAction={() => onRemove(index)}
          expanded={sample?.isOpen}
          title={`${
            !(sample?.beneficialOrganism?.name || sample?.pest?.name)
              ? sample.type === 'pest'
                ? `${translate({ text: 'item' })} ${index + 1}`
                : `${translate({ text: 'item' })} ${index + 1}`
              : ''
          }${
            type === 'beneficialOrganism'
              ? sample?.beneficialOrganism?.name
                ? `${
                    language === 'nl'
                      ? sample?.beneficialOrganism?.name?.nl &&
                        sample?.beneficialOrganism?.name?.la
                        ? `${sample?.beneficialOrganism?.name?.nl} (${sample?.beneficialOrganism?.name?.la})`
                        : sample?.beneficialOrganism?.name?.nl
                        ? sample?.beneficialOrganism?.name?.nl
                        : sample?.beneficialOrganism?.name?.la
                        ? sample?.beneficialOrganism?.name?.la
                        : ''
                      : sample?.beneficialOrganism?.name?.en &&
                        sample?.beneficialOrganism?.name?.la
                      ? `${sample?.beneficialOrganism?.name?.en} (${sample?.beneficialOrganism?.name?.la})`
                      : sample?.beneficialOrganism?.name?.en
                      ? sample?.beneficialOrganism?.name?.en
                      : sample?.beneficialOrganism?.name?.la
                      ? sample?.beneficialOrganism?.name?.la
                      : ''
                  }${
                    intensity &&
                    intensity !== '' &&
                    (intensity.toLowerCase() === 'low'
                      ? ` | ${translate({
                          text: 'intensitivity',
                        })}: ${translate({ text: 'low' })}`
                      : intensity.toLowerCase() === 'medium'
                      ? ` | ${translate({
                          text: 'intensitivity',
                        })}: ${translate({ text: 'medium' })}`
                      : intensity.toLowerCase() === 'high'
                      ? ` | ${translate({
                          text: 'intensitivity',
                        })}: ${translate({ text: 'high' })}`
                      : '')
                  }${
                    sample?.location?.floorplan?.row
                      ? ` | ${translate({ text: 'row' })} ${
                          sample?.location?.floorplan?.row
                        }`
                      : ''
                  }${
                    sample?.location?.floorplan &&
                    (sample?.location?.floorplan?.post ||
                      sample?.location?.floorplan?.range?.start ||
                      sample?.location?.floorplan?.range?.end)
                      ? sample?.location?.floorplan?.post
                        ? `${
                            !sample?.location?.floorplan?.row ? ' |' : ''
                          } ${translate({ text: 'pole' })} ${
                            sample?.location?.floorplan?.post
                          }`
                        : sample?.location?.floorplan?.range?.start
                        ? `${
                            !sample?.location?.floorplan?.row ? ' |' : ''
                          } ${translate({ text: 'pole' })} ${
                            sample?.location?.floorplan?.range?.start
                          }${
                            sample?.location?.floorplan?.range?.end
                              ? `-${sample?.location?.floorplan?.range?.end}`
                              : ''
                          }`
                        : ''
                      : ''
                  }`
                : ''
              : sample?.pest?.name
              ? `${sample?.pest?.name}${
                  intensity &&
                  intensity !== '' &&
                  (intensity.toLowerCase() === 'low'
                    ? ` | ${translate({
                        text: 'intensitivity',
                      })}: ${translate({ text: 'low' })}`
                    : intensity.toLowerCase() === 'medium'
                    ? ` | ${translate({
                        text: 'intensitivity',
                      })}: ${translate({ text: 'medium' })}`
                    : intensity.toLowerCase() === 'high'
                    ? ` | ${translate({
                        text: 'intensitivity',
                      })}: ${translate({ text: 'high' })}`
                    : '')
                }${
                  sample?.location?.floorplan?.row
                    ? ` | ${translate({ text: 'row' })} ${
                        sample?.location?.floorplan?.row
                      }`
                    : ''
                }${
                  sample?.location?.floorplan &&
                  (sample?.location?.floorplan?.post ||
                    sample?.location?.floorplan?.range?.start ||
                    sample?.location?.floorplan?.range?.end)
                    ? sample?.location?.floorplan?.post
                      ? `${
                          !sample?.location?.floorplan?.row ? ' |' : ''
                        } ${translate({ text: 'pole' })} ${
                          sample?.location?.floorplan?.post
                        }`
                      : sample?.location?.floorplan?.range?.start
                      ? `${
                          !sample?.location?.floorplan?.row ? ' |' : ''
                        } ${translate({ text: 'pole' })} ${
                          sample?.location?.floorplan?.range?.start
                        }${
                          sample?.location?.floorplan?.range?.end
                            ? `-${sample?.location?.floorplan?.range?.end}`
                            : ''
                        }`
                      : ''
                    : ''
                }`
              : ''
          }`}
        >
          {type === 'pest' ? (
            <View key={sample?.pest?.id}>
              <SingleSelect
                value={sample?.pest}
                filterType={filterType}
                onSelect={(value: any) => change('pest', value)}
                extraParams={{ crop: cropId }}
                matchRoute
              />
              <Divider />
              <View style={{ paddingLeft: 16, paddingBottom: 8, marginTop: 8 }}>
                <Title style={{ fontSize: 16 }}>
                  {translate({ text: 'intensitivity' })}
                </Title>
                <View style={{ flexDirection: 'row' }}>
                  <RadioButton.Android
                    onPress={() => selectIntensity('LOW')}
                    value={'LOW'}
                    status={
                      intensity.toLowerCase() === 'low'
                        ? 'checked'
                        : 'unchecked'
                    }
                  />
                  <Text
                    onPressIn={undefined}
                    onPressOut={undefined}
                    android_hyphenationFrequency={undefined}
                    style={{ marginTop: 10 }}
                    onPress={() => selectIntensity('LOW')}
                  >
                    {translate({ text: 'low' })}
                  </Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                  <RadioButton.Android
                    onPress={() => selectIntensity('MEDIUM')}
                    value={'MEDIUM'}
                    status={
                      intensity.toLowerCase() === 'medium'
                        ? 'checked'
                        : 'unchecked'
                    }
                  />
                  <Text
                    onPressIn={undefined}
                    onPressOut={undefined}
                    android_hyphenationFrequency={undefined}
                    style={{ marginTop: 10 }}
                    onPress={() => selectIntensity('MEDIUM')}
                  >
                    {translate({ text: 'medium' })}
                  </Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                  <RadioButton.Android
                    onPress={() => selectIntensity('HIGH')}
                    value={'HIGH'}
                    status={
                      intensity.toLowerCase() === 'high'
                        ? 'checked'
                        : 'unchecked'
                    }
                  />
                  <Text
                    onPressIn={undefined}
                    onPressOut={undefined}
                    android_hyphenationFrequency={undefined}
                    style={{ marginTop: 10 }}
                    onPress={() => selectIntensity('HIGH')}
                  >
                    {translate({ text: 'high' })}
                  </Text>
                </View>
                <View style={[{ marginRight: 16 }]}>
                  <TextInput
                    onPressIn={undefined}
                    onPressOut={undefined}
                    autoComplete={undefined}
                    mode={'flat'}
                    label={translate({ text: 'amount' })}
                    keyboardType={'numeric'}
                    style={{
                      backgroundColor: '#fff',
                      paddingRight: 16,
                    }}
                    value={sample?.density?.count || ''}
                    onChangeText={(value: string) => changeCount(value)}
                    textAlign={undefined}
                  />
                </View>
                <View style={[{ marginRight: 16, marginBottom: 6 }]}>
                  <TextInput
                    onPressIn={undefined}
                    onPressOut={undefined}
                    autoComplete={undefined}
                    mode={'flat'}
                    label={translate({ text: 'metricAmount' })}
                    style={{
                      backgroundColor: '#fff',
                      paddingRight: 16,
                    }}
                    value={sample?.density?.unit || ''}
                    onChangeText={(value: string) => changeUnit(value)}
                    textAlign={undefined}
                  />
                </View>
              </View>
              <View style={{ paddingLeft: 16, paddingBottom: 8, marginTop: 8 }}>
                <Title style={{ fontSize: 16 }}>
                  {translate({ text: 'location' })}
                </Title>
              </View>
              <View
                style={{
                  paddingLeft: 16,
                  paddingRight: 16,
                  flexDirection: 'row',
                  width: '100%',
                  flex: 1,
                }}
              >
                <Button
                  mode={'outlined'}
                  onPress={() => setInnerSelected(0)}
                  style={[
                    {
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      borderTopLeftRadius: 5,
                      borderBottomLeftRadius: 5,
                      flex: 1,
                    },
                    innerSelected === 0 && {
                      backgroundColor: c.greenColor,
                    },
                  ]}
                  color={innerSelected === 0 ? '#FFF' : undefined}
                >
                  {translate({ text: 'singlePole' })}
                </Button>
                <Button
                  mode={'outlined'}
                  style={[
                    {
                      borderTopRightRadius: 5,
                      borderBottomRightRadius: 5,
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      flex: 1,
                    },
                    innerSelected === 1 && {
                      backgroundColor: c.greenColor,
                    },
                  ]}
                  color={innerSelected === 1 ? '#FFF' : undefined}
                  onPress={() => setInnerSelected(1)}
                >
                  {translate({ text: 'fromToPole' })}
                </Button>
              </View>
              {innerSelected === 0 ? (
                <View style={{ paddingLeft: 16, marginTop: 8 }}>
                  <View style={[{ marginRight: 16 }]}>
                    <TextInput
                      onPressIn={undefined}
                      onPressOut={undefined}
                      autoComplete={undefined}
                      mode={'flat'}
                      label={translate({ text: 'poleEg' })}
                      style={{
                        backgroundColor: '#fff',
                        paddingRight: 16,
                      }}
                      value={sample?.location?.floorplan?.post || ''}
                      onChangeText={(value: string) => changePost(value)}
                      textAlign={undefined}
                    />
                  </View>
                </View>
              ) : (
                <View style={{ paddingLeft: 16, marginTop: 8 }}>
                  <View
                    style={[
                      {
                        marginRight: 16,
                        flexDirection: 'row',
                        flex: 1,
                      },
                    ]}
                  >
                    <TextInput
                      onPressIn={undefined}
                      onPressOut={undefined}
                      autoComplete={undefined}
                      mode={'flat'}
                      label={translate({ text: 'fromPole' })}
                      style={{
                        backgroundColor: '#fff',
                        paddingRight: 16,
                        flex: 1,
                      }}
                      value={sample?.location?.floorplan?.range?.start || ''}
                      onChangeText={(value: string) =>
                        changeFromToPost('start', value)
                      }
                      textAlign={undefined}
                    />
                    <TextInput
                      onPressIn={undefined}
                      onPressOut={undefined}
                      autoComplete={undefined}
                      mode={'flat'}
                      label={translate({ text: 'toPole' })}
                      style={{
                        backgroundColor: '#fff',
                        paddingRight: 16,
                        flex: 1,
                      }}
                      value={sample?.location?.floorplan?.range?.end || ''}
                      onChangeText={(value: string) =>
                        changeFromToPost('end', value)
                      }
                      textAlign={undefined}
                    />
                  </View>
                </View>
              )}
              <View style={{ paddingLeft: 16 }}>
                <View style={[{ marginRight: 16 }]}>
                  <TextInput
                    onPressIn={undefined}
                    onPressOut={undefined}
                    autoComplete={undefined}
                    mode={'flat'}
                    label={translate({ text: 'rowEg' })}
                    style={{
                      backgroundColor: '#fff',
                      paddingRight: 16,
                    }}
                    value={sample?.location?.floorplan?.row || ''}
                    onChangeText={(value: string) => changeRow(value)}
                    textAlign={undefined}
                  />
                </View>
                <View style={{ flexDirection: 'row' }}>
                  <RadioButton.Android
                    onPress={() => selectSection('left')}
                    value={'left'}
                    status={
                      section.toLowerCase() === 'left' ? 'checked' : 'unchecked'
                    }
                  />
                  <Text
                    onPressIn={undefined}
                    onPressOut={undefined}
                    android_hyphenationFrequency={undefined}
                    style={{ marginTop: 10 }}
                    onPress={() => selectSection('left')}
                  >
                    {translate({ text: 'left' })}
                  </Text>
                </View>
                <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                  <RadioButton.Android
                    onPress={() => selectSection('right')}
                    value={'right'}
                    status={
                      section.toLowerCase() === 'right'
                        ? 'checked'
                        : 'unchecked'
                    }
                  />
                  <Text
                    onPressIn={undefined}
                    onPressOut={undefined}
                    android_hyphenationFrequency={undefined}
                    style={{ marginTop: 10 }}
                    onPress={() => selectSection('right')}
                  >
                    {translate({ text: 'right' })}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  paddingLeft: 16,
                  paddingRight: 16,
                  marginBottom: 16,
                  flexDirection: 'row',
                  width: '100%',
                  flex: 1,
                }}
              >
                <TextInput
                  onPressIn={undefined}
                  onPressOut={undefined}
                  autoComplete={undefined}
                  mode={'flat'}
                  multiline
                  label={translate({ text: 'note' })}
                  style={{
                    backgroundColor: '#fff',
                    width: '100%',
                  }}
                  value={sample?.note || ''}
                  onChangeText={value => change('note', value)}
                  textAlign={undefined}
                />
              </View>
            </View>
          ) : (
            <View key={sample?.beneficialOrganism?.id}>
              <BeneficialOrganismSingleSelect
                value={sample?.beneficialOrganism}
                filterType={filterTypeBeneficialOrganism}
                onSelect={(value: any) => change('beneficialOrganism', value)}
              />
              <Divider />
              <View style={{ paddingLeft: 16, paddingBottom: 8, marginTop: 8 }}>
                <Title style={{ fontSize: 16 }}>
                  {translate({ text: 'phase' })}
                </Title>
                <View style={{ flexDirection: 'row' }}>
                  <RadioButton.Android
                    onPress={() => selectStage('EGG')}
                    value={'EGG'}
                    status={
                      stage.toLowerCase() === 'egg' ? 'checked' : 'unchecked'
                    }
                  />
                  <Text
                    onPressIn={undefined}
                    onPressOut={undefined}
                    android_hyphenationFrequency={undefined}
                    style={{ marginTop: 10 }}
                    onPress={() => selectStage('EGG')}
                  >
                    {translate({ text: 'Egg' })}
                  </Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                  <RadioButton.Android
                    onPress={() => selectStage('LARVA')}
                    value={'LARVA'}
                    status={
                      stage.toLowerCase() === 'larva' ? 'checked' : 'unchecked'
                    }
                  />
                  <Text
                    onPressIn={undefined}
                    onPressOut={undefined}
                    android_hyphenationFrequency={undefined}
                    style={{ marginTop: 10 }}
                    onPress={() => selectStage('LARVA')}
                  >
                    {translate({ text: 'Larva' })}
                  </Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                  <RadioButton.Android
                    onPress={() => selectStage('NYMPH')}
                    value={'NYMPH'}
                    status={
                      stage.toLowerCase() === 'nymph' ? 'checked' : 'unchecked'
                    }
                  />
                  <Text
                    onPressIn={undefined}
                    onPressOut={undefined}
                    android_hyphenationFrequency={undefined}
                    style={{ marginTop: 10 }}
                    onPress={() => selectStage('NYMPH')}
                  >
                    {translate({ text: 'Nymph' })}
                  </Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                  <RadioButton.Android
                    onPress={() => selectStage('PUPA')}
                    value={'PUPA'}
                    status={
                      stage.toLowerCase() === 'pupa' ? 'checked' : 'unchecked'
                    }
                  />
                  <Text
                    onPressIn={undefined}
                    onPressOut={undefined}
                    android_hyphenationFrequency={undefined}
                    style={{ marginTop: 10 }}
                    onPress={() => selectStage('PUPA')}
                  >
                    {translate({ text: 'Pupa' })}
                  </Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                  <RadioButton.Android
                    onPress={() => selectStage('POPULATION')}
                    value={'POPULATION'}
                    status={
                      stage.toLowerCase() === 'population'
                        ? 'checked'
                        : 'unchecked'
                    }
                  />
                  <Text
                    onPressIn={undefined}
                    onPressOut={undefined}
                    android_hyphenationFrequency={undefined}
                    style={{ marginTop: 10 }}
                    onPress={() => selectStage('POPULATION')}
                  >
                    {translate({ text: 'Population' })}
                  </Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                  <RadioButton.Android
                    onPress={() => selectStage('MUMMY')}
                    value={'MUMMY'}
                    status={
                      stage.toLowerCase() === 'mummy' ? 'checked' : 'unchecked'
                    }
                  />
                  <Text
                    onPressIn={undefined}
                    onPressOut={undefined}
                    android_hyphenationFrequency={undefined}
                    style={{ marginTop: 10 }}
                    onPress={() => selectStage('MUMMY')}
                  >
                    {translate({ text: 'Mummy' })}
                  </Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                  <RadioButton.Android
                    onPress={() => selectStage('ADULT')}
                    value={'ADULT'}
                    status={
                      stage.toLowerCase() === 'adult' ? 'checked' : 'unchecked'
                    }
                  />
                  <Text
                    onPressIn={undefined}
                    onPressOut={undefined}
                    android_hyphenationFrequency={undefined}
                    style={{ marginTop: 10 }}
                    onPress={() => selectStage('ADULT')}
                  >
                    {translate({ text: 'Adult' })}
                  </Text>
                </View>
              </View>
              <View style={{ paddingLeft: 16, paddingBottom: 8, marginTop: 8 }}>
                <Title style={{ fontSize: 16 }}>
                  {translate({ text: 'intensitivity' })}
                </Title>
                <View style={{ flexDirection: 'row' }}>
                  <RadioButton.Android
                    onPress={() => selectIntensity('low')}
                    value={'low'}
                    status={intensity === 'low' ? 'checked' : 'unchecked'}
                  />
                  <Text
                    onPressIn={undefined}
                    onPressOut={undefined}
                    android_hyphenationFrequency={undefined}
                    style={{ marginTop: 10 }}
                    onPress={() => selectIntensity('low')}
                  >
                    {translate({ text: 'low' })}
                  </Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                  <RadioButton.Android
                    onPress={() => selectIntensity('medium')}
                    value={'medium'}
                    status={intensity === 'medium' ? 'checked' : 'unchecked'}
                  />
                  <Text
                    onPressIn={undefined}
                    onPressOut={undefined}
                    android_hyphenationFrequency={undefined}
                    style={{ marginTop: 10 }}
                    onPress={() => selectIntensity('medium')}
                  >
                    {translate({ text: 'medium' })}
                  </Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                  <RadioButton.Android
                    onPress={() => selectIntensity('high')}
                    value={'high'}
                    status={intensity === 'high' ? 'checked' : 'unchecked'}
                  />
                  <Text
                    onPressIn={undefined}
                    onPressOut={undefined}
                    android_hyphenationFrequency={undefined}
                    style={{ marginTop: 10 }}
                    onPress={() => selectIntensity('high')}
                  >
                    {translate({ text: 'high' })}
                  </Text>
                </View>
              </View>
              <View style={{ paddingLeft: 16, paddingBottom: 8, marginTop: 8 }}>
                <Title style={{ fontSize: 16 }}>
                  {translate({ text: 'balanced' })}
                </Title>
                <View style={{ flexDirection: 'row' }}>
                  <RadioButton.Android
                    onPress={() => selectBalanced('true')}
                    value={'true'}
                    status={
                      balanced.toLowerCase() === 'true'
                        ? 'checked'
                        : 'unchecked'
                    }
                  />
                  <Text
                    onPressIn={undefined}
                    onPressOut={undefined}
                    android_hyphenationFrequency={undefined}
                    style={{ marginTop: 10 }}
                    onPress={() => selectBalanced('true')}
                  >
                    {translate({ text: 'yes' })}
                  </Text>
                </View>
                <View style={{ flexDirection: 'row' }}>
                  <RadioButton.Android
                    onPress={() => selectBalanced('false')}
                    value={'false'}
                    status={
                      balanced.toLowerCase() === 'false'
                        ? 'checked'
                        : 'unchecked'
                    }
                  />
                  <Text
                    onPressIn={undefined}
                    onPressOut={undefined}
                    android_hyphenationFrequency={undefined}
                    style={{ marginTop: 10 }}
                    onPress={() => selectBalanced('false')}
                  >
                    {translate({ text: 'no' })}
                  </Text>
                </View>
                <View style={[{ marginRight: 16 }]}>
                  <TextInput
                    onPressIn={undefined}
                    onPressOut={undefined}
                    autoComplete={undefined}
                    mode={'flat'}
                    label={translate({ text: 'amount' })}
                    keyboardType={'numeric'}
                    style={{
                      backgroundColor: '#fff',
                      paddingRight: 16,
                    }}
                    value={sample?.density?.count || ''}
                    onChangeText={(value: string) => changeCount(value)}
                    textAlign={undefined}
                  />
                </View>
                <View style={[{ marginRight: 16, marginBottom: 6 }]}>
                  <TextInput
                    onPressIn={undefined}
                    onPressOut={undefined}
                    autoComplete={undefined}
                    mode={'flat'}
                    label={translate({ text: 'metricAmount' })}
                    style={{
                      backgroundColor: '#fff',
                      paddingRight: 16,
                    }}
                    value={sample?.density?.unit || ''}
                    onChangeText={(value: string) => changeUnit(value)}
                    textAlign={undefined}
                  />
                </View>
              </View>
              <View style={{ paddingLeft: 16, paddingBottom: 8, marginTop: 8 }}>
                <Title style={{ fontSize: 16 }}>
                  {translate({ text: 'location' })}
                </Title>
              </View>

              <View
                style={{
                  paddingLeft: 16,
                  paddingRight: 16,
                  flexDirection: 'row',
                  width: '100%',
                  flex: 1,
                }}
              >
                <Button
                  mode={'outlined'}
                  onPress={() => setInnerSelected(0)}
                  style={[
                    {
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      borderTopLeftRadius: 5,
                      borderBottomLeftRadius: 5,
                      flex: 1,
                    },
                    innerSelected === 0 && {
                      backgroundColor: c.greenColor,
                    },
                  ]}
                  color={innerSelected === 0 ? '#FFF' : undefined}
                >
                  {translate({ text: 'singlePole' })}
                </Button>
                <Button
                  mode={'outlined'}
                  style={[
                    {
                      borderTopRightRadius: 5,
                      borderBottomRightRadius: 5,
                      borderTopLeftRadius: 0,
                      flex: 1,
                      borderBottomLeftRadius: 0,
                    },
                    innerSelected === 1 && {
                      backgroundColor: c.greenColor,
                    },
                  ]}
                  color={innerSelected === 1 ? '#FFF' : undefined}
                  onPress={() => setInnerSelected(1)}
                >
                  {translate({ text: 'fromToPole' })}
                </Button>
              </View>
              {innerSelected === 0 ? (
                <View style={{ paddingLeft: 16, marginTop: 8 }}>
                  <View style={[{ marginRight: 16 }]}>
                    <TextInput
                      onPressIn={undefined}
                      onPressOut={undefined}
                      autoComplete={undefined}
                      mode={'flat'}
                      label={translate({ text: 'poleEg' })}
                      style={{
                        backgroundColor: '#fff',
                        paddingRight: 16,
                      }}
                      value={sample?.location?.floorplan?.post || ''}
                      onChangeText={(value: string) => changePost(value)}
                      textAlign={undefined}
                    />
                  </View>
                </View>
              ) : (
                <View style={{ paddingLeft: 16, marginTop: 8 }}>
                  <View
                    style={[
                      {
                        marginRight: 16,
                        flexDirection: 'row',
                        flex: 1,
                      },
                    ]}
                  >
                    <TextInput
                      onPressIn={undefined}
                      onPressOut={undefined}
                      autoComplete={undefined}
                      mode={'flat'}
                      label={translate({ text: 'fromPole' })}
                      style={{
                        backgroundColor: '#fff',
                        paddingRight: 16,
                        flex: 1,
                      }}
                      value={sample?.location?.floorplan?.range?.start || ''}
                      onChangeText={(value: string) =>
                        changeFromToPost('start', value)
                      }
                      textAlign={undefined}
                    />
                    <TextInput
                      onPressIn={undefined}
                      onPressOut={undefined}
                      autoComplete={undefined}
                      mode={'flat'}
                      label={translate({ text: 'toPole' })}
                      style={{
                        backgroundColor: '#fff',
                        paddingRight: 16,
                        flex: 1,
                      }}
                      value={sample?.location?.floorplan?.range?.end || ''}
                      onChangeText={(value: string) =>
                        changeFromToPost('end', value)
                      }
                      textAlign={undefined}
                    />
                  </View>
                </View>
              )}
              <View style={{ paddingLeft: 16 }}>
                <View style={[{ marginRight: 16 }]}>
                  <TextInput
                    onPressIn={undefined}
                    onPressOut={undefined}
                    autoComplete={undefined}
                    mode={'flat'}
                    label={translate({ text: 'rowEg' })}
                    style={{
                      backgroundColor: '#fff',
                      paddingRight: 16,
                    }}
                    value={sample?.location?.floorplan?.row || ''}
                    onChangeText={(value: string) => changeRow(value)}
                    textAlign={undefined}
                  />
                </View>
                <View style={{ flexDirection: 'row' }}>
                  <RadioButton.Android
                    onPress={() => selectSection('left')}
                    value={'left'}
                    status={section === 'left' ? 'checked' : 'unchecked'}
                  />
                  <Text
                    onPressIn={undefined}
                    onPressOut={undefined}
                    android_hyphenationFrequency={undefined}
                    style={{ marginTop: 10 }}
                    onPress={() => selectSection('left')}
                  >
                    {translate({ text: 'left' })}
                  </Text>
                </View>
                <View style={{ flexDirection: 'row', marginBottom: 10 }}>
                  <RadioButton.Android
                    onPress={() => selectSection('right')}
                    value={'right'}
                    status={section === 'right' ? 'checked' : 'unchecked'}
                  />
                  <Text
                    onPressIn={undefined}
                    onPressOut={undefined}
                    android_hyphenationFrequency={undefined}
                    style={{ marginTop: 10 }}
                    onPress={() => selectSection('right')}
                  >
                    {translate({ text: 'right' })}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  paddingLeft: 16,
                  paddingRight: 16,
                  marginBottom: 16,
                  flexDirection: 'row',
                  width: '100%',
                  flex: 1,
                }}
              >
                <TextInput
                  onPressIn={undefined}
                  onPressOut={undefined}
                  autoComplete={undefined}
                  mode={'flat'}
                  multiline
                  label={translate({ text: 'note' })}
                  style={{
                    backgroundColor: '#fff',
                    width: '100%',
                  }}
                  value={sample?.note || ''}
                  onChangeText={value => change('note', value)}
                  textAlign={undefined}
                />
              </View>
            </View>
          )}
        </Box>
      )}
    </Translator>
  )
}

export default Sample
