import React, { useCallback } from 'react'
import { StyleSheet, View } from 'react-native'
import { Button, IconButton, Text, Title } from 'react-native-paper'
import Fields from '../Form/Fields'
import ProductSingleSelect from '../Products/ProductSingleSelect/ProductSingleSelect'

const unitFields = [
  {
    name: 'dosage',
    label: 'Hoeveelheid',
    type: 'text',
    fieldProps: {
      style: { flex: 1 },
    },
  },
  {
    name: 'dosageUnit',
    label: 'Eenheid',
    type: 'options',
    options: [
      { key: 'lha', title: 'Liter/ha', value: 'lha' },
      { key: 'la', title: 'Liter/a', value: 'la' },
    ],
    fieldProps: {
      style: { flex: 1 },
    },
  },
]
const baseLine = {
  products: [{}],
}
const fields = []
const secondFields = [
  {
    name: 'description',
    label: 'Opmerkingen',
    type: 'text',
    fieldProps: {
      style: { marginLeft: 6, marginRight: 6 },
    },
  },
]

function AdviceProduct({ editable, product, onChange }) {
  const onSelectProduct = useCallback(
    (realProduct) => {
      onChange({ ...product, product: realProduct })
    },
    [product, onChange]
  )
  const onChangeDosage = useCallback(
    (name, objectValue) => {
      onChange({ ...product, [name]: objectValue })
    },
    [product, onChange]
  )
  return (
    <>
      <View style={{ flexDirection: 'row' }}>
        <ProductSingleSelect
          value={product && product.id ? product : product.product}
          editable={editable}
          onSelect={onSelectProduct}
          style={{ flex: 1 }}
        />
      </View>
      <View style={{ flexDirection: 'row' }}>
        <Fields
          fields={unitFields}
          values={product?.dosage || {}}
          editable={editable}
          onChange={onChangeDosage}
        />
      </View>
    </>
  )
}

function AdviceLine({ editable, line, lineIndex, onChange, onRemove }) {
  const onChangeLine = useCallback(
    (name, objectValue) => {
      onChange({ ...line, [name]: objectValue })
    },
    [line, onChange]
  )
  const onAddProduct = useCallback(() => {
    onChange({
      ...line,
      products: [...(line.products || []), {}],
    })
  }, [line, onChange])

  const onChangeProduct = useCallback(
    (productIndex) => (product) => {
      onChange({
        ...line,
        products: (line.products || []).map((p, i) =>
          i === productIndex ? product : p
        ),
      })
    },
    [line, onChange]
  )
  console.log(line)
  return (
    <View
      key={lineIndex}
      style={[
        styles.col,
        styles.lineContainer,
        // { zIndex: 100 + lines.length - lineIndex },
      ]}
    >
      <View style={styles.rowJustifyEnd}>
        <View style={styles.full}>
          <View style={{ flexDirection: 'row', padding: 16 }}>
            <Text>Reden: {line.pest && line.pest.name}</Text>
          </View>
          {(line.products || []).map((product, productIndex) => (
            <AdviceProduct
              key={productIndex}
              product={product}
              editable={editable}
              onChange={onChangeProduct(productIndex)}
            />
          ))}
          {editable ? (
            <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
              <Button
                icon="plus"
                onPress={onAddProduct}
                uppercase={false}
                color="#000"
              >
                Middel toevoegen
              </Button>
            </View>
          ) : null}
        </View>
        {onRemove && editable ? (
          <View style={[styles.col, styles.closeContainer]}>
            <IconButton icon={'minus'} onPress={onRemove} />
          </View>
        ) : null}
      </View>

      <View style={[styles.row, styles.secondRow]}>
        <Fields
          fields={fields}
          values={line}
          onChange={onChangeLine}
          // onSubmit={next}
          editable={editable}
        />
      </View>
      <Fields
        fields={secondFields}
        values={line}
        onChange={onChangeLine}
        // onSubmit={next}
        editable={editable}
      />
    </View>
  )
}

function AdviceLines({ label, value, onChange, next, editable = true }) {
  const onAddLine = useCallback(() => {
    onChange([...(value || []), baseLine])
  }, [value, onChange])

  const onRemoveLine = useCallback(
    (lineIndex) => () => {
      onChange((value || []).filter((_, i) => i !== lineIndex))
    },
    [value, onChange]
  )
  const onChangeLine = useCallback(
    (lineIndex) => (line) => {
      const lines = (value || []).map((l, i) => (i === lineIndex ? line : l))
      onChange(lines)
    },
    [value, onChange]
  )

  const lines = value || []
  return (
    <View style={styles.root}>
      {label && <Title style={styles.title}>{label}</Title>}
      {lines.map((line, lineIndex) => (
        <AdviceLine
          key={lineIndex}
          line={line}
          lineIndex={lineIndex}
          onRemove={lines.length > 1 ? onRemoveLine(lineIndex) : undefined}
          onChange={onChangeLine(lineIndex)}
          editable={editable}
        />
      ))}
      {editable ? (
        <View style={styles.bottom}>
          <Button icon="plus" onPress={onAddLine} uppercase={false}>
            Adviesregel toevoegen
          </Button>
        </View>
      ) : null}
    </View>
  )
}

// const fixedZIndex = undefined

const styles = StyleSheet.create({
  title: {
    padding: 16,
    marginBottom: 0,
    paddingBottom: 0,
    marginTop: 12,
  },
  root: {
    // zIndex: fixedZIndex,
    // flex: 1,
    position: 'relative',
    flexDirection: 'column',
  },
  bottom: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: 16,
    paddingBottom: 16,
    marginBottom: 56,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowJustifyEnd: { flexDirection: 'row', justifyContent: 'flex-end' },
  full: {
    flex: 1,
  },
  closeContainer: { padding: 6 },
  secondRow: {
    paddingLeft: 6,
    // zIndex: fixedZIndex,
  },
  lineContainer: {
    position: 'relative',
    // zIndex: fixedZIndex,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    backgroundColor: '#fff',
    paddingTop: 0,
    paddingBottom: 16,
    marginBottom: 16,
    marginTop: 24,
    margin: 6,
    borderRadius: 5,
  },
})

export default AdviceLines
