import React from 'react'
import Svg from './SvgAbstraction'
import Path from './PathAbstraction'

import { color, defaultProps, propTypes } from './standardVector'

export const width = 24
export const height = 24

const Vector = props => (
  <Svg
    width={props.width || width}
    height={props.height || height}
    className={props.className}
    viewBox="0 0 24 24"
  >
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M22.254 9.91099C21.6092 7.74233 20.2811 5.83996 18.4675 4.48719C16.654 3.13443 14.452 2.40363 12.1895 2.40363C9.927 2.40363 7.72501 3.13443 5.91147 4.48719C4.09793 5.83996 2.76984 7.74233 2.125 9.91099H22.254Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M12.189 2.41101V0.911011"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M11.871 14.036C11.6639 14.036 11.496 13.8681 11.496 13.661C11.496 13.4539 11.6639 13.286 11.871 13.286"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M11.871 14.036C12.0781 14.036 12.246 13.8681 12.246 13.661C12.246 13.4539 12.0781 13.286 11.871 13.286"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M11.871 20.036C11.6639 20.036 11.496 19.8681 11.496 19.661C11.496 19.4539 11.6639 19.286 11.871 19.286"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M11.871 20.036C12.0781 20.036 12.246 19.8681 12.246 19.661C12.246 19.4539 12.0781 19.286 11.871 19.286"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M8.871 17.036C8.6639 17.036 8.496 16.8681 8.496 16.661C8.496 16.4539 8.6639 16.286 8.871 16.286"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M8.871 17.036C9.07811 17.036 9.246 16.8681 9.246 16.661C9.246 16.4539 9.07811 16.286 8.871 16.286"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M14.871 17.036C14.6639 17.036 14.496 16.8681 14.496 16.661C14.496 16.4539 14.6639 16.286 14.871 16.286"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M14.871 17.036C15.0781 17.036 15.246 16.8681 15.246 16.661C15.246 16.4539 15.0781 16.286 14.871 16.286"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M17.871 20.036C17.6639 20.036 17.496 19.8681 17.496 19.661C17.496 19.4539 17.6639 19.286 17.871 19.286"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M17.871 20.036C18.0781 20.036 18.246 19.8681 18.246 19.661C18.246 19.4539 18.0781 19.286 17.871 19.286"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M5.871 20.036C5.6639 20.036 5.496 19.8681 5.496 19.661C5.496 19.4539 5.6639 19.286 5.871 19.286"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M5.871 20.036C6.07811 20.036 6.246 19.8681 6.246 19.661C6.246 19.4539 6.07811 19.286 5.871 19.286"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M2.871 23.036C2.6639 23.036 2.496 22.8681 2.496 22.661C2.496 22.4539 2.6639 22.286 2.871 22.286"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M2.871 23.036C3.07811 23.036 3.246 22.8681 3.246 22.661C3.246 22.4539 3.07811 22.286 2.871 22.286"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M8.871 23.036C8.6639 23.036 8.496 22.8681 8.496 22.661C8.496 22.4539 8.6639 22.286 8.871 22.286"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M8.871 23.036C9.07811 23.036 9.246 22.8681 9.246 22.661C9.246 22.4539 9.07811 22.286 8.871 22.286"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M14.871 23.036C14.6639 23.036 14.496 22.8681 14.496 22.661C14.496 22.4539 14.6639 22.286 14.871 22.286"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M14.871 23.036C15.0781 23.036 15.246 22.8681 15.246 22.661C15.246 22.4539 15.0781 22.286 14.871 22.286"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M20.871 23.036C20.6639 23.036 20.496 22.8681 20.496 22.661C20.496 22.4539 20.6639 22.286 20.871 22.286"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M20.871 23.036C21.0781 23.036 21.246 22.8681 21.246 22.661C21.246 22.4539 21.0781 22.286 20.871 22.286"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </Svg>
)

Vector.propTypes = propTypes
Vector.defaultProps = defaultProps

export default Vector
