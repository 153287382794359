import Options from '../Options'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import a from './actions'
import aI from './unitInterval/actions'

function UnitList({
  onChange,
  key,
  label,
  fieldProps,
  value,
  editable,
  unitType,
}) {
  const options = useSelector(state =>
    unitType === 'dosage' ? state.unit.list : state.unitInterval.list
  )
  const dispatch = useDispatch()
  useEffect(() => {
    if (unitType === 'dosage') {
      dispatch(
        a.loadList(undefined, {
          params: { type: 'dosage' },
        })
      )
    } else {
      dispatch(
        aI.loadList(undefined, {
          params: { type: 'mix' },
        })
      )
    }
  }, [dispatch, unitType])

  const innerChange = (key, val) => {
    console.log({ key, val })
    onChange(val)
  }
  return (
    <Options
      key={key}
      label={label}
      options={options.data}
      {...fieldProps}
      value={value}
      editable={editable}
      onChange={val => innerChange(key, val)}
    />
  )
}

export default UnitList
