import React, { useCallback, useState } from 'react'
import { ActivityIndicator, Button, IconButton } from 'react-native-paper'
import { useSelector } from 'react-redux'
import widthAndHeightHOC from '../WidthAndHeight/widthAndHeightHOC'
import { download } from '../helpers/download'
import { getFirstSelectedId } from '../FilterType/config'
import { pushCustomEvent } from '../Analytics/abstraction'
import { Translate } from 'react-translated'
import { getLanguage } from '../language'

function ComparisonDownloadButton({ isMedium, productIds }) {
  const [loading, setLoading] = useState(false)
  const token = useSelector(state => (state.auth.tokens || {}).idToken)
  const cropId = useSelector(state =>
    getFirstSelectedId(state.filter.crop.selected)
  )
  const language = getLanguage()
  const onDownload = useCallback(() => {
    pushCustomEvent('Export button pressed ', '', { ProductIDs: productIds })
    if (cropId !== undefined) {
      async function downloadFunction() {
        setLoading(true)
        await download({
          url: 'comparison/download',
          params: {
            productIds,
            cropId,
            language: process.env.REACT_APP_API_LANG || 'nl_nl',
          },
          fileName: 'Export.xlsx',
          token,
        })
        setLoading(false)
      }

      downloadFunction()
    } else {
      // eslint-disable-next-line no-alert
      alert(language === 'nl' ? 'Selecteer een teelt' : 'Select a crop')
    }
  }, [cropId, token, productIds, language])

  return isMedium ? (
    <Button
      loading={loading}
      uppercase={false}
      icon={'download'}
      color={'#fff'}
      onPress={onDownload}
    >
      <Translate text={'export'} />
    </Button>
  ) : loading ? (
    <ActivityIndicator color={'#fff'} style={{ marginRight: 6 }} />
  ) : (
    <IconButton
      onPress={onDownload}
      icon={'download'}
      color={'#fff'}
      testID={'download'}
    />
  )
}

export default widthAndHeightHOC(ComparisonDownloadButton)
