import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { StyleSheet, View } from 'react-native'
import {
  Appbar,
  Avatar,
  IconButton,
  Menu,
  TouchableRipple,
} from 'react-native-paper'
import HeaderLogo from './GeneralListFunctions/HeaderLogo'
import {
  ACCOUNT_SCREEN_ROUTE,
  ORGANIZATION_SCREEN_ROUTE,
  ORGANIZATION_USERS_ROUTE,
  PDF_ROUTE,
} from './Routes.config'
import c from './constants'
import { getGravatar } from './helpers/gravatar'
// @ts-ignore
import { logout } from 'react-auth/lib/actions/authentication'
import AppbarResizer from './WidthAndHeight/AppbarResizer'
import { openMenu, push } from './Navigation'
import { menuOptions } from './helpers/role'
import { getLanguage } from './language'

const avatarSize = 30

const styles = StyleSheet.create({
  root: {
    flexDirection: 'column',
    height: 56,
    position: 'relative',
    zIndex: 10,
  },
  button: {
    width: 300,
    alignSelf: 'flex-start',
    marginBottom: 16,
  },
  avatarButton: {
    borderRadius: avatarSize / 2,
    overflow: 'hidden',
    marginRight: 6,
  },
  avatarContainer: {
    borderWidth: 2,
    borderColor: '#fff',
    borderRadius: (avatarSize + 4) / 2,
    overflow: 'hidden',
  },
  themeSwitchContainer: {},
  avatar: {
    backgroundColor: '#EDEDED',
  },
})
function PageHeader({
  title,
  back,
  componentId,
  withoutOnPressLogo = false,
  withoutAccount = false,
}: {
  title: any
  back?: () => any
  componentId?: string
  withoutOnPressLogo?: boolean
  withoutAccount?: boolean
}) {
  const language = getLanguage()
  const [visible, setVisible] = React.useState(false)
  const dispatch = useDispatch()
  const openSideMenu = () => {
    openMenu(componentId)
  }

  const user = useSelector(
    (state: any) =>
      (state.auth && state.auth.user && state.auth.user.user) || {}
  )
  const innerOpenMenu = () => setVisible(true)
  const closeMenu = () => setVisible(false)

  const pressLogout = () => {
    dispatch(logout())
    closeMenu()
  }

  const pressTerms = () => {
    push(componentId, {
      component: {
        name: PDF_ROUTE,
        passProps: {
          url: encodeURI(c.termsAndConditionsUrl),
          title: language === 'nl' ? 'Gebruiksvoorwaarden' : 'Terms of use',
        },
      },
    })

    closeMenu()
  }

  const pressAccount = () => {
    push(componentId, {
      component: {
        name: ACCOUNT_SCREEN_ROUTE,
      },
    })

    closeMenu()
  }

  const pressOrganization = () => {
    push(componentId, {
      component: {
        name: ORGANIZATION_SCREEN_ROUTE,
      },
    })

    closeMenu()
  }

  const pressUsers = () => {
    push(componentId, {
      component: {
        name: ORGANIZATION_USERS_ROUTE,
      },
    })

    closeMenu()
  }

  let options = menuOptions(user) // the available menu options for this user
  return (
    <View style={styles.root}>
      <AppbarResizer>
        {options.length > 0 ? (
          <>
            {back ? (
              <Appbar.BackAction color={'white'} onPress={back} />
            ) : (
              <IconButton
                testID="menuOptions"
                icon="menu"
                color={'white'}
                onPress={openSideMenu}
              />
            )}
          </>
        ) : null}
        {title ? title : <HeaderLogo withoutOnPressLogo={withoutOnPressLogo} />}
        {withoutAccount ? null : (
          <Menu
            visible={visible}
            onDismiss={closeMenu}
            anchor={
              <TouchableRipple
                onPress={innerOpenMenu}
                style={styles.avatarButton}
                testID={'avatar'}
                accessibilityRole={'button'}
              >
                <View style={styles.avatarContainer}>
                  <Avatar.Image
                    size={avatarSize}
                    source={{ uri: getGravatar(user.email, avatarSize * 3) }}
                    style={styles.avatar}
                  />
                </View>
              </TouchableRipple>
            }
          >
            <Menu.Item
              onPress={pressTerms}
              title={language === 'nl' ? 'Gebruiksvoorwaarden' : 'Terms of use'}
            />
            <Menu.Item
              onPress={pressAccount}
              title={language === 'nl' ? 'Mijn account' : 'My account'}
            />
            <Menu.Item
              onPress={pressOrganization}
              title={language === 'nl' ? 'Mijn bedrijf' : 'My company'}
            />
            <Menu.Item
              onPress={pressUsers}
              title={language === 'nl' ? 'Mijn gebruikers' : 'My users'}
            />
            <Menu.Item
              onPress={pressLogout}
              title={language === 'nl' ? 'Uitloggen' : 'Logout'}
            />
          </Menu>
        )}
      </AppbarResizer>
    </View>
  )
}

export default PageHeader
