const ns = (type: string) => `SPRAY_DATE_${type}`

export const OPEN = ns('OPEN')
export const open = () => ({ type: OPEN })

export const CLOSE = ns('CLOSE')
export const close = () => ({ type: CLOSE })

export const SET = ns('SET')
export const set = (date: number, closeModal?: boolean) => ({
  type: SET,
  payload: date,
  closeModal: closeModal,
})

export const REMOVE = ns('REMOVE')
export const remove = () => ({ type: REMOVE })
