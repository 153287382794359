import mixpanel from 'mixpanel-browser'
import { getURLFromPushOptions } from '../Navigation.global'

export function initAnalytics({ user, organization }) {
  mixpanel.init('c43ae06e417ad423d4c47e654b44f1cb', {
    api_host: 'https://api.mixpanel.com',
  })
  mixpanel.identify(
    user.user_id ? `${user.user_id}` : mixpanel.get_distinct_id()
  )
  mixpanel.people.set({
    $id: user.user_id ? `${user.user_id}` : 'Unauthenticated',
    $user_id: user.user_id ? `${user.user_id}` : 'Unauthenticated',
    $email: user.email ? user.email : 'Unauthenticated', // only special properties need the $
    $last_login: new Date(), // properties can be dates...
    $first_name: user.first_name ? user.first_name : 'Unauthenticated',
    $last_name: user.last_name
      ? `${user.preposition ? `${user.preposition} ` : ''}${user.last_name}`
      : 'Unauthenticated',
    $organization_id: organization?.id,
    $organization_name: organization?.name,
    $roles: user?.roles,
    $managing_organizations: organization?.managedByOrganizations,
  })
}

export function identifyAfterRegister({ user }) {
  mixpanel.identify(user.id ? `${user.id}` : mixpanel.get_distinct_id())
}

export function pushAnalytics(pushOptions) {
  try {
    mixpanel.init('c43ae06e417ad423d4c47e654b44f1cb', {
      api_host: 'https://api.mixpanel.com',
    })
    if (
      pushOptions.component.passProps &&
      pushOptions.component.passProps.name &&
      pushOptions.component.name.includes('/product/')
    ) {
      mixpanel.track(`Opened product details`, {
        productName: pushOptions.component.passProps.name,
        productId: pushOptions.component.passProps.id,
      })
    } else if (
      pushOptions.component.passProps &&
      pushOptions.component.passProps.name &&
      pushOptions.component.name.includes('.s3.')
    ) {
      mixpanel.track(`Opened prescription`, {
        S3_URL: getURLFromPushOptions(pushOptions),
      })
    } else if (
      pushOptions.component.passProps &&
      pushOptions.component.passProps.name &&
      pushOptions.component.name.includes('/product/comparison')
    ) {
      mixpanel.track(`Opened product comparison`, {
        url: getURLFromPushOptions(pushOptions),
      })
    } else if (
      pushOptions.component.passProps &&
      pushOptions.component.passProps.name &&
      pushOptions.component.name.includes('/filter')
    ) {
      const slug = pushOptions.component.name.split('/filter/').pop()

      mixpanel.track(`Opened ${slug} filter`, {
        url: getURLFromPushOptions(pushOptions),
      })
    } else if (!getURLFromPushOptions(pushOptions).includes('/filter')) {
      mixpanel.track(`Opened URL: ${getURLFromPushOptions(pushOptions)}`)
    }
  } catch (e) {
    console.error(e)
  }
}

export function pushCustomEvent(preString, url, object) {
  try {
    mixpanel.init(
      'c43ae06e417ad423d4c47e654b44f1cb',
      {
        api_host: 'https://api.mixpanel.com',
      },
      'App opened'
    )

    mixpanel.track(
      preString ? `${preString} ${url}` : `Navigate to: ${url}`,
      object
    )
  } catch (e) {
    console.error(e)
  }
}
