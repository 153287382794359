import React, { Component } from 'react'

import { GeoJSONLayer } from 'react-mapbox-gl'

class Polygon extends Component {
  shouldComponentUpdate(nextProps) {
    if (this.props.id !== nextProps.id) {
      return true
    }
    if (this.props.fillColor !== nextProps.fillColor) {
      return true
    }
    if (this.props.fillOpacity !== nextProps.fillOpacity) {
      return true
    }
    if (this.props.shape !== nextProps.shape) {
      return true
    }
    return false
  }

  render() {
    const { fillColor, fillOpacity, shape } = this.props

    return (
      <GeoJSONLayer
        type="fill"
        // maxZoom={17}
        linePaint={{
          'line-color': '#000',
          'line-opacity': 1,
        }}
        fillPaint={{
          'fill-color': fillColor,
          'fill-opacity': fillOpacity,
        }}
        data={shape}
        // before={belowLayerID}
      />
    )
  }
}

Polygon.defaultProps = {
  // belowLayerID: 'tunnel-oneway-arrows-blue-minor',
}
export default Polygon
