import React from 'react'
import PT from 'prop-types'
import { TouchableHighlight,  StyleSheet, View } from 'react-native'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import c from '../constants'
import { Text, withTheme } from 'react-native-paper'
import fonts from '../fonts'

const styles = StyleSheet.create({
  innerRow: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 5,
  },
  containerStyle: {
    justifyContent: 'center',
  },
  text: {
    fontSize: 13,
    padding: 2,
    paddingLeft: 5,
    paddingRight: 8,

    maxWidth: 250,
    ...fonts.medium,
    color: '#222',
    letterSpacing: 0.5,
  },
  textSmall: {
    fontSize: 12,
    padding: 3,
    paddingTop: 0,
    paddingLeft: 5,
    paddingRight: 8,
    maxWidth: 250,
    marginTop: -4,
  },
  iconClose: {
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: 20,
  },
  icon: {
    // marginLeft: 6,
    // marginRight: 6,
    marginTop: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  countContainerOnIcon: {
    borderRightWidth: StyleSheet.hairlineWidth,
    borderRightColor: 'rgba(0,0,0,0.29)',
  },
  countLabel: {
    color: '#000',
    textAlign: 'center',
    padding: 5,
  },
})


const Tag = ({
  testID,
  count,
  color,
  theme,
  label,
  subLabel,
  icon,
  remove,
  onPress,
  row,
  dontRemove,
}) => {
  const countComponent = count ? (
    <View style={[styles.countContainer, icon && styles.countContainerOnIcon]}>
      <Text style={styles.countLabel}>{count}</Text>
    </View>
  ) : undefined

  const textView = (
    <View style={{ flexDirection: 'column' }}>
      <Text
        style={[styles.text, { color: color === 'dark' ? '#fff' : '#222' }]}
        numberOfLines={1}
      >
        {label}
      </Text>
      {subLabel ? (
        <Text
          style={[
            styles.textSmall,
            { color: color === 'dark' ? '#fff' : '#b5b5b5' },
          ]}
          numberOfLines={1}
        >
          {subLabel}
        </Text>
      ) : null}
    </View>
  )
  return (
    <View
      style={[
        {
          flexDirection: 'row',
          justifyContent: 'center',
          height: 38,
          borderRadius: theme.roundness,
          borderWidth: StyleSheet.hairlineWidth,
          borderColor: 'rgba(0,0,0,0.29)',
          overflow: 'hidden',
          marginRight: 16,
        },
        row ? { marginTop: 12 } : {},
      ]}
    >
      <TouchableHighlight
        onPress={onPress}
        testID={`filter_${testID}`}
        accessibilityTraits={'button'}
        accessibilityComponentType={'button'}
        accessibilityRole={'button'}
        style={[
          styles.containerStyle,
          {
            backgroundColor: color === 'dark' ? c.darkTransBgColor : '#fff',
            borderTopLeftRadius: theme.roundness,
            borderBottomLeftRadius: theme.roundness,
            borderRightWidth: dontRemove ? 0 : 1,
            borderRightColor: color === 'dark' ? 'rgba(0,0,0,0.2)' : '#EDEDED',
          },
        ]}
        underlayColor={'rgba(0,0,0,0.3)'}
      >
        <View style={styles.innerRow}>
          {icon ? (
            <Icon name={icon} style={styles.icon} size={19} color={'#000'} />
          ) : null}
          {countComponent}
          {textView}
          {dontRemove ? <View style={{ width: 6 }} /> : null}
        </View>
      </TouchableHighlight>
      {remove ? (
        <TouchableHighlight
          onPress={remove}
          testID={`filter_${testID}_remove`}
          accessibilityTraits={'button'}
          accessibilityComponentType={'button'}
          accessibilityRole={'button'}
          style={[
            styles.containerStyle,
            {
              backgroundColor: color === 'dark' ? c.darkTransBgColor : '#fff',
              borderTopRightRadius: theme.roundness,
              borderBottomRightRadius: theme.roundness,
            },
          ]}
          underlayColor={'rgba(0,0,0,0.3)'}
        >
          <Icon
            name="close"
            style={styles.iconClose}
            color={color === 'dark' ? '#fff' : '#000'}
          />
        </TouchableHighlight>
      ) : null}
    </View>
  )
}

Tag.propTypes = {
  color: PT.string.isRequired,
  label: PT.string.isRequired,
  icon: PT.string,
  count: PT.number,
  onPress: PT.func.isRequired,
  remove: PT.func,
}
Tag.defaultProps = {
  color: 'white',
}

export default withTheme(Tag)
