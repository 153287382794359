import React, { Component } from 'react'
// import PT from 'prop-types'
import { View, Animated } from 'react-native'
import { connect } from 'react-redux'
// import setPropTypes from 'recompose/setPropTypes'
import ComparisonToolbar from './ComparisonToolbar'
import ComparisonHeader from './ComparisonHeader'
import Comparison from './Comparison.js'
import Navigation from '../Navigation'

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      scrollX: new Animated.Value(0),
    }
  }
  _navigateBack = () => {
    Navigation.pop(this.props.componentId)
  }

  render() {
    const left = this.state.scrollX.interpolate({
      inputRange: [0, this.props.ids.length * 230],
      outputRange: [0, this.props.ids.length * 230],
      extrapolate: 'clamp',
    })

    return (
      <View style={{ flex: 1 }}>
        <ComparisonToolbar
          onNavigateBack={this._navigateBack}
          componentId={this.props.componentId}
          productIds={this.props.ids}
        />
        <Animated.ScrollView
          alwaysBounceHorizontal={false}
          bounces={false}
          decelerationRate={'fast'}
          contentInsetAdjustmentBehavior={'never'}
          // contentContainerStyle={{ flex: 1 }}
          horizontal
          style={{ backgroundColor: '#fff' }}
          scrollEventThrottle={1}
          onScroll={Animated.event(
            [
              {
                nativeEvent: {
                  contentOffset: { x: this.state.scrollX },
                },
              },
            ],
            { useNativeDriver: true }
          )}
        >
          <View style={{ flex: 1 }}>
            <ComparisonHeader
              left={left}
              ids={this.props.ids}
              componentId={this.props.componentId}
            />
            <Comparison
              left={left}
              ids={this.props.ids}
              componentId={this.props.componentId}
            />

            {/* Dirty hack to keep unpin and pin working. Do test pin-unpin and scrolling after removing this! */}
            {/* <Animated.View
              style={[
                { transform: [{ translateX: left }] },
                { width: 0, height: 0, position: 'absolute' },
              ]}
            /> */}
          </View>
        </Animated.ScrollView>
        {/*Fix drawer*/}
        <View
          style={{
            width: 20,
            position: 'absolute',
            top: 0,
            bottom: 0,
            height: null,
          }}
        />
      </View>
    )
  }
}

export default connect((state, props) => {
  return {
    ids: (props.ids || []).split(','),
  }
})(Index)
