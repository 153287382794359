import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Button, Divider, IconButton, Text } from 'react-native-paper'
import Fields from '../Form/Fields'
import ProductSingleSelect from '../Products/ProductSingleSelect/ProductSingleSelect'
import SingleSelect from '../Product/Pest/SingleSelect'
import { filterTypesSorted } from '../FilterType/config'
import { Translate, Translator } from 'react-translated'
import Lines2 from './Lines2'

const baseLine = {}
const fourthFields = [
  {
    name: 'note',
    label: <Translate text="note" />,
    type: 'text',
    fieldProps: {
      style: { flex: 1, marginLeft: 12, marginTop: 12, marginRight: 16 },
    },
  },
]
const thirdFields = [
  {
    name: 'amount',
    label: <Translate text="area" />,
    type: 'amount',
    fieldProps: {
      style: { flex: 1, marginTop: 12 },
    },
  },
  {
    name: 'unit',
    label: <Translate text="unit" />,
    type: 'unit',
    unitType: 'dosage',
    fieldProps: {
      style: { flex: 1, marginTop: 12 },
    },
  },
]
const secondFields = [
  {
    name: 'amount',
    label: <Translate text="compositionSubstance" />,
    type: 'amount',
    fieldProps: {
      style: { flex: 1, marginTop: 12 },
    },
  },
  {
    name: 'unit',
    label: <Translate text="unit" />,
    type: 'unit',
    unitType: 'mix',
    fieldProps: {
      style: { flex: 1, marginTop: 12 },
    },
  },
]
const fields = [
  {
    name: 'amount',
    label: <Translate text="dosageProduct" />,
    type: 'amount',
    fieldProps: {
      style: { flex: 1, marginTop: 12 },
    },
  },
  {
    name: 'unit',
    label: <Translate text="unit" />,
    type: 'unit',
    unitType: 'dosage',
    fieldProps: {
      style: { flex: 1, marginTop: 12 },
    },
  },
]
function Lines({ value, onChange, editable, next }) {
  // const [data, setData] = React.useState({ appliedProducts: value })

  const changeLines = line => (name, objectValue) => {
    onChange(
      (value || []).map(l => (l === line ? { ...l, [name]: objectValue } : l))
    )
  }
  const addLine = () => {
    onChange([...(value || []), baseLine])
  }
  const removeLine = line => () => {
    onChange((value || []).filter(l => l !== line))
  }
  const changeLine = line => (name, objectValue) => {
    onChange(
      (value || []).map(l => (l === line ? { ...l, [name]: objectValue } : l))
    )
  }
  const changeDosageLine = line => (name, objectValue) => {
    onChange(
      (value || []).map(l =>
        l === line
          ? {
              ...l,
              dosage: { ...((l && l.dosage) || {}), [name]: objectValue },
            }
          : l
      )
    )
  }
  const changeMixLine = line => (name, objectValue) => {
    onChange(
      (value || []).map(l =>
        l === line
          ? { ...l, mix: { ...((l && l.mix) || {}), [name]: objectValue } }
          : l
      )
    )
  }
  const changeAreaLine = line => (name, objectValue) => {
    onChange(
      (value || []).map(l =>
        l === line
          ? { ...l, area: { ...((l && l.area) || {}), [name]: objectValue } }
          : l
      )
    )
  }

  const selectProduct = line => product => {
    onChange((value || []).map(l => (l === line ? { ...l, product } : l)))
  }

  const selectPest = line => pest => {
    onChange((value || []).map(l => (l === line ? { ...l, pest } : l)))
  }
  const lines = value || []
  const filterType = filterTypesSorted.find(it => it.id === 'pest')
  return (
    <Translator>
      {({ translate }) => (
        <View style={styles.root}>
          {lines.map((line, index) => (
            <View
              style={[styles.col, styles.lineContainer]}
              key={`${line.product && line.product.name}_${
                line.product && line.product.wCode
              }_${index}`}
            >
              <View style={styles.full}>
                <Text style={{ fontSize: 20, padding: 16 }}>
                  {translate({ text: 'application' })} #{index + 1}
                </Text>
              </View>
              <View style={styles.rowJustifyEnd}>
                <View style={[styles.col, styles.closeContainer]}>
                  <IconButton icon={'close'} onPress={removeLine(line)} />
                </View>
              </View>
              <Divider />
              <View style={{ flexDirection: 'row' }}>
                <SingleSelect
                  singleSelect
                  style={{ flex: 1 }}
                  filterType={filterType}
                  editable={editable}
                  value={line.pest}
                  onSelect={selectPest(line)}
                />
              </View>
              <View style={[styles.row]}>
                <ProductSingleSelect
                  singleSelect
                  style={{ flex: 1 }}
                  editable={editable}
                  value={line.product}
                  onSelect={selectProduct(line)}
                  // extraParams={{ admissionCategories: [1, 2, 3, 4, 5] }}
                />
              </View>
              <View style={[styles.row, styles.secondRow]}>
                <Fields
                  fields={thirdFields}
                  values={line && line.area}
                  editable={editable}
                  onChange={changeAreaLine(line)}
                  onSubmit={next}
                />
              </View>
              <View style={[styles.row, styles.secondRow]}>
                <Fields
                  fields={fields}
                  values={line && line.dosage}
                  editable={editable}
                  onChange={changeDosageLine(line)}
                  onSubmit={next}
                />
              </View>
              <View style={[styles.row, styles.secondRow]}>
                <Fields
                  fields={secondFields}
                  values={line && line.mix}
                  editable={editable}
                  onChange={changeMixLine(line)}
                  onSubmit={next}
                />
              </View>
              <Lines2
                onChange={changeLines(line)}
                initialValue={line?.additives}
              />
              <View style={[styles.row, styles.secondRow]}>
                <Fields
                  fields={fourthFields}
                  values={line}
                  editable={editable}
                  onChange={changeLine(line)}
                  onSubmit={next}
                />
              </View>
            </View>
          ))}
          {editable ? (
            <View style={styles.bottom}>
              <Button
                icon="plus"
                onPress={addLine}
                baseLine={true}
                mode={'outlined'}
              >
                {translate({ text: 'addProduct' })}
              </Button>
            </View>
          ) : null}
        </View>
      )}
    </Translator>
  )
}

const styles = StyleSheet.create({
  root: {
    // flex: 1,
    flexDirection: 'column',
  },
  bottom: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: 16,
    paddingBottom: 16,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowJustifyEnd: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: 0,
    top: 0,
  },
  full: {
    flex: 1,
  },
  closeContainer: { padding: 8 },
  secondRow: { paddingLeft: 6 },
  lineContainer: {
    // width: '100%',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
    backgroundColor: '#fff',

    paddingTop: 0,
    paddingBottom: 16,
    marginBottom: 16,
    marginTop: 24,
    margin: 6,
    borderRadius: 5,
  },
})

Lines.defaultProps = {
  editable: true,
}

export default Lines
