import React, { Component } from 'react'
import PT from 'prop-types'
import { Text } from 'react-native-paper'

export const highlightString = (highlightPar, origStr, caseSensitive) => {
  let highlight = highlightPar
  // Sanity check
  if (!highlight || !origStr) {
    return [{ text: origStr }]
  }

  const indices = []
  let startIndex = 0
  const searchStrLen = highlight.length
  let index
  let str = origStr

  if (!caseSensitive) {
    str = str.toLowerCase()
    highlight = highlight.toLowerCase()
  }

  while ((index = str.indexOf(highlight, startIndex)) > -1) {
    if (index > 0) {
      indices.push({
        text: origStr.substring(startIndex, index),
      })
    }
    startIndex = index + searchStrLen
    indices.push({
      highlight: true,
      text: origStr.substring(index, startIndex),
    })
  }
  if (startIndex < str.length) {
    indices.push({ text: origStr.substring(startIndex, str.length) })
  }
  return indices
}

class HighlightText extends Component {
  setNativeProps(nativeProps) {
    this._root.setNativeProps(nativeProps)
  }

  render() {
    const { highlight, caseSensitive, label, highlightStyle } = this.props

    const sections = highlightString(highlight, label, caseSensitive)
    const renderedText = sections.map((section, i) => {
      const style = section.highlight === true ? highlightStyle : null

      return (
        <Text
          ref={component => (this._root = component)}
          key={`text-highlight-element-${i}`}
          style={style}
        >
          {section.text}
        </Text>
      )
    })

    return (
      <Text ref={component => (this._root = component)} {...this.props}>
        {renderedText}
      </Text>
    )
  }
}

HighlightText.propTypes = {
  highlight: PT.string,
  str: PT.string,
  caseSensitive: PT.bool,
  text: PT.string,
}

HighlightText.defaultProps = {
  highlight: null,
  str: '',
  text: '-',
  caseSensitive: false,
}
export default HighlightText
