import React, { useCallback, useMemo } from 'react'
import { StyleSheet } from 'react-native'
import FormContainer from '../../Form/FormContainer'
import safeAreaHOC from '../../WidthAndHeight/safeAreaHOC'
import { Appbar } from 'react-native-paper'
import GeneralList from '../../GeneralListFunctions/GeneralList'
// @ts-ignore
import { Translator } from 'react-translated'
import { useDispatch, useSelector } from 'react-redux'
import { useActionsAsFunctions } from '../../helpers/actions'
import actions from '../../OrganizationField/actions'
import a from '../actions'
import PageHeader from '../../PageHeader'
import SafeListItem from '../../WidthAndHeight/SafeListItem'
import SelectionComponent from '../../components/SelectionComponent'
import { fromTimestamp, getFriendlyDate } from '../../dayjs'
import { getLanguage } from '../../language'

function GrowingPeriodSelectScreen({
  prev,
  next,
  componentId,
}: {
  prev: () => any
  next: () => any
  componentId: string
}) {
  const extraParams = useMemo(
    () => ({
      preloadAll: true,
      sort: 'name',
    }),
    []
  )
  const list = useSelector((state: any) => state.organizationField.list)
  const crud = useSelector((state: any) => state.observation.crud)
  const dispatch = useDispatch()

  const select = useCallback(
    (item: any) => {
      dispatch(
        a.setData({
          ...(crud.object || {}),
          growingPeriod: item,
          date: Math.round(new Date().getTime()),
          samples: [
            { type: 'pest', isOpen: true },
            { type: 'beneficialOrganism', isOpen: true },
          ],
        })
      )
      next()
    },
    [dispatch, crud, next]
  )

  const language = getLanguage()
  const renderItem = useCallback(
    ({ item }) => {
      const isSelected =
        crud &&
        crud.object &&
        crud.object.growingPeriod &&
        crud.object.growingPeriod.id === item.id

      const { crop } = item
      return (
        <SafeListItem
          title={`${crop && crop.name}`}
          onPress={() => select(item)}
          description={
            language === 'nl'
              ? `Zaaidatum: ${getFriendlyDate(
                  fromTimestamp(item.startDate)
                )} - Oogstdatum: ${getFriendlyDate(
                  fromTimestamp(item.endDate)
                )}`
              : `Sowing date: ${getFriendlyDate(
                  fromTimestamp(item.startDate)
                )} - Harvest date: ${getFriendlyDate(
                  fromTimestamp(item.endDate)
                )}`
          }
          left={
            <SelectionComponent
              selectType={'radiobutton'}
              status={isSelected ? 'checked' : 'unchecked'}
              onPress={() => select(item)}
            />
          }
          accessibilityLabel={`Open ${item.name}`}
          to={''}
          style={styles.listItem}
        />
      )
    },
    [select, crud, language]
  )
  const dispatchedActions = useActionsAsFunctions(actions)
  const selectedField =
    list &&
    list.data.find(
      (it: any) =>
        crud &&
        crud.object &&
        crud.object.field &&
        crud.object.field.id === it.id
    )
  return (
    <FormContainer onSubmit={next}>
      <>
        <Translator>
          {({
            translate,
          }: {
            translate: ({ text }: { text: string }) => string
          }) => (
            <GeneralList
              searchPlaceholder={translate({ text: 'searchGrowingPeriods' })}
              renderItem={renderItem}
              icon
              extraParams={extraParams}
              loadWhenLoadingList
              list={{
                ...list,
                data: (selectedField && selectedField.growingPeriods) || [],
                loadingList: false,
              }}
              selected={[]}
              appbarContent={
                <PageHeader
                  back={() => prev()}
                  componentId={componentId}
                  title={
                    <Appbar.Content
                      title={translate({ text: 'growingPeriods' })}
                    />
                  }
                />
              }
              {...dispatchedActions}
              componentId={componentId}
            />
          )}
        </Translator>
      </>
    </FormContainer>
  )
}

const styles = StyleSheet.create({
  listItem: {},
  paddingScroll: {
    paddingBottom: 200,
  },
  fieldsMapContainer: {
    height: 350,
    borderRadius: 8,
    margin: 16,
    overflow: 'hidden',
  },
})

export default safeAreaHOC(GrowingPeriodSelectScreen)
