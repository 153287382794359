import React, { useCallback } from 'react'
import FarmersListForAdvisor from './FarmersListForAdvisor'
import * as routes from '../../Routes.config'
import { push } from '../../Navigation'

export default function FarmerListScreen({ componentId }) {
  const onPressItem = useCallback(
    (item) =>
      push(componentId, {
        component: {
          name: routes.ADVISOR_FARMER_ROUTE,
          passProps: {
            id: item.id,
          },
        },
      }),
    [componentId]
  )
  return (
    <FarmersListForAdvisor
      componentId={componentId}
      onPressItem={onPressItem}
      onChange={onPressItem}
    />
  )
}
