import React, { useEffect, useRef, useState } from 'react'
import { StyleSheet, View } from 'react-native'

import { Button } from 'react-native-paper'
// @ts-ignore
import changePasswordHOC from 'react-auth/lib/hocs/changePasswordHOC'
import Agro4allLogo from '../vectors/Agro4allLogo'
import Fields from '../Form/Fields'
import base64 from 'base-64'
import AppbarHeader from '../WidthAndHeight/AppbarHeader'
import ErrorMessages from '../components/ErrorMessages'
import { pushHome } from '../Navigation'
import { connect } from 'react-redux'

const styles = StyleSheet.create({
  loginContainer: {
    flex: 1,
    maxWidth: 700,
    width: '100%',
    alignSelf: 'center',
  },

  loginForm: {
    maxWidth: 400,
    width: '100%',
    alignSelf: 'center',
    padding: 10,
    paddingTop: 60 * 2,
  },
  buttonBox: {
    marginTop: 16,
  },
  header: {
    alignItems: 'center',
    justifyContent: 'center',
  },
})
const fieldProps = {
  style: { marginBottom: 16 },
}
const fields = [
  {
    name: 'email',
    label: 'Emailadres',
    type: 'email',
    fieldProps: { ...fieldProps, disabled: true },
  },
  {
    name: 'password',
    label: 'Wachtwoord',
    type: 'password',
    fieldProps: { ...fieldProps, autoFocus: true },
  },
  {
    name: 'passwordCheck',
    label: 'Bevestig wachtwoord',
    type: 'password',
    fieldProps: { ...fieldProps },
  },
]

export function usePrevious<T>(value: T) {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

type ChangeScreenPropsType = {
  match: any
  secret: string
  onChangeField: (key: string, value: any) => any
  onLogin: ({ login, password }: { login: string; password: string }) => any
  completed: boolean
  values: {
    email: string
    password: string
    passwordCheck: string
  }
  onSubmit: () => any
  sending: boolean
  error: boolean
  authenticated: boolean
}

type ErrorType = { name: string; message: string }
function ChangeScreen(props: ChangeScreenPropsType) {
  const {
    match,
    secret,
    onChangeField,
    completed,
    onLogin,
    values,
    onSubmit,
    sending,
    error,
    authenticated,
  }: ChangeScreenPropsType = props
  const [hidePassword, setHidePassword] = useState(true)
  const [errors, setErrors] = useState<ErrorType[]>([])
  useEffect(() => {
    const encodedSecret = match?.params?.secret || secret

    if (encodedSecret) {
      const decodedSecret = base64.decode(encodedSecret)
      const re = new RegExp('(.*)-(.[0-9]*)-(.*)')
      const matches = re.exec(decodedSecret)
      console.log(matches)

      const email = matches ? matches[1] : ''
      const secret = matches ? matches[3] : ''

      onChangeField('email', email)
      onChangeField('secret', secret)
    }
  }, [match, onChangeField, secret])
  const oldCompleted = usePrevious(completed)
  const oldAuthenticated = usePrevious(authenticated)

  useEffect(() => {
    if (completed !== oldCompleted) {
      console.log(values)
      onLogin({
        login: values.email,
        password: values.password,
      })
    }
  }, [onLogin, completed, oldCompleted, values])

  useEffect(() => {
    if (authenticated !== oldAuthenticated && completed) {
      pushHome(props)
    }
  }, [authenticated, oldAuthenticated, props, completed])

  const submit = () => {
    if (!values.password || values.password.length === 0) {
      const e: ErrorType = {
        name: 'password',
        message: 'Dit veld is verplicht',
      }
      setErrors(prev => [...prev, e])
    }
    if (!values.passwordCheck || values.passwordCheck.length === 0) {
      const e: ErrorType = {
        name: 'passwordCheck',
        message: 'Dit veld is verplicht',
      }
      setErrors(prev => [...prev, e])
    }
    if (values.password !== values.passwordCheck) {
      const e: ErrorType = {
        name: 'passwordCheck',
        message: 'Wachtwoord komt niet overeen',
      }
      setErrors(prev => [...prev, e])
    } else {
      onSubmit()
    }
  }

  const change = (name: string, value: string) => {
    setErrors([])
    onChangeField(name, value)
  }

  return [
    <AppbarHeader key="header" style={styles.header}>
      <Agro4allLogo />
    </AppbarHeader>,
    <View style={styles.loginContainer} key="content">
      <View style={styles.loginForm}>
        {error && (
          <ErrorMessages
            messages={[
              'Er is iets fout gegaan bij het wijzigen van uw wachtwoord. Neem contact op met support@agro4all.com.',
            ].filter(n => !!n)}
          />
        )}
        <Fields
          fields={fields}
          values={values || {}}
          onChange={change}
          onSubmit={submit}
          errors={errors}
          hidePassword={hidePassword}
          toggleHidePassword={() => setHidePassword(!hidePassword)}
        />

        <View style={styles.buttonBox}>
          <Button
            loading={sending}
            onPress={submit}
            mode={'contained'}
            uppercase={false}
            dark={true}
          >
            {sending ? 'Aan het inloggen..' : 'Wachtwoord opslaan'}
          </Button>
        </View>
      </View>
    </View>,
  ]
}

const mapStateToProps = (state: any) => ({
  authenticated: state.auth.authenticated,
})

// @ts-ignore
export default changePasswordHOC(connect(mapStateToProps)(ChangeScreen))
