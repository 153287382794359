import { Linking, ScrollView, View } from 'react-native'
import { Text } from 'react-native-paper'
import React, { useEffect } from 'react'
import { SimpleBackgroundHeader } from './GeneralListFunctions/GeneralListHeader'
import PageHeader from './PageHeader'
// @ts-ignore
import { logout } from 'react-auth/lib/actions/authentication'
import Resizer from './WidthAndHeight/Resizer'
import { useDispatch, useSelector } from 'react-redux'
import { push } from './Navigation'
import * as routes from './Routes.config'

function RedirectWithFilters({ componentId }: { componentId: string }) {
  const dispatch = useDispatch()
  const authUser = useSelector((state: any) => state.auth && state.auth.user)
  const innerLogout = () => {
    dispatch(logout())
  }
  useEffect(() => {
    console.log(authUser)
    if (authUser && authUser.user) {
      if (
        authUser.user.roles &&
        authUser.user.roles.length > 0 &&
        authUser.user.roles.find(
          (role: { name: string }) => role.name === 'user'
        )
      ) {
        push(componentId, {
          component: {
            name: routes.PRODUCTS_ROUTE,
          },
        })
      }
    }
  }, [authUser, componentId])
  return (
    <>
      <SimpleBackgroundHeader
        key={`SimpleBackgroundHeader}`}
        absolute={false}
        showSearch={false}
        appbarContent={
          <PageHeader
            componentId={componentId}
            title={''}
            back={() => innerLogout() as any}
            withoutOnPressLogo={true}
          />
        }
        zIndex
      />
      <ScrollView style={{ paddingTop: 16 }}>
        <Resizer style={{ flex: 1 }}>
          <View>
            <Text
              style={{ flexDirection: 'row' }}
              onPressIn={undefined}
              onPressOut={undefined}
              android_hyphenationFrequency={undefined}
            >
              Je account heeft niet de juiste rechten om gebruik te maken van
              Agro4All. Neem hiervoor contact op met{' '}
              <Text
                onPressIn={undefined}
                onPressOut={undefined}
                android_hyphenationFrequency={undefined}
                onPress={() => Linking.openURL(`mailto:support@agro4all.com`)}
                style={{ textDecorationLine: 'underline', color: '#44ad4d' }}
              >
                support@agro4all.com
              </Text>
              .
            </Text>
          </View>
        </Resizer>
      </ScrollView>
    </>
  )
}
export default RedirectWithFilters
