import * as React from 'react'
import Polygon from '../Map/Polygon'
import { useSelector } from 'react-redux'
import { getCenterFromField } from '../Map/helpers'

import { GeoJSONLayer } from 'react-mapbox-gl'

const symbolLayout = {
  'text-field': '{label}',
  'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
  // 'text-offset': [0, 0.6],
  // 'text-anchor': 'top',
  'text-allow-overlap': true,
  'text-justify': 'center',
}
const symbolPaint = {
  'text-color': '#FFF',
  'text-halo-color': '#000',
  'text-halo-width': 1,
}

// const circleLayout = { visibility: 'visible' }
// const circlePaint = {
//   'circle-color': 'white',
// }

function FieldsOrganizationPolygons() {
  console.log('re-render FieldsOrganizationPolygons')
  const data = useSelector(state => state.fieldOrganizationMap.data)
  const fields = useSelector(state => state.fieldOrganizationMap.fieldData)

  const fieldsWithLabel = React.useMemo(() => {
    return (fields || []).map(f => ({
      id: f.id,
      label: f?.growingPeriods?.find(gp => gp?.contract?.id)?.contract?.number,
      center: getCenterFromField(f),
    }))
  }, [fields])

  const geoJSON = React.useMemo(() => {
    return {
      type: 'FeatureCollection',
      features: fieldsWithLabel.map(({ id, center, label }) => ({
        type: 'Feature',
        ...center,
        properties: {
          id,
          label,
        },
      })),
    }
  }, [fieldsWithLabel])
  console.log(data)
  return (
    <>
      {data.customField
        ? data.customField.map(cf => (
            <React.Fragment key={'field_border'}>
              <Polygon
                id={'field_border'}
                shape={cf}
                fillColor={'#1DA1F2'}
                fillOpacity={0.5}
                strokeWeight={2}
                strokeColor={'#000000'}
                zIndex={2}
              />

              <GeoJSONLayer
                data={geoJSON}
                // circleLayout={circleLayout}
                // circlePaint={circlePaint}
                symbolLayout={symbolLayout}
                symbolPaint={symbolPaint}
              />
            </React.Fragment>
          ))
        : data.field && (
            <React.Fragment key={'field_border'}>
              <Polygon
                id={'field_border'}
                shape={data.field}
                fillColor={'#1DA1F2'}
                fillOpacity={0.5}
                strokeWeight={2}
                strokeColor={'#000000'}
                zIndex={2}
              />

              <GeoJSONLayer
                data={geoJSON}
                // circleLayout={circleLayout}
                // circlePaint={circlePaint}
                symbolLayout={symbolLayout}
                symbolPaint={symbolPaint}
              />
            </React.Fragment>
          )}
    </>
  )
}

export default React.memo(FieldsOrganizationPolygons)
