import React, { Component } from 'react'
import loginHOC from 'react-auth/lib/hocs/loginHOC'
import { StyleSheet, View } from 'react-native'
import { Button, Text } from 'react-native-paper'
import Agro4allLogo from '../vectors/Agro4allLogo'
import AppbarHeader from '../WidthAndHeight/AppbarHeader'
import { push } from '../Navigation'
import { AUTH_SCREEN_ROUTE, HOME_ROUTE } from '../Routes.config'
import { setMessages } from '../Notifications/actions'
import { connect } from 'react-redux'

const styles = StyleSheet.create({
  loginContainer: {
    flex: 1,
    maxWidth: 700,
    width: '100%',
    alignSelf: 'center',
  },
  loginForm: {
    maxWidth: 400,
    width: '100%',
    alignSelf: 'center',
    padding: 10,
    paddingTop: 60 * 2,
  },
  buttonBox: {
    marginTop: 16,
  },

  errorBox: {
    backgroundColor: 'red',
    padding: 8,
    borderRadius: 10,
  },
})
class ConnectedLogin extends Component {
  constructor(props) {
    super(props)

    this.props.onSubmit()
    this.state = {
      error: '',
    }
  }
  componentDidMount() {
    let { url } = this.props
    if (!url) {
      url = this.props.location.search
    }
    const regex = url.match(/code=([^&]*)/)
    const code = regex[1]

    if (code && code.length > 3) {
      this.props.authStart({ code })
      push(this.props.componentId, {
        component: {
          name: HOME_ROUTE,
        },
      })
    } else {
      this.setState({
        error: 'Er is iets mis gegaan met het inloggen via Microsoft.',
      })
    }
  }

  render() {
    const { sending } = this.props
    return [
      <AppbarHeader
        key="header"
        style={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Agro4allLogo />
      </AppbarHeader>,
      <View style={styles.loginContainer} key="content">
        <View style={styles.loginForm}>
          {this.props.error !== false && (
            <View style={styles.errorBox}>
              <Text
                style={{
                  color: '#FFF',
                }}
              >
                {this.props.error}
              </Text>
            </View>
          )}
          <View style={styles.buttonBox}>
            <Button
              uppercase={false}
              loading={sending}
              onPress={() => {
                push(this.props.componentId, {
                  component: {
                    name: AUTH_SCREEN_ROUTE,
                  },
                })
              }}
            >
              {sending ? 'Inloggen met Microsoft..' : 'Terug naar Login pagina'}
            </Button>
          </View>
        </View>
      </View>,
    ]
  }
}
const mapDispatchToProps = (dispatch) => ({
  setMessages,
  dispatch,
})

export default connect(null, mapDispatchToProps)(loginHOC(ConnectedLogin))
