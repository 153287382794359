import { StyleSheet } from 'react-native'
export const descriptionColor = 'rgba(0, 0, 0, 0.54)'
const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  container: {
    // padding: 8,
    flex: 1,
  },
  icon: {
    marginLeft: 10,
    marginRight: 10,
    padding: 0,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  title: {
    fontSize: 16,
    color: 'rgba(0, 0, 0, 0.87)',
  },
  description: {
    fontSize: 14,
    color: descriptionColor,
  },
  item: {
    padding: 8,
  },
  content: {
    flex: 1,
    justifyContent: 'center',
  },
})

export default styles
