import * as React from 'react'
import { View, StyleSheet } from 'react-native'
import { Switch, TouchableRipple, Text } from 'react-native-paper'

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    height: 56,
    alignItems: 'center',
  },
  labelButton: {
    flex: 1,
  },
  label: {
    padding: 8,
    marginLeft: 6,
  },
})

class SwitchWithLabel extends React.Component {
  _toggle = () => {
    this.props.onChange(!this.props.value)
  }

  render() {
    const { value, label } = this.props
    return (
      <View style={styles.root}>
        <Switch value={value} onValueChange={this._toggle} />
        <TouchableRipple
          onPress={this._toggle}
          rippleColor="rgba(0, 0, 0, .12)"
          styles={styles.labelButton}
        >
          <Text style={styles.label}>{label}</Text>
        </TouchableRipple>
      </View>
    )
  }
}

export default SwitchWithLabel
