import React from 'react'
import { useSelector } from 'react-redux'
import actions from './actions'
import { useActionsAsFunctions } from '../../helpers/actions'
import GeneralList from '../../GeneralListFunctions/GeneralList'
import ItemInList from '../ItemInList'
import FilterHeader from '../FilterHeader'

import { FilterType } from '../config'
import { RootState } from '../../reducers'
import { SprayDirectionType } from './reducer'
import Navigation from '../../Navigation'
import { getLanguage } from '../../language'

export default function SprayDirectionList({
  componentId,
  filterType,
}: {
  componentId: string
  filterType: FilterType
}) {
  const lang = getLanguage() || 'en'
  console.log({ lang })
  const selected = useSelector(
    (state: RootState) => state.filter.sprayDirection.selected
  )
  let list = useSelector((state: RootState) => state.filter.sprayDirection.list)

  list.data = [
    { id: 'DOWNWARDS', name: lang === 'en' ? 'Downwards' : 'Neerwaarts' },
    {
      id: 'UP_AND_SIDEWAYS',
      name: lang === 'en' ? 'Up and sideways' : 'Op- en zijwaarts',
    },
  ]

  list.loadedList = true

  const actionAsDispatchers = useActionsAsFunctions(actions)

  const renderItem = ({ item }: { item: SprayDirectionType }) => {
    return (
      <ItemInList
        onPress={(s: SprayDirectionType) => {
          actionAsDispatchers.select(s, { singleSelect: true })
          Navigation.pop(componentId)
        }}
        onSelect={(s: SprayDirectionType) => {
          actionAsDispatchers.select(s, { singleSelect: true })
          Navigation.pop(componentId)
        }}
        item={item}
        filterType={filterType}
        isSelected={selected.some(it => it.id === item.id)}
        selectType="radiobutton"
      />
    )
  }

  return (
    <GeneralList<SprayDirectionType>
      list={list}
      selected={selected}
      componentId={componentId}
      searchPlaceholder={filterType.searchPlaceholder}
      renderItem={renderItem}
      icon
      showSearch={false}
      appbarContent={
        <FilterHeader filterType={filterType} componentId={componentId} />
      }
      {...actionAsDispatchers}
    />
  )
}
