import { Dimensions, Platform } from 'react-native'

export function isIphoneX(): boolean {
  let dimensions = Dimensions.get('window')
  return (
    Platform.OS === 'ios' &&
    !Platform.isPad &&
    !Platform.isTVOS &&
    (dimensions.height === 812 || dimensions.width === 812)
  )
}

const constants = {
  boxOffset: 24,
  textOffset: 16,
  inputHeight: 44,
  itemsPerPage: 100,
  staticUrl:
    process.env.REACT_APP_S3_ENDPOINT ||
    'https://s3.eu-central-1.amazonaws.com/nl-static-agro4all-com',

  termsAndConditionsUrl:
    'https://nl-static-agro4all-com.s3.eu-central-1.amazonaws.com/common/disclaimer.pdf',

  eppoUrl: process.env.REACT_APP_EPPO_ENDPOINT || 'https://gd.eppo.int/taxon/',

  knpvOrganizationID: process.env.REACT_APP_KNPV_ORGANIZATION_ID || '977',

  dataIssueEmailAddress: 'support+data-issue@agro4all.com',

  greenColor: '#179a42',
  blueColor: '#08325C',
  orangeColor: '#f67000',
  yellowColor: '#ff9d00',
  redColor: '#ff0000',
  defaultFontColor: '#222',
  lightFontColor: '#595959',
  lightBorderColor: '#E6E6E6',
  lightBgColor: '#F3F3F3',
  darkTransBgColor: 'rgba(0, 0, 0, 0.22)',
  defaultIconColor: '#FFF',
  lightIconColor: '#999',
  breakpoint1: 480,
  breakpoint2: 768,
  imagePath: 'images/',

  APPBAR_HEIGHT: Platform.OS === 'ios' ? 48 : 56,
  HEADER_HEIGHT: 0,
  HEADER_WITH_SEARCH_HEIGHT: 0,
}

constants.HEADER_HEIGHT = constants.APPBAR_HEIGHT
constants.HEADER_WITH_SEARCH_HEIGHT =
  constants.inputHeight + constants.HEADER_HEIGHT

export default constants
