import React, { useCallback, useEffect, useMemo } from 'react'
import { connect, useDispatch } from 'react-redux'
import { StyleSheet } from 'react-native'
import actions from './List/actions'
import cA from './actions'
import UserItem from './UserItem'
import List from '../../../GeneralListFunctions/List'
import BottomFab from '../../../WidthAndHeight/BottomFab'
import widthAndHeightHOC from '../../../WidthAndHeight/widthAndHeightHOC'
import { Translate, Translator } from 'react-translated'

function UserList({
  list,
  organizationId,
  componentId,
  add,
  height,
  createdUser,
  ...rest
}) {
  const dispatch = useDispatch()
  const extraParams = useMemo(() => {
    return {
      limit: 1000,
      number: 1000,
      'organizationIds[]': organizationId,
      test: true,
    }
  }, [organizationId])
  useEffect(() => {
    if (createdUser) {
      dispatch(actions.loadList({ params: extraParams }))
      dispatch(cA.reset())
    }
  }, [createdUser, dispatch, extraParams])
  const renderItem = useCallback(
    ({ item }) => {
      return (
        <UserItem
          item={item}
          componentId={componentId}
          organizationId={organizationId}
        />
      )
    },
    [componentId, organizationId]
  )

  return (
    <>
      <List
        {...rest}
        list={list}
        renderItem={renderItem}
        extraParams={extraParams}
        silentReloading={false}
        reloadIfAlreadyLoaded
        customEmptyTitle={<Translate text="noUsers" />}
        customEmptyDescription={<Translate text="addNewUser" />}
      />

      <Translator>
        {({ translate }) => (
          <BottomFab
            icon={'plus'}
            label={translate({ text: 'addUser' })}
            onPress={add}
          />
        )}
      </Translator>
    </>
  )
}

// eslint-disable-next-line
const styles = StyleSheet.create({})
const mapStateToProps = state => ({
  list: state.farmer.userList.list,
  createdUser: state.farmer.user.crud.created,
})

export default widthAndHeightHOC(connect(mapStateToProps, actions)(UserList))
