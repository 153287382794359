import React from 'react'
import { Appbar } from 'react-native-paper'
import safeAreaHOC from './safeAreaHOC'

function AppbarHeader({ safe, style, children, ...rest }) {
  return (
    <Appbar
      style={[
        {
          height: 56 + safe.top,
          paddingTop: safe.top,
          paddingLeft: safe.left + 6,
          paddingRight: safe.right + 6,
        },
        style,
      ]}
      {...rest}
    >
      {children}
    </Appbar>
  )
}
export default safeAreaHOC(AppbarHeader)
