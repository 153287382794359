import React, { useCallback } from 'react'
import { Keyboard, StyleSheet, TouchableOpacity } from 'react-native'
import { useDispatch } from 'react-redux'
import { setMessages } from '../Notifications/actions'
import { pushCustomEvent } from '../Analytics/abstraction'
import { getItemLabel } from './helper'
import { secondsToDDMMYYYY } from '../helpers/date'
import ExemptionIcon from '../vectors/Exemption'
import WarningIcon from '../vectors/Warning'

const messageMap = {
  kug: `Dit betreft een KUG-toepassing; let op, het gebruik is voor eigen risico!`,
  exemption: `Dit product is vrijgesteld voor gebruik vanaf `,
}

const iconMap = {
  kug: WarningIcon,
  exemption: ExemptionIcon,
}

const StatusComponent = ({ status, product, match }) => {
  const dispatch = useDispatch()
  const message =
    (product || match) && status === 'exemption'
      ? match
        ? messageMap[status] +
          secondsToDDMMYYYY(match.matchStartDate) +
          ' t/m ' +
          secondsToDDMMYYYY(match.matchEndDate) +
          '.'
        : messageMap[status] +
          secondsToDDMMYYYY(product.matchStartDate) +
          ' t/m ' +
          secondsToDDMMYYYY(product.matchEndDate) +
          '.'
      : messageMap[status]
  const Icon = iconMap[status]

  const onPress = useCallback(() => {
    Keyboard.dismiss()
    dispatch(setMessages([message]))
    if (match) {
      pushCustomEvent(
        'Pressed match status icon on match crop:',
        `${getItemLabel(match)}`
      )
    } else if (product) {
      pushCustomEvent(
        'Pressed match status icon on product:',
        `${getItemLabel(product)}`
      )
    }
  }, [dispatch, product, match, message])

  if (!message || !Icon) {
    return null
  }
  return (
    <TouchableOpacity onPress={onPress} style={styles.icon} >
      <Icon color={'blue'} />
    </TouchableOpacity>
  )
}

export default ({ status, product, match }) => {
  if (!status || status === 'unknown' || status === 'normal') {
    return null
  }
  if (status === 'kug_or_exemption') {
    return [
      <StatusComponent
        key="kug"
        status={'kug'}
        product={product}
        match={match}
      />,
      <StatusComponent
        key="exemption"
        status={'exemption'}
        product={product}
        match={match}
      />,
    ]
  }
  return <StatusComponent status={status} product={product} match={match} />
}

const styles = StyleSheet.create({
  icon: {
    width: 24,
    height: 24,
    margin: 12,
  },
})
