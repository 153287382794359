import React, { useCallback } from 'react'
import { Platform, ScrollView, StyleSheet, View } from 'react-native'
import { List } from 'react-native-paper'
import { useSelector } from 'react-redux'
import idx from 'idx'
import { closeMenu, push } from './Navigation'
import * as routes from './Routes.config'
import { menuOptions } from './helpers/role'
import { useSafeArea } from './WidthAndHeight/safeAreaHook'
import { RootState } from './reducers'
// @ts-ignore
import { Translator } from 'react-translated'
import CompanyIcon from './vectors/Company'
import DistributionIcon from './vectors/Distribution'
import LeafIcon from './vectors/Leaf'
import ObservationIcon from './vectors/Observation'
import ParcelIcon from './vectors/Parcel'
import MapIcon from './vectors/Map'
import ProductIcon from './vectors/Product'
import Agro4allLogo from './vectors/Agro4allLogo'
import RegistrationIcon from './vectors/Registration'

// do not enable width since this is the native platform
// at least do not enable this on Android
const nativeWidth = Platform.select({
  android: undefined,
  ios: 280,
  web: 280,
})

const styles = StyleSheet.create({
  root: Platform.select({
    web: {
      height: '100vh',
      backgroundColor: '#2d1c5d',
      color: '#FFF',
      width: 280,
    },
    default: {
      backgroundColor: '#2d1c5d',
      color: '#FFF',
      width: nativeWidth,
    },
  }),
  contentContainerStyle: {
    flexGrow: 1,
  },
  inner: {
    maxWidth: nativeWidth,
    width: nativeWidth,
    alignItems: 'center',
    flex: 1,
    padding: 6,
    // backgroundColor: 'pink',
  },
  logo: {
    maxHeight: 100,
    width: 200,
  },
  icon: {
    width: 24,
    height: 24,
    marginRight: 12,
    marginTop: 4,
    // tintColor: '#666666',
    // alignSelf: 'center',
    // justifySelf: 'center',
  },
  content: {
    // marginTop: 150,
    // marginBottom: 30,
    // marginLeft: 16,
    flexDirection: 'column',
    width: '100%',
    // backgroundColor: 'lightgreen',
    flex: 1,
    // alignItems: 'center',
    justifyContent: 'center',
  },
  item: { paddingTop: 15, paddingBottom: 15, width: '100%', color: '#FFF' },
  statusBar: {
    backgroundColor: 'rgba(0,0,0,0.2)',
    width: '100%',
  },
})

function SideMenu() {
  const { safePadding, safe } = useSafeArea()

  const user = useSelector(
    // @ts-ignore
    (state: RootState) => idx(state, _ => _.auth.user.user) || {}
  )

  // Returns the menu items based on the roles of the user
  const options = menuOptions(user)

  const onPress = useCallback(route => {
    push('AppHomeStack', {
      component: {
        name: route,
      },
    })
    closeMenu()
  }, [])

  console.log({ options })
  // @ts-ignore
  return (
    <Translator>
      {({ translate }: { translate: any }) => (
        <ScrollView
          style={styles.root}
          // automaticallyAdjustContentInsets={false}
          contentContainerStyle={[
            styles.contentContainerStyle,
            safePadding,
            { paddingRight: 0, paddingTop: 0 },
          ]}
        >
          {Platform.OS === 'android' && (
            <View style={[styles.statusBar, { height: safe.top }]} />
          )}
          <View style={[styles.inner]}>
            <View
              style={{
                width: 568 / 3 + 6,
                height: 75 / 3,
                marginTop: 18,
              }}
            >
              <Agro4allLogo />
            </View>
            <View style={styles.content}>
              {(options.includes('products') || options.length === 0) && (
                <List.Item
                  testID="productSideMenu"
                  style={styles.item}
                  titleStyle={{ color: '#FFF' }}
                  title={translate({ text: 'products' })}
                  onPress={() => onPress(routes.PRODUCTS_ROUTE)}
                  left={() => (
                    <View style={styles.icon}>
                      <ProductIcon />
                    </View>
                  )}
                />
              )}
              {options.includes('knpv') && (
                <List.Item
                  testID="knpvSideMenu"
                  style={styles.item}
                  titleStyle={{ color: '#FFF' }}
                  title={translate({ text: 'knpvSideMenu' })}
                  onPress={() => onPress(routes.KNPV_LIST_ROUTE)}
                  left={() => (
                    <View style={styles.icon}>
                      <ObservationIcon />
                    </View>
                  )}
                />
              )}
              {/*{options.includes('advice') && (*/}
              {/*  <List.Item*/}
              {/*    testID="adviceSideMenu"*/}
              {/*    style={styles.item}*/}
              {/*    titleStyle={{ color: '#FFF' }}*/}
              {/*    title={translate({ text: 'advice' })}*/}
              {/*    onPress={() => onPress(routes.ADVICE_WIZARD_ROUTE)}*/}
              {/*    left={() => (*/}
              {/*      <View style={styles.icon}>*/}
              {/*        <AdviceIcon />*/}
              {/*      </View>*/}
              {/*    )}*/}
              {/*  />*/}
              {/*)}*/}
              {/*{options.includes('registrations') && (*/}
              {/*  <List.Item*/}
              {/*    style={styles.item}*/}
              {/*    titleStyle={{color: '#FFF'}}*/}
              {/*    title={translate({text: 'registrations'})}*/}
              {/*    onPress={() => onPress(routes.LIST_REGISTRATION_ROUTE)}*/}
              {/*    left={() => (*/}
              {/*      <View style={styles.icon}>*/}
              {/*        <RegistrationIcon/>*/}
              {/*      </View>*/}
              {/*    )}*/}
              {/*  />*/}
              {/*)}*/}
              {options.includes('registrations') && (
                <List.Item
                  style={styles.item}
                  titleStyle={{ color: '#FFF' }}
                  title={translate({ text: 'registrations' })}
                  left={() => (
                    <View style={styles.icon}>
                      <RegistrationIcon />
                    </View>
                  )}
                  onPress={() => onPress(routes.REGISTRATION_ROUTE)}
                />
              )}
              {/*{options.includes('observations') && (*/}
              {/*  <List.Item*/}
              {/*    style={styles.item}*/}
              {/*    titleStyle={{ color: '#FFF' }}*/}
              {/*    title={translate({ text: 'observations' })}*/}
              {/*    left={() => (*/}
              {/*      <View style={styles.icon}>*/}
              {/*        <ObservationIcon />*/}
              {/*      </View>*/}
              {/*    )}*/}
              {/*    onPress={() => onPress(routes.OBSERVATION_ROUTE)}*/}
              {/*  />*/}
              {/*)}*/}
              {options.includes('crops') && (
                <List.Item
                  style={styles.item}
                  titleStyle={{ color: '#FFF' }}
                  title={translate({ text: 'cropsSideMenu' })}
                  onPress={() => onPress(routes.GROUPED_CROP_ROUTE)}
                  left={() => (
                    <View style={styles.icon}>
                      <LeafIcon />
                    </View>
                  )}
                />
              )}
              {options.includes('organizationFields') && (
                <List.Item
                  style={styles.item}
                  titleStyle={{ color: '#FFF' }}
                  title={translate({ text: 'parcels' })}
                  onPress={() => onPress(routes.FIELDS_ROUTE)}
                  left={() => (
                    <View style={styles.icon}>
                      <ParcelIcon />
                    </View>
                  )}
                />
              )}
              {options.includes('parcels') && (
                <List.Item
                  style={styles.item}
                  titleStyle={{ color: '#FFF' }}
                  title={translate({ text: 'parcels' })}
                  onPress={() =>
                    onPress(routes.ORGANIZATION_FIELDS_ON_MAP_ROUTE)
                  }
                  left={() => (
                    <View style={styles.icon}>
                      <MapIcon />
                    </View>
                  )}
                />
              )}
              {options.includes('organizations') && (
                <List.Item
                  style={styles.item}
                  titleStyle={{ color: '#FFF' }}
                  title={translate({ text: 'farmers' })}
                  onPress={() => onPress(routes.ADVISOR_FARMERS_ROUTE)}
                  left={() => (
                    <View style={styles.icon}>
                      <CompanyIcon />
                    </View>
                  )}
                />
              )}
              {options.includes('distributions') && (
                <List.Item
                  style={styles.item}
                  titleStyle={{ color: '#FFF' }}
                  title={translate({ text: 'distributions' })}
                  onPress={() => onPress(routes.DISTRIBUTION_LIST_ROUTE)}
                  left={() => (
                    <View style={styles.icon}>
                      <DistributionIcon />
                    </View>
                  )}
                />
              )}
            </View>
          </View>
        </ScrollView>
      )}
    </Translator>
  )
}

export default SideMenu
