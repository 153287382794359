import React, { useCallback, useMemo } from 'react'
import PageHeader from '../../PageHeader'
import DeepLink from '../../DeepLink'
import GeneralList from '../../GeneralListFunctions/GeneralList'
import { Appbar, RadioButton } from 'react-native-paper'
import { useDispatch, useSelector } from 'react-redux'
import { Translator } from 'react-translated'
import { useActionsAsFunctions } from '../../helpers/actions'
import actions from '../../Observations/actions'
import { Platform, View } from 'react-native'
import Navigation, { push } from '../../Navigation'
import SafeListItem from '../../WidthAndHeight/SafeListItem'
import { fromTimestamp, getFriendlyDate } from '../../dayjs'
import * as routes from '../../Routes.config'
import a from './actions'

function ObservationList(props) {
  const actionsAsFunction = useActionsAsFunctions(actions)
  const { componentId } = props

  const list = useSelector(state => state.observation.list)

  const dispatch = useDispatch()
  const extraParams = useMemo(
    () => ({
      sort: '-date',
      preloadAll: true,
      'filter[organizationIds]': [props.companyId],
    }),
    [props.companyId]
  )
  const onPressItem = useCallback(
    item => {
      dispatch(a.setData({ selectedObservation: item }))
      push(componentId, {
        component: {
          name: routes.ADVICE_WIZARD_ROUTE,
          passProps: {
            step: 2,
            companyId: props.companyId,
            observationId: item.id,
          },
        },
      })
    },
    [componentId, props.companyId, dispatch]
  )

  const renderLeft = useCallback(
    (item, isSelected) => {
      return (
        <View style={{}}>
          <RadioButton.Android
            status={isSelected ? 'checked' : 'unchecked'}
            onPress={() => onPressItem(item)}
          />
        </View>
      )
    },
    [onPressItem]
  )
  const renderItem = useCallback(
    ({ item }) => {
      const isSelected = props.observationId === item.id
      let title =
        item?.growingPeriod?.organizationField?.name &&
        item?.growingPeriod?.organizationField?.name.length > 0
          ? item?.growingPeriod?.organizationField?.name
          : 'Onbekende teelt'

      return (
        <SafeListItem
          title={title}
          description={`${getFriendlyDate(fromTimestamp(item.date))}${
            item?.growingPeriod?.crop?.name &&
            item?.growingPeriod?.crop?.name.length > 0
              ? ` - ${item?.growingPeriod?.crop?.name}`
              : ''
          }`}
          onPress={() => {
            if (Platform.OS !== 'web') {
              // We only push on native, because on web we use "to". This is because "to" has extra hover effects on web.
              push(componentId, {
                component: {
                  name: routes.ADVICE_WIZARD_ROUTE,
                  passProps: {
                    step: 2,
                    companyId: props.companyId,
                    observationId: item.id,
                  },
                },
              })
            }
          }}
          left={renderLeft(item, isSelected)}
          accessibilityLabel={`Open ${item.name}`}
          to={
            routes.ADVICE_WIZARD_ROUTE + '2/' + props.companyId + '/' + item.id
          }
        />
      )
    },
    [componentId, props.observationId, renderLeft, props.companyId]
  )

  const back = useCallback(() => {
    Navigation.pop(componentId)
  }, [componentId])

  const goToCreateObservation = useCallback(() => {
    push(componentId, {
      component: {
        name: routes.NEW_OBSERVATION_ROUTE,
        // passProps: {
        //   step: 2,
        //   companyId: props.companyId,
        //   observationId: item.id,
        // },
      },
    })
  }, [componentId])

  return (
    <>
      <Translator>
        {({ translate }) => (
          <GeneralList
            {...props}
            {...actionsAsFunction}
            list={list}
            // searchPlaceholder={translate({ text: 'searchObservation' })}
            showSearch={false}
            renderItem={renderItem}
            icon
            withoutHeaderBackground
            customTitle={translate({ text: 'noObservationsYet' })}
            customDescription={translate({ text: 'noObservationsYetDesc' })}
            appbarContent={
              <PageHeader
                back={back}
                componentId={componentId}
                title={
                  <Appbar.Content
                    title={translate({ text: 'generateAdvice' })}
                    titleStyle={{
                      textAlign: 'center',
                    }}
                    style={{ flex: 1 }}
                    subtitleStyle={{
                      textAlign: 'center',
                    }}
                    subtitle={translate({ text: 'whichObservation' })}
                  />
                }
              />
            }
            onPressFab={goToCreateObservation}
            fabLabel={translate({ text: 'doObservation' })}
            fabIcon={'plus'}
            extraParams={extraParams}
            // preloads={preloads}
          />
        )}
      </Translator>
      <DeepLink loggedIn={true} componentId={componentId} />
    </>
  )
}

export default ObservationList
