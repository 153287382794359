import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  ActivityIndicator,
  Appbar,
  Button,
  Snackbar,
  Text,
} from 'react-native-paper'
import GeneralListHeader from '../../GeneralListFunctions/GeneralListHeader'
import PageHeader from '../../PageHeader'
import Navigation, { push } from '../../Navigation'
import { Translate, Translator } from 'react-translated'
import { useDispatch, useSelector } from 'react-redux'
import adviceActions from '../actions'
import Resizer from '../../WidthAndHeight/Resizer'
import Title from '../../Observations/New/Title'
import BottomFab from '../../WidthAndHeight/BottomFab'
import { Platform, View } from 'react-native'
import Checkbox from '../../components/Checkbox'
import { ScrollView } from 'react-native-gesture-handler'

import Box from '../../components/Box'
import List from '../../GeneralListFunctions/List'
import actions from '../../Farmer/Organization/User/List/actions'
import cA from '../../Farmer/Organization/User/actions'
import a from './actions'
import UserItem from '../../Farmer/Organization/User/UserItem'
import { useActionsAsFunctions } from '../../helpers/actions'
import Fields from '../../Form/Fields'
import { ADVICE_PDF_ROUTE, HOME_ROUTE } from '../../Routes.config'

const fieldProps = {
  style: { marginBottom: 16 },
}
const fields = [
  {
    key: 'text',
    name: 'first_name',
    type: 'text',
    label: <Translate text="firstName" />,
    fieldProps,
  },
  {
    key: 'text',
    name: 'preposition',
    type: 'text',
    label: <Translate text="preposition" />,
    fieldProps,
  },
  {
    key: 'text',
    name: 'last_name',
    type: 'text',
    label: <Translate text="lastName" />,
    fieldProps,
  },
  {
    key: 'text',
    name: 'email',
    type: 'email',
    label: 'E-mail',
    fieldProps,
  },
]

function DownloadSendAdvice(props) {
  const { componentId, adviceId, companyId } = props
  const advice = useSelector(state => state.advice.listAndCrud.crud)
  const exporting = advice.generatingExport
  const exported = advice.generatedExport
  const wizard = useSelector(state => state.advice.wizard.crud)
  const users = useSelector(state => state.farmer.userList.list)
  const createdUser = useSelector(state => state.farmer.user.crud.created)
  const creatingUser = useSelector(state => state.farmer.user.crud.creating)
  const userCrud = useSelector(state => state.farmer.user.crud?.object || {})

  const loading = advice.loading
  const sending = wizard.sendingAdvice
  const sendingError = wizard.sentAdviceError

  const [hasLoaded, setHasLoaded] = useState(false)
  const [selected, setSelected] = useState([])
  const dispatch = useDispatch()
  useEffect(() => {
    if (!hasLoaded) {
      setHasLoaded(true)
      dispatch(adviceActions.load(adviceId))
    }
  }, [dispatch, hasLoaded, adviceId])

  useEffect(() => {
    if (advice.loaded && !exported && !exporting) {
      dispatch(
        adviceActions.load(null, {
          extraURL: `advice/${adviceId}/export?language=nl`,
        })
      )
    }
  }, [advice.loaded, adviceId, dispatch, exported, exporting])

  const back = useCallback(() => {
    Navigation.pop(componentId)
  }, [componentId])

  const listHeaderProps = {
    listKey: 'header',
    appbarContent: (
      <Translator>
        {({ translate }) => (
          <PageHeader
            back={back}
            componentId={componentId}
            title={
              <Appbar.Content
                title={translate({ text: 'generateAdvice' })}
                titleStyle={{
                  textAlign: 'center',
                }}
                style={{ flex: 1 }}
                subtitleStyle={{
                  textAlign: 'center',
                }}
                subtitle={translate({ text: 'downloadAdvice' })}
              />
            }
          />
        )}
      </Translator>
    ),
    showSearch: false,
    autoFocusSearch: false,
    withoutHeaderBackground: true,
    onSearch: () => null,
    navbarTranslate: 0,
    navbarOpacity: 1,
    renderStickyBelowHeader: undefined,
    searchPlaceholder: '',
    searchIcon: '',
    onSearchIconPress: () => null,
    searchValue: '',
  }

  const sendMail = () => {
    dispatch(
      a.sendAdvice(null, {
        users: selected,
        id: adviceId,
      })
    )
  }
  const goHome = () => {
    push(componentId, {
      component: {
        name: HOME_ROUTE,
      },
    })
  }

  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    if (!!wizard.sentAdvice) {
      setSuccess(
        'Het advies is verstuurd via de mail naar de volgende adressen: \n' +
          selected.map(it => it.email).join(', ')
      )
      setSelected([])
      dispatch(a.reset())
    }
  }, [success, wizard.sentAdvice, dispatch, selected])

  useEffect(() => {
    // Catch send advice error, then tell the user which ones failed to send and which succeeded.
    if (sendingError) {
      const failedIndexes = wizard.sentAdviceResponse
        .map((it, i) => {
          return !it.status ? i : undefined
        })
        .filter(n => n !== undefined)
      if (failedIndexes && failedIndexes.length > 0) {
        setError(
          'Er is iets mis gegaan bij het verzenden van het advies naar de volgende email adressen: \n' +
            selected
              .filter((it, i) => failedIndexes.some(it => it === i))
              .map(it => it.email)
              .join(', ') +
            '. \n\nProbeer het opnieuw door nogmaals op versturen te klikken, of neem contact op met support@agro4all.com'
        )
        setSelected(
          selected.filter((it, i) => failedIndexes.some(it => it === i))
        )
      }
      dispatch(a.reset())
    }
  }, [sendingError, wizard, selected, dispatch])

  const exportAdvice = () => {
    if (exported && advice?.object?.url && advice?.object?.url !== '') {
      if (Platform.OS === 'web') {
        window.open(advice.object.url, '_blank')
      } else {
        push(componentId, {
          component: {
            name: ADVICE_PDF_ROUTE,
            passProps: {
              url: advice.object.url,
            },
          },
        })
      }
    }
  }

  const extraParams = useMemo(() => {
    return {
      limit: 1000,
      number: 1000,
      'organizationIds[]': companyId,
    }
  }, [companyId])

  useEffect(() => {
    if (createdUser) {
      dispatch(actions.loadList({ params: extraParams }))
      setSelected([...selected, userCrud])
      dispatch(cA.reset())
    }
  }, [createdUser, dispatch, extraParams, userCrud, selected, setSelected])

  const onPress = useCallback(
    user => {
      if (selected.find(it => it.id === user.id)) {
        // Already selected, so deselect
        setSelected(selected.filter(it => it.id !== user.id))
      } else {
        // Not selected, so select
        setSelected([...selected, user])
      }
    },
    [selected, setSelected]
  )

  const renderLeft = useCallback(
    (item, isSelected) => {
      return (
        <View style={{ paddingRight: 8 }}>
          <Checkbox
            status={isSelected ? 'checked' : 'unchecked'}
            onPress={() => onPress(item)}
          />
        </View>
      )
    },
    [onPress]
  )
  const renderItem = useCallback(
    ({ item }) => {
      const isSelected = selected.find(it => it.id === item.id)
      return (
        <UserItem
          item={item}
          componentId={componentId}
          organizationId={companyId}
          upperOnPress={onPress}
          left={renderLeft(item, isSelected)}
        />
      )
    },
    [componentId, companyId, renderLeft, onPress, selected]
  )

  const add = () => {
    const organizationId = companyId
    const meta = {
      params: {
        embed: ['organization'],
        sendInvite: true,
        'roles[]': 'farmer',
      },
      extraBody: {},
    }

    if (organizationId) {
      meta.extraBody.organization_id = Number(organizationId)
      meta.params.organizationId = Number(organizationId)
    }

    dispatch(cA.create(userCrud, meta))
  }

  const editField = useCallback(
    (key, value) => {
      dispatch(cA.setData({ ...userCrud, [key]: value }))
    },
    [dispatch, userCrud]
  )
  const [disabled, setDisabled] = useState(true)

  useEffect(() => {
    if (
      userCrud &&
      userCrud.first_name &&
      userCrud.first_name !== '' &&
      userCrud.last_name &&
      userCrud.last_name !== '' &&
      userCrud.email &&
      userCrud.email !== '' &&
      userCrud.email.includes('@') &&
      userCrud.email.includes('.') &&
      userCrud.email.length > 3
    ) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [userCrud])

  const actionsAsFunction = useActionsAsFunctions(actions)
  return (
    <>
      <GeneralListHeader {...listHeaderProps} />
      <Translator>
        {({ translate }) => (
          <>
            <ScrollView>
              <>
                <Resizer
                  style={
                    Platform.OS === 'web'
                      ? { marginTop: 52, paddingBottom: 80 }
                      : { paddingBottom: 80 }
                  }
                >
                  <View style={{ flex: 1 }}>
                    <Title
                      titleStyle={{ color: '#000' }}
                      title={translate({ text: 'doneWithAdvice' })}
                    />
                  </View>
                  {!!loading || !!exporting ? (
                    <>
                      <Text style={{ paddingBottom: 16, paddingLeft: 16 }}>
                        {translate({ text: 'onTheLoads' })}
                      </Text>
                      <ActivityIndicator
                        loading={loading || exporting}
                        size={'large'}
                      />
                    </>
                  ) : (
                    <>
                      <Text
                        onTextLayout={undefined}
                        dataDetectorType={undefined}
                        onPressIn={undefined}
                        onPressOut={undefined}
                        android_hyphenationFrequency={undefined}
                        style={{ marginLeft: 16, marginRight: 16 }}
                      >
                        {translate({ text: 'doneWithAdviceSub' })}
                      </Text>
                      <View style={{ padding: 16 }}>
                        <Button
                          onPress={goHome}
                          mode={'contained'}
                          color={'#EFEFEF'}
                          uppercase={false}
                          icon={'home'}
                          style={{ width: 'auto' }}
                        >
                          {translate({ text: 'goHome' })}
                        </Button>
                      </View>
                      <View style={{ paddingLeft: 16, paddingRight: 16 }}>
                        <Button
                          icon="download"
                          onPress={exportAdvice}
                          mode={'contained'}
                          uppercase={false}
                          disabled={exporting || loading}
                          loading={exporting}
                        >
                          {translate({ text: 'downloadAdviceButton' })}
                        </Button>
                      </View>
                      <Box
                        title={translate({ text: 'sendAdvice' })}
                        style={{ margin: 16 }}
                        subTitle={translate({ text: 'selectReceivers' })}
                      >
                        <List
                          {...props}
                          {...actionsAsFunction}
                          list={users}
                          renderItem={renderItem}
                          extraParams={extraParams}
                          loadListOnMount
                          silentReloading={false}
                          withoutVirtualization
                          reloadIfAlreadyLoaded
                          customEmptyTitle={<Translate text="noUsers" />}
                          customEmptyDescription={
                            <Translate text="addNewUser" />
                          }
                        />
                        <Text
                          style={{
                            marginTop: 28,
                            marginBottom: 16,
                            fontSize: 14,
                          }}
                        >
                          {translate({ text: 'userDoesNotExist' })}
                        </Text>
                        <Fields
                          fields={fields}
                          values={userCrud}
                          onChange={editField}
                          onSubmit={add}
                        />
                        <Button
                          onPress={add}
                          mode={'outlined'}
                          uppercase={false}
                          disabled={disabled}
                          loading={creatingUser}
                        >
                          {translate({ text: 'createUser' })}
                        </Button>
                      </Box>
                    </>
                  )}
                </Resizer>
              </>
            </ScrollView>
            <BottomFab
              icon={'send'}
              label={translate({ text: 'send' })}
              loading={sending}
              onPress={sendMail}
              disabled={selected.length === 0} // disable when no users/emails have been selected
            />
            <Snackbar
              visible={!!error}
              duration={30000}
              style={{ bottom: 100 }}
              onDismiss={() => setError(false)}
              action={{
                label: 'OK',
                onPress: () => {
                  setError(false)
                },
              }}
            >
              {error}
            </Snackbar>
            <Snackbar
              visible={!!success}
              duration={10000}
              style={{ bottom: 100 }}
              onDismiss={() => setSuccess(false)}
              action={{
                label: 'OK',
                onPress: () => {
                  setSuccess(false)
                },
              }}
            >
              {success}
            </Snackbar>
          </>
        )}
      </Translator>
    </>
  )
}

export default DownloadSendAdvice
