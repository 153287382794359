import React, { PureComponent, useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { filterTypesSorted } from './config'

import Tag from './FilterTag'
import { FILTER_MODAL_ROUTE } from '../Routes.config'
import { push } from '../Navigation'
import FilterMenu from './FilterMenu'
import FiltersContainer from './FiltersContainer'
import FilterList from './FilterList'
import Resizer from '../WidthAndHeight/Resizer'
import { isFilterSet } from '../helpers/utils'
import { useWidthAndHeight } from '../WidthAndHeight/widthAndHeightHook'
import { groupBy } from 'lodash'
import { getLanguage } from '../language'

const buttonTheme = {
  colors: {
    text: '#fff',
    primary: '#000',
    placeholder: '#000',
  },
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  rootWhite: { backgroundColor: '#fff' },
  rootLarge: {
    position: 'absolute',
  },
  moreContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
})

class FilterTool extends PureComponent {
  _openFilterScreen = (activeId, parentId, f) => {
    const { context, dispatch, filters, componentId } = this.props
    const filter = filters[activeId]
    console.log({ filter })
    if (f.SelectFunction) {
      // custom things like modal open or something like that instead of a screen push
      f.SelectFunction(dispatch)
    } else {
      push(componentId, {
        component: {
          name: FILTER_MODAL_ROUTE,
          passProps: {
            context,
            activeId,
            parentId,
          },
        },
      })
    }
  }
  _renderItem = (f, i) => {
    const { filters, contextColor, dispatch } = this.props
    const filter = filters[f.id]

    const language = getLanguage()
    const hasFilter = isFilterSet(f, filters)

    let count
    let label
    let subLabel
    let item
    if (hasFilter) {
      if (Array.isArray(filter)) {
        const howManyItems = filter.length
        label = howManyItems === 1 ? f.getItemLabel(filter[0]) : f.labelN
        if (f.getItemSubLabel && filter.length > 0) {
          subLabel = f.getItemSubLabel(filter[0])
        }
        if (f.id === 'resistance') {
          const selectedGrouped = groupBy(filter, 'type')
          subLabel = Object.keys(selectedGrouped)
            .map(key => {
              return `${key.toUpperCase()}`
            })
            .join(', ')
        }
        item = howManyItems === 1 ? filter[0] : undefined
        count = howManyItems > 1 ? howManyItems : undefined
      } else if (Object.keys(filter).length > 0 && typeof filter === 'object') {
        if (f.id === 'certification') {
          const selectedFiltered = Object.keys(filter).filter(
            key => filter[key]
          )
          const howManyItems = selectedFiltered.length
          label = howManyItems === 1 ? f.label : f.labelN

          subLabel = selectedFiltered
            .map(key => {
              let formattedKey = key.charAt(0).toUpperCase() + key.slice(1)
              if (key === 'mps') {
                formattedKey = key.toUpperCase()
              } else if (key === 'none') {
                formattedKey =
                  language === 'nl' ? 'Geen keurmerk' : 'Without certification'
              }
              return `${formattedKey}`
            })
            .join(', ')
        }
        if (f.id === 'bufferZone') {
          label =
            (filter && filter.present) || filter.width
              ? language === 'nl'
                ? 'Teeltvrije zone tot perceelsrand'
                : 'Buffer zone to parcel border'
              : ''

          subLabel = filter && filter.width ? filter.width + ' cm' : ''
        }
      } else {
        console.log({ f })
        label = f.label

        if (f.getItemSubLabel) {
          subLabel = f.getItemSubLabel(filter)
        }
        item = filter
      }
    } else {
      label = f.callToAction
    }

    return (
      <Tag
        key={f.id}
        label={label}
        subLabel={subLabel}
        count={count}
        testID={f.id}
        onPress={() => {
          if (f.selectAction) {
            if (hasFilter) {
              if (Array.isArray(f.removeAction)) {
                f.removeAction.forEach(ra => dispatch(ra(f.id)))
              } else {
                dispatch(f.removeAction(f.id))
              }
            } else {
              dispatch(f.selectAction(f.id))
            }

            return
          }

          const parentId =
            item &&
            (item.parent && item.parent.id
              ? item.parent.id
              : item.parentId
              ? item.parentId
              : item.parent_id)
          this._openFilterScreen(f.id, parentId, f)
        }}
        remove={
          hasFilter
            ? () => {
                if (Array.isArray(f.removeAction)) {
                  f.removeAction.forEach(ra => dispatch(ra(f.id)))
                } else {
                  dispatch(f.removeAction(f.id))
                }
              }
            : null
        }
        color={contextColor === 'white' ? 'grey' : 'dark'}
        theme={buttonTheme}
      />
    )
  }

  render() {
    const {
      filters,
      width,
      componentId,
      dispatch,
      height,
      context,
      contextColor,
      alwaysShowSmall,
      comparison,
      filterTypes,
      withoutText,
    } = this.props
    console.log({ withoutText })
    const isLarge = alwaysShowSmall ? false : this.props.isLarge

    const listContexes = [
      'product',
      'grouped-crop',
      'advice',
      'registration',
      'field',
      'distribution',
      'organization',
      'farmer-product',
      'knpv',
    ]
    const isListContext = listContexes.some(c => c === context)

    const selectModals = filterTypes
      .filter(
        filterType => filterType.SelectFunction && filterType.SelectComponent
      )
      .map(filterType => (
        <filterType.SelectComponent
          key={filterType.id}
          context={context}
          componentId={componentId}
        />
      ))

    if (isLarge && isListContext) {
      return (
        <>
          {selectModals}
          <FilterList
            componentId={componentId}
            filters={filters}
            dispatch={dispatch}
            width={width}
            height={height}
            filterTypes={filterTypes}
            context={context}
            withoutText={withoutText}
          />
        </>
      )
    }

    const inner = (
      <>
        <View
          style={[
            isLarge ? styles.rootLarge : styles.root,
            contextColor === 'white' && styles.rootWhite,
          ]}
        >
          <FiltersContainer contextColor={contextColor}>
            {!comparison ? (
              <FilterMenu
                componentId={componentId}
                filters={filters}
                dispatch={dispatch}
                filterTypes={filterTypes}
                context={context}
                withoutText={withoutText}
              />
            ) : null}
            {filterTypes
              .filter(f => isFilterSet(f, filters))
              .map(this._renderItem)}
            {filterTypes
              .filter(f => {
                if (f.showAlways && !isFilterSet(f, filters)) {
                  return true
                }
                if (context === 'comparison') {
                  return false
                }
                return false
              })
              .map(this._renderItem)}

            <View style={{ width: 100 }} />
          </FiltersContainer>
        </View>
        {selectModals}
      </>
    )

    if (isListContext) {
      return <Resizer>{inner}</Resizer>
    }
    return inner
  }
}

FilterTool.defaultProps = {
  contextColor: 'white',
  showMenu: true,
}

export default function FilterToolExport(props) {
  const filterTypes = useMemo(
    () =>
      filterTypesSorted.filter(({ contexes }) =>
        (contexes || []).some(c => c === props.context)
      ),
    [props.context]
  )

  const { width, height, isMedium, isLarge } = useWidthAndHeight()
  const state = useSelector(state => state)
  let filters = {}
  filterTypes.forEach(f => {
    filters[f.id] = f.getFilter(state)
  })
  const memoizedFilters = useMemo(
    () => {
      return filters
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(filters)]
  )

  const dispatch = useDispatch()

  return (
    <FilterTool
      {...props}
      filters={memoizedFilters}
      filterTypes={filterTypes}
      width={width}
      height={height}
      isMedium={isMedium}
      isLarge={isLarge}
      dispatch={dispatch}
    />
  )
}

//
// widthAndHeightHOC(connect(mapStateToProps)(FilterTool))
