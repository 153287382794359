import React from 'react'
import ClosableModal from '../../components/ClosableModal'
import AddFarmer from './AddFarmer'

function AddFarmerModal({
  onRequestClose,
  componentId,
  isVisible,
  onBack,
  onPressItem,
  onCreated,
}) {
  return (
    <ClosableModal
      isVisible={isVisible}
      onBackButtonPress={onRequestClose}
      onBackdropPress={onRequestClose}
      onRequestClose={onRequestClose}
      onModalHide={onRequestClose}
      hideHeader={true}
    >
      {isVisible && (
        <>
          <AddFarmer
            componentId={componentId}
            onBack={onBack}
            onCreated={onCreated}
            onPressItem={onPressItem}
          />
        </>
      )}
    </ClosableModal>
  )
}

export default AddFarmerModal
