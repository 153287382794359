import React, { useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import actions from './actions'

import { getProductData } from '../selectors'
import L from '../../GeneralListFunctions/List'
import { sortList } from '../Crop/ProductCropList'
import Resizer from '../../WidthAndHeight/Resizer'
import SafeListItem from '../../WidthAndHeight/SafeListItem'
import { Translator } from 'react-translated'

function ProductPestList({ list, productId, ...rest }) {
  const extraParams = useMemo(
    () => ({
      limit: 100000,
      number: 100000,
      product: productId,
    }),
    [productId]
  )
  const memoizedList = useMemo(() => sortList(list), [list])
  const renderItem = useCallback(({ item }) => {
    return (
      <Resizer>
        <SafeListItem
          title={item.name}
          description={
            (item.latinName ? item.latinName : item.latin_name) || ' '
          }
        />
      </Resizer>
    )
  }, [])

  if (!productId) {
    return null
  }
  return (
    <Translator>
      {({ translate }) => (
        <>
          <L
            {...rest}
            list={memoizedList}
            renderItem={renderItem}
            extraParams={extraParams}
            silentReloading={false}
            reloadIfAlreadyLoaded
            customEmptyTitle={translate({ text: 'noPests' })}
            customEmptyDescription={translate({
              text: 'noPestsDescription',
            })}
          />
        </>
      )}
    </Translator>
  )
}

const mapStateToProps = state => ({
  list: state.product.pest.list,
  productId: getProductData(state).id,
})

export default React.memo(connect(mapStateToProps, actions)(ProductPestList))
