import PT from 'prop-types'
import c from '../constants'

export const propTypes = {
  width: PT.number,
  height: PT.number,

  color: PT.oneOf([
    'default',
    'white',
    'green',
    'orange',
    'red',
    'yellow',
    'blue',
    'black',
  ]),
  subColor: PT.oneOf(['default']),
}

export const defaultProps = {
  color: 'default',
  subColor: 'default',
}

export const color = label => {
  switch (label) {
    case 'default':
      return c.defaultIconColor
    case 'white':
      return '#FFFFFF'
    case 'green':
      return c.greenColor
    case 'orange':
      return c.orangeColor
    case 'yellow':
      return c.yellowColor
    case 'red':
      return c.redColor
    case 'blue':
      return '#08325C'
    case 'black':
      return '#000'
    default:
      return c.defaultIconColor
  }
}

export const subColor = label => {
  switch (label) {
    case 'default':
      return c.lightIconColor
    default:
      return undefined
  }
}
