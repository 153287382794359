// import { call, put, takeLatest } from 'redux-saga/effects'
// import api from 'react-api'

// import { geoJSON } from '../Map/helpers'
// import * as a from './actions'

// const getDataFromResponse = response => {
//   if (response && response.data) {
//     return response.data
//   }
//   return []
// }

// function* loadPolygons({ payload: { region } }) {
//   try {
//     const fieldResponse = yield call(api, {
//       // basePath: 'https://nl.agro4all.com/api',
//       path: `field/field`,
//       params: region,
//     })
import { call, put, takeLatest } from 'redux-saga/effects'
import { fetchListener } from 'react-auth/lib/sagas/authentication'

import { geoJSON } from '../Map/helpers'
import * as a from './actions'

const getDataFromResponse = response => {
  if (response && response.data) {
    return response.data
  }
  return []
}
const getCustomFieldFromResponse = response => {
  if (response && response.customFields) {
    return response.customFields
  }
  return []
}

function* loadPolygons({ payload: { region } }) {
  try {
    const fieldResponse = yield call(fetchListener, {
      // basePath: 'https://nl.agro4all.com/api',
      path: `v2/field`,
      params: { ...region, limit: 1000000 },
    })

    const fieldData = getDataFromResponse(fieldResponse)

    const customFields = getCustomFieldFromResponse(fieldResponse)

    const loadedPayload = {
      data: {
        field: geoJSON(fieldData),
      },
      fieldData,
      customFields,
    }

    yield put(a.loadedPolygons(loadedPayload))
  } catch (e) {
    console.log('Map saga error: ', e)
    yield put(a.loadedPolygons(e, null, true))
  }
}

export default function* saga() {
  yield takeLatest(a.LOAD_POLYGONS, loadPolygons)
}
