import { all, call } from 'redux-saga/effects'

import advisor from './Advisor/saga'
import { updateSaga } from './Organization/saga'
import kvk from './Organization/kvk/saga'
import user from './Organization/User/saga'
import sprayEquipment from './Organization/SprayNozzle/saga'
import userList from './Organization/User/List/saga'

export default function* rootSaga() {
  yield all([
    call(advisor),
    call(updateSaga),
    call(user),
    call(sprayEquipment),
    call(userList),
    call(kvk),
  ])
}
