import * as Sentry from '@sentry/react'

export default function setUserAndOrganization({user, organization}) {
    const scope = new Sentry.Scope();
    if (user) {
        Sentry.setUser({
            id: user.user_id ? user.user_id : "Unauthenticated",
            username: (user.first_name || user.last_name) ? (user.first_name + (user.preposition ? ' ' + user.preposition : '') + ' ' + user.last_name) : "Unauthenticated",
            email: user.email ? user.email : "Unauthenticated"
        })
        scope.setTag("userRoles", user.roles ? user.roles.toString() : undefined)
    } else {
        Sentry.setUser(null)
        scope.setTag("userRoles", undefined)
    }
    if (organization) {
        scope.setTag("organizationID", organization.id ? organization.id.toString() : undefined)
        scope.setTag("organizationName", organization.name ? organization.name.toString() : undefined)
    } else {
        scope.setTag("organizationID", undefined)
        scope.setTag("organizationName", undefined)
    }
}
