import React from 'react'
import { useSelector } from 'react-redux'
import { ZendeskAPI } from 'react-zendesk'
import { Button, Portal, Text } from 'react-native-paper'
// @ts-ignore
import useZendesk from 'use-zendesk'
import { View } from 'react-native'
// @ts-ignore
import { Translate } from 'react-translated'

// Take contact form as an example
// Let's customise our contact form appearance, launcher and add prefill content
// const setting = {
//   color: {
//     theme: '#44af4c',
//     button: '#44af4c',
//     launcherText: '#FFF',
//     header: '#44af4c',
//   },
//   launcher: {
//     chatLabel: {
//       'en-US': 'Need Help',
//     },
//   },
//   position: { horizontal: 'right', vertical: 'top' },
//   offset: {
//     horizontal: '100px',
//     vertical: '150px',
//   },
//
//   contactForm: {
//     fields: [
//       // { id: 'description', prefill: { '*': 'My pre-filled description' } },
//     ],
//   },
// }
function ZendeskWrapper() {
  const [hideButton, setHideButton] = React.useState(false)

  useZendesk({
    key: 'c61cb6dc-557f-48aa-be5f-d06523fc1548',
    onLoad: () => {
      console.log('Zendesk loaded')
      ZendeskAPI('webWidget', 'hide')
      ZendeskAPI('webWidget:on', 'close', function () {
        ZendeskAPI('webWidget', 'hide')
        setHideButton(false)
      })
      // ZendeskAPI('launcher', 'hide')
    },
  })
  const user = useSelector((state: any) => state.auth.user)
  React.useEffect(() => {
    if (
      user &&
      user.user &&
      user.user.id &&
      user.organization &&
      user.organization.id
    ) {
      ZendeskAPI('webWidget', 'identify', {
        name: `${user.user.first_name}${
          user.user.preposition && user.user.preposition !== ''
            ? ` ${user.user.preposition}`
            : ''
        } ${user.user.last_name}`,
        email: user.user.email,
        organization: user.organization.name,
      })
      ZendeskAPI('webWidget', 'prefill', {
        name: {
          value: `${user.user.first_name}${
            user.user.preposition && user.user.preposition !== ''
              ? ` ${user.user.preposition}`
              : ''
          } ${user.user.last_name}`,
        },
        email: { value: user.user.email, readOnly: true },
        organization: { value: user.organization.name },
      })
    }
  }, [user])
  return hideButton ? null : (
    <Portal key={'portal'}>
      <View
        style={{
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-end',
          height: '100vh',
          top: 0,
          zIndex: 3,
          // width: 20,
          left: 0,
          // clip: 'rect(0,40px,100vh,-100vw)',
          position: 'absolute',
          // pointerEvents: 'none',
        }}
        pointerEvents={'none'}
      >
        <Button
          style={{
            // borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
            padding: 0,
          }}
          pointerEvents={'auto'}
          mode={'contained'}
          onPress={() => {
            ZendeskAPI('webWidget', 'show')
            ZendeskAPI('webWidget', 'open')
            setHideButton(true)
          }}
        >
          <Text
            onPressIn={undefined}
            onPressOut={undefined}
            android_hyphenationFrequency={undefined}
            style={{ fontWeight: 'bold', color: '#FFF' }}
          >
            <Translate text={'needHelp'} />
          </Text>
        </Button>
      </View>
    </Portal>
  )
}

export default ZendeskWrapper
