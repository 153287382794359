import { delay, put, select, takeLatest } from 'redux-saga/effects'
import actions from './actions'
import saga from '../GeneralListFunctions/sagaFactory'

import { filterPageParamsSelector, reloadActions } from '../FilterType/config'

export const productListSaga = () =>
  saga(
    actions,
    () => 'match/product',
    (state) => state.products
    // {
    //   // reloadActions,
    //   // removeOthers: true,
    // }
  )

function* sync(action) {
  try {
    // console.log('Sync', { action })
    if (action && action.type === '@@INIT') {
      yield delay(300)
    }
    const existingParams = yield select((state) => state.products.list.params)
    const filterParams = yield select(filterPageParamsSelector)
    yield put(
      actions.loadList(
        {
          params: {
            ...existingParams,
            ...filterParams,
          },
        },
        { silentReloading: false }
      )
    )
  } catch (error) {
    // Error saving data
    console.log({ error })
  }
}

function* deselect() {
  yield put(actions.removeSelected())
}

export default function* root() {
  yield takeLatest(reloadActions, deselect)

  yield takeLatest(
    [...reloadActions, '@@INIT', 'RESET', 'persist/REHYDRATE'],
    sync
  )
}
