import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import { Appbar, Button, Text, TextInput } from 'react-native-paper'
import { useDispatch, useSelector } from 'react-redux'
import Navigation from '../../Navigation'

import * as actions from './actions'

import { FilterType } from '../config'
import AppbarHeader from '../../WidthAndHeight/AppbarHeader'
import AppbarResizer from '../../WidthAndHeight/AppbarResizer'

import KeyboardAvoidingScrollView from '../../WidthAndHeight/KeyboardAvoidingScrollView'
import Resizer from '../../WidthAndHeight/Resizer'

import { RootState } from '../../reducers'
import ItemInList from '../ItemInList'
// @ts-ignore
import { Translator } from 'react-translated'

const safeSettings = { bottom: true, left: true, right: true }

function CropFreeBufferZoneChoose({
  componentId,
  filterType,
}: {
  componentId: string
  filterType: FilterType
}) {
  const dispatch = useDispatch()
  const cropFreeBufferZone = useSelector(
    (state: RootState) => state.filter.cropFreeBufferZone
  ) as { width: number | null; present: boolean | null }

  const onBack = () => {
    Navigation.pop(componentId)
  }

  const onChangeText = (text: string) => {
    if (text === '') {
      dispatch(actions.change({ width: 0 }))
    } else {
      dispatch(actions.change({ width: Number(text), present: true }))
    }
  }
  const onSelect = (id: boolean) => {
    dispatch(actions.change({ present: id }))
  }

  return (
    <>
      <AppbarHeader
        key="header"
        dark
        style={{
          justifyContent: 'center',
        }}
      >
        <AppbarResizer>
          <Appbar.BackAction onPress={onBack} color="#fff" testID={'back'} />
          <Appbar.Content title={filterType.label} color="#fff" />
        </AppbarResizer>
      </AppbarHeader>
      <KeyboardAvoidingScrollView
        style={styles.fill}
        safeSettings={safeSettings}
      >
        <Resizer>
          <Translator>
            {({ translate }: any) => (
              <>
                <ItemInList
                  onPress={(s: { id: boolean; name: string }) => {
                    onSelect(s.id)
                  }}
                  onSelect={(s: { id: boolean; name: string }) => {
                    onSelect(s.id)
                  }}
                  item={{
                    id: true,
                    name: translate({ text: 'bufferzonePresent' }),
                  }}
                  filterType={filterType}
                  isSelected={
                    cropFreeBufferZone && cropFreeBufferZone.present === true
                  }
                  selectType="radiobutton"
                  nameFirst
                />
                <ItemInList
                  onPress={(s: { id: boolean; name: string }) => {
                    onSelect(s.id)
                  }}
                  onSelect={(s: { id: boolean; name: string }) => {
                    onSelect(s.id)
                  }}
                  item={{
                    id: false,
                    name: translate({ text: 'bufferzoneNotPresent' }),
                  }}
                  filterType={filterType}
                  isSelected={
                    cropFreeBufferZone && cropFreeBufferZone.present === false
                  }
                  selectType="radiobutton"
                  nameFirst
                />
                <View style={{ padding: 12 }}>
                  <Text
                    style={{ fontSize: 16 }}
                    onTextLayout={undefined}
                    dataDetectorType={undefined}
                    onPressIn={undefined}
                    onPressOut={undefined}
                    android_hyphenationFrequency={undefined}
                  >
                    {translate({ text: 'measuredFrom' })}
                  </Text>
                  <View style={{ height: 16 }} />
                  <TextInput
                    onPressIn={undefined}
                    onPressOut={undefined}
                    autoComplete={undefined}
                    label={translate({ text: 'bufferzoneInCM' })}
                    mode="outlined"
                    value={`${
                      cropFreeBufferZone &&
                      cropFreeBufferZone.width &&
                      cropFreeBufferZone.present
                        ? cropFreeBufferZone.width
                        : ''
                    }`}
                    onChangeText={onChangeText}
                    textAlign={undefined}
                  />
                  <View style={{ height: 16 }} />
                  <Button uppercase={false} mode="contained" onPress={onBack}>
                    {translate({ text: 'done' })}
                  </Button>
                </View>
              </>
            )}
          </Translator>
        </Resizer>
      </KeyboardAvoidingScrollView>
    </>
  )
}

const styles = StyleSheet.create({
  fill: {
    flex: 1,
  },
})

export default CropFreeBufferZoneChoose
