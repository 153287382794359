import actions from './actions'
import reducer from '../../GeneralListFunctions/reducerFactory'

export type DriftClassificationType = {
  id: string | number
  classification: number
  expirationDate: number
  sprayDirection: '' | 'DOWNWARDS' | 'UP_AND_SIDEWAYS'
  orientation: '' | 'FORWARDS' | 'BACKWARDS'
  pressure: PressureType
}

export type DropletSizeClassificationType = {
  classification: number
  pressure: PressureType
}

export type PressureType = {
  min?: number
  max?: number
  specific?: number
}

export type BrandType = {
  id: string
  name: string
}

export interface SprayNozzleType {
  id: string
  name: string
  brand: BrandType
  size: string
  material: '' | 'VP' | 'VS' | 'VK'
  partNumber: number
  sprayAngle: number
  endNozzle: boolean
  driftClassifications: DriftClassificationType[]
  dropletSizeClassifications: DropletSizeClassificationType[]
}

export default reducer<SprayNozzleType>(actions)
