import React from 'react'
import { useSelector } from 'react-redux'
import actions from './actions'
import GeneralList from '../../GeneralListFunctions/GeneralList'
import ItemInList from '../ItemInList'
import FilterHeader from '../FilterHeader'
import Navigation from '../../Navigation'
import { RootState } from '../../reducers'
import { DriftClassificationType } from './reducer'
import { useActionsAsFunctions } from '../../helpers/actions'
import { Text } from 'react-native-paper'

type MannerListPropsType = {
  componentId: string
  filterType: any
  title?: string
  finish?: () => void
  closeModal?: () => void
  useUpperSelected?: boolean
  upperSelected?: any[]
  setUpperSelected?: (upperSelected: any[]) => void
}

function SprayNozzleList({
  componentId,
  filterType,
  title,
  finish,
  closeModal,
  useUpperSelected,
  upperSelected,
  setUpperSelected,
}: MannerListPropsType) {
  const list = useSelector((state: RootState) => state.filter.sprayNozzle.list)
  const selected = useSelector(
    (state: RootState) => state.filter.sprayNozzle.selected
  )
  const selectedSprayDirection = useSelector(
    (state: RootState) => state.filter.sprayDirection.selected
  )
  const actionAsDispatchers = useActionsAsFunctions(actions)

  const getParams = (ignore: any, inputSearch?: string) => {
    let search = inputSearch
    if (search === undefined) {
      search = list.params && list.params.search
    }

    let params = {
      ...list.params,
      sort: 'name',
      search,
      name: search,
      'search[name]': search,
      'search[partNumber]': search,
      sprayDirection: undefined,
    }
    if (selectedSprayDirection && selectedSprayDirection.length > 0) {
      params.sprayDirection = selectedSprayDirection[0].id
    }

    return params
  }

  const selectFunc = (item: DriftClassificationType) => {
    if (useUpperSelected && setUpperSelected) {
      if (
        ((upperSelected && upperSelected) || []).some(it => it.id === item.id)
      ) {
        // exists, remove
        setUpperSelected(
          ((upperSelected && upperSelected) || []).filter(
            it => it.id !== item.id
          )
        )
      } else {
        // add
        setUpperSelected([...((upperSelected && upperSelected) || []), item])
      }
    } else {
      actionAsDispatchers.select(item)
    }
  }
  const renderItem = ({ item }: { item: any }) => {
    const arr = item.driftClassifications
      ? item.driftClassifications.map((val: any) => {
          return val.classification
        })
      : [0]
    const highest = Math.max(...arr)
    return (
      <ItemInList
        onPress={selectFunc}
        onSelect={selectFunc}
        item={item}
        filterType={filterType}
        isSelected={
          useUpperSelected
            ? ((upperSelected && upperSelected) || []).some(
                it => it.id === item.id
              )
            : selected.some(it => it.id === item.id)
        }
        selectType={'checkbox'}
        right={
          highest !== 0 && (
            <Text
              onPressIn={undefined}
              onPressOut={undefined}
              android_hyphenationFrequency={undefined}
              style={{ fontSize: 16, color: '#000' }}
              onTextLayout={undefined}
              dataDetectorType={undefined}
            >{`↥ ${highest}%`}</Text>
          )
        }
      />
    )
  }
  const pop = () => {
    Navigation.pop(componentId)
  }

  return (
    <>
      {/* @ts-ignore*/}
      <GeneralList
        list={list}
        {...actionAsDispatchers}
        searchPlaceholder={filterType.searchPlaceholder}
        renderItem={renderItem}
        icon
        appbarContent={
          <FilterHeader
            filterType={filterType}
            componentId={componentId}
            label={title}
            onBack={closeModal}
          />
        }
        getParams={getParams}
        onPressFab={finish ? finish : pop}
      />
    </>
  )
}

export default SprayNozzleList
