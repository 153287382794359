import { combineReducers } from 'redux'

import advisor from './Advisor/reducer'
import organization from './Organization/reducer'
import kvk from './Organization/kvk/reducer'
import user from './Organization/User/reducer'
import sprayEquipment from './Organization/SprayNozzle/reducer'
import userList from './Organization/User/List/reducer'

export default combineReducers({
  advisor,
  organization,
  user,
  userList,
  sprayEquipment,
  kvk,
})
