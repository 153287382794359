import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ProductsList from '../../Products/ProductsList'
import PageHeader from '../../PageHeader'
import { Appbar } from 'react-native-paper'
import Navigation, { push } from '../../Navigation'
import * as routes from '../../Routes.config'
import actions from '../actions'
import qmActions from '../../FilterType/Switches/Certification/actions'

const AdviceProductsScreen = function AdviceProductsScreen({ componentId }) {
  const dispatch = useDispatch()
  const selected = useSelector(state => state.products.selected)

  const selectedFieldsWithGrowingPeriod = useSelector(
    state => state.advice.extra.selectedFieldsWithGrowingPeriod
  )
  const onBack = useCallback(() => {
    Navigation.pop(componentId)
  }, [componentId])
  const onPressFab = useCallback(() => {
    dispatch(
      actions.setData({
        lines: selected.map(product => ({ products: [{ product }] })),
      })
    )

    push(componentId, {
      component: {
        name: routes.ADVICE_NEW_LINES_ROUTE,
      },
    })
  }, [dispatch, componentId, selected])

  useEffect(() => {
    if (selectedFieldsWithGrowingPeriod.length > 0) {
      // Select PlanetProof if the selected field has planetproof value
      if (
        selectedFieldsWithGrowingPeriod[0].growingPeriod &&
        selectedFieldsWithGrowingPeriod[0].growingPeriod.planetProof
      ) {
        dispatch(
          qmActions.selectKeyValue({
            planetProof: true,
          })
        )
      } else {
        dispatch(
          qmActions.selectKeyValue({
            planetProof: false,
          })
        )
      }
      // Select MPS if the selected field has MPS value
      if (
        selectedFieldsWithGrowingPeriod[0].growingPeriod &&
        selectedFieldsWithGrowingPeriod[0].growingPeriod.mps
      ) {
        dispatch(
          qmActions.selectKeyValue({
            mps: true,
          })
        )
      } else {
        dispatch(
          qmActions.selectKeyValue({
            mps: false,
          })
        )
      }
      // Select Skal if the selected field has Skal value
      if (
        selectedFieldsWithGrowingPeriod[0].growingPeriod &&
        selectedFieldsWithGrowingPeriod[0].growingPeriod.skal
      ) {
        dispatch(
          qmActions.selectKeyValue({
            skal: true,
          })
        )
      } else {
        dispatch(
          qmActions.selectKeyValue({
            skal: false,
          })
        )
      }
    }
  }, [selectedFieldsWithGrowingPeriod, dispatch])

  return (
    <ProductsList
      componentId={componentId}
      onPressFab={onPressFab}
      appbarContent={
        <PageHeader
          componentId={componentId}
          back={onBack}
          title={<Appbar.Content title={`Middelen om te adviseren`} />}
        />
      }
      onlyShowFabWhenSelected
      fabLabel={`${selected.length} middelen adviseren`}
      context={'product'}
    />
  )
}

export default AdviceProductsScreen
