import React from 'react'
import { Platform, StyleSheet } from 'react-native'
import Checkbox from './Checkbox'
import RadioButton from './RadioButton'

let SelectComponentMap = {
  radiobutton: RadioButton,
  checkbox: Checkbox,
}
const styles = StyleSheet.create({
  checkbox: {
    width: 46,
    height: 46,
    padding: 6 + 5,
    borderRadius: 46 / 2,
  },
  radiobutton: {
    height: 46,
    width: 46,
    paddingLeft: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
})
const isWeb = Platform.OS === 'web'
function SelectionComponent({ selectType, ...rest }) {
  const SelectComponent = SelectComponentMap[selectType]
  return (
    <SelectComponent
      {...rest}
      style={isWeb ? styles.checkbox : styles[selectType]}
    />
  )
}
SelectionComponent.defaultProps = {
  selectType: 'checkbox',
  withPadding: true,
}
export default SelectionComponent
