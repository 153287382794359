import React from 'react'
import { View } from 'react-native'
import { ActivityIndicator } from 'react-native-paper'
import LogoutTrigger from 'react-auth/lib/components/Logout'

const Logout = props => (
  <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
    <ActivityIndicator size={'large'} />
    <LogoutTrigger />
  </View>
)

export default Logout
