import { get } from 'lodash/fp'

const stringAttr = (string = '', config) => ({
  label: config.label,
  value: `${string}`,
  hasValue: !!string,
})

const objectAttr = (object = {}, config) => ({
  label: config.label,
  value: `${get(config.path, object)}`,
  hasValue: !!get(config.path, object),
})

// const arrayAttr = (array, config) => ({
//     label:
//         Array.isArray(array) && array.length > 1 ? config.labelN : config.label,
//     value: (Array.isArray(array) ? array : []).join(', '),
//     hasValue: Array.isArray(array) && !!array.length,
// })

// const activeSubstanceAttr = (array = [], config) => {
//     const arrayResult = arrayAttr(array, config)
//     const cleanedArray = (array || []).map(
//         item =>
//             `${get('amount', item)}${get('unit', item)} ${get(
//                 'activeSubstance.name',
//                 item
//             )}`
//     )
//
//     cleanedArray.sort()
//
//     return {
//         ...arrayResult,
//         value: cleanedArray.join(', '),
//     }
// }

// const arrayObjectAttr = (array = [], config) => {
//   const arrayResult = arrayAttr(array, config)

//   return {
//     ...arrayResult,
//     value: arrayResult.hasValue
//       ? array.map(item => get(config.path, item)).join(', ')
//       : '',
//   }
// }

// const dateAttr = (date, config) => {
//   return {
//     label: config.label,
//     value:
//       date && date.expirationDate
//         ? secondsToDDMMYYYY(date.expirationDate)
//         : date
//         ? secondsToDDMMYYYY(date)
//         : '',
//     hasValue: !!date,
//   }
// }

export const attributes = language => [
  {
    key: 'name',
    func: stringAttr,
    config: {
      label: language === 'nl' ? 'Naam' : 'Name',
    },
  },
  {
    key: 'isPreferred',
    func: d => {
      return {
        value: !!d
          ? language === 'nl'
            ? 'Ja'
            : 'Yes'
          : language === 'nl'
          ? 'Nee'
          : 'No',
        hasValue: true,
        label: language === 'nl' ? 'Voorkeursnaam' : 'Preferred name',
      }
    },
    config: {
      label: language === 'nl' ? 'Voorkeursnaam' : 'Preferred name',
    },
  },
  {
    key: 'source',
    func: objectAttr,
    config: {
      label: language === 'nl' ? 'Bron' : 'Source',
      path: 'name',
    },
  },
  {
    key: 'source',
    func: objectAttr,
    config: {
      label: language === 'nl' ? 'Jaartal' : 'Year',
      path: 'year',
    },
  },
  {
    key: 'crops',
    func: (d = {}) => {
      let value = '-'
      if (Array.isArray(d)) {
        value = d?.map(crop => crop?.names)?.map(item =>
            item?.sort((a, b) => {
              // First sort on preferred
              if (a?.isPreferred && !b?.isPreferred) {
                return -1
              } else if (!a?.isPreferred && b?.isPreferred) {
                return 1
              }

              // Then sort on language
              if (a?.language === 'nl' && b?.language === 'la') {
                return -1
              } else if (a?.language === 'la' && b?.language === 'nl') {
                return 1
              }

              // Then sort alphabetically
              if (a?.name < b?.name) {
                return -1
              } else if (a?.name > b?.name) {
                return 1
              }
              return 0
            })
          )?.sort((a, b) => {
            let first = (a && Array.isArray(a) && a.length > 0 && a[0]) || null
            let second = (b && Array.isArray(b) && b.length > 0 && b[0]) || null

            if (first?.name < second?.name) {
              return -1
            } else if (first?.name > second?.name) {
              return 1
            }
            return 0
          })
      }

      return {
        hasValue: true,
        value,
        label: language === 'nl' ? 'Teelten' : 'Crops',
      }
    },
    config: {
      label: language === 'nl' ? 'Overige benamingen' : 'Other names',
    },
  },
]

export const attributesMain = language => [
  {
    key: 'names',
    func: (d = {}) => {
      let value = '-'
      if (Array.isArray(d) && d.filter(it => it.isPreferred).length > 0) {
        value = d
          .filter(it => it.isPreferred)
          .map(it =>
            it.language === 'nl'
              ? `${it.name} (${language === 'nl' ? 'Nederlands' : 'Dutch'})`
              : `${it.name} (${language === 'nl' ? 'Wetenschappelijke benaming' : 'Scientific name'})`
          )
          .join(', ')
      }

      return {
        hasValue: true,
        value,
        label: language === 'nl' ? 'Voorkeursnamen' : 'Preferred names',
      }
    },
    config: {
      label: language === 'nl' ? 'Voorkeursnamen' : 'Preferred names',
    },
  },
  {
    key: 'names',
    func: (d = {}) => {
      let value = '-'
      if (Array.isArray(d) && d.filter(it => !it.isPreferred).length > 0) {
        value = d
          .filter(it => !it.isPreferred)
          .map(it =>
            it.language === 'nl'
              ? `${it.name} (${language === 'nl' ? 'Nederlands' : 'Dutch'})`
              : `${it.name} (${language === 'nl' ? 'Wetenschappelijke benaming' : 'Scientific name'})`
          )
          .join(', ')
      }

      return {
        hasValue: true,
        value,
        label: language === 'nl' ? 'Overige benamingen' : 'Other names',
      }
    },
    config: {
      label: language === 'nl' ? 'Overige benamingen' : 'Other names',
    },
  },
  {
    key: 'eppo',
    func: objectAttr,
    config: {
      label:
        language === 'nl'
          ? 'EPPO code (Klik voor meer informatie)'
          : 'EPPO code (Click for more information)',
      path: 'code',
    },
  },
  {
    key: 'eppo',
    func: (d = {}) => {
      let value = '-'
      if (
        Array.isArray(d?.names) &&
        d?.names?.filter(it => it.language === 'la').length > 0
      ) {
        value = d?.names
          ?.filter(it => it.language === 'la')
          .map(it => it.fullName)
          .join(', ')
      }

      return {
        hasValue: true,
        value,
        label:
          language === 'nl'
            ? 'Wetenschappelijke benamingen (EPPO)'
            : 'Scientific names (EPPO)',
      }
    },
    config: {
      label: language === 'nl' ? 'Overige benamingen' : 'Other names',
    },
  },
]

export const processKNPVPest = (object, language) => {
  return attributes(language).map(({ key, func, config }) =>
    func(object[key], config)
  )
}
export const processKNPVPestMain = (object, language) => {
  return attributesMain(language).map(({ key, func, config }) =>
    func(object[key], config)
  )
}
