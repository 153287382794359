import React from 'react'
import { FlatList, StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { Appbar, List, withTheme } from 'react-native-paper'
import Resizer from '../../WidthAndHeight/Resizer'
import { Translator } from 'react-translated'
import a from '../actions'
import AppbarHeader from '../../WidthAndHeight/AppbarHeader'
import AppbarResizer from '../../WidthAndHeight/AppbarResizer'
import Checkbox from '../../components/Checkbox'

function SelectSubDivisions({ prev }) {
  const organizationField = useSelector(
    state => state.organizationField.crud.object
  )

  const dispatch = useDispatch()
  const onPress = item => {
    console.log({ item })
    let selectedSections = organizationField?.selectedSections || []
    if (item.id === 'selectAll') {
      if (selectedSections.length === organizationField?.sections?.length) {
        selectedSections = []
      } else {
        selectedSections = organizationField?.sections || []
      }
    } else if (selectedSections.some(selLoc => selLoc.id === item.id)) {
      selectedSections = selectedSections.filter(
        selLoc => selLoc.id !== item.id
      )
    } else {
      selectedSections.push(item)
    }
    dispatch(
      a.setData({
        ...organizationField,
        selectedSections,
      })
    )
  }
  const renderLeft = (item, isSelected) => () => {
    return (
      <View style={styles.leftContainer}>
        <Checkbox
          status={isSelected ? 'checked' : 'unchecked'}
          onPress={() => onPress(item)}
          withPadding
        />
      </View>
    )
  }
  const renderHeader = () => {
    return (
      <Resizer>
        <List.Item
          key={'selectAll'}
          title={
            organizationField?.selectedSections?.length ===
            organizationField?.sections?.length
              ? 'Deselecteer alles'
              : 'Selecteer alles'
          }
          onPress={() => onPress({ id: 'selectAll' })}
          left={renderLeft(
            { id: 'selectAll' },
            organizationField?.selectedSections?.length ===
              organizationField?.sections?.length
          )}
        />
      </Resizer>
    )
  }
  const renderItem = ({ item, index }) => {
    const { growingPeriods } = item
    const today = Math.floor(new Date())
    const activeGrowingPeriod = growingPeriods?.find(
      gp => gp.startDate <= today && gp.endDate >= today
    )
    let descriptionA = []
    if (activeGrowingPeriod?.crop?.name) {
      descriptionA.push(activeGrowingPeriod?.crop?.name)
    }
    if (activeGrowingPeriod?.variety) {
      descriptionA.push(activeGrowingPeriod?.variety)
    }

    let selected = false
    if (organizationField?.selectedSections?.length > 0) {
      selected = organizationField?.selectedSections?.some(
        selLoc => selLoc.id === item.id
      )
    }
    return (
      <Resizer>
        <List.Item
          key={item.id}
          title={item.name || `${item?.unit?.name} ${index + 1}`}
          description={descriptionA.join(', ')}
          onPress={() => onPress(item)}
          left={renderLeft(item, selected)}
        />
      </Resizer>
    )
  }

  return (
    <Translator>
      {({ translate }) => (
        <>
          <AppbarHeader
            key="header"
            dark
            style={{ elevation: 0, justifyContent: 'center' }}
          >
            <AppbarResizer>
              <Appbar.BackAction onPress={prev} color="#fff" />
              <Appbar.Content
                title={translate({ text: 'crop' })}
                color="#fff"
              />
            </AppbarResizer>
          </AppbarHeader>
          {renderHeader()}
          <FlatList
            data={organizationField?.sections}
            renderItem={renderItem}
          />
        </>
      )}
    </Translator>
  )
}
// eslint-disable-next-line
const styles = StyleSheet.create({})

export default withTheme(SelectSubDivisions)
