import React from 'react';
import { StyleSheet } from 'react-native';
import {TouchableRipple, Text} from "react-native-paper";

// Modified from https://github.com/callstack/react-native-paper/blob/main/src/components/DataTable/DataTableCell.tsx
// to enable multiline text
const DataTableCellMultiLine = ({children, textStyle, style, numeric, numberOfLines, ...rest}) => (
  <TouchableRipple
    {...rest}
    style={[styles.container, numeric && styles.right, style]}
  >
    <Text style={textStyle} numberOfLines={numberOfLines || 5}>
      {children}
    </Text>
  </TouchableRipple>
);

DataTableCellMultiLine.displayName = 'DataTable.CellMultiLine';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  right: {
    justifyContent: 'flex-end',
  },
});

export default DataTableCellMultiLine;
