import React, { Component } from 'react'

class Swiper extends Component {
  render() {
    const { currentScreen, passItemProps } = this.props
    if (!currentScreen || !currentScreen.component) {
      return null
    }
    return (
      <currentScreen.component
        {...currentScreen}
        {...passItemProps}
        prev={this.props.onPrev}
        remove={this.props.remove}
        next={this.props.onNext}
      />
    )
  }
}

Swiper.defaultProps = {
  index: 0,
  onIndexChange: () => {},
}

export default Swiper
