import * as a from './actions'
import { Dimensions } from 'react-native'
const { width, height } = Dimensions.get('window')

const initialState = {
  width,
  height,
}

const reducer = (state = initialState, { type, error, payload }) => {
  switch (type) {
    case a.UPDATE: {
      if (payload.width !== state.width || payload.height !== state.height) {
        return {
          width: payload.width,
          height: payload.height,
        }
      }
      return state
    }
    default:
      return state
  }
}

export default reducer
