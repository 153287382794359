// @param user the user to check
// @param expectedRole = the specified role you're checking
// @return Returns true if user has the role of an advisor
export const hasRole = (user, expectedRole) => {
  return (user?.roles || []).some(role => role?.name === expectedRole)
}

const userOptions = new Map([
  [
    'farmer',
    [
      'farmer-product',
      'products',
      'registrations',
      'crops',
      'organizationFields',
      'parcels',
    ],
  ],
  [
    'advisor',
    [
      'products',
      'registrations',
      'advice',
      'observations',
      'crops',
      'organizationFields',
    ],
  ],
  [
    'admin',
    [
      'farmer-product',
      'products',
      'knpv',
      'registrations',
      'advice',
      'observations',
      'crops',
      'organizationFields',
      'parcels',
      'organizations',
      'distributions',
    ],
  ],
  [
    'wholesaler',
    [
      'products',
      'advice',
      'observations',
      'crops',
      'organizationFields',
      'parcels',
      'organizations',
    ],
  ],
  ['horticulturist', ['products']],
  ['contractor', ['crops', 'parcels', 'organizations']],
  ['transporter', ['crops', 'parcels', 'organizations']],
  ['manager', ['products', 'distributions']],
  ['knpv', ['knpv']],
])

export const menuOptions = user => {
  let options = []
  if (!user || !user.roles) {
    return options
  }
  let mergedOptions = []
  for (const role of user.roles) {
    mergedOptions = [
      ...new Set([...options, ...(userOptions.get(role.name) || [])]),
    ]
    options = mergedOptions
  }
  return options
}
