import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { Text } from 'react-native-paper'

import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import nl from 'date-fns/locale/nl'
import './DurationPicker.css'
import dayjs from '../dayjs'

registerLocale('nl', nl)

const popperProps = {
  // withPortal: true,
  // popperStyle: {
  //   zIndex: 1000,
  // },
  popperClassName: 'react-datepicker-popper',
  popperPlacement: 'top-end',
  popperModifiers: {
    offset: {
      enabled: true,
      offset: '5px, 10px',
    },
    preventOverflow: {
      enabled: true,
      escapeWithReference: false,
      boundariesElement: 'viewport',
    },
  },
}

class DateTimePicker extends Component {
  _change = date => {
    this.props.onChange(dayjs(date).unix())
  }
  render() {
    const { mode, value, label, editable } = this.props
    let dateValue = value
    if (!dateValue) {
      dateValue = 0
    }
    // unix to Javascript Date object
    const date = dayjs.unix(dateValue).toDate()

    let inner = <Text>Unsupported mode</Text>

    if (mode === 'date') {
      inner = (
        <DatePicker
          locale="nl"
          dateFormat="dd-MM-yyyy"
          selected={date}
          onChange={editable ? this._change : () => null}
          {...popperProps}
        />
      )
    }
    if (mode === 'time') {
      inner = (
        <DatePicker
          locale="nl"
          selected={date}
          onChange={editable ? this._change : () => null}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={6}
          dateFormat="HH:mm"
          timeFormat="HH:mm"
          timeCaption="Tijd"
          {...popperProps}
        />
      )
    }

    return (
      <View style={styles.root}>
        {inner}
        <Text style={styles.label} pointerEvents="none">
          {label}
        </Text>
      </View>
    )
  }
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginLeft: 6,
  },
  label: {
    position: 'absolute',
    top: 6,
    fontSize: 12,
    opacity: 0.6,
    marginLeft: 10,
  },
})

DateTimePicker.defaultProps = {
  editable: true,
}
export default DateTimePicker
