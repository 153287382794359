import React from 'react'
import { StyleSheet, View } from 'react-native'
import FormContainer from '../../Form/FormContainer'
import safeAreaHOC from '../../WidthAndHeight/safeAreaHOC'
import KeyboardAvoidingScrollView from '../../WidthAndHeight/KeyboardAvoidingScrollView'
import AppbarHeader from '../../WidthAndHeight/AppbarHeader'
import AppbarResizer from '../../WidthAndHeight/AppbarResizer'
import { Appbar, Menu } from 'react-native-paper'
import { Translate, Translator } from 'react-translated'
import Box from '../../components/Box'
import Amount from '../../Form/Amount'
import ListItem from '../../WidthAndHeight/ListItemWithBottom'

export const overAllFields = [
  {
    name: 'organization',
    label: <Translate text="company" />,
    type: 'organizationSelect',
    plainList: true,
    fieldProps: {
      // style: { marginLeft: 6, marginRight: 6 },
    },
  },
  {
    name: 'growingPeriods',
    label: <Translate text="growingPeriodsForAllParcels" />,
    type: 'growingPeriods',
    canDelete: true,
  },
]

function LocationSubDivision({ title, prev, next }) {
  const [visible, setVisible] = React.useState(false)
  const [unit, setUnit] = React.useState()

  const closeMenu = () => setVisible(false)

  const setData = (name, value) => {
    console.log({ name, value })
  }
  return (
    <>
      <AppbarHeader
        key="header"
        dark
        style={{ elevation: 0, justifyContent: 'center' }}
      >
        <AppbarResizer>
          <Appbar.BackAction onPress={prev} color="#fff" />
          <Appbar.Content title={title} color="#fff" />

          {/* <Appbar.Action icon={'delete'} onPress={onRemove} color="#fff" /> */}
        </AppbarResizer>
      </AppbarHeader>
      <KeyboardAvoidingScrollView>
        <View
          style={{
            maxWidth: 800,
            alignSelf: 'center',
            width: '100%',
            marginTop: 32,
          }}
        >
          <FormContainer onSubmit={next}>
            <Translator>
              {({ translate }) => (
                <Box
                  title={translate({ text: 'amount' })}
                  style={{ marginBottom: 16 }}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      flex: 1,
                      width: '100%',
                      flexGrow: 1,
                    }}
                  >
                    <Amount
                      name="amount"
                      label={translate({ text: 'amount' })}
                      step={1}
                      onChange={setData}
                      fieldProps={{
                        style: { marginLeft: 6, marginRight: 6, flex: 1 },
                      }}
                    />
                    <Menu
                      visible={visible}
                      onDismiss={closeMenu}
                      style={{ flex: 1, marginTop: 24 }}
                      anchor={
                        <ListItem
                          onPress={() => setVisible(true)}
                          title={
                            unit ? unit : translate({ text: 'noUnitSelected' })
                          }
                          description={
                            unit
                              ? translate({ text: 'unit' })
                              : translate({ text: 'selectUnit' })
                          }
                          style={{
                            flex: 1,
                            width: '100%',
                            paddingLeft: 16,

                            marginTop: 24,
                          }}
                          // right={}
                        />
                      }
                    >
                      <Menu.Item
                        onPress={() => {
                          closeMenu()
                          setUnit('Vakken')
                        }}
                        title="Vakken"
                      />
                      <Menu.Item
                        onPress={() => {
                          closeMenu()
                          setUnit('Kappen')
                        }}
                        title="Kappen"
                      />
                      <Menu.Item
                        onPress={() => {
                          closeMenu()
                          setUnit('Bedden')
                        }}
                        title="Bedden"
                      />
                      <Menu.Item
                        onPress={() => {
                          closeMenu()
                          setUnit('Rijen')
                        }}
                        title="Rijen"
                      />
                      <Menu.Item
                        onPress={() => {
                          closeMenu()
                          setUnit('Tafels')
                        }}
                        title="Tafels"
                      />
                      <Menu.Item
                        onPress={() => {
                          closeMenu()
                          setUnit('Vloeren')
                        }}
                        title="Vloeren"
                      />
                    </Menu>
                  </View>
                </Box>
              )}
            </Translator>
          </FormContainer>
        </View>
        <View style={styles.paddingScroll} />
      </KeyboardAvoidingScrollView>
    </>
  )
}

const styles = StyleSheet.create({
  paddingScroll: {
    paddingBottom: 200,
  },
  fieldsMapContainer: {
    height: 350,
    borderRadius: 8,
    margin: 16,
    overflow: 'hidden',
  },
})

export default safeAreaHOC(LocationSubDivision)
