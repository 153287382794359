import actions from './actions'
import { tempSaga } from '../../../GeneralListFunctions/sagaFactory'

export default () =>
  tempSaga(
    actions,
    () => 'spray/equipment',
    (state) => state.farmer.sprayEquipment,
    {
      reloadActions: [],
    }
  )
