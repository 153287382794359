import React, { Component } from 'react'
import { Animated, Platform, ScrollView, View } from 'react-native'

import PT from 'prop-types'
import ErrorMessages from '../components/ErrorMessages'
import DetailRowShimmer from '../components/Item.shimmer'
import 'react-native-get-random-values'
import { v4 as uuidv4 } from 'uuid'

import { hasFiltersInParam, LoadingArray } from './GeneralList'
import Empty from './Empty'

class List extends Component {
  state = {
    listKey: `${uuidv4()}`,
  }

  UNSAFE_componentWillMount() {
    if (this.props.loadListOnMount) {
      if (
        (!this.props.list.loadedList || this.props.reloadIfAlreadyLoaded) &&
        this.props.loadList
      ) {
        this.props.loadList(
          { params: this.props.getParams(this.props) },
          {
            silentReloading: this.props.silentReloading,
            ...(this.props.meta || {}),
          }
        )
      }
    }
  }

  _renderEmptyState = () => {
    const { customEmptyDescription, customEmptyTitle, smallEmpty } = this.props
    const search = this.props.list.params && this.props.list.params.search
    const isReallyEmpty = !hasFiltersInParam(
      this.props.getParams(this.props, search)
    )
    console.log({ isReallyEmpty })
    if (this.props.renderEmptyState) {
      return this.props.renderEmptyState()
    }
    return (
      <Empty
        isReallyEmpty={isReallyEmpty}
        search={search}
        customTitle={customEmptyTitle}
        customDescription={customEmptyDescription}
        smallEmpty={smallEmpty}
      />
    )
  }

  _renderHeader = () => {
    const { renderAboveHeader, icon } = this.props
    const {
      data,
      loadingListError,
      loadingListErrorMessage,
      loadingList,
      loadedList,
    } = this.props.list
    // const showStatusbar = this._showStatusBar()
    return (
      <View key="header-above">
        {renderAboveHeader ? renderAboveHeader() : null}
        {loadingListError ? (
          <ErrorMessages messages={[loadingListErrorMessage]} />
        ) : null}
        {loadingList && !loadedList
          ? LoadingArray.map((none, i) => (
              <DetailRowShimmer key={`${i}`} icon={icon} />
            ))
          : null}
        {loadedList &&
          data.length === 0 &&
          !loadingListError &&
          this._renderEmptyState()}
      </View>
    )
  }

  _keyExtractor = item =>
    `id_${this.state.listKey}_${item.id}_${
      item.attributes && item.attributes.map(a => a.id)
    }`

  render() {
    const { rerenderKey, withoutVirtualization } = this.props
    const { loadingList, data, noHeader } = this.props.list
    return [
      Platform.OS === 'web' || withoutVirtualization ? (
        <ScrollView
          key={'container-scroll'}
          scrollEventThrottle={30}
          contentContainerStyle={{
            overflow: withoutVirtualization ? 'scroll' : 'auto',
            flexDirection: 'column',
            flexGrow: 1,
            ...this.props.contentContainerStyle,
          }}
          style={{ flex: 1 }}
        >
          {!noHeader && this._renderHeader()}
          {data.map((item, index) => (
            <View key={this._keyExtractor(item)} style={{ width: '100%' }}>
              {this.props.renderItem({ item, index })}
            </View>
          ))}
        </ScrollView>
      ) : (
        <Animated.FlatList
          key="flatlist"
          contentContainerStyle={this.props.contentContainerStyle}
          contentInsetAdjustmentBehavior={'never'}
          listKey={this.state.listKey}
          data={data}
          renderItem={this.props.renderItem}
          ListFooterComponent={this._renderHeader}
          keyExtractor={this._keyExtractor}
          extraData={`${loadingList}_${rerenderKey}`}
        />
      ),
    ]
  }
}

List.propTypes = {
  renderItem: PT.func.isRequired,
  icon: PT.bool,
}
List.defaultProps = {
  getParams: (listProps, search) => {
    const { sort, preloads, extraParams } = listProps
    if (search === undefined) {
      search =
        listProps &&
        listProps.list &&
        listProps.list.params &&
        listProps.list.params.search
    }
    return {
      ...listProps.list.params,
      sort,
      search,
      // eslint-disable-next-line
      ['search[name]']: search,
      name: search,
      preloads,
      ...extraParams,
    }
  },
  loadListOnMount: true,
  reloadIfAlreadyLoaded: false,
  silentReloading: true,
  withStatusBar: true,
  icon: false,
}

export default List
