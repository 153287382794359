const errorObject = (key: string, message: string) => ({
  key,
  message,
})

export const isEmpty = (str: string) => !str || str.length === 0
export const uppercaseFirstLetter = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1)
export const validateEmail = (email: string) => /\S+@\S+\.\S+/.test(email)
export const getFormData = (object: any) => {
  const formData = new FormData()
  Object.keys(object).forEach(key => formData.append(key, object[key]))
  return formData
}
export const getErrors = (fields: any, values: any) => {
  const validateErrors: any[] = []
  Object.keys(fields).forEach(key => {
    const value = values[key]
    const error = fields[key].validate(value)

    if (error) {
      validateErrors.push(errorObject(key, error))
    }
  })
  return validateErrors
}
export const removed = (props: any, nextProps: any) =>
  nextProps.removing !== props.removing &&
  !nextProps.removing &&
  !nextProps.removingError

export const loaded = (props: any, nextProps: any) =>
  nextProps.object !== props.object && nextProps.object

export const createdOrUpdated = (props: any, nextProps: any) =>
  (nextProps.creating !== props.creating &&
    !nextProps.creating &&
    !nextProps.creatingError) ||
  (nextProps.updating !== props.updating &&
    !nextProps.updating &&
    !nextProps.updatingError)

export const isFilterSet = (
  f: { id: string; showAlways: boolean; canBeFalse?: boolean },
  filters: {
    [key: string]: any
  }
): boolean => {
  const filter = filters[f.id]

  return isFilterValueSet(f, filter)
}

export const isFilterValueSet = (
  f: { id: string; showAlways: boolean; canBeFalse?: boolean },
  filter: any
): boolean => {
  return (
    !(
      filter === undefined ||
      filter === null ||
      (Array.isArray(filter) && filter.length === 0) ||
      (!f.canBeFalse && filter === false) ||
      (typeof filter === 'object' &&
        !Array.isArray(filter) &&
        (Object.keys(filter).length === 0 ||
          !Object.keys(filter).some(key => filter[key] === true)))
    ) ||
    (filter &&
      // @ts-ignore
      new Date(filter) !== 'Invalid Date' &&
      filter &&
      new Date(filter).getTime() > 0)
  )
}
