import React, { useCallback, useMemo } from 'react'
import { StyleSheet, View } from 'react-native'
import FormContainer from '../../Form/FormContainer'
import safeAreaHOC from '../../WidthAndHeight/safeAreaHOC'
import { Appbar, Text } from 'react-native-paper'
import GeneralList from '../../GeneralListFunctions/GeneralList'
// @ts-ignore
import { Translator } from 'react-translated'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useActionsAsFunctions } from '../../helpers/actions'
import actions from '../../OrganizationField/actions'
import a from '../actions'
import PageHeader from '../../PageHeader'
import SafeListItem from '../../WidthAndHeight/SafeListItem'
import SelectionComponent from '../../components/SelectionComponent'
import round from '../../helpers/round'
import AdvisorFilter from '../../AdvisorFilter'
import { getFirstSelectedId } from '../../FilterType/config'
import idx from 'idx'

function FieldSelectScreen({
  prev,
  next,
  componentId,
  selectedFarmerId,
  selectedContractId,
  selectedGrowingPlan,
  certifications,
}: {
  prev: () => any
  next: () => any
  componentId: string
  selectedFarmerId?: string
  selectedContractId?: string
  selectedGrowingPlan?: string
  certifications?: any
}) {
  const selectedCrop = useSelector((state: any) => state.filter.crop.selected)
  const extraParams = useMemo(
    () => ({
      managedOrganizationId: selectedFarmerId,
      cropId: getFirstSelectedId(selectedCrop),
      contractId: selectedContractId,
      growingPlan: selectedGrowingPlan,
      certifications: Object.keys(certifications).filter(key =>
        certifications[key] ? key : false
      ),
      preloadAll: true,
      sort: 'name',
    }),
    [
      selectedFarmerId,
      selectedCrop,
      selectedContractId,
      selectedGrowingPlan,
      certifications,
    ]
  )

  const list = useSelector((state: any) => state.organizationField.list)
  const crud = useSelector((state: any) => state.observation.crud)
  const dispatch = useDispatch()

  const select = useCallback(
    (item: any) => {
      dispatch(a.setData({ ...(crud.object || {}), field: item }))
      next()
    },
    [dispatch, crud, next]
  )

  const renderItem = useCallback(
    ({ item }) => {
      const isSelected =
        crud &&
        crud.object &&
        crud.object.field &&
        crud.object.field.id === item.id

      const { field, name, customField, organization } = item
      // let description = (crop ? crop.name : '') + (variety ? ` ${variety}` : '')
      let calculatedField: any = 0
      if (field && field.area > 0) {
        calculatedField = round(parseFloat(field.area) / 10000, 3)
      } else if (customField && customField.fields) {
        let add = 0
        customField.fields.forEach((fi: any) => (add += parseFloat(fi.area)))
        calculatedField = round(add / 10000, 3)
      }

      const contract = item?.growingPeriods?.find((gp: any) => gp?.contract?.id)
        ?.contract?.number
      if (!list.loadingList) {
        return (
          <SafeListItem
            title={`${organization && organization.name}${
              contract && selectedCrop.length > 0 ? ` - ${contract}` : ''
            }`}
            onPress={() => select(item)}
            description={name}
            right={
              calculatedField && (field || customField) ? (
                <View style={{ flexDirection: 'row', marginRight: 5 }}>
                  <Text
                    onPressIn={undefined}
                    onPressOut={undefined}
                    android_hyphenationFrequency={undefined}
                    onTextLayout={undefined}
                    dataDetectorType={undefined}
                  >
                    {calculatedField} ha
                  </Text>
                </View>
              ) : (
                <View />
              )
            }
            left={
              <SelectionComponent
                selectType={'radiobutton'}
                status={isSelected ? 'checked' : 'unchecked'}
                onPress={() => select(item)}
              />
            }
            accessibilityLabel={`Open ${item.name}`}
            to={''}
            style={styles.listItem}
          />
        )
      }
    },
    [list.loadingList, selectedCrop.length, select, crud]
  )
  const dispatchedActions = useActionsAsFunctions(actions)

  const renderStickyBelowHeader = useCallback(() => {
    return (
      <AdvisorFilter
        key="filter-tool"
        componentId={componentId}
        context="field"
      />
    )
  }, [componentId])
  return (
    <FormContainer onSubmit={next}>
      <>
        <Translator>
          {({
            translate,
          }: {
            translate: ({ text }: { text: string }) => string
          }) => (
            <GeneralList
              searchPlaceholder={translate({ text: 'searchParcels' })}
              renderItem={renderItem}
              renderStickyBelowHeader={renderStickyBelowHeader}
              icon
              extraParams={extraParams}
              fabLabel={translate({ text: 'new' })}
              loadWhenLoadingList
              list={list}
              selected={[(crud && crud.object && crud.object.field) || {}]}
              appbarContent={
                <PageHeader
                  back={() => prev()}
                  componentId={componentId}
                  title={
                    <Appbar.Content title={translate({ text: 'parcels' })} />
                  }
                />
              }
              {...dispatchedActions}
              componentId={componentId}
            />
          )}
        </Translator>
      </>
    </FormContainer>
  )
}

const styles = StyleSheet.create({
  listItem: {},
  paddingScroll: {
    paddingBottom: 200,
  },
  fieldsMapContainer: {
    height: 350,
    borderRadius: 8,
    margin: 16,
    overflow: 'hidden',
  },
})

const mapStateToProps = (state: any) => ({
  list: state.organizationField.list,
  showOnMapButton: (state.organizationField.list.data || []).length > 0,
  selectedFarmerId: getFirstSelectedId(state.filter.advisorFarmer.selected),
  selectedContractId: getFirstSelectedId(state.filter.contract.selected),
  selectedGrowingPlan: getFirstSelectedId(state.filter.growingPlan.selected),
  certifications: state.filter.certification.selectedKeyValue,
  user: idx(state, _ => _.auth.user.user),
})

export default connect(mapStateToProps, null)(safeAreaHOC(FieldSelectScreen))
