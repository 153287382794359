import React, { Component } from 'react'
import Pdf from './Pdf/Pdf'
import c from './constants'
import { getLanguage } from './language'

class TermsScreen extends Component {
  render() {
    const language = getLanguage()
    return (
      <Pdf
        title={language === 'nl' ? 'Gebruiksvoorwaarden' : 'Terms of use'}
        url={c.termsAndConditionsUrl}
        enableDrawer
        {...this.props}
      />
    )
  }
}

export default TermsScreen
