import React, { PureComponent } from 'react'
import PT from 'prop-types'
import { View, StyleSheet } from 'react-native'
import { Text } from 'react-native-paper'

import { connect } from 'react-redux'
import LabelRow from './LabelRow'
import c from '../constants'
import { getProductData } from './selectors'
import KeyboardAvoidingScrollView from '../WidthAndHeight/KeyboardAvoidingScrollView'
import Resizer from '../WidthAndHeight/Resizer'
import widthAndHeightHOC from '../WidthAndHeight/widthAndHeightHOC'

const styles = StyleSheet.create({
  container: {
    padding: c.textOffset / 2,
  },
})

class ProductSafetyCodes extends PureComponent {
  render() {
    const { safetyCodes, isMedium } = this.props
    return (
      <KeyboardAvoidingScrollView
        safeSettings={{ left: true, bottom: true, right: true }}
      >
        <Resizer>
          {(!safetyCodes || safetyCodes.length === 0) && (
            <Text style={styles.container}>Geen veiligheidscodes gevonden</Text>
          )}
          <View
            style={[isMedium && { flexDirection: 'row', flexWrap: 'wrap' }]}
          >
            {(safetyCodes || []).map(({ code, statement }, index) => (
              <View style={[isMedium && { width: '50%' }]} key={index}>
                <LabelRow
                  key={index}
                  index={index}
                  label={code}
                  value={statement || '-'}
                />
              </View>
            ))}
          </View>
        </Resizer>
      </KeyboardAvoidingScrollView>
    )
  }
}

ProductSafetyCodes.propTypes = {
  safetyCodes: PT.arrayOf(
    PT.shape({
      id: PT.string.isRequired,
      code: PT.string.isRequired,
      statement: PT.string,
    })
  ),
}

const mapStateToProps = state => {
  return {
    safetyCodes: getProductData(state).safetyWarnings,
  }
}

export default widthAndHeightHOC(connect(mapStateToProps)(ProductSafetyCodes))
