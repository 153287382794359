import translations from './translations'

let language = 'nl'

const twoToBCP = {
  fr: 'fr-FR',
  nl: 'nl-NL',
  de: 'de-DE',
  en: 'en-US',
}

export const setLanguage = l => {
  //   if (process.env.NODE_ENV !== 'development') {
  language = l
  //   }
}
export function getLanguage() {
  return language
}

export const twoLetterToBCP = l => {
  return twoToBCP[l] || 'en-US'
}

export const getTranslation = (object, key) => {
  if (!object) {
    return ''
  }
  const translations = object[`${key}Translations`] || {}
  const value = translations[language]
  if (!value) {
    return object[key] || ''
  }
  return value
}

export const getTranslatedString = (string, lang) => {
  return translations[string][lang]
}
