import actions from './actions'
import a from './actions'
import reducer from '../GeneralListFunctions/reducerFactory'

const initialState = {
  duplicating: false,
  duplicatingError: false,
  duplicated: false,
  data: null,
}

export function duplicateReducer(
  state = initialState,
  { type, payload, error }
) {
  switch (type) {
    case a.DUPLICATE: {
      return {
        ...state,
        duplicating: true,
        duplicatingError: false,
        duplicated: false,
        data: null,
      }
    }
    case a.DUPLICATED: {
      return error
        ? {
            ...state,
            duplicating: false,
            duplicatingError: true,
            duplicated: false,
            data: null,
          }
        : {
            ...state,
            duplicating: false,
            duplicatingError: false,
            duplicated: true,
            data: payload,
          }
    }
    case a.DUPLICATE_RESET: {
      return {
        ...state,
        duplicating: false,
        duplicated: false,
        duplicatingError: false,
        duplicateReset: true,
        data: null,
      }
    }
    default:
      return state
  }
}
export default reducer(actions)
