import React, { useCallback, useMemo } from 'react'
import { Linking, Platform, StyleSheet, View } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import {
  Appbar,
  Button,
  Modal,
  Portal,
  Snackbar,
  Text,
  Title,
} from 'react-native-paper'
import { push } from '../Navigation'
import * as routes from '../Routes.config'
import actions from './actions'
import PageHeader from '../PageHeader'
import GeneralList from '../GeneralListFunctions/GeneralList'
import { useActionsAsFunctions } from '../helpers/actions'
import SafeListItem from '../WidthAndHeight/SafeListItem'
import { fromTimestamp, getFriendlyDate } from '../dayjs'
import BottomFab from '../WidthAndHeight/BottomFab'
import { Translate, Translator } from 'react-translated'

function ObservationScreen({ componentId }) {
  const list = useSelector(state => state.observation.list)
  const crud = useSelector(state => state.observation.crud)

  const extraParams = useMemo(() => {
    return {
      sort: '-date',
      preloadAll: true,
      'filter[organizationIds]': undefined,
    }
  }, [])

  const onPressAdd = useCallback(
    () =>
      push(componentId, {
        component: {
          name: routes.NEW_OBSERVATION_ROUTE,
        },
      }),
    [componentId]
  )

  const dispatch = useDispatch()
  const [selected] = React.useState([])
  const [showLangSelector, setShowLangSelector] = React.useState(false)

  const [showDownloadModal, setShowDownloadModal] = React.useState(false)

  const createAdvice = useCallback(
    lang => {
      dispatch(
        actions.createAdvice(undefined, {
          extraBody: { observations: selected },
          params: { language: lang },
        })
      )
    },
    [dispatch, selected]
  )

  const renderItem = useCallback(
    ({ item }) => {
      let title = item?.growingPeriod?.organizationField?.organization?.name

      return (
        <SafeListItem
          title={title}
          description={`${getFriendlyDate(fromTimestamp(item.date))}${
            item?.growingPeriod?.crop?.name &&
            item?.growingPeriod?.crop?.name.length > 0
              ? ` - ${item?.growingPeriod?.crop?.name}`
              : ''
          }${
            item?.growingPeriod?.organizationField?.name &&
            item?.growingPeriod?.organizationField?.name.length > 0
              ? ` - ${item?.growingPeriod?.organizationField?.name}`
              : ''
          }`}
          onPress={() => {
            if (Platform.OS !== 'web') {
              // We only push on native, because on web we use "to". This is because "to" has extra hover effects on web.
              push(componentId, {
                component: {
                  name: routes.NEW_OBSERVATION_ROUTE,
                  passProps: {
                    id: item.id,
                    step: 2,
                  },
                },
              })
            }
          }}
          // left={
          //   <SelectionComponent
          //     selectType={'checkbox'}
          //     status={isSelected ? 'checked' : 'unchecked'}
          //     onPress={() => select(item)}
          //   />
          // }
          accessibilityLabel={`Open ${item.name}`}
          to={routes.NEW_OBSERVATION_ROUTE + '2/' + item.id}
          style={styles.listItem}
        />
      )
    },
    [componentId]
  )

  const [error, setError] = React.useState(undefined)

  React.useEffect(() => {
    if (crud.createAdviceError) {
      setError(crud.createAdviceErrorMessage)
    }
    if (crud.createdAdvice) {
      // setShowDownloadModal(true)
    }
  }, [crud])

  const dispatchedActions = useActionsAsFunctions(actions)

  return [
    <Translator>
      {({ translate }) => (
        <GeneralList
          key={'generalList'}
          {...dispatchedActions}
          list={list}
          searchPlaceholder={translate({ text: 'searchObservations' })}
          renderItem={renderItem}
          icon
          appbarContent={
            <PageHeader
              // back={() => back()}
              componentId={componentId}
              title={
                <Appbar.Content
                  title={translate({ text: 'observations' })}
                  titleStyle={{
                    textAlign: 'center',
                  }}
                  subtitleStyle={{
                    textAlign: 'center',
                  }}
                />
              }
            />
          }
          extraParams={extraParams}
          onPressFab={onPressAdd}
          fabIcon={'plus'}
          fabLabel={translate({ text: 'newObservation' })}
        />
      )}
    </Translator>,
    <Portal key={'createAdviceButton'}>
      {selected.length > 0 && (
        <BottomFab
          icon={'download'}
          label={<Translate text={'generateAdvice'} />}
          onPress={() => {
            setShowLangSelector(true)
          }}
          loading={crud.creatingAdvice}
          keyboardHeight={64}
          align={'right'}
        />
      )}
    </Portal>,
    <Portal key={'snackbar'}>
      <Snackbar
        visible={!!error}
        onDismiss={() => setError(undefined)}
        action={{
          label: 'OK',
          color: '#FFF',
          onPress: () => {
            setError(undefined)
          },
        }}
      >
        {error}
      </Snackbar>
    </Portal>,
    <Portal key={'langmodal'}>
      <Modal
        visible={showLangSelector}
        onDismiss={() => setShowLangSelector(false)}
        dismissable
        contentContainerStyle={{
          backgroundColor: '#FFF',
          borderRadius: 10,
          maxWidth: 700,
          width: '100%',
          alignSelf: 'center',
          padding: 16,
        }}
      >
        <Title>
          <Translate text={'chooseLanguageAdvice'} />
        </Title>

        <View style={{ flexDirection: 'row', marginTop: 8 }}>
          <Button
            onPress={() => {
              setShowLangSelector(false)
              createAdvice('nl')
            }}
            mode={'contained'}
            style={{ marginRight: 16 }}
          >
            <Translate text={'dutch'} />
          </Button>
          <Button
            mode={'contained'}
            onPress={() => {
              setShowLangSelector(false)
              createAdvice('en')
            }}
          >
            <Translate text={'english'} />
          </Button>
        </View>
      </Modal>
    </Portal>,
    <Portal key={'downloadModal'}>
      <Modal
        visible={showDownloadModal}
        onDismiss={() => setShowDownloadModal(false)}
        dismissable
        contentContainerStyle={{
          backgroundColor: '#FFF',
          borderRadius: 10,
          maxWidth: 700,
          width: '100%',
          alignSelf: 'center',
          padding: 16,
        }}
      >
        <Title>
          <Translate text={'adviceIsGenerated'} />
        </Title>
        <View style={{ marginTop: 8 }}>
          <Text>
            <Translate text={'adviceFindBack'} />
          </Text>
          <View style={{ flexDirection: 'row' }}>
            <Button
              onPress={() => {
                if (crud?.advice?.url) {
                  if (Platform.OS === 'web') {
                    window.open(crud.advice.url, '_blank')
                  } else {
                    Linking.openURL(crud.advice.url)
                  }
                }
                setShowDownloadModal(false)
                dispatch(actions.reset())
              }}
              mode={'contained'}
              style={{ marginTop: 16 }}
            >
              <Translate text={'openAdvicePDF'} />
            </Button>
          </View>
        </View>
      </Modal>
    </Portal>,
  ]
}

const styles = StyleSheet.create({
  tabs: { flexDirection: 'row', paddingLeft: 5 },
  tab: {},
  tabText: {
    paddingLeft: 16,
    paddingRight: 16,
    textAlignVertical: 'center',
    letterSpacing: 0.5,
    height: 46,
    lineHeight: 46,
  },
  tabTextSelected: {
    fontWeight: 'bold',
  },
})

export default ObservationScreen
