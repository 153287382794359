import React from 'react'

import { propTypes, defaultProps, color } from './standardVector'

export const width = 15
export const height = 14

const Vector = props => (
  <svg
    width={props.width || width}
    height={props.height || height}
    className={props.className}
    viewBox="0 0 15 14"
  >
    <path
      fill={color(props.color)}
      fillRule="evenodd"
      d="M18.7071068,18.2928932 C19.0976311,18.6834175 19.0976311,19.3165825 18.7071068,19.7071068 C18.3165825,20.0976311 17.6834175,20.0976311 17.2928932,19.7071068 L14.2928932,16.7071068 C14.0976311,16.5118446 14,16.2559223 14,16 C14,15.7440777 14.0976311,15.4881554 14.2928932,15.2928932 L17.2928932,12.2928932 C17.6834175,11.9023689 18.3165825,11.9023689 18.7071068,12.2928932 C19.0976311,12.6834175 19.0976311,13.3165825 18.7071068,13.7071068 L17.4142136,15 L23.0024554,15 C23.553384,15 24,15.4438648 24,16 C24,16.5522847 23.5536886,17 23.0024554,17 L17.4142136,17 L18.7071068,18.2928932 Z M9,10.0068455 C9,9.45078007 9.44386482,9 10,9 C10.5522847,9 11,9.44994876 11,10.0068455 L11,21.9931545 C11,22.5492199 10.5561352,23 10,23 C9.44771525,23 9,22.5500512 9,21.9931545 L9,10.0068455 Z"
      transform="translate(-9 -9)"
    />
  </svg>
)

Vector.propTypes = propTypes
Vector.defaultProps = defaultProps

export default Vector
