import * as React from 'react'
import { StyleSheet } from 'react-native'
import { Appbar } from 'react-native-paper'
import { useDispatch, useSelector } from 'react-redux'
import Navigation from '../../Navigation'

import * as actions from './actions'

import { FilterType } from '../config'
import AppbarHeader from '../../WidthAndHeight/AppbarHeader'
import AppbarResizer from '../../WidthAndHeight/AppbarResizer'
import widthAndHeightHOC from '../../WidthAndHeight/widthAndHeightHOC'
import safeAreaHOC from '../../WidthAndHeight/safeAreaHOC'
import KeyboardAvoidingScrollView from '../../WidthAndHeight/KeyboardAvoidingScrollView'
import Resizer from '../../WidthAndHeight/Resizer'

import ItemInList from '../ItemInList'
import { RootState } from '../../reducers'
import AquaticBufferZoneChoose from './AquaticBufferZone/AquaticBufferZoneChoose'

const safeSettings = { bottom: true, left: true, right: true }

function ParcelCloseToWaterChoose({
  componentId,
  filterType,
}: {
  componentId: string
  filterType: FilterType
}) {
  const dispatch = useDispatch()
  const enabled = useSelector(
    (state: RootState) => state.filter.parcelCloseToWater
  )
  const onBack = () => {
    Navigation.pop(componentId)
  }

  const onSelect = () => {
    dispatch(actions.check())
    Navigation.pop(componentId)
  }

  const onDeselect = () => {
    dispatch(actions.uncheck())
    Navigation.pop(componentId)
  }

  return (
    <>
      <AppbarHeader
        key="header"
        dark
        style={{
          justifyContent: 'center',
        }}
      >
        <AppbarResizer>
          <Appbar.BackAction onPress={onBack} color="#fff" testID={'back'} />
          <Appbar.Content title={filterType.label} color="#fff" />
        </AppbarResizer>
      </AppbarHeader>
      <KeyboardAvoidingScrollView
        style={styles.fill}
        safeSettings={safeSettings}
      >
        <Resizer>
          <ItemInList
            onPress={onSelect}
            onSelect={onSelect}
            item={{ parcelCloseToWater: true }}
            filterType={filterType}
            isSelected={enabled === true}
            selectType="radiobutton"
          />
          <ItemInList
            onPress={onDeselect}
            onSelect={onDeselect}
            item={{ parcelCloseToWater: false }}
            filterType={filterType}
            isSelected={enabled === false}
            selectType="radiobutton"
          />
          <AquaticBufferZoneChoose
            componentId={componentId}
            filterType={filterType}
          />
        </Resizer>
      </KeyboardAvoidingScrollView>
    </>
  )
}

const styles = StyleSheet.create({
  fill: {
    flex: 1,
  },
})

export default safeAreaHOC(widthAndHeightHOC(ParcelCloseToWaterChoose))
