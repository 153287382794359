import React, { useCallback, useMemo } from 'react'
import actions from './actions'
import PageHeader from '../PageHeader'
import DeepLink from '../DeepLink'
import GeneralList from '../GeneralListFunctions/GeneralList'
import { Appbar } from 'react-native-paper'
import { connect, useDispatch } from 'react-redux'
import { getFirstSelectedId } from '../FilterType/config'
import GroupedCropItem from './GroupedCropItem'
import AdvisorFilter from '../AdvisorFilter'
import { hasRole } from '../helpers/role'
import idx from 'idx'
import { push } from '../Navigation'
import { Translator } from 'react-translated'
import * as routes from '../Routes.config'
import cropActions from '../FilterType/Crop/actions'
import qmActions from '../FilterType/Switches/Certification/actions'
import { View } from 'react-native'

const preloads = [
  'organizationField',
  'organizationField.field',
  'organizationField.organization',
]

function GroupedCropsListScreen(props) {
  const { selectedFarmerId, componentId, user, selectedGrowingPlan } = props

  const dispatch = useDispatch()
  const setFilters = useCallback(
    (skal, planetProof, demeter, mps, none) => {
      dispatch(
        qmActions.selectKeyValue({ skal, planetProof, demeter, mps, none })
      )
    },
    [dispatch]
  )
  const onPressCrop = useCallback(
    crop => {
      console.log({ crop })
      const c = crop.growingPeriods?.[0]?.crop
      const qmSkal = crop?.certification === 'SKAL'
      const qmPlanetProof = crop?.certification === 'PLANETPROOF'
      const qmDemeter = crop?.certification === 'DEMETER'
      const qmMps = crop?.certification === 'MPS'
      if (c) {
        setFilters(
          qmSkal,
          qmPlanetProof,
          qmDemeter,
          qmMps,
          !qmSkal && !qmPlanetProof && !qmDemeter && !qmMps
        )
        dispatch(
          cropActions.select(c, { singleSelect: true, noTracking: true })
        )
        push(props.componentId, {
          component: {
            name: routes.FIELDS_ROUTE,
            passProps: {
              fromRoute: 'crop',
            },
          },
        })
      }
    },
    [props.componentId, dispatch, setFilters]
  )
  const extraParams = useMemo(
    () => ({
      // onlyUserManaged: true,
      _organizationId: selectedFarmerId,
      sort: 'name',
      growingPlan: selectedGrowingPlan,
    }),
    [selectedFarmerId, selectedGrowingPlan]
  )
  const renderItem = useCallback(
    ({ item }) => (
      <GroupedCropItem
        item={item}
        componentId={componentId}
        onPress={onPressCrop}
      />
    ),
    [componentId, onPressCrop]
  )

  const renderStickyBelowHeader = useCallback(() => {
    return (
      <AdvisorFilter
        key="filter-tool"
        componentId={componentId}
        context="grouped-crop"
      />
    )
  }, [componentId])
  // const onPressAdd = useCallback(
  //   () =>
  //     push(componentId, {
  //       component: {
  //         name: routes.NEW_GROWING_PERIOD_SCREEN,
  //       },
  //     }),
  //   [componentId]
  // )

  const isFarmer = hasRole(user, 'farmer')
  const isHorticulturist = hasRole(user, 'horticulturist')
  return (
    <View
      key={
        props?.list?.data?.length + '_list_' + props?.list?.data?.[0]?.crop?.id
      }
    >
      <Translator>
        {({ translate }) => (
          <GeneralList
            {...props}
            searchPlaceholder={translate({ text: 'searchCrop' })}
            renderItem={renderItem}
            isFarmer={isFarmer || isHorticulturist}
            icon
            renderStickyBelowHeader={renderStickyBelowHeader}
            // fabLabel={translate({ text: 'new' })}
            // onPressFab={onPressAdd}
            appbarContent={
              <PageHeader
                // back={() => back()}
                componentId={componentId}
                title={
                  <Appbar.Content
                    title={translate({ text: 'cropsSideMenu' })}
                    titleStyle={{
                      textAlign: 'center',
                    }}
                    subtitleStyle={{
                      textAlign: 'center',
                    }}
                  />
                }
              />
            }
            extraParams={extraParams}
            preloads={preloads}
          />
        )}
      </Translator>
      <DeepLink loggedIn={true} componentId={componentId} />
    </View>
  )
}

const mapStateToProps = state => ({
  list: state.groupedCrop.list,
  selectedFarmerId: getFirstSelectedId(state.filter.advisorFarmer?.selected),
  selectedGrowingPlan: getFirstSelectedId(state.filter.growingPlan?.selected),
  user: idx(state, _ => _.auth.user.user),
})

export default connect(mapStateToProps, actions)(GroupedCropsListScreen)
