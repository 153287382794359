import React, {useCallback, useEffect, useState} from 'react'
import {Platform, StyleSheet, useWindowDimensions, View} from 'react-native'
import {Appbar, Button, Caption, HelperText, Snackbar, Text, TextInput, Title,} from 'react-native-paper'
import AppbarResizer from '../WidthAndHeight/AppbarResizer'
import FormContainer from '../Form/FormContainer'
import KeyboardAvoidingScrollView from '../WidthAndHeight/KeyboardAvoidingScrollView'
import keyboardHOC from '../WidthAndHeight/keyboardHOC'
import AppbarHeader from '../WidthAndHeight/AppbarHeader'
import {get} from 'lodash'
import {useDispatch, useSelector} from 'react-redux'
import registerActions from './logic/actions'
// @ts-ignore
import {Translate, Translator} from 'react-translated'
import Navigation, {push} from '../Navigation'
import * as kvkActions from '../Farmer/Organization/kvk/actions'
import Touchable from '../WidthAndHeight/Touchable'
import List from '../GeneralListFunctions/List'
import {identifyAfterRegister, pushCustomEvent,} from '../Analytics/abstraction'
import detailHOC from '../GeneralListFunctions/detailHOC'
import * as routes from '../Routes.config'
import Fields from '../Form/Fields'
import {getLanguage} from '../language'
import c from '../constants'
import constants from '../constants'
import SafariView from './SafariView'

import a from './providerLogic/actions'
// @ts-ignore
import {getLoginPage} from 'react-auth/lib/promises'
// @ts-ignore
import {getRequestCodeFromHTML} from 'react-auth/lib/utils'
// @ts-ignore
import {clientRedirectUri} from 'react-auth/lib/config'
// @ts-ignore
import {authPath} from 'react-api'
// @ts-ignore
import {CustomTabs} from 'react-native-custom-tabs'
import ErrorMessages from '../components/ErrorMessages'
import useQueryParameter from "../helpers/queryParameter";

const fieldProps = {
  style: { marginBottom: 16 },
}

const emailField = (translate: (params: { text: string }) => string) => [
  {
    name: 'user.email',
    label: translate({
      text: 'email',
    }),
    type: 'email',

    fieldProps: { ...fieldProps, autoFocus: true, required: true },
  },
]

const userFields = (translate: (params: { text: string }) => string) => [
  {
    name: 'user.firstName',
    label: translate({
      text: 'firstName',
    }),
    type: 'text',
    fieldProps: { ...fieldProps, required: true },
  },
  {
    name: 'user.preposition',
    label: translate({
      text: 'preposition',
    }),
    type: 'text',

    fieldProps: { ...fieldProps },
  },
  {
    name: 'user.lastName',
    label: translate({
      text: 'lastName',
    }),
    type: 'text',

    fieldProps: { ...fieldProps, required: true },
  },
  {
    name: 'user.language',
    label: translate({
      text: 'language',
    }),
    type: 'language',

    fieldProps: { ...fieldProps, required: true },
  },
  // {
  //   name: 'user.approval',
  //   label: translate({
  //     text: 'approval',
  //   }),
  //   type: 'checkbox',
  //   fieldProps: { ...fieldProps, required: false },
  // },
]

function Register({
  object,
  onChange,
  onSubmit,
  componentId,
  keyboardHeight,
  sending,
  url,
}: {
  object: any
  onChange: (name: string, value: any) => void
  onSubmit: (payload: any) => void
  componentId: string
  keyboardHeight?: any
  sending: any
  url: any
}) {
  const crud = useSelector((state: any) => state.requestAccount.crud)
  useEffect(() => {
    // Nieuwe accountaanvraag direct via registratie URL.
    pushCustomEvent(`Opened account request form`, ``)
  }, [])
  // useEffect(() => {}, [])

  const completed = useSelector(
    (state: any) =>
      state.requestAccount &&
      state.requestAccount.crud &&
      state.requestAccount.crud.created
  )
  const error = useSelector(
    (state: any) =>
      state.requestAccount &&
      state.requestAccount.crud &&
      state.requestAccount.crud.creatingError
  )
  const provider = useSelector(
    (state: any) =>
      state.provider && state.provider.list && state.provider.list.listGetter
  )
  const providerErrorMessage = useSelector(
    (state: any) =>
      state.provider &&
      state.provider.list &&
      state.provider.list.loadingListErrorMessage
  )
  const dispatch = useDispatch()

  const [search, setSearch] = useState('')
  const searchList = useSelector((state: any) => state.farmer.kvk.list)

  const [showDetails, setShowDetails] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  const [hideErrors, setHideErrors] = useState(true)
  const [externalProviderPossible, setExternalProviderPossible] =
    useState(false)

  // Use searchref to fix stupid closure problem...
  const searchRef: any = React.useRef([])
  useEffect(() => {
    searchRef.current = search
    return () => {}
  }, [search])

  const referral = useQueryParameter(url, "referral")
  const knpvFlow = referral === String(constants.knpvOrganizationID)

  const onSearch = () => {
    // Use searchref to fix stupid closure problem...
    dispatch(
      kvkActions.default.loadList({
        params: {
          name: searchRef.current || '',
          // onlyUserManaged: false,
          sort: 'name',
          limit: 10,
        },
      })
    )
  }
  const userLang = getLanguage()

  const [values, setValues] = React.useState({
    ...((object && object.crud) || {}),
    user: {
      ...((object && object.crud && object.crud.user) || {}),
      language:
        userLang === 'nl'
          ? 'nl'
          : userLang === 'en_ca'
          ? 'en'
          : userLang === 'en_us'
          ? 'en'
          : userLang === 'en_gb'
          ? 'en'
          : 'en',
    },
    organization: {
      ...((object && object.crud && object.crud.organization) || {}),
      addresses: [
          ...((object && object.crud && object.crud.organization && object.crud.organization.addresses && object.crud.organization.addresses) || [{
            country: 'Nederland',
          }])
      ],
      settings: {
        ...((object && object.crud && object.crud.organization && object.crud.organization.settings) || {}),
        ...((referral && referral !== "" && {
          rolloutPartnerId: referral
        }) || {}),
        ...((knpvFlow && {
          personal: "true"
        }) || {}),
      }
    }
  })

  const [visible, setVisible] = React.useState(false)
  const [validationError, setValidationError] = React.useState(false)

  const change = useCallback(
    (name: string, inputVal: any) => {
      let value = inputVal
      // if (name === 'organization.identificationNumber') {
      //   value = Number(value)
      // }
      onChange(name, value)
      setHideErrors(true)
      if (name === 'organization') {
        setValues({
          ...values,
          user: { ...values.user },
          organization: value,
        })
      } else if (name.includes('user')) {
        if (name.includes('email')) {
          setShowDetails(false)
          setExternalProviderPossible(false)
        }
        setValues({
          ...values,
          user: { ...values.user, [name.split('.')[1]]: value },
          organization: { ...values.organization },
        })
      } else {
        setValues({
          ...values,
          user: { ...values.user },
          organization: { ...values.organization, [name.split('.')[1]]: value },
        })
      }
      //send logs to mixpanel when user is typing
      // pushCustomEvent(`Changed account request form data`, values)
      if (visible) {
        setVisible(false)
      }
    },
    [onChange, values, visible]
  )

  const renderKvkItem = useCallback(
    ({ item }) => {
      return (
        <Touchable
          key={item.kvkNumber}
          description={item.kvkNumber}
          title={item.tradeName}
          componentId={componentId}
          onPress={() => {
            change('organization', {
              name: item.tradeName,
              identificationNumber: item.kvkNumber,
              addresses: item.addresses,
            })
            setSearch('')
            return dispatch(kvkActions.default.reset())
          }}
        />
      )
    },
    [componentId, change, dispatch]
  )

  const checkEmail = () => {
    let retVal = true
    const bool = checkRequired(['user.email'].filter(n => n))

    const emailRegex =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
    if (bool.some((it: boolean) => it)) {
      retVal = false
    } else if (!emailRegex.test(values.user.email)) {
      retVal = false
    }
    return retVal
  }

  const checkAddress = () => {
    return values.organization.addresses &&
        values.organization.addresses.length > 0 &&
        values.organization.addresses[0] &&
        !checkRequired([
            'organization.addresses[0].city',
            'organization.addresses[0].houseNumber',
            'organization.addresses[0].street',
            'organization.addresses[0].country'
        ]).filter(n => n).some((it: boolean) => it)
  }

  const checkUser = () => {
    let retVal = true
    const bool = checkRequired(
        [
          'user.firstName',
          'user.lastName',
          'user.language',
        ].filter(n => n)
    )

    if (bool.some((it: boolean) => it)) {
      retVal = false
    } else if (!checkEmail()) {
      retVal = false
    }
    return retVal
  }

  const checkAll = () => {
    let retVal = true
    const bool = checkRequired(
      [
        // process.env.REACT_APP_API_LANG === 'nl_nl' &&
        'organization.name',
        'organization.addresses',
      ].filter(n => n)
    )

    const numberOK = (searchList.loadingListError ||
          (searchList.loadedList && searchList.data.length === 0)) ? true :
        checkRequired(['organization.identificationNumber'].filter(n => n)).some((it: boolean) => !it)

    if (bool.some((it: boolean) => it)) {
      retVal = false
    } else if (!checkAddress()) {
      retVal = false
    } else if (!numberOK) {
      retVal = false
    } else if (!checkUser()) {
      retVal = false
    }
    return retVal
  }

  const submit = () => {
    if (!showDetails) {
      pushCustomEvent(`Pressed next button after entering e-mail`, ``, {
        email: values.user.email,
      })
      dispatch(
        a.loadList({
          // @ts-ignore
          disableAuthentication: true,
          params: {
            email: values.user.email,
          },
        })
      )
      return
    }

    if (!checkAll()) {

      // Check if KNPV flow is OK, if so, continue with that
      if (knpvFlow && checkUser()) {
        pushCustomEvent(
            `Trying to process private individual account request in the backend`,
            undefined,
            values
        )
        onSubmit({
          payload: {
            ...values,
            organization: {
              ...values?.organization,
              name: `${values?.user?.firstName} ${(values?.user?.preposition ? values?.user?.preposition + ' ' : '') + values?.user?.lastName}`,
              email: values?.user?.email ? values?.user?.email : null,
            },
            disableAuthentication: true,
          },
        })
      } else {
        setValidationError(true)
      }
    } else {
      pushCustomEvent(
        `Trying to process account request in the backend`,
        undefined,
        values
      )
      onSubmit({
        payload: {
          ...values,
          organization: {
            ...values?.organization,
            addresses: values?.organization?.addresses?.map((it: any) => {
              return {
                ...it,
                type: 'work',
              }
            }),
          },
          disableAuthentication: true,
        },
      })
    }
  }
  useEffect(() => {
    if (!!completed) {
      // Wanneer account succesvol is aangemaakt.
      // pushCustomEvent(`Succesfully registered account`, undefined, crud?.object)
      identifyAfterRegister(crud?.object)
    }
  }, [completed, crud])
  const checkRequired = (checkArr: any[]) => {
    return checkArr.map(it => {
      return !get(values, it)
    })
  }

  const pop = () => {
    Navigation.pop(componentId)
  }

  const debouncedInput = useCallback(
    (name, value) => {
      setSearch(value)
      change(name, value)
    },
    [change]
  )
  const dismiss = () => {
    push(componentId, {
      component: {
        name: routes.HOME_ROUTE,
      },
    })
  }

  const loginWithConnector = (connector: any) => {
    getLoginPage(clientRedirectUri)
      .then((response: any) => {
        const reqId = getRequestCodeFromHTML(response)

        const realPath = authPath

        const url = `${realPath}auth/${connector}?req=${reqId}`
        if (Platform.OS === 'web') {
          window.open(url, '_self')
        }

        if (Platform.OS === 'android') {
          CustomTabs.openURL(url, {
            toolbarColor: c.greenColor,
            showPageTitle: true,
            forceCloseOnRedirection: true,
          }).catch((err: any) => {
            setErrors(['Chrome browser niet geïnstalleerd'])
            setHideErrors(false)
          })
        }

        if (Platform.OS === 'ios') {
          // @ts-ignore
          SafariView.isAvailable()
            .then((available: any) => {
              SafariView.show({
                url,
                tintColor: '#fff',
                barTintColor: c.greenColor,
              })
            })
            .catch((error: any) => {
              setErrors(['Safari niet geïnstalleerd'])
              setHideErrors(false)
            })
        }
      })
      .catch((error: any) => {
        setErrors([error])
        setHideErrors(false)
      })
  }

  const loginMicrosoft = () => {
    // Tijdens registratie wordt ontdekt dat iemand probeert te registeren met een SSO Microsoft account.
    pushCustomEvent(`Registration page, pressed`, ``, values?.user?.email)
    loginWithConnector('microsoft')
  }

  // const continueWithoutExternalProvider = () => {
  //   pushCustomEvent(
  //     'Registration page, pressed',
  //     'Continue without Microsoft',
  //     values?.user?.email
  //   )
  //   setShowDetails(true)
  //   setExternalProviderPossible(false)
  // }

  useEffect(() => {
    if (provider && provider?.providers && provider?.providers?.length > 0) {
      if (provider?.providers?.some((pro: any) => pro === 'LOCAL')) {
        setShowDetails(true)
        setExternalProviderPossible(false)
      } else if (provider?.providers?.some((pro: any) => pro === 'MICROSOFT')) {
        console.log('This person can sign in with microsoft')
        setExternalProviderPossible(true)
      }
    } else {
      setExternalProviderPossible(false)
    }
  }, [provider])

  useEffect(() => {
    setErrors(providerErrorMessage)
    setHideErrors(false)
  }, [providerErrorMessage])

  const dimensions = useWindowDimensions()
  return [
    <AppbarHeader
      key="header"
      style={{
        alignItems: 'center',
        justifyContent: 'center',
      }}
      dark={true}
    >
      <AppbarResizer>
        <Appbar.BackAction color="#fff" onPress={pop} />
        <Appbar.Content title={<Translate text={'register'} />} />
      </AppbarResizer>
    </AppbarHeader>,
    <KeyboardAvoidingScrollView
      key="avoiding-scroll"
      keyboardShouldPersistTaps={'always'}
      testID={'requestAccountContainer'}
      keyboardDismissMode={'on-drag'}
      safeSettings={{ top: false, bottom: false, left: true, right: true }}
    >
      <FormContainer key="form-container" onSubmit={submit}>
        <View style={styles.loginContainer}>
          {/* <Image style={styles.logo} source={Logo} /> */}

          <View style={styles.resize}>
            <View>
              <Translator>
                {({
                  translate,
                }: {
                  translate: (params: { text: string }) => string
                }) => (
                  <>
                    <Title>{translate({ text: 'userTitle' })}</Title>
                    <Fields
                      fields={emailField(translate)}
                      values={values}
                      onChange={change}
                      onSubmit={submit}
                    />
                    {!showDetails ? (
                      <>
                        {externalProviderPossible ? (
                          <>
                            <Text
                              onPressIn={undefined}
                              onPressOut={undefined}
                              android_hyphenationFrequency={undefined}
                              style={{ fontSize: 16 }}
                            >
                              {translate({ text: 'loginMicrosoftPossible' })}
                            </Text>
                            <View style={styles.buttonBox}>
                              <Button
                                testID={'continueWithMicrosoft'}
                                mode={'contained'}
                                onPress={loginMicrosoft}
                                loading={crud.creating}
                                disabled={
                                  !externalProviderPossible || !checkEmail()
                                }
                                uppercase={false}
                                contentStyle={styles.buttonContentStyle}
                              >
                                {translate({ text: 'continueWithMicrosoft' })}
                              </Button>
                              {/*<Button*/}
                              {/*  testID={'continueWithoutMicrosoft'}*/}
                              {/*  onPress={continueWithoutExternalProvider}*/}
                              {/*  loading={crud.creating}*/}
                              {/*  disabled={*/}
                              {/*    !externalProviderPossible || !checkEmail()*/}
                              {/*  }*/}
                              {/*  uppercase={false}*/}
                              {/*  contentStyle={styles.buttonContentStyle}*/}
                              {/*>*/}
                              {/*  {translate({*/}
                              {/*    text: 'continueWithoutMicrosoft',*/}
                              {/*  })}*/}
                              {/*</Button>*/}
                            </View>
                          </>
                        ) : (
                          <View style={styles.buttonBox}>
                            <Button
                              testID={'requestAccountSubmit'}
                              mode={'contained'}
                              onPress={submit}
                              loading={crud.creating}
                              disabled={!checkEmail()}
                              uppercase={false}
                              contentStyle={styles.buttonContentStyle}
                            >
                              <Translate text="next" />
                            </Button>
                          </View>
                        )}
                      </>
                    ) : (
                      <>
                        <Fields
                          fields={userFields(translate)}
                          values={values}
                          onChange={change}
                          onSubmit={submit}
                        />

                        <Title>
                          {translate({ text: 'organizationTitle' })}
                        </Title>

                        <>
                          <TextInput
                            key={'organization.name'}
                            label={translate({
                              text: 'companyName',
                            })}
                            value={get(values, 'organization.name')}
                            error={false}
                            onPressIn={() => null}
                            style={{
                              backgroundColor: '#fff',
                            }}
                            onPressOut={() => null}
                            mode={'flat'}
                            autoComplete={false}
                            onChangeText={value => {
                              const key = 'organization.name'
                              debouncedInput(key, value)
                            }}
                            onSubmitEditing={event => {
                              onSearch()
                            }}
                            onFocus={() =>
                              pushCustomEvent(
                                'Focused field:',
                                'organization.name'
                              )
                            }
                          />
                          <HelperText
                            type="error"
                            visible={false}
                            onPressIn={() => null}
                            onPressOut={() => null}
                            android_hyphenationFrequency={null}
                          >
                            Email address is invalid!
                          </HelperText>
                        </>
                        {search !== '' && (
                          <>
                            <View style={{ marginBottom: 12 }}>
                              <Button
                                icon="magnify"
                                onPress={onSearch}
                                uppercase={false}
                                color="#000"
                                loading={searchList.loadingList}
                              >
                                <Translate text={'Zoek in de KvK resultaten'} />
                              </Button>
                            </View>
                            {searchList.loadingListError && (
                              <View style={{ marginBottom: 12 }}>
                                <Translate text={'errorKvk'} />
                              </View>
                            )}
                          </>
                        )}
                        {searchList.loadedList &&
                          !searchList.loadingListError && (
                            <List
                              list={searchList}
                              data={searchList.data}
                              withoutVirtualization
                              renderItem={renderKvkItem}
                              customEmptyDescription={
                                <Translate text={'noResultsDesc'} />
                              }
                              customEmptyTitle={
                                <Translate text={'noResults'} />
                              }
                              smallEmpty
                            />
                          )}
                        <View
                          style={{
                            flexDirection:
                              dimensions.width > 600 ? 'row' : 'column',
                            alignItems: 'center',
                          }}
                        >
                          <TextInput
                            key={'street'}
                            label={translate({
                              text: 'street',
                            })}
                            value={
                              values?.organization?.addresses?.length > 0 &&
                              values?.organization?.addresses[0].street
                                ? values?.organization?.addresses[0].street
                                : ''
                            }
                            onChangeText={value => {
                              const key = 'organization'
                              const newAddress = {
                                ...values?.organization?.addresses?.[0],
                                street: value,
                              }
                              change(key, {
                                ...values?.organization,
                                addresses: [newAddress],
                              })
                            }}
                            onSubmitEditing={event => {
                              submit()
                            }}
                            error={false}
                            onPressIn={() => null}
                            style={{
                              width: dimensions.width > 600 ? '50%' : '100%',
                              backgroundColor: '#fff',
                            }}
                            onPressOut={() => null}
                            mode={'flat'}
                            autoComplete={false}
                            onFocus={() =>
                              pushCustomEvent(
                                'Focused field:',
                                'organization.street'
                              )
                            }
                          />
                          <HelperText
                            type="error"
                            visible={false}
                            onPressIn={() => null}
                            onPressOut={() => null}
                            android_hyphenationFrequency={null}
                          >
                            Street name is required!
                          </HelperText>
                          <TextInput
                            key={'houseNumber'}
                            label={translate({
                              text: 'houseNumber',
                            })}
                            value={
                              values?.organization?.addresses?.length > 0 &&
                              values?.organization?.addresses[0].houseNumber
                                ? values?.organization?.addresses[0].houseNumber
                                : ''
                            }
                            keyboardType={'numeric'}
                            onChangeText={value => {
                              const key = 'organization'
                              const newAddress = {
                                ...values?.organization?.addresses?.[0],
                                houseNumber: value,
                              }
                              change(key, {
                                ...values?.organization,
                                addresses: [newAddress],
                              })
                            }}
                            onSubmitEditing={event => {
                              submit()
                            }}
                            error={false}
                            onPressIn={() => null}
                            style={{
                              width: dimensions.width > 600 ? '33.5%' : '100%',
                              backgroundColor: '#fff',
                            }}
                            onPressOut={() => null}
                            mode={'flat'}
                            autoComplete={false}
                            onFocus={() =>
                              pushCustomEvent(
                                'Focused field:',
                                'organization.houseNumber'
                              )
                            }
                          />
                          <HelperText
                            type="error"
                            visible={false}
                            onPressIn={() => null}
                            onPressOut={() => null}
                            android_hyphenationFrequency={null}
                          >
                            Housenumber is required!
                          </HelperText>
                        </View>
                        <>
                          <TextInput
                            key={'address'}
                            label={translate({
                              text: 'city',
                            })}
                            value={
                              values?.organization?.addresses?.length > 0 &&
                              values?.organization?.addresses[0].city
                                ? values?.organization?.addresses[0].city
                                : ''
                            }
                            onChangeText={value => {
                              const key = 'organization'
                              const newAddress = {
                                ...values?.organization?.addresses?.[0],
                                city: value,
                              }
                              change(key, {
                                ...values?.organization,
                                addresses: [newAddress],
                              })
                            }}
                            onSubmitEditing={event => {
                              submit()
                            }}
                            error={false}
                            onPressIn={() => null}
                            style={{
                              backgroundColor: '#fff',
                            }}
                            onPressOut={() => null}
                            mode={'flat'}
                            autoComplete={false}
                            onFocus={() =>
                              pushCustomEvent(
                                'Focused field:',
                                'organization.city'
                              )
                            }
                          />
                          <HelperText
                            type="error"
                            visible={false}
                            onPressIn={() => null}
                            onPressOut={() => null}
                            android_hyphenationFrequency={null}
                          >
                            City name is required!
                          </HelperText>
                        </>
                        <>
                          <TextInput
                              key={'country'}
                              label={translate({
                                text: 'country',
                              })}
                              value={
                                values?.organization?.addresses?.length > 0 &&
                                values?.organization?.addresses[0].country
                                    ? values?.organization?.addresses[0].country
                                    : ''
                              }
                              onChangeText={value => {
                                const key = 'organization'
                                const newAddress = {
                                  ...values?.organization?.addresses?.[0],
                                  country: value,
                                }
                                change(key, {
                                  ...values?.organization,
                                  addresses: [newAddress],
                                })
                              }}
                              onSubmitEditing={event => {
                                submit()
                              }}
                              error={false}
                              onPressIn={() => null}
                              style={{
                                backgroundColor: '#fff',
                              }}
                              onPressOut={() => null}
                              mode={'flat'}
                              autoComplete={false}
                              onFocus={() =>
                                  pushCustomEvent(
                                      'Focused field:',
                                      'organization.country'
                                  )
                              }
                          />
                          <HelperText
                              type="error"
                              visible={false}
                              onPressIn={() => null}
                              onPressOut={() => null}
                              android_hyphenationFrequency={null}
                          >
                            Country name is required!
                          </HelperText>
                        </>
                        <>
                          <TextInput
                            key={'organization.identificationNumber'}
                            label={translate({
                              text: 'identificationNumber',
                            })}
                            value={get(
                              values,
                              'organization.identificationNumber'
                            )}
                            onChangeText={value => {
                              const key = 'organization.identificationNumber'
                              change(key, value)
                            }}
                            onSubmitEditing={event => {
                              submit()
                            }}
                            error={false}
                            onPressIn={() => null}
                            style={{
                              backgroundColor: '#fff',
                            }}
                            disabled={
                              searchList.loadingList || (!searchList.loadingListError && (!searchList.loadedList || searchList.data.length !== 0))
                            }
                            onPressOut={() => null}
                            mode={'flat'}
                            autoComplete={false}
                          />
                        </>
                        <View style={styles.buttonBox}>
                          <Button
                            testID={'requestAccountSubmit'}
                            mode={knpvFlow && !checkAll() ? 'outlined' : 'contained'}
                            onPress={submit}
                            loading={crud.creating}
                            disabled={crud.created || knpvFlow ? !checkUser() : !checkAll()}
                            uppercase={false}
                            contentStyle={styles.buttonContentStyle}
                          >
                            {knpvFlow && !checkAll() ? <Translate text="registerAsPrivateButton" /> : <Translate text="requestAccountButton" />}
                          </Button>
                        </View>
                      </>
                    )}
                    {!hideErrors && (
                      <ErrorMessages
                        messages={[errors].filter(n => !!n).map(n => n)}
                      />
                    )}
                  </>
                )}
              </Translator>

              {completed ? (
                <View testID={'requestAccountSuccess'}>
                  <Caption>
                    <Translate text={'requestSuccess'} />
                  </Caption>
                </View>
              ) : null}
            </View>
          </View>
        </View>
        <View style={{ margin: 100 }} />
      </FormContainer>
    </KeyboardAvoidingScrollView>,

    <Snackbar
      key="snackbar"
      visible={!!error}
      onDismiss={dismiss}
      style={[
        {
          marginBottom: keyboardHeight + 32,
        },
      ]}
    >
      <Translate text="requestAccountError" />
    </Snackbar>,
    <Snackbar
      key="snackbar1"
      visible={validationError}
      onDismiss={() => setValidationError(false)}
      action={{
        label: 'OK',
        onPress: () => setValidationError(false),
      }}
      duration={3000}
      style={[
        {
          marginBottom: keyboardHeight + 32,
        },
      ]}
    >
      <Translate text="validationError" />
    </Snackbar>,
    <Snackbar
      key="snackbar2"
      visible={!!completed}
      onDismiss={dismiss}
      style={[
        {
          marginBottom: keyboardHeight + 32,
        },
      ]}
    >
      <Translate text="requestAccountSuccess" />
    </Snackbar>,
  ]
}

const styles = StyleSheet.create({
  buttonContentStyle: {
    height: 56,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  logoContainer: {
    padding: 20,
    paddingTop: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    marginTop: 50,
    marginBottom: 50,
    height: 70,
    width: 200,
    resizeMode: 'contain',
  },
  loginContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: '#fff',
    paddingTop: 10,
  },

  resize: {
    maxWidth: 500,
    paddingLeft: 6,
    paddingRight: 6,
    width: '100%',
    flex: 1,
  },
  errorMessage: {
    backgroundColor: 'red',
  },
  buttonBox: {
    marginTop: 16,
  },
})

const mapStateToProps = (state: any) => ({
  crud: state.requestAccount.crud,
  completed:
    !state.requestAccount.crud.creating &&
    state.requestAccount.crud.object &&
    state.requestAccount.crud.object.user &&
    state.requestAccount.crud.object.user.id,
  error: state.requestAccount.crud.creatingError,
  errorMessage: state.requestAccount.crud.creatingErrorMessage,
  popToRootOnCreate: false,
  provider: state.provider.list.listGetter,
  providerLoading: state.provider.list.loadingList,
  providerError: state.provider.list.loadingListError,
  providerErrorMessage: state.provider.list.loadingListErrorMessage,
})
export default keyboardHOC(
  detailHOC(Register, mapStateToProps, registerActions)
)
