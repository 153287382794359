import React, { useCallback, useMemo, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import actions from './actions'
import GeneralList from '../../GeneralListFunctions/GeneralList'
import ItemInTree from '../ItemInTree'
import ItemParent from '../ItemParent'
import FilterHeader from '../FilterHeader'
import Navigation from '../../Navigation'
import { pushCustomEvent } from '../../Analytics/abstraction'
import { Keyboard } from 'react-native'

function PestListScreen({
  componentId,
  filterType,
  tree,

  selected,
  filterTypeParams,
  onSelectRedirect,
  list,
  match,
  ...rest
}) {
  const parentObject = tree && tree[0]
  const dispatch = useDispatch()
  const [parentId, setParentId] = useState(rest.parentId)

  const goUp = useCallback(() => {
    Keyboard.dismiss()
    if (parentObject) {
      setParentId(
        parentObject && parentObject.parent && parentObject.parent.id
          ? parentObject.parent.id
          : parentObject?.parentId
          ? parentObject.parentId
          : parentObject?.parent_id || null
      )
    }
  }, [parentObject, setParentId])

  const renderAboveHeader = useCallback(() => {
    return parentObject ? (
      <ItemParent
        title={filterType.getItemLabel(parentObject)}
        description={filterType.getItemSubLabel(parentObject)}
        onPress={goUp}
      />
    ) : null
  }, [filterType, goUp, parentObject])

  const extraParams = useMemo(
    () => ({
      id: parentId || null,
      ...filterTypeParams,
    }),
    // eslint-disable-next-line
    [parentId, JSON.stringify(filterTypeParams)]
  )

  const onPress = useCallback(item => {
    Keyboard.dismiss()
    setParentId(item.id)
  }, [setParentId])
  const onSelect = useCallback(
    item => {
      Keyboard.dismiss()
      dispatch(actions.select(item, { singleSelect: true }))
      if (onSelectRedirect) {
        onSelectRedirect()
      } else {
        Navigation.pop(componentId)
      }
      pushCustomEvent('Filter selected for', 'pest', {
        selected: item.name,
        selectedId: item.id,
      })
    },
    [dispatch, componentId, onSelectRedirect]
  )
  const renderItem = ({ item, index }) => {
    return (
      <ItemInTree
        onPress={onPress}
        onSelect={onSelect}
        testID={`pest_${index}`}
        item={item}
        filterType={filterType}
        isSelected={selected.some(it => it.id === item.id)}
      />
    )
  }

  return (
    <GeneralList
      {...rest}
      list={list}
      selected={selected}
      searchPlaceholder={filterType.searchPlaceholder}
      renderAboveHeader={renderAboveHeader}
      renderItem={renderItem}
      extraParams={extraParams}
      icon
      sort={'name'}
      appbarContent={
        <FilterHeader filterType={filterType} componentId={componentId} />
      }
    />
  )
}

const mapStateToProps = (state, props) => ({
  selected: state.filter.pest.selected,
  list: state.filter.pest.list,
  tree: state.filter.pest.list.tree,
  filterTypeParams: props.filterType.getExtraParams(state),
})

export default connect(mapStateToProps, actions)(PestListScreen)
