import React from 'react'
import Svg from './SvgAbstraction'
import Path from './PathAbstraction'

import { propTypes, defaultProps, color } from './standardVector'

export const width = 24
export const height = 24

const Vector = props => (
  <Svg
    width={props.width || width}
    height={props.height || height}
    className={props.className}
    viewBox="0 0 24 24"
  >
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="m19.5 6.5 2-6 2 6v15a2 2 0 1 1-4 0zm-.001 13H23.5m-4.001-13h4m-7.999 14a1 1 0 0 1-1 1h-11v-19h11a1 1 0 0 1 1 1z"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M6.5 7.5h6v3h-6zm-3-5h-2a1 1 0 0 0-1 1v17a1 1 0 0 0 1 1h2"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
  </Svg>
)

Vector.propTypes = propTypes
Vector.defaultProps = defaultProps

export default Vector
