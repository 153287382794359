import React, { useCallback, useEffect, useMemo } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import actions from './actions'
import { push } from '../Navigation'
import * as routes from '../Routes.config'
import PageHeader from '../PageHeader'
import DeepLink from '../DeepLink'

import GeneralList from '../GeneralListFunctions/GeneralList'
import Touchable from '../WidthAndHeight/Touchable'
import { Appbar, Text } from 'react-native-paper'
import AdvisorFilter from '../AdvisorFilter'
import { getFirstSelectedId } from '../FilterType/config'
import idx from 'idx'
import { View } from 'react-native'
import { hasRole } from '../helpers/role'
import { fromTimestamp, getFriendlyDate } from '../dayjs'
import { loadMyLocation } from '../Map/actions'

function AdvicesListScreen({ componentId, ...rest }) {
  const selectedFarmerId = useSelector(state =>
    getFirstSelectedId(state.filter.advisorFarmer.selected)
  )
  const user = useSelector(state => idx(state, _ => _.auth.user.user) || {})
  const isAdvisor = hasRole(user, 'advisor')
  const isContractor = hasRole(user, 'contractor')
  const isTransporter = hasRole(user, 'transporter')

  const extraParams = useMemo(() => {
    return isContractor || isTransporter
      ? {
          'filter[farmerIds]': [selectedFarmerId],
          sort: '-created_at',
          preloadAll: true,
        }
      : {
          'filter[farmerIds]': [selectedFarmerId],
          // onlyUserManaged: true,
          sort: '-created_at',
          preloadAll: true,
        }
  }, [selectedFarmerId, isContractor, isTransporter])
  const dispatch = useDispatch()
  const onPressAdd = useCallback(
    () =>
      push(componentId, {
        component: {
          name: routes.ADVICE_NEW_CROP_ROUTE,
        },
      }),
    [componentId]
  )

  const renderStickyBelowHeader = useCallback(
    () => (
      <AdvisorFilter componentId={componentId} key="advice" context="advice" />
    ),
    [componentId]
  )
  useEffect(() => {
    dispatch(loadMyLocation())
  }, [dispatch])

  const isFarmer = hasRole(user, 'farmer')
  const renderItem = useCallback(
    ({
      item: {
        id,
        // description,
        farmer,
        growingPeriod,
        createdAt,
      },
    }) => {
      const description = isFarmer
        ? `${growingPeriod?.organizationField?.name || 'onbekend teeltlocatie'}`
        : `${farmer && farmer.name} op ${
            growingPeriod?.organizationField?.name || 'onbekend teeltlocatie'
          }`

      return (
        <Touchable
          key={id}
          description={description}
          title={growingPeriod.crop && growingPeriod.crop.name}
          componentId={componentId}
          onPress={() => {
            push(componentId, {
              component: {
                name: routes.ADVICE_DETAIL_ROUTE,
                passProps: {
                  id,
                },
              },
            })
          }}
          right={
            <View
              pointerEvents="none"
              style={{ position: 'absolute', right: 6 }}
            >
              <Text>
                {createdAt ? getFriendlyDate(fromTimestamp(createdAt)) : ''}
              </Text>
            </View>
          }
        />
      )
    },
    [componentId, isFarmer]
  )

  return (
    <>
      <GeneralList
        {...rest}
        searchPlaceholder={'Zoek op adviezen'}
        renderItem={renderItem}
        icon
        renderStickyBelowHeader={renderStickyBelowHeader}
        appbarContent={
          <PageHeader
            // back={back}
            componentId={componentId}
            title={<Appbar.Content title={'Adviezen'} />}
          />
        }
        extraParams={extraParams}
        fabLabel={'Advies samenstellen'}
        isFarmer={isFarmer}
        onPressFab={onPressAdd}
        hideAddFab={!isAdvisor}
      />
      <DeepLink loggedIn={true} componentId={componentId} />
    </>
  )
}

const mapStateToProps = state => ({
  list: state.advice.listAndCrud.list,
  user: state.auth.user.user,
})

export default connect(mapStateToProps, { ...actions, loadMyLocation })(
  AdvicesListScreen
)
