import React, { useCallback } from 'react'
import { Image, Platform } from 'react-native'
import { useDispatch } from 'react-redux'
import { setMessages } from '../Notifications/actions'

import { IconButton } from 'react-native-paper'
import { pushCustomEvent } from '../Analytics/abstraction'
import { getItemLabel } from './helper'

const MPS = ({ mps, product }) => {
  const dispatch = useDispatch()
  const onPress = useCallback(
    item => {
      dispatch(
        setMessages([
          item.list.description ? item.list.description : item.list.name,
        ])
      )
      pushCustomEvent(
        'Pressed MPS icon on product:',
        `${getItemLabel(product)}`
      )
    },
    [dispatch, product]
  )

  return mps.map(item => (
    <IconButton
      key={item.activeSubstanceID}
      onPress={() => onPress(item)}
      icon={({ size }) => (
        <Image
          style={{ width: size, height: size }}
          source={
            Platform.OS === 'web'
              ? require('../../img/webp/mps.webp')
              : require('../../img/mps.png')
          }
        />
      )}
    />
  ))
}

export default MPS
