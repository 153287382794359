import { put, call, takeLatest } from 'redux-saga/effects'
import actions from './actions'
import { fetchListener } from 'react-auth/lib/sagas/authentication'

function* create(action) {
  try {
    const response = yield call(fetchListener, {
      path: `v1/user/${action.payload.id}/invite`,
      method: 'POST',
    })

    yield put(actions.created(response))
    console.log(response.message)
  } catch (e) {
    console.log(e)
    yield put(actions.created(e))
  }
}

export default function* updateSaga() {
  yield takeLatest(actions.CREATE, create)
}
