import React from 'react'
import Svg from './SvgAbstraction'
import Path from './PathAbstraction'

import { color, defaultProps, propTypes } from './standardVector'
import Line from './LineAbstraction'

export const width = 24
export const height = 24

const Vector = props => (
  <Svg
    width={props.width || width}
    height={props.height || height}
    className={props.className}
    viewBox="0 0 24 24"
  >
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M8.25,19.41a8.42,8.42,0,0,1-4.81-1.85C1.88,16,.12,10.42,3.44,7.1,7.25,3.29,19.13,0,20.09.91c.48.48-.13,3.74-1.34,7.34"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Line
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      x1="7.87"
      y1="13.13"
      x2="0.75"
      y2="20.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M23.25,15.75a7.68,7.68,0,0,1-6,7.5,7.68,7.68,0,0,1-6-7.5V13.5a1.5,1.5,0,0,1,1.5-1.5h9a1.5,1.5,0,0,1,1.5,1.5Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Line
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      x1="15"
      y1="17.25"
      x2="19.5"
      y2="17.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </Svg>
)

Vector.propTypes = propTypes
Vector.defaultProps = defaultProps

export default Vector
