import React, { useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { Button, Divider, IconButton, Text } from 'react-native-paper'
import Fields from '../Form/Fields'
import { Translate, Translator } from 'react-translated'
import AdditiveSingleSelect from '../Products/AdditiveSingleSelect/AdditiveSingleSelect'

const baseLine = {}
const secondFields = [
  {
    name: 'amount',
    label: <Translate text="dosageProduct" />,
    type: 'amount',
    fieldProps: {
      style: { flex: 1, marginTop: 12 },
    },
  },
  {
    name: 'unit',
    label: <Translate text="unit" />,
    type: 'unit',
    unitType: 'dosage',
    fieldProps: {
      style: { flex: 1, marginTop: 12 },
    },
  },
]
function Lines({ editable, next, onChange, initialValue }) {
  const [value, setValues] = React.useState(initialValue || [])

  const addLine = () => {
    setValues([...(value || []), baseLine])
  }
  const removeLine = line => () => {
    setValues((value || []).filter(l => l !== line))
  }
  const changeDosageLine = line => (name, objectValue) => {
    setValues(
      (value || []).map(l =>
        l === line
          ? {
              ...l,
              dosage: { ...((l && l.dosage) || {}), [name]: objectValue },
            }
          : l
      )
    )
  }
  const selectProduct = line => product => {
    setValues((value || []).map(l => (l === line ? { ...l, product } : l)))
  }
  useEffect(() => {
    onChange('additives', value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const lines = value || []
  return (
    <Translator>
      {({ translate }) => (
        <View style={styles.root}>
          {lines.map((line, index) => (
            <View
              style={[styles.col, styles.lineContainer]}
              key={`${line.product && line.product.name}_${
                line.product && line.product.wCode
              }_${index}`}
            >
              <View style={styles.full}>
                <Text style={{ fontSize: 20, padding: 16 }}>
                  {translate({ text: 'adjuvant' })} #{index + 1}
                </Text>
              </View>
              <View style={styles.rowJustifyEnd}>
                <View style={[styles.col, styles.closeContainer]}>
                  <IconButton icon={'close'} onPress={removeLine(line)} />
                </View>
              </View>
              <Divider />
              <View style={[styles.row]}>
                <AdditiveSingleSelect
                  singleSelect
                  style={{ flex: 1 }}
                  editable={editable}
                  value={line.product}
                  onSelect={selectProduct(line)}
                  extraParams={{ admissionCategories: [2] }}
                />
              </View>
              <View style={[styles.row, styles.secondRow]}>
                <Fields
                  fields={secondFields}
                  values={line && line.dosage}
                  editable={editable}
                  onChange={changeDosageLine(line)}
                  onSubmit={next}
                />
              </View>
            </View>
          ))}
          {editable ? (
            <View style={styles.bottom}>
              <Button icon="plus" onPress={addLine} mode={'outlined'}>
                {translate({ text: 'addAdjuvants' })}
              </Button>
            </View>
          ) : null}
        </View>
      )}
    </Translator>
  )
}

const styles = StyleSheet.create({
  root: {
    flexDirection: 'column',
  },
  bottom: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: 24,
    paddingTop: 16,
    paddingBottom: 8,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowJustifyEnd: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: 0,
    top: 0,
  },
  full: {
    flex: 1,
  },
  closeContainer: { padding: 8 },
  secondRow: { paddingLeft: 6 },
  lineContainer: {
    left: '1.5%',
    width: '95%',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
    backgroundColor: '#fff',

    paddingTop: 0,
    paddingBottom: 16,
    marginBottom: 16,
    marginTop: 24,
    margin: 6,
    borderRadius: 5,
  },
})

Lines.defaultProps = {
  editable: true,
}

export default Lines
