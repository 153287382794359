import React, { useEffect } from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import { Button, Text } from 'react-native-paper'
import DateTimePicker from './DateTimePicker'
import dayjs from '../dayjs'
import { Translator } from 'react-translated'

function secondsToHms(d) {
  d = Number(d)
  const h = Math.floor(d / 3600)
  const m = Math.floor((d % 3600) / 60)
  const mPrefix = m < 10 ? '0' : ''
  return `${h}:${mPrefix}${m}`
}

const getNewUnixWithDate = (dateObj, timeToChange) => {
  let timeObj = dayjs.unix(timeToChange)
  timeObj = timeObj.set('month', dateObj.get('month'))
  timeObj = timeObj.set('date', dateObj.get('date'))
  timeObj = timeObj.set('year', dateObj.get('year'))
  return timeObj.unix()
}

function DurationPicker({
  startTime,
  endTime,
  date,
  changeOnMount,
  onChange,
  editable,
}) {
  const [withTime, setWithTime] = React.useState(!!endTime)
  useEffect(() => {
    if (changeOnMount) {
      if (endTime) {
        onChange({ startTime: date, endTime })
      } else {
        onChange({ date })
      }
    }
  }, [date, startTime, endTime, changeOnMount, onChange])

  const change = key => unix => {
    let newState = {}

    // change date unix when date changes
    if (key === 'date') {
      const dateObj = dayjs.unix(unix)
      if (withTime) {
        newState.date = getNewUnixWithDate(dateObj, date)
        newState.startTime = getNewUnixWithDate(dateObj, date)
        newState.endTime = getNewUnixWithDate(dateObj, endTime)
      } else {
        newState.date = getNewUnixWithDate(dateObj, startTime)
      }
    } else {
      if (key === 'startTime') {
        newState['date'] = unix
      } else {
        newState[key] = unix
      }
    }
    console.log({ newState })
    onChange(newState)
  }
  const innerSetTime = () => {
    setWithTime(true)
    onChange({ startTime: date, endTime: date })
  }

  const from = dayjs.unix(startTime)
  const to = dayjs.unix(endTime)

  const difference = to.diff(from)

  return (
    <Translator>
      {({ translate }) => (
        <View style={styles.root}>
          <View style={[styles.row, styles.first]}>
            <DateTimePicker
              mode={'date'}
              editable={editable}
              value={date}
              onChange={change('date')}
              label={translate({ text: 'date' })}
            />
          </View>
          {withTime ? (
            <View>
              <View style={styles.row}>
                <DateTimePicker
                  mode={'time'}
                  editable={editable}
                  value={startTime}
                  onChange={change('startTime')}
                  label={translate({ text: 'startTime' })}
                />

                <View style={styles.time}>
                  <Text style={styles.timeText}>
                    {secondsToHms(difference / 1000)}
                  </Text>
                </View>

                <DateTimePicker
                  mode={'time'}
                  value={endTime}
                  editable={editable}
                  onChange={change('endTime')}
                  label={translate({ text: 'endTime' })}
                />
              </View>
              <Button
                onPress={() => {
                  setWithTime(false)
                  onChange({ date: date })
                }}
                style={{ marginTop: 12 }}
                mode={'outlined'}
                icon={'calendar-outline'}
              >
                Registreer enkel via datum
              </Button>
            </View>
          ) : (
            <Button
              onPress={innerSetTime}
              mode={'outlined'}
              icon={'clock-outline'}
            >
              {translate({ text: 'chooseTime' })}
            </Button>
          )}
        </View>
      )}
    </Translator>
  )
}
const styles = StyleSheet.create({
  root: {
    flexDirection: 'column',
    position: 'relative',
    zIndex: 100,
    marginBottom: 16,
  },
  first: {
    position: 'relative',
    zIndex: 100,
    paddingBottom: Platform.OS === 'web' ? 16 : 0,
  },
  listItem: {
    flex: 1,
  },
  time: {
    width: 70,
    justifyContent: 'center',
    alignItems: 'center',
  },
  timeText: { fontWeight: 'bold', fontSize: 22 },
  row: {
    flexDirection: 'row',
    // justifySelf: 'center',
    zIndex: 100,
    alignSelf: 'center',
  },
})
DurationPicker.defaultProps = {
  editable: true,
}

export default DurationPicker
