import React, { Component } from 'react'
import {
  Image,
  Linking,
  Platform,
  StyleSheet,
  TouchableOpacity,
} from 'react-native'
import safeAreaHOC from '../WidthAndHeight/safeAreaHOC'

const styles = StyleSheet.create({
  root: {
    position: 'absolute',
    opacity: 0.9,
    zIndex: 100,
  },
})

class MapboxLogo extends Component {
  _press = () => {
    const url = 'https://www.mapbox.com/'
    if (Platform.OS === 'web') {
      window.open(url)
    } else {
      Linking.openURL(url).catch(err => console.error('An error occurred', err))
    }
  }
  render() {
    // const { safe, fullScreen } = this.props
    return (
      <TouchableOpacity
        onPress={this._press}
        style={[styles.root, { right: 8, bottom: 8 }]}
      >
        <Image
          style={{ width: 100, height: 20 }}
          resizeMode={'contain'}
          source={
            Platform.OS === 'web'
              ? require('../../img/webp/mapbox-logo.webp')
              : require('../../img/mapbox-logo.png')
          }
        />
      </TouchableOpacity>
    )
  }
}

export default safeAreaHOC(MapboxLogo)
