import React, { useCallback, useMemo } from 'react'
import PageHeader from '../../PageHeader'
import DeepLink from '../../DeepLink'
import GeneralList from '../../GeneralListFunctions/GeneralList'
import { Appbar, RadioButton } from 'react-native-paper'
import { useDispatch, useSelector } from 'react-redux'
import { Translator } from 'react-translated'
import { useActionsAsFunctions } from '../../helpers/actions'
import actions from '../../Farmer/Advisor/actions'
import a from './actions'
import { Platform, View } from 'react-native'
import { push } from '../../Navigation'
import * as routes from '../../Routes.config'
import SafeListItem from '../../WidthAndHeight/SafeListItem'

function CompanyList(props) {
  const actionsAsFunction = useActionsAsFunctions(actions)
  const { componentId } = props
  const dispatch = useDispatch()

  const list = useSelector(state => state.farmer.advisor.list)

  const extraParams = useMemo(
    () => ({
      sort: 'name',
    }),
    []
  )
  const onPressItem = useCallback(
    item => {
      dispatch(a.setData({ selectedCompany: item }))

      push(componentId, {
        component: {
          name: routes.ADVICE_WIZARD_ROUTE,
          passProps: {
            step: 1,
            companyId: item.id,
          },
        },
      })
    },
    [componentId, dispatch]
  )

  const renderLeft = useCallback(
    (item, isSelected) => {
      return (
        <View>
          <RadioButton.Android
            status={isSelected ? 'checked' : 'unchecked'}
            onPress={() => onPressItem(item)}
          />
        </View>
      )
    },
    [onPressItem]
  )

  const renderItem = useCallback(
    ({ item }) => {
      const isSelected = `${props.companyId}` === `${item.id}`
      let title = item?.name

      return (
        <SafeListItem
          title={title}
          description={''}
          onPress={() => {
            if (Platform.OS !== 'web') {
              // We only push on native, because on web we use "to". This is because "to" has extra hover effects on web.
              push(componentId, {
                component: {
                  name: routes.ADVICE_WIZARD_ROUTE,
                  passProps: {
                    step: 1,
                    companyId: item.id,
                  },
                },
              })
            }
          }}
          left={renderLeft(item, isSelected)}
          accessibilityLabel={`Open ${item.name}`}
          to={routes.ADVICE_WIZARD_ROUTE + '1/' + item.id}
        />
      )
    },
    [componentId, props.companyId, renderLeft]
  )
  //
  // const back = useCallback(() => {
  //   push(componentId, {
  //     component: {
  //       name: routes.HOME_ROUTE,
  //     },
  //   })
  // }, [componentId])

  return (
    <>
      <Translator>
        {({ translate }) => (
          <GeneralList
            {...props}
            {...actionsAsFunction}
            list={list}
            searchPlaceholder={translate({ text: 'searchCompany' })}
            renderItem={renderItem}
            icon
            fabLabel={translate({ text: 'next' })}
            withoutHeaderBackground
            appbarContent={
              <PageHeader
                componentId={componentId}
                title={
                  <Appbar.Content
                    title={translate({ text: 'generateAdvice' })}
                    titleStyle={{
                      textAlign: 'center',
                    }}
                    subtitleStyle={{
                      textAlign: 'center',
                    }}
                    style={{ flex: 1 }}
                    subtitle={translate({ text: 'forWhatCompany' })}
                  />
                }
              />
            }
            extraParams={extraParams}
            // preloads={preloads}
          />
        )}
      </Translator>
      <DeepLink loggedIn={true} componentId={componentId} />
    </>
  )
}

export default CompanyList
