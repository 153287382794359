import React, { Component } from 'react'
import { PersistGate } from 'redux-persist/integration/react'
//@ts-ignore
import Routes from './Routes'
import { wrap } from './Modal'
import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper'
import { Provider } from 'react-redux'
import configureStore from './configureStore'
import fonts from './fonts'
import WidthAndHeight from './WidthAndHeight/WidthAndHeight'
import Analytics from './Analytics/Analytics'
import NotificationModal from './Notifications/NotificationModal'
import TranslationWrapper from './TranslationWrapper'
import { lightColors } from './colorThemes'
import SubscriptionWrapper from './SubscriptionWrapper'
import ZendeskWrapper from './ZendeskWrapper'
import CrashReports from "./Sentry/CrashReports";

// Create store
const { store, persistor } = configureStore()

export const HOC = (WrappedComponent: any) => {
  class Wrapper extends Component {
    // state = {
    //   isDark: false,
    // }
    // componentDidMount() {
    //   this.setState({
    //     isDark: Appearance.getColorScheme() === 'dark',
    //   })
    // }

    render() {
      const theme = {
        ...DefaultTheme,
        // colors: this.state.isDark ? darkColors : lightColors,
        colors: lightColors,
        roundness: 10,
        fonts,
        // dark: this.state.isDark,
      }
      const inner = (
        <WidthAndHeight
          listenToChanges
          render={(props: any) => (
            <>
              <CrashReports />
              <WrappedComponent {...this.props} {...props} />
              <NotificationModal {...props} />
              <Analytics />
            </>
          )}
        />
      )
      return (
        <Provider store={store}>
          <TranslationWrapper>
            <PersistGate loading={null} persistor={persistor}>
              <PaperProvider theme={theme}>
                <SubscriptionWrapper />
                <ZendeskWrapper />
                {wrap(inner)}
              </PaperProvider>
            </PersistGate>
          </TranslationWrapper>
        </Provider>
      )
    }
  }
  if (WrappedComponent) {
    //@ts-ignore
    Wrapper.options = WrappedComponent.options
  }

  return Wrapper
}

export default HOC(Routes)
