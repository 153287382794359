import React, { useCallback } from 'react'
import { View } from 'react-native'
import { Text } from 'react-native-paper'
import { getTotalAreaOfGrowingPeriods } from '../helpers/area'
import Touchable from '../WidthAndHeight/Touchable'
import round from '../helpers/round'
import { Translator } from 'react-translated'

export default function GroupedCropItem({ item, componentId, onPress }) {
  const innerOnPress = useCallback(() => {
    onPress(item)
  }, [item, onPress])

  const totalArea = getTotalAreaOfGrowingPeriods((item || {}).growingPeriods)

  const amountOfFields = ((item || {}).growingPeriods || []).length
  // .filter(gp => !!gp.organizationField.organization.name)
  // .map(gp => gp.organizationField.organization.name)
  // .join(', ')

  // Position absolute on the right so we can click through the item (pointerevents does not work without pos. absolute)
  const skalOrDemeterOrPlanetProof =
    item?.certification?.toLowerCase().charAt(0).toUpperCase() +
    item?.certification?.toLowerCase().slice(1)
  return (
    <Translator>
      {({ translate }) => (
        <Touchable
          onPress={innerOnPress}
          key={`${item?.crop?.name}_${skalOrDemeterOrPlanetProof}`}
          title={`${item?.crop?.name}${
            item?.crop?.parentName
              ? ` (${item?.crop?.parentName}${
                  skalOrDemeterOrPlanetProof
                    ? `, ${skalOrDemeterOrPlanetProof})`
                    : ')'
                }`
              : `${
                  skalOrDemeterOrPlanetProof
                    ? ` (${skalOrDemeterOrPlanetProof})`
                    : ''
                }`
          }`}
          right={
            totalArea > 0 && (
              <View
                pointerEvents="none"
                style={{ position: 'absolute', right: 6 }}
              >
                <Text>{round(totalArea / 10000, 3)} ha</Text>
              </View>
            )
          }
          description={`${amountOfFields} ${
            amountOfFields === 1
              ? translate({ text: 'parcelLow' })
              : translate({ text: 'parcelsLow' })
          }`}
          componentId={componentId}
        />
      )}
    </Translator>
  )
}
