import { call, select, put, takeLatest } from 'redux-saga/effects'

import { geoJSON } from '../Map/helpers'
import * as a from './actions'
import { fetchListener } from 'react-auth/lib/sagas/authentication'
import { getFirstSelectedId } from '../FilterType/config'

const getDataFromResponse = (response) => {
  if (response && response.data) {
    return response.data
  }
  return []
}

function* loadPolygons({ payload: { organizationId, region } }) {
  try {
    const selectedFarmerId = yield select((state) =>
      getFirstSelectedId(state.filter.advisorFarmer.selected)
    )
    const selectedCropId = yield select((state) =>
      getFirstSelectedId(state.filter.crop.selected)
    )
    const selectedContractId = yield select((state) =>
      getFirstSelectedId(state.filter.contract.selected)
    )
    const selectedGrowingPlan = yield select((state) =>
      getFirstSelectedId(state.filter.growingPlan.selected)
    )

    const fieldResponse = yield call(fetchListener, {
      path: `v2/field/organization-field`,
      params: {
        managedOrganizationId: selectedFarmerId,
        cropId: selectedCropId,
        contractId: selectedContractId,
        growingPlan: selectedGrowingPlan,
        limit: 20000,
        number: 20000,
        preloadAll: true,
      },
    })

    const data = getDataFromResponse(fieldResponse)
    let fieldData = []
    data.forEach((orgField) => {
      if (
        orgField.customField &&
        orgField.customField.fields &&
        orgField.customField.fields.length > 0
      ) {
        orgField.customField.fields.forEach((fie) => {
          fieldData = [...fieldData, { ...orgField, ...fie }]
        })
      } else {
        fieldData = [
          ...fieldData,
          {
            ...orgField,
            ...orgField.field,
          },
        ]
      }
    })
    fieldData = fieldData.filter((obj) => {
      return !!obj.id && (obj?.coordinates || obj.field?.coordinates)
    })

    const loadedPayload = {
      data: {
        field: geoJSON(fieldData),
      },
      fieldData,
    }
    yield put(a.loadedPolygons(loadedPayload))
  } catch (e) {
    console.log('Map saga error: ', e)
    yield put(a.loadedPolygons(e, null, true))
  }
}

export default function* saga() {
  yield takeLatest(a.LOAD_POLYGONS, loadPolygons)
}
