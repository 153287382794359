import React, { Component } from 'react'

import { Keyboard, Platform, LayoutAnimation } from 'react-native'

export default WrappedComponent => {
  class HOC extends Component {
    constructor(props) {
      super(props)

      this.state = {
        keyboardHeight: 0,
      }
    }

    UNSAFE_componentWillMount() {
      if (WrappedComponent.componentDidMount)
        WrappedComponent.componentDidMount()

      if (Platform.OS === 'ios') {
        this.keyboardDidChangeListener = Keyboard.addListener(
          'keyboardWillChangeFrame',
          this._onKeyboardChange
        )
      } else {
        this.keyboardDidShowListener = Keyboard.addListener(
          'keyboardDidShow',
          this._keyboardDidShow
        )
      }

      this.keyboardDidHideListener = Keyboard.addListener(
        'keyboardDidHide',
        this._keyboardDidHide
      )
    }
    componentWillUnmount() {
      if (WrappedComponent.componentWillUnmount)
        WrappedComponent.componentWillUnmount()

      if (Platform.OS === 'ios') {
        this.keyboardDidChangeListener.remove()
      } else {
        this.keyboardDidShowListener.remove()
      }
      this.keyboardDidHideListener.remove()
    }

    _onKeyboardChange = event => {
      if (!event) {
        this.setState({ keyboardHeight: 0 })
        return
      }

      const { duration, easing, endCoordinates } = event
      const keyboardHeight = endCoordinates.height

      if (duration && easing) {
        LayoutAnimation.configureNext({
          duration,
          update: {
            duration,
            type: LayoutAnimation.Types[easing] || 'keyboard',
          },
        })
      }
      this.setState({ keyboardHeight })
    }
    _keyboardDidShow = e => {
      const { height } = e.endCoordinates

      this.setState({
        keyboardHeight: Platform.OS === 'android' ? 0 : height,
      })
    }
    _keyboardDidHide = () => {
      this.setState({
        keyboardHeight: 0,
      })
    }

    render() {
      return <WrappedComponent {...this.state} {...this.props} />
    }
  }

  return HOC
}
