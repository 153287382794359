import * as React from 'react'
import { View } from 'react-native'
import { Button, Text, TextInput } from 'react-native-paper'
import { useDispatch, useSelector } from 'react-redux'
import Navigation from '../../../Navigation'

import * as actions from './actions'

import { FilterType } from '../../config'

import { RootState } from '../../../reducers'
import ItemInList from '../../ItemInList'
// @ts-ignore
import { Translator } from 'react-translated'

// const safeSettings = { bottom: true, left: true, right: true }

function AquaticBufferZoneChoose({
  componentId,
  filterType,
}: {
  componentId: string
  filterType: FilterType
}) {
  const dispatch = useDispatch()
  const aquaticBufferZone = useSelector(
    (state: RootState) => state.filter.aquaticBufferZone
  ) as { width: number | null; present: boolean | null }

  const onBack = () => {
    Navigation.pop(componentId)
  }

  const onChangeText = (text: string) => {
    if (text === '') {
      dispatch(actions.change({ width: 0 }))
    } else {
      dispatch(actions.change({ width: Number(text), present: true }))
    }
  }
  const onSelect = (id: boolean) => {
    dispatch(actions.change({ present: id }))
  }

  return (
    <>
      <Translator>
        {({ translate }: any) => (
          <>
            <Text
              style={{ fontSize: 20, marginTop: 16, marginBottom: 10 }}
              onTextLayout={undefined}
              dataDetectorType={undefined}
              onPressIn={undefined}
              onPressOut={undefined}
              android_hyphenationFrequency={undefined}
            >
              {translate({ text: 'cropFreeZone' })}
            </Text>
            <ItemInList
              onPress={(s: { id: boolean; name: string }) => {
                onSelect(s.id)
              }}
              onSelect={(s: { id: boolean; name: string }) => {
                onSelect(s.id)
              }}
              item={{
                id: true,
                name: translate({ text: 'bufferzoneToSurfaceWaterPresent' }),
              }}
              filterType={filterType}
              isSelected={
                aquaticBufferZone && aquaticBufferZone.present === true
              }
              selectType="radiobutton"
              nameFirst
              noSublabel
            />
            <ItemInList
              onPress={(s: { id: boolean; name: string }) => {
                onSelect(s.id)
              }}
              onSelect={(s: { id: boolean; name: string }) => {
                onSelect(s.id)
              }}
              item={{
                id: false,
                name: translate({ text: 'bufferzoneToSurfaceWaterNotPresent' }),
              }}
              filterType={filterType}
              isSelected={
                aquaticBufferZone && aquaticBufferZone.present === false
              }
              selectType="radiobutton"
              nameFirst
              noSublabel
            />
            <View style={{ padding: 12 }}>
              <Text
                style={{ fontSize: 16 }}
                onTextLayout={undefined}
                dataDetectorType={undefined}
                onPressIn={undefined}
                onPressOut={undefined}
                android_hyphenationFrequency={undefined}
              >
                {translate({ text: 'measuredFromSurfaceWater' })}
              </Text>
              <View style={{ height: 16 }} />
              <TextInput
                label={translate({ text: 'bufferzoneToSurfaceWaterInCM' })}
                mode="outlined"
                onPressIn={undefined}
                onPressOut={undefined}
                autoComplete={undefined}
                value={`${
                  aquaticBufferZone &&
                  aquaticBufferZone.width &&
                  aquaticBufferZone.present
                    ? aquaticBufferZone.width
                    : ''
                }`}
                onChangeText={onChangeText}
                textAlign={undefined}
              />
              <View style={{ height: 16 }} />
              <Button uppercase={false} mode="contained" onPress={onBack}>
                {translate({ text: 'done' })}
              </Button>
            </View>
          </>
        )}
      </Translator>
    </>
  )
}

// const styles = StyleSheet.create({
//   fill: {
//     flex: 1,
//   },
// })

export default AquaticBufferZoneChoose
