import React from 'react'
import Svg from './SvgAbstraction'
import Path from './PathAbstraction'

import { propTypes, defaultProps, color } from './standardVector'

export const width = 24
export const height = 24

const Vector = props => (
  <Svg
    width={props.width || width}
    height={props.height || height}
    className={props.className}
    viewBox="0 0 24 24"
  >
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M12.932,17s1.22,2.495,3.27,2.307c4.158-.38,3.88-5.907,5.4-7.859a9.106,9.106,0,0,0-8.265.075C11.183,12.744,11.031,14.8,12.932,17Z"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M15.873,14.469s-2.7.334-4.623,5.03"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M21.747,18.86l1.3,2.129A1.5,1.5,0,0,1,21.75,23.25H2.25A1.5,1.5,0,0,1,.957,20.989L8.25,9V.75"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M15.75 0.75L15.75 7.5"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M6 0.75L18 0.75"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M15.75 3.75L13.5 3.75"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M15.75 6.75L13.5 6.75"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
  </Svg>
)

Vector.propTypes = propTypes
Vector.defaultProps = defaultProps

export default Vector
