import React, { useMemo, useEffect, useState, useCallback } from 'react'
import { StyleSheet, View } from 'react-native'
import { useSelector, useDispatch } from 'react-redux'
import { loadPolygons } from './actions'
import MapView from '../../Map/Map'
import MapUserLocation from '../../Map/MapUserLocation'
// import SinglePolygon from './SinglePolygon'
import Search from '../../MapSearch/Search'
import LoadCurrentLocationButton from '../../Map/LoadCurrentLocationButton'
import {
  getCoordinatesFromField,
  findFieldByCoordinates,
} from '../../Map/helpers'
import MapError from '../../Map/MapError'
import SinglePolygon from './SinglePolygon'
import { Translate } from 'react-translated'

function SingleFieldMap({
  componentId,
  fullScreen = true,
  onHeaderIconPress,
  headerIcon,
  onPressField,
  fieldId,
  selectedField,
}) {
  const dispatch = useDispatch()
  const field = useSelector(state => state.singleField.data)
  const error = useSelector(state => state.singleField.error)
  const loadingFields = !field.field
  // const field = fields.find(field => field.id === fieldId)
  const fieldsRef = React.useRef([])
  useEffect(() => {
    fieldsRef.current = field
    return () => {}
  }, [field])

  const [mapRef, setMap] = useState(undefined)
  const onRef = useCallback(c => c && setMap(c), [setMap])

  const onPress = useCallback(
    ({ latitude, longitude }) => {
      const matchedField = findFieldByCoordinates({
        fields: [fieldsRef.current] || [], // fix closure to old reference
        longitude,
        latitude,
      })

      if (matchedField && onPressField) {
        onPressField(matchedField)
      }
    },
    [onPressField]
  )
  useEffect(() => {
    dispatch(loadPolygons({ fieldId }))
  }, [dispatch, fieldId])
  // working fieldss
  const fieldCoordinates = useMemo(() => getCoordinatesFromField(field.field), [
    field,
  ])
  const subscribeToUserLocation = !fieldCoordinates
  return (
    <View style={styles.container} keyboardShouldPersistTaps={'handled'}>
      <LoadCurrentLocationButton loading={loadingFields} />

      <MapView
        onRef={onRef}
        // onRegionChanged={onInnerRegionChanged}
        centerCoordinates={fieldCoordinates}
        onPressLatLong={onPress}
        fullScreen={fullScreen}
        subscribeToUserLocation={subscribeToUserLocation}
        render={permission => (
          <>
            <SinglePolygon field={field.field} selectedField={selectedField} />
            {permission && (
              <MapUserLocation
                subscribeToUserLocation={subscribeToUserLocation}
                mapRef={mapRef}
              />
            )}
          </>
        )}
      />

      <Search
        withPadding
        fullScreen={fullScreen}
        componentId={componentId}
        onIconPress={onHeaderIconPress}
        icon={headerIcon}
        keyboardShouldPersistTaps={'handled'}
      />
      {error && (
        <MapError fullScreen={fullScreen} label={<Translate text="errorParcel" />} />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
  },
})

export default SingleFieldMap
