import React from 'react'
import Svg from './SvgAbstraction'
import Path from './PathAbstraction'

import { color, defaultProps, propTypes } from './standardVector'

export const width = 24
export const height = 24

const Vector = props => (
  <Svg
    fill="none"
    viewBox="0 0 24 24"
    width={props.width || width}
    height={props.height || height}
    className={props.className}
  >
    <Path
      stroke={color(props.color)}
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M8.99998 19.188C8.71869 19.4692 8.33723 19.6272 7.93948 19.6272C7.54174 19.6272 7.16027 19.4692 6.87898 19.188L1.49998 13.81C1.36059 13.6707 1.25002 13.5053 1.17458 13.3233C1.09913 13.1412 1.0603 12.9461 1.0603 12.749C1.0603 12.552 1.09913 12.3568 1.17458 12.1748C1.25002 11.9927 1.36059 11.8273 1.49998 11.688L12 1.18802C12.1393 1.04876 12.3048 0.938307 12.4868 0.862982C12.6689 0.787658 12.864 0.748934 13.061 0.749024H18.439C18.8368 0.749024 19.2183 0.907059 19.4996 1.18836C19.7809 1.46967 19.939 1.8512 19.939 2.24902V7.62802C19.9389 8.02557 19.781 8.40682 19.5 8.68802"
    />
    <Path
      stroke={color(props.color)}
      d="M16.189 4.87402C15.9819 4.87402 15.814 4.70613 15.814 4.49902C15.814 4.29192 15.9819 4.12402 16.189 4.12402"
    />
    <Path
      stroke={color(props.color)}
      d="M16.189 4.87402C16.3961 4.87402 16.564 4.70613 16.564 4.49902C16.564 4.29192 16.3961 4.12402 16.189 4.12402"
    />
    <Path
      stroke={color(props.color)}
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M19.6129 15.5L16.7079 19.374C16.6434 19.4599 16.5612 19.5309 16.4669 19.5823C16.3725 19.6337 16.2683 19.6642 16.1611 19.6718C16.054 19.6793 15.9465 19.6638 15.8458 19.6263C15.7452 19.5887 15.6538 19.5299 15.5779 19.454L14.0779 17.954"
    />
    <Path
      stroke={color(props.color)}
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M16.939 23.249C20.2527 23.249 22.939 20.5627 22.939 17.249C22.939 13.9353 20.2527 11.249 16.939 11.249C13.6253 11.249 10.939 13.9353 10.939 17.249C10.939 20.5627 13.6253 23.249 16.939 23.249Z"
    />
  </Svg>
)

Vector.propTypes = propTypes
Vector.defaultProps = defaultProps

export default Vector
