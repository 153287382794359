import actions from './actions'
import { put, call, takeLatest, select } from 'redux-saga/effects'

import { fetchListener } from 'react-auth/lib/sagas/authentication'

function* create(payload) {
  try {
    const response = yield call(fetchListener, {
      path: 'v1/auth/organization/manage',
      params: payload.payload.meta,
      body: {
        ...(payload.payload.item || {}),
        identificationNumber: payload.payload.item.kvkNumber,
        addresses: payload.payload.item.addresses || [],
        name: payload.payload.item.tradeName || payload.payload.item.name,
      },
      method: 'POST',
    })

    yield put(actions.created(response))
  } catch (e) {
    console.log(e)
    yield put(actions.created(e))
  }
}

function* load(payload) {
  try {
    console.log(payload)
    const response = yield call(fetchListener, {
      path: `v1/auth/organization/manage/${payload.payload.id}`,
      method: 'GET',
    })

    yield put(actions.loaded(response))
  } catch (e) {
    console.log(e)
    yield put(actions.loaded(e))
  }
}

function* update() {
  try {
    const crud = yield select((state) => state.farmer.organization.crud)
    const { object } = crud
    const response = yield call(fetchListener, {
      path: `v1/auth/organization/manage/${object.id}`,
      body: object,
      method: 'PUT',
    })

    yield put(actions.updated(response))
  } catch (e) {
    console.log(e)
    yield put(actions.updated(e))
  }
}

function* remove(payload) {
  try {
    const response = yield call(fetchListener, {
      path: `v1/auth/organization/manage/${payload.payload.id}`,
      method: 'DELETE',
    })

    yield put(actions.removed(response))
  } catch (e) {
    console.log(e)
    yield put(actions.removed(e))
  }
}

export function* updateSaga() {
  yield takeLatest(actions.UPDATE, update)
  yield takeLatest(actions.CREATE, create)
  yield takeLatest(actions.LOAD, load)
  yield takeLatest(actions.REMOVE, remove)
}
