import React, { Component } from 'react'

import { StyleSheet, View } from 'react-native'
import { IconButton, Menu, Text } from 'react-native-paper'
import SafeListItem from '../WidthAndHeight/SafeListItem'
import { FILTER_MODAL_ROUTE } from '../Routes.config'
import { push } from '../Navigation'
import { isFilterValueSet } from '../helpers/utils'
import c from '../constants'
import { groupBy } from 'lodash'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import { pushCustomEvent } from '../Analytics/abstraction'
import { getLanguage } from '../language'

const styles = StyleSheet.create({
  rightContainer: { justifyContent: 'center', alignContent: 'center' },
  leftContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    width: 46,
    maxWidth: 46,
    minWidth: 46,
  },
  icon: { width: 24, height: 24 },
  badgeContainer: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  badge: {
    height: 22,
    minWidth: 22,
    paddingLeft: 3,
    paddingRight: 3,

    backgroundColor: c.greenColor,
    borderRadius: 22 / 2,
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: -32,
    marginRight: -26,
    borderWidth: 1,
    borderColor: '#fff',
  },
  badgeLabel: { color: '#fff' },
})

class FilterMenuItem extends Component {
  _press = () => {
    const { inList, filter, componentId, context, selected, dispatch } =
      this.props
    if (!inList) {
      this.props.onClose()
    }

    if (filter.selectAction) {
      if (selected === true) {
        if (Array.isArray(filter.removeAction)) {
          filter.removeAction.forEach(ra => dispatch(ra(filter.id)))
        } else {
          dispatch(filter.removeAction(filter.id))
        }
      } else {
        dispatch(filter.selectAction(filter.id))
      }

      return
    }

    const item = selected && selected[0]
    const parentId =
      item &&
      (item.parent && item.parent.id
        ? item.parent.id
        : item.parentId
        ? item.parentId
        : item.parent_id)

    if (filter.SelectFunction) {
      // custom things like modal open or something like that instead of a screen push
      filter.SelectFunction(dispatch)
    } else {
      pushCustomEvent(`Filter opened: ${filter.callToAction}`, ``, {
        name: filter.callToAction,
      })
      push(componentId, {
        component: {
          name: FILTER_MODAL_ROUTE,
          passProps: {
            activeId: filter.id,
            parentId,
            context,
          },
        },
      })
    }
  }
  _removeFilter = filter => {
    if (Array.isArray(filter.removeAction)) {
      filter.removeAction.forEach(ra => this.props.dispatch(ra(filter.id)))
    } else {
      this.props.dispatch(filter.removeAction(filter.id))
    }
  }

  render() {
    const { filter, selected, inList, testID } = this.props
    let hasSelected = isFilterValueSet(filter, selected)
    const language = getLanguage()
    if (
      (filter.id === 'bufferZone' && selected && selected.present === false) ||
      (filter.id === 'parcelCloseToWater' &&
        selected &&
        selected.aquaticBufferZone &&
        selected.aquaticBufferZone.present === false) ||
      (selected && selected.parcelCloseToWater === false)
    ) {
      hasSelected = true
    }

    let description =
      this.props.withoutText && filter.id === 'certification'
        ? language === 'nl'
          ? 'Selecteer keurmerken'
          : 'Select certifications'
        : filter.callToActionDescription
    if (hasSelected && Array.isArray(selected)) {
      description = selected
        .map(item => {
          return filter.getItemLabel(item)
        })
        .join(', ')
    } else if (hasSelected && !description) {
      description = filter.getItemLabel(selected)
    }

    if (filter.id === 'parcelCloseToWater') {
      console.log(hasSelected, selected, filter)
    }
    if (filter.id === 'resistance') {
      if (hasSelected && Array.isArray(selected)) {
        const selectedGrouped = groupBy(selected, 'type')
        description =
          'Verberg ' +
          Object.keys(selectedGrouped)
            .map(key => {
              const allItemsOfThisKey = selectedGrouped[key]

              return `${key.toUpperCase()}: ${allItemsOfThisKey
                .map(item => filter.getItemLabel(item))
                .join(', ')}`
            })
            .join(', ')
      }
    }
    if (filter.id === 'certification') {
      if (hasSelected) {
        const selectedFiltered = Object.keys(selected).filter(
          key => selected[key]
        )
        description =
          language === 'nl'
            ? `Verberg middelen die niet toegestaan zijn volgens${
                selectedFiltered.length > 1 ? ': ' : ' '
              }` +
              selectedFiltered
                .map(key => {
                  let formattedKey = key.charAt(0).toUpperCase() + key.slice(1)
                  if (key === 'mps') {
                    formattedKey = key.toUpperCase()
                  } else if (key === 'none') {
                    formattedKey =
                      language === 'nl'
                        ? 'Geen keurmerk'
                        : 'Without certification'
                  }
                  return `${formattedKey}`
                })
                .join(', ')
            : `Hide products which are not allowed by${
                selectedFiltered.length > 1 ? ': ' : ' '
              }` +
              selectedFiltered
                .map(key => {
                  let formattedKey = key.charAt(0).toUpperCase() + key.slice(1)
                  if (key === 'mps') {
                    formattedKey = key.toUpperCase()
                  } else if (key === 'none') {
                    formattedKey =
                      language === 'nl'
                        ? 'Geen keurmerk'
                        : 'Without certification'
                  }
                  return `${formattedKey}`
                })
                .join(', ')
      }
      if (this.props.withoutText) {
        description = description.replace(
          'Verberg middelen die niet toegestaan zijn volgens: ',
          ''
        )
        description = description.replace(
          'Verberg middelen die niet toegestaan zijn volgens ',
          ''
        )
        description = description.replace(
          'Hide products which are not allowed by: ',
          ''
        )
        description = description.replace(
          'Hide products which are not allowed by ',
          ''
        )
        description = description.replace(', WithoutText', '')
      }
    }

    const left = (
      <View style={styles.leftContainer}>
        <View style={styles.icon}>
          <filter.Icon color={'blue'} />
        </View>

        {hasSelected ? (
          <View style={styles.badgeContainer}>
            <View
              style={[
                styles.badge,
                selected &&
                (selected.present === undefined || selected.present !== false)
                  ? {}
                  : { backgroundColor: 'red' },
              ]}
            >
              {Array.isArray(selected) ? (
                <Text style={styles.badgeLabel}>{selected.length}</Text>
              ) : selected &&
                (selected.present === undefined ||
                  selected.present !== false) ? (
                <Icon name="check" size={14} color="#fff" />
              ) : (
                <Icon name="close" size={14} color="#fff" />
              )}
            </View>
          </View>
        ) : null}
      </View>
    )

    const MenuItem = inList ? SafeListItem : Menu.Item
    const MenuItemProps = inList
      ? {
          left,
          right: hasSelected ? (
            <View style={styles.rightContainer}>
              <IconButton
                icon={'close'}
                onPress={() => this._removeFilter(filter)}
              />
            </View>
          ) : null,
          style: { minHeight: 56 },
          titleStyle: { fontSize: 14 },
          descriptionStyle: { fontSize: 13 },
          safeSettings: { left: true, right: false },
          // style: { maxWidth: 250 },
        }
      : {
          icon: ({ size }) => left,
        }
    return (
      <MenuItem
        testID={testID}
        style={{ width: 350 }}
        onPress={this._press}
        title={filter.callToAction}
        description={description}
        {...MenuItemProps}
      />
    )
  }
}

export default FilterMenuItem
