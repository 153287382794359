import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import ProductListItem from './ProductListItem'
import GeneralList, {
  GeneralListProps,
} from '../GeneralListFunctions/GeneralList'
import { useActionsAsFunctions } from '../helpers/actions'

import FilterTool from '../FilterType/FilterTool'
import { RootState } from '../reducers'
import { ProductType } from './reducer'
import actions from './actions'
// @ts-ignore
import { Translator } from 'react-translated'
import data from './MockData.json'

interface ProductsListType extends GeneralListProps<ProductType> {
  context: string
}

function ProductsList({ componentId, context, ...rest }: ProductsListType) {
  const selected = useSelector((state: RootState) => state.products.selected)

  const list = useSelector((state: RootState) => state.products.list)

  const actionAsDispatchers = useActionsAsFunctions(actions)

  const renderItem = useCallback(
    ({ item, index }: { item: ProductType; index: number }) => {
      return (
        <ProductListItem
          key={item.id}
          item={item}
          testID={`product_${index}`}
          componentId={componentId}
          isSelected={selected.some(it => it.id === item.id)}
          onSelect={actionAsDispatchers.select}
        />
      )
    },
    [componentId, actionAsDispatchers.select, selected]
  )

  const renderStickyBelowHeader = () => {
    return (
      <FilterTool
        key="filter-tool"
        componentId={componentId}
        context={context}
      />
    )
  }

  return (
    <Translator>
      {({ translate }: { translate: any }) => (
        <GeneralList<ProductType>
          componentId={componentId}
          {...actionAsDispatchers}
          {...rest}
          list={
            process.env.REACT_APP_USE_MOCK_DATA === 'true'
              ? { ...list, loadingList: false, loadedList: true, data: data }
              : list
          }
          searchPlaceholder={translate({ text: 'searchProduct' })}
          selected={selected}
          renderStickyBelowHeader={renderStickyBelowHeader}
          renderItem={renderItem}
          testID={'compare'}
          icon
          selectAll={false}
          loadListOnMount={false}
        />
      )}
    </Translator>
  )
}

export default React.memo(ProductsList)
