import React, { useCallback, useState } from 'react'
import ChooseFarmerModal from './ChooseFarmerModal'

export function ChooseFarmerRenderer({ componentId, render, onChange }) {
  const [isVisible, setIsVisible] = useState(false)

  const onRequestClose = useCallback(() => {
    setIsVisible(false)
  }, [setIsVisible])

  const onShowModal = useCallback(() => {
    setIsVisible(true)
  }, [setIsVisible])

  return (
    <>
      {render({ onShowModal })}

      <ChooseFarmerModal
        isVisible={isVisible}
        componentId={componentId}
        onRequestClose={onRequestClose}
        onChange={onChange}
      />
    </>
  )
}
