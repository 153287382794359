import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Keyboard, Platform, StyleSheet, View } from 'react-native'
import { connect, useDispatch, useSelector } from 'react-redux'
import { getBoundingBox } from '../helpers/boundingBox'

import { loadPolygons } from './actions'
import MapView from '../Map/Map'
import MapUserLocation from '../Map/MapUserLocation'
import FieldsOrganizationPolygons from './FieldsOrganizationPolygons'
import Search from '../MapSearch/Search'
import LoadCurrentLocationButton from '../Map/LoadCurrentLocationButton'
import { findFieldByCoordinates, getCoordinatesFromField } from '../Map/helpers'
import MapError from '../Map/MapError'
import { FILTER_MODAL_ROUTE } from '../Routes.config'
import { push } from '../Navigation'
import { Translate } from 'react-translated'
import { getStatusBarHeight } from 'react-native-iphone-x-helper'
import Tag from '../FilterType/FilterTag'
import gpActions from '../FilterType/GrowingPlan/actions'
import cropActions from '../FilterType/Crop/actions'
import farmerActions from '../FilterType/AdvisorFarmer/actions'
import contractActions from '../GroupedCrop/Contract/List/actions'
import qmActions from '../FilterType/Switches/Certification/actions'
import { getLanguage, getTranslatedString } from '../language'

const casingMap = lang => {
  return {
    none: getTranslatedString('none', lang),
    planetProof: getTranslatedString('planetProof', lang),
    mps: getTranslatedString('mps', lang),
    skal: getTranslatedString('skal', lang),
    demeter: getTranslatedString('demeter', lang),
  }
}

function FieldsOrganizationMap({
  componentId,
  selectedField,
  fullScreen = true,
  onHeaderIconPress,
  headerIcon,
  onPressField,
  organizationId,
  selectedFarmer,
  selectedCrop,
  selectedContract,
  selectedGrowingPlan,
  certifications,
}) {
  let fieldCoordinates = getCoordinatesFromField(selectedField)

  const dispatch = useDispatch()
  const dataFields = useSelector(state => state.fieldOrganizationMap.data.field)

  const fields = useSelector(state => state.fieldOrganizationMap.fieldData)
  const error = useSelector(state => state.fieldOrganizationMap.error)
  const loadingFields = useSelector(state => state.fieldOrganizationMap.loading)
  const fieldsRef = React.useRef([])
  useEffect(() => {
    fieldsRef.current = fields
    return () => {}
  }, [fields])

  const [mapRef, setMap] = useState(undefined)
  const onRef = useCallback(c => c && setMap(c), [setMap])

  const bounds = useMemo(() => {
    if (dataFields && dataFields.features[0].geometry.coordinates.length > 0) {
      const bounding = getBoundingBox(dataFields)
      // convert this to mapbox bounding
      // https://docs.mapbox.com/mapbox-gl-js/example/fitbounds/
      const value = Platform.OS === 'web' ? 0.01 : 0.08
      let mapBoxBounding = {
        ne: [bounding.xMin + value, bounding.yMax + value],
        sw: [bounding.xMax - value, bounding.yMin - value],
      }
      // if (bounding.xMin === bounding.xMax) {
      //   return undefined
      // }
      return mapBoxBounding
    }

    return null
  }, [dataFields])

  const onPress = useCallback(
    ({ latitude, longitude }) => {
      const matchedField = findFieldByCoordinates({
        fields: fieldsRef.current || [], // fix closure to old reference
        longitude,
        latitude,
      })

      if (matchedField && onPressField) {
        onPressField(matchedField)
      }
    },
    [onPressField]
  )

  useEffect(() => {
    dispatch(loadPolygons({ organizationId }))
  }, [dispatch, organizationId])

  const selectComponent = useCallback(
    route => () => {
      Keyboard.dismiss()
      // DO FARMER SCREEN HERE
      push(componentId, {
        component: {
          name:
            Platform.OS === 'web'
              ? FILTER_MODAL_ROUTE.replace(':context', 'field')
              : FILTER_MODAL_ROUTE,
          passProps: {
            activeId: route,
          },
        },
      })
    },
    [componentId]
  )
  const STATUS_BAR_HEIGHT = getStatusBarHeight() || 0

  const subscribeToUserLocation = !fieldCoordinates && !bounds
  const lang = getLanguage()
  return (
    <View style={styles.container} keyboardShouldPersistTaps={'handled'}>
      <LoadCurrentLocationButton loading={loadingFields} />
      <View
        style={{
          position: 'absolute',
          top: 8 + STATUS_BAR_HEIGHT + 52 + 4,
          left: 16,
          zIndex: 2,
          flexDirection: 'row',
        }}
      >
        <View
          style={{ maxWidth: '100%', flexDirection: 'row', flexWrap: 'wrap' }}
        >
          <Tag
            label={<Translate text="growingPlan" />}
            subLabel={
              selectedGrowingPlan.length !== 0 && selectedGrowingPlan[0].name
            }
            onPress={selectComponent('growingPlan')}
            remove={
              selectedGrowingPlan.length !== 0
                ? () => dispatch(gpActions.removeSelected())
                : null
            }
            color={'grey'}
            row
          />
          <Tag
            label={<Translate text="crop" />}
            subLabel={selectedCrop.length !== 0 && selectedCrop[0].name}
            onPress={selectComponent('crop')}
            remove={
              selectedCrop.length !== 0
                ? () => dispatch(cropActions.removeSelected())
                : null
            }
            color={'grey'}
            row
          />
          <Tag
            label={<Translate text="certification" />}
            subLabel={
              certifications.length !== 0 &&
              certifications.map(item => casingMap(lang)[item]).join(', ')
            }
            onPress={selectComponent('certification')}
            remove={
              certifications.length !== 0
                ? () => dispatch(qmActions.removeSelectedKeyValue())
                : null
            }
            color={'grey'}
            row
          />
          <Tag
            label={<Translate text="farmer" />}
            subLabel={selectedFarmer.length !== 0 && selectedFarmer[0].name}
            onPress={selectComponent('farmer')}
            remove={
              selectedFarmer.length !== 0
                ? () => dispatch(farmerActions.removeSelected())
                : null
            }
            color={'grey'}
            row
          />
          <Tag
            label={<Translate text="contract" />}
            subLabel={
              selectedContract.length !== 0 && selectedContract[0].number
            }
            onPress={selectComponent('contract')}
            remove={
              selectedContract.length !== 0
                ? () => dispatch(contractActions.removeSelected())
                : null
            }
            color={'grey'}
            row
          />
        </View>
      </View>
      <MapView
        onRef={onRef}
        bounds={bounds}
        centerCoordinates={!bounds && fieldCoordinates}
        onPressLatLong={onPress}
        fullScreen={fullScreen}
        // subscribeToUserLocation={subscribeToUserLocation}
        render={permission => (
          <>
            <FieldsOrganizationPolygons selectedField={selectedField} />
            {permission && (
              <MapUserLocation
                subscribeToUserLocation={subscribeToUserLocation}
                mapRef={mapRef}
              />
            )}
          </>
        )}
      />

      <Search
        withPadding
        fullScreen={fullScreen}
        componentId={componentId}
        onIconPress={onHeaderIconPress}
        icon={headerIcon}
      />
      {error && (
        <MapError
          fullScreen={fullScreen}
          label={<Translate text="errorParcels" />}
        />
      )}
    </View>
  )
  // } else {
  //   return null
  // }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'relative',
  },
  noSelectedFarmer: {
    zIndex: 200,
    // bottom: 90,/
    height: 54,
    justifyContent: 'center',
    // padding: 5,
    marginRight: 10,
    marginBottom: 10,
    borderRadius: 10,
    backgroundColor: '#FFF',
  },
})

const mapStateToProps = state => ({
  selectedFarmer: state.filter.advisorFarmer.selected,
  selectedCrop: state.filter.crop.selected,
  selectedContract: state.filter.contract.selected,
  selectedGrowingPlan: state.filter.growingPlan.selected,
  certifications: Object.keys(
    state.filter.certification.selectedKeyValue
  ).filter(key =>
    state.filter.certification.selectedKeyValue[key] ? key : false
  ),
})
export default connect(mapStateToProps, null)(FieldsOrganizationMap)
