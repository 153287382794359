import { RestrictionSummaryType } from '../Products/ItemNoteIcons'
const ns = (type: string) => `NOTIFICATION_${type}`

export const SET_MESSAGES = ns('SET_MESSAGES')
export const setMessages = (payload: string[]) => ({
  type: SET_MESSAGES,
  payload,
})

export const SET_NOTIFCATIONS = ns('SET_NOTIFCATIONS')
export const setNotifications = (payload: string[]) => ({
  type: SET_NOTIFCATIONS,
  payload,
})

export const SET_RESTRICTIONS = ns('SET_RESTRICTIONS')
export const setRestrictions = (payload: RestrictionSummaryType) => ({
  type: SET_RESTRICTIONS,
  payload,
})

export const DISMISS = ns('DISMISS')
export const dismiss = () => ({
  type: DISMISS,
})
