import React, { useCallback } from 'react'
import { Image, Keyboard, Platform } from 'react-native'
import { useDispatch } from 'react-redux'
import { setMessages } from '../Notifications/actions'

import { IconButton } from 'react-native-paper'
import { pushCustomEvent } from '../Analytics/abstraction'
import { getItemLabel } from './helper'

const PlanetProof = ({ planetProof, product }) => {
  const dispatch = useDispatch()
  const forbiddenItem = planetProof.find(item => item.forbidden)

  const onPress = useCallback(
    items => {
      Keyboard.dismiss()
      console.log(items)
      let fullText =
        items[0] && items[0].list.description
          ? items[0].list.description
          : items[0] && items[0].list.name
      items.forEach(it => {
        const newText = it.list.description ? it.list.description : it.list.name
        if (newText !== fullText) {
          fullText += `\n\n${newText}`
        }
      })
      dispatch(setMessages([fullText]))
      pushCustomEvent(
        'Pressed PlanetProof icon on product:',
        `${getItemLabel(product)}`
      )
    },
    [dispatch, product]
  )

  if (forbiddenItem) {
    return (
      <IconButton
        onPress={() => onPress([forbiddenItem])}
        icon={({ size }) => (
          <Image
            style={{ width: size, height: size }}
            source={
              Platform.OS === 'web'
                ? require('../../img/webp/planetproof-forbidden.webp')
                : require('../../img/planetproof-forbidden.png')
            }
          />
        )}
      />
    )
  }

  if (planetProof.length > 0) {
    const item = planetProof[0]
    return (
      <IconButton
        key={item.activeSubstanceID}
        onPress={() => onPress(planetProof)}
        icon={({ size }) => (
          <Image
            style={{ width: size, height: size }}
            source={
              item.points > 0
                ? Platform.OS === 'web'
                  ? require('../../img/webp/planetproof-bonus.webp')
                  : require('../../img/planetproof-bonus.png')
                : Platform.OS === 'web'
                ? require('../../img/webp/planetproof-malus.webp')
                : require('../../img/planetproof-malus.png')
            }
          />
        )}
      />
    )
  }
  return null
}

export default PlanetProof
