import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { initAnalytics } from './abstraction'

function Analytics() {
  const authUser = useSelector(state => state.auth && state.auth.user)

  useEffect(() => {
    const user = (authUser && authUser.user) || {}
    const organization = (authUser && authUser.organization) || {}
    if (user && organization) {
      initAnalytics({ user, organization })
    }
  }, [authUser])

  return null
}
export default Analytics
