import React, { useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import { Appbar } from 'react-native-paper'

import actions from './actions'
import PageHeader from '../../PageHeader'

import GeneralList from '../../GeneralListFunctions/GeneralList'
import Navigation from '../../Navigation'
import FarmerItem from '../../Farmer/FarmerItem'
import { hasRole } from '../../helpers/role'
import { Translator } from 'react-translated'

function FarmersListForAdvisor(props) {
  const { componentId, title, select, user } = props

  const onBack = useCallback(() => Navigation.pop(componentId), [componentId])

  const isContractor = hasRole(user, 'contractor')
  const isTransporter = hasRole(user, 'transporter')
  const onPressItem = useCallback(
    (item) => {
      select(item, { singleSelect: true })
      Navigation.pop(componentId)
    },
    [componentId, select],
  )
  const renderItem = useCallback(
    ({ item }) => <FarmerItem item={item} onPress={onPressItem} />,
    [onPressItem],
  )

  const extraParams = useMemo(() => {
    return isContractor || isTransporter
      ? {
        sort: 'name',
      }
      : {
        // onlyUserManaged: true,
        sort: 'name',
      }
  }, [isContractor, isTransporter])

  return (
    <Translator>
      {({ translate }) => (
        <GeneralList
          {...props}
          silentReloadingOnMount={false}
          searchPlaceholder={translate({text: 'searchForCompanies'})}
          renderItem={renderItem}
          icon
          extraParams={extraParams}
          appbarContent={
            <PageHeader
              componentId={componentId}
              back={onBack}
              title={<Appbar.Content title={title || translate({text: 'companies'})} />}
            />
          }
        />
      )}
    </Translator>
  )
}

const mapStateToProps = (state) => ({
  list: state.filter.advisorFarmer.list,
  user: state.auth.user.user,
})

export default connect(mapStateToProps, actions)(FarmersListForAdvisor)
