import React from 'react'
import { connect } from 'react-redux'
import ProductListItem from '../ProductListItem'
import safeAreaHOC from '../../WidthAndHeight/safeAreaHOC'
import GeneralList from '../../GeneralListFunctions/GeneralList'
import actions from './actions'
import idx from 'idx'
import { Appbar } from 'react-native-paper'
import PageHeader from '../../PageHeader'
import { Translator } from 'react-translated'

const extraParams = {
  language: process.env.REACT_APP_API_LANG || 'nl_nl',
  expirationDateFrom: Math.floor(new Date()),
}

function AdditivesList({
  componentId,
  safe,
  totalCount,
  context,
  value,
  onSelect,
  onBack,
  extraParamsFromProps,
  ...rest
}) {
  const renderItem = ({ item }) => {
    return (
      <ProductListItem
        key={item.id}
        item={item}
        componentId={componentId}
        isSelected={value && value.id === item.id}
        onSelect={onSelect}
        selectType={'radiobutton'}
        selectOnly
      />
    )
  }

  return (
    <Translator>
      {({ translate }) => (
        <GeneralList
          {...rest}
          searchPlaceholder={translate({ text: 'searchProductName' })}
          renderItem={renderItem}
          icon
          extraParams={{ ...extraParams, ...(extraParamsFromProps || {}) }}
          appbarContent={
            <PageHeader
              withoutAccount={true}
              componentId={componentId}
              back={onBack}
              title={
                <Appbar.Content title={translate({ text: 'selectResource' })} />
              }
            />
          }
        />
      )}
    </Translator>
  )
}

const mapStateToProps = state => ({
  loading: state.additiveSingleSelect.list.loading,
  loadingMore: state.additiveSingleSelect.list.loadingMore,
  list: state.additiveSingleSelect.list,
  totalCount:
    idx(state.additiveSingleSelect.list, _ => _.pagination.total) || 0,
})

export default connect(mapStateToProps, actions)(safeAreaHOC(AdditivesList))
