import React from 'react'
import { StyleSheet } from 'react-native'
import Resizer from './Resizer'

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    height: 56,
    alignItems: 'center',
  },
})

export default function AppbarResizer({ style, ...rest }: any) {
  return <Resizer style={[styles.root, style]} {...rest} />
}
