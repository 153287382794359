import React, { Component } from 'react'
import { View } from 'react-native'
import { Button, Text } from 'react-native-paper'
import styles from './AcceptTerms.style'

import c from '../constants'
import CheckBoxWithLabel from './CheckboxWithLabel'
import { push } from '../Navigation'
import { PDF_ROUTE } from '../Routes.config'

import Agro4allLogo from '../vectors/Agro4allLogo'
import AppbarHeader from '../WidthAndHeight/AppbarHeader'
import BottomFab from '../WidthAndHeight/BottomFab'
import { getLanguage } from '../language'

class FieldComponentWithButton extends Component {
  state = {
    accepted: false,
  } // true
  _changeValue = accepted => {
    this.setState({
      accepted,
    })
  }
  _openTerms = () => {
    const language = getLanguage()
    push(this.props.componentId, {
      component: {
        name: PDF_ROUTE,
        passProps: {
          url: encodeURI(c.termsAndConditionsUrl),
          title: language === 'nl' ? 'Gebruiksvoorwaarden' : 'Terms of use',
        },
      },
    })
  }

  render() {
    return (
      <>
        <AppbarHeader
          style={{
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Agro4allLogo />
        </AppbarHeader>

        <View style={styles.fieldComponentWithButton}>
          <View style={styles.termsContainer}>
            <CheckBoxWithLabel
              value={this.state.accepted}
              innerStyle={styles.innerCheckBoxStyle}
              testID={'acceptTerms'}
              theme={'transparent'}
              onChangeValue={this._changeValue}
              labelComponent={
                <Text style={styles.termsText}>
                  Bij gebruik van deze app ga ik expliciet akkoord met de
                  gebruikersvoorwaarden
                </Text>
              }
            />
            <Button
              mode={'outlined'}
              onPress={this._openTerms}
              style={{ margin: 16 }}
              uppercase={false}
            >
              Bekijk gebruikersvoorwaarden
            </Button>
          </View>
        </View>
        <BottomFab
          small
          icon="check"
          label={'Akkoord'}
          testID={'next'}
          disabled={this.state.accepted !== true}
          onPress={this.props.onSubmit}
          color="#fff"
        />
      </>
    )
  }
}

export default FieldComponentWithButton
