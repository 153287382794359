import React, { useCallback } from 'react'
import ClosableModal from '../../components/ClosableModal'
import FarmersListForAdvisor from './FarmersListForAdvisor'
import { Translate } from 'react-translated'

function ChooseFarmerForAdvisorModal({
  onRequestClose,
  onChange,
  componentId,
  isVisible,
  value,
  plainList,
}) {
  const isSelected = useCallback(
    item => item.id === (value && value.id),
    [value]
  )

  const onPressItem = useCallback(
    item => {
      onChange(item)
      onRequestClose()
    },
    [onChange, onRequestClose]
  )
  return (
    <ClosableModal
      isVisible={isVisible}
      onBackButtonPress={onRequestClose}
      onBackdropPress={onRequestClose}
      onRequestClose={onRequestClose}
      onModalHide={onRequestClose}
      hideHeader={true}
    >
      {isVisible && (
        <FarmersListForAdvisor
          title={<Translate text={'connectCompany'} />}
          componentId={componentId}
          isSelected={isSelected}
          onRequestClose={onRequestClose}
          onBack={onRequestClose}
          onPressItem={onPressItem}
          onChange={onPressItem}
          //   onCreated={onCreated}
          plainList={plainList}
        />
      )}
    </ClosableModal>
  )
}

export default ChooseFarmerForAdvisorModal
