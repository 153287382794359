import React, { useCallback, useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import FormContainer from '../../Form/FormContainer'
import safeAreaHOC from '../../WidthAndHeight/safeAreaHOC'
import KeyboardAvoidingScrollView from '../../WidthAndHeight/KeyboardAvoidingScrollView'
import AppbarHeader from '../../WidthAndHeight/AppbarHeader'
import AppbarResizer from '../../WidthAndHeight/AppbarResizer'
import { Appbar, Menu, Text } from 'react-native-paper'
import { Translate, Translator } from 'react-translated'
import Box from '../../components/Box'
import Amount from '../../Form/Amount'
import ListItem from '../../WidthAndHeight/ListItemWithBottom'
import Fields from '../../Form/Fields'
import { connect } from 'react-redux'
import actions from '../actions'
import { usePrevious } from '../../Auth/ChangePassword'
import widthAndHeightHOC from '../../WidthAndHeight/widthAndHeightHOC'

const baseLine = {
  startDate: Math.floor(new Date()),
  endDate: Math.floor(
    new Date(new Date().setFullYear(new Date().getFullYear() + 1))
  ),
}
export const overAllFields = [
  {
    name: 'name',
    label: <Translate text="name" />,
    type: 'text',
    fieldProps: {
      // style: { marginLeft: 6, marginRight: 6 },
    },
  },
]

function SectionSubDivision({ title, prev, next, object, setData, isMedium }) {
  const [visible, setVisible] = React.useState(false)

  const closeMenu = () => setVisible(false)

  const innerSetData = useCallback(
    (name, value) => {
      console.log({ name, value })
      setData({
        ...(object || {}),
        [name]: value,
      })
    },
    [object, setData]
  )
  const setAmount = value => {
    const oldArr = object?.sections || []
    const newArr = []
    for (let i = 0; i < value; i++) {
      newArr.push(oldArr[i] || {})
    }
    innerSetData('sections', newArr)
  }
  const [unit, setUnitState] = React.useState(
    object?.sections?.length > 0 ? object?.sections[0]?.unit?.name : undefined
  )
  const preSetUnit = unit => {
    setUnitState(unit)
  }
  const setUnit = useCallback(
    unit => {
      const oldArr = object?.sections || []
      const newArr = []
      for (let i = 0; i < oldArr.length; i++) {
        newArr.push({
          ...oldArr[i],
          id: oldArr[i]?.id,
          growingPeriods:
            oldArr[i]?.growingPeriods?.length > 0
              ? oldArr[i]?.growingPeriods
              : [baseLine],
          name:
            (unit === 'Vakken' || unit === 'Vak'
              ? 'Vak'
              : unit === 'Kappen' || unit === 'Kap'
              ? 'Kap'
              : unit === 'Bedden' || unit === 'Bed'
              ? 'Bed'
              : unit === 'Rijen' || unit === 'Rij'
              ? 'Rij'
              : unit === 'Tafels' || unit === 'Tafel'
              ? 'Tafel'
              : unit === 'Vloeren' || unit === 'Vloer'
              ? 'Vloer'
              : 'Vak') +
            ' ' +
            (i + 1),
          unit: {
            name:
              unit === 'Vakken' || unit === 'Vak'
                ? 'Vak'
                : unit === 'Kappen' || unit === 'Kap'
                ? 'Kap'
                : unit === 'Bedden' || unit === 'Bed'
                ? 'Bed'
                : unit === 'Rijen' || unit === 'Rij'
                ? 'Rij'
                : unit === 'Tafels' || unit === 'Tafel'
                ? 'Tafel'
                : unit === 'Vloeren' || unit === 'Vloer'
                ? 'Vloer'
                : 'Vak',
          },
        })
      }
      innerSetData('sections', newArr)
    },
    [innerSetData, object]
  )

  const prevSectionsLength = usePrevious(object?.sections?.length)

  const prevUnit = usePrevious(unit)
  useEffect(() => {
    if (prevSectionsLength !== object?.sections?.length && unit) {
      setUnit(unit)
    } else if (unit !== prevUnit) {
      setUnit(unit)
    }
  }, [unit, setUnit, object, prevSectionsLength, prevUnit])
  return (
    <>
      <AppbarHeader
        key="header"
        dark
        style={{ elevation: 0, justifyContent: 'center' }}
      >
        <AppbarResizer>
          <Appbar.BackAction onPress={prev} color="#fff" />
          <Appbar.Content title={title} color="#fff" />

          {/* <Appbar.Action icon={'delete'} onPress={onRemove} color="#fff" /> */}
        </AppbarResizer>
      </AppbarHeader>
      <KeyboardAvoidingScrollView>
        <View
          style={{
            maxWidth: 800,
            alignSelf: 'center',
            width: '100%',
            marginTop: 32,
          }}
        >
          <FormContainer onSubmit={next}>
            <Translator>
              {({ translate }) => (
                <>
                  <View style={{ marginBottom: 24 }}>
                    <Fields
                      fields={overAllFields}
                      values={object}
                      onChange={innerSetData}
                      onSubmit={() => console.log('submit')}
                    />
                  </View>
                  <Box
                    title={translate({ text: 'subDivisions' })}
                    style={{ marginBottom: 16 }}
                  >
                    <View
                      style={{
                        flexDirection: isMedium ? 'row' : 'column',
                        flex: 1,
                        width: '100%',
                        flexGrow: 1,
                      }}
                    >
                      <Amount
                        name="amount"
                        label={translate({ text: 'amount' })}
                        step={1}
                        value={object?.sections?.length || 0}
                        onChange={setAmount}
                        fieldProps={{
                          style: { marginLeft: 6, marginRight: 6, flex: 1 },
                        }}
                      />
                      <Menu
                        visible={visible}
                        onDismiss={closeMenu}
                        style={{ flex: 1, marginTop: 24 }}
                        anchor={
                          <ListItem
                            onPress={() => setVisible(true)}
                            title={
                              unit ? unit : translate({ text: 'selectUnit' })
                            }
                            description={
                              unit
                                ? translate({ text: 'unit' })
                                : translate({ text: 'noUnitSelected' })
                            }
                            style={{
                              flex: 1,
                              width: '100%',
                              paddingLeft: 16,

                              marginTop: 24,
                            }}
                            // right={}
                          />
                        }
                      >
                        <Menu.Item
                          onPress={() => {
                            closeMenu()
                            preSetUnit('Vakken')
                          }}
                          title="Vakken"
                        />
                        <Menu.Item
                          onPress={() => {
                            closeMenu()
                            preSetUnit('Kappen')
                          }}
                          title="Kappen"
                        />
                        <Menu.Item
                          onPress={() => {
                            closeMenu()
                            preSetUnit('Bedden')
                          }}
                          title="Bedden"
                        />
                        <Menu.Item
                          onPress={() => {
                            closeMenu()
                            preSetUnit('Rijen')
                          }}
                          title="Rijen"
                        />
                        <Menu.Item
                          onPress={() => {
                            closeMenu()
                            preSetUnit('Tafels')
                          }}
                          title="Tafels"
                        />
                        <Menu.Item
                          onPress={() => {
                            closeMenu()
                            preSetUnit('Vloeren')
                          }}
                          title="Vloeren"
                        />
                      </Menu>
                    </View>
                    <Text
                      style={{
                        marginLeft: 16,
                        marginTop: 16,
                        color: 'rgb(164, 164, 164)',
                        fontStyle: 'italic',
                      }}
                    >
                      {translate({ text: 'requiredUnitAndAmount' })}
                    </Text>
                  </Box>
                </>
              )}
            </Translator>
          </FormContainer>
        </View>
        <View style={styles.paddingScroll} />
      </KeyboardAvoidingScrollView>
    </>
  )
}

const styles = StyleSheet.create({
  paddingScroll: {
    paddingBottom: 200,
  },
  fieldsMapContainer: {
    height: 350,
    borderRadius: 8,
    margin: 16,
    overflow: 'hidden',
  },
})

const mapStateToProps = state => ({
  object: state.organizationField.crud.object,
})
export default widthAndHeightHOC(
  safeAreaHOC(connect(mapStateToProps, actions)(SectionSubDivision))
)
