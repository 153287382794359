export const attributes = language => [
  {
    label: language === 'nl' ? 'Actieve stof' : 'Active substance',
    parse: (d = {}) => {
      return {
        hasValue: d.activeSubstance && !!d.activeSubstance.name,
        value:
          d.activeSubstance &&
          (d.activeSubstance.nameNl
            ? d.activeSubstance.nameNl
            : d.activeSubstance.nameEn
            ? d.activeSubstance.nameEn
            : d.activeSubstance.name),
      }
    },
  },
  {
    label: language === 'nl' ? 'EU registratie nr.' : 'EU registration nr.',
    parse: (d = {}) => {
      let value = '-'

      if (d.activeSubstance && d.activeSubstance.ecNumber) {
        value = d.activeSubstance.ecNumber
      }
      return {
        hasValue:
          d.activeSubstance &&
          !!d.activeSubstance.ecNumber &&
          d.activeSubstance.ecNumber !== '',
        value,
      }
    },
  },
  {
    label: language === 'nl' ? 'Laag risco' : 'Low risk',
    parse: (d = {}) => {
      return {
        hasValue: d.activeSubstance && true,
        value: d.activeSubstance && d.activeSubstance.lowRisk ? 'Ja' : 'Nee',
      }
    },
  },
  {
    label: language === 'nl' ? 'MoA' : 'Mode of Action',
    parse: (d = {}) => {
      let value = '-'
      if (d.activeSubstance && !!d.activeSubstance.hrac) {
        value = `${d.activeSubstance.hrac.modeOfAction.code} - ${
          language === 'nl'
            ? d.activeSubstance.hrac.modeOfAction.name.nl
              ? d.activeSubstance.hrac.modeOfAction.name.nl
              : d.activeSubstance.hrac.modeOfAction.name.en
            : d.activeSubstance.hrac.modeOfAction.name.en
            ? d.activeSubstance.hrac.modeOfAction.name.en
            : d.activeSubstance.hrac.modeOfAction.name.nl
        }`
      } else if (d.activeSubstance && !!d.activeSubstance.frac) {
        value = `${d.activeSubstance.frac.modeOfAction.code} - ${
          language === 'nl'
            ? d.activeSubstance.frac.modeOfAction.name.nl
              ? d.activeSubstance.frac.modeOfAction.name.nl
              : d.activeSubstance.frac.modeOfAction.name.en
            : d.activeSubstance.frac.modeOfAction.name.en
            ? d.activeSubstance.frac.modeOfAction.name.en
            : d.activeSubstance.frac.modeOfAction.name.nl
        }`
      } else if (d.activeSubstance && !!d.activeSubstance.irac) {
        value = `${d.activeSubstance.irac.modeOfAction.code} - ${
          language === 'nl'
            ? d.activeSubstance.irac.modeOfAction.name.nl
              ? d.activeSubstance.irac.modeOfAction.name.nl
              : d.activeSubstance.irac.modeOfAction.name.en
            : d.activeSubstance.irac.modeOfAction.name.en
            ? d.activeSubstance.irac.modeOfAction.name.en
            : d.activeSubstance.irac.modeOfAction.name.nl
        }`
      }

      return {
        hasValue:
          d.activeSubstance &&
          (!!d.activeSubstance.hrac ||
            !!d.activeSubstance.frac ||
            !!d.activeSubstance.irac),
        value,
      }
    },
  },
  {
    label: 'HRAC code',
    parse: (d = {}) => {
      let value = '-'
      if (d.activeSubstance && !!d.activeSubstance.hrac) {
        value = `${d.activeSubstance.hrac.code}`
      }

      return {
        hasValue: d.activeSubstance && !!d.activeSubstance.hrac,
        value,
      }
    },
  },
  {
    label: 'IRAC code',
    parse: (d = {}) => {
      let value = '-'
      if (d.activeSubstance && !!d.activeSubstance.irac) {
        value = `${d.activeSubstance.irac.code}`
      }

      return {
        hasValue: d.activeSubstance && !!d.activeSubstance.irac,
        value,
      }
    },
  },
  {
    label: 'FRAC code',
    parse: (d = {}) => {
      let value = '-'
      if (d.activeSubstance && !!d.activeSubstance.frac) {
        value = `${d.activeSubstance.frac.code}`
      }

      return {
        hasValue: d.activeSubstance && !!d.activeSubstance.frac,
        value,
      }
    },
  },
]

export const planetProofAttributes = language => [
  {
    label: language === 'nl' ? 'PlanetProof punten' : 'PlanetProof points',
    parse: (d = {}) => {
      let value = '-'
      if (Array.isArray(d) && d.length === 1) {
        value = d[0].points
      }
      return {
        hasValue: true,
        value,
      }
    },
  },
]
