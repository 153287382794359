import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Button, Divider, IconButton, List, Title } from 'react-native-paper'
import Fields from '../Form/Fields'
import SingleSelect from '../Product/Crop/SingleSelect'
import ContractSelect from './Contract/ContractSelect'
// import Touchable from '../WidthAndHeight/Touchable'
import { connect } from 'react-redux'

import { push } from '../Navigation'
import * as routes from '../Routes.config'
import { Translate, Translator } from 'react-translated'
import { globalConfirm } from '../helpers/alert'
import AttributesSelect from './Attributes/AttributesSelect'
import { getLanguage } from '../language'

const baseLine = {
  startDate: Math.floor(new Date()),
  endDate: Math.floor(
    new Date(new Date().setFullYear(new Date().getFullYear() + 1))
  ),
}
const fields = translate => [
  {
    name: 'startDate',
    type: 'date',
    fieldProps: {
      label: translate({ text: 'sowDate' }),
      style: { flex: 1 },
    },
  },
  {
    name: 'endDate',
    type: 'date',
    fieldProps: {
      label: translate({ text: 'harvestDate' }),
      style: { flex: 1 },
    },
  },
]
const secondFields = [
  {
    name: 'variety',
    label: <Translate text="variety" />,
    type: 'text',
    fieldProps: {
      style: { marginLeft: 6, marginRight: 6 },
    },
  },
  {
    name: 'planetProof',
    label: 'PlanetProof',
    type: 'checkbox',
    fieldProps: {
      style: { marginLeft: 6, marginRight: 6 },
    },
  },
  {
    name: 'mps',
    label: 'MPS',
    type: 'checkbox',
    fieldProps: {
      style: { marginLeft: 6, marginRight: 6 },
    },
  },
  {
    name: 'skal',
    label: 'Skal',
    type: 'checkbox',
    fieldProps: {
      style: { marginLeft: 6, marginRight: 6 },
    },
  },
  {
    name: 'demeter',
    label: 'Demeter',
    type: 'checkbox',
    fieldProps: {
      style: { marginLeft: 6, marginRight: 6 },
    },
  },
]

function GrowingPeriods({
  label,
  value,
  onChange,
  next,
  editable = true,
  componentId,
  canDelete,
  organizationField,
}) {
  const _addLine = () => {
    onChange([...(value || []), baseLine])
  }

  const language = getLanguage()
  const removeLine = line => () => {
    globalConfirm({
      title: language === 'nl' ? 'Bevestiging' : 'Confirm',
      message:
        language === 'nl'
          ? 'Weet u zeker dat u deze teeltperiode wilt verwijderen?'
          : 'Do you want to permanently delete this growing period?',
      onConfirm: () => {
        onChange((value || []).filter(l => l !== line))
      },
    })
  }

  const changeLine = line => (name, objectValue) => {
    console.log(name)
    let newObject = { ...line, [name]: objectValue }
    if (name === 'skal') {
      newObject = { ...newObject, demeter: false }
    } else if (name === 'demeter') {
      newObject = { ...newObject, skal: false }
    }
    onChange((value || []).map(l => (l === line ? newObject : l)))
  }

  const selectCrop = line => crop => {
    onChange(
      (value || []).map(l => {
        return l === line ? { ...l, crop } : l
      })
    )
  }

  const selectContract = line => contract => {
    onChange(
      (value || []).map(l => {
        return l === line ? { ...l, contract } : l
      })
    )
  }
  const selectAttributes = line => attributes => {
    onChange(
      (value || []).map(l => {
        return l === line ? { ...l, attributes } : l
      })
    )
  }

  const moveToField = (field, name) => () => {
    push(componentId, {
      component: {
        name: routes.GROUPED_CROP_ON_MAP_ROUTE,
        passProps: {
          fieldId: field.id,
          fieldName: name,
        },
      },
    })
  }
  const lines = value || []

  return (
    <View style={styles.root}>
      {label && <Title style={styles.title}>{label}</Title>}
      {lines.map((line, lineIndex) => (
        <View
          key={lineIndex}
          style={[
            styles.col,
            styles.lineContainer,
            // { zIndex: 100 + lines.length - lineIndex },
          ]}
        >
          <View
            style={{
              // padding: 16,
              flexDirection: 'row',
            }}
          >
            <Title style={{ fontSize: 16, padding: 16 }}>
              {(language === 'nl' ? 'Teeltperiode #' : 'Growing period #') +
                (lineIndex + 1)}
            </Title>
            {(lines.length > 0 && editable) ||
            (lines.length === 1 && editable && canDelete) ? (
              <View style={[styles.col, styles.closeContainer]}>
                <IconButton
                  icon={'delete-outline'}
                  onPress={removeLine(line)}
                />
              </View>
            ) : null}
          </View>
          <Divider style={{ paddingLeft: 16, paddingRight: 16 }} />
          {/*{line && line.cultivationMethod === 'Bedekte teelt' && (*/}
          {/*  <View style={{ padding: 8 }}>*/}
          {/*    <RowAndPole />*/}
          {/*  </View>*/}
          {/*)}*/}
          <View style={styles.rowJustifyEnd}>
            <View style={styles.full}>
              <SingleSelect
                value={line.crop}
                editable={editable}
                onSelect={selectCrop(line)}
              />
            </View>
          </View>
          <View style={styles.full}>
            <AttributesSelect
              value={(line && line.attributes) || []}
              growingPeriod={line}
              editable={editable}
              onSelect={selectAttributes(line)}
            />
          </View>
          {line.id && (
            <View style={styles.full}>
              <ContractSelect
                value={line.contract}
                growingPeriod={line}
                editable={editable}
                onSelect={selectContract(line)}
              />
            </View>
          )}

          <View style={[styles.row, styles.secondRow]}>
            <Translator>
              {({ translate }) => (
                <Fields
                  fields={fields(translate)}
                  values={line}
                  onChange={changeLine(line)}
                  onSubmit={next}
                  editable={editable}
                />
              )}
            </Translator>
          </View>
          <Fields
            fields={secondFields}
            values={{
              ...line,
              skal: !!line?.certifications?.some(cert => cert === 'SKAL'),
              demeter: !!line?.certifications?.some(cert => cert === 'DEMETER'),
              planetProof: !!line?.certifications?.some(
                cert => cert === 'PLANETPROOF'
              ),
              mps: !!line?.certifications?.some(cert => cert === 'MPS'),
            }}
            onChange={changeLine(line)}
            onSubmit={next}
            editable={editable}
          />
          {line.organizationField && line.organizationField.field && (
            <View>
              <List.Item
                onPress={moveToField(
                  line.organizationField.field,
                  line.organizationField.name
                )}
                title={
                  line.organizationField.name || language === 'nl'
                    ? 'Onbekend teeltlocatie'
                    : 'Unknown parcel'
                }
                description={
                  line.organizationField.organization.name !== ''
                    ? line.organizationField.organization.name
                    : language === 'nl'
                    ? `Naamloos Bedrijf #${line.organizationField.organization.id}`
                    : `Nameless company #${line.organizationField.organization.id}`
                }
              />
            </View>
          )}
        </View>
      ))}
      {editable ? (
        <View style={styles.bottom}>
          <Button
            icon="plus"
            onPress={_addLine}
            uppercase={false}
            mode={'outlined'}
          >
            <Translate text="addGrowingPeriod" />
          </Button>
        </View>
      ) : null}
    </View>
  )
}

const fixedZIndex = undefined

const styles = StyleSheet.create({
  title: {
    padding: 16,
    marginBottom: 0,
    paddingBottom: 0,
    marginTop: 12,
  },
  root: {
    zIndex: fixedZIndex,
    // flex: 1,
    position: 'relative',
    flexDirection: 'column',
  },
  bottom: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: 16,
    paddingBottom: 16,
    marginBottom: 56,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowJustifyEnd: { flexDirection: 'row', justifyContent: 'flex-end' },
  full: {
    flex: 1,
  },
  closeContainer: {
    flex: 1,
    padding: 6,
    alignItems: 'flex-end',
  },
  secondRow: {
    paddingLeft: 6,
    zIndex: fixedZIndex,
  },
  lineContainer: {
    position: 'relative',
    zIndex: fixedZIndex,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
    backgroundColor: '#fff',
    paddingTop: 0,
    paddingBottom: 16,
    marginBottom: 16,
    marginTop: 24,
    margin: 6,
    borderRadius: 5,
  },
})

const mapStateToProps = state => ({
  organizationField: state.organizationField.crud,
})

export default connect(mapStateToProps, null)(GrowingPeriods)
