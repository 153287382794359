import * as a from './actions'
import { pushCustomEvent } from '../../Analytics/abstraction'

const initialState: boolean | null = null

const reducer = (
  state = initialState,
  { type }: { type: string }
): boolean | null => {
  switch (type) {
    case a.CHECK: {
      pushCustomEvent('Filter enabled:', 'Grondwaterbeschermingsgebied')
      return true
    }
    case a.UNCHECK: {
      pushCustomEvent('Filter disabled:', 'Grondwaterbeschermingsgebied')
      return false
    }
    case a.REMOVE: {
      pushCustomEvent('Filter removed:', 'Grondwaterbeschermingsgebied')
      return null
    }
    default:
      return state
  }
}

export default reducer
