import React, { Component } from 'react'
import { Animated, Platform, StyleSheet, View } from 'react-native'
import SearchHeader from './SearchHeader'
import { getStatusBarHeight } from 'react-native-iphone-x-helper'
import { debounce } from 'lodash'
import c from '../constants'

const STATUS_BAR_HEIGHT = getStatusBarHeight() || 0

export const styles = StyleSheet.create({
  animatedHeader: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    zIndex: 10,

    paddingBottom: 0,
  },

  innerHeader: {
    backgroundColor: c.greenColor,
    height: 56 + STATUS_BAR_HEIGHT,
    left: 0,
    right: 0,
    top: 0,
    // elevation: Platform.OS === 'web' ? 1 : 0,
  },
  innerHeaderAbsolute: {
    position: 'absolute',
  },
  innerHeaderWithSearch: {
    height: 56 + 56 / 2 + STATUS_BAR_HEIGHT,
  },
  headerBackground: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: 122,
  },
  headerBackgroundWithSearch: {
    marginBottom: 56 / 2,
    height: 150,
  },
  searchContainer: {
    maxWidth: 700,
    width: '100%',
    alignSelf: 'center',
    justifyContent: 'center',
    paddingTop: STATUS_BAR_HEIGHT + 56,
    paddingLeft: 6,
    paddingRight: 6,
    zIndex: 100,
    // backgroundColor: 'purple',
  },
  // There is a very strange bug with elevation happening in Android
  // We need the view below to make touches in the appbarContent working on Android
  searchContainerAndroidBackButtonFix: {
    paddingTop: STATUS_BAR_HEIGHT + 56,
  },

  navbarWhite: {
    backgroundColor: '#fff',
    height: 56 + 50 + STATUS_BAR_HEIGHT,
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
  },
  appbarContentContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: STATUS_BAR_HEIGHT,
    bottom: 0,
  },
})
const isWeb = Platform.OS === 'web'

export function SimpleBackgroundHeader({
  showSearch,
  appbarContent,
  navbarOpacity = 1,
  absolute,
  zIndex,
  withoutHeaderBackground = false,
}) {
  return (
    <View
      style={[
        styles.innerHeader,
        absolute && styles.innerHeaderAbsolute,
        showSearch && styles.innerHeaderWithSearch,
        zIndex && { zIndex: 999 },
      ]}
    >
      {withoutHeaderBackground ? null : (
        <View
          style={[
            styles.headerBackground,
            showSearch && styles.headerBackgroundWithSearch,
          ]}
        >
          {/*<HeaderBackground />*/}
        </View>
      )}

      <Animated.View
        style={[styles.appbarContentContainer, { opacity: navbarOpacity }]}
      >
        {appbarContent}
      </Animated.View>
    </View>
  )
}
class GeneralListHeader extends Component {
  constructor(props) {
    super(props)

    this.debouncedSearch = debounce(this.props.onSearch, 250)
  }
  render() {
    const {
      navbarTranslate,
      navbarOpacity,
      listKey,
      appbarContent,
      renderStickyBelowHeader,
      searchPlaceholder,
      searchIcon,
      onSearchIconPress,
      searchValue,
      showSearch,
      withoutHeaderBackground,
      autoFocusSearch,
    } = this.props

    const inner = (
      <Animated.View
        key={`animated-header-${listKey}`}
        style={[
          styles.animatedHeader,
          {
            transform: [{ translateY: navbarTranslate }],
          },
        ]}
        pointerEvents="box-none"
      >
        <SimpleBackgroundHeader
          key={`SimpleBackgroundHeader-${listKey}`}
          showSearch={showSearch}
          navbarOpacity={navbarOpacity}
          appbarContent={appbarContent}
          absolute
          withoutHeaderBackground={withoutHeaderBackground}
        />

        {/* <Animated.View
          style={[
            styles.navbarWhite,
            { right: isWeb ? 15 : 0 },
            { opacity: navbarWhiteOpacity },
          ]}
          pointerEvents="box-none"
        /> */}
        {showSearch ? (
          <View style={styles.searchContainer} pointerEvents="box-none">
            <SearchHeader
              searchIcon={searchIcon}
              onIconPress={onSearchIconPress}
              searchPlaceholder={searchPlaceholder}
              onSearch={this.debouncedSearch}
              value={searchValue}
              autoFocusSearch={autoFocusSearch}
            />
          </View>
        ) : Platform.OS === 'android' ? (
          // There is a very strange bug with elevation happening in Android
          // We need the view below to make touches in the appbarContent working on Android
          <View
            style={styles.searchContainerAndroidBackButtonFix}
            pointerEvents="none"
          />
        ) : null}
        {renderStickyBelowHeader ? renderStickyBelowHeader() : null}
      </Animated.View>
    )

    if (isWeb) {
      return <div className="animated-children">{inner}</div>
    }
    return inner
  }
}

export default GeneralListHeader
