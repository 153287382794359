import * as a from './actions'

const reducer = (state = { done: false }, { type, payload }: any) => {
  switch (type) {
    case a.SET_FILTERS:
      return {
        done: false,
      }
    case a.SETTED_FILTERS:
      return {
        done: true,
      }

    default:
      return state
  }
}

export default reducer
