/* @flow */

import React, { PureComponent } from 'react'
import { Platform, View } from 'react-native'
import { Text, TouchableRipple } from 'react-native-paper'
import Link from './Link'
import styles from './ListItemWithBottom.styles'
import Resizer from './Resizer'

const isWeb = Platform.OS === 'web'

class ListItem extends PureComponent {
  static displayName = 'List.Item'
  render() {
    const {
      left,
      right,
      bottom,
      top,
      title,
      description,
      onPress,
      theme,
      style,
      titleStyle,
      descriptionStyle,
      forceDescription,
      to,
      key,
      lineNumber,
      ...rest
    } = this.props

    const useLink = to && isWeb

    let TouchableComponent = useLink ? Link : TouchableRipple
    if (isWeb && !useLink) {
      TouchableComponent = View
    }
    const buttonProps = {
      accessibilityTraits: 'button',
      accessibilityComponentType: 'button',
      accessibilityRole: 'button',
      onPress: onPress,
      onClick: isWeb ? onPress : null,
      to,
    }
    return (
      <Resizer key={key}>
        <View style={styles.root}>
          {left}
          <TouchableComponent
            {...rest}
            {...buttonProps}
            style={[styles.content, styles.item, style]}
          >
            <View style={[styles.content]} to={to}>
              <Text
                pointerEvents="none"
                numberOfLines={lineNumber ? lineNumber : 2}
                style={[styles.title, titleStyle]}
              >
                {title}
              </Text>
              {description ? (
                <Text
                  pointerEvents="none"
                  numberOfLines={3}
                  style={[styles.description, descriptionStyle]}
                >
                  {description}
                </Text>
              ) : forceDescription ? (
                <Text
                  pointerEvents="none"
                  numberOfLines={3}
                  style={[styles.description, descriptionStyle]}
                >
                  {forceDescription}
                </Text>
              ) : null}
            </View>
          </TouchableComponent>
          {right}
        </View>
      </Resizer>
    )
  }
}

export default ListItem
