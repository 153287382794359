import { actionCreator as ac } from '../../actionCreator'

const ns = type => `ADVICE_NEW_${type}`

export const SELECT_GROUPED_CROP = ns('SELECT_GROUPED_CROP')
export const selectGroupedCrop = ac(SELECT_GROUPED_CROP)

export const SELECT_FIELD_WITH_GROWING_PERIOD = ns(
  'SELECT_FIELD_WITH_GROWING_PERIOD'
)
export const selectFieldWithGrowingPeriod = ac(SELECT_FIELD_WITH_GROWING_PERIOD)

export const CREATE = ns('CREATE')
export const create = ac(CREATE)

export const CREATED = ns('CREATED')
export const created = ac(CREATED)
