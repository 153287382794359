import React, { useCallback, useEffect, useMemo } from 'react'
import { connect, useDispatch } from 'react-redux'
import distributionActions from './distribution/actions'
import productActions from './product/actions'
import PageHeader from '../PageHeader'
import DeepLink from '../DeepLink'

import GeneralList from '../GeneralListFunctions/GeneralList'
import { Appbar } from 'react-native-paper'
import ProductListItem from '../Products/ProductListItem'
import { fromTimestamp, getFriendlyDate } from '../dayjs'
import FilterTool from '../FilterType/FilterTool'
import { RootState } from '../reducers'
import { mapIds } from '../FilterType/config'

import { DistributedProduct } from './distribution/reducer'
// @ts-ignore
import { Translator } from 'react-translated'

interface DistributionProps {
  componentId: string
  selected: DistributedProduct[]
  distributionList: RootState['distribution']['distribution']['list']['list']
  selectedAdmissionHolder: any
}
function DistributionListScreen({
  componentId,
  selected,
  distributionList,
  selectedAdmissionHolder,
  ...rest
}: DistributionProps) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(distributionActions.load())
  }, [dispatch])

  const renderStickyBelowHeader = useCallback(() => {
    return (
      <FilterTool
        key="filter-tool"
        componentId={componentId}
        context="distribution"
      />
    )
  }, [componentId])
  const extraParams = useMemo(() => {
    return {
      language: process.env.REACT_APP_API_LANG || 'nl_nl',
      preloads: ['Documents', 'Documents.Links', 'PlanetProof'],
      expirationDateFrom: Math.floor(Number(new Date())),
      group_by: 'admission_number',
      admissionholders: mapIds(selectedAdmissionHolder),
    }
  }, [selectedAdmissionHolder])
  const onPressSave = useCallback(() => {
    dispatch(distributionActions.confirm())
  }, [dispatch])

  const onSelect = useCallback(
    item => {
      dispatch(distributionActions.select(item))
    },
    [dispatch]
  )
  const renderItem = ({ item }: any) => {
    const selectedProd =
      selected &&
      selected.find(
        it =>
          `${it.admissionNumber}` === item.admissionNumber ||
          `${it.productId}` === item.id
      )
    return (
      <ProductListItem
        key={item.id}
        item={item}
        noWcode
        description={
          selectedProd && selectedProd.createdAt ? (
            <Translator>
              {({
                translate,
              }: {
                translate: ({ text }: { text: string }) => string
              }) =>
                `${translate({ text: 'admissionNr' })}.: ${
                  item.admissionNumber
                } - ${translate({ text: 'addedOn' })}: ${getFriendlyDate(
                  fromTimestamp(selectedProd.createdAt)
                )}`
              }
            </Translator>
          ) : (
            <Translator>
              {({
                translate,
              }: {
                translate: ({ text }: { text: string }) => string
              }) =>
                `${translate({ text: 'admissionNr' })}.: ${
                  item.admissionNumber
                }`
              }
            </Translator>
          )
        }
        componentId={componentId}
        isSelected={
          (selectedProd &&
            selectedProd.admissionNumber === `${item.admissionNumber}`) ||
          (selectedProd && selectedProd.productId === `${item.id}`)
        }
        onSelect={onSelect}
        selectType={'checkbox'}
        selectOnly
      />
    )
  }
  return (
    <>
      <Translator>
        {({
          translate,
        }: {
          translate: ({ text }: { text: string }) => string
        }) => (
          <>
            {/*
      //@ts-ignore */}
            <GeneralList
              {...rest}
              searchPlaceholder={translate({ text: 'searchProductName' })}
              renderItem={renderItem}
              renderStickyBelowHeader={renderStickyBelowHeader}
              icon
              appbarContent={
                <PageHeader
                  // back={() => back()}
                  componentId={componentId}
                  //@ts-ignore add accesibility
                  title={
                    <Appbar.Content
                      title={translate({ text: 'distribution' })}
                      titleStyle={{
                        textAlign: 'center',
                      }}
                      subtitleStyle={{
                        textAlign: 'center',
                      }}
                    />
                  }
                />
              }
              extraParams={extraParams}
              fabLabel={translate({ text: 'distributionSave' })}
              onPressFab={selected.length === 0 ? undefined : onPressSave}
            />
          </>
        )}
      </Translator>
      <DeepLink loggedIn={true} componentId={componentId} />
    </>
  )
}

export default connect(
  (state: RootState) => ({
    list: state.distribution.productDistribution.list,
    selected: state.distribution.distribution.select.distributedProducts,
    distributionList: state.distribution.distribution.list.list,
    fabLoading: state.distribution.distribution.select.updating,
    selectedAdmissionHolder: state.filter.admissionHolder.selected,
  }),
  {
    ...distributionActions,
    ...productActions,
  }
)(DistributionListScreen)
