import * as a from './actions'
import { pushCustomEvent } from '../../Analytics/abstraction'

const initialState: { width: number | null; present: boolean | null } = {
  width: null,
  present: null,
}

const reducer = (
  state = initialState,
  {
    type,
    payload,
  }: {
    type: string
    payload: { width?: number | null; present?: boolean | null }
  }
): { width: number | null; present: boolean | null } => {
  switch (type) {
    case a.CHANGE: {
      pushCustomEvent(`Filter changed:`, 'cropFreeBufferZone')
      return { ...state, ...payload }
    }

    case a.REMOVE: {
      pushCustomEvent(`Filter removed:`, 'cropFreeBufferZone')
      return { width: null, present: null }
    }
    default:
      return state
  }
}

export default reducer
