// TODO: remove action creator in favor of type safe actions
export const actionCreator =
  (type: string) => (payload?: any, meta?: any, error?: any) => ({
    type,
    payload,
    meta,
    error: error || (payload && payload instanceof Error),
  })

export const updatableListActions = (namespace: string) => {
  const RELOAD_LIST = `${namespace}_RELOAD_LIST`

  const LOAD_LIST = `${namespace}_LOAD_LIST`
  const LOADED_LIST = `${namespace}_LOADED_LIST`

  const LOAD_MORE_LIST = `${namespace}_LOAD_MORE_LIST`
  const LOADED_MORE_LIST = `${namespace}_LOADED_MORE_LIST`

  const LOAD = `${namespace}_LOAD`
  const LOADED = `${namespace}_LOADED`

  const SELECT = `${namespace}_SELECT`
  const SELECT_KEY_VALUE = `${namespace}_SELECT_KEY_VALUE`
  const TOGGLE_SELECT_ALL = `${namespace}_SELECT_ALL`
  const REMOVE_SELECTED = `${namespace}_REMOVE_SELECTED`
  const REMOVE_SELECTED_KEY_VALUE = `${namespace}_REMOVE_SELECTED_KEY_VALUE`

  const CREATE = `${namespace}_CREATE`
  const CREATED = `${namespace}_CREATED`
  const RESET = `${namespace}_RESET`

  const UPDATE = `${namespace}_UPDATE`
  const UPDATED = `${namespace}_UPDATED`

  const REMOVE = `${namespace}_REMOVE`
  const REMOVED = `${namespace}_REMOVED`

  const SET_DATA = `${namespace}_SET_DATA`
  // const SET_LIST_PARAMS = `${SET_LIST_PARAMS}_SET_DATA`
  const CREATE_ADVICE = `${namespace}_CREATE_ADVICE`
  const CREATED_ADVICE = `${namespace}_CREATED_ADVICE`
  const SEND_ADVICE = `${namespace}_SEND_ADVICE`
  const SENT_ADVICE = `${namespace}_SENT_ADVICE`

  const allActions = {
    SET_DATA,
    setData: actionCreator(SET_DATA),
    CREATE_ADVICE,
    createAdvice: actionCreator(CREATE_ADVICE),
    CREATED_ADVICE,
    createdAdvice: actionCreator(CREATED_ADVICE),
    SEND_ADVICE,
    sendAdvice: actionCreator(SEND_ADVICE),
    SENT_ADVICE,
    sentAdvice: actionCreator(SENT_ADVICE),
    RELOAD_LIST,
    reloadList: (
      payload?: {
        params?: object
      },
      meta?: {
        silentReloading?: boolean
      }
    ) => ({
      type: RELOAD_LIST,
      payload,
      meta,
    }),
    LOAD_LIST,
    loadList: (
      payload?: {
        params?: object
      },
      meta?: {
        silentReloading?: boolean
        addToPath?: string
      }
    ) => ({
      type: LOAD_LIST,
      payload,
      meta,
    }),
    LOADED_LIST,
    loadedList: actionCreator(LOADED_LIST),
    LOAD_MORE_LIST,
    loadMoreList: actionCreator(LOAD_MORE_LIST),
    LOADED_MORE_LIST,
    loadedMoreList: actionCreator(LOADED_MORE_LIST),
    LOAD,
    load: actionCreator(LOAD),
    LOADED,
    loaded: actionCreator(LOADED),
    CREATE,
    create: actionCreator(CREATE),
    CREATED,
    created: actionCreator(CREATED),
    UPDATE,
    update: actionCreator(UPDATE),
    UPDATED,
    updated: actionCreator(UPDATED),
    REMOVE,
    remove: actionCreator(REMOVE),
    REMOVED,
    removed: actionCreator(REMOVED),
    RESET,
    reset: actionCreator(RESET),
    SELECT,
    select: actionCreator(SELECT),
    SELECT_KEY_VALUE,
    selectKeyValue: actionCreator(SELECT_KEY_VALUE),
    TOGGLE_SELECT_ALL,
    toggleSelectAll: actionCreator(TOGGLE_SELECT_ALL),
    REMOVE_SELECTED,
    removeSelected: actionCreator(REMOVE_SELECTED),
    REMOVE_SELECTED_KEY_VALUE,
    removeSelectedKeyValue: actionCreator(REMOVE_SELECTED_KEY_VALUE),
  }

  return allActions
}
