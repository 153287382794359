import React, { useCallback } from 'react'
import PageHeader from '../PageHeader'
import { SimpleBackgroundHeader } from '../GeneralListFunctions/GeneralListHeader'
import { Keyboard, View } from 'react-native'
import Resizer from '../WidthAndHeight/Resizer'
import { Appbar } from 'react-native-paper'
import Navigation from '../Navigation'
import Title from './New/Title'

function AdviceStepper({ componentId }: { componentId: string }) {
  // const [step, setStep] = React.useState(0)

  const pop = useCallback(() => {
    Navigation.pop(componentId)
  }, [componentId])

  const prev = useCallback(() => {
    Keyboard.dismiss()
    pop()
  }, [pop])
  return (
    <>
      <SimpleBackgroundHeader
        key={`SimpleBackgroundHeader}`}
        // absolute
        showSearch={false}
        absolute={false}
        appbarContent={
          <PageHeader
            componentId={componentId}
            title={<Appbar.Content title={'Advies genereren'} />}
            back={prev}
          />
        }
        zIndex
      />
      <View style={{ paddingTop: 16 }}>
        <Resizer style={{ flex: 1 }}>
          <Title>Hello</Title>
        </Resizer>
      </View>
    </>
  )
}

export default AdviceStepper
