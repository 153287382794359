import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { connect } from 'react-redux'
import actions from '../actions'
import FormContainer from '../../Form/FormContainer'
import safeAreaHOC from '../../WidthAndHeight/safeAreaHOC'
import Lines from '../Lines'
import KeyboardAvoidingScrollView from '../../WidthAndHeight/KeyboardAvoidingScrollView'
import Resizer from '../../WidthAndHeight/Resizer'
import AppbarHeader from '../../WidthAndHeight/AppbarHeader'
import AppbarResizer from '../../WidthAndHeight/AppbarResizer'
import { Appbar } from 'react-native-paper'
import DurationPicker from '../../components/DurationPicker'
import { getTimes } from '../helpers'
import RegistrationConceptStatus from '../RegistrationConceptStatus'

// import RegistrationConceptStatus from '../RegistrationConceptStatus'

class InformationScreen extends Component {
  _changeLines = value => {
    this.props.setData({ appliedProducts: value })
  }
  _changeDuration = values => {
    // console.log({ values })
    let newPerformedAt = this.props.object?.performedAt || {}
    Object.keys(values).forEach(key => {
      if (key === 'startTime') {
        newPerformedAt.date = values[key]
      } else {
        newPerformedAt[key] = values[key]
      }
    })
    // console.log(newPerformedAt)
    this.props.setData({ performedAt: newPerformedAt })
  }
  _change = (name, value) => {
    this.props.setData({ [name]: value })
  }
  componentDidMount() {
    console.log('componentDidMount')
    const { duplicateCrud } = this.props

    if (duplicateCrud.duplicated) {
      this.props.duplicateReset()
    }
  }

  render() {
    const { object, title, prev, loaded } = this.props
    const { endTime, date } = getTimes(object || {})
    return (
      <>
        <AppbarHeader
          key="header"
          dark
          style={{ elevation: 0, justifyContent: 'center' }}
        >
          <AppbarResizer>
            <Appbar.BackAction onPress={prev} color="#fff" />
            <Appbar.Content title={title} color="#fff" />
          </AppbarResizer>
        </AppbarHeader>
        <KeyboardAvoidingScrollView>
          <Resizer style={[styles.col, styles.lineContainer]}>
            <FormContainer onSubmit={this.props.next}>
              <View style={{ height: 24 }} />
              <View style={[styles.row, styles.secondRow, { zIndex: 1000 }]}>
                {loaded ? (
                  <DurationPicker
                    onChange={this._changeDuration}
                    startTime={date}
                    endTime={endTime}
                    date={date}
                    changeOnMount
                  />
                ) : null}
              </View>
              {/*<View style={[styles.row, styles.secondRow]}>*/}
              {/*  <Fields*/}
              {/*    fields={[*/}
              {/*      {*/}
              {/*        name: 'selectedLocations',*/}
              {/*        label: <Translate text="parcels" />,*/}
              {/*        type: 'text',*/}
              {/*        fieldProps: {*/}
              {/*          style: {*/}
              {/*            flex: 1,*/}
              {/*            marginTop: 12,*/}
              {/*          },*/}
              {/*        },*/}
              {/*      },*/}
              {/*    ]}*/}
              {/*    values={{*/}
              {/*      selectedLocations: object?.selectedLocations*/}
              {/*        ?.map(location => location?.name)*/}
              {/*        .join(', '),*/}
              {/*    }}*/}
              {/*    editable={false}*/}
              {/*    onChange={this._change}*/}
              {/*    onSubmit={() => null}*/}
              {/*  />*/}
              {/*</View>*/}
              <Lines
                onChange={this._changeLines}
                value={(object && object.appliedProducts) || []}
              />

              <RegistrationConceptStatus
                value={object && object.stage}
                onChange={val => this._change('stage', val)}
              />
              <View style={styles.paddingScroll} />
            </FormContainer>
          </Resizer>
        </KeyboardAvoidingScrollView>
      </>
    )
  }
}

InformationScreen.propTypes = {}
InformationScreen.defaultProps = {}

const styles = StyleSheet.create({
  lineContainer: {
    position: 'relative',
  },
  resizer: {
    maxWidth: 600,
    width: '100%',
    alignSelf: 'center',
    // padding: 16,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 24,
  },
  secondRow: {
    paddingLeft: 6,
  },
  paddingScroll: {
    paddingBottom: 100,
  },
})

const mapStateToProps = state => ({
  loaded: state.registration.crud.loaded,
  object: state.registration.crud.object,
  duplicateCrud: state.duplicateReducer,
})

export default safeAreaHOC(connect(mapStateToProps, actions)(InformationScreen))
