import React, { Component } from 'react'
import { connect } from 'react-redux'
import actions from './actions'
import GeneralList from '../../GeneralListFunctions/GeneralList'
import ItemInList from '../ItemInList'
import FilterHeader from '../FilterHeader'
import Navigation from '../../Navigation'

const extraParams = {
  number: 100000,
  limit: 100000,
}

class AdmissionCategoryList extends Component {
  _pop = () => {
    Navigation.pop(this.props.componentId)
  }
  _renderItem = ({ item }) => {
    return (
      <ItemInList
        onPress={this.props.select}
        onSelect={this.props.select}
        item={item}
        filterType={this.props.filterType}
        isSelected={this.props.selected.some((it) => it.id === item.id)}
        selectType="checkbox"
      />
    )
  }
  render() {
    const { componentId, filterType } = this.props

    return (
      <GeneralList
        {...this.props}
        searchPlaceholder={filterType.searchPlaceholder}
        renderItem={this._renderItem}
        icon
        appbarContent={
          <FilterHeader filterType={filterType} componentId={componentId} />
        }
        extraParams={extraParams}
        onPressFab={this._pop}
      />
    )
  }
}

AdmissionCategoryList.propTypes = {}
AdmissionCategoryList.defaultProps = {}

const mapStateToProps = (state) => ({
  selected: state.filter.admissionCategory.selected,
  list: state.filter.admissionCategory.list,
})

export default connect(mapStateToProps, actions)(AdmissionCategoryList)
