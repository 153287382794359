export default function SentryInit(Sentry) {
  Sentry.init({
    dsn: 'https://9c704f93a7a44d60823336e1145efb57@o942287.ingest.sentry.io/5891071',
    environment: process.env.REACT_APP_ENVIRONMENT,
    tracesSampleRate: 1,
    enabled: process.env.NODE_ENV !== 'development',
    // debug: true,
    // autoInitializeNativeSdk: false,
  })
}
