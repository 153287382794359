import { combineReducers } from 'redux'

import * as a from './actions'

const getProduct = (p) => {
  if (p) {
    if (p.id) {
      return p
    }
    if (p.product && p.product.id) {
      return p.product
    }
    if (p.product && p.product.product) {
      return p.product.product
    }
  }
}
const getResult = (data) => (data || []).map((p) => getProduct(p).id)
const getEntities = (data) =>
  (data || []).reduce((object, value, i) => {
    const product = getProduct(value)
    object[product.id] = product
    return object
  }, {})

const initialProductsState = {
  result: [],
  entities: {},
  loading: false,
  loaded: false,
  error: false,
  pinned: undefined,
}
const productsReducer = (
  state = initialProductsState,
  { type, payload, meta, error }
) => {
  switch (type) {
    case a.LOAD:
      return {
        ...initialProductsState,
        result: payload,
        loading: true,
      }
    case a.LOADED: {
      if (error) {
        return {
          ...state,
          loading: false,
          error: true,
        }
      }

      const newState = {
        ...state,
        result: getResult(payload),
        entities: getEntities(payload),
        loading: meta && meta.overrideLoading ? true : false,
        error: false,
        pinned: undefined,
      }
      return newState
    }
    case a.PIN:
      return {
        ...state,
        pinned: payload,
      }
    case a.UNPIN:
      return {
        ...state,
        pinned: undefined,
      }
    default:
      return state
  }
}

const initialDosagesState = {
  data: [],
  loading: false,
  error: false,
}
const dosagesReducer = (
  state = initialDosagesState,
  { type, payload, error }
) => {
  switch (type) {
    case a.LOAD_DOSAGES:
      return {
        ...initialDosagesState,
        loading: true,
      }
    case a.LOADED_DOSAGES: {
      if (error) {
        return {
          ...state,
          loading: false,
          error: true,
        }
      }
      return {
        data: payload.data || [],
        loading: false,
        error: false,
      }
    }
    default:
      return state
  }
}

const reducer = combineReducers({
  products: productsReducer,
  dosages: dosagesReducer,
})

export default reducer
