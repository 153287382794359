import React from 'react'
import { Text } from 'react-native-paper'

function FieldRouteScreen({ fieldCoordinates, entrance }) {
  console.log({ fieldCoordinates, entrance })

  // TODO: decode base64 prop waarden en log ze

  return (
    <>
      <Text>
        {fieldCoordinates} {entrance}
      </Text>
    </>
  )
}

export default FieldRouteScreen
