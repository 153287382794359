import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { switchMenu } from './Navigation'
import * as routes from './Routes.config'
import actions from './FilterType/Location/actions'
import { useActionsAsFunctions } from './helpers/actions'
import { Platform } from 'react-native'

function SubscriptionWrapper() {
  // const [done,setDone] = useState(false)
  const authUser = useSelector((state: any) => state.auth && state.auth.user)
  const location = useSelector((state: any) => state.filter.location.selected)
  useEffect(() => {
    const user = (authUser && authUser.user) || {}
    if (
      user &&
      user.roles &&
      user.roles.length > 0 &&
      !user.roles.find((role: { name: string }) => role.name === 'user')
    ) {
      // this account does not have the role "user" which means that it's an Driftzoeker account, and needs to
      // contact Agro4all for getting the role "user", to access Agro4all's products.
      switchMenu(routes.WRONG_SUBSCRIPTION_ROUTE)
    }
  }, [authUser])

  useEffect(() => {
    const user = (authUser && authUser.user) || {}
    if (
      Platform.OS === 'web' &&
      ((user &&
        user.attributes &&
        user.attributes.length > 0 &&
        user.attributes.find(
          (att: { key: string; value: string }) =>
            att.key === 'onboardingDone' && att.value !== 'true'
        )) ||
        (user &&
          Object.keys(user).length > 0 &&
          !user.attributes &&
          user &&
          user.roles &&
          user.roles.length > 0 &&
          user.roles.find(
            (role: { name: string }) => role.name === 'horticulturist'
          )))
    ) {
      switchMenu(routes.ONBOARDING_ROUTE)
    }
  }, [authUser])

  const actionAsDispatchers = useActionsAsFunctions(actions)

  useEffect(() => {
    const user = (authUser && authUser.user) || {}
    if (
      user &&
      user.roles &&
      user.roles.length > 0 &&
      user.roles.find(
        (role: { name: string }) => role.name === 'horticulturist'
      ) &&
      location.length === 0
    ) {
      actionAsDispatchers.select({
        id: '08727e19-4fb6-4896-582b-265113255de0',
        name: 'Bedekte teelt',
      })
    }
  }, [authUser, location, actionAsDispatchers])

  return null
}
export default SubscriptionWrapper
