import React, { useState, useCallback, useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { Text, TouchableRipple } from 'react-native-paper'
import Checkbox from '../components/Checkbox'

const styles = StyleSheet.create({
  inner: { flexDirection: 'row', alignItems: 'center', padding: 16 },
  label: { paddingLeft: 16 / 2, paddingRight: 16 / 2 },
})

export default function CheckBoxWithLabel({
  value,
  onChangeValue,
  labelComponent,
  label,
  disabled,
  testID,
  innerStyle,
}) {
  const [checked, setChecked] = useState(value)

  const toggle = useCallback(() => {
    setChecked(!checked)
    onChangeValue(!checked)
  }, [onChangeValue, setChecked, checked])

  useEffect(() => {
    setChecked(value)
  }, [value])

  return (
    <TouchableRipple
      onPress={toggle}
      style={styles.container}
      testID={testID}
      disabled={disabled}
      accessibilityTraits={'button'}
      accessibilityComponentType={'button'}
      accessibilityRole={'button'}
      accessibilityLabel={label}
    >
      <View style={[styles.inner, innerStyle]}>
        <Checkbox
          disabled={disabled}
          status={checked ? 'checked' : 'unchecked'}
        />
        {labelComponent || <Text style={styles.label}>{label}</Text>}
      </View>
    </TouchableRipple>
  )
}
