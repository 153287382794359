import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { Appbar } from 'react-native-paper'

import actions from './actions'
import PageHeader from '../../PageHeader'

import GeneralList from '../../GeneralListFunctions/GeneralList'
import Navigation from '../../Navigation'
import ItemInList from '../ItemInList'
import { hasRole } from '../../helpers/role'
import { Translate, Translator } from 'react-translated'

export const organizationType = [
  { id: 1, key: 'farmer', name: <Translate text="farmerCompany" /> },
  { id: 2, key: 'contractor', name: <Translate text="contractorCompany" /> },
  { id: 3, key: 'transporter', name: <Translate text="transporterCompany" /> },
  {
    id: 4,
    key: 'horticulturist',
    name: <Translate text="horticulturistCompany" />,
  },
]
export const organizationTypeSingle = [
  { id: 1, key: 'farmer', name: <Translate text="farmerCompany" /> },
]

function FarmersListForAdvisor(props) {
  const { componentId, title, select, selected, filterType, user } = props

  const onBack = useCallback(() => Navigation.pop(componentId), [componentId])

  const onPressItem = useCallback(
    (item) => {
      select(item)
    },
    [select]
  )

  const isContractor = hasRole(user, 'contractor')
  const isTransporter = hasRole(user, 'transporter')
  const pop = useCallback(() => {
    Navigation.pop(componentId)
  }, [componentId])

  const renderItem = useCallback(
    ({ item }) => (
      <ItemInList
        onPress={() => onPressItem(item)}
        onSelect={() => onPressItem(item)}
        item={item}
        filterType={filterType}
        isSelected={selected.some((it) => it.id === item.id)}
        selectType="checkbox"
      />
    ),
    [onPressItem, filterType, selected]
  )

  return (
    <Translator>
      {({ translate }) => (
        <GeneralList
          {...props}
          list={{
            data:
              isContractor || isTransporter
                ? organizationTypeSingle
                : organizationType,
          }}
          silentReloadingOnMount={false}
          searchPlaceholder={translate({ text: 'searchType' })}
          renderItem={renderItem}
          icon
          appbarContent={
            <PageHeader
              componentId={componentId}
              back={onBack}
              title={
                <Appbar.Content
                  title={title || translate({ text: 'companyTypes' })}
                />
              }
              companyTypes
            />
          }
          onPressFab={pop}
        />
      )}
    </Translator>
  )
}

const mapStateToProps = (state) => ({
  selected: state.filter.organizationType.selected,
  user: state.auth.user.user,
})

export default connect(mapStateToProps, actions)(FarmersListForAdvisor)
