import React from 'react'
import { StyleSheet } from 'react-native'
import { FAB } from 'react-native-paper'
import safeAreaHOC from './safeAreaHOC'
import keyboardHOC from './keyboardHOC'

const styles = StyleSheet.create({
  root: { position: 'absolute', bottom: 16, right: 16 },
  rootLeft: { position: 'absolute', bottom: 16, left: 16 },
})

function BottomFab({ style, safe, keyboardHeight, align = 'right', ...rest }) {
  const safeStyle = {
    [align]: safe[align] + 16,
    bottom: (keyboardHeight || 0) + safe.bottom + 16,
    zIndex: 2,
  }
  return (
    <FAB
      style={[
        align === 'left' ? styles.rootLeft : styles.root,
        safeStyle,
        style,
      ]}
      {...rest}
    />
  )
}

export default keyboardHOC(safeAreaHOC(BottomFab))
