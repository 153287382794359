import React, { useEffect } from 'react'
import {
  ActivityIndicator,
  Button,
  HelperText,
  Snackbar,
  TextInput,
} from 'react-native-paper'
import { Text, View } from 'react-native'
import AppbarHeader from '../../WidthAndHeight/AppbarHeader'
import Resizer from '../../WidthAndHeight/Resizer'
import Agro4allLogo from '../../vectors/Agro4allLogo'
import { useDispatch, useSelector } from 'react-redux'
import updateActions from './actions'
import b64 from 'base-64'
import KeyboardHOC from '../../WidthAndHeight/keyboardHOC'
import KeyboardAvoidingScrollView from '../../WidthAndHeight/KeyboardAvoidingScrollView'
import { Translate, Translator } from 'react-translated'

function base64_decode(s) {
  return decodeURIComponent(escape(b64.decode(s)))
}

function ExtraInfoAdmin({ secret, keyboardHeight, componentId }) {
  const dispatch = useDispatch()
  const [jsonData, setJsonData] = React.useState({})
  const [errors, setErrors] = React.useState([])

  const phoneRegex = /^\d{10}$/g

  const [minLength, setMinLength] = React.useState(0)

  const informationData = useSelector(state => state?.extraInfoAdmin)

  const completed = useSelector(
    state =>
      state.extraInfoAdmin &&
      state.extraInfoAdmin.crud &&
      state.extraInfoAdmin.crud.updated
  )
  const error = useSelector(
    state =>
      state.extraInfoAdmin &&
      state.extraInfoAdmin.crud &&
      state.extraInfoAdmin.crud.updatingError
  )
  React.useEffect(() => {
    console.log('secret', secret)
    console.log({ secret })
    const base64String = base64_decode(secret)
    const jsonObject = JSON.parse(base64String)

    setJsonData(jsonObject)
  }, [secret])
  const [phoneNumbers, setPhoneNumbers] = React.useState([''])
  const [reason, setReason] = React.useState('')
  // const [inputText, setInputText] = useState('')
  const [showError, setShowError] = React.useState(false)

  useEffect(() => {
    if (error) {
      setShowError(true)
    }
  }, [error])
  const dismiss = () => {
    if (completed) {
      // push(componentId, {
      //   component: {
      //     name: routes.HOME_ROUTE,
      //   },
      // })
    } else {
      setShowError(false)
    }
  }
  const saveData = () => {
    let newErrors = []

    if (jsonData?.user?.firstName?.length < 2) {
      newErrors.push('firstName')
    }
    if (jsonData?.user?.lastName?.length < 1) {
      newErrors.push('lastName')
    }
    if (
      !phoneNumbers?.some(phoneNumber => {
        return phoneRegex.test(phoneNumber) && phoneNumber.length === 10
      }) &&
      phoneNumbers?.length > 0
    ) {
      newErrors.push('phoneNumbers')
    }
    if (reason?.length < 40) {
      newErrors.push('reason')
    }

    if (newErrors.length > 0) {
      setErrors(newErrors)
    } else {
      dispatch(
        updateActions.update({
          extraBody: {
            ...jsonData.user,
            attributes: [
              {
                type: 'string',
                key: 'register_reason',
                value: reason,
              },
            ],
            phoneNumbers: phoneNumbers.map(phoneNumber => {
              return {
                phone_number: phoneNumber,
                type: 'MOBILE',
              }
            }),
          },
          overwriteId: jsonData.secret,
          withoutAuthentication: true,
        })
      )
    }
  }

  const handlePhoneNumberChange = (index, value) => {
    setErrors(errors.filter(error => error !== 'phoneNumbers'))
    const newPhoneNumbers = [...phoneNumbers]
    newPhoneNumbers[index] = value
    setPhoneNumbers(newPhoneNumbers)
  }

  return [
    <AppbarHeader
      style={{
        elevation: 0,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Agro4allLogo withoutOnPressLogo={true} />
    </AppbarHeader>,
    <Translator>
      {({ translate }) => (
        <KeyboardAvoidingScrollView style={{ paddingBottom: 56 }}>
          <Resizer style={{ marginTop: 10 }}>
            <TextInput
              style={{
                backgroundColor: '#fff',
                fontSize: 16,
                marginLeft: 8,
                marginRight: 8,
              }}
              label={translate({ text: 'firstName' })}
              value={jsonData?.user?.firstName}
              onChangeText={text => {
                setErrors(errors.filter(error => error !== 'firstName'))
                setJsonData({
                  ...jsonData,
                  user: { ...jsonData.user, firstName: text },
                })
              }}
            />
            {errors.includes('firstName') && (
              <HelperText type="error" visible={errors.includes('firstName')}>
                {translate({ text: 'enterFirstName' })}
              </HelperText>
            )}
            <TextInput
              style={{
                backgroundColor: '#fff',
                fontSize: 16,
                marginLeft: 8,
                marginRight: 8,
              }}
              autoCapitalize="none"
              label={translate({ text: 'preposition' })}
              value={jsonData.user?.preposition}
              onChangeText={text =>
                setJsonData({
                  ...jsonData,
                  user: { ...jsonData.user, preposition: text },
                })
              }
            />
            <TextInput
              style={{
                backgroundColor: '#fff',
                fontSize: 16,
                marginLeft: 8,
                marginRight: 8,
              }}
              label={translate({ text: 'lastName' })}
              value={jsonData.user?.lastName}
              onChangeText={text => {
                setErrors(errors.filter(error => error !== 'lastName'))
                setJsonData({
                  ...jsonData,
                  user: { ...jsonData.user, lastName: text },
                })
              }}
            />
            {errors.includes('lastName') && (
              <HelperText type="error" visible={errors.includes('lastName')}>
                {translate({ text: 'enterLastName' })}
              </HelperText>
            )}
            <TextInput
              style={{
                backgroundColor: '#fff',
                fontSize: 16,
                marginLeft: 8,
                marginRight: 8,
              }}
              disabled={true}
              label={translate({ text: 'email' })}
              value={jsonData.user?.email}
              onChangeText={text =>
                setJsonData({ ...jsonData, user: { ...jsonData, email: text } })
              }
            />
            <TextInput
              style={{
                backgroundColor: '#fff',
                fontSize: 16,
                marginLeft: 8,
                marginRight: 8,
              }}
              disabled={true}
              label={translate({ text: 'companyName' })}
              value={jsonData.user?.organization?.name}
              onChangeText={text =>
                setJsonData({
                  ...jsonData,
                  user: {
                    ...jsonData.user,
                    organization: {
                      ...jsonData.user.organization,
                      name: text,
                    },
                  },
                })
              }
            />
            <View>
              {phoneNumbers.map((phoneNumber, index) => (
                <>
                  <TextInput
                    style={{
                      backgroundColor: '#fff',
                      fontSize: 16,
                      marginLeft: 8,
                      marginRight: 8,
                    }}
                    key={index}
                    label={translate({ text: 'phoneNumber' })}
                    value={phoneNumber}
                    onChangeText={text =>
                      handlePhoneNumberChange(
                        index,
                        text.replace(/[^0-9]/g, '')
                      )
                    }
                    maxLength={10}
                    keyboardType={'numeric'}
                  />
                </>
              ))}
              {errors.includes('phoneNumbers') && (
                <HelperText
                  type="error"
                  visible={errors.includes('phoneNumbers')}
                >
                  {translate({ text: 'enterPhoneNumber' })}
                </HelperText>
              )}
            </View>
            {/*<Button*/}
            {/*  style={{*/}
            {/*    fontSize: 16,*/}
            {/*    margin: 4,*/}
            {/*  }}*/}
            {/*  mode={'outlined'}*/}
            {/*  onPress={addPhoneNumber}*/}
            {/*>*/}
            {/*  Telefoonnummer toevoegen*/}
            {/*</Button>*/}
            <View style={{ position: 'relative' }}>
              <TextInput
                style={{
                  backgroundColor: '#fff',
                  fontSize: 16,
                  marginLeft: 8,
                  marginRight: 8,
                  textAlign: 'auto',
                }}
                label={translate({ text: 'reasonSentence' })}
                value={reason}
                onChangeText={text => {
                  setMinLength(text.length)
                  setErrors(errors.filter(error => error !== 'reason'))
                  setReason(text)
                }}
                multiline
                numberOfLines={6}
              />
              {errors.includes('reason') && (
                <HelperText type="error" visible={errors.includes('reason')}>
                  {translate({ text: 'minimumCharacters' })}
                </HelperText>
              )}
              <Text
                style={{
                  fontSize: 16,
                  color: minLength > 39 ? '#179a42' : '#dfdfdf',
                  position: 'absolute',
                  bottom: 32,
                  right: 12,
                }}
              >
                {minLength}/40
              </Text>
            </View>
            <Button
              style={{
                marginTop: 32,
                fontSize: 16,
                margin: 4,
                color: minLength < 39 ? 'orange' : 'blue',
              }}
              mode={'contained'}
              onPress={saveData}
            >
              {informationData?.crud?.updating ? (
                <ActivityIndicator size={20} color={'#fff'} />
              ) : (
                <Text style={{ color: '#fff' }}>Opslaan</Text>
              )}
            </Button>
          </Resizer>
        </KeyboardAvoidingScrollView>
      )}
    </Translator>,
    <Resizer>
      <Snackbar
        key="snackbar"
        visible={!!showError}
        onDismiss={dismiss}
        style={[
          {
            marginBottom: keyboardHeight + 32,
          },
        ]}
      >
        <Translate text="extraInfoError" />
      </Snackbar>
    </Resizer>,
    <Resizer>
      <Snackbar
        key="snackbar2"
        visible={!!completed}
        onDismiss={dismiss}
        style={[
          {
            marginBottom: keyboardHeight + 32,
          },
        ]}
      >
        <Translate text="extraInfoSuccess" />
      </Snackbar>
    </Resizer>,
  ]
}

export default KeyboardHOC(ExtraInfoAdmin)
