import React, { useCallback } from 'react'
import { Keyboard, StyleSheet, TouchableOpacity } from 'react-native'
import { setMessages } from '../Notifications/actions'
import { useDispatch } from 'react-redux'
import { pushCustomEvent } from '../Analytics/abstraction'
import { getItemLabel } from './helper'
import { secondsToDDMMYYYY } from '../helpers/date'
import { getLanguage } from '../language'
import UsedUpPeriodIcon from '../vectors/UsedUpPeriod'
import AuthorizationExpiredIcon from '../vectors/AuthorizationExpired'

const messageMap = {
  2: {
    en: 'This admission is not valid anymore. The admission holder can sell the product until ',
    nl: `De toelating is niet meer geldig. De toelatingshouder mag het middel verkopen t/m `,
  },
  3: {
    en: 'This product with this subversion is expired and it is not allow to apply this product anymore!',
    nl: `Dit middel met dit W-nummer is vervallen en mag niet meer worden toegepast!`,
  },
}

const iconMap = {
  2: UsedUpPeriodIcon,
  3: AuthorizationExpiredIcon,
}

export default ({ status, product }) => {
  const dispatch = useDispatch()
  const Icon = iconMap[status]
  const language = getLanguage()
  const message =
    status === 2
      ? messageMap[status][language] +
        secondsToDDMMYYYY(product.lastDeliveryDate) +
        (language === 'nl'
          ? '. Voorraden mogen worden gebruikt t/m '
          : '. It is allowed to apply the product that is left in stock until ') +
        secondsToDDMMYYYY(product.lastUseDate) +
        '.'
      : messageMap[status] && messageMap[status][language]

  const onPress = useCallback(() => {
    Keyboard.dismiss()
    dispatch(setMessages([message]))
    pushCustomEvent(
      'Pressed product status icon on product:',
      `${getItemLabel(product)}`
    )
  }, [dispatch, product, message])

  if (!Icon || !message) {
    return null
  }
  return (
    <TouchableOpacity onPress={onPress} style={styles.icon} >
      <Icon color={'blue'} />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  icon: {
    width: 24,
    height: 24,
    margin: 12,
  },
})
