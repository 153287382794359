import React, { useCallback, useEffect, useMemo, useState } from 'react'
import config from './config'
import ReactMapboxGl from 'react-mapbox-gl'
import MapboxLogo from './MapboxLogo'
import MapboxAttribution from './MapboxAttribution'
import MapHOC from './MapHOC'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import * as turf from '@turf/turf'
import { addCustomField } from '../FieldMap/actions'
import actions from '../OrganizationField/actions'
import { connect, useDispatch } from 'react-redux'
import 'react-native-get-random-values'
import DrawControl from 'react-mapbox-gl-draw'

const LibraryMapView = ReactMapboxGl({
  accessToken: config.accessToken,
})

const { styleURL } = config

const containerStyle = {
  // width: '100%',
  // height: '100%',
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
}
// const zoom = [config.initialZoomLevel]
const pitch = [30]
const animationOptions = {
  duration: 200,
  maxDuration: 200,
  zoom: config.initialZoomLevel,
}
const flyToOptions = {
  duration: 200,
  maxDuration: 200,
  zoom: config.initialZoomLevel,
}

function MapView({
  bounds,
  onRef,
  render,
  onPressLatLong,
  centerCoordinates,
  onMapLoaded,
  onMapLoading,
  reducerZoom,
  hasReducerZoom,
  onRegionChanged,
  showDrawMenu,
  showSnapMenu,
  save,
  fieldArray,
  selectedFarmer,
  presetCenter,
  setSnap,
  selectedField,
  selectedFields,
  dontUpdate,
  centerFromHOC,
  withoutInformation,
}) {
  const [center, setCenter] = useState([5, 52])
  const [zoomState, setZoomState] = useState(hasReducerZoom ? [14] : [8])
  const [drawing, setDrawing] = useState()
  const onDrawRef = React.createRef()
  const dispatch = useDispatch()
  const onInnerRegionChange = map => {
    const bounds = map.getBounds()
    onRegionChanged &&
      onRegionChanged(
        {
          nwLat: bounds.getNorth(),
          nwLng: bounds.getWest(),
          neLat: bounds.getNorth(),
          neLng: bounds.getEast(),
          swLat: bounds.getSouth(),
          swLng: bounds.getWest(),
          seLat: bounds.getSouth(),
          seLng: bounds.getEast(),
        },
        map.getZoom()
      )
  }

  const onClick = useCallback(
    (map, event) => {
      const { lat, lng } = event.lngLat
      if (lat && lng) {
        onPressLatLong({
          map,
          longitude: lng,
          latitude: lat,
        })
      }
    },
    [onPressLatLong]
  )

  const [loaded, setLoaded] = useState(false)
  const onSourceData = useCallback(
    (map, event) => {
      const mapIsLoaded =
        map.loaded() &&
        map.isStyleLoaded() &&
        map.areTilesLoaded() &&
        !map.isMoving() &&
        !map.isZooming()
      if (!loaded && mapIsLoaded) {
        setLoaded(true)
      }
      if (loaded && !mapIsLoaded) {
        setLoaded(false)
      }
    },
    [loaded, setLoaded]
  )
  useEffect(() => {
    if (loaded) {
      onMapLoaded && onMapLoaded()
    } else {
      onMapLoaded && onMapLoading()
    }
  }, [loaded, onMapLoaded, onMapLoading, centerCoordinates, center, setCenter])

  useEffect(() => {
    console.log('Got new center bois, setting new center')
    setCenter(centerCoordinates)
  }, [centerCoordinates, setCenter])

  useEffect(() => {
    console.log({ dontUpdate, centerFromHOC })
    if (!dontUpdate) {
      setCenter(centerFromHOC)
    }
  }, [centerFromHOC, setCenter, dontUpdate])

  const [hasSetReducerZoom, setHasSetReducerZoom] = useState(false)
  useEffect(() => {
    if (hasReducerZoom && !hasSetReducerZoom) {
      setHasSetReducerZoom(true)
      setZoomState(reducerZoom)
    }
  }, [setZoomState, reducerZoom, hasReducerZoom, hasSetReducerZoom])

  // console.log('map.web.js', boundingBox)

  const fitBounds = useMemo(() => {
    if (bounds) {
      // TODO: fix this for less than 2 fields
      return [bounds.ne, bounds.sw]
    }
    return null
  }, [bounds])
  const onDrawCreate = ({ features }) => {
    setDrawing(features)
  }

  const onDrawUpdate = ({ features }) => {
    setDrawing(features)
  }

  const onSnapCreate = ({ features }) => {
    let turfPolygon = [],
      allCoordinates = []

    if (selectedFields && selectedFields.length > 0) {
      selectedFields.forEach(sf => {
        if (sf.fields) {
          sf.fields.forEach(f => {
            allCoordinates = [...allCoordinates, ...f.coordinates]
          })
        } else if (sf.field) {
          allCoordinates = [...allCoordinates, ...sf.field.coordinates]
        } else {
          allCoordinates = [...allCoordinates, ...sf.coordinates]
        }
      })
    } else {
      allCoordinates = selectedField.coordinates
    }
    allCoordinates.forEach(coord => {
      turfPolygon = [...turfPolygon, [coord.latitude, coord.longitude]]
    })
    const turfCollection = turf.lineString(turfPolygon)
    const turfPoint = turf.point([
      features[0].geometry.coordinates[1],
      features[0].geometry.coordinates[0],
    ])
    const nearest = turf.nearestPointOnLine(turfCollection, turfPoint, {
      units: 'meters',
    })
    setSnap(nearest)
  }

  const onSnapUpdate = ({ features }) => {
    let turfPolygon = []
    selectedField.coordinates.forEach(coord => {
      turfPolygon = [...turfPolygon, [coord.latitude, coord.longitude]]
    })
    const turfCollection = turf.lineString(turfPolygon)
    const turfPoint = turf.point([
      features[0].geometry.coordinates[1],
      features[0].geometry.coordinates[0],
    ])
    const nearest = turf.nearestPointOnLine(turfCollection, turfPoint, {
      units: 'meters',
    })
    setSnap(nearest)
  }

  const convertCoords = coordArr => {
    let newCoords = []
    coordArr.forEach(posArr => {
      newCoords = [...newCoords, { latitude: posArr[1], longitude: posArr[0] }]
    })
    return newCoords
  }

  // This is when we have drawn a Polygon, and we want to save it because we are done editing
  useEffect(() => {
    if (save && drawing) {
      const turfPolygon = turf.polygon(drawing[0].geometry.coordinates)
      const area = turf.area(turfPolygon)

      // First, we will add the freshly drawn field to the fields that have returned from the database.
      dispatch(
        addCustomField([
          // ...fieldArray,
          {
            rawCoordinates: drawing[0].geometry.coordinates[0],
            coordinates: convertCoords(drawing[0].geometry.coordinates[0]),
            source: 'manually',
            area: area,
          },
        ])
      )

      // Next, we will add the freshly drawn field to the selected fields array.
      if (!selectedFarmer) {
        dispatch(
          actions.setData({
            coordinates: convertCoords(drawing[0].geometry.coordinates[0]),
            source: 'manually',
            area: area,
            field: {
              coordinates: convertCoords(drawing[0].geometry.coordinates[0]),
              source: 'manually',
              area: area,
            },
            freshlyDrawnField: {
              coordinates: convertCoords(drawing[0].geometry.coordinates[0]),
              source: 'manually',
              area: area,
            },
          })
        )
      } else {
        dispatch(
          actions.setData({
            coordinates: convertCoords(drawing[0].geometry.coordinates[0]),
            source: 'manually',
            area: area,
            field: {
              coordinates: convertCoords(drawing[0].geometry.coordinates[0]),
              source: 'manually',
              area: area,
            },
            freshlyDrawnField: {
              coordinates: convertCoords(drawing[0].geometry.coordinates[0]),
              source: 'manually',
              area: area,
            },
          })
        )
      }
      setDrawing(undefined)
    }
  }, [showDrawMenu, save, drawing, dispatch, selectedFarmer, fieldArray])

  console.log({ zoomState, reducerZoom, hasReducerZoom })
  return (
    <>
      <MapboxLogo />
      <MapboxAttribution />
      <LibraryMapView
        fitBounds={fitBounds}
        onStyleLoad={onRef}
        style={styleURL}
        containerStyle={containerStyle}
        attributionControl={false}
        onClick={showDrawMenu || showSnapMenu ? null : onClick}
        animationOptions={animationOptions}
        className="swiper-no-swiping"
        flyToOptions={flyToOptions}
        center={presetCenter ? presetCenter : center}
        zoom={zoomState}
        onRender={onSourceData}
        withoutInformation={withoutInformation}
        pitch={pitch}
        beargin={-60}
        onMoveEnd={onInnerRegionChange}
        onDragEnd={onInnerRegionChange}
        onZoomEnd={onInnerRegionChange}
        onPitchEnd={onInnerRegionChange}
        onTouchEnd={onInnerRegionChange}
        onRotateEnd={onInnerRegionChange}
      >
        {showDrawMenu ? (
          <DrawControl
            onDrawCreate={onDrawCreate}
            onDrawUpdate={onDrawUpdate}
            ref={onDrawRef}
            defaultMode={'draw_polygon'}
            displayControlsDefault={false}
          />
        ) : null}
        {showSnapMenu ? (
          <DrawControl
            onDrawCreate={onSnapCreate}
            onDrawUpdate={onSnapUpdate}
            ref={onDrawRef}
            defaultMode={'draw_point'}
            displayControlsDefault={false}
          />
        ) : null}
        {render(true)}
      </LibraryMapView>
    </>
  )
}

const mapStateToProps = state => ({
  fieldArray:
    (state.organizationField.crud.object &&
      state.organizationField.crud.object.fieldArray) ||
    [],

  selectedFarmer: state.filter.advisorFarmer.selected,
})

export default MapHOC(
  connect(mapStateToProps, { addCustomField, ...actions })(MapView)
)
