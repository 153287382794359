import React from 'react'
import Svg from './SvgAbstraction'
import Path from './PathAbstraction'

import { color, defaultProps, propTypes } from './standardVector'

export const width = 24
export const height = 24

const Vector = props => (
  <Svg
    width={props.width || width}
    height={props.height || height}
    className={props.className}
    viewBox="0 0 24 24"
  >
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M23.25 9.00001V4.65001C23.2501 4.35016 23.1602 4.05718 22.9922 3.80888C22.8241 3.56058 22.5854 3.36835 22.307 3.25701L16.307 0.857006C15.9494 0.714006 15.5506 0.714006 15.193 0.857006L8.80701 3.41201C8.44944 3.55501 8.05058 3.55501 7.69301 3.41201L1.77901 1.04601C1.6649 1.00027 1.5413 0.983292 1.41909 0.996554C1.29687 1.00982 1.17979 1.05292 1.07815 1.12206C0.97651 1.1912 0.893422 1.28427 0.836202 1.39308C0.778981 1.50188 0.749382 1.62308 0.75001 1.74601V16.119C0.74996 16.4189 0.839775 16.7118 1.00786 16.9601C1.17595 17.2084 1.4146 17.4007 1.69301 17.512L7.69301 19.912C8.05058 20.055 8.44944 20.055 8.80701 19.912L11.688 18.759"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M8.25 3.519V20.019"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M15.75 0.75V8.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M18.75 11.324C19.9435 11.324 21.0881 11.7981 21.932 12.642C22.7759 13.4859 23.25 14.6305 23.25 15.824C23.25 17.745 20.562 21.4 19.341 22.962C19.2709 23.0518 19.1812 23.1244 19.0788 23.1743C18.9764 23.2243 18.8639 23.2502 18.75 23.2502C18.6361 23.2502 18.5236 23.2243 18.4212 23.1743C18.3188 23.1244 18.2291 23.0518 18.159 22.962C16.938 21.401 14.25 17.745 14.25 15.824C14.25 14.6305 14.7241 13.4859 15.568 12.642C16.4119 11.7981 17.5565 11.324 18.75 11.324Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M18.75 16.2C18.5429 16.2 18.375 16.0321 18.375 15.825C18.375 15.6179 18.5429 15.45 18.75 15.45"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M18.75 16.2C18.9571 16.2 19.125 16.0321 19.125 15.825C19.125 15.6179 18.9571 15.45 18.75 15.45"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </Svg>
)

Vector.propTypes = propTypes
Vector.defaultProps = defaultProps

export default Vector
