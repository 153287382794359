import React, { useState } from 'react'
import { Menu } from 'react-native-paper'
import ListItem from '../WidthAndHeight/ListItemWithBottom'
import { Translator } from 'react-translated'
import { View } from 'react-native'

function LanguagePicker({ value, onChange }) {
  const [visible, setVisible] = useState(false)

  console.log({ value })
  return (
    <View style={{ marginBottom: 16 }}>
      <Translator>
        {({ translate }) => {
          const languageMap = [
            { name: translate({ text: 'dutch' }), id: 'nl' },
            { name: translate({ text: 'english' }), id: 'en' },
          ]
          const selectedLang = languageMap.find(lang => lang.id === value)
          return (
            <Menu
              visible={visible}
              onDismiss={() => setVisible(false)}
              anchor={
                <ListItem
                  onPress={() => setVisible(true)}
                  title={
                    selectedLang?.name
                      ? selectedLang.name
                      : translate({ text: 'noLanguageSelected' })
                  }
                  description={
                    selectedLang?.name
                      ? translate({ text: 'language' })
                      : translate({ text: 'selectALanguage' })
                  }
                  // right={}
                />
              }
            >
              {languageMap.map(it => (
                <Menu.Item
                  value={it.id}
                  onPress={() => {
                    onChange(it.id)
                    setVisible(false)
                  }}
                  title={it.name}
                />
              ))}
            </Menu>
          )
        }}
      </Translator>
    </View>
  )
}

export default LanguagePicker
