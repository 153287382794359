import actions from './actions'
import reducer from '../../GeneralListFunctions/reducerFactory'

export interface PestType {
  id: string
  name: string
  latin_name: string
  latinName: string
}

export default reducer<PestType>(actions)
