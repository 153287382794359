import { actionCreator as ac } from '../../../actionCreator'

const ns = (type) => `FILTER_QUALITY_MARK_${type}`

const SELECT_KEY_VALUE = ns('SELECT_KEY_VALUE')
const selectKeyValue = ac(SELECT_KEY_VALUE)

const REMOVE_SELECTED_KEY_VALUE = ns('REMOVE_SELECTED_KEY_VALUE')
const removeSelectedKeyValue = ac(REMOVE_SELECTED_KEY_VALUE)

export default {
  SELECT_KEY_VALUE,
  selectKeyValue,
  REMOVE_SELECTED_KEY_VALUE,
  removeSelectedKeyValue,
}
