import React, { Component } from 'react'
import { Platform, StyleSheet } from 'react-native'
import { Appbar } from 'react-native-paper'
import AppbarHeader from '../../WidthAndHeight/AppbarHeader'
import AppbarResizer from '../../WidthAndHeight/AppbarResizer'

class Title extends Component {
  render() {
    const { title, prev, onRemove, titleStyle } = this.props
    return (
      <AppbarHeader style={styles.appBar} dark={false}>
        <AppbarResizer>
          {prev ? <Appbar.BackAction onPress={prev} /> : null}
          <Appbar.Content title={title} titleStyle={titleStyle} />
          {onRemove && <Appbar.Action icon="delete" onPress={onRemove} />}
        </AppbarResizer>
      </AppbarHeader>
    )
  }
}

const styles = StyleSheet.create({
  appBar: {
    backgroundColor: '#fff',
    elevation: 0,
    flexDirection: 'column',
    width: Platform.OS === 'web' ? '100%' : undefined,
  },
})

export default Title
