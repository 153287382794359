import * as a from './actions'
import { pushCustomEvent } from '../../Analytics/abstraction'
import { filterTypesSorted } from '../config'

export interface SwitchStateType {
  planetProof: boolean
  mps: boolean
  skal: boolean
  distributed: boolean
  expirationDate: boolean
}

const initialState: SwitchStateType = {
  planetProof: false,
  mps: false,
  skal: false,
  distributed: false,
  expirationDate: false,
}

const reducer = (
  state = initialState,
  { type, payload }: { type: string; error: boolean; payload: string }
): SwitchStateType => {
  switch (type) {
    case a.CHECK: {
      const filterType = filterTypesSorted.find((it) => it.id === payload)
      pushCustomEvent(
        `Filter enabled:`,
        `${
          filterType && Object.keys(filterType).some((key) => key === 'label')
            ? filterType.label
            : ''
        }`
      )
      return {
        ...state,
        [payload]: true,
      }
    }
    case a.UNCHECK: {
      const filterType = filterTypesSorted.find((it) => it.id === payload)
      pushCustomEvent(
        `Filter disabled:`,
        `${
          filterType && Object.keys(filterType).some((key) => key === 'label')
            ? filterType.label
            : ''
        }`
      )
      return {
        ...state,
        [payload]: false,
      }
    }
    default:
      return state
  }
}

export default reducer
