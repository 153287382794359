import React from 'react'
import { ScrollView } from 'react-native'
import compose from 'recompose/compose'
import pure from 'recompose/pure'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { loadDosages } from './actions'
// import FilterTool from '../FilterTool/FilterTool'
import { Appbar } from 'react-native-paper'
import AppbarHeader from '../WidthAndHeight/AppbarHeader'
import FilterTool from '../FilterType/FilterTool'
import ComparisonDownloadButton from './ComparisonDownloadButton'

const ComparisonToolbar = props => (
  <AppbarHeader>
    <Appbar.BackAction
      onPress={props.onNavigateBack}
      color="#fff"
      testID={'back'}
    />

    <ScrollView
      contentInsetAdjustmentBehavior={'never'}
      horizontal
      // style={{ padding: 5 }}
    >
      <FilterTool
        componentId={props.componentId}
        context="comparison"
        contextColor="dark"
        showMenu={false}
        alwaysShowSmall
        comparison
        //
      />
    </ScrollView>
    <ComparisonDownloadButton productIds={props.productIds} />	
  </AppbarHeader>
)

const enhance = compose(
  pure,
  connect(null, dispatch => bindActionCreators({ loadDosages }, dispatch))
)

export default enhance(ComparisonToolbar)
