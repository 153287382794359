import React from 'react'

import './RadioButton.web.css'

function RadioButton({ status, onPress, withPadding }) {
  return (
    <div
      role="radio"
      aria-checked={status === 'checked'}
      tabIndex="0"
      className={`radio ${status}  ${withPadding ? 'with-padding' : ''}`}
      onClick={onPress}
    >
      <div className={'outer-circle'}>
        <div className={'inner-circle'} />
      </div>
    </div>
  )
}

export default RadioButton
