import React, { Component } from 'react'
import { Text, TextInput } from 'react-native-paper'

import SwitchWithLabel from './SwitchWithLabel'
import SingleCropSelect from '../Product/Crop/SingleSelect'
import SingleProductSelect from '../Products/ProductSingleSelect/ProductSingleSelect'

// import RegistrationLines from '../Registration/Lines'
import Options from './Options'
import UnitList from './Unit/UnitList'
import GrowingPeriods from '../GroupedCrop/GrowingPeriods'
import SoilResearches from '../GroupedCrop/SoilResearches'
import FarmerListForAdvisorSingleSelect from '../Farmer/Advisor/FarmerListForAdvisorSingleSelect'
import CheckBoxWithLabel from '../Auth/CheckboxWithLabel'
import Amount from './Amount'
import RegistrationLines from '../Registration/Lines'
import RadioButtonWithLabel from '../components/RadioButtonWithLabel'
import DatePicker from '../components/DatePicker'
import LanguagePicker from '../Auth/LanguagePicker'

export const isTextInput = type =>
  type === 'text' ||
  type === 'password' ||
  type === 'email' ||
  type === 'number'

class Field extends Component {
  state = {
    hidePassword:
      this.props.type === 'password' && this.props.hidePassword
        ? this.props.hidePassword
        : this.props.type === 'password',
  }
  _change = (name, address) => inputVal => {
    console.log({ name, address, inputVal })
    let value = inputVal
    if (this.props.type === 'number') {
      value = parseInt(value, 10)
    }
    this.props.onChange(name, value, address)
  }

  render() {
    const {
      name,
      type,
      value,
      label,
      options,
      fieldProps,
      editable,
      address,
      canDelete,
      stringTrueValue,
      plainList,
      error,
      unitType,
    } = this.props
    let fieldComponent = <Text>{name} field is not implemented</Text>

    if (isTextInput(type)) {
      let extraFieldProps = {}
      if (type === 'email') {
        extraFieldProps = {
          keyboardType: 'email-address',
          autoCapitalize: 'none',
          textContentType: 'username',
        }
      }
      if (type === 'password') {
        extraFieldProps = {
          textContentType: 'password',
        }
      }
      if (type === 'number') {
        extraFieldProps = {
          textContentType: 'number',
        }
      }
      fieldComponent = (
        <TextInput
          key={name}
          mode={'flat'}
          label={label}
          testID={name}
          type={type}
          disabled={!editable}
          {...extraFieldProps}
          {...fieldProps}
          secureTextEntry={
            type === 'password' && this.props.hidePassword !== undefined
              ? this.props.hidePassword
              : this.state.hidePassword
          }
          right={
            type === 'password' && (
              <TextInput.Icon
                accessible={false}
                icon={
                  (
                    this.props.hidePassword !== undefined
                      ? this.props.hidePassword
                      : this.state.hidePassword
                  )
                    ? 'eye'
                    : 'eye-off'
                }
                size={20}
                onPress={() => {
                  this.props.toggleHidePassword
                    ? this.props.toggleHidePassword()
                    : this.setState({ hidePassword: !this.state.hidePassword })
                }}
              />
            )
          }
          style={{
            backgroundColor: '#fff',

            ...((fieldProps && fieldProps.style) || {}),
          }}
          value={value || ''}
          onChangeText={this._change(name, address)}
        />
      )
      // console.log({ fieldComponent })
    }
    if (type === 'amount') {
      fieldComponent = (
        <Amount
          key={name}
          label={label}
          testID={name}
          {...fieldProps}
          value={value}
          editable={editable}
          onChange={this._change(name, type)}
        />
      )
    }
    if (type === 'language') {
      fieldComponent = (
        <LanguagePicker
          key={name}
          label={label}
          testID={name}
          {...fieldProps}
          value={value}
          editable={editable}
          onChange={this._change(name, type)}
        />
      )
    }
    if (type === 'switch') {
      fieldComponent = (
        <SwitchWithLabel
          key={name}
          label={label}
          testID={name}
          {...fieldProps}
          value={value}
          onChange={this._change(name, address)}
        />
      )
    }
    if (type === 'radiobutton') {
      fieldComponent = (
        <RadioButtonWithLabel
          key={name}
          disabled={!editable}
          label={label}
          testID={name}
          {...fieldProps}
          stringTrueValue={stringTrueValue}
          value={value}
          onChangeValue={this._change(name, type)}
        />
      )
    }
    if (type === 'checkbox') {
      fieldComponent = (
        <CheckBoxWithLabel
          key={name}
          disabled={!editable}
          label={label}
          testID={name}
          {...fieldProps}
          stringTrueValue={stringTrueValue}
          value={value}
          onChangeValue={this._change(name, type)}
        />
      )
    }

    if (type === 'registrationLines') {
      fieldComponent = (
        <RegistrationLines
          key={name}
          label={label}
          {...fieldProps}
          value={value}
          onChange={this._change(name, type)}
        />
      )
    }
    if (type === 'product') {
      fieldComponent = (
        <SingleProductSelect
          key={name}
          value={value}
          onSelect={this._change(name, type)}
        />
      )
    }
    if (type === 'organizationSelect') {
      fieldComponent = (
        <FarmerListForAdvisorSingleSelect
          key={name}
          value={value}
          plainList={plainList}
          onSelect={this._change(name, type)}
        />
      )
    }
    if (type === 'crop') {
      fieldComponent = (
        <SingleCropSelect
          key={name}
          value={value}
          onSelect={this._change(name, type)}
        />
      )
    }
    if (type === 'growingPeriods') {
      fieldComponent = (
        <GrowingPeriods
          key={name}
          label={label}
          {...fieldProps}
          value={value}
          onChange={this._change(name, type)}
          canDelete={canDelete}
        />
      )
    }
    if (type === 'soilResearches') {
      fieldComponent = (
        <SoilResearches
          key={name}
          {...fieldProps}
          label={label}
          value={value}
          onChange={this._change(name, type)}
          canDelete={canDelete}
        />
      )
    }
    if (type === 'options') {
      fieldComponent = (
        <Options
          key={name}
          label={label}
          options={options}
          {...fieldProps}
          value={value}
          editable={editable}
          onChange={this._change(name, type)}
        />
      )
    }
    if (type === 'unit') {
      fieldComponent = (
        <UnitList
          key={name}
          label={label}
          options={options}
          fieldProps={fieldProps}
          value={value}
          unitType={unitType}
          editable={editable}
          onChange={this._change(name)}
        />
      )
    }

    if (type === 'date') {
      fieldComponent = (
        <DatePicker
          key={name}
          title={label}
          {...fieldProps}
          mode={'date'}
          editable={editable}
          value={value}
          onChange={this._change(name, type)}
        />
      )
    }

    return [
      fieldComponent,
      error && (
        <Text style={{ color: 'red', fontSize: 12 }}>{error.message}</Text>
      ),
    ]
  }
}

Field.defaultProps = {
  editable: true,
}
export default Field
