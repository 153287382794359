import React, { Component } from 'react'
import { List } from 'react-native-paper'
import AttributesList from './List/AttributesList'
import ClosableModal from '../../components/ClosableModal'
import fonts from '../../fonts'
import { Translate } from 'react-translated'
import { filterTypesSorted } from '../../FilterType/config'

class AttributesSelect extends Component {
  state = {
    modalVisible: false,
  }
  _hideModal = () => {
    this.setState({ modalVisible: false })
  }
  _showModal = () => {
    this.setState({ modalVisible: true })
  }
  _isSelected = item => {
    return this.props.value && this.props.value.some(it => it.id === item.id)
  }
  _select = item => {
    // this._hideModal()
    this.props.onSelect(item)
  }

  render() {
    const { modalVisible } = this.state
    const { value, editable, growingPeriod } = this.props
    const filterType = filterTypesSorted.find(it => it.id === 'attributes')

    return (
      <>
        <List.Item
          onPress={editable && this._showModal}
          title={
            value && value.length > 0 ? (
              value.map(it => it.name).join(', ')
            ) : editable ? (
              <Translate text="selectAttributes" />
            ) : (
              <Translate text="noAttributesSelected" />
            )
          }
          titleStyle={{
            ...fonts.medium,
          }}
          description={
            value && value.length > 0 ? (
              <Translate text="attributes" />
            ) : (
              <Translate text="noAttributesSelected" />
            )
          }
          right={p => editable && <List.Icon {...p} icon="chevron-down" />}
        />

        <ClosableModal
          isVisible={modalVisible}
          onBackButtonPress={this._hideModal}
          onBackdropPress={this._hideModal}
          onRequestClose={this._hideModal}
          onModalHide={this._hideModal}
          hideHeader={true}
        >
          <AttributesList
            {...this.props}
            filterType={filterType}
            isSelected={this._isSelected}
            editable={editable}
            onBack={this._hideModal}
            onSelect={this._select}
            growingPeriod={growingPeriod}
            selected={value}
            autoFocusSearch={true}
          />
        </ClosableModal>
      </>
    )
  }
}

AttributesSelect.defaultProps = {
  editable: true,
}

export default AttributesSelect
