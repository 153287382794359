import React, { useEffect } from 'react'
import { StyleSheet, View, useWindowDimensions } from 'react-native'
import widthAndHeightHOC from './widthAndHeightHOC'
import { useDispatch } from 'react-redux'
import { update } from './actions'

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
})

function WidthAndHeight({ render, listenToChanges, ...rest }) {
  const dispatch = useDispatch()
  const dimensions = useWindowDimensions()
  useEffect(() => {
    dispatch(
      update({
        width: dimensions.width,
        height: dimensions.height,
      })
    )
  }, [dimensions.width, dimensions.height, dispatch])

  return <View style={styles.root}>{render(rest)}</View>
}

export default widthAndHeightHOC(WidthAndHeight)
