import React from 'react'
import Svg from './SvgAbstraction'
import Path from './PathAbstraction'

import { propTypes, defaultProps, color } from './standardVector'

export const width = 24
export const height = 24

const Vector = props => (
  <Svg
    width={props.width || width}
    height={props.height || height}
    className={props.className}
    viewBox="0 0 24 24"
  >
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M6,11.25a6,6,0,0,1,12,0"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M3 11.25L0.75 11.25"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M23.25 11.25L21 11.25"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M12 0.75L12 3"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M18 5.25L19.5 3.75"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M6 5.25L4.5 3.75"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M.75,15.75v7.5h22.5v-7.5S19.5,13.5,12,13.5.75,15.75.75,15.75Z"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M6.939 13.873L2.25 23.25"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M9.865 13.563L8.25 23.25"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M17.061 13.873L21.75 23.25"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M14.135 13.563L15.75 23.25"
      stroke-linecap="round"
      stroke-linejoin="round"
      // transform="translate(-9 -9)"
    />
  </Svg>
)

Vector.propTypes = propTypes
Vector.defaultProps = defaultProps

export default Vector
