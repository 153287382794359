import { Component } from 'react'
import { Linking, Platform } from 'react-native'
import Navigation, { mergeOptions, push } from './Navigation'
import { allRoutes, HOME_ROUTE, routeObjectToWeb } from './Routes.config'
import { matchPath } from 'react-router'

class DeepLink extends Component {
  componentDidMount() {
    console.log('HELLo')

    Linking.getInitialURL().then(url => {
      console.log('URL: ', url)
      // alert(url)
      if (url) {
        this._handleOpenURL({ url })
      } else if (url === null) {
        console.log('NO URL')
      }
    })
    Linking.addEventListener('url', this._handleOpenURL)
  }

  componentWillUnmount() {
    Linking.removeEventListener('url', this._handleOpenURL)
  }

  _handleOpenURL = event => {
    if (Platform.OS === 'web') {
      return
    }
    console.log('HELLo')
    const animations = {
      push: {
        enabled: 'false',
        pop: {
          enabled: 'false',
        },
      },
    }

    if (this.props.loggedIn) {
      mergeOptions(this.props.componentId, {
        bottomTabs: {
          currentTabIndex: 0,
        },
        options: {
          animations,
        },
      })
    }

    if (this.props.componentId) {
      Navigation.popToRoot(this.props.componentId, { options: { animations } })
    }

    let url = event.url.replace('https://nl.agro4all.com/', '/')
    url = url.replace('http://nl.agro4all.com/', '/')
    url = url.replace('agro4all://agro4all/', '/')
    url = url.replace('agro4all://', '/')

    const urlWithoutQueryParams = url.split('?')[0]

    // Let's check this url against the routes
    const route = allRoutes.find(route => {
      const match = matchPath(urlWithoutQueryParams, routeObjectToWeb(route))
      return !!match
    })
    console.log({ route })

    // user is not logged in
    if (!this.props.loggedIn) {
      // if no route is found or deeplinking without login is disabled
      if (!route || !route.deepLinkWithoutLogin) {
        // alert('Deep is disabled here')
        return
      }
    }
    // We found a match in our routes.config
    if (route) {
      const match = matchPath(url, routeObjectToWeb(route))
      console.log({ match })

      // Let's push that screen on native, baby
      push(this.props.componentId, {
        component: {
          options: { animations },
          name: route.path,
          passProps: {
            ...(match && match),
            ...(match && match.params),
            url,
          },
        },
      })
    } else {
      push(this.props.componentId, {
        component: {
          options: { animations },
          name: HOME_ROUTE,
        },
      })
    }
  }

  render() {
    return null
  }
}

DeepLink.defaultProps = {
  loggedIn: false,
}
export default DeepLink
