import React, { useCallback, useEffect, useState } from 'react'
// import CheckBoxWithLabel from '../Auth/CheckboxWithLabel'
import { Translator } from 'react-translated'
import CheckBoxWithLabel from '../Auth/CheckboxWithLabel'

export default function RegistrationConceptStatus({
  value,
  onChange,
  editable = true,
  ...rest
}) {
  const [done, setDone] = useState(false)
  const onInnerChange = useCallback(
    value => {
      onChange(value ? 'FINAL' : 'DRAFT')
    },
    [onChange]
  )

  useEffect(() => {
    if (!done) {
      setDone(true)
      onInnerChange(false)
    }
  }, [done, onInnerChange, setDone])

  return editable ? (
    <Translator>
      {({ translate }) => (
        <CheckBoxWithLabel
          value={value === 'FINAL'}
          label={translate({ text: 'registrationDone' })}
          onChangeValue={onInnerChange}
          {...rest}
        />
      )}
    </Translator>
  ) : null
}
