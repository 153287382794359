import actions from './actions'
import reducer from '../../GeneralListFunctions/reducerFactory'

export interface CropType {
  id: string
  name: string
  latin_name: string
  latinName: string
  number_children: number
}

export default reducer<CropType>(actions)
