import {ScrollView, StyleSheet, TouchableOpacity, View} from 'react-native'
import LabelRow from '../Product/LabelRow'
import { Translate } from 'react-translated'
import React, {useCallback, useEffect} from 'react'
import SafeAreaHOC from '../WidthAndHeight/safeAreaHOC'
import { processKNPVPest, processKNPVPestMain } from './config'
import { getLanguage } from '../language'
import { useDispatch, useSelector } from 'react-redux'
import AppbarHeader from '../WidthAndHeight/AppbarHeader'
import AppbarResizer from '../WidthAndHeight/AppbarResizer'
import {Appbar, DataTable, Divider, Title} from 'react-native-paper'
import Navigation from '../Navigation'
import actions from './actions'
import Resizer from '../WidthAndHeight/Resizer'
import WidthAndHeightHOC from '../WidthAndHeight/widthAndHeightHOC'
import {openEppoPage} from "../helpers/openWebPage";
import DataTableCellMultiLine from "../components/DataTableCellMultiLine";
import Contact from "../Contact/Contact";

const capitalizeFirstLetter = s => (s && s[0].toUpperCase() + s.slice(1)) || "-"
function PestDetailScreen({ isMedium, safe, componentId, id }) {
  const language = getLanguage()
  const knpvPest = useSelector(state => state.knpv.crud)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      actions.load(
        { id },
        { cacheFromList: true, fetchOptions: { path: `pest/${id}` } }
      )
    )
  }, [id, dispatch])

  const back = useCallback(() => {
    Navigation.pop(componentId)
  }, [componentId])

  const preferredNameNL = knpvPest?.object?.names?.find(
    name => name?.language === 'nl' && name?.isPreferred
  )
  const preferredNameLA = knpvPest?.object?.names?.find(
    name => name?.language === 'la' && name?.isPreferred
  )

  if (!knpvPest.loaded) {
    return (
      <>
        <AppbarHeader dark style={{ elevation: 0, justifyContent: 'center' }}>
          <AppbarResizer>
            <Appbar.BackAction
              onPress={() => {
                Navigation.pop(componentId)
              }}
              color="#fff"
              testID={'back'}
            />
            <Appbar.Content title={'Aan het laden...'} color="#fff" />
          </AppbarResizer>
        </AppbarHeader>
        <View style={{ backgroundColor: '#fff', flex: 1 }} />
      </>
    )
  }
  const attributesMain = processKNPVPestMain(knpvPest?.object, language)
  return (
    <>
      <AppbarHeader dark style={{ elevation: 0, justifyContent: 'center' }}>
        <AppbarResizer>
          <Appbar.BackAction
            onPress={back}
            color="#fff"
            testID={'back'}
          />
          <Appbar.Content
            title={
              preferredNameNL?.name ||
              preferredNameLA?.name ||
              knpvPest?.object?.name
            }
            color="#fff"
          />
        </AppbarResizer>
      </AppbarHeader>
      <ScrollView>
        <Resizer>
          <View style={{ margin: 14 }}>
            <Title>
              <Translate text={'infestation'} />
            </Title>
          </View>
          <View
            style={[isMedium && { flexDirection: 'row', flexWrap: 'wrap' }]}
          >
            {attributesMain.map(({ label, value, hasValue }, index) => {
              return (
                <View style={[isMedium && { width: '50%' }]} key={index}>
                  {label?.includes('EPPO code') &&
                  value !== '-' &&
                  value !== 'undefined' ? (
                    <TouchableOpacity
                      onPress={() => openEppoPage({code: value})}
                    >
                      <LabelRow
                        key={index}
                        enableBorderTop={
                          isMedium ? index !== 0 && index !== 1 : index !== 0
                        }
                        label={<Translate text={label} />}
                        value={hasValue ? value : '-'}
                      />
                    </TouchableOpacity>
                  ) : (
                    <LabelRow
                      key={index}
                      enableBorderTop={
                        isMedium ? index !== 0 && index !== 1 : index !== 0
                      }
                      label={
                        !hasValue && label?.includes('EPPO code') ? (
                          <Translate text={'EPPO code'} />
                        ) : (
                          <Translate text={label} />
                        )
                      }
                      value={hasValue ? value : '-'}
                    />
                  )}
                </View>
              )
            })}
          </View>
          {knpvPest?.object?.pests?.map((pest, index) => {
            return (
              <>
                <View style={{ margin: 14 }}>
                  <Title>
                    <Translate text={'pest'} /> {index + 1}
                  </Title>
                </View>
                {pest?.names
                  ?.sort((a, b) => {
                    return a?.isPreferred ? -1 : 1
                  })
                  ?.map((pestName, index) => {
                    const attributes = processKNPVPest(pestName, language)
                    return (
                      <>
                        <View style={{ margin: 14 }}>
                          <Title>
                            <Translate text={'pestName'} /> {index + 1}
                          </Title>
                          <Divider />
                        </View>
                        <View
                          style={[
                            isMedium && {
                              flexDirection: 'row',
                              flexWrap: 'wrap',
                            },
                          ]}
                        >
                          {attributes.map(
                            ({ label, value, hasValue }, index) => (
                              (label === 'Teelten' || label === 'Crops') && hasValue && value && Array.isArray(value) && value.length > 0 ? (
                                <View style={styles.tableContainer} key={index}>
                                  <DataTable style={styles.table}>
                                    <DataTable.Header style={{borderBottomWidth: 3, ...styles.row}}>
                                      <DataTable.Title style={styles.tableWide}><Translate text={'cropName'}/></DataTable.Title>
                                      <DataTable.Title style={styles.tableSmall}><Translate text={'language'}/></DataTable.Title>
                                      <DataTable.Title style={styles.tableWide}><Translate text={'preferredName'}/></DataTable.Title>
                                    </DataTable.Header>
                                    {
                                      value.map(item => {
                                        if (item && Array.isArray(item) && item.length > 0) {
                                          return item.map((it, idx, arr) => {
                                            return (
                                              <DataTable.Row key={idx} style={ idx === arr.length - 1 ? {borderBottomWidth: 3, ...styles.row} : styles.row}>
                                                <DataTableCellMultiLine style={styles.tableWide} numberOfLines={10}>{capitalizeFirstLetter(it?.name)}</DataTableCellMultiLine>
                                                <DataTable.Cell style={styles.tableSmall}>{it?.language.toUpperCase()}</DataTable.Cell>
                                                <DataTable.Cell style={styles.tableWide}>{it?.isPreferred ? <Translate text={'yes'}/> : <Translate text={'no'}/>}</DataTable.Cell>
                                              </DataTable.Row>
                                            )
                                          })
                                        }
                                        return null
                                      })
                                    }
                                  </DataTable>
                                </View>
                              ) : (
                              <View
                                style={[isMedium && { width: '50%' }]}
                                key={index}
                              >
                                {label?.includes('EPPO code') &&
                                value !== '-' &&
                                value !== 'undefined' ? (
                                  <TouchableOpacity
                                    onPress={() => openEppoPage({code: value})}
                                  >
                                    <LabelRow
                                      key={index}
                                      enableBorderTop={
                                        isMedium
                                          ? index !== 0 && index !== 1
                                          : index !== 0
                                      }
                                      label={<Translate text={label} />}
                                      value={hasValue ? value : '-'}
                                    />
                                  </TouchableOpacity>
                                ) : (
                                  <LabelRow
                                    key={index}
                                    enableBorderTop={
                                      isMedium
                                        ? index !== 0 && index !== 1
                                        : index !== 0
                                    }
                                    label={
                                      !hasValue &&
                                      label &&
                                      label?.includes('EPPO code') ? (
                                        <Translate text={'EPPO code'} />
                                      ) : (
                                        <Translate text={label ? label : ''} />
                                      )
                                    }
                                    value={
                                      hasValue ? (value ? value : '-') : '-'
                                    }
                                  />
                                )}
                              </View>
                            ))
                          )}
                        </View>
                      </>
                    )
                  })}
              </>
            )
          })}
          <Contact problem relatedTo={`KNPV pest, id ${id}`}/>
        </Resizer>
        <View style={{ marginBottom: 20 }} />
      </ScrollView>
    </>
  )
}

const styles = StyleSheet.create({
  tableContainer: {
    width: '100%',
    padding: 16,
    paddingTop: 0,
  },
  table: {
    width: '100%',
    borderTopWidth: 1,
    borderTopColor: 'rgba(0,0,0,0.1)',
  },
  tableWide: {
    flex: 2,
    padding: 5,
  },
  tableSmall: {
    flex: 1,
    padding: 5,
  },
  row: {
    paddingLeft: 5,
    paddingRight: 5,
  },
})

export default WidthAndHeightHOC(SafeAreaHOC(PestDetailScreen))
