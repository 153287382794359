import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import actions from '../../Activity/actions'
import registrationActions from '../actions'

import GeneralList from '../../GeneralListFunctions/GeneralList'
import PageHeader from '../../PageHeader'
import { Appbar, List, RadioButton } from 'react-native-paper'
import Navigation from '../../Navigation'
import Resizer from '../../WidthAndHeight/Resizer'
import { View } from 'react-native'
import { Translator } from 'react-translated'
import { useActionsAsFunctions } from '../../helpers/actions'

function ActivityScreen({ next, componentId }) {
  const list = useSelector(state => state.activity.list)
  const selectedActivity = useSelector(
    state => state.registration.crud.object?.activity
  )

  const dispatch = useDispatch()
  useEffect(() => {
    // If list data has 1 item, we skip this screen, and select that item.
    if (list?.data?.length === 0) {
      next(true)
    } else if (list?.data?.length === 1) {
      dispatch(registrationActions.setData({ activity: list.data[0] }))
      next(true)
    }
  }, [dispatch, list, next])

  const press = activity => () => {
    dispatch(registrationActions.setData({ activity }))
    next()
  }

  const onBack = () => {
    Navigation.pop(componentId)
  }
  const renderLeft = (item, isSelected) => () => {
    return (
      <View style={{}}>
        <RadioButton.Android
          status={isSelected ? 'checked' : 'unchecked'}
          onPress={() => press(item)}
        />
      </View>
    )
  }

  const renderItem = ({ item }) => {
    const selected = (selectedActivity && selectedActivity.id) === item.id
    return (
      <Resizer>
        <List.Item
          key={item.id}
          title={item.name}
          description={item.description}
          onPress={press(item)}
          left={renderLeft(item, selected)}
        />
      </Resizer>
    )
  }

  const actionsAsFunctions = useActionsAsFunctions({
    ...actions,
    setRegistrationData: registrationActions.setData,
  })

  return (
    <Translator>
      {({ translate }) => (
        <GeneralList
          {...actionsAsFunctions}
          list={list}
          searchPlaceholder={translate({ text: 'searchActivity' })}
          renderItem={renderItem}
          icon
          appbarContent={
            <PageHeader
              componentId={componentId}
              back={onBack}
              title={
                <Appbar.Content title={translate({ text: 'chooseActivity' })} />
              }
            />
          }
          context={'activity'}
        />
      )}
    </Translator>
  )
}

export default ActivityScreen
