import React from 'react'
import Point from '../Map/Point'
import { useSelector } from 'react-redux'

function LocationsOnMap({ locations }) {
  // Do not remove this, we need to rerender the entrace whenever the data.field (all fields) are loaded
  // Because mapbox will always give a later rendered item a higher zIndex
  // https://github.com/nitaliano/react-native-mapbox-gl/issues/970
  const data = useSelector(state => state.fieldMap.data)
  return locations
    .filter(location => location?.coordinates?.length > 0)
    .map(location => (
      <>
        {data.field && (
          <Point
            key={location?.id + '_' + location?.order}
            id={location?.id + '_' + location?.order}
            shape={location?.coordinates ? location?.coordinates : []}
            zIndex={100}
          />
        )}
        <Point
          key={location?.id + '_' + location?.order + '_2'}
          id={location?.id + '_' + location?.order + '_2'}
          shape={location?.coordinates ? location?.coordinates : []}
          zIndex={100}
        />
      </>
    ))
}

export default React.memo(LocationsOnMap)
