import React from 'react'
import Svg from './SvgAbstraction'
import Path from './PathAbstraction'

import { color, defaultProps, propTypes } from './standardVector'

export const width = 24
export const height = 24

const Vector = props => (
  <Svg
    width={props.width || width}
    height={props.height || height}
    className={props.className}
    viewBox="0 0 24 24"
  >
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M11.999 0.747L11.999 23.247"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M23.249 11.997L0.749 11.997"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M2.999 11.997 A9.000 9.000 0 1 0 20.999 11.997 A9.000 9.000 0 1 0 2.999 11.997 Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M7.499 11.997 A4.500 4.500 0 1 0 16.499 11.997 A4.500 4.500 0 1 0 7.499 11.997 Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </Svg>
)

Vector.propTypes = propTypes
Vector.defaultProps = defaultProps

export default Vector
