import React, {useCallback, useState} from 'react'
import {Keyboard, StyleSheet, TouchableOpacity} from 'react-native'
import { Text } from 'react-native-paper'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import Resizer from '../WidthAndHeight/Resizer'
import SafeListItem from '../WidthAndHeight/SafeListItem'
import SelectionComponent from '../components/SelectionComponent'
import fonts from '../fonts'
import EppoModal from "./Eppo/EppoModal"

const isZero = (value) => !value || value === 0

function ItemInTree({
  isSelected,
  item,
  filterType,
  multiSelect,
  testID,
  noSelect,
  onSelect,
  onPress,
}){
  const { getItemLabel, getItemSubLabel } = filterType
  const [isVisible, setIsVisible] = useState(false)

  const onRequestClose = useCallback(() => {
    setIsVisible(false)
  }, [setIsVisible])

  const select = useCallback(() => {
    onSelect(item)
  }, [item, onSelect])

  const pressInfo = useCallback((e) => {
    Keyboard.dismiss()
    if (e && e.preventDefault) {
      e.preventDefault()
    }

    if (!item.eppo) {
      onSelect(item)
      return
    }

    setIsVisible(true)
  }, [item, onSelect, setIsVisible])

  const pressRight = useCallback((e) => {
    if (e && e.preventDefault) {
      e.preventDefault()
    }

    // item can
    if (isZero(item.number_children) && isZero(item.childrenCount)) {
      onSelect(item)
      return
    }

    onPress(item)
  }, [item, onSelect, onPress])

  return (
    <Resizer>
      <SafeListItem
        title={getItemLabel(item)}
        description={getItemSubLabel && getItemSubLabel(item)}
        onPress={select}
        left={
          !noSelect && (
            <SelectionComponent
              testID={testID}
              status={isSelected ? 'checked' : 'unchecked'}
              onPress={select}
              selectType={multiSelect ? 'checkbox' : 'radiobutton'}
            />
          )
        }
        right={
          <>
            {item.eppo ? (
              <TouchableOpacity
                style={styles.rightContainer}
                testID={`down_tree_${testID}`}
                accessibilityTraits={'button'}
                accessibilityComponentType={'button'}
                accessibilityRole={'button'}
                onPress={pressInfo}
              >
                <Icon name={'information-outline'} size={24} />
                <EppoModal
                  isVisible={isVisible}
                  onRequestClose={onRequestClose}
                  item={item}
                />
              </TouchableOpacity>
            ) : null}
            {isZero(item.number_children) &&
            isZero(item.childrenCount) ? null : (
              <TouchableOpacity
                style={styles.rightContainer}
                testID={`down_tree_${testID}`}
                accessibilityTraits={'button'}
                accessibilityComponentType={'button'}
                accessibilityRole={'button'}
                onPress={pressRight}
              >
                <Text style={styles.rightText}>
                  {item.number_children
                    ? item.number_children
                    : item.childrenCount}
                </Text>
                <Icon name={'chevron-right'} size={24} />
              </TouchableOpacity>
            )}
          </>
        }
        // pressableRight
      />
    </Resizer>
  )
}

const styles = StyleSheet.create({
  leftContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 5,
  },
  rightContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 13,
  },
  rightText: {
    paddingRight: 6,
    ...fonts.medium,
  },
})
export default ItemInTree
