import React, { Component } from 'react'

import Resizer from '../WidthAndHeight/Resizer'
import SafeListItem from '../WidthAndHeight/SafeListItem'
import SelectionComponent from '../components/SelectionComponent'

class ItemInList extends Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.isSelected !== this.props.isSelected) {
      return true
    }
    return false
  }

  _select = () => {
    const { item } = this.props
    this.props.onSelect(item)
  }
  _press = () => {
    const { item } = this.props
    this.props.onPress(item)
  }

  render() {
    const {
      item,
      filterType,
      isSelected,
      selectType,
      right,
      noSelect,
      nameFirst,
      noSublabel,
    } = this.props
    return (
      <Resizer>
        <SafeListItem
          title={
            nameFirst
              ? item.name
              : filterType && filterType.getItemLabel
              ? filterType.getItemLabel(item)
              : item.name
          }
          description={
            !noSublabel &&
            filterType &&
            filterType.getItemSubLabel &&
            filterType.getItemSubLabel(item)
          }
          onPress={this._press}
          left={
            !noSelect && (
              <SelectionComponent
                status={isSelected ? 'checked' : 'unchecked'}
                onPress={this._select}
                withPadding
                selectType={selectType}
              />
            )
          }
          right={right}
        />
      </Resizer>
    )
  }
}

ItemInList.defaultProps = {
  selectType: 'checkbox',
}
export default ItemInList
