import React from 'react'
import Svg from './SvgAbstraction'
import Path from './PathAbstraction'

import { color, defaultProps, propTypes } from './standardVector'

export const width = 24
export const height = 24

const Vector = props => (
  <Svg
    width={props.width || width}
    height={props.height || height}
    className={props.className}
    viewBox="0 0 24 24"
  >
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M1 14.5776C2.6186 13.1479 4.70715 12.3647 6.86667 12.3776C10.5333 12.3776 17.1333 18.5698 23 12.7032"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M1 9.20015C2.6186 7.77054 4.70715 6.98734 6.86667 7.00015C10.5333 7.00015 17.1333 13.1924 23 7.32576"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M1 19.9556C2.6186 18.5259 4.70715 17.7427 6.86667 17.7556C10.5333 17.7556 17.1333 23.9478 23 18.0812"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </Svg>
)

Vector.propTypes = propTypes
Vector.defaultProps = defaultProps

export default Vector
