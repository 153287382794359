import actions from './actions'
import organizationActions from '../Organization/actions'

import saga from '../../GeneralListFunctions/sagaFactory'

export default () =>
  saga(
    actions,
    () => 'v1/auth/organization/manage',
    state => state.farmer.advisor,
    {
      reloadActions: [
        organizationActions.CREATED,
        organizationActions.REMOVED,
        organizationActions.UPDATED,
      ],
    }
  )
