import React from 'react'
import { Image, Platform, TouchableOpacity } from 'react-native'
import { switchMenu } from '../Navigation'
import * as routes from '../Routes.config'

const Agro4allLogo = withoutOnPressLogo => {
  return (
    <TouchableOpacity
      onPress={() => {
        return withoutOnPressLogo.withoutOnPressLogo
          ? null
          : switchMenu(routes.HOME_ROUTE)
      }}
    >
      <Image
        style={{ width: 180, height: 32 }}
        resizeMode="contain"
        source={
          Platform.OS === 'web'
            ? require('../../img/webp/logo_white.webp')
            : require('../../img/logo_white.png')
        }
      />
    </TouchableOpacity>
  )
}

export default Agro4allLogo
