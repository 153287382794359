import React, { useCallback, useMemo } from 'react'
import { Appbar } from 'react-native-paper'

import actions from './actions'
import { View } from 'react-native'
import PageHeader from '../../PageHeader'

import GeneralList from '../../GeneralListFunctions/GeneralList'
import { ChooseFarmerRenderer } from '../Organization/ChooseFarmerButton'
import FarmerItem from '../FarmerItem'
import AdvisorFilter from '../../AdvisorFilter'
import { connect, useDispatch, useSelector } from 'react-redux'
import { hasRole } from '../../helpers/role'
import { Translator } from 'react-translated'

function FarmersListForAdvisor(props) {
  const {
    componentId,
    onPressItem,
    title,
    // onBack,
    renderAboveHeader,
    onChange,
    user,
    plainList,
  } = props

  const selectedTypes = useSelector(
    state => state.filter.organizationType.selected
  )
  const isContractor = hasRole(user, 'contractor')
  const isTransporter = hasRole(user, 'transporter')
  const renderStickyBelowHeader = useCallback(() => {
    return (
      <AdvisorFilter
        key="filter-tool"
        componentId={componentId}
        context="organization"
      />
    )
  }, [componentId])

  const extraParams = useMemo(() => {
    let cleaned = selectedTypes.map(it => it.key)
    if (isContractor || isTransporter) {
      cleaned = ['farmer', 'horticulturist']
    }
    return isContractor || isTransporter
      ? plainList
        ? {
            sort: 'name',
          }
        : {
            'filter[type]': cleaned,
            sort: 'name',
          }
      : plainList
      ? {
          // onlyUserManaged: true,
          sort: 'name',
        }
      : {
          'filter[type]': cleaned,
          // onlyUserManaged: true,
          sort: 'name',
        }
  }, [selectedTypes, isContractor, isTransporter, plainList])
  const dispatch = useDispatch()
  React.useEffect(() => {
    if (props.list.loadedList || props.crud.created) {
      // dispatch(orgActions.reset())
    }
  }, [props.list.loadedList, props.crud.created, dispatch])
  const renderItem = useCallback(
    ({ item }) => <FarmerItem item={item} onPress={onPressItem} />,
    [onPressItem]
  )

  return (
    <ChooseFarmerRenderer
      onChange={onChange}
      render={({ onShowModal }) => (
        <Translator>
          {({ translate }) => (
            <View
              style={
                plainList ? { marginBottom: 25, height: 600 } : { flex: 1 }
              }
            >
              <GeneralList
                {...props}
                silentReloadingOnMount={false}
                searchPlaceholder={translate({ text: 'searchCompany' })}
                renderStickyBelowHeader={
                  plainList ? undefined : renderStickyBelowHeader
                }
                renderAboveHeader={renderAboveHeader}
                renderItem={renderItem}
                icon
                extraParams={extraParams}
                appbarContent={
                  <PageHeader
                    componentId={componentId}
                    // back={back}
                    title={
                      <Appbar.Content
                        title={title || translate({ text: 'companies' })}
                        titleStyle={{
                          textAlign: 'center',
                        }}
                        subtitleStyle={{
                          textAlign: 'center',
                        }}
                      />
                    }
                  />
                }
                onPressFab={plainList ? undefined : onShowModal}
                fabLabel={
                  plainList ? undefined : translate({ text: 'addCompany' })
                }
              />
            </View>
          )}
        </Translator>
      )}
    />
  )
}

const mapStateToProps = state => ({
  list: state.farmer.advisor.list,
  crud: state.farmer.organization.crud,
  user: state.auth.user.user,
})

export default connect(mapStateToProps, actions)(FarmersListForAdvisor)
