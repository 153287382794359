import React, { useEffect, useState } from 'react'
import CompanyList from './CompanyList'
import DownloadSendAdvice from './DownloadSendAdvice'
import AdjustAdvice from './AdjustAdvice'
import ObservationList from './ObservationList'

const steps = [
  {
    component: CompanyList,
  },
  {
    component: ObservationList,
  },
  {
    component: AdjustAdvice,
  },
  {
    component: DownloadSendAdvice,
  },
]

function AdviceWizardStepper({
  componentId,
  step,
  companyId,
  observationId,
  adviceId,
}) {
  const [activeStep, setActiveStep] = useState(0)

  useEffect(() => {
    if (step) {
      setActiveStep(Number(step))
    } else if (step === undefined) {
      setActiveStep(0)
    }
  }, [step])

  return (
    <>
      {steps.map((step, i) => {
        return i === activeStep ? (
          <step.component
            key={'active_' + i}
            componentId={componentId}
            companyId={companyId}
            observationId={observationId}
            adviceId={adviceId}
          />
        ) : null
      })}
    </>
  )
}

export default AdviceWizardStepper
