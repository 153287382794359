import React from 'react'
import { useSelector } from 'react-redux'
import { menuOptions } from './helpers/role'
import DeepLink from './DeepLink'
import { SimpleBackgroundHeader } from './GeneralListFunctions/GeneralListHeader'
import PageHeader from './PageHeader'
import Resizer from './WidthAndHeight/Resizer'
import MenuButton from './components/MenuButton'
import * as routes from './Routes.config'
import { Platform, ScrollView, StyleSheet } from 'react-native'
import { Translator } from 'react-translated'
import SafeAreaHOC from './WidthAndHeight/safeAreaHOC'
import RegistrationIcon from './vectors/Registration'
import CompanyIcon from './vectors/Bedrijven'
import LeafIcon from './vectors/CultivationSystem'
import MapIcon from './vectors/Map'
import ObservationIcon from './vectors/Observation'
import ParcelIcon from './vectors/Parcels'
import ProductIcon from './vectors/Middelen'
import DistributionIcon from './vectors/Distribution'
import { useSafeArea } from './WidthAndHeight/safeAreaHook'

// do not enable width since this is the native platform
// at least do not enable this on Android
const nativeWidth = Platform.select({
  android: undefined,
  ios: 280,
  web: 280,
})

const optionToComponent = new Map([
  [
    'products',
    {
      title: 'homeProductsButton',
      Icon: ProductIcon,
      route: (routes) => routes.PRODUCTS_ROUTE,
    },
  ],
  [
    'knpv',
    {
      title: 'homeKNPVButton',
      Icon: ObservationIcon,
      route: (routes) => routes.KNPV_LIST_ROUTE,
    },
  ],
  [
    'registrations',
    {
      title: 'homeRegistrationsButton',
      Icon: RegistrationIcon,
      route: (routes) => routes.REGISTRATION_ROUTE,
    }
  ],
  [
    'crops',
    {
      title: 'homeGroupedCropsButton',
      Icon: LeafIcon,
      route: (routes) => routes.GROUPED_CROP_ROUTE,
    },
  ],
  [
    'organizationFields',
    {
      title: 'homeOrganizationFieldsButton',
      Icon: ParcelIcon,
      route: (routes) => routes.FIELDS_ROUTE,
    },
  ],
  [
    'parcels',
    {
      title: 'homeParcelsButton',
      Icon: MapIcon,
      route: (routes) => routes.ORGANIZATION_FIELDS_ON_MAP_ROUTE,
    },
  ],
  [
    'organizations',
    {
      title: 'homeOrganizationsButton',
      Icon: CompanyIcon,
      route: (routes) => routes.ADVISOR_FARMERS_ROUTE,
    },
  ],
  [
    'distributions',
    {
      title: 'homeDistributionsButton',
      Icon: DistributionIcon,
      route: (routes) => routes.DISTRIBUTION_LIST_ROUTE,
    },
  ],
])

function Home({ componentId }) {
  const { safePadding } = useSafeArea()

  const user = useSelector(
    state => (state.auth && state.auth.user && state.auth.user.user) || {}
  )

  // Returns the menu items based on the roles of the user
  const options = menuOptions(user)

  if (options.length === 0 || options.length === 1) {
    // Get the only option of the user defaulting to products
    const option = (options.length === 1 && options[0]) || 'products'
    const optionRoute = optionToComponent.get(option).route(routes)
    const component = routes.allRoutes.find(it => it.path === optionRoute)
    return <component.component componentId={componentId} />
  }

  return (
    <>
      <SimpleBackgroundHeader
        key={`SimpleBackgroundHeader}`}
        // absolute
        appbarContent={<PageHeader componentId={componentId} />}
        zIndex
      />
      <Translator>
        {({ translate }) => (
          <ScrollView
            style={{ paddingTop: 16, paddingBottom: 32 }}
            contentContainerStyle={[
              styles.contentContainerStyle,
              safePadding,
              { paddingRight: 0, paddingTop: 0, paddingBottom: 32 },
            ]}
          >
            {options.includes('products') && (
              <Resizer style={{ flex: 1, height: '100%' }}>
                <MenuButton
                  testID="productsButton"
                  title={translate({ text: 'homeProductsButton' })}
                  componentId={componentId}
                  renderIcon={() => (
                    <ProductIcon color={'blue'} width={50} height={50} />
                  )}
                  route={routes.PRODUCTS_ROUTE}
                />
              </Resizer>
            )}
            <Resizer
              style={{
                flexWrap: 'wrap',
                flexDirection: 'row',
                flex: 3,
              }}
            >
              {
                options.map(option => {
                  const component = optionToComponent.get(option)
                  if (!component || option === 'products') {
                    return null
                  }

                  return (
                    <MenuButton
                      testID={`${component.title}`}
                      title={translate({text: component.title})}
                      componentId={componentId}
                      renderIcon={() => (
                        <component.Icon color={'blue'} width={50} height={50}/>
                      )}
                      route={component.route(routes)}
                      halfWidth
                      second
                    />
                  )
                })
              }
            </Resizer>
          </ScrollView>
        )}
      </Translator>
      <DeepLink loggedIn={false} componentId={componentId} />
    </>
  )
}

const styles = StyleSheet.create({
  root: Platform.select({
    web: {
      height: '100vh',
      backgroundColor: '#2d1c5d',
      color: '#FFF',
      width: 280,
    },
    default: {
      backgroundColor: '#2d1c5d',
      color: '#FFF',
      width: nativeWidth,
    },
  }),
  contentContainerStyle: {
    flexGrow: 1,
  },
})

export default SafeAreaHOC(Home)
