import React, { useCallback, useState } from 'react'
import { Keyboard, Platform, StyleSheet, View } from 'react-native'
import actions from '../actions'
import LocationScreen from './LocationScreen'
import InformationScreen from './InformationScreen'
import Swiper from '../../components/Swiper'
import Navigation, { push } from '../../Navigation'
import detailHOC from '../../GeneralListFunctions/detailHOC'
import BottomFab from '../../WidthAndHeight/BottomFab'
import { FILTER_MODAL_ROUTE } from '../../Routes.config'
import { useDispatch } from 'react-redux'
import { Translate, Translator } from 'react-translated'
import LocationSubDivisions from './LocationSubDivisions'
import FieldsScreen from './FieldsScreen'

let showDrawMenuOutOfComponent = false
let saveOutOfComponent = false
let mergedFieldOutOfComponent = []
const getData = (showFieldInfo, setShowFieldInfo) =>
  [
    {
      key: 'parcel',
      title: <Translate text="parcel" />,
      component: FieldsScreen,
      showNextButton: true,
    },
    {
      key: 'address',
      title: <Translate text="location" />,
      component: LocationScreen,
      showNextButton: true,
      showDrawMenu: showDrawMenuOutOfComponent,
      save: saveOutOfComponent,
      mergedField: mergedFieldOutOfComponent,
      showFieldInfo,
      setShowFieldInfo,
    },
    {
      key: 'subDivisions',
      title: <Translate text="subDivisions" />,
      component: LocationSubDivisions,
      showNextButton: true,
    },
    {
      key: 'information',
      title: <Translate text="parcels" />,
      component: InformationScreen,
      showNextButton: true,
    },
  ].filter(n => n)

function Detail({
  onRemove,
  crud,
  componentId,
  onSubmit,
  selectedFarmer,
  fieldArray,
  customFieldArray,
  setData,
}) {
  const [index, setIndex] = useState(0)
  const swiper = React.createRef()
  const [showFieldInfo, setShowFieldInfo] = useState(false)
  const data = getData(showFieldInfo, setShowFieldInfo)
  const [showDrawMenu, setShowDrawMenu] = useState(false)
  const dispatch = useDispatch()

  const hasEmptyCropForGP =
    !crud.object ||
    (crud.object &&
      (!crud.object.fieldArray ||
        (crud.object.fieldArray &&
          (crud.object.fieldArray.length <= 0 ||
            crud.object.fieldArray.some(
              fa => fa.growingPeriods && fa.growingPeriods.some(gp => !gp.crop)
            )))))

  const pop = useCallback(() => {
    Navigation.pop(componentId)
  }, [componentId])

  const prev = useCallback(() => {
    Keyboard.dismiss()
    if (index === 0) {
      pop()
      return
    }
    setIndex(index - 1)
  }, [index, pop, setIndex])

  const to = useCallback(
    index => {
      setIndex(index)
    },
    [setIndex]
  )

  const next = useCallback(
    selectedFirstPage => {
      setShowFieldInfo(false)
      Keyboard.dismiss()
      const isLast = data.length - 1 === index

      if (isLast) {
        let newObject = crud.object
        // console.log({ newObject })
        let newFieldArray = newObject.fieldArray.map(it => {
          if (it && it.growingPeriods && it.growingPeriods.length > 0) {
            return {
              ...it,
              growingPeriods: it.growingPeriods.map(gp => {
                return {
                  ...gp,
                  endDate: Math.round(gp.endDate / 1000),
                  startDate: Math.round(gp.startDate / 1000),
                }
              }),
            }
          }
          return it
        })
        if (!newObject.id) {
          onSubmit({ payload: { fieldArray: newFieldArray } })
        } else {
          onSubmit({ payload: { extraBody: { fieldArray: newFieldArray } } })
        }
      } else if (selectedFirstPage) {
        // skip new parcel screen
        setIndex(index + 2)
      } else {
        setIndex(index + 1)
      }
    },
    [data.length, index, onSubmit, crud.object]
  )

  const selectFarmer = useCallback(() => {
    Keyboard.dismiss()
    // DO FARMER SCREEN HERE
    push(componentId, {
      component: {
        name:
          Platform.OS === 'web'
            ? FILTER_MODAL_ROUTE.replace(':context', 'field')
            : FILTER_MODAL_ROUTE,
        passProps: {
          activeId: 'farmer',
        },
      },
    })
  }, [componentId])

  const saveDrawing = useCallback(() => {
    if (showDrawMenu) {
      saveOutOfComponent = true
      showDrawMenuOutOfComponent = false
      setShowDrawMenu(false)
    } else {
      showDrawMenuOutOfComponent = true
      setShowDrawMenu(true)
      saveOutOfComponent = false
    }
  }, [showDrawMenu])

  const cancel = useCallback(() => {
    showDrawMenuOutOfComponent = false
    setShowDrawMenu(false)
  }, [])

  let nextLabel = 'save'
  if (data[index + 1]) {
    nextLabel = 'next'
  }
  const currentScreen = (data && data[index]) || {}

  const mergeSelected = useCallback(() => {
    const fillColor =
      !customFieldArray || customFieldArray.length === 0
        ? '#FF0000'
        : customFieldArray.length === 1
        ? '#FFFF00'
        : '#FFFFFF'
    const newFieldArray = fieldArray.map(fa => {
      return { ...fa, fillColor: fillColor }
    })
    mergedFieldOutOfComponent = newFieldArray
    const newCustomFieldArray = [...(customFieldArray || []), newFieldArray]
    dispatch(
      setData({
        fieldArray: [],
        customFieldArray: newCustomFieldArray,
      })
    )
  }, [fieldArray, dispatch, setData, customFieldArray])
  return (
    <View style={{ flex: 1 }}>
      <Translator>
        {({ translate }) => (
          <>
            <Swiper
              data={data}
              ref={swiper}
              index={index}
              onIndexChange={to}
              onPop={pop}
              currentScreen={currentScreen}
              onNext={next}
              onPrev={prev}
              passItemProps={{ onRemove }}
              showFieldInfo={showFieldInfo}
              setShowFieldInfo={setShowFieldInfo}
            />

            {showDrawMenu ? (
              <>
                <BottomFab
                  style={[
                    styles.deleteButton,
                    Platform.OS === 'ios' ? styles.iosFab : {},
                    showFieldInfo && Platform.OS !== 'web' && styles.pusher,
                  ]}
                  label={translate({ text: 'dontSave' })}
                  icon="delete"
                  onPress={cancel}
                  color={'#000'}
                />
                <BottomFab
                  style={[
                    styles.saveButton,
                    Platform.OS === 'ios' ? styles.iosFab : {},
                    showFieldInfo && Platform.OS !== 'web' && styles.pusher,
                  ]}
                  label={translate({ text: 'save' })}
                  icon="check"
                  onPress={saveDrawing}
                  color={'#000'}
                />
              </>
            ) : (
              <>
                {currentScreen.showNextButton ? (
                  <BottomFab
                    testID="next"
                    style={[
                      styles.fab,
                      showFieldInfo && Platform.OS !== 'web' && styles.pusher,
                    ]}
                    label={translate({ text: nextLabel })}
                    icon="chevron-right"
                    onPress={index === 0 ? () => next(true) : next}
                    type="submit"
                    disabled={hasEmptyCropForGP}
                    loading={crud.creating}
                  />
                ) : null}
                {index === 0 && (
                  <BottomFab
                    style={{ marginBottom: 64, color: '#FFF' }}
                    label={translate({ text: 'newParcel' })}
                    icon="plus"
                    onPress={() => next(false)}
                    color={'#FFF'}
                  />
                )}
                {index === 1 && (
                  <>
                    {/* {((fieldArray && fieldArray.length > 1) ||
              (fieldArray &&
                fieldArray.length === 1 &&
                selectedFarmer.length === 0)
                ) && ( */}
                    {fieldArray && fieldArray.length > 1 && (
                      <BottomFab
                        style={[
                          styles.mergeButton,
                          Platform.OS !== 'web' ? styles.iosFab : {},
                          showFieldInfo &&
                            Platform.OS !== 'web' &&
                            styles.pusher,
                        ]}
                        label={
                          selectedFarmer.length === 0
                            ? translate({ text: 'mergeParcel' })
                            : translate({ text: 'mergeParcels' })
                        }
                        icon="link-plus"
                        onPress={mergeSelected}
                        color={'#000'}
                      />
                    )}
                    {Platform.OS === 'web' ? (
                      <BottomFab
                        style={[
                          styles.drawButton,
                          Platform.OS === 'ios' ? styles.iosFab : {},
                          showFieldInfo &&
                            Platform.OS !== 'web' &&
                            styles.pusher,
                        ]}
                        label={translate({ text: 'drawParcel' })}
                        icon="pencil"
                        onPress={saveDrawing}
                        color={'#000'}
                      />
                    ) : null}
                    {selectedFarmer.length === 0 ? (
                      <BottomFab
                        style={[
                          styles.noSelectedFarmer,
                          Platform.OS !== 'web' ? styles.iosFab : {},
                          showFieldInfo &&
                            Platform.OS !== 'web' &&
                            styles.pusher,
                        ]}
                        label={translate({ text: 'selectMultipleParcels' })}
                        icon="format-list-bulleted"
                        onPress={selectFarmer}
                        color={'#000'}
                      />
                    ) : (
                      <BottomFab
                        style={[
                          styles.noSelectedFarmer,
                          showFieldInfo &&
                            Platform.OS !== 'web' &&
                            styles.pusher,
                        ]}
                        label={selectedFarmer[0].name}
                        icon="account-group"
                        onPress={selectFarmer}
                        color={'#000'}
                      />
                    )}
                  </>
                )}
              </>
            )}
            <View style={styles.navigationFixer} />
          </>
        )}
      </Translator>
    </View>
  )
}

const styles = StyleSheet.create({
  navigationFixer: {
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    width: 25,
  },
  fab: {
    zIndex: 200,
  },
  noSelectedFarmer: {
    zIndex: 200,
    bottom: 110,
    backgroundColor: '#FFF',
  },
  drawButton: {
    zIndex: 200,
    bottom: 165,
    backgroundColor: '#FFF',
  },
  saveButton: {
    zIndex: 200,
    bottom: 15,
    backgroundColor: '#FFF',
  },
  deleteButton: {
    zIndex: 200,
    bottom: 90,
    backgroundColor: '#FFF',
  },
  mergeButton: {
    zIndex: 200,
    bottom: 170,
    backgroundColor: '#FFF',
  },
  iosFab: {
    marginBottom: 25,
  },
  pusher: {
    marginBottom: 125,
  },
})

const mapStateToProps = state => ({
  fieldArray:
    state.organizationField.crud.object &&
    state.organizationField.crud.object.fieldArray,
  customFieldArray:
    state.organizationField.crud.object &&
    state.organizationField.crud.object.customFieldArray,
  crud: state.organizationField.crud,
  selectedFarmer: state.filter.advisorFarmer.selected,
})
// TODO: Check with Ruben why dontLoadOnMount was not true
// Why was this call happening when creating a new organizationField?
export default detailHOC(Detail, mapStateToProps, actions, {
  optinEdit: true,
  enableDelete: true,
  disableSafe: true,
  dontLoadOnMount: true,
})
