import React, { useCallback } from 'react'
import Touchable from '../WidthAndHeight/Touchable'

// interface FarmerItem {
//   name: string
//   email: string
// }
// interface FarmerItemProps {
//   item: FarmerItem
//   componentId: string
//   onPress: (params: FarmerItem) => any
// }

export default function FarmerItem({ item, componentId, onPress, left }) {
  const description = [item.email].join(', ')

  const onPressInner = useCallback(() => {
    onPress(item)
  }, [onPress, item])
  return (
    <Touchable
      description={description}
      onPress={onPressInner}
      title={item.name}
      componentId={componentId}
      left={left}
    />
  )
}
