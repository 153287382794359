import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { Appbar } from 'react-native-paper'

import actions from './actions'
import PageHeader from '../../PageHeader'
import { pushCustomEvent } from '../../Analytics/abstraction'

import GeneralList from '../../GeneralListFunctions/GeneralList'
import Navigation from '../../Navigation'
import ItemInList from '../ItemInList'
import { Translator } from 'react-translated'

export const growingPlans = [
  { id: '2019', key: '2019', name: '2019' },
  { id: '2020', key: '2020', name: '2020' },
  { id: '2021', key: '2021', name: '2021' },
  { id: '2022', key: '2022', name: '2022' },
  { id: '2023', key: '2023', name: '2023' },
]

function GrowingPlanList(props) {
  const { componentId, title, select, selected, filterType } = props

  const onBack = useCallback(() => Navigation.pop(componentId), [componentId])

  const onSelect = useCallback(
    (item) => {
      select(item, { singleSelect: true })
      Navigation.pop(componentId)
      pushCustomEvent('Filter selected for', 'growingPlan',
        {
          selected: item.name,
          selectedId: item.id,
        },
      )
    },
    [componentId,select],
  )
  const onPressItem = useCallback(
    (item) => {
      onSelect(item)
    },
    [onSelect]
  )


  const pop = useCallback(() => {
    Navigation.pop(componentId)
  }, [componentId])

  const renderItem = useCallback(
    ({ item }) => (
      <ItemInList
        onPress={() => onPressItem(item)}
        onSelect={() => onPressItem(item)}
        item={item}
        filterType={filterType}
        isSelected={selected.some((it) => it.id === item.id)}
        // selectType="radiobutton"
      />
    ),
    [onPressItem, filterType, selected]
  )

  return (
    <Translator>
      {({ translate }) => (
        <GeneralList
          {...props}
          list={{
            data:growingPlans,
          }}
          silentReloadingOnMount={false}
          searchPlaceholder={translate({ text: 'searchGrowingPlan' })}
          renderItem={renderItem}
          icon
          appbarContent={
            <PageHeader
              componentId={componentId}
              back={onBack}
              title={<Appbar.Content title={title || translate({ text: 'growingPlans'})} />}
            />
          }
          onPressFab={pop}
        />
      )}
    </Translator>
  )
}

const mapStateToProps = (state) => ({
  selected: state.filter.growingPlan.selected,
})

export default connect(mapStateToProps, actions)(GrowingPlanList)
