import React from 'react'
import Svg from './SvgAbstraction'
import Path from './PathAbstraction'

import { color, defaultProps, propTypes } from './standardVector'

export const width = 24
export const height = 24

const Vector = props => (
  <Svg
    width={props.width || width}
    height={props.height || height}
    className={props.className}
    viewBox="0 0 24 24"
  >
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M9 17.25H2.25C1.85218 17.25 1.47064 17.092 1.18934 16.8107C0.908035 16.5294 0.75 16.1478 0.75 15.75V3.75C0.75 3.35218 0.908035 2.97064 1.18934 2.68934C1.47064 2.40804 1.85218 2.25 2.25 2.25H15.75C16.1478 2.25 16.5294 2.40804 16.8107 2.68934C17.092 2.97064 17.25 3.35218 17.25 3.75V8"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M0.75 6.75H17.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M5.25 3.75V0.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M12.75 3.75V0.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M17.1746 20.2548C16.9675 20.2548 16.7996 20.0869 16.7996 19.8798C16.7996 19.6727 16.9675 19.5048 17.1746 19.5048"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M17.1746 20.2548C17.3817 20.2548 17.5496 20.0869 17.5496 19.8798C17.5496 19.6727 17.3817 19.5048 17.1746 19.5048"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M17.241 17.2498V14.9998"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M23.0509 20.6798C23.189 20.9495 23.2559 21.2499 23.2454 21.5526C23.2349 21.8553 23.1473 22.1504 22.9909 22.4098C22.8299 22.6652 22.6072 22.8761 22.3433 23.0228C22.0795 23.1696 21.7829 23.2477 21.4809 23.2498H12.9909C12.689 23.2477 12.3924 23.1696 12.1286 23.0228C11.8647 22.8761 11.642 22.6652 11.4809 22.4098C11.323 22.1512 11.2337 21.8566 11.2214 21.5539C11.2092 21.2512 11.2744 20.9503 11.4109 20.6798L15.6309 12.2298C15.7792 11.9352 16.0064 11.6876 16.2871 11.5146C16.5679 11.3416 16.8912 11.25 17.2209 11.25C17.5507 11.25 17.874 11.3416 18.1548 11.5146C18.4355 11.6876 18.6627 11.9352 18.8109 12.2298L23.0509 20.6798Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </Svg>
)

Vector.propTypes = propTypes
Vector.defaultProps = defaultProps

export default Vector
