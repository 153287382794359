import { call, put, takeLatest, select } from 'redux-saga/effects'
import { uniqBy } from 'lodash'
import { fetchListener } from 'react-auth/lib/sagas/authentication'
import saga from '../../GeneralListFunctions/sagaFactory'
import a from './actions'

function* load() {
  try {
    const response = yield call(fetchListener, {
      path: 'distributor',
      method: 'GET',
    })

    if (response) {
      yield put(a.loaded(response))
    }
  } catch (e) {
    // alert('Er gaat iets fout bij het opslaan van geselecteerde producten')
    yield put(a.loaded(e))
  }
}

function* portalUpdate() {
  try {
    const distributedProducts = yield select(
      (state) => state.distribution.distribution.select.distributedProducts
    )
    const withOutAdmissionNumber = distributedProducts.filter(
      (it) => it.admissionNumber === '' || !it.admissionNumber
    )
    const withAdmissionNumber = distributedProducts.filter(
      (it) => it.admissionNumber && it.admissionNumber !== ''
    )

    const response = yield call(fetchListener, {
      path: 'distributor',
      method: 'PUT',
      body: {
        distributedProducts: [
          ...uniqBy(withAdmissionNumber, 'admissionNumber'),
          ...withOutAdmissionNumber,
        ],
      },
    })

    yield put(a.confirmed(response))
  } catch (e) {
    alert('Er gaat iets fout bij het laden van geselecteerde producten')
    yield put(a.confirmed(e))
  }
}

export default function* rootSaga() {
  yield takeLatest(a.CONFIRM, portalUpdate)
  yield takeLatest(a.LOAD, load)
}

export const selectDistributedProductList = () =>
  saga(
    a,
    () => 'distributor',
    (state) => state.distribution.distribution
  )
