import React, { useCallback, useEffect, useState } from 'react'
import { Alert, Keyboard, Platform } from 'react-native'
import FieldSelectScreen from './FieldSelectScreen'
import GrowingPeriodSelectScreen from './GrowingPeriodSelectScreen'
import ObservationEdit from './ObservationEdit'
import Navigation from '../../Navigation'
import { Translate } from 'react-translated'
import { useDispatch, useSelector } from 'react-redux'
import a from '../actions'
import { FAB, Portal } from 'react-native-paper'
import safeAreaHOC from '../../WidthAndHeight/safeAreaHOC'
import dayjs from 'dayjs'

const getData = (componentId, onlyLast) => {
  return onlyLast
    ? [
        {
          key: 'ObservationEdit',
          title: <Translate text="observationEdit" />,
          isLast: true,
          componentId: componentId,
          showNextButton: true,
          component: ObservationEdit,
        },
      ].filter(n => n)
    : [
        {
          key: 'information',
          title: <Translate text="parcels" />,
          isLast: false,
          componentId: componentId,
          showNextButton: true,
          component: FieldSelectScreen,
        },
        {
          key: 'GrowingPeriodSelectScreen',
          title: <Translate text="growingPeriods" />,
          isLast: false,
          component: GrowingPeriodSelectScreen,
          componentId: componentId,
          showNextButton: true,
        },
        {
          key: 'ObservationEdit',
          title: <Translate text="observationEdit" />,
          component: ObservationEdit,
          isLast: true,
          componentId: componentId,
          showNextButton: true,
        },
      ].filter(n => n)
}

function Detail({ componentId, id, safe, step }) {
  const [index, setIndex] = useState(0)
  const crud = useSelector(state => state.observation.crud)

  const swiper = React.createRef()
  const data = step ? getData(componentId, true) : getData(componentId)
  const dispatch = useDispatch()
  useEffect(() => {
    if (id) {
      dispatch(a.load(id))
    }
  }, [id, dispatch])

  const pop = useCallback(() => {
    Navigation.pop(componentId)
  }, [componentId])

  const prev = useCallback(() => {
    Keyboard.dismiss()
    if (index === 0) {
      pop()
      return
    } else if (id) {
      pop()
      return
    }
    setIndex(index - 1)
  }, [index, pop, setIndex, id])

  const remove = useCallback(() => {
    Keyboard.dismiss()
    if (Platform.OS === 'web') {
      if (window.confirm('Are you sure you want to delete this observation?')) {
        dispatch(a.remove(id))
        pop()
      }
    } else {
      Alert.alert(
        'Delete observation',
        'Are you sure you want to delete this observation?',
        [
          {
            text: 'Cancel',
            style: 'cancel',
          },
          {
            text: 'OK',
            onPress: () => {
              dispatch(a.remove(id))
              pop()
            },
          },
        ]
      )
    }
  }, [dispatch, id, pop])

  const to = useCallback(
    index => {
      setIndex(index)
    },
    [setIndex]
  )

  const save = useCallback(() => {
    if (crud.object && crud.object.id) {
      dispatch(
        a.update({
          extraBody: {
            ...crud.object,
            date: crud.object.date
              ? Math.round(dayjs(crud.object.date).unix())
              : Math.round(dayjs(new Date()).unix()),
          },
        })
      )
    } else {
      dispatch(
        a.create({
          ...crud.object,
          date: crud.object.date
            ? Math.round(dayjs(crud.object.date).unix())
            : Math.round(dayjs(new Date()).unix()),
        })
      )
    }
  }, [crud.object, dispatch])

  const next = useCallback(() => {
    Keyboard.dismiss()
    const isLast = data.length - 1 === index

    if (isLast) {
      save()
    } else {
      setIndex(index + 1)
    }
  }, [data.length, index, save])

  const currentScreen = (data && data[index]) || {}
  const isLast = data.length - 1 === index

  return (
    <>
      {data.map((step, i) => {
        return i === index ? (
          <step.component
            key={step.key}
            componentId={componentId}
            title={step.title}
            showNextButton={step.showNextButton}
            data={data}
            ref={swiper}
            index={index}
            remove={remove}
            isLast={data.length - 1 === index}
            onIndexChange={to}
            pop={pop}
            currentScreen={currentScreen}
            next={next}
            prev={prev}
          />
        ) : null
      })}
      <Portal key={'portal'}>
        <FAB
          onPress={next}
          icon={!isLast ? 'chevron-right' : 'check'}
          loading={crud?.creating || crud?.updating}
          style={{
            position: 'absolute',
            bottom: safe.bottom + 16,
            right: safe.right + 16,
          }}
        />
      </Portal>
    </>
  )
}

export default safeAreaHOC(Detail)
