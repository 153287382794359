import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { push } from '../Navigation'
import { COMPARISON_ROUTE } from '../Routes.config'

import PageHeader from '../PageHeader'
import DeepLink from '../DeepLink'
import ProductsList from './ProductsList'
import { Translator } from 'react-translated'
import { hasRole } from '../helpers/role'

function ProductsListScreen({ componentId, ...rest }) {
  const selected = useSelector(state => state.products.selected)
  const onPressFab = useCallback(() => {
    const ids = selected
      .map(it => it.id)
      .filter(n => n)
      .join(',')

    push(componentId, {
      component: {
        name: COMPARISON_ROUTE,
        passProps: {
          ids,
        },
      },
    })
  }, [selected, componentId])

  // const back = useCallback(() => {
  //   push(componentId, {
  //     component: {
  //       name: routes.HOME_ROUTE,
  //     },
  //   })
  // }, [componentId])

  const appbarContent = useMemo(
    () => <PageHeader componentId={componentId} />,
    [componentId]
  )

  const user = useSelector(state => state?.auth?.user?.user)
  const isFarmer = hasRole(user, 'farmer')
  const isAdmin = hasRole(user, 'admin')
  return (
    <>
      <Translator>
        {({ translate }) => (
          <ProductsList
            componentId={componentId}
            onPressFab={onPressFab}
            appbarContent={appbarContent}
            onlyShowFabWhenSelected
            fabLabel={translate({ text: 'compare' })}
            context={isFarmer || isAdmin ? 'farmer-product' : 'product'}
          />
        )}
      </Translator>
      <DeepLink loggedIn={true} componentId={componentId} />
    </>
  )
}

export default React.memo(ProductsListScreen)
