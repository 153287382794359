import React from 'react'
import Svg from './SvgAbstraction'
import Path from './PathAbstraction'

import { color, defaultProps, propTypes } from './standardVector'

export const width = 24
export const height = 24

const Vector = props => (
  <Svg
    width={props.width || width}
    height={props.height || height}
    className={props.className}
    viewBox="0 0 24 24"
  >
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M.75,2.217l1.5,1.892V8.576c-.04,1.688,1.917,3.2,3.749,4.752"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M5.231,7.358,7,9.17a1.218,1.218,0,1,0,1.745-1.7L5.994,4.663V3.2A6.286,6.286,0,0,0,5.016.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M6,8.138V20.25a1.5,1.5,0,0,0,1.5,1.5h6.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M23.25,21.782l-1.5-1.892V15.424c.035-1.459-1.421-2.786-3-4.12"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M9.75,3.75h7.5a1.5,1.5,0,0,1,1.5,1.5V16.62L17,14.83a1.218,1.218,0,1,0-1.745,1.7l2.755,2.808V20.8a6.293,6.293,0,0,0,.978,2.446"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </Svg>
)

Vector.propTypes = propTypes
Vector.defaultProps = defaultProps

export default Vector
