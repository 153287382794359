import { Alert, PermissionsAndroid, Platform } from 'react-native'
import { call, put, takeLatest } from 'redux-saga/effects'

import Geolocation from 'react-native-geolocation-service'
import * as a from './actions'

const getUserLocation = () =>
  Platform.OS === 'android'
    ? new Promise((resolve, reject) => {
        PermissionsAndroid.request(
          PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION
        )
          .then(() => {
            Geolocation.getCurrentPosition(
              location => resolve(location),
              error => reject(error),
              {
                enableHighAccuracy: false,
              }
            )
          })
          .catch(error => {
            reject(error)
          })
      })
    : new Promise((resolve, reject) => {
        Geolocation.getCurrentPosition(
          location => resolve(location),
          error => reject(error),
          {
            enableHighAccuracy: false,
          }
        )
      })
function* loadMyLocation() {
  // console.log('loadMyLocation')
  try {
    console.log('START LOADING POSITION')
    const position = yield call(getUserLocation)
    console.log('STOP LOADING POSITION')

    console.log({ position })
    if (
      position &&
      position.coords &&
      position.coords.longitude &&
      position.coords.latitude
    ) {
      const coordinates = [position.coords.longitude, position.coords.latitude]

      yield put(a.loadedMyLocation(coordinates))
    }
  } catch (e) {
    const alertTitle = 'Foutmelding'
    const alertText = 'Geen toegang tot locatiedata'
    if (Platform.OS === 'web') {
      // alert(alertText)
    } else {
      console.log(e)
      Alert.alert(alertTitle, alertText)
    }
    yield put(a.loadedMyLocation(e, null, true))
  }
}

export default function* saga() {
  // // fix faster load of current location..
  // if (Platform.OS === 'web') {
  //   yield call(loadMyLocation)
  // }
  yield takeLatest(a.LOAD_MY_LOCATION, loadMyLocation)
}
