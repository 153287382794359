import React, { useCallback, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { Text, Title } from 'react-native-paper'
import FieldsOrganizationMap from './FieldsOrganizationMap'
import Navigation from '../Navigation'
import SimpleSwipableModal from '../Notifications/SimpleSwipableModal'
import RouteButton from './RouteButton'
import { getLongitudeAndLatitudeFromField } from '../Map/helpers'
import round from '../helpers/round'
import { groupBy } from 'lodash'
import { Translate } from 'react-translated'

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
  },
  spacer: { flex: 1 },
})

function FieldsOrganizationMapScreen({ organizationId, componentId }) {
  const [isVisible, setIsVisible] = useState(false)
  const [selectedField, setSelectedField] = useState(undefined)
  const onClose = useCallback(() => setIsVisible(false), [setIsVisible])
  const onPressField = useCallback(
    field => {
      setIsVisible(true)
      setSelectedField(field)
    },
    [setIsVisible, setSelectedField]
  )

  const onHeaderIconPress = useCallback(() => {
    Navigation.pop()
  }, [])

  let totalArea =
    selectedField && selectedField.field
      ? parseFloat(selectedField.field.area)
      : undefined
  if (
    selectedField &&
    selectedField.customField &&
    selectedField.customField.fields
  ) {
    console.log(selectedField)
    selectedField.customField.fields.forEach(it => {
      totalArea = totalArea
        ? totalArea + parseFloat(it.area)
        : parseFloat(it.area)
    })
  }

  const grouped =
    selectedField &&
    selectedField.customField &&
    groupBy(selectedField.customField.soilResearches, 'type')

  const allCrops =
    selectedField &&
    selectedField.growingPeriods &&
    selectedField.growingPeriods.map(gp => gp.crop.name).join(', ')
  console.log({ allCrops })
  return (
    <>
      <FieldsOrganizationMap
        onPressField={onPressField}
        componentId={componentId}
        onHeaderIconPress={onHeaderIconPress}
        headerIcon={'arrow-left'}
        organizationId={organizationId}
      />

      <SimpleSwipableModal isVisible={isVisible} onRequestClose={onClose}>
        {((selectedField && selectedField.field) ||
          (selectedField &&
            selectedField.customField &&
            selectedField.customField.fields.length > 0)) && (
          <>
            <View style={styles.root}>
              <Title style={{ flex: 2 }}>
                {selectedField.name !== ''
                  ? selectedField.name
                  : `Teeltlocatie #${selectedField.id}`}
              </Title>
              <RouteButton
                {...getLongitudeAndLatitudeFromField(
                  selectedField.entrances,
                  selectedField.field ||
                    (selectedField.customField &&
                      selectedField.customField.fields[0])
                )}
                title={
                  selectedField.name !== ''
                    ? selectedField.name
                    : `Teeltlocatie #${selectedField.id}`
                }
              />
            </View>
            <View>
              <Text style={{ fontWeight: 'bold' }}>
                <Translate text="area" />
              </Text>
            </View>
            <View>
              <Text>{round(totalArea / 10000, 3)} ha</Text>
            </View>
            {selectedField && selectedField.organization && (
              <>
                <View>
                  <Text style={{ fontWeight: 'bold' }}>
                    <Translate text="teler" />
                  </Text>
                </View>
                <View>
                  <Text>{selectedField.organization.name}</Text>
                </View>
              </>
            )}
            {allCrops !== undefined && allCrops !== '' && (
              <>
                <View>
                  <Text style={{ fontWeight: 'bold' }}>
                    <Translate text="cropsSideMenu" />
                  </Text>
                </View>
                <View>
                  <Text>{allCrops}</Text>
                </View>
              </>
            )}
            {selectedField &&
              selectedField.growingPeriods &&
              selectedField.growingPeriods.length > 0 && (
                <>
                  <View>
                    <Text style={{ fontWeight: 'bold' }}>
                      <Translate text="contract" />
                    </Text>
                  </View>
                  <View>
                    <Text>
                      {
                        selectedField?.growingPeriods?.find(
                          gp => gp?.contract?.id
                        )?.contract?.number
                      }
                    </Text>
                  </View>
                </>
              )}
            {selectedField &&
              selectedField.customField &&
              selectedField.customField.soilResearches &&
              selectedField.customField.soilResearches.length > 0 && (
                <View style={{ marginTop: 10 }}>
                  <Text style={{ fontWeight: 'bold' }}>
                    <Translate text={'soilResearches'} />
                  </Text>
                </View>
              )}
            {selectedField &&
              selectedField.customField &&
              selectedField.customField.soilResearches &&
              selectedField.customField.soilResearches.map(sr => {
                const latestResearch = grouped[sr.type].reduce(
                  (prev, current) => {
                    return prev.registrationDate > current.registrationDate
                      ? prev
                      : current
                  }
                )
                return (
                  <View style={{ marginBottom: 5 }}>
                    <Text>
                      {latestResearch.type}: {latestResearch.value}{' '}
                      {latestResearch.unit}
                    </Text>
                  </View>
                )
              })}
          </>
        )}
      </SimpleSwipableModal>
    </>
  )
}
export default FieldsOrganizationMapScreen
