export const attributes = language => [
  {
    label: language === 'nl' ? 'Dosering' : 'Dosage',
    parse: (d = {}) => {
      console.log({ d })
      let value
      if (d.dosageMax && d.dosageMin) {
        value = `${d.dosageMin} - ${d.dosageMax}`
      } else if (d.dosageMin) {
        value = `min. ${d.dosageMin}`
      } else if (d.dosageMax) {
        value = `max. ${d.dosageMax}`
      }
      if (value && d.dosageMaxUnit && d.dosageMaxUnit) {
        value = `${value} ${d.dosageMaxUnit}`
      }
      if (value && d.mixingAdvice) {
        value += ` (${d.mixingAdvice})`
      } else if (!value && d.mixingAdvice) {
        value = d.mixingAdvice
      }

      return {
        hasValue: !!(d.dosageMin || d.dosageMax || d.mixingAdvice),
        value,
      }
    },
  },
  {
    label: language === 'nl' ? 'Toepassingswijze' : 'Application method',
    parse: (d = {}) => {
      let value = '-'

      if (d.applicationManners) {
        value = d.applicationManners
          .map(item => item.manner.replace(/\\n/g, '\n'))
          .join('\n\n')
      }
      return {
        hasValue: !!d.applicationManners,
        value,
      }
    },
  },
  {
    label: language === 'nl' ? 'Toepassingsmoment' : 'Application moment',
    parse: (d = {}) => {
      let value = '-'
      if (d.applicationMoments) {
        value = d.applicationMoments
          .map(item => item.moment.replace(/\\n/g, '\n'))
          .join('\n\n')
      }

      return {
        hasValue: !!d.applicationMoments,
        value,
      }
    },
  },
  {
    label: language === 'nl' ? 'Notities' : 'Notes',
    parse: (d = {}) => {
      let value = '-'
      if (d.notes) {
        value = d.notes.map(item => {
          let note = item.note
          note = note.replace(/\\"/g, '"')
          note = note.replace(/\\n/g, '\n')
          note = note.replace(/\\t/g, '\t')
          note += '\n\n'

          return note
        })
      }

      return {
        hasValue: !!d.notes,
        value,
      }
    },
  },

  {
    label: language === 'nl' ? 'Toepassingen' : 'Applications',
    parse: (d = {}) => {
      let value
      if (d.maxApplications) {
        value = `max. ${d.maxApplications}`
      }
      if (value && d.maxApplicationsUnit) {
        value = `${value} ${d.maxApplicationsUnit}`
      }
      return {
        hasValue: !!d.maxApplications,
        value,
      }
    },
  },
  {
    label: language === 'nl' ? 'Limiet' : 'Limit',
    parse: (d = {}) => {
      let value
      if (d.maxAmountProduct) {
        value = `max. ${d.maxAmountProduct}`
      }
      if (value && d.maxAmountProductUnit) {
        value = `${value} ${d.maxAmountProductUnit}`
      }
      return {
        hasValue: !!d.maxAmountProduct,
        value,
      }
    },
  },
  {
    label: language === 'nl' ? 'Periodieke limiet' : 'Periodic limit',
    parse: (d = {}) => {
      let value
      if (d.maxAmountProductPeriod) {
        value = `max. ${d.maxAmountProductPeriod}`
      }
      if (value && d.maxAmountProductPeriodUnit) {
        value = `${value} ${d.maxAmountProductPeriodUnit}`
      }
      return {
        hasValue: !!d.maxAmountProductPeriod,
        value,
      }
    },
  },
  {
    label: 'Interval',
    parse: (d = {}) => {
      let value
      if (d.minIntervalApplication) {
        value = d.minIntervalApplication
      }
      if (value && d.intervalApplicationUnit) {
        value = `${value} ${d.intervalApplicationUnit}`
      }
      return {
        hasValue: !!d.minIntervalApplication,
        value,
      }
    },
  },
  {
    label: language === 'nl' ? 'Veiligheidstermijn' : 'Safety period',
    parse: (d = {}) => {
      let value
      if (d.safetyPeriod) {
        value = d.safetyPeriod
      }
      if (value && d.safetyPeriodUnit) {
        value = `${value} ${d.safetyPeriodUnit}`
      }
      return {
        hasValue: !!d.safetyPeriod,
        value,
      }
    },
  },
]
