import React from 'react'
import { useSafeArea } from './safeAreaHook'

export default (WrappedComponent) => {
  function HOC(props) {
    const { safe, safePadding, safeMargin } = useSafeArea()
    return (
      <WrappedComponent
        {...props}
        safe={safe}
        safePadding={safePadding}
        safeMargin={safeMargin}
      />
    )
  }
  return HOC
}
