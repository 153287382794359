import React, { useCallback, useEffect, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import * as kvkActions from './kvk/actions'
import actions from './actions'
import Fields from '../../Form/Fields'
import FormContainer from '../../Form/FormContainer'
import Touchable from '../../WidthAndHeight/Touchable'

import safeAreaHOC from '../../WidthAndHeight/safeAreaHOC'
import { ActivityIndicator, Appbar, Button, Snackbar } from 'react-native-paper'
import KeyboardAvoidingScrollView from '../../WidthAndHeight/KeyboardAvoidingScrollView'
import AppbarHeader from '../../WidthAndHeight/AppbarHeader'
import AppbarResizer from '../../WidthAndHeight/AppbarResizer'
import Navigation from '../../Navigation'
import BottomFab from '../../WidthAndHeight/BottomFab'
import { useDispatch, useSelector } from 'react-redux'
import List from '../../GeneralListFunctions/List'
import SafeListItem from '../../WidthAndHeight/SafeListItem'
import SelectionComponent from '../../components/SelectionComponent'
import { organizationType } from '../../FilterType/OrganizationType/OrganizationTypeList'
import { Translate, Translator } from 'react-translated'
import { get, set } from 'lodash'

const fieldProps = {
  style: { marginBottom: 16 },
}
const fields = [
  {
    name: 'name',
    label: <Translate text="name" />,
    type: 'text',
    fieldProps,
  },
]
const identificationField = [
  {
    name: 'identificationNumber',
    label: <Translate text="identificationNumber" />,
    type: 'number',
    fieldProps,
  },
]

function FarmerDetail({ onBack, componentId, safePadding, onCreated }) {
  const dispatch = useDispatch()
  const title = <Translate text="addCompany" />
  const [search, setSearch] = useState('')
  const { object, loading, updating, creating, removing, created } =
    useSelector(state => state.farmer.organization.crud)
  const searchList = useSelector(state => state.farmer.kvk.list)

  // Use searchref to fix stupid closure problem...
  const searchRef = React.useRef([])
  useEffect(() => {
    searchRef.current = search
    return () => {}
  }, [search])

  const onInnerBack = useCallback(() => {
    if (onBack) {
      onBack()
    } else {
      Navigation.pop(componentId)
    }
  }, [onBack, componentId])
  const change = useCallback(
    (name, value) => {
      let innerObj = object || {}
      if (name.includes('.')) {
        set(innerObj, name, value)
        dispatch(actions.setData(innerObj))
      } else {
        dispatch(actions.setData({ ...innerObj, [name]: value }))
      }
    },
    [dispatch, object]
  )

  const onSearch = () => {
    // Use searchref to fix stupid closure problem...
    dispatch(
      kvkActions.default.loadList({
        params: {
          name: searchRef.current || '',
          // onlyUserManaged: false,
          sort: 'name',
        },
      })
    )
  }

  useEffect(() => {
    if (!creating && created) {
      onCreated()
    }
  }, [created, creating, onCreated])

  const debouncedInput = useCallback(
    (name, value) => {
      setSearch(value)
      change(name, value)
    },
    [change]
  )
  const [validationError, setValidationError] = React.useState(false)

  const createItem = useCallback(
    item => {
      const all = { ...object, ...item }
      const bool = checkRequired(['name'], all)
      if (bool.some(it => it)) {
        console.log('VALI ERR')
        setValidationError(true)
      } else {
        console.log('creting')
        const meta =
          object &&
          object.settings &&
          object.settings.type &&
          (object.settings.type === 'contractor' ||
            object.settings.type === 'transporter')
            ? {
                inheritManaged: true,
              }
            : undefined

        dispatch(
          actions.create({
            item: all,
            meta,
          })
        )
      }
    },
    [object, dispatch]
  )
  const checkRequired = (checkArr, values) => {
    return checkArr.map(it => {
      return !get(values, it)
    })
  }

  const renderItem = useCallback(
    ({ item }) => {
      return (
        <Touchable
          key={item.kvkNumber}
          description={item.kvkNumber}
          title={item.tradeName}
          componentId={componentId}
          onPress={() => createItem(item)}
        />
      )
    },
    [componentId, createItem]
  )
  const [selected, setSelected] = useState('farmer')
  const [initialized, setInitialized] = useState(false)
  React.useEffect(() => {
    if (!initialized) {
      setInitialized(true)
      change('settings.type', 'farmer')
    }
  }, [change, initialized])
  const select = useCallback(
    it => {
      change('settings.type', it.key)
      setSelected(it.key)
    },
    [change]
  )
  const submit = useCallback(() => {
    createItem(object)
  }, [createItem, object])

  console.log({
    object,
    issu: checkRequired(['identificationNumber', 'name'], object),
  })
  return [
    <>
      <AppbarHeader
        key="header"
        dark
        style={{ elevation: 0, justifyContent: 'center' }}
      >
        <AppbarResizer>
          <Appbar.BackAction onPress={onInnerBack} color="#fff" />
          <Appbar.Content title={title} color="#fff" />

          {object && object.id && !loading ? (
            <Appbar.Action
              icon={'delete'}
              onPress={() => dispatch(actions.remove(object.id))}
              color="#fff"
            />
          ) : null}
          {loading || updating || removing || creating ? (
            <ActivityIndicator style={{ padding: 12 }} color={'#FFF'} />
          ) : null}
        </AppbarResizer>
      </AppbarHeader>
      <View
        style={[
          safePadding,
          { backgroundColor: '#fff', zIndex: 100, flex: 1, height: 600 },
        ]}
      >
        {organizationType.map(item => {
          return (
            <SafeListItem
              key={item.key}
              title={item.name}
              onPress={() => select(item)}
              left={
                <SelectionComponent
                  status={selected === item.key ? 'checked' : 'unchecked'}
                  onPress={() => select(item)}
                  withPadding
                  selectType={'radiobutton'}
                />
              }
            />
          )
        })}
        <KeyboardAvoidingScrollView>
          <View style={{ maxHeight: 500, height: 500 }}>
            <View style={styles.innerScroll}>
              <FormContainer onSubmit={onSearch}>
                {/* <View style={{ height: 56 }} /> */}
                <Fields
                  fields={fields}
                  values={object}
                  onChange={debouncedInput}
                  onSubmit={onSearch}
                />
                {search !== '' && (
                  <View>
                    <Button
                      icon="magnify"
                      onPress={onSearch}
                      uppercase={false}
                      color="#000"
                      loading={searchList.loadingList}
                    >
                      <Translate text={'searchKvK'} />
                    </Button>
                  </View>
                )}
              </FormContainer>
            </View>
            {(searchList.loadedList || searchList.loadingListError) && (
              <View>
                <List
                  list={searchList}
                  data={searchList.data}
                  withoutVirtualization
                  renderItem={renderItem}
                  customEmptyDescription={<Translate text={'noResultsDesc'} />}
                  customEmptyTitle={<Translate text={'noResults'} />}
                  smallEmpty
                />
                {((searchList.data.length === 0 && searchList.loadedList) ||
                  searchList.loadingListError) && (
                  <Fields
                    fields={identificationField}
                    values={object}
                    onChange={debouncedInput}
                    onSubmit={submit}
                  />
                )}
              </View>
            )}
          </View>
          <Translator>
            {({ translate }) => (
              <BottomFab
                icon={'check'}
                label={translate({ text: 'addWithoutKvKSearch' })}
                onPress={submit}
                disabled={
                  !(
                    (searchList.data.length === 0 && searchList.loadedList) ||
                    searchList.loadingListError
                  ) ||
                  checkRequired(['identificationNumber', 'name'], object).some(
                    it => !!it
                  )
                }
              />
            )}
          </Translator>
        </KeyboardAvoidingScrollView>
      </View>
    </>,

    <Snackbar
      key="snackbar"
      visible={validationError}
      onDismiss={() => setValidationError(false)}
      action={{
        label: 'OK',
        onPress: () => setValidationError(false),
      }}
      duration={3000}
      style={{
        zIndex: 9999999,
        marginBottom: 32,
      }}
    >
      <Translate text="validationError" />
    </Snackbar>,
  ]
}

const styles = StyleSheet.create({
  innerScroll: {
    maxWidth: 600,
    alignSelf: 'center',
    width: '100%',
  },
  bottomFab: {},
})

export default safeAreaHOC(FarmerDetail)
