import React, { useCallback, useEffect, useState } from 'react'
import { Alert, Keyboard, Platform, StyleSheet, View } from 'react-native'
import actions from '../actions'
import gpActions from './GrowingPeriod/actions'
import SectionScreen from './LocationScreen'
import Swiper from '../../components/Swiper'
import Navigation, { push } from '../../Navigation'
import detailHOC from '../../GeneralListFunctions/detailHOC'
import BottomFab from '../../WidthAndHeight/BottomFab'
import { Translate, Translator } from 'react-translated'
import SectionSubDivisions from './LocationSubDivisions'
import SelectSubdivisions from './SelectSubdivisions'
import AddCrops from './AddCrops'
import { useDispatch } from 'react-redux'
import { FILTER_MODAL_ROUTE } from '../../Routes.config'
import { hasRole } from '../../helpers/role'

let showDrawMenuOutOfComponent = false
let saveOutOfComponent = false
let mergedFieldOutOfComponent = undefined
const getData = (showFieldInfo, setShowFieldInfo) =>
  [
    {
      key: 'address',
      title: <Translate text="section" />,
      component: SectionScreen,
      showNextButton: true,
      showDrawMenu: showDrawMenuOutOfComponent,
      save: saveOutOfComponent,
      mergedField: mergedFieldOutOfComponent,
      showFieldInfo,
      setShowFieldInfo,
    },
    {
      key: 'subDivisions',
      title: <Translate text="parcels" />,
      component: SectionSubDivisions,
      showNextButton: true,
    },
    {
      key: 'selectSubDivision',
      title: <Translate text="parcels" />,
      component: SelectSubdivisions,
      showNextButton: true,
      showAddCropsDirectly: true,
    },
    // {
    //   key: 'subDivisionsOnMap',
    //   title: <Translate text="drawOnMap" />,
    //   component: SubDivisionsOnMap,
    //   showNextButton: true,
    // },
  ].filter(n => n)

function Detail({
  onRemove,
  crud,
  componentId,
  onSubmit,
  selectedFarmer,
  user,
  gpCrud,
}) {
  const [index, setIndex] = useState(0)
  const swiper = React.createRef()
  const [showFieldInfo, setShowFieldInfo] = useState(false)
  const data = getData(showFieldInfo, setShowFieldInfo)
  const [showDrawMenu, setShowDrawMenu] = useState(false)

  const pop = useCallback(() => {
    Navigation.pop(componentId)
  }, [componentId])

  const prev = useCallback(() => {
    Keyboard.dismiss()
    if (index === 0) {
      pop()
      return
    }
    setIndex(index - 1)
  }, [index, pop, setIndex])

  const to = useCallback(
    index => {
      setIndex(index)
    },
    [setIndex]
  )

  const next = useCallback(() => {
    setShowFieldInfo(false)
    Keyboard.dismiss()
    const isLast = data.length - 1 === index

    console.log('Next called, ', isLast, index, data.length)
    if (index === 1) {
      console.log(
        'here we will post the organization field with its subdivisions'
      )
      // let newObject = crud.object
      onSubmit()
    } else {
      if (isLast) {
        Navigation.pop(componentId)
      } else {
        setIndex(index + 1)
      }
    }
  }, [data.length, index, onSubmit, componentId])

  const [hasCreated, setHasCreated] = useState(false)
  useEffect(() => {
    if (crud?.created && !hasCreated) {
      console.log('SETTING INDEX + 1 and hasCreated to true')
      setHasCreated(true)
      setIndex(index + 1)
    }
  }, [crud, index, hasCreated, setHasCreated])

  console.log({ index })
  const saveDrawing = useCallback(() => {
    if (showDrawMenu) {
      saveOutOfComponent = true
      showDrawMenuOutOfComponent = false
      setShowDrawMenu(false)
    } else {
      showDrawMenuOutOfComponent = true
      setShowDrawMenu(true)
      saveOutOfComponent = false
    }
  }, [showDrawMenu])

  const cancel = useCallback(() => {
    showDrawMenuOutOfComponent = false
    setShowDrawMenu(false)
  }, [])

  let nextLabel = 'saveAndQuit'
  if (data[index + 1]) {
    nextLabel = 'next'
  }
  const currentScreen = (data && data[index]) || {}

  const hasEmptyCropForGP =
    ((!crud?.object?.sections ||
      crud?.object?.sections?.length === 0 ||
      crud?.object?.sections?.some(section => !section?.unit?.name)) &&
      currentScreen.key === 'subDivisions') ||
    (currentScreen.key === 'address' &&
      (!crud?.object?.coordinates || crud?.object?.coordinates?.length === 0))
  const [showCrops, setShowCrops] = useState(false)
  const dispatch = useDispatch()
  const selectFarmer = useCallback(() => {
    Keyboard.dismiss()
    // DO FARMER SCREEN HERE
    push(componentId, {
      component: {
        name:
          Platform.OS === 'web'
            ? FILTER_MODAL_ROUTE.replace(':context', 'field')
            : FILTER_MODAL_ROUTE,
        passProps: {
          activeId: 'farmer',
        },
      },
    })
  }, [componentId])
  const isWholesaler = hasRole(user, 'wholesaler')
  useEffect(() => {
    if (gpCrud?.created) {
      setShowCrops(false)
      dispatch(gpActions.reset())
    }
  }, [gpCrud, dispatch])
  return (
    <View style={{ flex: 1 }}>
      <Translator>
        {({ translate }) => (
          <>
            {showCrops ? (
              <AddCrops
                isLoading={gpCrud?.creating}
                onFinish={(growingPeriods, onlyBack) => {
                  if (onlyBack) {
                    dispatch(
                      actions.setData({
                        ...crud.object,
                        selectedSections: [],
                      })
                    )
                    setShowCrops(false)
                    return
                  }
                  const notSelectedSections = (
                    crud.object.sections || []
                  ).filter(
                    section =>
                      !crud.object.selectedSections.some(
                        selSection => selSection.id === section.id
                      )
                  )
                  const growingPeriodsWithIDButShouldBePostedBecauseOtherSectionsWithThisGrowingPeriodIDAreNotSelected =
                    growingPeriods.filter(
                      newGp =>
                        notSelectedSections?.some(section =>
                          section?.growingPeriods?.some(
                            gp => newGp.id === gp.id
                          )
                        ) && newGp.id
                    )

                  const allSectionsWithThatGrowingPeriod =
                    notSelectedSections?.filter(section =>
                      section?.growingPeriods?.some(gp2 =>
                        growingPeriodsWithIDButShouldBePostedBecauseOtherSectionsWithThisGrowingPeriodIDAreNotSelected.some(
                          gp => gp.id === gp2.id
                        )
                      )
                    )

                  dispatch(
                    gpActions.create({
                      growingPeriods: growingPeriods.map(gp => {
                        return {
                          ...gp,
                          certifications: [
                            ...new Set([
                              ...(gp?.certifications || []),
                              gp?.demeter ? 'DEMETER' : undefined,
                              gp?.skal ? 'SKAL' : undefined,
                              gp?.mps ? 'MPS' : undefined,
                              gp?.planetProof ? 'PLANETPROOF' : undefined,
                            ]),
                          ].filter(n => n),

                          startDate: Math.round(gp?.startDate / 1000),
                          endDate: Math.round(gp?.endDate / 1000),
                          organizationField: {
                            // id: crud.object.id,
                            sections: crud.object.selectedSections.map(
                              section => {
                                return {
                                  id: section.id,
                                }
                              }
                            ),
                          },
                        }
                      }),
                      growingPeriodsWithIDButShouldBePostedBecauseOtherSectionsWithThisGrowingPeriodIDAreNotSelected:
                        growingPeriodsWithIDButShouldBePostedBecauseOtherSectionsWithThisGrowingPeriodIDAreNotSelected?.map(
                          gp => {
                            return {
                              ...gp,
                              certifications: [
                                ...new Set([
                                  ...(gp?.certifications || []),
                                  gp?.demeter ? 'DEMETER' : undefined,
                                  gp?.skal ? 'SKAL' : undefined,
                                  gp?.mps ? 'MPS' : undefined,
                                  gp?.planetProof ? 'PLANETPROOF' : undefined,
                                ]),
                              ].filter(n => n),

                              startDate: Math.round(gp?.startDate / 1000),
                              endDate: Math.round(gp?.endDate / 1000),
                              allSectionsWithThatGrowingPeriod:
                                allSectionsWithThatGrowingPeriod,
                              organizationField: {
                                // id: crud.object.id,
                                sections: crud.object.selectedSections.map(
                                  section => {
                                    return {
                                      id: section.id,
                                    }
                                  }
                                ),
                              },
                            }
                          }
                        ),
                    })
                  )
                }}
              />
            ) : (
              <Swiper
                data={data}
                ref={swiper}
                index={index}
                onIndexChange={to}
                onPop={pop}
                currentScreen={currentScreen}
                onNext={next}
                onPrev={prev}
                passItemProps={{ onRemove }}
                showFieldInfo={showFieldInfo}
                setShowFieldInfo={setShowFieldInfo}
              />
            )}

            {showDrawMenu ? (
              <>
                <BottomFab
                  style={[
                    styles.deleteButton,
                    Platform.OS === 'ios' ? styles.iosFab : {},
                    showFieldInfo && Platform.OS !== 'web' && styles.pusher,
                  ]}
                  label={translate({ text: 'dontSave' })}
                  icon="delete"
                  onPress={cancel}
                  color={'#000'}
                />
                <BottomFab
                  style={[
                    styles.saveButton,
                    Platform.OS === 'ios' ? styles.iosFab : {},
                    showFieldInfo && Platform.OS !== 'web' && styles.pusher,
                  ]}
                  label={translate({ text: 'save' })}
                  icon="check"
                  onPress={saveDrawing}
                  color={'#000'}
                />
              </>
            ) : (
              <>
                {currentScreen.showAddCropsDirectly && !showCrops ? (
                  <BottomFab
                    testID="addCrops"
                    style={[styles.fab, { marginBottom: 64 }]}
                    label={translate({ text: 'addCrops' })}
                    icon="link"
                    onPress={() => setShowCrops(true)}
                    disabled={
                      !crud?.object?.selectedSections ||
                      crud?.object?.selectedSections?.length === 0
                    }
                    loading={crud.creating}
                  />
                ) : null}
                {currentScreen.showNextButton && !showCrops ? (
                  <BottomFab
                    testID="next"
                    style={[
                      styles.fab,
                      showFieldInfo && Platform.OS !== 'web' && styles.pusher,
                      currentScreen.key === 'selectSubDivision' &&
                        (!crud?.object?.sections ||
                          crud?.object?.sections?.length === 0 ||
                          crud?.object?.sections?.some(
                            section =>
                              !section?.growingPeriods ||
                              section?.growingPeriods?.length === 0
                          )) && {
                          backgroundColor: '#FFF',
                        },
                      currentScreen.key === 'address' &&
                        !crud?.object?.coordinates?.length > 0 && {
                          backgroundColor: 'rgba(0,0,0,0.4)',
                        },
                    ]}
                    label={translate({ text: nextLabel })}
                    icon="chevron-right"
                    onPress={() => {
                      if (
                        currentScreen.key === 'selectSubDivision' &&
                        (!crud?.object?.sections ||
                          crud?.object?.sections?.length === 0 ||
                          crud?.object?.sections?.some(
                            section =>
                              !section?.growingPeriods ||
                              section?.growingPeriods?.length === 0
                          ))
                      ) {
                        if (Platform.OS === 'web') {
                          if (
                            window.confirm(
                              translate({ text: 'emptyCropForGP' }) +
                                ' ' +
                                translate({ text: 'emptyCropForGPMessage' })
                            )
                          )
                            next()
                        } else {
                          Alert.alert(
                            translate({ text: 'emptyCropForGP' }),
                            translate({ text: 'emptyCropForGPMessage' }),
                            [
                              {
                                text: translate({ text: 'ok' }),
                                onPress: () => next(),
                                style: 'cancel',
                              },
                            ],
                            { cancelable: true }
                          )
                        }
                      } else {
                        next()
                      }
                    }}
                    type="submit"
                    color={
                      currentScreen.key === 'selectSubDivision' &&
                      (!crud?.object?.sections ||
                        crud?.object?.sections?.length === 0 ||
                        crud?.object?.sections?.some(
                          section =>
                            !section?.growingPeriods ||
                            section?.growingPeriods?.length === 0
                        ))
                        ? '#000'
                        : '#FFF'
                    }
                    disabled={hasEmptyCropForGP}
                    loading={crud.creating}
                  />
                ) : null}
                {index === 0 && (
                  <>
                    {Platform.OS === 'web' ? (
                      <BottomFab
                        style={[
                          styles.drawButton,
                          Platform.OS === 'ios' ? styles.iosFab : {},
                          showFieldInfo &&
                            Platform.OS !== 'web' &&
                            styles.pusher,
                        ]}
                        label={translate({ text: 'drawParcel' })}
                        icon="pencil"
                        onPress={saveDrawing}
                        color={'#000'}
                      />
                    ) : null}
                    {isWholesaler && (
                      <>
                        {selectedFarmer.length === 0 ? (
                          <BottomFab
                            style={[
                              styles.noSelectedFarmer,
                              Platform.OS !== 'web' ? styles.iosFab : {},
                              showFieldInfo &&
                                Platform.OS !== 'web' &&
                                styles.pusher,
                            ]}
                            label={translate({ text: 'selectFarmer' })}
                            icon="format-list-bulleted"
                            onPress={selectFarmer}
                            color={'#000'}
                          />
                        ) : (
                          <BottomFab
                            style={[
                              styles.noSelectedFarmer,
                              showFieldInfo &&
                                Platform.OS !== 'web' &&
                                styles.pusher,
                            ]}
                            label={selectedFarmer[0].name}
                            icon="account-group"
                            onPress={selectFarmer}
                            color={'#000'}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}

            <View style={styles.navigationFixer} />
          </>
        )}
      </Translator>
    </View>
  )
}

const styles = StyleSheet.create({
  navigationFixer: {
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    width: 25,
  },
  fab: {
    zIndex: 200,
  },
  noSelectedFarmer: {
    zIndex: 200,
    bottom: 150,
    backgroundColor: '#FFF',
  },
  drawButton: {
    zIndex: 200,
    bottom: 64 + 16,
    backgroundColor: '#FFF',
  },
  saveButton: {
    zIndex: 200,
    bottom: 15,
    backgroundColor: '#FFF',
  },
  deleteButton: {
    zIndex: 200,
    bottom: 90,
    backgroundColor: '#FFF',
  },
  mergeButton: {
    zIndex: 200,
    bottom: 165,
    backgroundColor: '#FFF',
  },
  mergeButtonWeb: {
    bottom: 220,
  },
  iosFab: {
    marginBottom: 25,
  },
  pusher: {
    marginBottom: 125,
  },
})

const mapStateToProps = state => ({
  crud: state.organizationField.crud,
  gpCrud: state.organizationFieldGrowingPeriod?.crud,
  authenticatedOrganization: state.auth.user.organization,
  user: state.auth.user.user,
  // selectedFarmer: state.filter.advisorFarmer.selected,
  popToRootOnCreate: false,
  selectedFarmer: state.filter.advisorFarmer.selected,
})
// TODO: Check with Ruben why dontLoadOnMount was not true
// Why was this call happening when creating a new organizationField?
export default detailHOC(Detail, mapStateToProps, actions, {
  optinEdit: true,
  enableDelete: true,
  disableSafe: true,
  popToRootOnCreate: false,
  dontLoadOnMount: true,
})
