import React, { useCallback, useMemo } from 'react'
import { connect } from 'react-redux'
import actions from './actions'
import ProductCropItem from './ProductCropItem'
import { getProductData } from '../selectors'
import List from '../../GeneralListFunctions/List'
import { Translator } from 'react-translated'
import { getLanguage } from '../../language'
import { findHighestClass } from '../../BeneficialOrganism/SideEffect/ProductBeneficialOrganism'

export const sortFunc = (a, b) => {
  const language = getLanguage()
  const aName = a?.name && a?.name[language] ? a.name[language] : a.name
  const bName = b?.name && b?.name[language] ? b.name[language] : b.name
  const textA = aName && aName.length > 0 ? aName.toUpperCase() : ''
  const textB = bName && bName.length > 0 ? bName.toUpperCase() : ''
  return textA < textB ? -1 : textA > textB ? 1 : 0
}
export const sortByClassFunc = (a, b) => {
  const highestClassA = findHighestClass(a?.mortalities)
  const highestClassB = findHighestClass(b?.mortalities)
  return highestClassB?.class - highestClassA?.class
}

export const sortList = list => {
  return {
    ...list,
    data: [...list.data].sort(sortFunc),
  }
}
export const sortByClassList = list => {
  return {
    ...list,
    data: [...list.data].sort(sortByClassFunc),
  }
}

function ProductCropList({ list, productId, ...rest }) {
  const extraParams = useMemo(
    () => ({
      limit: 100000,
      number: 100000,
      product: productId,
    }),
    [productId]
  )
  const memoizedList = useMemo(() => sortList(list), [list])
  const renderItem = useCallback(({ item }) => {
    return <ProductCropItem item={item} />
  }, [])

  if (!productId) {
    return null
  }
  return (
    <Translator>
      {({ translate }) => (
        <>
          <List
            {...rest}
            list={memoizedList}
            renderItem={renderItem}
            extraParams={extraParams}
            silentReloading={false}
            reloadIfAlreadyLoaded
            customEmptyTitle={translate({ text: 'noCrops' })}
            customEmptyDescription={translate({
              text: 'noCropsDescription',
            })}
          />
        </>
      )}
    </Translator>
  )
}

const mapStateToProps = state => ({
  list: state.product.crop.list,
  productId: getProductData(state).id,
})

export default connect(mapStateToProps, actions)(ProductCropList)
