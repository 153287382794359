import ActiveSubstanceList from './ActiveSubstance/ActiveSubstanceList'
import Croplist from './Crop/CropList'
import PestList from './Pest/PestList'
import LocationList from './Location/LocationList'
// import SprayModeList from './SprayMode/SprayModeList'
import GroundWaterProtectionAreaChoose from './GroundWaterProtectionArea/GroundWaterProtectionAreaChoose'
import ParcelCloseToWaterChoose from './ParcelCloseToWater/ParcelCloseToWaterChoose'
import AdmissionHolderList from './AdmissionHolder/AdmissionHolderList'
import ProductTypeList from './ProductType/ProductTypeList'
import AdmissionCategoryList from './AdmissionCategory/AdmissionCategoryList'

import FarmersListForAdvisor from './AdvisorFarmer/FarmersListForAdvisor'
import OrganizationTypeList from './OrganizationType/OrganizationTypeList'
import GrowingPlanList from './GrowingPlan/GrowingPlanList'
import ContractList from '../GroupedCrop/Contract/List/ContractList'

// import GroundTypeList from './GroundType/GroundTypeList'
// import SprayTypeList from './SprayType/SprayTypeList'
// import SprayProductList from './SprayProduct/SprayProductList'
// import AirAssistingList from './AirAssisting/AirAssistingList'
// import BbchScaleList from './BBCHScale/BBCHList'
import SprayDirectionList from './SprayDirection/SprayDirectionList'
import SprayDate from './SprayDate/SprayDate'
import SprayNozzle from './SprayNozzle/SprayNozzleList'
import SprayTechnique from './SprayTechnique/SprayTechniqueList'
import CropFreeBufferZoneChoose from './CropFreeBufferZone/CropFreeBufferZoneChoose'

import FarmerAdvisorActions from './AdvisorFarmer/actions'
import OrganizationTypeActions from './OrganizationType/actions'
import ActiveSubstanceActions from './ActiveSubstance/actions'
import CropActions from './Crop/actions'
import KNPVCropActions from './KNPVCrop/actions'
import PestActions from './Pest/actions'
import LocationActions from './Location/actions'
import SprayModeActions from './SprayMode/actions'
import SprayNozzleActions from './SprayNozzle/actions'
import SprayTechniqueActions from './SprayTechnique/actions'
import AdmissionHolderActions from './AdmissionHolder/actions'
import ProductTypeActions from './ProductType/actions'
import AdmissionCategoryActions from './AdmissionCategory/actions'
import * as GroundWaterProtectionAreaActions from './GroundWaterProtectionArea/actions'
import * as SprayDateActions from './SprayDate/actions'
import * as ParcelCloseToWaterActions from './ParcelCloseToWater/actions'
import * as BufferZoneActions from './CropFreeBufferZone/actions'

import GroundTypeActions from './GroundType/actions'
import RegionActions from './Region/actions'
import SprayTypeActions from './SprayType/actions'
import SprayProductActions from './SprayProduct/actions'
import BeneficialOrganismActions from './BeneficialOrganism/actions'
import AirAssistingActions from './AirAssisting/actions'
import BbchScaleActions from './BBCHScale/actions'
import ResistanceActions from './Resistance/actions'
import CertificationActions from './Switches/Certification/actions'
import SprayDirectionActions from './SprayDirection/actions'
import ContractActions from '../GroupedCrop/Contract/List/actions'
import GrowingPlanActions from './GrowingPlan/actions'
import * as AquaticBufferZoneActions from './ParcelCloseToWater/AquaticBufferZone/actions'

import { check, CHECK, RESET, UNCHECK, uncheck } from './Switches/actions'
import List from './Switches/Certification/List'

import { AdvisorFarmerType } from './AdvisorFarmer/reducer'
import { OrganizationTypeType } from './OrganizationType/reducer'
import { ActiveSubstanceType } from './ActiveSubstance/reducer'
import { CropType } from './Crop/reducer'
import { PestType } from './Pest/reducer'
import { LocationType } from './Location/reducer'
// import { SprayModeType } from './SprayMode/reducer'
import { AdmissionHolderType } from './AdmissionHolder/reducer'
import { ProductTypeType } from './ProductType/reducer'
import { AdmissionCategoryType } from './AdmissionCategory/reducer'

// import { GroundTypeType } from './GroundType/reducer'
// import { SprayTypeType } from './SprayType/reducer'
// import { SprayProductType } from './SprayProduct/reducer'
// import { AirAssistingType } from './AirAssisting/reducer'
// import { BBCHScaleType } from './BBCHScale/reducer'
import { SprayDirectionType } from './SprayDirection/reducer'

import { RootState } from '../reducers'
import { fromTimestamp, getApplicationDate } from '../dayjs'
import PreList from './Resistance/PreList'
import { groupBy } from 'lodash'
import { SprayNozzleType } from './SprayNozzle/reducer'
import { GrowingPlanType } from './GrowingPlan/reducer'
import { SprayTechniqueType } from './SprayTechnique/reducer'
import { getTranslatedString } from '../language'
import BeneficialOrganismList from '../BeneficialOrganism/List/BeneficialOrganismList'
import { BeneficialOrganismType } from './BeneficialOrganism/reducer'
import dayjs from 'dayjs'
import AttributesList from '../GroupedCrop/Attributes/List/AttributesList'

import LeafIcon from '../vectors/Leaf'
import CultivationSystemIcon from '../vectors/CultivationSystem'
import TargetIcon from '../vectors/Target'
import ResistanceIcon from '../vectors/Resistence'
import ProductTypeIcon from '../vectors/Product'
import GroundWaterIcon from '../vectors/GroundwaterProtection'
import SurfaceWaterIcon from '../vectors/SurfaceWater'
import SprayNozzleIcon from '../vectors/SprayNozzle'
import SprayDirectionIcon from '../vectors/SprayDirection'
import FreeZoneIcon from '../vectors/FreeZone'
import SprayTechniqueIcon from '../vectors/SprayTechnique'
import DistributionIcon from '../vectors/Distribution'
import LabelIcon from '../vectors/Label'
import AuthorizationExpiredIcon from '../vectors/AuthorizationExpired'
import SuperCompanyIcon from '../vectors/Company'
import ActiveSubstanceIcon from '../vectors/ActiveSubstance'
import ApplicationDateIcon from '../vectors/ApplicationDate'
import CategoryIcon from '../vectors/Category'
import FarmIcon from '../vectors/Farm'
import ContractIcon from '../vectors/Contract'
import KNPVCropList from './KNPVCrop/CropList'

type selectedType = {
  id?: string | number
  code?: string | number
}

export const getFirstSelectedId = (selected?: selectedType[]) => {
  return selected && selected[0] && selected[0].id
}

export const mapIds = (selected?: selectedType[]) => {
  return (selected || []).map(it => it.id)
}

export const excludeCodes = (selected?: selectedType[]) => {
  return (selected || []).map(it => `-${it.code}`)
}

export const reloadActions = [
  CropActions.REMOVE_SELECTED,
  CropActions.SELECT,
  CropActions.TOGGLE_SELECT_ALL,

  KNPVCropActions.REMOVE_SELECTED,
  KNPVCropActions.SELECT,
  KNPVCropActions.TOGGLE_SELECT_ALL,

  BeneficialOrganismActions.REMOVE_SELECTED,
  BeneficialOrganismActions.SELECT,
  BeneficialOrganismActions.TOGGLE_SELECT_ALL,

  ResistanceActions.REMOVE_SELECTED,
  ResistanceActions.SELECT,
  ResistanceActions.TOGGLE_SELECT_ALL,

  CertificationActions.REMOVE_SELECTED_KEY_VALUE,
  CertificationActions.SELECT_KEY_VALUE,

  ActiveSubstanceActions.REMOVE_SELECTED,
  ActiveSubstanceActions.SELECT,
  ActiveSubstanceActions.TOGGLE_SELECT_ALL,

  CropActions.REMOVE_SELECTED,
  CropActions.SELECT,
  CropActions.TOGGLE_SELECT_ALL,

  KNPVCropActions.REMOVE_SELECTED,
  KNPVCropActions.SELECT,
  KNPVCropActions.TOGGLE_SELECT_ALL,

  PestActions.REMOVE_SELECTED,
  PestActions.SELECT,
  PestActions.TOGGLE_SELECT_ALL,

  LocationActions.REMOVE_SELECTED,
  LocationActions.SELECT,
  LocationActions.TOGGLE_SELECT_ALL,

  SprayModeActions.REMOVE_SELECTED,
  SprayModeActions.SELECT,
  SprayModeActions.TOGGLE_SELECT_ALL,

  SprayNozzleActions.REMOVE_SELECTED,
  SprayNozzleActions.SELECT,
  SprayNozzleActions.TOGGLE_SELECT_ALL,

  SprayTechniqueActions.REMOVE_SELECTED,
  SprayTechniqueActions.SELECT,
  SprayTechniqueActions.TOGGLE_SELECT_ALL,

  AdmissionHolderActions.REMOVE_SELECTED,
  AdmissionHolderActions.SELECT,
  AdmissionHolderActions.TOGGLE_SELECT_ALL,

  GrowingPlanActions.REMOVE_SELECTED,
  GrowingPlanActions.SELECT,
  GrowingPlanActions.TOGGLE_SELECT_ALL,

  ProductTypeActions.REMOVE_SELECTED,
  ProductTypeActions.SELECT,
  ProductTypeActions.TOGGLE_SELECT_ALL,

  AdmissionCategoryActions.REMOVE_SELECTED,
  AdmissionCategoryActions.SELECT,
  AdmissionCategoryActions.TOGGLE_SELECT_ALL,

  AdmissionCategoryActions.REMOVE_SELECTED,
  AdmissionCategoryActions.SELECT,
  AdmissionCategoryActions.TOGGLE_SELECT_ALL,

  GroundTypeActions.REMOVE_SELECTED,
  GroundTypeActions.SELECT,
  GroundTypeActions.TOGGLE_SELECT_ALL,

  RegionActions.REMOVE_SELECTED,
  RegionActions.SELECT,
  RegionActions.TOGGLE_SELECT_ALL,

  SprayTypeActions.REMOVE_SELECTED,
  SprayTypeActions.SELECT,
  SprayTypeActions.TOGGLE_SELECT_ALL,

  SprayProductActions.REMOVE_SELECTED,
  SprayProductActions.SELECT,
  SprayProductActions.TOGGLE_SELECT_ALL,

  AirAssistingActions.REMOVE_SELECTED,
  AirAssistingActions.SELECT,
  AirAssistingActions.TOGGLE_SELECT_ALL,

  BbchScaleActions.REMOVE_SELECTED,
  BbchScaleActions.SELECT,
  BbchScaleActions.TOGGLE_SELECT_ALL,

  SprayDirectionActions.REMOVE_SELECTED,
  SprayDirectionActions.SELECT,
  SprayDirectionActions.TOGGLE_SELECT_ALL,

  CHECK,
  RESET,
  UNCHECK,

  GroundWaterProtectionAreaActions.CHECK,
  GroundWaterProtectionAreaActions.REMOVE,
  GroundWaterProtectionAreaActions.RESET,
  GroundWaterProtectionAreaActions.UNCHECK,

  ParcelCloseToWaterActions.CHECK,
  ParcelCloseToWaterActions.REMOVE,
  ParcelCloseToWaterActions.RESET,
  ParcelCloseToWaterActions.UNCHECK,

  SprayDateActions.SET,
  SprayDateActions.REMOVE,

  BufferZoneActions.CHANGE,
  BufferZoneActions.REMOVE,

  AquaticBufferZoneActions.CHANGE,
  AquaticBufferZoneActions.REMOVE,
]

export const filterPageParamsSelector = (state: RootState) => {
  const resistanceByType = groupBy(state.filter.resistance.selected, 'type')

  return {
    language: process.env.REACT_APP_API_LANG || 'nl_nl',
    crop: getFirstSelectedId(state.filter.crop.selected),
    pest: getFirstSelectedId(state.filter.pest.selected),
    attributes: [
      ...mapIds(state.filter.location.selected),
      // ...mapIds(state.filter.sprayMode.selected) // TODO: FIX THIS backend and get attribute type
    ],
    admissionholders: mapIds(state.filter.admissionHolder.selected),
    frac: excludeCodes(resistanceByType['frac']),
    hrac: excludeCodes(resistanceByType['hrac']),
    irac: excludeCodes(resistanceByType['irac']),
    productTypes: mapIds(state.filter.productType.selected),
    groundwaterProtectionArea: state.filter.groundwaterProtectionArea,
    parcelCloseToWater: state.filter.parcelCloseToWater,
    distributedProducts: !!state.filter.switches.distributed,
    'active-substances': mapIds(state.filter.activeSubstance.selected),
    admissionCategories: mapIds(state.filter.admissionCategory.selected),
    expirationDateFrom:
      state.filter.switches.expirationDate === true
        ? null
        : Math.floor(
            // @ts-ignore
            new Date() / 1000
          ),
    preloads: ['Documents', 'Documents.Links', 'PlanetProof'],
    certifications: Object.keys(
      state.filter.certification.selectedKeyValue
    ).filter(key =>
      state.filter.certification.selectedKeyValue[key] ? key : false
    ),
    // groundTypeId: getFirstSelectedId(state.filter.groundType.selected),
    // regionId: getFirstSelectedId(state.filter.region.selected),
    // sprayTypeId: getFirstSelectedId(state.filter.sprayType.selected),
    // sprayProductId: getFirstSelectedId(state.filter.sprayProduct.selected),
    // airAssistingId: getFirstSelectedId(state.filter.airAssisting.selected),
    // bbchScaleId: getFirstSelectedId(state.filter.bbchScale.selected),
    // sprayDirectionId: getFirstSelectedId(state.filter.sprayDirection.selected),
    sprayerIds: mapIds(state.filter.sprayTechnique.selected),
    nozzleIds: mapIds(state.filter.sprayNozzle.selected),
    sprayDirection: getFirstSelectedId(state.filter.sprayDirection.selected),
    applicationDate: state.filter.sprayDate.date
      ? dayjs(new Date(state.filter.sprayDate.date)).unix()
      : undefined,
    'cropFreeBufferZone[present]':
      state.filter.cropFreeBufferZone &&
      state.filter.cropFreeBufferZone.present,
    'cropFreeBufferZone[width]':
      state.filter.cropFreeBufferZone &&
      state.filter.cropFreeBufferZone.present &&
      state.filter.cropFreeBufferZone.width
        ? state.filter.cropFreeBufferZone.width
        : undefined,
    'aquaticBufferZone[present]':
      state.filter.aquaticBufferZone && state.filter.aquaticBufferZone.present,
    'aquaticBufferZone[width]':
      state.filter.aquaticBufferZone &&
      state.filter.aquaticBufferZone.present &&
      state.filter.aquaticBufferZone.width
        ? state.filter.aquaticBufferZone.width
        : undefined,
  }
}

export interface FilterType {
  id: string
  label: string
  labelN: string
  searchPlaceholder?: string
  callToAction: string
  callToActionDescription?: string
  Icon: any
  SelectComponent?: any
  SelectFunction?: (dispatch: (action: any) => any) => any
  getItemLabel: (item: any) => string
  getItemSubLabel?: (item: any) => string
  getItemRight?: (item: any) => string
  showAlways?: boolean
  selectAction?: () => any
  removeAction: any
  getFilter: (state: RootState) => any
  getExtraParams?: (state: RootState) => any
  contexes: string[]
  heavy?: boolean
  canBeFalse?: boolean
  filter?: boolean
  withoutText?: boolean
}

const attributeTypeMap: any = {
  cultivation_type: 'Teelttype',
  cultivation_restriction: 'Beperking of Restrictie',
  cultivation_purpose: 'Teeltdoel',
  cultivation_method: 'Teeltsysteem',
  application_manner: 'Toepassingmanier',
  application_moment: 'Toepassingsmoment',
}
export let filterTypesSorted: FilterType[] = []

export function setFilterTypesSorted(language: string) {
  console.log('setting filtertypes')
  const lang = language || 'nl'
  filterTypesSorted = [
    {
      id: 'growingPlan',
      label: getTranslatedString('growingPlan', lang),
      labelN: getTranslatedString('growingPlans', lang),

      callToAction: getTranslatedString('growingPlan', lang),
      callToActionDescription: getTranslatedString('selectGrowingPlan', lang), // needs to be this way for elepsis in menu..
      Icon: LeafIcon,
      SelectComponent: GrowingPlanList,
      getItemLabel: (item: GrowingPlanType) => item.name || '',
      removeAction: GrowingPlanActions.removeSelected,
      getFilter: (state: RootState) => state.filter.growingPlan.selected,
      contexes: ['grouped-crop', 'field', 'advice'],
    },
    {
      id: 'crop',
      label: getTranslatedString('crop', lang),
      labelN: getTranslatedString('crops', lang),
      searchPlaceholder: getTranslatedString('searchCrop', lang),
      callToAction: getTranslatedString('crop', lang),
      Icon: LeafIcon,
      SelectComponent: Croplist,
      getItemLabel: (item: CropType) => item.name || '',
      getItemSubLabel: (item: CropType) =>
        item.latinName ? item.latinName : item.latin_name,
      showAlways: true,
      removeAction: CropActions.removeSelected,
      getFilter: (state: RootState) => state.filter.crop.selected,
      getExtraParams: () => ({
        // TODO filter with pest not implemented
        // in backend yet so skip it for now
        // pest: getFirstSelectedId(state.filter.pest.selected),
        // pest: state.filter.crop.selected && state.filter.crop.selected[0],
      }),
      contexes: ['product', 'farmer-product', 'comparison', 'field'],
    },
    {
      id: 'knpvCrop',
      label: getTranslatedString('crop', lang),
      labelN: getTranslatedString('crops', lang),
      searchPlaceholder: getTranslatedString('searchCrop', lang),
      callToAction: getTranslatedString('crop', lang),
      Icon: LeafIcon,
      SelectComponent: KNPVCropList,
      getItemLabel: (item: CropType) => item.name || '',
      getItemSubLabel: (item: CropType) =>
        item.latinName ? item.latinName : item.latin_name,
      showAlways: true,
      removeAction: KNPVCropActions.removeSelected,
      getFilter: (state: RootState) => state.filter.knpvCrop.selected,
      getExtraParams: () => ({
        listId: '0d5d07a0-5624-4559-bd36-eb12f2a34446',
      }),
      contexes: ['knpv'],
    },
    {
      id: 'pest',
      label: getTranslatedString('pest', lang),
      labelN: getTranslatedString('pests', lang),
      searchPlaceholder: getTranslatedString('searchPest', lang),
      callToAction: getTranslatedString('pest', lang),
      Icon: TargetIcon,
      SelectComponent: PestList,
      getItemLabel: (item: PestType) => item.name || '',
      getItemSubLabel: (item: PestType) =>
        item.latinName ? item.latinName : item.latin_name,
      removeAction: PestActions.removeSelected,
      getFilter: (state: RootState) => state.filter.pest.selected,
      getExtraParams: (state: RootState) => ({
        crop: getFirstSelectedId(state.filter.crop.selected),
      }),
      contexes: ['product', 'farmer-product', 'comparison'],
    },
    {
      id: 'beneficialOrganism',
      label: getTranslatedString('beneficialOrganism', lang),
      labelN: getTranslatedString('beneficialOrganisms', lang),
      searchPlaceholder: getTranslatedString('searchBeneficialOrganism', lang),
      callToAction: getTranslatedString('beneficialOrganism', lang),
      Icon: TargetIcon,
      SelectComponent: BeneficialOrganismList,
      getItemLabel: (item: BeneficialOrganismType) => {
        console.log({ item })
        return (
          (item && item.name && lang === 'nl'
            ? item.name?.nl
              ? item.name?.nl
              : item.name?.en
              ? item.name?.en
              : item?.name
              ? item?.name
              : ''
            : item?.name?.en
            ? item?.name?.en
            : item?.name?.nl
            ? item?.name?.nl
            : item?.name
            ? item?.name
            : '') || ''
        )
      },
      getItemSubLabel: (item: BeneficialOrganismType) =>
        (item && item.name && item.name?.la ? item.name.la : '') || '',
      showAlways: true,
      removeAction: BeneficialOrganismActions.removeSelected,
      getFilter: (state: RootState) => state.filter.beneficialOrganism.selected,
      getExtraParams: () => ({}),
      contexes: [],
    },
    {
      id: 'location',
      label: getTranslatedString('growingLocation', lang),
      labelN: getTranslatedString('growingLocations', lang),
      callToAction: getTranslatedString('growingLocation', lang),
      // callToActionDescription: 'Zoals bedekte en onbekende teelt',
      searchPlaceholder: getTranslatedString('searchGrowingLocation', lang),
      Icon: CultivationSystemIcon,
      SelectComponent: LocationList,
      getItemLabel: (item: LocationType) => item.name || '',
      // getItemSubLabel: item => item.type,
      removeAction: LocationActions.removeSelected,
      getFilter: (state: RootState) => state.filter.location.selected,
      getExtraParams: () => ({}),
      contexes: ['product', 'farmer-product'],
    },
    {
      id: 'admissionHolder',
      label: getTranslatedString('admissionholder', lang),
      labelN: getTranslatedString('admissionholders', lang),

      callToAction: getTranslatedString('admissionholder', lang),
      searchPlaceholder: getTranslatedString('searchAdmissionholder', lang),
      Icon: SuperCompanyIcon,
      SelectComponent: AdmissionHolderList,
      getItemLabel: (item: AdmissionHolderType) => item.name || '',
      removeAction: AdmissionHolderActions.removeSelected,
      getFilter: (state: RootState) => state.filter.admissionHolder.selected,
      getExtraParams: () => ({}),
      // heavy: true,
      contexes: ['product', 'farmer-product', 'distribution'],
    },
    {
      id: 'activeSubstance',
      label: getTranslatedString('activeSubstance', lang),
      labelN: getTranslatedString('activeSubstances', lang),

      callToAction: getTranslatedString('activeSubstance', lang),
      Icon: ActiveSubstanceIcon,
      SelectComponent: ActiveSubstanceList,
      getItemLabel: (item: ActiveSubstanceType) => item.name || '',
      removeAction: ActiveSubstanceActions.removeSelected,
      getFilter: (state: RootState) => state.filter.activeSubstance.selected,
      getExtraParams: () => ({}),
      heavy: true,
      contexes: ['product', 'farmer-product'],
    },
    {
      id: 'resistance',
      label: getTranslatedString('resistance', lang),
      labelN: getTranslatedString('resistances', lang),

      callToAction: getTranslatedString('resistance', lang),
      callToActionDescription: getTranslatedString('showResistance', lang), // needs to be this way for elepsis in menu..
      Icon: ResistanceIcon,
      SelectComponent: PreList,
      getItemLabel: (item: any) => {
        return (item && item.name ? item.name : item.code) || ''
      },
      getItemSubLabel: (item: any) => {
        return lang === 'en'
          ? (item && item.modeOfAction
              ? item.modeOfAction.name && item.modeOfAction.name.en
                ? `MoA: ${item.modeOfAction.name.en}`
                : `MoA: ${item.modeOfAction.name.nl}`
              : '') || ''
          : (item && item.modeOfAction
              ? item.modeOfAction.name && item.modeOfAction.name.nl
                ? `MoA: ${item.modeOfAction.name.nl}`
                : `MoA: ${item.modeOfAction.name.en}`
              : '') || ''
      },
      removeAction: ResistanceActions.removeSelected,
      getFilter: (state: RootState) => state.filter.resistance.selected,
      getExtraParams: () => ({}),
      heavy: true,
      contexes: ['product', 'farmer-product'],
    },
    {
      id: 'productType',
      label: getTranslatedString('productType', lang),
      labelN: getTranslatedString('productTypes', lang),
      callToAction: getTranslatedString('productType', lang),
      Icon: ProductTypeIcon,
      SelectComponent: ProductTypeList,
      getItemLabel: (item: ProductTypeType) => item.name || '',
      removeAction: ProductTypeActions.removeSelected,
      getFilter: (state: RootState) => state.filter.productType.selected,
      getExtraParams: () => ({}),
      contexes: ['product', 'farmer-product'],
    },
    {
      id: 'admissionCategory',
      label: getTranslatedString('admissionCategory', lang),
      labelN: getTranslatedString('admissionCategories', lang),
      callToAction: getTranslatedString('admissionCategory', lang),
      // callToActionDescription: 'Gewasbescherming of toevoeging',
      Icon: CategoryIcon,
      SelectComponent: AdmissionCategoryList,
      removeAction: AdmissionCategoryActions.removeSelected,

      getItemLabel: (item: AdmissionCategoryType) => item.name || '',
      getFilter: (state: RootState) => state.filter.admissionCategory.selected,
      getExtraParams: () => ({}),
      contexes: ['product', 'farmer-product'],
    },
    {
      id: 'groundwaterProtectionArea',
      label: getTranslatedString('groundwaterProtectionArea', lang),
      labelN: getTranslatedString('groundwaterProtectionAreas', lang),

      callToAction: getTranslatedString('groundwaterProtectionArea', lang),
      // callToActionDescription:
      //   'Verberg verboden middelen in grondwaterbeschermingsgebied', // needs to be this way for elepsis in menu..
      Icon: GroundWaterIcon,
      SelectComponent: GroundWaterProtectionAreaChoose,
      // selectAction: check,
      canBeFalse: true,
      removeAction: GroundWaterProtectionAreaActions.remove,
      getItemLabel: (item: boolean) =>
        item
          ? getTranslatedString('insideGroundwaterProtectionArea', lang)
          : getTranslatedString('outsideGroundwaterProtectionArea', lang),
      // getItemSubLabel: (item: boolean) =>
      //   item
      //     ? 'In een grondwaterbeschermingsgebied'
      //     : 'Buiten grondwaterbeschermingsgebied',
      getFilter: (state: RootState) => state.filter.groundwaterProtectionArea,
      getExtraParams: () => ({}),
      contexes: ['product', 'farmer-product'],
    },
    {
      id: 'parcelCloseToWater',
      label: getTranslatedString('parcelCloseToWater', lang),
      labelN: getTranslatedString('parcelCloseToWater', lang),

      callToAction: getTranslatedString('parcelCloseToWater', lang),
      // callToActionDescription:
      //   'Verberg verboden middelen in grondwaterbeschermingsgebied', // needs to be this way for elepsis in menu..
      Icon: SurfaceWaterIcon,
      SelectComponent: ParcelCloseToWaterChoose,
      // selectAction: check,
      canBeFalse: true,
      removeAction: [
        ParcelCloseToWaterActions.remove,
        AquaticBufferZoneActions.remove,
      ],
      getItemLabel: (item: {
        parcelCloseToWater: boolean
        aquaticBufferZone: {
          width: null | number
          present: boolean | null
        }
      }) => {
        return (
          item &&
          (item.aquaticBufferZone && item.aquaticBufferZone.present
            ? `Teeltvrije zone tot oppervlaktewater aanwezig${
                item.aquaticBufferZone && item.aquaticBufferZone.width
                  ? ` van ${item.aquaticBufferZone.width} cm`
                  : ''
              }`
            : item.aquaticBufferZone && item.aquaticBufferZone.present === false
            ? 'Teeltvrije zone tot oppervlaktewater afwezig'
            : '') +
            (item.parcelCloseToWater
              ? (item.aquaticBufferZone &&
                item.aquaticBufferZone.present !== undefined &&
                item.aquaticBufferZone.present !== null
                  ? ', '
                  : '') + getTranslatedString('trueParcelCloseToWater', lang)
              : (item.aquaticBufferZone &&
                item.aquaticBufferZone.present !== undefined &&
                item.aquaticBufferZone.present !== null
                  ? ', '
                  : '') + getTranslatedString('falseParcelCloseToWater', lang))
        )
      },
      getItemSubLabel: (item: {
        parcelCloseToWater: boolean
        aquaticBufferZone: {
          width: null | number
          present: boolean | null
        }
      }) => {
        console.log(item)
        return (
          item &&
          (item.aquaticBufferZone && item.aquaticBufferZone.present
            ? `Teeltvrije zone tot oppervlaktewater aanwezig${
                item.aquaticBufferZone && item.aquaticBufferZone.width
                  ? ` van ${item.aquaticBufferZone.width} cm`
                  : ''
              }`
            : item.aquaticBufferZone && item.aquaticBufferZone.present === false
            ? 'Teeltvrije zone tot oppervlaktewater afwezig'
            : '') +
            (item.parcelCloseToWater
              ? (item.aquaticBufferZone ? ', ' : '') +
                getTranslatedString('trueParcelCloseToWater', lang)
              : (item.aquaticBufferZone ? ', ' : '') +
                getTranslatedString('falseParcelCloseToWater', lang))
        )
      },
      getFilter: (state: RootState) => {
        return {
          parcelCloseToWater: state.filter.parcelCloseToWater,
          aquaticBufferZone: state.filter.aquaticBufferZone,
        }
      },
      getExtraParams: () => ({}),
      contexes: ['farmer-product'],
    },
    {
      id: 'farmer',
      label: getTranslatedString('farmer', lang),
      labelN: getTranslatedString('farmers', lang),

      callToAction: getTranslatedString('farmer', lang),
      callToActionDescription: getTranslatedString('selectFarmer', lang), // needs to be this way for elepsis in menu..
      Icon: FarmIcon,
      SelectComponent: FarmersListForAdvisor,
      getItemLabel: (item: AdvisorFarmerType) => item.name || '',
      getItemSubLabel: (item: AdvisorFarmerType) => item.email,
      removeAction: FarmerAdvisorActions.removeSelected,
      getFilter: (state: RootState) => state.filter.advisorFarmer.selected,
      getExtraParams: () => ({}),
      contexes: ['grouped-crop', 'field', 'advice', 'registration'],
    },
    {
      id: 'type',
      label: getTranslatedString('type', lang),
      labelN: getTranslatedString('types', lang),

      callToAction: getTranslatedString('type', lang),
      callToActionDescription: getTranslatedString('selectType', lang), // needs to be this way for elepsis in menu..
      Icon: FarmIcon,
      SelectComponent: OrganizationTypeList,
      getItemLabel: (item: OrganizationTypeType) =>
        (item.name && getTranslatedString(item.name.props.text, lang)) || '',
      removeAction: OrganizationTypeActions.removeSelected,
      getFilter: (state: RootState) => state.filter.organizationType.selected,
      contexes: ['organization'],
    },
    {
      id: 'contract',
      label: getTranslatedString('contract', lang),
      labelN: getTranslatedString('contract', lang),
      searchPlaceholder: getTranslatedString('searchContract', lang),

      callToAction: getTranslatedString('contract', lang),
      callToActionDescription: getTranslatedString('selectContract', lang), // needs to be this way for elepsis in menu..
      Icon: ContractIcon,
      SelectComponent: ContractList,
      filter: true,
      getItemLabel: (item: any) => item.number || '',
      removeAction: ContractActions.removeSelected,
      getFilter: (state: RootState) => state.filter.contract.selected,
      contexes: ['field'],
    },
    {
      id: 'attributes',
      label: getTranslatedString('attributes', lang),
      labelN: getTranslatedString('attributes', lang),
      searchPlaceholder: getTranslatedString('searchAttributes', lang),

      callToAction: getTranslatedString('attributes', lang),
      callToActionDescription: getTranslatedString('selectAttributes', lang), // needs to be this way for elepsis in menu..
      Icon: FarmIcon,
      SelectComponent: AttributesList,
      filter: false,
      getItemLabel: (item: any) => item.name || '',
      getItemSubLabel: (item: any) =>
        (item.type && attributeTypeMap[item.type]
          ? attributeTypeMap[item.type]
          : item.type
          ? item.type
          : undefined) || '',
      removeAction: () => null,
      getFilter: () => null,
      contexes: [],
    },
    {
      id: 'bufferZone',
      label: getTranslatedString('bufferZone', lang),
      labelN: getTranslatedString('bufferZones', lang),
      searchPlaceholder: getTranslatedString('searchBufferZone', lang),
      callToAction: getTranslatedString('bufferZone', lang),
      Icon: FreeZoneIcon,
      // callToActionDescription: 'Driftreducie',
      SelectComponent: CropFreeBufferZoneChoose,
      getItemLabel: (cropFreeBufferZone: {
        width: null | number
        present: boolean | null
      }) =>
        cropFreeBufferZone && cropFreeBufferZone.present
          ? `Teeltvrije zone tot perceelsrand aanwezig${
              cropFreeBufferZone && cropFreeBufferZone.width
                ? ` van ${cropFreeBufferZone.width} cm`
                : ''
            }`
          : cropFreeBufferZone.present === false
          ? 'Teeltvrije zone tot perceelsrand afwezig'
          : '',
      // getItemSubLabel: (item: GroundTypeType) => item.latin_name,
      // showAlways: true,\
      canBeFalse: true,
      removeAction: BufferZoneActions.remove,
      getFilter: (state: RootState) => state.filter.cropFreeBufferZone,
      contexes: ['farmer-product'],
    },
    // {
    //   id: 'groundType',
    //   label: 'Grondsoort',
    //   labelN: 'Grondsoorten',
    //   searchPlaceholder: 'Zoek in grondsoorten',
    //   callToAction: 'Grondsoort',
    //   iconName: Platform.OS === 'web' ? require('../../img/crop.webp') : require('../../img/crop.png'),
    //   // callToActionDescription: 'Driftreducie',
    //   SelectComponent: GroundTypeList,
    //   getItemLabel: (item: GroundTypeType) => item.name || '',
    //   // getItemSubLabel: (item: GroundTypeType) => item.latin_name,
    //   // showAlways: true,
    //   removeAction: GroundTypeActions.removeSelected,
    //   getFilter: (state: RootState) => state.filter.groundType.selected,
    //   contexes: ['product'],
    // },
    {
      id: 'sprayDate',
      SelectComponent: SprayDate,
      label: getTranslatedString('sprayDate', lang),
      labelN: getTranslatedString('sprayDates', lang),
      searchPlaceholder: getTranslatedString('searchSprayDate', lang),
      callToAction: getTranslatedString('sprayDate', lang),
      Icon: ApplicationDateIcon,
      // callToActionDescription: 'sprayType',
      getItemLabel: (date: any) => {
        return date
          ? getApplicationDate(fromTimestamp(dayjs(new Date(date)).unix()))
          : ''
      },
      getItemSubLabel: (date: any) => {
        return date
          ? getApplicationDate(fromTimestamp(dayjs(new Date(date)).unix()))
          : ''
      },
      // showAlways: true,
      SelectFunction: dispatch => dispatch(SprayDateActions.open()),
      removeAction: SprayDateActions.remove,
      getFilter: (state: RootState) => state.filter.sprayDate.date,
      contexes: ['product', 'farmer-product'],
    },
    // { // TODO: FIX THIS backend and get attribute type
    //   id: 'sprayMode',
    //   label: 'Toepassingswijze',
    //   labelN: 'Toepassingswijzen',
    //   searchPlaceholder: 'Zoek op toepassingswijze',
    //   callToAction: 'Toepassingswijze',
    //   iconName: Platform.OS === 'web' ? require('../../img/crop.webp') : require('../../img/crop.png'),
    //
    //   SelectComponent: SprayModeList,
    //   getItemLabel: (item: SprayModeType) => item.name || '',
    //   // getItemSubLabel: item => item.type,
    //   removeAction: SprayModeActions.removeSelected,
    //   getFilter: (state: RootState) => state.filter.sprayMode.selected,
    //   getExtraParams: () => ({}),
    //   contexes: ['product'],
    // },

    {
      id: 'sprayDirection',
      label: getTranslatedString('sprayDirection', lang),
      labelN: getTranslatedString('sprayDirections', lang),
      searchPlaceholder: getTranslatedString('searchSprayDirections', lang),
      callToAction: getTranslatedString('sprayDirection', lang),
      Icon: SprayDirectionIcon,
      // callToActionDescription: 'Spuitrichting',
      SelectComponent: SprayDirectionList,
      getItemLabel: (item: SprayDirectionType) => item.name || '',
      // getItemSubLabel: (item: GroundTypeType) => item.latin_name,
      // showAlways: true,
      removeAction: SprayDirectionActions.removeSelected,
      getFilter: (state: RootState) => state.filter.sprayDirection.selected,
      contexes: ['farmer-product'],
    },
    {
      id: 'sprayTechnique',
      label: getTranslatedString('sprayTechnique', lang),
      labelN: getTranslatedString('sprayTechniques', lang),
      searchPlaceholder: getTranslatedString('searchSprayTechnique', lang),
      callToAction: getTranslatedString('sprayTechnique', lang),
      Icon: SprayTechniqueIcon,
      // callToActionDescription: 'sprayType',
      SelectComponent: SprayTechnique,
      getItemLabel: (item: SprayTechniqueType) => item.name || '',
      // getItemSubLabel: (item) => '99,5%',
      getItemRight: item => '99',
      removeAction: SprayTechniqueActions.removeSelected,
      getFilter: (state: RootState) => state.filter.sprayTechnique.selected,
      getExtraParams: () => ({}),
      contexes: ['farmer-product'],
    },
    {
      id: 'sprayNozzle',
      label: getTranslatedString('sprayNozzle', lang),
      labelN: getTranslatedString('sprayNozzles', lang),
      searchPlaceholder: getTranslatedString('searchSprayNozzle', lang),
      callToAction: getTranslatedString('sprayNozzle', lang),
      Icon: SprayNozzleIcon,
      // callToActionDescription: 'sprayType',
      SelectComponent: SprayNozzle,
      getItemLabel: (item: SprayNozzleType) => item.name || '',
      // getItemSubLabel: item => item.type,
      removeAction: SprayNozzleActions.removeSelected,
      getFilter: (state: RootState) => state.filter.sprayNozzle.selected,
      getExtraParams: () => ({}),
      contexes: ['farmer-product'],
    },
    // {
    //   id: 'sprayType',
    //   label: 'Spuitkar',
    //   labelN: 'Spuitkarren',
    //   searchPlaceholder: 'Zoek op spuitkar',
    //   callToAction: 'Spuitkar',
    //   iconName: Platform.OS === 'web' ? require('../../img/crop.webp') : require('../../img/crop.png'),
    //   // callToActionDescription: 'sprayType',
    //   SelectComponent: SprayTypeList,
    //   getItemLabel: (item: SprayTypeType) => item.name || '',
    //   // getItemSubLabel: (item: GroundTypeType) => item.latin_name,
    //   // showAlways: true,
    //   removeAction: SprayTypeActions.removeSelected,
    //   getFilter: (state: RootState) => state.filter.sprayType.selected,
    //   contexes: ['product'],
    // },
    // {
    //   id: 'sprayProduct',
    //   label: 'Spuitdop',
    //   labelN: 'Spuitdoppen',
    //   searchPlaceholder: 'Zoek op spuitdoppen',
    //   callToAction: 'Spuitdop',
    //   iconName: Platform.OS === 'web' ? require('../../img/crop.webp') : require('../../img/crop.png'),
    //   // callToActionDescription: 'Spuitdop',
    //   SelectComponent: SprayProductList,
    //   getItemLabel: (item: SprayProductType) => item.name || '',
    //   // getItemSubLabel: (item: GroundTypeType) => item.latin_name,
    //   // showAlways: true,
    //   removeAction: SprayProductActions.removeSelected,
    //   getFilter: (state: RootState) => state.filter.sprayProduct.selected,
    //   contexes: ['product'],
    // },

    // {
    //   id: 'bbchScale',
    //   label: 'Groeistadium',
    //   labelN: 'Groeistadiums',
    //   searchPlaceholder: 'Zoek op groeistadiums',
    //   callToAction: 'Groeistadium',
    //   iconName: Platform.OS === 'web' ? require('../../img/crop.webp') : require('../../img/crop.png'),
    //   // callToActionDescription: 'bbchScale',
    //   SelectComponent: BbchScaleList,
    //   getItemLabel: (item: BBCHScaleType) => item.name || '',
    //   // getItemSubLabel: (item: GroundTypeType) => item.latin_name,
    //   // showAlways: true,
    //   removeAction: BbchScaleActions.removeSelected,
    //   getFilter: (state: RootState) => state.filter.bbchScale.selected,
    //   contexes: ['product'],
    // },
    // {
    //   id: 'airAssisting',
    //   label: 'Luchtondersteuning',
    //   labelN: 'Luchtondersteuning',
    //   searchPlaceholder: 'Zoek op luchtondersteuning',
    //   callToAction: 'Luchtondersteuning',
    //   iconName: Platform.OS === 'web' ? require('../../img/crop.webp') : require('../../img/crop.png'),
    //   // callToActionDescription: 'airAssisting',
    //   SelectComponent: AirAssistingList,
    //   getItemLabel: (item: AirAssistingType) => item.name || '',
    //   // getItemSubLabel: (item: GroundTypeType) => item.latin_name,
    //   // showAlways: true,
    //   removeAction: AirAssistingActions.removeSelected,
    //   getFilter: (state: RootState) => state.filter.airAssisting.selected,
    //   contexes: ['product'],
    // },
    // {
    //   id: 'region',
    //   label: 'Regio',
    //   labelN: "Regio's",
    //   searchPlaceholder: 'Zoek op regio',
    //   callToAction: 'Regio',
    //   iconName: Platform.OS === 'web' ? require('../../img/crop.webp') : require('../../img/crop.png'),
    //   // callToActionDescription: 'region',
    //   SelectComponent: RegionList,
    //   getItemLabel: (item: RegionType) => item.name || '',
    //   // getItemSubLabel: (item: GroundTypeType) => item.latin_name,
    //   // showAlways: true,
    //   removeAction: RegionActions.removeSelected,
    //   getFilter: (state: RootState) => state.filter.region.selected,
    //   contexes: ['product'],
    // },
    {
      id: 'distributed',
      label: getTranslatedString('distributed', lang),
      labelN: getTranslatedString('distributedMultiple', lang),

      callToAction: getTranslatedString('distributedMultiple', lang),
      callToActionDescription: getTranslatedString('showDistributed', lang), // needs to be this way for elepsis in menu..
      Icon: DistributionIcon,
      selectAction: check,
      removeAction: uncheck,
      getItemLabel: () => getTranslatedString('pastFutureDistributed', lang),
      getFilter: (state: RootState) => !!state.filter.switches.distributed,
      getExtraParams: () => ({}),
      contexes: ['product', 'farmer-product'],
    },
    {
      id: 'certification',
      label: getTranslatedString('certification', lang),
      labelN: getTranslatedString('certifications', lang),
      callToAction: getTranslatedString('certification', lang),
      SelectComponent: List,
      callToActionDescription: getTranslatedString('showCertification', lang), // needs to be this way for elepsis in menu..
      Icon: LabelIcon,
      getItemLabel: (item: any) => item.name,

      removeAction: CertificationActions.removeSelectedKeyValue,
      getFilter: (state: RootState) =>
        state.filter.certification.selectedKeyValue,
      getExtraParams: () => ({}),
      contexes: ['product', 'field', 'farmer-product'],
      // heavy: true,
    },
    {
      id: 'expirationDate',
      label: getTranslatedString('expirationDate', lang),
      labelN: getTranslatedString('expirationDates', lang),
      callToAction: getTranslatedString('expirationDate', lang),
      callToActionDescription: getTranslatedString('showExpirationDate', lang),
      Icon: AuthorizationExpiredIcon,
      selectAction: check,
      removeAction: uncheck,
      getItemLabel: () => getTranslatedString('expirationDates', lang),
      getFilter: (state: RootState) => !!state.filter.switches.expirationDate,
      getExtraParams: () => ({}),
      contexes: ['product', 'farmer-product'],
    },
  ]
}
