import React from 'react'
import Svg from './SvgAbstraction'
import Path from './PathAbstraction'

import { color, defaultProps, propTypes } from './standardVector'

export const width = 24
export const height = 24

const Vector = props => (
  <Svg
    width={props.width || width}
    height={props.height || height}
    className={props.className}
    viewBox="0 0 24 24"
  >
    {/*<Path*/}
    {/*  d="*/}
    {/*  M 11.24 18.428*/}
    {/*  L 14 21*/}
    {/*  l 5.37 -5.97*/}
    {/*  "*/}
    {/*  fill="none"*/}
    {/*  stroke={color(props.color)}*/}
    {/*  stroke-linecap="round"*/}
    {/*  stroke-linejoin="round"*/}
    {/*/>*/}
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M14.578 20.754C14.578 20.754 15.798 23.249 17.849 23.062C22.007 22.682 21.729 17.155 23.249 15.203C21.9633 14.5623 20.544 14.235 19.1075 14.2481C17.671 14.2611 16.2579 14.614 14.984 15.278C12.83 16.5 12.677 18.548 14.578 20.754Z"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M17.52 18.219C17.52 18.219 14.82 18.554 12.897 23.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M16.938 11.245C16.5521 9.89165 15.8271 8.6592 14.8318 7.66436C13.8364 6.66951 12.6036 5.9452 11.25 5.56V0.75H6.75001V5.56C4.83479 6.104 3.18082 7.32209 2.09309 8.98967C1.00535 10.6573 0.557197 12.6619 0.831259 14.6339C1.10532 16.6059 2.08312 18.4124 3.58436 19.7202C5.08559 21.0279 7.00903 21.7489 9.00001 21.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M4.5 0.75H14.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M7.125 10.5C6.91789 10.5 6.75 10.3321 6.75 10.125C6.75 9.91789 6.91789 9.75 7.125 9.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M7.125 10.5C7.33211 10.5 7.5 10.3321 7.5 10.125C7.5 9.91789 7.33211 9.75 7.125 9.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M6.375 15.75C6.16789 15.75 6 15.5821 6 15.375C6 15.1679 6.16789 15 6.375 15"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M6.375 15.75C6.58211 15.75 6.75 15.5821 6.75 15.375C6.75 15.1679 6.58211 15 6.375 15"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M11.625 12.75C11.4179 12.75 11.25 12.5821 11.25 12.375C11.25 12.1679 11.4179 12 11.625 12"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <Path
      fill={'none'}
      stroke={color(props.color)}
      fillRule="evenodd"
      d="M11.625 12.75C11.8321 12.75 12 12.5821 12 12.375C12 12.1679 11.8321 12 11.625 12"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </Svg>
)

Vector.propTypes = propTypes
Vector.defaultProps = defaultProps

export default Vector
