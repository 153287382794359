import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

export default function MapHOC(WrappedComponent) {
  function EnhancedMap({ centerCoordinates, boundingBox, ...rest }) {
    const goTo = useSelector(state => state.map.center)
    const fromButton = useSelector(state => state.map.fromButton)
    const newZoom = useSelector(state => state.map.zoom)
    const [innerCoordinates, setInnerCoordinates] = useState(centerCoordinates)
    const [centerFromHOC, setCenterFromHOC] = useState(goTo)

    const [bounds, setBounds] = useState(boundingBox)
    const [zoomState, setZoom] = useState(undefined)
    // working search
    useEffect(() => {
      console.log({ goTo, fromButton, bounds })
      if (bounds) {
        setBounds(bounds)
      }
      if (goTo) {
        if (fromButton) {
          setInnerCoordinates(goTo)
        } else {
          setCenterFromHOC(goTo)
        }
      }
      if (newZoom) {
        setZoom([newZoom])
      }
    }, [setInnerCoordinates, goTo, bounds, newZoom, fromButton])

    // working coordinates from outer space
    useEffect(() => {
      // if (innerCoordinates !== centerCoordinates) {
      if (centerCoordinates) {
        setInnerCoordinates(centerCoordinates)
      }

      // }
    }, [setInnerCoordinates, centerCoordinates])

    return (
      <WrappedComponent
        {...rest}
        centerCoordinates={innerCoordinates}
        centerFromHOC={centerFromHOC}
        boundingBox={bounds}
        reducerZoom={
          rest?.reducerZoom && rest?.hasReducerZoom
            ? rest?.reducerZoom
            : zoomState
        }
      />
    )
  }
  return React.memo(EnhancedMap)
}
