import React from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import FilterMenuItem from './FilterMenuItem'
import { Title } from 'react-native-paper'
import { useWindowDimensions } from 'react-native'

const styles = StyleSheet.create({
  title: {
    padding: 16,
  },
  root: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 12,
    },
    shadowOpacity: 0.07,
    shadowRadius: 16.0,

    elevation: 2,

    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    position: 'absolute',
    backgroundColor: '#fff',
    top: 56,
    overflow: 'hidden',
  },
})

function getToolWidth(screenWidth) {
  return Math.min((screenWidth - 700) / 2, 400)
}

function FilterList({
  filterTypes,
  filters,
  height,
  dispatch,
  componentId,
  context,
  withoutText,
}) {
  const dimensions = useWindowDimensions()

  return (
    <ScrollView
      style={[
        styles.root,
        { maxHeight: height, width: getToolWidth(dimensions.width),paddingBottom:45 },
      ]}
      // pointerEvents="box-none"
    >
      <Title style={styles.title} pointerEvents="none">
        Filters
      </Title>
      {filterTypes.map((f) => (
        <FilterMenuItem
          key={`list-item-${f.id}`}
          componentId={componentId}
          filter={f}
          selected={filters[f.id]}
          dispatch={dispatch}
          inList
          withoutText={withoutText}
          context={context}
        />
      ))}
      <View style={{ height: 56 }} />
    </ScrollView>
  )
}

export default FilterList
