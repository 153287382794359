import React, { Component } from 'react'
import PT from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { compose, lifecycle, setPropTypes } from 'recompose'
import _ from 'lodash/fp'

import { load } from './actions'
import { attributes as pAttributes } from '../Product/config'
import { attributes as mAttributes } from './matchDetails'
import {
  attributes as asAttributes,
  planetProofAttributes,
} from './activeSubstanceDetails'
import { getLanguage } from '../language'

export default WrappedComponent => {
  class HOC extends Component {
    constructor(props) {
      super(props)

      this.state = {
        collapsedDosage: -1,
        collapsedActiveSubstance: -1,
      }
    }

    _toggleDosage = collapsedDosage => {
      if (this.state.collapsedDosage === collapsedDosage) {
        this.setState({ collapsedDosage: -1 })
        return
      }
      this.setState({ collapsedDosage })
    }

    _toggleActiveSubstance = collapsedActiveSubstance => {
      if (this.state.collapsedActiveSubstance === collapsedActiveSubstance) {
        this.setState({ collapsedActiveSubstance: -1 })
        return
      }
      this.setState({ collapsedActiveSubstance })
    }

    render() {
      const language = getLanguage()
      const props = this.props
      const { collapsedDosage, collapsedActiveSubstance } = this.state
      let matchRows = []
      let activeSubstances = {}

      if (props.matches) {
        props.matches.forEach((match, mIndex) => {
          if (match && match.specification) {
            matchRows = [
              ...matchRows,
              {
                key: mIndex,
                tds: [
                  {
                    key: `${mIndex}_specification`,
                    mIndex,
                    isCollapsed: mIndex === collapsedDosage,
                    pinned: props.pinned,
                    isSpecification: true,
                    values: [
                      {
                        text: match.specification.map(s => s.name).join(', '),
                      },
                    ],
                    colSpan: props.ids.length,
                  },
                ],
              },
            ]
          }

          if (mIndex === collapsedDosage) {
            mAttributes(language).forEach((attribute, aIndex) => {
              const matchRow = {
                key: `${mIndex}_${aIndex}`,
                tds: [],
              }
              let addCurrentRow = false
              props.ids.forEach((id, pIndex) => {
                const pinned = props.pinned && props.pinned === id

                const matchValue = {
                  key: `${mIndex}_${aIndex}_${pIndex}_${attribute.label}`,
                  pinned,
                  label: attribute.label,
                  values: [],
                  isSpecification: false,
                  colSpan: 1,
                }

                if (match && match.data[id]) {
                  let addDosageRow = false
                  match.data[id].forEach((dosage, dIndex) => {
                    const parsed = attribute.parse(dosage)
                    if (parsed.hasValue) {
                      addCurrentRow = true
                      addDosageRow = true
                    }

                    // if multiple dosages inside this product
                    if (match.data[id].length > 1) {
                      if (addDosageRow) {
                        const before = dIndex + 1
                        const text = parsed.hasValue ? parsed.value : '-'
                        matchValue.values = [
                          ...matchValue.values,
                          {
                            before,
                            text,
                          },
                        ]
                      }
                    } else {
                      const text = parsed.hasValue ? parsed.value : '-'
                      matchValue.values = [
                        ...matchValue.values,
                        {
                          text,
                        },
                      ]
                    }
                  })
                }

                // add them to the array
                if (pinned) {
                  matchRow.tds.unshift(matchValue)
                } else {
                  matchRow.tds.push(matchValue)
                }
              })
              if (addCurrentRow) {
                matchRows = [...matchRows, matchRow]
              }
            })
          }
        })
      }
      if (props.products) {
        Object.keys(props.products).forEach(key => {
          const product = props.products[key]
          if ((product.activeSubstances || []).length > 0) {
            product.activeSubstances.forEach((activeSubstance, mIndex) => {
              let newArray = {}
              asAttributes(language).forEach((attribute, aIndex) => {
                const matchRow = {
                  key: `${aIndex}_${mIndex}`,
                  tds: [],
                }
                const pinned = props.pinned && props.pinned === product.id

                const matchValue = {
                  key: `${aIndex}_${attribute.label}`,
                  pinned,
                  mIndex,
                  isCollapsed: mIndex === collapsedActiveSubstance,
                  id: activeSubstance.id,
                  label: attribute.label,
                  values: [],
                  isSpecification: false,
                  colSpan: 1,
                }
                const parsed = attribute.parse(activeSubstance)
                const text = parsed.hasValue ? parsed.value : '-'
                matchValue.values = [
                  ...matchValue.values,
                  {
                    text,
                  },
                ]
                // add them to the array
                if (pinned) {
                  matchRow.tds.unshift(matchValue)
                } else {
                  matchRow.tds.push(matchValue)
                }
                newArray = {
                  ...newArray,
                  [matchRow.key]: pinned
                    ? [
                        ...matchRow.tds,
                        ...(activeSubstances[matchRow.key] || []),
                      ]
                    : [
                        ...(activeSubstances[matchRow.key] || []),
                        ...matchRow.tds,
                      ],
                }
              })
              planetProofAttributes(language).forEach((attribute, aIndex) => {
                const matchRow = {
                  key: `${aIndex + 20}_${mIndex}`,
                  tds: [],
                }
                const pinned = props.pinned && props.pinned === product.id

                const matchValue = {
                  key: `${aIndex}_${attribute.label}`,
                  pinned,
                  mIndex,
                  isCollapsed: mIndex === collapsedActiveSubstance,
                  id: activeSubstance.id,
                  label: attribute.label,
                  values: [],
                  colSpan: 1,
                }

                const parsed = attribute.parse(product.planetProof)
                const text = parsed.hasValue ? parsed.value : '-'
                matchValue.values = [
                  ...matchValue.values,
                  {
                    text,
                  },
                ]
                // add them to the array
                if (pinned) {
                  matchRow.tds.unshift(matchValue)
                } else {
                  matchRow.tds.push(matchValue)
                }
                newArray = {
                  ...newArray,
                  [matchRow.key]: pinned
                    ? [
                        ...matchRow.tds,
                        ...(activeSubstances[matchRow.key] || []),
                      ]
                    : [
                        ...(activeSubstances[matchRow.key] || []),
                        ...matchRow.tds,
                      ],
                }
              })
              const text = `${mIndex}_spec`
              activeSubstances = {
                ...activeSubstances,
                [text]: [
                  {
                    key: text,
                    pinned: props.pinned && props.pinned === product.id,
                    mIndex,
                    isCollapsed: mIndex === collapsedActiveSubstance,
                    id: activeSubstance.id,
                    label: `Actieve stof #${mIndex + 1}`,
                    values: [
                      {
                        text: `Actieve stof #${mIndex + 1}`,
                      },
                    ],
                    isSpecification: true,
                    colSpan: props.ids.length,
                  },
                ],
                ...newArray,
              }
            })
          }
        })
      }

      return (
        <WrappedComponent
          matchRows={matchRows}
          activeSubstances={activeSubstances}
          toggleDosage={this._toggleDosage}
          collapsedDosage={this.state.collapsedDosage}
          toggleActiveSubstance={this._toggleActiveSubstance}
          collapsedActiveSubstance={this.state.collapsedActiveSubstance}
          {...this.props}
        />
      )
    }
  }

  const language = getLanguage()
  const enhance = compose(
    // mapProps(props => ({
    //   ...p
    // })),
    connect(
      state => {
        return {
          products: state.comparison.products.entities,
          pinned: state.comparison.products.pinned,
          loading: state.comparison.dosages.loading,
          matches: state.comparison.dosages.data,
          rows: pAttributes(language).map(({ key, func, config }) => ({
            key,
            label: config.label,
            results: state.comparison.products.entities
              ? [
                  ..._.values(
                    _.pick(
                      state.comparison.products.pinned,
                      state.comparison.products.entities
                    )
                  ),
                  ..._.map(
                    id => state.comparison.products.entities[id],
                    _.without(
                      [state.comparison.products.pinned],
                      state.comparison.products.result
                    )
                  ),
                ].map(
                  product =>
                    product && { ...func(product[key], config), id: product.id }
                )
              : [],
          })),
        }
      },
      dispatch => bindActionCreators({ load }, dispatch)
    ),

    setPropTypes({
      ids: PT.arrayOf(PT.string.isRequired).isRequired,
      loading: PT.bool.isRequired,
      isScrolling: PT.bool,

      products: PT.object.isRequired,
      pinned: PT.string,
      matches: PT.arrayOf(
        PT.shape({
          data: PT.object.isRequired,
          specification: PT.array.isRequired,
        })
      ),
    }),
    lifecycle({
      componentDidMount() {
        this.props.load(this.props.ids)
      },
    })
  )

  return enhance(HOC)
}
