import React from 'react'
import { AppRegistry } from 'react-native'
import iconFont from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'
import App from './src/App'
import { name as appName } from './app.json'
import './index.css'
import * as Sentry from '@sentry/react'
import SentryInit from "./src/Sentry/sentryInit";
import { nl, registerTranslation } from 'react-native-paper-dates'

// registerTranslation('en', en)
registerTranslation('nl-NL', nl)
// registerTranslation('pl', pl)
// registerTranslation('pt', pt)
// registerTranslation('de', de)

SentryInit(Sentry);

// function renderApp() {
//   return <App />
// }
// <AppContainer>

// </AppContainer>
// )

AppRegistry.registerComponent(appName, () => App)

// if (module.hot) {
//   // $FlowFixMe
//   module.hot.accept()

//   const renderHotApp = () => (
//     <AppContainer>
//       <App />
//     </AppContainer>
//   )

//   // App registration and rendering
//   AppRegistry.registerComponent(appName, () => renderHotApp)
// }

AppRegistry.runApplication(appName, {
  rootTag: document.getElementById('root'),
})

const iconFontStyles = `@font-face {
  src: url(${iconFont});
  font-family: MaterialCommunityIcons;
}`

// Create stylesheet
const style = document.createElement('style')
style.type = 'text/css'
if (style.styleSheet) {
  style.styleSheet.cssText = iconFontStyles
} else {
  style.appendChild(document.createTextNode(iconFontStyles))
}

// Inject stylesheet
document.head.appendChild(style)
