import React, { PureComponent } from 'react'
import { Keyboard, StyleSheet, TouchableOpacity, View } from 'react-native'

import { PDF_ROUTE, PRODUCT_ROUTE } from '../Routes.config'
import { push } from '../Navigation'
import ProductStatus from './ProductStatus'
import MatchStatus from './MatchStatus'
import PlanetProof from './PlanetProof'
import MPS from './MPS'
import ItemNoteIcons from './ItemNoteIcons'
import Skal from './Skal'
import SafeListItem from '../WidthAndHeight/SafeListItem'
import { getItemLabel, getItemStatus } from './helper'
import SelectionComponent from '../components/SelectionComponent'
import c from '../constants'
import { pushCustomEvent } from '../Analytics/abstraction'
import DocumentIcon from '../vectors/Document'

const getDocument = (documents, type) => {
  return (documents || []).find(d => d.type === type)
}
const getLink = (links, type) => {
  return (links || []).find(l => l.type === type)
}
const getPrescriptionUrl = item => {
  const document = getDocument(item.documents, 'prescription')
  const link = getLink((document || {}).links, 'pdf')
  const url = link && link.url
  return url
}

class ProductListItem extends PureComponent {
  // shouldComponentUpdate(nextProps) {
  //   if (nextProps.isSelected !== this.props.isSelected) {
  //     console.log({ next: nextProps.isSelected, prev: this.props.isSelected })
  //     return true
  //   }
  //   return false
  // }

  _openPrescription = () => {
    Keyboard.dismiss()
    const { item, componentId } = this.props
    const url = getPrescriptionUrl(item)

    const prescriptionUrl =
      url.startsWith('http') || url.startsWith('www')
        ? url
        : c.staticUrl + '/' + url

    pushCustomEvent(
      'Opened prescription from product list:',
      `${getItemLabel(item)}`
    )

    push(componentId, {
      component: {
        name: PDF_ROUTE,
        passProps: {
          title: getItemLabel(item),
          url: prescriptionUrl,
        },
      },
    })
  }
  _pressItem = () => {
    const { item, componentId } = this.props
      Keyboard.dismiss()
    push(componentId, {
      ignoreOnWeb: true,
      component: {
        name: PRODUCT_ROUTE,
        passProps: {
          id: item.id,
          name: getItemLabel(item),
        },
      },
    })
  }

  _select = () => {
    Keyboard.dismiss()
    const { item } = this.props
    this.props.onSelect(item)
    pushCustomEvent('Selected item:', `${getItemLabel(item)}`)
  }

  render() {
    const {
      item,
      isSelected,
      selectType,
      selectOnly,
      testID,
      description,
      noWcode,
    } = this.props
    const title = getItemLabel(item, noWcode)
    // console.log('product rendered')
    const url = getPrescriptionUrl(item)
    return (
      <SafeListItem
        title={title}
        testID={testID}
        description={description || ''}
        onPress={selectOnly ? this._select : this._pressItem}
        left={
          <SelectionComponent
            selectType={selectType}
            testID={`select_${testID}`}
            status={isSelected ? 'checked' : 'unchecked'}
            onPress={this._select}
          />
        }
        right={
          <View style={styles.rightContainer}>
            <PlanetProof planetProof={item.planetProof || []} product={item} />
            <Skal skal={item.skal || {}} product={item} />
            <MPS mps={item.mps || []} product={item} />
            <ProductStatus
              status={getItemStatus(
                item.introductionDate,
                item.lastDeliveryDate,
                item.lastUseDate,
                item.expirationDate,
                item.matchType
              )}
              product={item}
            />
            <MatchStatus status={item.matchType} product={item} />
            <ItemNoteIcons
              id={item.id}
              notes={item.notes}
              restrictionSummary={item.restrictionSummary}
              product={item}
            />
            {url ? (
              <TouchableOpacity onPress={this._openPrescription} style={styles.icon} >
                <DocumentIcon color={'blue'} />
              </TouchableOpacity>
            ) : null}
          </View>
        }
        accessibilityLabel={`Open ${title}`}
        to={selectOnly ? '' : `/product/${item.id}`}
        style={styles.listItem}
      />
    )
  }
}

const styles = StyleSheet.create({
  listItem: {
    borderRadius: 5,
  },
  rightContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: 24,
    height: 24,
    margin: 12,
  },
})
export default ProductListItem
