import { call, all, put, select, takeLatest } from 'redux-saga/effects'
import { fetchListener } from 'react-auth/lib/sagas/authentication'
import * as a from './actions'
import * as actions from '../../FilterType/Switches/actions'

function* createSaga(action) {
  try {
    const lines = yield select(
      state => (state.advice.listAndCrud.crud.object || { lines: [] }).lines
    )
    const selectedFieldsWithGrowingPeriod = yield select(
      state => state.advice.extra.selectedFieldsWithGrowingPeriod
    )
    const user = yield select(
      state => (state.auth && state.auth.user && state.auth.user.user) || {}
    )
    const pest = yield select(
      state =>
        (state.filter && state.filter.pest && state.filter.pest.selected) || {}
    )

    lines.forEach(line => (line.pest = pest[0]))

    // selectedFieldsWithGrowingPeriod.map(({growingPeriod,organizationField}) => )
    const advices = yield all(
      selectedFieldsWithGrowingPeriod.map(
        ({ growingPeriod, organizationField }) =>
          call(fetchListener, {
            path: 'advice',
            method: 'POST',
            body: {
              lines,
              growingPeriod,
              organizationField: organizationField,
              farmer: organizationField.organization,
              advisor: { id: user && user.user_id },
            },
          })
      )
    )

    yield put(actions.reset())

    yield put(a.created(advices))
  } catch (e) {
    console.log(e)
    yield put(a.created(e))
  }
}

export default function* rootSaga() {
  yield takeLatest(a.CREATE, createSaga)
}
