import React, { Component } from 'react'
import { View, StyleSheet, Linking, Platform } from 'react-native'
import { IconButton, List } from 'react-native-paper'

import Modal from '../Modal'
import safeAreaHOC from '../WidthAndHeight/safeAreaHOC'

const styles = StyleSheet.create({
  button: {
    position: 'absolute',
    right: 6,
    top: 0,
    opacity: 0.9,
    zIndex: 0,
    margin: 6,
    padding: 2,
    backgroundColor: 'transparent',
  },
  modal: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    alignItems: 'center',

    justifyContent: 'flex-end',
    margin: 0,
  },
  modalInner: {
    flex: 1,
    backgroundColor: '#FFF',
    zIndex: 5,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    width: '100%',

    paddingTop: 20,
    elevation: 56,
  },
})

const items = [
  {
    label: '© Mapbox',
    url: 'https://www.mapbox.com/about/maps/',
  },
  {
    label: '© OpenStreetMap',
    url: 'http://www.openstreetmap.org/copyright',
  },
  {
    label: '© DigitalGlobe',
    url: 'https://www.digitalglobe.com/',
  },
  {
    label: 'Improve this map',
    url: 'https://www.mapbox.com/map-feedback/',
  },
]

class MapboxAttribution extends Component {
  state = {
    open: false,
  }
  _press = (item) => () => {
    if (Platform.OS === 'web') {
      window.open(item.url)
    } else {
      Linking.openURL(item.url).catch((err) =>
        console.error('An error occurred', err)
      )
    }
  }
  _open = () => {
    this.setState({
      open: true,
    })
  }
  _close = () => {
    this.setState({
      open: false,
    })
  }

  render() {
    const { safe, fullScreen } = this.props
    const { open } = this.state

    return [
      <IconButton
        key={'information'}
        icon={'information'}
        color={'#fff'}
        onPress={this._open}
        style={[styles.button, { top: (fullScreen ? safe.top : 0) + 6 + 56 }]}
      />,
      <Modal
        key="modal"
        useNativeDriver
        style={styles.modal}
        isVisible={open}
        onBackdropPress={this._close}
        onModalHide={this._close}
        onSwipeComplete={this._close}
        onSwipeStart={this._close}
        onBackButtonPress={this._close}
        swipeDirectio={'down'}
        swipeThreshold={50}
        hideModalContentWhileAnimating={true}
        // backdropOpacity={Platform.OS === 'android' ? 0 : undefined}
        animationOutTiming={150}
        propagateSwipe={true}
        // scrollOffset={1}
      >
        <View style={[styles.modalInner, { maxHeight: 250 + safe.bottom }]}>
          {items.map((item) => (
            <List.Item
              key={`${item.label}`}
              title={item.label}
              onPress={this._press(item)}
              icon={'chevron-right'}
            />
          ))}
        </View>
      </Modal>,
    ]
  }
}

export default safeAreaHOC(MapboxAttribution)
