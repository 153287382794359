import React, { useCallback, useEffect, useMemo } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Platform, StyleSheet, View } from 'react-native'
import actions from '../../Farmer/Organization/User/List/actions'
import cA from '../../Farmer/Organization/User/actions'
import UserItem from './UserItem'
import List from '../../GeneralListFunctions/List'
import BottomFab from '../../WidthAndHeight/BottomFab'
import widthAndHeightHOC from '../../WidthAndHeight/widthAndHeightHOC'
import { Translate, Translator } from 'react-translated'
import PageHeader from '../../PageHeader'
import { Appbar } from 'react-native-paper'
import GeneralListHeader from '../../GeneralListFunctions/GeneralListHeader'
import Navigation, { push } from '../../Navigation'
import { ADD_ORGANIZATION_USER_ROUTE } from '../../Routes.config'
import { hasRole } from '../../helpers/role'

function UserList({ list, componentId, height, createdUser, ...rest }) {
  const dispatch = useDispatch()
  const organizationId = useSelector(state => state.auth.user.organization.id)
  const user = useSelector(state => state.auth.user.user)
  const isAdmin = hasRole(user, 'admin')
  const extraParams = useMemo(() => {
    return isAdmin
      ? {
          limit: 1000,
          number: 1000,
          'organizationIds[]': organizationId,
        }
      : {
          limit: 1000,
          number: 1000,
        }
  }, [organizationId, isAdmin])

  useEffect(() => {
    if (createdUser) {
      dispatch(actions.loadList({ params: extraParams }))
      dispatch(cA.reset())
    }
  }, [createdUser, dispatch, extraParams])

  useEffect(() => {
    dispatch(actions.loadList({ params: extraParams }))
  }, [dispatch, extraParams])

  const renderItem = useCallback(
    ({ item }) => {
      return (
        <UserItem
          item={item}
          componentId={componentId}
          organizationId={organizationId}
        />
      )
    },
    [componentId, organizationId]
  )

  const back = useCallback(() => {
    Navigation.pop(componentId)
  }, [componentId])

  const add = () => {
    push(componentId, {
      component: {
        name: ADD_ORGANIZATION_USER_ROUTE,
      },
    })
  }

  const listHeaderProps = {
    listKey: 'header',
    appbarContent: (
      <Translator>
        {({ translate }) => (
          <PageHeader
            back={back}
            componentId={componentId}
            title={
              <Appbar.Content
                title={translate({ text: 'myUsers' })}
                titleStyle={{
                  textAlign: 'center',
                }}
                style={{ flex: 1 }}
                ja
                subtitleStyle={{
                  textAlign: 'center',
                }}
              />
            }
          />
        )}
      </Translator>
    ),
    showSearch: false,
    autoFocusSearch: false,
    withoutHeaderBackground: true,
    onSearch: () => null,
    navbarTranslate: 0,
    navbarOpacity: 1,
    renderStickyBelowHeader: undefined,
    searchPlaceholder: '',
    searchIcon: '',
    onSearchIconPress: () => null,
    searchValue: '',
  }

  return (
    <>
      <GeneralListHeader {...listHeaderProps} />
      <View style={{ marginTop: Platform.OS === 'web' ? 60 : 90 }} />
      <List
        {...rest}
        list={list}
        renderItem={renderItem}
        extraParams={extraParams}
        silentReloading={false}
        reloadIfAlreadyLoaded
        contentContainerStyle={{ paddingBottom: 150 }}
        customEmptyTitle={<Translate text="noUsers" />}
        customEmptyDescription={<Translate text="addNewUser" />}
      />

      <Translator>
        {({ translate }) => (
          <BottomFab
            icon={'plus'}
            label={translate({ text: 'addUser' })}
            onPress={add}
          />
        )}
      </Translator>
    </>
  )
}

// eslint-disable-next-line
const styles = StyleSheet.create({})
const mapStateToProps = state => ({
  list: state.farmer.userList.list,
  createdUser: state.farmer.user.crud.created,
})

export default widthAndHeightHOC(connect(mapStateToProps, actions)(UserList))
