import { StyleSheet } from 'react-native'
import c from '../constants'
import fonts from '../fonts'

export default StyleSheet.create({
  fieldComponentWithButton: {
    flex: 1,
    maxWidth: 700,
    width: '100%',
    alignSelf: 'center',
  },
  innerCheckBoxStyle: {
    paddingLeft: c.textOffset,
  },

  termsContainer: {
    maxWidth: 400,
    width: '100%',
    alignSelf: 'center',
    padding: 10,
    paddingTop: 60 * 2,
  },
  terms: {
    //marginTop: c.textOffset,
  },
  termsText: {
    flex: 1,
    lineHeight: 22,
    padding: c.textOffset,
    // paddingRight: c.textOffset,
    color: c.whiteColor,
  },
  greenText: {
    color: c.greenColor,
    ...fonts.medium,
    textDecorationLine: 'underline',
    margin: 5,
  },
  fab: {
    position: 'absolute',
  },
})
