import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { Appbar, withTheme } from 'react-native-paper'

import Modal from '../Modal'
import safeAreaHOC from '../WidthAndHeight/safeAreaHOC'

class ClosableModal extends Component {
  render() {
    const { hideHeader, title, safePadding } = this.props

    return (
      <Modal
        animationInTiming={150}
        backdropTransitionInTiming={150}
        animationOutTiming={150}
        backdropTransitionOutTiming={150}
        useNativeDriver
        {...this.props}
        style={[
          styles.modal,
          !hideHeader && { paddingTop: safePadding.paddingTop },
        ]}
      >
        {!hideHeader ? (
          <View style={styles.headerContainer} pointerEvents="none">
            <Appbar style={styles.header} dark={true}>
              <Appbar.Content title={title} />
              <Appbar.Action
                pointerEvents="all"
                icon={'close'}
                onPress={this.props.onRequestClose}
              />
            </Appbar>
          </View>
        ) : (
          <View style={{ height: 10 }} />
        )}
        <View
          style={[
            styles.modalContent,
            // safePadding, DO NOT ADD THIS, THIS breaks some things on iOS please ask Richard for other solution
            !hideHeader && { paddingTop: 0 },
            hideHeader && {
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              overflow: 'hidden',
            },
          ]}
        >
          {this.props.children}
        </View>
      </Modal>
    )
  }
}

ClosableModal.propTypes = {}
ClosableModal.defaultProps = {}

const styles = StyleSheet.create({
  header: {
    backgroundColor: 'transparent',
    elevation: 0,
  },
  modal: {
    margin: 0,
    marginTop: 20,
    marginBottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerContainer: {
    width: '100%',
    maxWidth: 600,
  },
  modalContent: {
    flex: 1,
    width: '100%',
    maxWidth: 600,
    // height:"90%",
    backgroundColor: '#fff',
    borderRadius: 0,
    position: 'relative',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
})

export default safeAreaHOC(withTheme(ClosableModal))
