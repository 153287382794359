import React, { Component } from 'react'
import { StyleSheet, View } from 'react-native'
import { Text } from 'react-native-paper'
import FilterHeader from './FilterHeader'
import Resizer from '../WidthAndHeight/Resizer'
import ItemShimmer from '../components/Item.shimmer'
import c from '../constants'
import { filterTypesSorted } from './config'

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: '#fff',
  },
})
// const isWeb = Platform.OS === 'web'
// const isHeavy = activeId =>
//   filterTypesSorted.find(it => it.id === activeId).heavy

class FilterType extends Component {
  state = {
    loaded: true,
  }

  // componentDidMount() {
  //   if (isWeb && isHeavy(this.props.activeId)) {
  //     setTimeout(() => {
  //       this.setState({ loaded: true })
  //     }, 300)
  //   }
  // }

  render() {
    const { activeId, ...rest } = this.props
    const filterType = filterTypesSorted.find(it => it.id === activeId) || {}
    if (!filterType.SelectComponent) {
      return <Text>Filter not supported</Text>
    }
    if (!this.state.loaded) {
      return (
        <View style={styles.root}>
          <FilterHeader
            filterType={filterType}
            style={{
              paddingBottom: 56 / 2,
              height: 56 + 56 / 2,
              backgroundColor: c.greenColor,
            }}
          />
          <Resizer>
            <ItemShimmer icon />
            <ItemShimmer icon />
            <ItemShimmer icon />
          </Resizer>
        </View>
      )
    }

    return (
      <View style={styles.root}>
        <filterType.SelectComponent
          {...rest}
          filterType={filterType}
          filter={true}
        />
      </View>
    )
  }
}

export default FilterType
