import React, { useCallback, useEffect, useState } from 'react'
import { Appbar, Button, Snackbar } from 'react-native-paper'
import GeneralListHeader from '../GeneralListFunctions/GeneralListHeader'
import PageHeader from '../PageHeader'
import Navigation from '../Navigation'
import { Translate, Translator } from 'react-translated'
import { useDispatch, useSelector } from 'react-redux'
import { updateProfile } from 'react-auth/lib/actions/authentication'
import Resizer from '../WidthAndHeight/Resizer'
import { Platform, View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'
import Fields from '../Form/Fields'
import { usePrevious } from './ChangePassword'
// import UserList from '../Auth/Users/UserList'

const fieldProps = {
  style: { marginBottom: 16 },
}
const fields = [
  {
    key: 'text',
    name: 'email',
    type: 'email',
    label: 'E-mail',
    fieldProps: { ...fieldProps, disabled: true },
  },
  {
    key: 'text',
    name: 'identificationNumber',
    type: 'text',
    label: <Translate text="identificationNumber" />,
    fieldProps: { ...fieldProps, disabled: true },
  },
  {
    key: 'text',
    name: 'name',
    type: 'text',
    label: <Translate text="name" />,
    fieldProps,
  },
]

function Organization(props) {
  const { componentId } = props
  const user = useSelector(state => state.auth.user || {})
  const organizationReducer = useSelector(state => state.auth || {})
  const organizationCrud = user?.organization
  const prevUpdating = usePrevious(organizationReducer?.updating)

  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [organzationState, setOrganizationState] = useState(organizationCrud)

  useEffect(() => {
    if (
      organizationReducer?.updating !== prevUpdating &&
      !organizationReducer?.updating &&
      prevUpdating !== undefined
    ) {
      if (organizationReducer?.updatingError) {
        setError(true)
      } else {
        setSuccess(true)
      }
    }
  }, [prevUpdating, organizationReducer])

  const [disabled, setDisabled] = useState(true)

  const dispatch = useDispatch()

  const add = () => {
    dispatch(
      updateProfile({
        organization: organzationState,
        user: user?.user,
      })
    )
  }

  // const UserList = () => {}

  const editField = useCallback(
    (key, value) => {
      setOrganizationState({ ...organzationState, [key]: value })
    },
    [organzationState]
  )

  const back = useCallback(() => {
    Navigation.pop(componentId)
  }, [componentId])

  const listHeaderProps = {
    listKey: 'header',
    appbarContent: (
      <Translator>
        {({ translate }) => (
          <PageHeader
            back={back}
            componentId={componentId}
            title={
              <Appbar.Content
                title={translate({ text: 'myCompany' })}
                titleStyle={{
                  textAlign: 'center',
                }}
                style={{ flex: 1 }}
                subtitleStyle={{
                  textAlign: 'center',
                }}
              />
            }
          />
        )}
      </Translator>
    ),
    showSearch: false,
    autoFocusSearch: false,
    withoutHeaderBackground: true,
    onSearch: () => null,
    navbarTranslate: 0,
    navbarOpacity: 1,
    renderStickyBelowHeader: undefined,
    searchPlaceholder: '',
    searchIcon: '',
    onSearchIconPress: () => null,
    searchValue: '',
  }

  useEffect(() => {
    if (
      organzationState &&
      organzationState.name &&
      organzationState.name !== ''
    ) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [organzationState])

  return (
    <>
      <GeneralListHeader {...listHeaderProps} />
      <Translator>
        {({ translate }) => (
          <>
            <ScrollView>
              <>
                <Resizer
                  style={
                    Platform.OS === 'web'
                      ? { marginTop: 52, paddingBottom: 80 }
                      : { paddingBottom: 80 }
                  }
                >
                  <View style={{ padding: 16 }}>
                    <Fields
                      fields={fields}
                      values={organzationState}
                      onChange={editField}
                      onSubmit={add}
                    />
                    <Button
                      onPress={add}
                      mode={'outlined'}
                      uppercase={false}
                      disabled={disabled}
                    >
                      {translate({ text: 'save' })}
                    </Button>
                  </View>
                </Resizer>
              </>
            </ScrollView>
            <Snackbar
              visible={!!error}
              duration={30000}
              style={{ bottom: 100 }}
              onDismiss={() => setError(false)}
              action={{
                label: 'OK',
                onPress: () => {
                  setError(false)
                },
              }}
            >
              {translate({ text: 'saveError' })}
            </Snackbar>
            <Snackbar
              visible={!!success}
              duration={10000}
              style={{ bottom: 100 }}
              onDismiss={() => setSuccess(false)}
              action={{
                label: 'OK',
                onPress: () => {
                  setSuccess(false)
                },
              }}
            >
              {translate({ text: 'saveOrganizationSuccess' })}
            </Snackbar>
          </>
        )}
      </Translator>
    </>
  )
}

export default Organization
