import React from 'react'
import { View, StyleSheet } from 'react-native'
import { Text } from 'react-native-paper'
import safeAreaHOC from '../WidthAndHeight/safeAreaHOC'

const styles = StyleSheet.create({
  root: {
    height: 36,

    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    backgroundColor: 'red',
    zIndex: 1002,

    borderRadius: 8,
    left: 0,
    right: 0,
  },
  text: { color: 'white', fontSize: 11 },
})

function MapError({ label, safe, fullScreen }) {
  return (
    <View
      style={[
        styles.root,
        {
          bottom: (fullScreen ? safe.top : 0) + 6 + 56,
          marginLeft: safe.left + 56,
          marginRight: safe.left + 56,
        },
      ]}
    >
      <Text style={styles.text}>{label}</Text>
    </View>
  )
}

export default safeAreaHOC(MapError)
