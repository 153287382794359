import { get } from 'lodash/fp'

import { secondsToDDMMYYYY } from '../helpers/date'

const stringAttr = (string = '', config) => ({
  label: config.label,
  value: `${string}`,
  hasValue: !!string,
})

const objectAttr = (object = {}, config) => ({
  label: config.label,
  value: `${get(config.path, object)}`,
  hasValue: !!get(config.path, object),
})

// const arrayAttr = (array, config) => ({
//     label:
//         Array.isArray(array) && array.length > 1 ? config.labelN : config.label,
//     value: (Array.isArray(array) ? array : []).join(', '),
//     hasValue: Array.isArray(array) && !!array.length,
// })

// const activeSubstanceAttr = (array = [], config) => {
//     const arrayResult = arrayAttr(array, config)
//     const cleanedArray = (array || []).map(
//         item =>
//             `${get('amount', item)}${get('unit', item)} ${get(
//                 'activeSubstance.name',
//                 item
//             )}`
//     )
//
//     cleanedArray.sort()
//
//     return {
//         ...arrayResult,
//         value: cleanedArray.join(', '),
//     }
// }

// const arrayObjectAttr = (array = [], config) => {
//   const arrayResult = arrayAttr(array, config)

//   return {
//     ...arrayResult,
//     value: arrayResult.hasValue
//       ? array.map(item => get(config.path, item)).join(', ')
//       : '',
//   }
// }

const dateAttr = (date, config) => {
  return {
    label: config.label,
    value:
      date && date.expirationDate
        ? secondsToDDMMYYYY(date.expirationDate)
        : date
        ? secondsToDDMMYYYY(date)
        : '',
    hasValue: !!date,
  }
}

export const attributes = (language, isBio) =>
  isBio
    ? [
        {
          key: 'manufacturer',
          func: objectAttr,
          config: {
            label: language === 'nl' ? 'Fabrikant' : 'Manufacturer',
            path: 'name',
          },
        },
        {
          key: 'admissionCategory',
          func: stringAttr,
          config: {
            label:
              language === 'nl' ? 'Toelatingscategorie' : 'Admission category',
          },
        },
        {
          key: 'admissionNumber',
          func: stringAttr,
          config: {
            label: language === 'nl' ? 'Toelatingsnummer' : 'Admission number',
          },
        },
        {
          key: 'admissionProductType',
          func: stringAttr,
          config: {
            label:
              language === 'nl'
                ? 'Toelatings product type'
                : 'Admission product type',
          },
        },
        {
          key: 'admissionType',
          func: stringAttr,
          config: {
            label: language === 'nl' ? 'Toelatingstype' : 'Admission type',
          },
        },
        {
          key: 'useBy',
          func: stringAttr,
          config: {
            label: language === 'nl' ? 'Te gebruiken door' : 'Use by',
          },
        },
        {
          key: 'planetProof',
          config: {
            label: 'PlanetProof',
          },
          func: (d = {}) => {
            let value = '-'
            Array.isArray(d)
              ? d.forEach(it => {
                  value =
                    !it.id || it.id === ''
                      ? '-'
                      : it.forbidden
                      ? language === 'nl'
                        ? 'Niet toegestaan'
                        : 'Not allowed'
                      : language === 'nl'
                      ? 'Toegestaan'
                      : 'Allowed'
                })
              : (value = '-')
            return {
              hasValue: true,
              value,
              label: 'PlanetProof',
            }
          },
        },
        {
          key: 'mps',
          config: {
            label: 'MPS',
          },
          func: (d = {}) => {
            let value = '-'
            Array.isArray(d)
              ? d.forEach(it => {
                  value =
                    !it.id || it.id === ''
                      ? '-'
                      : it.forbidden
                      ? language === 'nl'
                        ? 'Niet toegestaan'
                        : 'Not allowed'
                      : language === 'nl'
                      ? 'Toegestaan'
                      : 'Allowed'
                })
              : (value = '-')
            return {
              hasValue: true,
              value,
              label: 'MPS',
            }
          },
        },
        {
          key: 'skal',
          config: {
            label: 'Skal',
          },
          func: dateAttr,
        },
      ]
    : [
        {
          key: 'admissionHolder',
          func: objectAttr,
          config: {
            label: language === 'nl' ? 'Toelatingshouder' : 'Admission holder',
            path: 'name',
          },
        },
        {
          key: 'manufacturer',
          func: objectAttr,
          config: {
            label: language === 'nl' ? 'Fabrikant' : 'Manufacturer',
            path: 'name',
          },
        },
        {
          key: 'distributors',
          func: (d = {}) => {
            let value = '-'
            if (Array.isArray(d)) {
              value = d.map(it => it.name).join(', ')
            }

            return {
              hasValue: true,
              value,
              label: language === 'nl' ? 'Distributeurs' : 'Distributors',
            }
          },
          config: {
            label: language === 'nl' ? 'Distributeurs' : 'Distributors',
          },
        },
        {
          key: 'wCode',
          func: stringAttr,
          config: {
            label: language === 'nl' ? 'W-code' : 'Subversion',
          },
        },
        // {
        //   key: 'FRACCode',
        //   func: stringAttr,
        //   config: {
        //     label: 'FRAC-code',
        //   },
        // },
        {
          key: 'admissionCategory',
          func: stringAttr,
          config: {
            label:
              language === 'nl' ? 'Toelatingscategorie' : 'Admission category',
          },
        },
        {
          key: 'admissionNumber',
          func: stringAttr,
          config: {
            label: language === 'nl' ? 'Toelatingsnummer' : 'Admission number',
          },
        },
        {
          key: 'admissionProductType',
          func: stringAttr,
          config: {
            label:
              language === 'nl'
                ? 'Toelatings product type'
                : 'Admission product type',
          },
        },
        {
          key: 'admissionType',
          func: stringAttr,
          config: {
            label: language === 'nl' ? 'Toelatingstype' : 'Admission type',
          },
        },
        {
          key: 'productType',
          func: objectAttr,
          config: {
            label: 'Product type',
            path: 'name',
          },
        },

        // {
        //   key: 'categories',
        //   func: arrayObjectAttr,
        //   config: {
        //     label: 'Categorie',
        //     labelN: 'Categorieën',
        //     path: 'name',
        //   },
        // },
        {
          key: 'introductionDate',
          func: dateAttr,
          config: {
            label:
              language === 'nl' ? 'Introductie datum' : 'Introduction date',
          },
        },
        {
          key: 'changedDate',
          func: dateAttr,
          config: {
            label: language === 'nl' ? 'Wijzigingsdatum' : 'Changed date',
          },
        },
        {
          key: 'expirationDate',
          func: dateAttr,
          config: {
            label: language === 'nl' ? 'Vervaldatum' : 'Expiration date',
          },
        },
        {
          key: 'lastDeliveryDate',
          func: dateAttr,
          config: {
            label:
              language === 'nl' ? 'Laatste leverdatum' : 'Last delivery date',
          },
        },
        {
          key: 'lastUseDate',
          func: dateAttr,
          config: {
            label:
              language === 'nl' ? 'Laatste gebruiksdatum' : 'Last use date',
          },
        },
        {
          key: 'useBy',
          func: stringAttr,
          config: {
            label: language === 'nl' ? 'Te gebruiken door' : 'Use by',
          },
        },
        {
          key: 'activeSubstances',
          func: (d = {}) => {
            let value = '-'
            if (d && d.length > 0) {
              value = d
                .map(it => {
                  if (it.activeSubstance && !!it.activeSubstance.frac) {
                    return `${it.activeSubstance.frac.code}`
                  }
                  return null
                })
                .filter(it => it)
                .join(', ')
              if (value.length === 0) {
                value = '-'
              }
            }

            return {
              hasValue: value !== '-',
              value,
              label: 'FRAC code',
            }
          },
          config: {
            label: 'FRAC code',
          },
        },
        {
          key: 'activeSubstances',
          func: (d = {}) => {
            let value = '-'
            if (d && d.length > 0) {
              value = d
                .map(it => {
                  if (it.activeSubstance && !!it.activeSubstance.hrac) {
                    return `${it.activeSubstance.hrac.code}`
                  }
                  return null
                })
                .filter(it => it)
                .join(', ')
              if (value.length === 0) {
                value = '-'
              }
            }

            return {
              hasValue: value !== '-',
              value,
              label: 'HRAC code',
            }
          },
          config: {
            label: 'HRAC code',
          },
        },
        {
          key: 'activeSubstances',
          func: (d = {}) => {
            let value = '-'
            if (d && d.length > 0) {
              value = d
                .map(it => {
                  if (it.activeSubstance && !!it.activeSubstance.irac) {
                    return `${it.activeSubstance.irac.code}`
                  }
                  return null
                })
                .filter(it => it)
                .join(', ')
              if (value.length === 0) {
                value = '-'
              }
            }

            return {
              hasValue: value !== '-',
              value,
              label: 'IRAC code',
            }
          },
          config: {
            label: 'IRAC code',
          },
        },
        {
          key: 'planetProof',
          config: {
            label: 'PlanetProof',
          },
          func: (d = {}) => {
            let value = '-'
            Array.isArray(d)
              ? d.forEach(it => {
                  value =
                    !it.id || it.id === ''
                      ? '-'
                      : it.forbidden
                      ? language === 'nl'
                        ? 'Niet toegestaan'
                        : 'Not allowed'
                      : language === 'nl'
                      ? 'Toegestaan'
                      : 'Allowed'
                })
              : (value = '-')
            return {
              hasValue: true,
              value,
              label: 'PlanetProof',
            }
          },
        },
        {
          key: 'mps',
          config: {
            label: 'MPS',
          },
          func: (d = {}) => {
            let value = '-'
            Array.isArray(d)
              ? d.forEach(it => {
                  value =
                    !it.id || it.id === ''
                      ? '-'
                      : it.forbidden
                      ? language === 'nl'
                        ? 'Niet toegestaan'
                        : 'Not allowed'
                      : language === 'nl'
                      ? 'Toegestaan'
                      : 'Allowed'
                })
              : (value = '-')
            return {
              hasValue: true,
              value,
              label: 'MPS',
            }
          },
        },
        {
          key: 'skal',
          config: {
            label: 'Skal',
          },
          func: dateAttr,
        },
      ]

export const processProduct = (object, language, isBio) => {
  console.log({ isBio })
  return attributes(language, isBio).map(({ key, func, config }) =>
    func(object[key], config)
  )
}
