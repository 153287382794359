import actions from './actions'
import saga from '../GeneralListFunctions/sagaFactory'

export default () =>
  saga(
    actions,
    state => {
      const organizationId =
        state.groupedCrop.list.params &&
        state.groupedCrop.list.params._organizationId
      return organizationId
        ? `v2/field/organization-crop/${organizationId}`
        : `v2/field/organization-crop`
    },

    state => state.groupedCrop
  )
