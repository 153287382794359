import {
  actionCreator,
  updatableListActions,
} from '../GeneralListFunctions/actionFactory'

const ns = 'REGISTRATION'
let allActions = updatableListActions(ns)

const DUPLICATE = `${ns}_DUPLICATE`
const DUPLICATED = `${ns}_DUPLICATED`

const DUPLICATE_RESET = `${ns}_DUPLICATE_RESET`

allActions = {
  ...allActions,
  // Add any custom actions here

  DUPLICATE,
  duplicate: actionCreator(DUPLICATE),
  DUPLICATED,
  duplicated: actionCreator(DUPLICATED),

  DUPLICATE_RESET,
  duplicateReset: actionCreator(DUPLICATE_RESET),
}
export default allActions
