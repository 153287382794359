import actions from './actions'
import { call, put, takeLatest } from 'redux-saga/effects'

import api from 'react-api'

function* loadList(payload) {
  try {
    console.log(payload)
    const response = yield call(api, {
      path: 'v1/kvk/search',
      params: payload.payload.params,
    })

    yield put(actions.loadedList(response))
  } catch (e) {
    console.log(e)
    yield put(actions.loadedList(e))
  }
}

export default function* saga() {
  yield takeLatest(actions.LOAD_LIST, loadList)
}
