import React, { Component } from 'react'
import { Platform, StyleSheet, TouchableHighlight, View } from 'react-native'
import { connect } from 'react-redux'
import actions from '../actions'
import FieldsMap from '../../FieldMap/FieldsMap'
import { Portal, Text, Title } from 'react-native-paper'
import { setMessages } from '../../Notifications/actions'
import round from '../../helpers/round'
import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import { Translate } from 'react-translated'

const styles = StyleSheet.create({
  customModal: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: -2,
    },
    shadowOpacity: 0.22,
    shadowRadius: 5,
    elevation: 1,
    backgroundColor: '#fff',

    zIndex: 210,
    bottom: 0,
    right: 0,
    left: 0,
    height: 150,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    maxWidth: 700,
    width: '100%',
  },
  closeButton: {
    padding: 10,
    paddingRight: 15,
    paddingLeft: 15,
    fontSize: 20,
  },
  title: {
    margin: 10,
    marginTop: 5,
    flex: 1,
  },
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
})
class LocationScreen extends Component {
  state = {
    selectedField: {},
  }
  _esc = () => e => {
    if (e.keyCode === 27) {
      this._close()
    }
  }
  _close = () => {
    this.props.setShowFieldInfo(false)
  }
  _selectField = (propsField, isCustomField) => {
    let field = propsField
    let customField
    if (isCustomField) {
      customField = propsField
      field = { id: null }
    }
    if (
      this.state.selectedField &&
      (this.state.selectedField.id === field.id ||
        (customField &&
          customField.fields.some(
            customFieldField =>
              customFieldField.id === this.state.selectedField.id
          ))) &&
      !!this.props.showFieldInfo
    ) {
      this._close()
    }
    if (
      this.props.list &&
      this.props.list.data &&
      (this.props.list.data.some(it => it.field && it.field.id === field.id) ||
        this.props.list.data.some(
          it =>
            customField &&
            customField.fields.some(customFieldField => {
              return it.field && it.field.id === customFieldField.id
            })
        )) !== true
    ) {
      if (this.props.selectedFarmer.length > 0) {
        // DOES THIS ALREADY EXIST?
        if (isCustomField) {
          if (
            this.props.customFieldArray &&
            this.props.customFieldArray.some(cf => customField.id === cf.id)
          ) {
            // Update existing item
            this.props.setData({
              customFieldArray: this.props.customFieldArray.filter(
                cf => cf.id !== customField.id
              ),
            })
          } else {
            let customFieldField = { ...customField.fields[0] }
            customField.fields.forEach(
              cf => (customFieldField.area = customFieldField?.area + cf?.area)
            )
            this.setState({
              selectedField: customFieldField,
            })
            this.props.setShowFieldInfo(true)
            // Add it to the array
            this.props.setData({
              customFieldArray: [
                ...((this.props.customFieldArray &&
                  this.props.customFieldArray) ||
                  []),
                customField,
              ],
            })
          }
        } else {
          if (
            this.props.fieldArray &&
            this.props.fieldArray.some(f => f.field.id === field.id)
          ) {
            // Update existing item
            this.props.setData({
              fieldArray: this.props.fieldArray.filter(
                f => f.field.id !== field.id
              ),
            })
          } else {
            this.setState({
              selectedField: field,
            })
            this.props.setShowFieldInfo(true)
            // Add it to the array
            this.props.setData({
              fieldArray: [
                ...((this.props.fieldArray && this.props.fieldArray) || []),
                { field, organization: this.props.selectedFarmer[0] },
              ],
            })
          }
        }
      } else {
        if (isCustomField) {
          if (
            this.props.customFieldArray &&
            this.props.customFieldArray.some(cf => cf.id === customField.id)
          ) {
            this.props.setData({
              customFieldArray: this.props.customFieldArray.filter(
                cf => cf.id !== customField.id
              ),
            })
          } else {
            let customFieldField = { ...customField.fields[0] }
            customField.fields.forEach(
              cf => (customFieldField.area = customFieldField?.area + cf?.area)
            )
            this.setState({
              selectedField: customFieldField,
            })
            this.props.setShowFieldInfo(true)
            this.props.setData({
              customFieldArray: [customField],
            })
          }
        } else {
          if (
            this.props.fieldArray &&
            this.props.fieldArray.some(f => f.field.id === field.id)
          ) {
            this.props.setData({
              fieldArray: this.props.fieldArray.filter(
                f => f.field.id !== field.id
              ),
            })
          } else {
            this.setState({
              selectedField: field,
            })
            this.props.setShowFieldInfo(true)
            this.props.setData({
              fieldArray: [
                { field, organization: this.props.selectedFarmer[0] },
              ],
            })
          }
        }
      }
    } else {
      if (this.props.selectedFarmer[0]) {
        alert('Dit teeltlocatie is al gekoppeld aan deze organisatie.')
      } else {
        if (isCustomField) {
          let customFieldField = { ...customField.fields[0] }
          customField.fields.forEach(
            cf => (customFieldField.area = customFieldField?.area + cf?.area)
          )
          this.setState({
            selectedField: customFieldField,
          })
          this.props.setShowFieldInfo(true)
          this.props.setData({
            customFieldArray: [customField],
          })
        } else {
          this.setState({
            selectedField: field,
          })
          this.props.setShowFieldInfo(true)
          this.props.setData({
            fieldArray: [{ field }],
          })
        }
      }
    }
  }
  componentDidMount() {
    if (Platform.OS === 'web') {
      document.addEventListener('keydown', this._esc(), false)
    }
    if (this.props.selectedFarmer.length > 0) {
      this.props.setData({
        organization: this.props.selectedFarmer[0],
      })
      this.props.loadList({
        params: {
          managedOrganizationId: this.props.selectedFarmer[0].id,
          preloadAll: true,
        },
      })
    }
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.save &&
      this.props.freshlyDrawnField !== prevProps.freshlyDrawnField
    ) {
      this.setState({
        selectedField: this.props.freshlyDrawnField,
      })

      this.props.setShowFieldInfo(true)
    }
    if (
      this.props.mergedField !== prevProps.mergedField &&
      this.props.mergedField.length > 0
    ) {
      let ha = 0
      this.props.mergedField.forEach(it => (ha = ha + it?.field?.area))
      const mergedCombinedField = {
        ...this.props.mergedField[0].field,
        area: ha,
      }
      this.setState({
        selectedField: mergedCombinedField,
      })

      this.props.setShowFieldInfo(true)
    }
  }
  render() {
    const { showDrawMenu, save } = this.props
    return (
      <>
        <FieldsMap
          onPressField={this._selectField}
          // selectedField={this.props.fieldArray && this.props.fieldArray[0]}
          selectedFields={this.props.fieldArray}
          selectedCustomFields={this.props.customFieldArray}
          alreadySelectedFields={
            this.props.selectedFarmer && this.props.selectedFarmer.length > 0
              ? this.props.list
              : []
          }
          componentId={this.props.componentId}
          onHeaderIconPress={this.props.prev}
          headerIcon={'arrow-left'}
          loaded={this.props.loaded}
          showDrawMenu={showDrawMenu}
          save={save}
          portalActive={this.props.showFieldInfo}
        />

        {this.props.showFieldInfo && (
          <>
            <Portal>
              <View pointerEvents="box-none" style={styles.container}>
                <View style={styles.customModal}>
                  <View
                    style={{
                      flexDirection: 'row',
                    }}
                  >
                    <View style={styles.title}>
                      <Title>
                        <Translate text="parcelInfo" />
                      </Title>
                    </View>
                    <TouchableHighlight
                      onPress={this._close}
                      accessibilityTraits={'button'}
                      accessibilityComponentType={'button'}
                      accessibilityRole={'button'}
                      // style={{ flex: 1 }}
                      underlayColor={'rgba(0,0,0,0.3)'}
                    >
                      <Icon
                        name="close"
                        style={styles.closeButton}
                        color={'#000'}
                      />
                    </TouchableHighlight>
                  </View>
                  <View style={{ margin: 10, marginTop: 5 }}>
                    <View style={{ flexDirection: 'row' }}>
                      <Text>
                        <Translate text="area" />:{' '}
                      </Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        {round(
                          (this.state.selectedField?.area || 0) / 10000,
                          3
                        )}{' '}
                        ha
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </Portal>
          </>
        )}
      </>
    )
  }
}

LocationScreen.propTypes = {}
LocationScreen.defaultProps = {}

const mapStateToProps = state => ({
  fieldArray:
    state.organizationField.crud.object &&
    state.organizationField.crud.object.fieldArray,
  customFieldArray:
    state.organizationField.crud.object &&
    state.organizationField.crud.object.customFieldArray,
  loaded: state.organizationField.crud.object,
  selectedFarmer: state.filter.advisorFarmer.selected,
  list: state.organizationField.list,
  freshlyDrawnField:
    state.organizationField.crud.object &&
    state.organizationField.crud.object.freshlyDrawnField,
})
export default connect(mapStateToProps, { ...actions, setMessages })(
  LocationScreen
)
