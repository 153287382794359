import React, { Component } from 'react'

import { Menu, withTheme } from 'react-native-paper'

import FilterMenuItem from './FilterMenuItem'
import widthAndHeightHOC from '../WidthAndHeight/widthAndHeightHOC'
import FilterTag from './FilterTag'
import { isFilterSet } from '../helpers/utils'

class FilterMenu extends Component {
  state = {
    visible: false,
  }

  _openMenu = () => this.setState({ visible: true })
  _closeMenu = () => this.setState({ visible: false })

  render() {
    const { visible } = this.state
    const {
      filters,
      filterTypes,
      componentId,
      dispatch,
      context,
      withoutText,
    } = this.props
    return (
      <Menu
        visible={visible}
        onDismiss={this._closeMenu}
        anchor={
          <FilterTag
            onPress={this._openMenu}
            label={'Filter'}
            testID={'filterMenuButton'}
          />
        }
      >
        {filterTypes
          .filter(f => !isFilterSet(f, filters))
          .map(f => {
            return (
              <FilterMenuItem
                componentId={componentId}
                testID={`filter_menu_${f.id}`}
                key={f.id}
                filter={f}
                selected={filters[f.id]}
                onClose={this._closeMenu}
                dispatch={dispatch}
                context={context}
                withoutText={withoutText}
              />
            )
          })}
      </Menu>
    )
  }
}

FilterMenu.propTypes = {}
FilterMenu.defaultProps = {}
export default withTheme(widthAndHeightHOC(FilterMenu))
