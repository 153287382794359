import * as React from 'react'
import { useSelector } from 'react-redux'
import actions from './actions'
import GeneralList from '../../GeneralListFunctions/GeneralList'
import FilterHeader from '../FilterHeader'
import Navigation, { push } from '../../Navigation'

import ItemInList from '../ItemInList'
import ItemParent from '../ItemParent'
import { FILTER_MODAL_ROUTE } from '../../Routes.config'
import { pushCustomEvent } from '../../Analytics/abstraction'
import { ResistanceType } from './reducer'
import { RootState } from '../../reducers'
import { useActionsAsFunctions } from '../../helpers/actions'
import { FilterType } from '../config'
import { getInitialListState } from '../../GeneralListFunctions/reducerFactory'
import SafeListItem from '../../WidthAndHeight/SafeListItem'

import Icon from 'react-native-vector-icons/MaterialCommunityIcons'
import Resizer from '../../WidthAndHeight/Resizer'
import { Platform } from 'react-native'

type Category = {
  id: number
  name: string
  description: string
  filter: string
}

const categories: Category[] = [
  { id: 1, name: 'FRAC', description: 'Fungicide', filter: 'frac' },
  { id: 2, name: 'HRAC', description: 'Herbicide', filter: 'hrac' },
  { id: 3, name: 'IRAC', description: 'Insecticide', filter: 'irac' },
]

const categoriesList = {
  ...getInitialListState<Category>(),
  data: categories,
  loadingList: false,
  loadingMoreList: false,
  loadedList: true,
}

function getExtraParams(item?: any) {
  console.log(item)
  return {
    number: 100000,
    limit: 100000,
    language: process.env.REACT_APP_API_LANG || 'nl_nl',
    sort: 'code',
    type: item ? item.filter : '',
  }
}

function PreList({
  parentId,
  context,
  componentId,
  filterType,
}: {
  parentId: number | string
  componentId: string
  context: string
  filterType: FilterType
}) {
  const [extraParams, setExtraParams] = React.useState<any>(null)

  const selected = useSelector(
    (state: RootState) => state.filter.resistance.selected
  )
  const resistanceList = useSelector(
    (state: RootState) => state.filter.resistance.list
  )
  const isCategory = !parentId
  const list = isCategory ? categoriesList : resistanceList

  const actionAsDispatchers = useActionsAsFunctions(actions)

  React.useEffect(() => {
    const item = categories.find((cat) => `${cat.id}` === `${parentId}`)
    console.log(item, parentId)
    setExtraParams(getExtraParams(item))
  }, [parentId, setExtraParams])

  const _pop = () => {
    Navigation.pop(componentId)
  }

  const _openCategory = (it: Category) => {
    const item = categories.find((cat) => `${cat.id}` === `${it.id}`)
    pushCustomEvent(`Filtering on ${item?.name}`, ``, { name: item?.name })
    push(componentId, {
      component: {
        name: FILTER_MODAL_ROUTE,
        passProps: {
          context: context,
          activeId: 'resistance',
          parentId: it.id,
        },
      },
    })
  }
  const _renderItem = ({ item }: { item: ResistanceType | Category }) => {
    if (isCategory) {
      const category = item as Category
      return (
        <Resizer>
          <SafeListItem
            key={category.id}
            title={category.name}
            description={category.description}
            onPress={() => _openCategory(category)}
            right={
              <Icon
                style={{ position: 'absolute', right: 0 }}
                name={'chevron-right'}
                size={24}
              />
            }
          />
        </Resizer>
      )
    }

    return (
      <ItemInList
        onPress={actionAsDispatchers.select}
        onSelect={actionAsDispatchers.select}
        item={item}
        filterType={filterType}
        isSelected={selected.some(
          (it: ResistanceType) =>
            `${it.code}_${it.type}` ===
            `${(item as ResistanceType).code}_${(item as ResistanceType).type}`
        )}
        selectType="checkbox"
      />
    )
  }

  const _goUp = () => {
    if (Platform.OS === 'web') {
      push(componentId, {
        component: {
          name: FILTER_MODAL_ROUTE,
          passProps: {
            context: context,
            activeId: 'resistance',
          },
        },
      })
    } else {
      Navigation.pop(componentId)
    }
  }
  const _renderAboveHeader = () => {
    const item = categories.find((cat) => `${cat.id}` === `${parentId}`)
    if (item) {
      return <ItemParent title={item.name} onPress={_goUp} />
    }
  }

  return (
    // @ts-ignore
    <GeneralList<ResistanceType | Category>
      {...actionAsDispatchers}
      list={list}
      searchPlaceholder={filterType.searchPlaceholder}
      showSearch={false}
      renderItem={_renderItem}
      renderAboveHeader={_renderAboveHeader}
      icon
      appbarContent={
        <FilterHeader
          filterType={filterType}
          componentId={componentId}
          onBack={_pop}
        />
      }
      extraParams={extraParams}
      onPressFab={_pop}
    />
  )
}

export default PreList
