import * as React from 'react'
import { Animated, Platform, StyleSheet, View } from 'react-native'

import ErrorMessages from '../components/ErrorMessages'
import DetailRowShimmer from '../components/Item.shimmer'
import { getStatusBarHeight } from 'react-native-iphone-x-helper'
import 'react-native-get-random-values'
import { v4 as uuidv4 } from 'uuid'
import safeAreaHOC from '../WidthAndHeight/safeAreaHOC'
import Empty from './Empty'
import GeneralListHeader from './GeneralListHeader'
import SafeListItem from '../WidthAndHeight/SafeListItem'
import SelectionComponent from '../components/SelectionComponent'
import BottomFab from '../WidthAndHeight/BottomFab'
import widthAndHeightHOC from '../WidthAndHeight/widthAndHeightHOC'
import { ListType } from './reducerFactory'
import UpdateNotification from '../Update/UpdateNotification'
import { pushCustomEvent } from '../Analytics/abstraction'
// @ts-ignore
import { Translator } from 'react-translated'
import { getLanguage, getTranslatedString } from '../language'

const ITEM_HEIGHT = 65

function getDefaultItemLayout(data: any, index: any) {
  return {
    length: ITEM_HEIGHT,
    offset: ITEM_HEIGHT * index,
    index,
  }
}

export interface GeneralListProps<T> extends Partial<DefaultProps<T>> {
  componentId: string
  list: ListType<T>
  selected: T[]
  selectAll?: boolean
  extraParams?: any
  searchPlaceholder?: string
  searchIcon?: string
  rerenderKey?: string
  loadWhenLoadingList?: boolean
  withoutHeaderBackground?: boolean
  safePadding?: {
    paddingLeft: number
    paddingRight: number
    paddingTop: number
    paddingBottom: number
  }
  renderListHeader?: () => any
  renderBelowFooter?: () => any
  renderAboveHeader?: () => any
  renderEmptyState?: () => any
  renderItem: ({ item, index }: { item: T; index: number }) => any

  showSearch?: boolean
  icon?: boolean
  isLarge?: boolean
  renderEmptyWithZeroData?: boolean
  disableRenderBelowFooterWhenEmpty?: boolean
  isFarmer?: boolean //REMOVE

  disableSafe?: boolean
  onPressFab?: () => any
  noHeader?: boolean
  fabLabel?: string
  fabIcon?: string
  fabLoading?: boolean
  testID?: string

  onSearchIconPress?: () => any
  toggleSelectAll?: () => any
  renderStickyBelowHeader?: () => any
  appbarContent?: any

  loadList: (payload: any, meta?: any, error?: any) => any
  reloadList: (payload: any, meta?: any, error?: any) => any
  loadMoreList?: (payload?: any, meta?: any, error?: any) => any
  onSearch?: (value: string) => any
  customDescription?: string
  customTitle?: string
}

type DefaultProps<T> = {
  getParams: (listProps: GeneralListProps<T>, search?: string) => any
  loadListOnMount: boolean
  withStatusBar: boolean
  icon: boolean
  fabLabel: string
  onlyShowFabWhenSelected: boolean
  selected: T[]
  disableSafe: boolean
  showSearch: boolean
  silentReloadingOnMount: boolean
  autoFocusSearch: boolean
}

type GeneralListState = {
  scrollAnim: any
  offsetAnim: any
  listKey: string
  clampedScroll: any
}

class GeneralList<T> extends React.Component<
  GeneralListProps<T>,
  GeneralListState
> {
  constructor(props: GeneralListProps<T>) {
    super(props)

    const scrollAnim = new Animated.Value(0)
    const offsetAnim = new Animated.Value(0)

    this.state = {
      scrollAnim,
      offsetAnim,
      listKey: `${uuidv4()}`,
      clampedScroll: Animated.diffClamp(
        Animated.add(
          scrollAnim.interpolate({
            inputRange: [0, 1],
            outputRange: [0, 1],
            extrapolateLeft: 'clamp',
          }),
          offsetAnim
        ),
        0,
        NAVBAR_HEIGHT - 0
      ),
    }

    let animatedParams = { useNativeDriver: true }
    if (isWeb) {
      //@ts-ignore
      animatedParams.listener = this._handleScroll
    }
    //@ts-ignore
    this._animatedEvent = Animated.event(
      [{ nativeEvent: { contentOffset: { y: this.state.scrollAnim } } }],
      animatedParams
    )
  }

  static defaultProps = {
    getParams: (listProps: any, search: string) => {
      // ALSO CHANGE IN LIST.js
      let { sort, preloads, extraParams } = listProps
      // if (search === undefined) {
      //   search =
      //     listProps &&
      //     listProps.list &&
      //     listProps.list.params &&
      //     listProps.list.params.search
      // }
      if (preloads === undefined) {
        preloads =
          listProps &&
          listProps.list &&
          listProps.list.params &&
          listProps.list.params.preloads
      }
      return {
        ...((listProps && listProps.list && listProps.list.params) || {}),
        sort,
        search,
        // eslint-disable-next-line
        ['search[name]']: search,
        name: search,
        preloads,
        ...extraParams,
      }
    },
    loadListOnMount: true,
    withStatusBar: true,
    icon: false,

    fabLabel: undefined,
    onlyShowFabWhenSelected: false,
    selected: [],
    disableSafe: true,
    showSearch: true,
    silentReloadingOnMount: true,
    autoFocusSearch: false,
  }

  _showingStatusBar = true
  _clampedScrollValue = 0
  _offsetValue = 0
  _scrollValue = 0

  UNSAFE_componentWillMount() {
    if (this.props.loadListOnMount) {
      this.props.loadList(
        {
          // @ts-ignore
          params: this.props.getParams(this.props),
        },
        { silentReloading: this.props.silentReloadingOnMount }
      )
    }
  }

  componentDidUpdate(prevProps: GeneralListProps<T>) {
    if (
      JSON.stringify(prevProps && prevProps.extraParams) !==
        JSON.stringify(this.props && this.props.extraParams) &&
      prevProps.loadListOnMount
    ) {
      this.props.loadList({
        //@ts-ignore
        params: this.props.getParams(this.props),
      })
    }
  }

  componentDidMount() {
    this.state.scrollAnim.addListener(({ value }: any) => {
      const diff = value - this._scrollValue
      this._scrollValue = value
      this._clampedScrollValue = Math.min(
        Math.max(this._clampedScrollValue + diff, 0),
        NAVBAR_HEIGHT - 0
      )
    })
    this.state.offsetAnim.addListener(({ value }: any) => {
      this._offsetValue = value
    })
  }

  componentWillUnmount() {
    this.state.scrollAnim.removeAllListeners()
    this.state.offsetAnim.removeAllListeners()
  }

  _handleScroll = ({ nativeEvent }: any) => {
    const nearBottom = isCloseToBottom(nativeEvent)
    if (nearBottom) {
      this._loadMore()
    }
  }

  _loadMore = () => {
    const { stopLoadingMore, loadingMoreList } = this.props.list
    if (!stopLoadingMore && !loadingMoreList) {
      this.props.loadMoreList && this.props.loadMoreList()
    }
  }
  _onScrollEndDrag = () => {
    //@ts-ignore
    this._scrollEndTimer = setTimeout(this._onMomentumScrollEnd, 250)
  }

  _onMomentumScrollBegin = () => {
    //@ts-ignore
    clearTimeout(this._scrollEndTimer)
  }

  _onMomentumScrollEnd = () => {
    const toValue =
      this._scrollValue > NAVBAR_HEIGHT &&
      this._clampedScrollValue > (NAVBAR_HEIGHT - 0) / 2
        ? this._offsetValue + NAVBAR_HEIGHT
        : this._offsetValue - NAVBAR_HEIGHT

    Animated.timing(this.state.offsetAnim, {
      toValue,
      duration: 350,
      useNativeDriver: true,
    }).start()
  }
  _search = (value: string) => {
    if (
      this.props.searchPlaceholder &&
      this.props.searchPlaceholder.includes('product')
    ) {
      pushCustomEvent(`Searching in products for`, `"${value}"`)
    }
    this.props.onSearch && this.props.onSearch(value)
    this.props.loadList &&
      this.props.loadList(
        //@ts-ignore
        { params: this.props.getParams(this.props, value) },
        { silentReloading: false }
      )
  }

  _renderEmptyState = () => {
    const { customDescription, customTitle } = this.props
    const search = this.props.list.params && this.props.list.params.search
    const isReallyEmpty = !hasFiltersInParam(
      //@ts-ignore
      this.props.getParams(this.props, search)
    )
    if (this.props.renderEmptyState) {
      return this.props.renderEmptyState()
    }

    //@ts-ignore
    return (
      <Empty
        isReallyEmpty={isReallyEmpty}
        search={search}
        customDescription={customDescription}
        customTitle={customTitle}
      />
    )
  }
  _toggleAll = () => {
    //@ts-ignore
    this.props.toggleSelectAll()
  }
  _renderSelectAll = () => {
    const { selected, selectAll, list } = this.props
    if (!selectAll) {
      return null
    }
    const itemsCount = (list.data || []).length

    return (
      <>
        <Translator>
          {({ translate }: any) => (
            <SafeListItem
              // title={'Selecteer'}
              title={
                selected.length === itemsCount
                  ? translate({ text: 'deselectAll' })
                  : translate({ text: 'selectAll' })
              }
              description={
                selected.length === itemsCount
                  ? `${translate({ text: 'deselect' })} ${itemsCount} items`
                  : `${translate({ text: 'select' })} ${itemsCount} items`
              }
              componentId={this.props.componentId}
              // titleStyle={{ color: '#525252', fontSize: 13 }}
              left={
                <SelectionComponent
                  status={
                    selected.length > 0 && selected.length === itemsCount
                      ? 'checked'
                      : 'unchecked'
                  }
                  // pointerEvents="none"
                  selectType="checkbox"
                  withPadding
                  onPress={this._toggleAll}
                />
              }
              onPress={this._toggleAll}
            />
          )}
        </Translator>
        <View style={{ height: 10 }} />
      </>
    )
  }
  _renderListHeader = () => {
    const {
      searchPlaceholder,
      searchIcon,
      onSearchIconPress,
      renderStickyBelowHeader,
      appbarContent,
      showSearch,
      autoFocusSearch,
      noHeader,
      withoutHeaderBackground,
    } = this.props
    const { clampedScroll, listKey } = this.state
    const navbarTranslate = clampedScroll.interpolate({
      inputRange: [0, NAVBAR_HEIGHT - 0],
      outputRange: [0, -(NAVBAR_HEIGHT - 0)],
      extrapolate: 'clamp',
    })

    const navbarOpacity = clampedScroll.interpolate({
      inputRange: [0, NAVBAR_HEIGHT - 0],
      outputRange: [1, 0],
      extrapolate: 'clamp',
    })
    const navbarWhiteOpacity = clampedScroll.interpolate({
      inputRange: [0, NAVBAR_HEIGHT - 0],
      outputRange: [0, 1],
      extrapolate: 'clamp',
    })
    const searchValue = this.props.list.params && this.props.list.params.search

    const listHeaderProps = {
      navbarTranslate,
      navbarOpacity,
      navbarWhiteOpacity,
      listKey,
      appbarContent,
      renderStickyBelowHeader,
      searchPlaceholder,
      searchIcon,
      onSearchIconPress,
      onSearch: this._search,
      searchValue,
      showSearch,
      autoFocusSearch,
      withoutHeaderBackground,
    }
    return noHeader ? null : <GeneralListHeader {...listHeaderProps} />
  }

  _renderHeader = () => {
    const {
      renderAboveHeader,
      showSearch,
      icon,
      renderStickyBelowHeader,
      isLarge,
      renderEmptyWithZeroData,
      isFarmer, // TODO: REMOVE!!
      loadWhenLoadingList,
      noHeader,
    } = this.props
    const {
      data,
      loadingListError,
      loadingListErrorMessage,
      loadingList,
      loadedList,
    } = this.props.list

    const renderEmpty =
      (loadedList && data && data.length === 0 && !loadingListError) ||
      (renderEmptyWithZeroData && data && data.length === 0)
    let marginStyle = styles.paddingInnerTop
    if (showSearch) {
      marginStyle = styles.paddingInnerTopWithSearch
    }
    if (renderStickyBelowHeader && renderAboveHeader && !isLarge && !isFarmer) {
      marginStyle = styles.paddingInnerTopWithDoubleTop
    } else if (renderStickyBelowHeader && !isLarge && !isFarmer) {
      marginStyle = styles.paddingInnerTopWithDoubleTop
    } else {
      if (!showSearch) {
        marginStyle = styles.paddingInnerTopWithStickyBottomWithoutSearch
      } else {
        marginStyle = styles.paddingInnerTopWithStickyBottom
      }
    }

    return (
      <>
        {noHeader ? null : !this.props.renderListHeader ? (
          <View style={marginStyle} />
        ) : null}

        {renderAboveHeader ? renderAboveHeader() : null}
        {loadingListError ? (
          <ErrorMessages messages={[loadingListErrorMessage]} />
        ) : null}
        {!renderEmpty && !loadingList && !loadingListError
          ? this._renderSelectAll()
          : null}
        {/* <DetailRowShimmer icon={true} /> */}
        <UpdateNotification />
        {(loadingList && !loadedList) || (loadingList && loadWhenLoadingList)
          ? LoadingArray.map((_, i) => (
              <DetailRowShimmer key={`shimmer_${i}`} icon={icon} />
            ))
          : null}
        {renderEmpty && this._renderEmptyState()}
      </>
    )
  }
  _renderFooter = () => {
    const { icon, renderEmptyWithZeroData, disableRenderBelowFooterWhenEmpty } = this.props
    const {
      data,
      loadingListError,
      loadedList,
      loadingMoreList
    } = this.props.list
    const { listKey } = this.state

    const renderEmpty =
        (loadedList && data && data.length === 0 && !loadingListError) ||
        (renderEmptyWithZeroData && data && data.length === 0)

    return (
      <View key={`footer-${listKey}`}>
        {loadingMoreList
          ? LoadingArray.map((none, i) => (
              <DetailRowShimmer key={`shimmer-${i}`} icon={icon} />
            ))
          : null}
        {
          this.props.renderBelowFooter &&
          !(disableRenderBelowFooterWhenEmpty && renderEmpty) ? // Do not render below footer if empty and flag set to true
              this.props.renderBelowFooter() : null
        }
      </View>
    )
  }
  _keyExtractor = (item: any) =>
    `id_${this.state.listKey}_${item.id}_${
      item.attributes && item.attributes.map((a: any) => a.id)
    }_${item.name}_${item.variety}_${item.code}_${item.type}`

  isItemLoaded = ({ index }: { index: number }) => !!this.props.list.data[index]

  render() {
    const {
      // width,
      // height,
      // safe,
      selected,
      rerenderKey,
      reloadList,
      safePadding,
      disableSafe,
      onPressFab,
      fabLabel,
      fabIcon,
      fabLoading,
      testID,

      onlyShowFabWhenSelected,
    } = this.props
    let {
      reloadingList,
      loadingMoreList,
      loadingList,
      // pagination,
      data,
    } = this.props.list
    const ListImpl = Animated.FlatList as any
    const language = getLanguage()
    return (
      <>
        <ListImpl
          style={{ flex: 1 }}
          key={`${this.state.listKey}_flatlist`}
          contentContainerStyle={
            disableSafe
              ? { paddingBottom: 48 } // For help button
              : { ...safePadding, paddingBottom: 48 } // For help button
          }
          keyboardDismissMode={'on-drag'}
          keyboardShouldPersistTaps={'handled'}
          contentInsetAdjustmentBehavior={'never'}
          listKey={this.state.listKey}
          data={data as any}
          renderItem={this.props.renderItem}
          refreshing={reloadingList || false}
          onRefresh={reloadList as any}
          ListFooterComponent={this._renderFooter}
          ListHeaderComponent={this._renderHeader}
          keyExtractor={this._keyExtractor}
          extraData={`${loadingList}_${loadingMoreList}_${rerenderKey}_${
            selected && selected.length
          }_${data && data.length}`}
          progressViewOffset={160 + STATUS_BAR_HEIGHT}
          scrollEventThrottle={1}
          onMomentumScrollBegin={this._onMomentumScrollBegin}
          onMomentumScrollEnd={this._onMomentumScrollEnd}
          onScrollEndDrag={this._onScrollEndDrag}
          getItemLayout={getDefaultItemLayout}
          //@ts-ignore
          onScroll={this._animatedEvent}
          onEndReached={this._loadMore}
          onEndReachedThreshold={10}
          initialNumToRender={20}
        />

        {onPressFab &&
          (!onlyShowFabWhenSelected ||
            (onlyShowFabWhenSelected && selected && selected.length > 0)) && (
            <BottomFab
              icon={fabIcon}
              label={fabLabel || getTranslatedString('done', language)}
              onPress={onPressFab}
              loading={fabLoading}
              testID={testID}
            />
          )}

        {this._renderListHeader()}
      </>
    )
  }
}

// also in generalListHeader
const STATUS_BAR_HEIGHT = getStatusBarHeight() || 0

export const NAVBAR_HEIGHT = 56 - 6

const styles = StyleSheet.create({
  list: {
    flex: 1,
    alignItems: 'center',
  },
  footer: {
    height: 100,
    justifyContent: 'center',
    alignItems: 'center',
  },
  paddingInnerTopWithStickyBottom: {
    paddingTop: NAVBAR_HEIGHT + 56 / 2 + STATUS_BAR_HEIGHT + 24 + 8,
  },
  paddingInnerTopWithStickyBottomWithoutSearch: {
    paddingTop: NAVBAR_HEIGHT + 56 / 2 + STATUS_BAR_HEIGHT + 24 + 8 - 56,
  },
  paddingInnerTopWithDoubleTop: {
    paddingTop: NAVBAR_HEIGHT + 56 / 2 + STATUS_BAR_HEIGHT + 24 + 8 + 56,
  },
  paddingInnerTop: {
    paddingTop: NAVBAR_HEIGHT + STATUS_BAR_HEIGHT + 8,
  },
  paddingInnerTopWithSearch: {
    paddingTop: NAVBAR_HEIGHT + 56 / 2 + STATUS_BAR_HEIGHT + 24 + 8,
  },
  fab: {
    position: 'absolute',
  },
})

const isCloseToBottom = ({
  layoutMeasurement,
  contentOffset,
  contentSize,
}: {
  layoutMeasurement: {
    height: number
    width: number
  }
  contentOffset: {
    y: number
  }
  contentSize: {
    height: number
    width: number
  }
}) => {
  const paddingToBottom = 400
  return (
    layoutMeasurement.height + contentOffset.y >=
    contentSize.height - paddingToBottom
  )
}

export const hasFiltersInParam = (params: any = {}) => {
  const keys = Object.keys(params).filter(
    key =>
      !key.startsWith('_') &&
      key !== 'onlyUserManaged' &&
      key !== 'sort' &&
      key !== 'limit' &&
      key !== 'offset' &&
      key !== 'page' &&
      key !== 'number' &&
      key !== 'expirationDateFrom' &&
      key !== 'preloadAll' &&
      key !== 'preloads' &&
      !!params[key]
  )

  return keys.length > 0
}

export const LoadingArray = Array(10).fill(0)
const isWeb = Platform.OS === 'web'

export default widthAndHeightHOC(safeAreaHOC(GeneralList)) as <T>(
  props: GeneralListProps<T>
) => any
