import React, { Component } from 'react'

const styles = {
  full: {
    alignItems: 'stretch',
    borderWidth: 0,
    borderStyle: 'solid',
    boxSizing: 'border-box',

    flexDirection: 'column',
    margin: 0,
    padding: 0,
    position: 'relative',
    zIndex: 0,
    // fix flexbox bugs
    minHeight: 0,
    minWidth: 0,
    flex: 1,
    // display: 'inline-flex',
  },
}

class FormContainer extends Component {
  _submit = e => {
    if (e) {
      e.preventDefault()
    }

    this.props.onSubmit()
  }
  render() {
    return (
      // <View style
      <form action={''} method={''} onSubmit={this._submit} style={styles.full}>
        {this.props.children}
        <input type="submit" className="hidden-submit" tabIndex="-1" />
      </form>
    )
  }
}
export default FormContainer
