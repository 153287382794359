import { combineReducers } from 'redux'

import auth from 'react-auth/lib/reducers/authentication'

import product from './Product/reducer'
import products from './Products/reducer'
import authSettings from './Auth/reducer'
import filter from './FilterType/reducer'
import comparison from './Comparison/reducer'
import notification from './Notifications/reducer'
import organizationFieldGrowingPeriod from './OrganizationField/New/GrowingPeriod/reducer'
import widthAndHeight from './WidthAndHeight/reducer'
import forgotPassword from 'react-auth/lib/reducers/forgotPassword'
import organizationField from './OrganizationField/reducer'
import advice from './Advice/reducer'
import groupedCrop from './GroupedCrop/reducer'
import map from './Map/reducer'
import fieldMap from './FieldMap/reducer'
import fieldOrganizationMap from './FieldOrganizationMap/reducer'
import singleField from './FieldOrganizationMap/single/reducer'
import productSingleSelect from './Products/ProductSingleSelect/reducer'
import additiveSingleSelect from './Products/AdditiveSingleSelect/reducer'
import search from './MapSearch/reducer'
import crop from './Product/Crop/List/reducer'
import pest from './Product/Pest/List/reducer'
import contract from './GroupedCrop/Contract/List/reducer'
import growingPeriodAttributes from './GroupedCrop/Attributes/List/reducer'
import farmer from './Farmer/reducer'
import registration, { duplicateReducer } from './Registration/reducer'
import growingPeriod from './Registration/GrowingPeriod/reducer'
import registrationAdvice from './Registration/New/Advice/reducer'
import activity from './Activity/reducer'
import distribution from './Distribution/reducer'
import unit from './Form/Unit/reducer'
import observation from './Observations/reducer'
import provider from './Auth/providerLogic/reducer'
import beneficialOrganism from './BeneficialOrganism/List/reducer'
import sideEffect from './BeneficialOrganism/SideEffect/reducer'
import filterActions from './Products/FilterAction/reducer'
import unitInterval from './Form/Unit/unitInterval/reducer'
import requestAccount from './Auth/logic/reducer'
import knpv from './KNPV/reducer'
import extraInfoAdmin from './Auth/ExtraInfoAdmin/reducer'
import inviteUser from './Farmer/Organization/User/Invite/reducer'

const rootReducer = combineReducers({
  registration,
  filterActions,
  requestAccount,
  knpv,
  provider,
  observation,
  auth,
  beneficialOrganism,
  organizationFieldGrowingPeriod,
  sideEffect,
  farmer,
  duplicateReducer,
  unit,
  distribution,
  product,
  unitInterval,
  registrationAdvice,
  growingPeriodAttributes,
  additiveSingleSelect,
  activity,
  products,
  advice,
  groupedCrop,
  singleField,
  notification,
  authSettings,
  growingPeriod,
  crop,
  pest,
  filter,
  comparison,
  forgotPassword,
  contract,
  widthAndHeight,
  map,
  fieldMap,
  fieldOrganizationMap,
  search,
  organizationField,
  productSingleSelect,
  extraInfoAdmin,
  inviteUser,
})

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
