import React, { useCallback } from 'react'
import SafeListItem from '../../WidthAndHeight/SafeListItem'
import { push } from '../../Navigation'
import * as routes from '../../Routes.config'

function UserItem({ item, organizationId, componentId, upperOnPress, left }) {
  const onPress = useCallback(() => {
    push(componentId, {
      component: {
        name: routes.EDIT_ORGANIZATION_USER_ROUTE,
        passProps: {
          organizationId: organizationId,
          id: item.id,
        },
      },
    })
  }, [componentId, item, organizationId])
  return (
    <SafeListItem
      title={getUserName(item)}
      description={item.email || ''}
      onPress={upperOnPress ? () => upperOnPress(item) : onPress}
      left={left}
      // right={
      //   <View style={styles.rightContainer}>
      //     <MatchStatus status={item.matchType} />
      //   </View>
      // }
    />
  )
}
export const getUserName = item => {
  let itemName = ''
  if (item.name && item.name !== '') {
    itemName += ' '
    itemName += item.name
  }
  if (item.first_name && item.first_name !== '') {
    itemName += item.first_name
  }
  if (item.preposition && item.preposition !== '') {
    itemName += ' '
    itemName += item.preposition
  }
  if (item.last_name && item.last_name !== '') {
    itemName += ' '
    itemName += item.last_name
  }

  return itemName
}
export default UserItem
