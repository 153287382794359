import React, { Component } from 'react'
// import { StyleSheet } from 'react-native'
import { List } from 'react-native-paper'
import ClosableModal from '../../components/ClosableModal'
import ProductSingleSelectList from './ProductSingleSelectList'
import { View } from 'react-native'
import { Translator } from 'react-translated'

class ProductSingleSelect extends Component {
  state = {
    modalVisible: false,
  }
  _hideModal = () => {
    this.setState({ modalVisible: false })
  }
  _showModal = () => {
    this.setState({ modalVisible: true })
  }
  _isSelected = item => {
    const valueId = this.props.value && this.props.value.id
    return item.id === valueId
  }
  _select = item => {
    this._hideModal()
    this.props.onSelect(item)
  }

  render() {
    const { modalVisible } = this.state
    const { value, style, editable, extraParams } = this.props

    return (
      <Translator>
        {({ translate }) => (
          <>
            <List.Item
              onPress={editable ? this._showModal : null}
              title={value ? value.name : translate({ text: 'selectProduct' })}
              description={value ? 'Product' : ''}
              right={
                editable ? p => <List.Icon {...p} icon="chevron-down" /> : null
              }
              style={style}
            />

            <ClosableModal
              isVisible={modalVisible}
              onBackButtonPress={this._hideModal}
              onBackdropPress={this._hideModal}
              onRequestClose={this._hideModal}
              onModalHide={this._hideModal}
              hideHeader={true}
            >
              <View style={{ marginBottom: 25, height: 600 }}>
                <ProductSingleSelectList
                  value={value}
                  onSelect={this._select}
                  onBack={this._hideModal}
                  extraParamsFromProps={extraParams}
                />
              </View>
            </ClosableModal>
          </>
        )}
      </Translator>
    )
  }
}
ProductSingleSelect.defaultProps = {
  editable: true,
}
export default ProductSingleSelect
