import actions from './actions'
import reducer from '../../GeneralListFunctions/reducerFactory'

interface ModeOfAction {
  id: string
  code: string
  name: {
    en: string
  }
}

export interface ResistanceType {
  code: string
  type: string
  modeOfAction: ModeOfAction
}

export default reducer<ResistanceType>(actions)
