import React from 'react'
import Polygon from '../Map/Polygon'
import { useSelector } from 'react-redux'
import { geoJSON } from '../Map/helpers'

function Polygons({ selectedField, fillColor, opacity, selectedFields }) {
  const data = useSelector((state) => state.fieldMap.data)
  console.log({ selectedFields })
  return (
    <>
      {selectedFields && selectedFields.length > 0 ? (
        <Polygon
          key={`selectedField_${selectedFields.length}_${fillColor}`}
          id={'field'}
          shape={geoJSON(selectedFields)}
          fillColor={fillColor ? fillColor : '#1DA1F2'}
          fillOpacity={opacity ? opacity : 0.5}
          zIndex={2}
          strokeWeight={0}
        />
      ) : (
        selectedField && (
          <Polygon
            key={'field'}
            id={'field'}
            shape={geoJSON([selectedField])}
            fillColor={fillColor ? fillColor : '#1DA1F2'}
            fillOpacity={opacity ? opacity : 0.5}
            zIndex={2}
            strokeWeight={0}
          />
        )
      )}
      {data.field && (
        <Polygon
          key={'field_border'}
          id={'field_border'}
          shape={data.field}
          fillColor={'#FFFFFF'}
          fillOpacity={0}
          strokeWeight={2}
          strokeColor={'#1DA1F2'}
          zIndex={1}
        />
      )}
    </>
  )
}

export default React.memo(Polygons)
