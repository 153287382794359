import AppbarHeader from '../../WidthAndHeight/AppbarHeader'
import AppbarResizer from '../../WidthAndHeight/AppbarResizer'
import { Appbar } from 'react-native-paper'
import KeyboardAvoidingScrollView from '../../WidthAndHeight/KeyboardAvoidingScrollView'
import { StyleSheet, View } from 'react-native'
import FormContainer from '../../Form/FormContainer'
import { Translate, Translator } from 'react-translated'
import Fields from '../../Form/Fields'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import a from '../actions'
import BottomFab from '../../WidthAndHeight/BottomFab'

const fields = [
  {
    name: 'growingPeriods',
    label: <Translate text="growingPeriods" />,
    type: 'growingPeriods',
    canDelete: true,
  },
]
function AddCrops({ onFinish, isLoading }) {
  const dispatch = useDispatch()
  const organizationField = useSelector(state => state.organizationField?.crud)
  const innerChange = (name, value) => {
    dispatch(
      a.setData({
        ...organizationField?.object,
        sections: organizationField?.object?.sections?.map(section => {
          if (
            organizationField?.object?.selectedSections?.some(
              s => s.id === section.id
            )
          ) {
            console.log('section', value)
            console.log(
              'real',
              (value || [])?.map(v => ({
                ...v,

                certifications: [
                  ...(v?.certifications || []),
                  v?.planetProof ? 'PLANETPROOF' : null,
                  v?.demeter ? 'DEMETER' : null,
                  v?.mps ? 'MPS' : null,
                  v?.skal ? 'SKAL' : null,
                ].filter(c => c),
                organization: organizationField?.object?.organization,
              }))
            )
            return {
              ...section,
              growingPeriods: (value || [])?.map(v => ({
                ...v,

                certifications: [
                  ...(v?.certifications || []),
                  v?.planetProof ? 'PLANETPROOF' : null,
                  v?.demeter ? 'DEMETER' : null,
                  v?.mps ? 'MPS' : null,
                  v?.skal ? 'SKAL' : null,
                ].filter(c => c),
                organization: organizationField?.object?.organization,
              })),
            }
          }
          return section
        }),
        selectedSections: organizationField?.object?.selectedSections?.map(
          section => {
            return {
              ...section,
              growingPeriods: (value || [])?.map(v => ({
                ...v,
                certifications: [
                  ...(v?.certifications || []),
                  v?.planetProof ? 'PLANETPROOF' : null,
                  v?.demeter ? 'DEMETER' : null,
                  v?.mps ? 'MPS' : null,
                  v?.skal ? 'SKAL' : null,
                ].filter(c => c),
                organization: organizationField?.object?.organization,
              })),
            }
          }
        ),
      })
    )
  }
  return (
    <>
      <Translator>
        {({ translate }) => (
          <>
            <AppbarHeader
              key="header"
              dark
              style={{ elevation: 0, justifyContent: 'center' }}
            >
              <AppbarResizer>
                <Appbar.BackAction
                  onPress={() => onFinish(null, true)}
                  color="#fff"
                />
                <Appbar.Content
                  title={translate({ text: 'crop' })}
                  color="#fff"
                />

                <Appbar.Action
                  icon={'check'}
                  onPress={() =>
                    onFinish(
                      organizationField?.object?.selectedSections?.length > 0 &&
                        organizationField?.object?.selectedSections[0]?.growingPeriods?.map(
                          gp => {
                            return {
                              ...gp,
                              certifications: [
                                ...(gp?.certifications || []),
                                gp?.planetProof ? 'PLANETPROOF' : null,
                                gp?.demeter ? 'DEMETER' : null,
                                gp?.mps ? 'MPS' : null,
                                gp?.skal ? 'SKAL' : null,
                              ].filter(c => c),
                            }
                          }
                        )
                    )
                  }
                  color="#fff"
                />
                {/* <Appbar.Action icon={'delete'} onPress={onRemove} color="#fff" /> */}
              </AppbarResizer>
            </AppbarHeader>
            <KeyboardAvoidingScrollView>
              <View
                style={{
                  maxWidth: 800,
                  alignSelf: 'center',
                  width: '100%',
                  marginTop: 32,
                }}
              >
                <FormContainer onSubmit={() => console.log('submit')}>
                  <Fields
                    fields={fields}
                    values={
                      organizationField?.object?.selectedSections &&
                      organizationField?.object?.selectedSections[0]
                    }
                    onChange={innerChange}
                    onSubmit={() => console.log('submit')}
                  />
                </FormContainer>
              </View>
              <View style={styles.paddingScroll} />
            </KeyboardAvoidingScrollView>

            <BottomFab
              testID="next"
              style={[styles.fab]}
              label={translate({ text: 'save' })}
              icon="chevron-right"
              loading={isLoading}
              onPress={() => {
                onFinish(
                  organizationField?.object?.selectedSections?.length > 0 &&
                    organizationField?.object?.selectedSections[0]?.growingPeriods?.map(
                      gp => {
                        return {
                          ...gp,
                          certifications: [
                            ...(gp?.certifications || []),
                            gp?.planetProof ? 'PLANETPROOF' : null,
                            gp?.demeter ? 'DEMETER' : null,
                            gp?.mps ? 'MPS' : null,
                            gp?.skal ? 'SKAL' : null,
                          ].filter(c => c),
                        }
                      }
                    )
                )
              }}
              type="submit"
            />
          </>
        )}
      </Translator>
    </>
  )
}

const styles = StyleSheet.create({
  fab: {
    zIndex: 200,
  },
})
export default AddCrops
