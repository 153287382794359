import React, { useCallback } from 'react'
import { push } from '../Navigation'
import { View } from 'react-native'
import { Button, Text } from 'react-native-paper'
import widthAndHeightHOC from '../WidthAndHeight/widthAndHeightHOC'

function MenuButton({
  title,
  renderIcon = () => null,
  halfWidth,
  route,
  componentId,
  testID,
}) {
  const onPress = useCallback(
    route => {
      push(componentId, {
        component: {
          name: route,
        },
      })
    },
    [componentId]
  )
  return (
    <Button
      testID={testID}
      style={[
        {
          flex: 1,
          margin: 10,
          shadowColor: '#000',
          shadowOffset: {
            width: 0,
            height: 2,
          },
          shadowOpacity: 0.15,
          shadowRadius: 3.84,
          justifyContent: 'center',
        },
        halfWidth ? { minWidth: '45%' } : { minWidth: '80%' },
      ]}
      mode={'outlined'}
      onPress={() => onPress(route)}
    >
      <View
        style={[
          {
            padding: 25,
            flex: 1,
            alignItems: 'center',
          },
          halfWidth
            ? { minWidth: '45%', maxWidth: '100%' }
            : { minWidth: '80%', maxWidth: '100%' },
        ]}
      >
        {renderIcon()}
        <Text
          style={{
            paddingTop: 15,
            paddingBottom: 20,
            flexGrow: 1,
            fontWeight: 600,
            color: '#08325C',
          }}
          ellipsizeMode={'tail'}
          numberOfLines={1}
        >
          {title}
        </Text>
      </View>
    </Button>
  )
}

export default widthAndHeightHOC(MenuButton)
