import React, {useEffect} from 'react'
import ClosableModal from '../../components/ClosableModal'
import {Appbar, Title, Text, DataTable, Divider, Button} from 'react-native-paper'
import {Platform, ScrollView, StyleSheet, View} from "react-native";
import AppbarHeader from "../../WidthAndHeight/AppbarHeader";
import AppbarResizer from "../../WidthAndHeight/AppbarResizer";
import KeyboardAvoidingScrollView from "../../WidthAndHeight/KeyboardAvoidingScrollView";
import {openEppoPage} from "../../helpers/openWebPage";
import {Translator} from "react-translated";
import {getLanguage} from "../../language";
import SafeAreaHOC from "../../WidthAndHeight/safeAreaHOC";
import DataTableCellMultiLine from "../../components/DataTableCellMultiLine";

const capitalizeFirstLetter = s => (s && s[0].toUpperCase() + s.slice(1)) || "-"

const capTranslate = (translate, string) => capitalizeFirstLetter(translate({text: string}))

/**
 * Sorts two items based on their language. The user language will always go first, then Latin, then the rest alphabetically
 *
 * @param a the first item
 * @param b the second item
 * @param userLanguage the user language in capital casing
 * @returns {number} normal sorting number
 */
const sortByLanguage = (a, b, userLanguage) => {
  const langA = a.language.toUpperCase()
  const langB = b.language.toUpperCase()

  if (langA === userLanguage ^ langB === userLanguage) {  // If either one is the user language but not both

    return langA === userLanguage ? -1 : 1
  } else if (langA === 'LA' ^ langB === 'LA') {           // If either one is Latin but not both

    return langA === 'LA' ? -1 : 1
  } else if (langA === langB) {
    return 0
  }
  return langA < langB ? -1 : 1
}

function EppoModal({
  onRequestClose,
  isVisible,
  item
}) {
  const [alternativeNames, setAlternativeNames] = React.useState([])

  useEffect(() => {
    if (isVisible) {
      const userLanguage = getLanguage().toUpperCase()

      // Sort list first on isPreferred, then language, then isActive
      const sortedList = item?.eppo?.names.sort((a, b) => {

        // Sort on isPreferred
        const preferredA = a.isPreferred
        const preferredB = b.isPreferred
        if (preferredA < preferredB) {
          return 1
        } else if (preferredA > preferredB) {
          return -1
        }

        // isPreferred are the same, sort on language
        switch (sortByLanguage(a, b, userLanguage)) {
          case -1:
            return -1
          case 1:
            return 1
          default:
        }

        // isPreferred and languages are the same, sort on isActive
        const activeA = a.isActive
        const activeB = b.isActive
        if (activeA < activeB) {
          return 1
        } else if (activeA > activeB) {
          return -1
        }

        return 0
      })

      setAlternativeNames(sortedList)
    }
  }, [item, isVisible])

  return (
    <ClosableModal
      isVisible={isVisible}
      onBackButtonPress={onRequestClose}
      onBackdropPress={onRequestClose}
      onRequestClose={onRequestClose}
      onModalHide={onRequestClose}
      hideHeader={true}
    >
      <View
        style={{
          height: Platform.OS === 'web' ? 600 : '100%'
        }}
      >
        {isVisible && (
          <Translator>
            {({translate}) => (
              <>
                <AppbarHeader
                  key="header"
                  dark
                  style={{ elevation: 0, justifyContent: 'center' }}
                >
                  <AppbarResizer>
                    <Appbar.BackAction onPress={onRequestClose} color="#fff" />
                    <Appbar.Content title={capTranslate(translate, 'naming')} color="#fff" />
                  </AppbarResizer>
                </AppbarHeader>
                  <KeyboardAvoidingScrollView
                    safeSettings={{ bottom: true, left: true, right: true }}
                    style={styles.fill}
                  >
                    <Title style={styles.header}>{item.name}</Title>
                    <Text style={styles.text}>{item?.latinName}</Text>
                    <Divider style={styles.divider} />
                    <Title style={styles.header}>EPPO</Title>
                    <Text style={styles.text}>{`${capTranslate(translate, 'code')}: ${item?.eppo?.code}`}</Text>
                    <View style={styles.buttonContainer}>
                      <Button
                        icon="open-in-new"
                        mode="text"
                        onPress={() => openEppoPage({code: item?.eppo?.code})}
                        contentStyle={styles.buttonContent}
                      >
                        {capTranslate(translate, 'seeSource')}
                      </Button>
                    </View>
                    <View style={styles.container}>
                      <ScrollView horizontal style={styles.fill} contentContainerStyle={styles.table}>
                        <DataTable>
                          <DataTable.Header style={styles.row}>
                            <DataTable.Title style={styles.colWide}>{capTranslate(translate, 'name')}</DataTable.Title>
                            <DataTable.Title style={styles.colSmall}>{capTranslate(translate, 'language')}</DataTable.Title>
                            <DataTable.Title style={styles.colSmall}>{capTranslate(translate, 'active')}</DataTable.Title>
                            <DataTable.Title style={styles.colHalf}>{capTranslate(translate, 'preferredName')}</DataTable.Title>
                            <DataTable.Title style={styles.colWide}>{capTranslate(translate, 'author')}</DataTable.Title>
                          </DataTable.Header>
                          {alternativeNames.map(item => {
                            return (
                              <DataTable.Row key={`${item.fullName},${item.isActive},${item.isPreferred}`} style={styles.row}>
                                <DataTableCellMultiLine style={styles.colWide} numberOfLines={10}>{capitalizeFirstLetter(item.fullName)}</DataTableCellMultiLine>
                                <DataTable.Cell style={styles.colSmall}>{item.language.toUpperCase()}</DataTable.Cell>
                                <DataTable.Cell style={styles.colSmall}>{item.isActive ? translate({text: 'yes'}) : translate({text: 'no'})}</DataTable.Cell>
                                <DataTable.Cell style={styles.colHalf}>{item.isPreferred ? translate({text: 'yes'}) : translate({text: 'no'})}</DataTable.Cell>
                                <DataTableCellMultiLine style={styles.colWide} numberOfLines={10}>{capitalizeFirstLetter(item.authority)}</DataTableCellMultiLine>
                              </DataTable.Row>
                            )}
                          )}
                        </DataTable>
                      </ScrollView>
                    </View>
                  </KeyboardAvoidingScrollView>
              </>
            )}
          </Translator>
        )}
      </View>
    </ClosableModal>
  )
}

const styles = StyleSheet.create({
  fill: {
    flex: 1,
  },
  header: {
    margin: 20,
    marginTop: 20,
  },
  divider: {
    marginTop: 20,
  },
  container: {
    marginTop: 0,
    margin: 20,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: 4,
  },
  buttonContent: {
    flexDirection: 'row-reverse',
  },
  text: {
    marginLeft: 20,
    marginTop: 10,
  },
  table: {
    flexGrow: 1,
    width: '100%',
    minWidth: 543,
  },
  colWide: {
    flex: 3.5,
    padding: 5,
  },
  colHalf: {
    flex: 2.7,
    padding: 5,
  },
  colSmall: {
    flex: 1.7,
    padding: 5,
  },
  row: {
    paddingLeft: 5,
    paddingRight: 5,
  },
})

export default SafeAreaHOC(EppoModal)
