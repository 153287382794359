import React, { useCallback } from 'react'
import { Image, Keyboard, Platform } from 'react-native'
import { useDispatch } from 'react-redux'
import { setMessages } from '../Notifications/actions'

import { IconButton } from 'react-native-paper'
import { pushCustomEvent } from '../Analytics/abstraction'
import { getItemLabel } from './helper'
import { fromTimestamp, getFriendlyDate } from '../dayjs'
import { getLanguage } from '../language'

const Skal = ({ skal, product }) => {
  const dispatch = useDispatch()
  const language = getLanguage()
  const onPress = useCallback(
    item => {
      Keyboard.dismiss()
      dispatch(
        setMessages([item.expirationDate === -1 ?( language === 'nl' ? 'Permanente Skal toelating.' :  'Permanent Skal allowance.') :
          language === 'nl'
            ? `Skal toelating t/m ${getFriendlyDate(
                fromTimestamp(item.expirationDate)
              )}.`
            : `Skal allowed until ${getFriendlyDate(
                fromTimestamp(item.expirationDate)
              )}.`,
        ])
      )
      pushCustomEvent(
        'Pressed Skal icon on product:',
        `${getItemLabel(product)}`
      )
    },
    [dispatch, product, language]
  )
  if (
    Object.keys(skal).length > 0
  ) {
    return (
      <IconButton
        key={skal.id}
        onPress={() => onPress(skal)}
        icon={({ size }) => (
          <Image
            style={{ width: size, height: size }}
            source={
              Platform.OS === 'web'
                ? require('../../img/webp/skal-logo.webp')
                : require('../../img/skal-logo.png')
            }
          />
        )}
      />
    )
  }
  return null
}

export default Skal
