import { actionCreator as ac } from '../actionCreator'

const ns = type => `PRODUCT_${type}`

export const LOAD = ns('LOAD')
export const LOADED = ns('LOADED')
export const LOAD_CROPS = ns('LOAD_CROPS')
export const LOADED_CROPS = ns('LOADED_CROPS')
export const LOAD_PESTS = ns('LOAD_PESTS')
export const LOADED_PESTS = ns('LOADED_PESTS')

export const load = ac(LOAD)
export const loaded = ac(LOADED)
export const loadCrops = ac(LOAD_CROPS)
export const loadedCrops = ac(LOADED_CROPS)
export const loadPests = ac(LOAD_PESTS)
export const loadedPests = ac(LOADED_PESTS)
