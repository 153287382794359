import { all, call, put, select, take, takeLatest } from 'redux-saga/effects'
// import { Platform } from 'react-native'
import { fetchListener } from 'react-auth/lib/sagas/authentication'
import * as resetActions from '../FilterType/Switches/actions'
// import {  filterPageParamsSelector } from '../FilterType/config'
import api from 'react-api'

export const numberPerRequest = 200
// DISABLE THIS IN PROD, NECESSARY FOR users in test
// export const numberPerRequest = Platform.OS === 'web' ? 50 : 100

function* load(actions, getPath, getState, action) {
  try {
    console.log({ actions, getPath, getState, action })
    const state = yield select(state => state)
    const path = getPath(state)
    const { crud } = yield select(state => getState(state))
    const { object } = crud
    const innerId =
      object && object.id
        ? object.id
        : action && action.payload
        ? action.payload
        : undefined
    const { params, fetchOptions, cachedFromList, extraURL } = action.meta || {}

    if (!innerId && !extraURL) {
      yield put(actions.loaded(object))
      return
    }

    if (cachedFromList) {
      yield put(actions.loaded(object))
      // return
    }
    const response = yield call(fetchListener, {
      path: extraURL ? extraURL : `${path}/${innerId}`,
      params,
      ...fetchOptions,
    })

    yield put(actions.loaded(response))
  } catch (e) {
    yield put(actions.loaded(e, null, true))
  }
}

function* loadList(actions, getPath, getState, action, loadMore) {
  try {
    const state = yield select(state => state)
    const url = getPath(state)
    const { list } = yield select(state => getState(state))
    // const filterParams = yield select(filterPageParamsSelector)

    console.log(action.payload)
    const meta = action.meta || {}
    const disableAuthentication =
      (action && action.payload && action.payload.disableAuthentication) ||
      false

    let params = {
      limit: numberPerRequest,
      number: numberPerRequest,
      ...list.params,
      ...meta.params,
      // ...filterParams,
    }

    if (loadMore) {
      const { offset, total } = list
      if (offset > total) {
        return
      }
      params.offset = (offset || 0) + numberPerRequest
    }

    let realURL = url
    if (meta && meta.addToPath) {
      realURL = `${url}${meta.addToPath}`
    }
    let response
    console.log('disableAuthentication', disableAuthentication)
    if (disableAuthentication) {
      console.log('withdisabled')
      response = yield call(api, {
        method: 'GET',
        path: realURL,
        params,
        fetchMeta: {
          // please keep this for fast loading!!
          optional: url.includes('product'),
        },
      })
    } else {
      response = yield call(fetchListener, {
        method: 'GET',
        path: realURL,
        params,
        fetchMeta: {
          // please keep this for fast loading!!
          optional: url.includes('product'),
        },
      })
    }
    yield put(
      loadMore
        ? actions.loadedMoreList(response, meta)
        : actions.loadedList(response, meta)
    )
  } catch (e) {
    console.log(e)
    yield put(
      loadMore
        ? actions.loadedMoreList(e, null, true)
        : actions.loadedList(e, null, true)
    )
  }
  if (actions.LOAD_MORE_LIST) {
    yield take(actions.LOAD_MORE_LIST)
    yield call(loadList, actions, getPath, getState, action, true)
  }
}

function* update(actions, getPath, getState, action) {
  try {
    const state = yield select(state => state)
    const path = getPath(state)
    const { crud } = yield select(state => getState(state))
    const { object } = crud || {}
    const { params } = action.meta || {}

    let body = object
    const { extraBody, overwriteId, withoutAuthentication } =
      action.payload || {}
    if (extraBody) {
      body = extraBody
    }
    console.log({ body, path, overwriteId, params })

    const response = yield call(withoutAuthentication ? api : fetchListener, {
      path: `${path}/${overwriteId ? overwriteId : body.id}`,
      method: 'PUT',
      body,
      params,
    })

    if (body.field || body.customField) {
      // yield put(resetActions.reset())
    } else {
      yield put(resetActions.reset())
    }
    yield put(actions.updated(response))
  } catch (e) {
    yield put(actions.updated(e, null, true))
  }
}

function* remove(actions, getPath, getState, action) {
  try {
    const state = yield select(state => state)
    const path = getPath(state)
    const { crud } = yield select(state => getState(state))
    const { object } = crud
    const { params } = action.meta || {}
    // const response =
    yield call(fetchListener, {
      path: `${path}/${object.id}`,
      method: 'DELETE',
      params,
    })

    if (object.field || object.customField) {
      // yield put(resetActions.reset())
    } else {
      yield put(resetActions.reset())
    }
    yield put(actions.removed(object))
  } catch (e) {
    yield put(actions.removed(e, null, true))
  }
}

function* create(actions, getPath, getState, action) {
  try {
    // console.log({ actions, getPath, getState, action })
    const state = yield select(state => state)
    const url = getPath(state)
    const { crud } = yield select(state => getState(state))
    const { object } = crud
    const { params, extraBody, extraURL } = action.meta || {}

    const disableAuthentication =
      (action && action.payload && action.payload.disableAuthentication) ||
      false

    let body = { ...object, ...extraBody }
    console.log({ body })
    if (body.fieldArray && body.fieldArray.length > 0) {
      let newFieldArray = []
      body.fieldArray.forEach(it => {
        if (it.field && it.field.source === 'manually') {
          newFieldArray = [
            ...newFieldArray,
            {
              ...it,
              field: null,
              customField: {
                fields: [
                  it.field,
                ],
              },
            },
          ]
        } else {
          newFieldArray = [...newFieldArray, it]
        }
      })
      body = newFieldArray
    }
    if (body.customFieldArray && body.customFieldArray.length > 0) {
      console.log(body.customFieldArray)
      body.customFieldArray.forEach(customField => {
        const newFieldArray = [
          {
            ...customField[0],
            field: null,
            customField: {
              fields: customField.map(cf => cf.field),
            },
          },
        ]
        body = Array.isArray(body) ? [...body, ...newFieldArray] : newFieldArray
      })
    }

    let response
    if (disableAuthentication) {
      response = yield call(api, {
        path: extraURL ? extraURL : url,
        method: 'POST',
        body,
        params,
      })
    } else {
      response = yield call(fetchListener, {
        path: extraURL ? extraURL : url,
        method: 'POST',
        body,
        params,
      })
    }

    if (
      (body.fieldArray && body.fieldArray.length > 0) ||
      (body && body.length > 0 && body[0].field) ||
      (body && body.field) ||
      (body && body.length > 0 && body[0].customField) ||
      (body && body.customField)
    ) {
      console.log('not resetting')
      // yield put(resetActions.reset())
    } else {
      console.log(' resetting')
      yield put(resetActions.reset())
    }
    yield put(actions.created(response))
  } catch (e) {
    yield put(actions.created(e, null, true))
  }
}

function* createAdvice(actions, getPath, getState, action) {
  try {
    const { crud } = yield select(state => getState(state))
    const { object } = crud
    const { params, extraBody } = action.meta || {}

    let body = { ...object, ...extraBody }

    const response = yield call(fetchListener, {
      path: 'advice/generated',
      method: 'POST',
      body,
      params,
    })

    yield put(actions.createdAdvice(response))
  } catch (e) {
    yield put(actions.createdAdvice(e, null, true))
  }
}

// modified to return either true or false
function* fetchData(item, id) {
  try {
    const data = yield call(fetchListener, {
      path: 'advice/' + id + '/notify/email',
      method: 'GET',
      params: {
        language: 'nl',
        userId: item.id,
      },
    })
    return data
  } catch (error) {
    return error
  }
}

function* sendAdvice(actions, getPath, getState, action) {
  try {
    const { users, id } = action.meta || {}

    const response = yield all(users.map(user => fetchData(user, id)))

    console.log(response)
    yield put(
      actions.sentAdvice(
        response,
        null,
        (response || []).some(it => it?.status !== 'success')
      )
    )
  } catch (e) {
    yield put(actions.sentAdvice(e, null, true))
  }
}

export const tempSaga = function* pagedList(
  actions,
  getPath = state => '',
  getState = state => console.log('get state not implemented'),
  reloadConfig
) {
  let reloadActions
  if (reloadConfig && reloadConfig.removeOthers) {
    reloadActions = reloadConfig.reloadActions
  } else if (reloadConfig) {
    reloadActions = [...reloadConfig.reloadActions, reloadActions]
  }

  yield takeLatest(
    [actions.LOAD_LIST, actions.RELOAD_LIST, ...reloadActions].filter(n => n),
    loadList,
    actions,
    getPath,
    getState
  )

  yield takeLatest(actions.LOAD, load, actions, getPath, getState)
  yield takeLatest(actions.UPDATE, update, actions, getPath, getState)
  yield takeLatest(actions.CREATE, create, actions, getPath, getState)
  yield takeLatest(actions.REMOVE, remove, actions, getPath, getState)
}

export default function* pagedList(
  actions,
  getPath = state => '',
  getState = state => console.log('get state not implemented'),
  reloadConfig
) {
  let reloadActions = [actions.CREATED]
  if (reloadConfig && reloadConfig.removeOthers) {
    reloadActions = reloadConfig.reloadActions
  } else if (reloadConfig) {
    reloadActions = [...reloadConfig.reloadActions, reloadActions]
  }

  yield takeLatest(
    process.env.REACT_APP_USE_MOCK_DATA === 'true'
      ? []
      : [actions.LOAD_LIST, actions.RELOAD_LIST, ...reloadActions].filter(
          n => n
        ),
    loadList,
    actions,
    getPath,
    getState
  )

  yield takeLatest(actions.LOAD, load, actions, getPath, getState)
  yield takeLatest(actions.UPDATE, update, actions, getPath, getState)
  yield takeLatest(actions.CREATE, create, actions, getPath, getState)
  yield takeLatest(actions.REMOVE, remove, actions, getPath, getState)
  yield takeLatest(
    actions.CREATE_ADVICE,
    createAdvice,
    actions,
    getPath,
    getState
  )
  yield takeLatest(actions.SEND_ADVICE, sendAdvice, actions, getPath, getState)
}
