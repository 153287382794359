import actions from './actions'
import ofActions from '../../actions'

import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { fetchListener } from 'react-auth/lib/sagas/authentication'

function* createGrowingPeriods({ payload }) {
  try {
    const state = yield select(state => state)
    const orgFieldObject = state.organizationField.crud.object

    const growingPeriodsToAdd = [
      ...((payload?.growingPeriods || [])?.filter(gp => !gp.id) || []),
      ...((
        payload?.growingPeriodsWithIDButShouldBePostedBecauseOtherSectionsWithThisGrowingPeriodIDAreNotSelected ||
        []
      )?.map(gp => ({
        ...gp,
        id: undefined,
      })) || []),
    ]

    const growingPeriodsToUpdate = payload?.growingPeriods?.filter(
      gp =>
        gp.id &&
        !payload?.growingPeriodsWithIDButShouldBePostedBecauseOtherSectionsWithThisGrowingPeriodIDAreNotSelected?.some(
          newGp => newGp.id === gp.id
        )
    )
    const growingPeriodsToUpdateWithoutSection =
      payload?.growingPeriodsWithIDButShouldBePostedBecauseOtherSectionsWithThisGrowingPeriodIDAreNotSelected?.map(
        gp => ({
          ...(gp?.oldGrowingPeriod || {}),
          organizationField: {
            ...gp?.organizationField,
            sections: gp?.allSectionsWithThatGrowingPeriod,
          },
        })
      )

    console.log({
      growingPeriodsToAdd,
      payload,
      growingPeriodsToUpdate,
      growingPeriodsToUpdateWithoutSection,
    })
    const putResponses = yield all([
      ...[
        ...(growingPeriodsToUpdate || []),
        ...(growingPeriodsToUpdateWithoutSection || []),
      ].map(growingPeriod =>
        call(fetchListener, {
          path: `v2/field/growing-period/${growingPeriod.id}`,
          method: 'PUT',
          body: {
            ...growingPeriod,
          },
        })
      ),
    ])
    const responses = yield all([
      ...growingPeriodsToAdd.map(growingPeriod =>
        call(fetchListener, {
          path: `v2/field/growing-period`,
          method: 'POST',
          body: {
            ...growingPeriod,
          },
        })
      ),
    ])

    yield all([
      put(actions.created([...responses, ...putResponses])),
      put(
        ofActions.setData({
          ...orgFieldObject,
          selectedSections: [],
        })
      ),
    ])
  } catch (e) {
    const state = yield select(state => state)
    const orgFieldObject = state.organizationField.crud.object
    console.log('error:')
    console.log(e)
    yield all([
      put(actions.created(e, null, true)),
      put(
        ofActions.setData({
          ...orgFieldObject,
          selectedSections: [],
        })
      ),
    ])
  }
}

export default function* crud() {
  yield takeLatest(actions.CREATE, createGrowingPeriods)
}
