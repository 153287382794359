import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as filterAction from './FilterAction/actions'
import { initAnalytics } from '../Analytics/abstraction'
import { push } from '../Navigation'
import * as routes from '../Routes.config'

function RedirectWithFilters({
  params,
  componentId,
  ...rest
}: {
  componentId: string
  params: any
}) {
  const dispatch = useDispatch()
  const authUser = useSelector((state: any) => state.auth && state.auth.user)
  const fa = useSelector((state: any) => state.filterActions)

  useEffect(() => {
    if (fa && fa.done) {
      const user = (authUser && authUser.user) || {}
      if (
        user &&
        user.roles &&
        user.roles.length > 0 &&
        user.roles.find((role: { name: string }) => role.name === 'user')
      ) {
        // this account has the role "user" which means that it's an Agro4all user account.
        push(componentId, {
          component: {
            name: routes.PRODUCTS_ROUTE,
          },
        })
      } else {
        // this account does not have the role "user" which means that it's an Driftzoeker account, and needs to
        // contact Agro4all for getting the role "user", to access Agro4all's products.
        push(componentId, {
          component: {
            name: routes.WRONG_SUBSCRIPTION_ROUTE,
          },
        })
      }
    }
  }, [fa, componentId, authUser])
  useEffect(() => {
    const user = (authUser && authUser.user) || {}
    const organization = (authUser && authUser.organization) || {}
    if (user && organization) {
      initAnalytics({ user, organization })
    }
  }, [authUser])

  const [hasDone, setHasDone] = useState(false)
  useEffect(() => {
    if (!hasDone) {
      setHasDone(true)
      const urlSearchParams = new URLSearchParams(window.location.search)
      let params: any = Object.fromEntries(urlSearchParams.entries())
      Object.keys(params).forEach((key) => {
        if (params[key] === 'undefined') {
          params[key] = undefined
        }
        if (params[key] === 'true') {
          params[key] = true
        }
        if (params[key] === 'false') {
          params[key] = false
        }
      })

      if (params) {
        dispatch(filterAction.setFilters({ params }))
      }
    }
  }, [params, rest, dispatch, hasDone, setHasDone])

  return null
}
export default RedirectWithFilters
