import * as a from './actions'

const initialState = {
  data: {
    value: '',
  },
  loaded: false,
  error: false,
  loading: false,
}

const reducer = (state = initialState, { type, payload, error, meta }) => {
  switch (type) {
    case 'RESET': {
      return initialState
    }
    case a.SET_SEARCH: {
      return {
        ...state,
        data: {
          ...payload,
          value: payload.value || '',
        },
        error: false,
        loading: false,
        loaded: true,
      }
    }

    default:
      return state
  }
}

export default reducer
