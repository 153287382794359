import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import { secondsToDDMMYYYY } from '../helpers/date'
import { push } from '../Navigation'
import { FlatList, StyleSheet, View } from 'react-native'
import { PDF_ROUTE } from '../Routes.config'
import { getProductData } from './selectors'
import SafeListItem from '../WidthAndHeight/SafeListItem'
import safeAreaHOC from '../WidthAndHeight/safeAreaHOC'
import Resizer from '../WidthAndHeight/Resizer'
import { Text } from 'react-native-paper'
import { getPDFLink } from './ProductSummary'
import { Translate } from 'react-translated'

const getLink = decision => {
  const link = getPDFLink(decision)
  if (!decision || !link) {
    return false
  }

  if (link.endsWith('pdf')) {
    return link
  }

  return false
}
const getItemLabel = decision => decision.description
const getItemSubLabel = decision => secondsToDDMMYYYY(decision.signatureDate)

const styles = StyleSheet.create({
  container: {
    padding: 16,
  },
})

class ProductDecisions extends PureComponent {
  _press = decision => () => {
    const link = getLink(decision)
    if (link) {
      push(this.props.componentId, {
        component: {
          name: PDF_ROUTE,
          passProps: {
            url: encodeURI(link),
            title: getItemLabel(decision),
            subtitle: getItemSubLabel(decision),
          },
        },
      })
    }
  }
  _renderItem = ({ item }) => {
    return (
      <Resizer>
        <SafeListItem
          title={getItemLabel(item)}
          description={getItemSubLabel(item)}
          onPress={this._press(item)}
        />
      </Resizer>
    )
  }
  _renderFooter = () => {
    const { safe } = this.props
    return <View style={{ height: safe.bottom }} />
  }
  render() {
    return !this.props.decisions || this.props.decisions.length === 0 ? (
      <Resizer>
        <Text style={styles.container}>
          <Translate text={'noDecisions'} />
        </Text>
      </Resizer>
    ) : (
      <FlatList
        data={this.props.decisions || []}
        renderItem={this._renderItem}
        ListFooterComponent={this._renderFooter}
      />
    )
  }
}

const mapStateToProps = state => ({
  decisions: getProductData(state).decisions,
})

export default safeAreaHOC(connect(mapStateToProps)(ProductDecisions))
