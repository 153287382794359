import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import setUserAndOrganization from "./abstraction";

function CrashReports() {
    const authUser = useSelector(state => state.auth && state.auth.user)

    useEffect(() => {
        const user = (authUser && authUser.user) || {}
        const organization = (authUser && authUser.organization) || {}
        setUserAndOrganization({user, organization});
    }, [authUser])

    return null
}
export default CrashReports
