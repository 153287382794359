import React from 'react'
import PestList from '../../FilterType/Pest/PestList'
import { filterTypesSorted } from '../../FilterType/config'
import { push } from '../../Navigation'
import * as routes from '../../Routes.config'

function AdvicePestsScreen({ componentId }) {
  const filterType = filterTypesSorted.find(it => it.id === 'pest')
  return (
    <PestList
      componentId={componentId}
      filterType={filterType}
      tree
      filterTypeParams={filterType}
      onSelectRedirect={() =>
        push(componentId, {
          component: {
            name: routes.ADVICE_NEW_PRODUCT_ROUTE,
          },
        })
      }
    />
  )
}

export default AdvicePestsScreen
